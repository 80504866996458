import { useId } from 'react';

import { Field, FormState, useMutateField } from '@pushpay/forms';
import { TextInput, Switch } from '@pushpay/inputs';
import { Column, Columns } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { CallToActionPropertyInput, ShellCapabilityKey } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp } from '@src/myContext';

import { InAppBrowserSwitch } from '../shared';
import { ContentWrapper, LabelWrapper, TextFieldWrapper } from '../wrappers';

const useStyles = createUseStyles({
	switchWrapper: {
		display: 'flex',
	},
});

type CallToActionInput = FormState<Required<CallToActionPropertyInput>>;
export type CallToActionProps = ComponentProps<
	{
		propertyField: FormState<CallToActionPropertyInput>;
	},
	typeof undefined
>;

export const CallToAction = ({ propertyField, 'data-pp-at-target': targetId }: CallToActionProps) => {
	const { getAppShellCapability } = useMyApp();
	// Resi VOD and In-app Browser share the same shell requirement
	const isInAppBrowserSupported = getAppShellCapability(ShellCapabilityKey.ResiVodDraggable)?.isSupported ?? false;
	const id = useId();

	const { label: labelField, url: urlField, isExternal: isExternalField } = propertyField as CallToActionInput;
	const { value: isExternal } = isExternalField;

	const { translate } = useTranslation('appDesign');
	const { mutateField: mutateIsExternal } = useMutateField(isExternalField);
	const { mutateField: mutateLabel } = useMutateField(labelField);
	const classes = useStyles(undefined);

	const handleLabelChange = (value: string) => {
		mutateLabel(value.toUpperCase());
	};
	const handleIsExternalToggle = (value: boolean) => {
		mutateIsExternal(value);
	};

	return (
		<ContentWrapper>
			<Columns space="SMALL" stackWhen="NEVER">
				<Column width="2/3">
					<LabelWrapper label={translate('callToAction.label')}>
						<TextInput
							aria-label={translate('callToAction.label')}
							data-pp-at-target={`${targetId}-call-to-action-label`}
							id={useId()}
							maxLength={23}
							value={labelField?.value || ''}
							onChange={handleLabelChange}
						/>
					</LabelWrapper>
				</Column>
				{isInAppBrowserSupported ? (
					<InAppBrowserSwitch checked={isExternal} id={id} label="" onChange={handleIsExternalToggle} />
				) : (
					<Column>
						<LabelWrapper
							classes={{ content: classes.switchWrapper }}
							label={translate('callToAction.openExternally')}
						>
							<Switch checked={isExternal} id={id} onChange={handleIsExternalToggle} />
						</LabelWrapper>
					</Column>
				)}
			</Columns>
			<TextFieldWrapper
				aria-label={translate('callToAction.url')}
				data-pp-at-target={`${targetId}-call-to-action-url`}
				field={urlField as Field<string>}
				label={translate('callToAction.url')}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
