import { useEffect, useState } from 'react';

import { CheckboxField, FormState, SelectField, TextField, useFieldVisibility, useMutateField } from '@pushpay/forms';
import { Column, Columns, Stack, Tiles } from '@pushpay/layout';
import { clsx, createUseStyles, multiply } from '@pushpay/styles';
import { Theme, useMediaBreakpoint } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ExpandableAttributes } from '@src/components/expandableAttributes';
import { CTAField } from '@src/components/formFields/CTAField';
import { FeaturedLabelField } from '@src/components/formFields/FeaturedLabelField';
import { CardType, GetItemQuery, SaveItemSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useEvent } from '@src/utils';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	label: {
		font: theme.typography['text-4'],
		margin: `${multiply(theme.SPACING.XSMALL, 1.5)} 0 ${theme.SPACING.XSMALL} 0`,
	},
	inputLabel: {
		display: 'flex',
		font: theme.typography['text-4'],
		color: theme.colors['text-default'],
	},
	inputLabelTooltip: {
		marginLeft: theme.SPACING.XXSMALL,
	},
	checkboxInput: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		padding: 0,
	},
	checkboxInputLabel: {
		font: theme.typography['text-4'],
		alignItems: 'center',
		width: 'auto',
		lineHeight: theme.SPACING.SMALL,
		margin: `0 0 0 ${theme.SPACING.XSMALL}`,
	},
	checkboxTile: {
		flexBasis: 'unset',
		maxWidth: 'unset',
	},
	stackItem: {
		paddingTop: 0,
	},
	tile: {
		padding: `0 0 0 ${theme.SPACING.MEDIUM}`,
	},
	column: {
		marginTop: theme.SPACING.SMALL,
	},
	expandableAttributesBody: {
		paddingTop: 0,
	},
}));
type GetItemQueryProperties = NonNullable<
	NonNullable<NonNullable<NonNullable<NonNullable<GetItemQuery>['organization']>['application']>['container']>['item']
>['properties'];
type GetItemCardDefinition = NonNullable<
	NonNullable<NonNullable<NonNullable<NonNullable<GetItemQuery>['organization']>['application']>['container']>['item']
>['cardDefinition'];

type DHSItemSettingProps = {
	fields: FormState<SaveItemSettingsSchema>['input']['cardDefinition'];
	itemProperties?: GetItemQueryProperties;
	cardDefinition?: GetItemCardDefinition;
};

export const DHSItemSettingLegacy = ({
	fields,
	cardDefinition,
	itemProperties,
	classes: classesProp,
}: Omit<ComponentProps<DHSItemSettingProps, typeof useStyles>, 'data-pp-at-target'>) => {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const [enableDhs, setEnableDhs] = useState(!!cardDefinition);
	const isCTAEnabled = (
		[CardType.Intro, CardType.Special, CardType.Event, CardType.Impact] as Array<CardType | undefined | null>
	).includes(fields.cardType.value);
	const isImpactItem = fields.cardType.value === CardType.Impact;

	const { mutateField: updateIsFeatured } = useMutateField(fields.isFeatured);
	const { visible, setVisibility: handleOnDHSToggle } = useFieldVisibility(fields);

	const syncFieldVisibility = useEvent((val: boolean) => {
		if (enableDhs !== val) {
			setEnableDhs(val);
		}
	});

	useEffect(() => {
		syncFieldVisibility(visible);
	}, [syncFieldVisibility, visible]);

	const isTablet = useMediaBreakpoint('DESKTOP_AND_BELOW');

	return (
		<ExpandableAttributes
			classes={{ root: classes.root, body: classes.expandableAttributesBody }}
			data-pp-at-target="dhs-options"
			disabled={false}
			expand={enableDhs}
			isFeatured={fields.isFeatured.value && enableDhs}
			title={translate('itemSetting.dhsSetting.dhsTitle')}
			toggleName={translate('itemSetting.dhsSetting.dhsToggleName')}
			tooltipContent={translate('itemSetting.dhsSetting.dhsTooltip')}
			canBeFeatured
			onExpandChange={handleOnDHSToggle}
			onIsFeaturedChange={updateIsFeatured}
		>
			<Stack classes={{ stackItem: classes.stackItem }} space={0}>
				<Columns>
					<Column classes={{ root: classes.column }}>
						<Tiles
							classes={{
								tile: classes.tile,
							}}
							columns={isTablet ? [1] : [2]}
						>
							<SelectField<CardType | null>
								classes={{ label: classes.label }}
								data-pp-at-target="dhs-options-card-type"
								defaultValue={CardType.Default}
								field={fields.cardType}
								label={translate('itemSetting.dhsSetting.dhsCardTypeLabel')}
								labelPosition="top"
								options={getCardTypeOptions(isImpactItem, itemProperties)}
								readOnly={isImpactItem}
								tooltip={
									isImpactItem
										? translate('itemSetting.dhsSetting.dhsImpactCardTypeLabelTooltip')
										: translate('itemSetting.dhsSetting.dhsCardTypeLabelTooltip')
								}
								showLabel
							/>
							{!isImpactItem && (
								<TextField
									classes={{
										label: classes.label,
									}}
									data-pp-at-target="dhs-options-subtitle"
									field={fields.genericContent?.subtitle}
									label={translate('itemSetting.dhsSetting.dhsSubTitleLabel')}
									labelPosition="top"
									placeholder={translate('itemSetting.dhsSetting.subTitlePlaceholder')}
									showLabel
								/>
							)}
						</Tiles>
						<Tiles columns={[1]}>
							<TextField
								classes={{ label: classes.label }}
								data-pp-at-target="dhs-options-summary"
								field={fields.genericContent?.summary}
								label={translate('itemSetting.dhsSetting.dhsSummaryLabel')}
								labelPosition="top"
								placeholder={translate('itemSetting.dhsSetting.summaryPlaceholder')}
								showLabel
							/>
						</Tiles>
						{!isImpactItem && (
							<Tiles classes={{ tile: classes.checkboxTile }} columns={[4]}>
								<CheckboxField
									classes={{
										root: classes.checkboxInput,
										label: clsx(classes.checkboxInputLabel, classes.label),
									}}
									field={fields.showProperties?.publishedTime}
									label={translate('itemSetting.dhsSetting.dhsShowPublishedTime')}
									showLabel
								/>
								<CheckboxField
									classes={{
										root: classes.checkboxInput,
										label: clsx(classes.checkboxInputLabel, classes.label),
									}}
									field={fields.showProperties?.category}
									label={translate('itemSetting.dhsSetting.dhsShowCategory')}
									showLabel
								/>
							</Tiles>
						)}
						{isImpactItem && (
							<Tiles classes={{ tile: classes.checkboxTile }} columns={[4]}>
								<CheckboxField
									classes={{
										root: classes.checkboxInput,
										label: clsx(classes.checkboxInputLabel, classes.label),
									}}
									field={fields.showProperties?.title}
									label={translate('itemSetting.dhsSetting.dhsShowTitle')}
									showLabel
								/>
								<CheckboxField
									classes={{
										root: classes.checkboxInput,
										label: clsx(classes.checkboxInputLabel, classes.label),
									}}
									field={fields.showProperties?.keyMetrics}
									label={translate('itemSetting.dhsSetting.dhsShowKeyMetrics')}
									showLabel
								/>
							</Tiles>
						)}
						{isCTAEnabled && (
							<Tiles columns={[2]}>
								<CTAField
									cardDefinitionType={isImpactItem ? 'impact' : 'base'}
									field={fields.callToActionText}
								/>
							</Tiles>
						)}
						{!!fields.isFeatured.value && (
							<Tiles columns={[2]}>
								<FeaturedLabelField field={fields.featuredLabel} />
							</Tiles>
						)}
					</Column>
				</Columns>
			</Stack>
		</ExpandableAttributes>
	);
};

function getCardTypeOptions(isImpactItem: boolean, itemProperties?: GetItemQueryProperties) {
	let cardTypeOptions = isImpactItem ? [CardType.Impact] : [CardType.Default, CardType.Special, CardType.Intro];
	if (!isImpactItem && itemProperties) {
		const dynamicCardTypeList = itemProperties
			.filter(({ __typename: type = '' }) =>
				['VideoProperty', 'AudioProperty', 'TimeframeProperty'].includes(type)
			)
			.filter(x => {
				if (x.__typename === 'VideoProperty' || x.__typename === 'AudioProperty') {
					return !!x.url;
				}
				if (x.__typename === 'TimeframeProperty') {
					return !!x.timeframe;
				}
				return true;
			})
			.map(({ __typename: type }) => {
				if (type === 'VideoProperty') {
					return CardType.Video;
				}
				if (type === 'AudioProperty') {
					return CardType.Audio;
				}
				if (type === 'TimeframeProperty') {
					return CardType.Event;
				}
				return CardType.Default;
			});

		// Using Set to remove duplicate card types
		cardTypeOptions = cardTypeOptions.concat([...new Set(dynamicCardTypeList)]);
	}
	return cardTypeOptions.map(x => ({
		display: x.toLowerCase().charAt(0).toUpperCase() + x.toLowerCase().slice(1),
		value: x,
	}));
}
