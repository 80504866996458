import { useCallback, useEffect, useId, useMemo, useRef } from 'react';

import { Button } from '@pushpay/button';
import {
	DynamicFieldsProvider,
	FormState,
	getDynamicFieldsItems,
	useDynamicFieldsMutation,
	useDynamicFieldsItems,
} from '@pushpay/forms';
import { MultiSelect, CheckableOption } from '@pushpay/inputs';
import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { PillButton } from '@src/components/buttons';
import { ItemPropertyField } from '@src/components/properties/types';
import { SaveItemSettingsSchema, SharePropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { usePropertyListContext } from '../contexts';
import { ContentWrapper, LabelWrapper } from '../wrappers';

type PropertyListType = Array<{
	value: string;
	display: string;
}>;
export type ShareProps = ComponentProps<
	{
		propertyField: FormState<SharePropertyInput>;
	},
	typeof undefined
>;
export type SharePropertyProps = ShareProps & {
	propertyList: PropertyListType;
};

export type SharePropertyFieldType = FormState<
	SaveItemSettingsSchema['input']['properties'][number]['sharePropertyInput']
>;

function createSelectedOptions(
	sharedProperties: SharePropertyFieldType['sharedProperties'][number][],
	propertyList: PropertyListType
) {
	return sharedProperties.reduce((acc, { value: propertyId }) => {
		const { display } = propertyList.find(({ value: id }) => propertyId === id) || {};

		if (display) {
			acc.push({ value: propertyId, display });
		}

		return acc;
	}, [] as CheckableOption<string>[]);
}

const useStyles = createUseStyles({
	overlay: {
		maxHeight: '300px',
	},
	disabledCursor: {
		cursor: 'not-allowed',
	},
	actionBar: {
		bottom: 0,
		display: 'flex',
		padding: '10px 10px',
		position: 'sticky',
		borderTop: '1px solid #E3E5E9',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#FFF',
	},
});

const ShareProperty = ({ propertyField, propertyList, 'data-pp-at-target': targetId }: SharePropertyProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyles(undefined);
	const { disabledForFeedItem } = usePropertyListContext();

	const { sharedProperties } = propertyField as SharePropertyFieldType;
	const sharedDynamicFields = getDynamicFieldsItems(sharedProperties);

	const { removeItem, replaceItems } = useDynamicFieldsMutation();
	const previousJoinedOptions = useRef<string>(propertyList.map(({ value }) => value).join());

	const updateSelectedPropertiesOnListUpdate = useCallback(() => {
		const currentOptions = propertyList.map(({ value }) => value);

		const hasOptionsBeenUpdated = currentOptions.join() !== previousJoinedOptions.current;
		if (hasOptionsBeenUpdated) {
			previousJoinedOptions.current = currentOptions.join();

			const currentSelectedOptions = sharedDynamicFields.map(({ value }) => value) as string[];
			const currentOptionsSet = new Set(currentOptions);
			const filteredSelectedList = currentSelectedOptions.filter(propertyId => currentOptionsSet.has(propertyId));

			const hasDeletedIds = filteredSelectedList.join() !== currentSelectedOptions.join();
			if (hasDeletedIds) {
				replaceItems(filteredSelectedList);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propertyList]);

	useEffect(() => {
		updateSelectedPropertiesOnListUpdate();
	}, [updateSelectedPropertiesOnListUpdate]);

	const selectedOptions = createSelectedOptions(sharedDynamicFields, propertyList);

	const onChange = async (options: CheckableOption<string>[]) => {
		const propertiesList = options.reduce((acc: string[], { value: propertyId }) => {
			if (propertyId) {
				acc.push(propertyId);
			}
			return acc;
		}, []);

		replaceItems(propertiesList);
	};

	const removePropertyFromList = (idToRemove: string | null) => {
		if (idToRemove) {
			const indexToRemove = sharedDynamicFields.findIndex(({ value: id }) => idToRemove === id);

			if (indexToRemove !== -1) removeItem(indexToRemove);
		}
	};

	const propertyPills = selectedOptions.map(({ display, value }) => (
		<PillButton key={value} onClick={() => removePropertyFromList(value)}>
			{display}
		</PillButton>
	));

	const isDisabled = !propertyList.length || disabledForFeedItem;

	// TODO: Fix MultiSelect CL -> https://pushpay.atlassian.net/browse/CAPPS-11484
	const multiSelectOptions = propertyList.length
		? propertyList.sort((a, b) => a.display.localeCompare(b.display))
		: [{ value: 'No Properties Selected', display: 'No Properties Selected' }];

	return (
		<ContentWrapper>
			<LabelWrapper label={translate('share.shareLabel')}>
				<MultiSelect<string>
					actionBarConfig={{
						isDefault: false,
						renderActionBar: (
							selectionOptions,
							_initialOptions,
							_displayOptions,
							_setDisplayOptions,
							close
						) => (
							<div className={classes.actionBar}>
								<Button displayStyle="text" type="button" onClick={() => onChange([])}>
									{translate('share.propertiesSelector.clearActionText')}
								</Button>
								<Button
									displayStyle="text"
									type="button"
									onClick={() => {
										onChange(selectionOptions);
										close();
									}}
								>
									{translate('share.propertiesSelector.applyButtonLabel')}
								</Button>
							</div>
						),
					}}
					allOptionsSelectedText={translate('share.propertiesSelector.allPropertiesSelectedText')}
					applyButtonLabel={translate('share.propertiesSelector.applyButtonLabel')}
					classes={{
						overlay: classes.overlay,
						summary: clsx(isDisabled && classes.disabledCursor),
					}}
					data-pp-at-target={`${targetId}-select`}
					disabled={isDisabled}
					id={useId()}
					immediatelyApplyAfterSelecting={false}
					mobileBreakpoint="NEVER"
					options={multiSelectOptions}
					selectionDisplayConfig={{
						isDefault: true,
						noOptionsSelectedText: translate('share.propertiesSelector.noPropertiesSelectedText'),
						someOptionsSelectedText: translate('share.propertiesSelector.somePropertiesSelectedText'),
					}}
					value={selectedOptions}
					hasSelectAllOption
					onChange={onChange}
				/>
			</LabelWrapper>
			{selectedOptions.length > 0 ? (
				<LabelWrapper label={translate('share.selectedPropertiesLabel')}>{propertyPills}</LabelWrapper>
			) : null}
		</ContentWrapper>
	);
};

export const Share = ({ propertyField, 'data-pp-at-target': targetId }: ShareProps) => {
	const { sharedProperties } = propertyField as SharePropertyFieldType;
	const { items }: { items: ItemPropertyField[] } = useDynamicFieldsItems();

	const propertyList = useMemo(
		() =>
			items.reduce<PropertyListType>((acc, property) => {
				const { baseProperty } = Object.values(property)[0];
				const display = baseProperty.header.value;

				if (display !== 'Share') {
					const { value } = baseProperty.id;

					acc.push({ value, display });
				}

				return acc;
			}, []),
		[items]
	);

	return (
		<DynamicFieldsProvider dynamicFields={sharedProperties}>
			<ShareProperty
				data-pp-at-target={targetId}
				propertyField={propertyField as FormState<SharePropertyInput>}
				propertyList={propertyList}
			/>
		</DynamicFieldsProvider>
	);
};
