import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { invisibleVerticalScroll } from '@src/shared/styles';

const headerHeight = '60px';

export const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexGrow: 1,
		minWidth: 0,
	},
	sidePanel: {
		backgroundColor: theme.colors['common-white'],
		flexShrink: 0,
		borderRight: `1px solid ${theme.colors['grey-300']}`,
		...invisibleVerticalScroll(),

		[theme.mediaBreakpoint('TABLET_AND_BELOW')]: {
			width: '175px',
		},
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		overflow: 'hidden',
		position: 'relative',
	},
	header: {
		height: headerHeight,
		borderBottom: `1px solid ${theme.colors['grey-300']}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	breadcrumbs: {},
	actions: {},
	content: {
		display: 'flex',
		height: `calc(100% - ${headerHeight})`,
		overflow: 'auto',
	},
	pageContainer: {
		flexGrow: 1,
		minWidth: '780px',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100%',
	},
	page: {
		padding: `0 ${theme.SPACING.LARGE}`,
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		backgroundColor: 'transparent',
		width: '100%',
		flexShrink: 0,
	},
}));
