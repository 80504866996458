import { createUseStyles, clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

import { ServiceUnavailableIllustration } from '../illustrations';
import { BaseError } from './BaseError';

const useStyles = createUseStyles(() => ({
	root: {},
}));

export type ServiceErrorComponentProps = ComponentProps<{ errorMessage: string }, typeof useStyles>;

export const ServiceError = ({
	errorMessage,
	className: classNameProp,
	classes: classesProp,
	'data-pp-at-target': targetId,
}: ServiceErrorComponentProps) => {
	const { translate } = useTranslation();
	const classes = useStyles(classesProp);
	const className = clsx(classNameProp, classes.root);

	return (
		<BaseError
			classes={{ root: className }}
			data-pp-at-target={targetId}
			illustration={ServiceUnavailableIllustration}
			text={errorMessage}
			title={translate('errors.title.generalError')}
		/>
	);
};
