import { RecursivePartial } from '@pushpay/types';

import { DEFAULT_FEATURED_LABEL } from '@src/components/formFields';
import {
	BaseCardDefinition,
	ImpactCardDefinition,
	SaveItemSettingsSchema,
	CardType,
	CallToActionType,
	ItemType,
	ItemPropertyInput,
} from '@src/graphql/generated';
import { ItemSettings } from '@src/pages/itemSettings/types';
import { propertyInputTypeObj } from '@src/pages/itemSettings/utils';

const getCardDefinition = (item: ItemSettings) => {
	if (item?.cardDefinition === null) {
		if (item.type === ItemType.Impact) {
			return {
				cardType: CardType.Impact,
				showProperties: {
					keyMetrics: true,
					title: true,
				},
			};
		}
		return {
			cardType: [ItemType.Event, ItemType.Events].includes(item.type) ? CardType.Event : CardType.Default,
			showProperties: {
				category: true,
				publishedTime: true,
			},
			featuredLabel: DEFAULT_FEATURED_LABEL,
			callToActionText: CallToActionType.ReadMore,
		};
	}

	return {
		...item?.cardDefinition,
		genericContent: {
			subtitle: (item?.cardDefinition as BaseCardDefinition)?.content?.subtitle,
			summary: (item?.cardDefinition as BaseCardDefinition)?.content?.summary,
		},
		featuredLabel: item?.cardDefinition?.featuredCard?.label,
		showProperties: {
			category: (item?.cardDefinition as BaseCardDefinition)?.displayProperties.showCategory || false,
			keyMetrics: (item?.cardDefinition as ImpactCardDefinition)?.displayProperties.showKeyMetrics || false,
			title: (item?.cardDefinition as ImpactCardDefinition)?.displayProperties.showTitle || false,
			publishedTime: (item?.cardDefinition as BaseCardDefinition)?.displayProperties.showPublishedTime || false,
		},
	};
};

export function getProperties(item: ItemSettings) {
	if (!item?.properties) return [];

	return item?.properties.reduce<ItemPropertyInput[]>((properties, property) => {
		if (propertyInputTypeObj[property.type]) {
			const { action, actionBar, header, icon, id, hidden: isHidden, position, ...data } = property;
			const baseProperty = {
				action,
				actionBar,
				header,
				icon: icon || '',
				id,
				isHidden,
				position,
			};
			const propertyInputType = propertyInputTypeObj[property.type];

			let values: Record<string, any> = data;

			if (data.__typename === 'TimeframeProperty') {
				const { timeframe, ...rest } = data;

				values = { ...rest, ...timeframe };
			} else if (data.__typename === 'ShareProperty') {
				const { sharedProperties: rawSharedProperties, ...rest } = data;
				const sharedProperties = rawSharedProperties.map(({ id: sharedPropertyId }) => sharedPropertyId);

				values = { ...rest, sharedProperties };
			} else if (data.__typename === 'CarouselProperty') {
				const { images, ...rest } = data;
				const imageInputs = images.map(image => ({
					existingImageInput: {
						id: image.id,
					},
				}));
				values = { ...rest, images: imageInputs };
			}

			properties.push({ [propertyInputType]: { ...values, baseProperty } });
		}

		return properties;
	}, []);
}

export function generateItemSettingsFormInitialValues(
	item: ItemSettings,
	applicationId: string
): RecursivePartial<SaveItemSettingsSchema['input']> {
	return {
		...item,
		applicationId,
		parentContainerId: item?.parentContainer?.id,
		itemId: item?.id,
		cardDefinition: getCardDefinition(item),
		imageUrl: item?.image?.urls?.original || item?.image?.urls?.unprocessedImageUrl,
		properties: getProperties(item),
	};
}

export const generateItemSettingsFormFieldVisibility = (itemSettings: ItemSettings) => ({
	cardDefinition: itemSettings?.cardDefinition !== null,
});
