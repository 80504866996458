import { memo } from '@pushpay/types';

import { initGoogleMaps } from '@src/googleMaps';
import { initI18n } from '@src/i18n';
import { initRaygun } from '@src/raygun';

export const Init = memo(function Init() {
	initI18n();
	initRaygun();
	initGoogleMaps();

	return null;
});
