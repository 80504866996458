import { useDroppable } from '@dnd-kit/core';

import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		flex: '1',
		minHeight: '1px',
	},
	dropArea: ({ isOver }: { isOver: boolean }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.colors['grey-200'],
		border: `3px dashed ${isOver ? theme.colors['blue-500'] : theme.colors['grey-400']}`,
		borderRadius: '6px',
		color: theme.colors['text-placeholder'],
		padding: theme.SPACING.LARGE,
	}),
	dropIndicator: {
		borderTop: `4px solid ${theme.colors['blue-500']}`,
	},
}));

type NewPropertyDroppableAreaProps = ComponentProps<{ showDropAreaMessage: boolean }, typeof useStyles, undefined>;

const dropNewPropertyAreaId = 'newItemPropertiesDropArea';

export function NewPropertyDroppableArea({ showDropAreaMessage }: NewPropertyDroppableAreaProps) {
	const { translate } = useTranslation('appDesign');
	const { setNodeRef, isOver, active } = useDroppable({
		id: dropNewPropertyAreaId,
	});

	const classes = useStyles(undefined, { isOver });
	const newItem = !active?.data.current?.sortable;

	if (showDropAreaMessage)
		return (
			<div ref={setNodeRef} className={classes.dropArea}>
				{translate('property.dropArea')}
			</div>
		);

	return <div ref={setNodeRef} className={clsx(classes.root, { [classes.dropIndicator]: isOver && newItem })} />;
}
