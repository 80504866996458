import { createContext, useContext } from 'react';

type ImageFieldContext = {
	isProcessing: boolean;
};

export const ImageFieldContext = createContext<ImageFieldContext | null>(null);
ImageFieldContext.displayName = 'ImageFieldContext';

export function useImageFieldContext(): ImageFieldContext {
	const context = useContext(ImageFieldContext);
	if (!context) {
		return {
			isProcessing: false,
		};
	}
	return context;
}
