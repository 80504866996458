import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	content: {
		width: '480px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},
	icon: {
		width: '32px',
		height: '32px',
		color: theme.colors['grey-900'],
		marginTop: '48px',
	},
	header: {
		font: theme.typography['heading-3'],
		color: theme.colors['text-heading'],
		marginTop: theme.SPACING.MEDIUM,
	},
	text: {
		color: theme.colors['text-default'],
		fontSize: theme.typography.baseSize,
		marginTop: theme.SPACING.XSMALL,
		width: '400px',
	},
	cardText: {
		lineHeight: '24px',
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.SPACING.SMALL,
		marginBottom: '50px',
		marginTop: theme.SPACING.MEDIUM,
		justifyContent: 'space-between',
	},
	closeButton: {
		position: 'absolute',
		right: theme.SPACING.XSMALL,
		top: theme.SPACING.XSMALL,
	},
}));

export default useStyles;
