import { Theme } from '@pushpay/theming';

export const topNavItemStyles = (theme: Theme) => ({
	item: {
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
		alignItems: 'center',
		height: '40px',
		width: '40px',
		borderRadius: theme.SHAPE.CIRCULAR,

		'&:hover, &:focus': {
			backgroundColor: theme.colors['grey-200'],
		},
	},
	itemLink: {
		outline: 'none',
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
	},
});
