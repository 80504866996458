import { Field, FormState } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { FacetimePropertyInput } from '@src/graphql/generated';

import { ContentWrapper, TextFieldWrapper } from '../wrappers';

export type FacetimeProps = ComponentProps<
	{
		propertyField: FormState<FacetimePropertyInput>;
	},
	typeof undefined
>;

export const Facetime = ({ propertyField, 'data-pp-at-target': targetId }: FacetimeProps) => {
	const {
		facetimeUrl: fieldInput,
		baseProperty: { header },
	} = propertyField;

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={header?.value}
				data-pp-at-target={`${targetId}-text-field`}
				field={fieldInput as Field<string>}
				label={header?.value}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
