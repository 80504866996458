import { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useDroppable } from '@dnd-kit/core';

import { useMutateField } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ExpandableCard } from '@src/components/expandableCard';
import { useFeedProcessContext } from '@src/context/feedProcessContext';
import { useScrollElementIntoViewActions } from '@src/context/scrollElementIntoViewContext';
import { useFeature } from '@src/featureFlags';
import {
	FeedFragment,
	FeedFragmentDoc,
	FeedProcessState,
	useGetContainerFeedQuery,
	useProcessContainerFeedMutation,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp } from '@src/myContext';
import { useDragAutoScroll, useGetQueryAndMutationVars } from '@src/shared/hooks';
import { usePolling } from '@src/utils';

import { AttributeTypename } from '../draggable';
import { CampusAttribute } from './CampusAttribute';
import { FeedAttribute } from './FeedAttribute';
import { AttributeFields, ContainerType } from './types';
import { useFeedMutateFields } from './useFeedMutateFields';

const useStyles = createUseStyles((theme: Theme) => ({
	body: {
		paddingTop: 0,

		'& > :not(:last-child)': {
			marginBottom: theme.SPACING.SMALL,
		},
	},
	title: {
		font: theme.typography['heading-3'],
	},
	dropArea: ({ isOver }: { isOver: boolean }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.colors['grey-200'],
		border: `3px dashed ${isOver ? theme.colors['blue-500'] : theme.colors['grey-400']}`,
		borderRadius: '6px',
		color: theme.colors['text-placeholder'],
		padding: theme.SPACING.LARGE,
	}),
}));

export type AttributePanelProps = ComponentProps<
	{
		attributeFields: AttributeFields;
		container: ContainerType;
		onCacheUpdate?: () => void;
		isContainerCachePolluted: boolean;
		onProcessFeedEnd?: () => void;
	},
	typeof undefined
>;

const POLL_INTERVAL = 5000;
const TEMP_FEED_ID = 'temp-feed-id';
export const AttributePanel = ({
	container,
	attributeFields,
	onCacheUpdate,
	isContainerCachePolluted,
	onProcessFeedEnd,
}: AttributePanelProps) => {
	const isInAppCalendarEnabled = useFeature('InAppCalendar');
	const [initialFeed, updateInitialFeed] = useState(container?.feed);
	const { translate } = useTranslation('appDesign');
	const { currentApp } = useMyApp();
	const { organizationKey, platformCampusKey, applicationId, campusId } = useGetQueryAndMutationVars();
	const { setElementIdToScrollTo } = useScrollElementIntoViewActions();
	const { mutateField: mutateCampusId } = useMutateField(attributeFields.campusId);
	const { onAddFeed, onDeleteFeed } = useFeedMutateFields(attributeFields);
	const hasFeedConfiguration = (!!initialFeed?.id && initialFeed.id !== TEMP_FEED_ID) ?? false;
	const containerId = container?.id || '';

	// TODO: remove alongside InAppCalendar FF
	const isProcessingFeed = [FeedProcessState.Processing, FeedProcessState.Requested].includes(
		container?.feed?.processState ?? FeedProcessState.Unknown
	);

	const { startListeningToFeed } = useFeedProcessContext();

	useEffect(() => {
		// update the initialFeed to latest from server
		if (!isContainerCachePolluted) {
			updateInitialFeed(container?.feed);
		}

		// only update initial feed data when feed changes come from server
		if (container?.feed && container.feed.processState !== FeedProcessState.Unprocessed) {
			updateInitialFeed({ ...container.feed });
		}
	}, [container, isContainerCachePolluted]);

	const [processContainerFeed] = useProcessContainerFeedMutation({
		variables: {
			organizationKey,
			platformCampusKey,
			input: {
				applicationId,
				containerId,
			},
		},
		optimisticResponse: {
			processContainerFeed: {
				feed: {
					id: container?.feed?.id || '',
					processState: FeedProcessState.Requested,
					__typename: container?.feed?.__typename,
				},
				__typename: 'ProcessContainerFeedResult',
			},
		},
	});

	// TODO: remove alongside InAppCalendar FF
	const { startPolling, stopPolling } = useGetContainerFeedQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId,
		},
		fetchPolicy: 'network-only',
		skip: !isProcessingFeed,
	});

	const onProcessFeed = () => {
		if (container && isInAppCalendarEnabled) startListeningToFeed(container.id);
		processContainerFeed();
	};

	// TODO: remove alongside InAppCalendar FF
	usePolling({
		inProgress: isProcessingFeed,
		startPolling,
		stopPolling,
		onPollEnd: onProcessFeedEnd,
		interval: POLL_INTERVAL,
	});

	const client = useApolloClient();
	const onCampusSelect = (value: string | null) => {
		mutateCampusId(value);
		client.cache.modify({
			id: getContainerCacheId(),
			fields: {
				campus() {
					return currentApp.campuses.find(x => x.id === value) ?? null;
				},
			},
		});
		if (onCacheUpdate) {
			onCacheUpdate();
		}
	};
	const deleteCampus = () => {
		onCampusSelect(null);
	};
	const addCampus = () => {
		setElementIdToScrollTo(AttributeTypename.Campus);
		onCampusSelect(campusId);
	};
	const deleteFeed = (feedType: AttributeTypename) => {
		onDeleteFeed(feedType);
		client.cache.modify({
			id: getContainerCacheId(),
			fields: {
				feed() {
					return null;
				},
			},
		});
		if (onCacheUpdate) {
			onCacheUpdate();
		}
	};
	const addFeed = (feedType: AttributeTypename, feedFields: Record<string, any>) => {
		onAddFeed(feedType, feedFields);
		const id = feedType === initialFeed?.__typename ? initialFeed?.id : TEMP_FEED_ID;

		setElementIdToScrollTo(feedType);

		const createdFeedRef = client.cache.writeFragment<FeedFragment>({
			id: client.cache.identify({ __typename: feedType, id }),
			fragment: FeedFragmentDoc,
			data: {
				id,
				processState: FeedProcessState.Unprocessed,
				url: feedFields.url || null,
				identifiers: null,
				properties: {
					actionBarArray: null,
					forceRegenerate: null,
					hiddenProperties: null,
					itemTemplate: null,
					itemTemplateRaw: null,
					playlistId: null,
					shareProperties: null,
					...feedFields.properties,
				},
				__typename: feedType as any,
			},
		});

		client.cache.modify({
			id: getContainerCacheId(),
			fields: {
				feed() {
					return createdFeedRef ?? null;
				},
			},
		});

		if (onCacheUpdate) {
			onCacheUpdate();
		}
	};

	const { node, setNodeRef, isOver } = useDroppable({
		id: 'newContainerPropertiesDropArea',
		data: {
			addCampus,
			addFeed,
		},
	});
	const classes = useStyles(undefined, { isOver });

	useDragAutoScroll(node);

	return (
		<ExpandableCard
			classes={{ body: classes.body, title: classes.title }}
			initialExpandStatus="preexpanded"
			title={translate('attribute.draggables')}
		>
			<div ref={setNodeRef} className={classes.dropArea}>
				{translate('attribute.dropArea')}
			</div>
			{attributeFields.campusId.value && (
				<CampusAttribute
					campusId={attributeFields.campusId.value}
					onCampusDelete={deleteCampus}
					onCampusSelect={onCampusSelect}
				/>
			)}
			<FeedAttribute
				enableDownloadButton={hasFeedConfiguration}
				feed={initialFeed}
				inputFields={attributeFields}
				processFeed={onProcessFeed}
				onFeedDelete={deleteFeed}
			/>
		</ExpandableCard>
	);

	function getContainerCacheId(): string | undefined {
		return client.cache.identify({ __typename: 'Container', id: container?.id });
	}
};
