/* eslint-disable no-param-reassign */
const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;
const DEFAULT_HEIGHT = 56;
const THRESHOLD = 2;

export function setFloatingElemPositionForLinkEditor(
	targetRect: DOMRect | null,
	floatingElem: HTMLElement,
	anchorElem: HTMLElement,
	verticalGap: number = VERTICAL_GAP,
	horizontalOffset: number = HORIZONTAL_OFFSET
): void {
	const scrollerElem = anchorElem.parentElement;

	if (targetRect === null || !scrollerElem) {
		floatingElem.style.opacity = '0';
		floatingElem.style.transform = 'translate(-10000px, -10000px)';
		return;
	}

	const floatingElemRect = floatingElem.getBoundingClientRect();
	const anchorElementRect = anchorElem.getBoundingClientRect();
	const editorScrollerRect = scrollerElem.getBoundingClientRect();

	let top = targetRect.top - DEFAULT_HEIGHT - targetRect.height - VERTICAL_GAP * 2;
	const isOnFirstLine = editorScrollerRect.top + scrollerElem.offsetTop * 2 + THRESHOLD >= targetRect.top;

	if (isOnFirstLine) {
		top = targetRect.top - verticalGap;
	}

	let left = targetRect.left - horizontalOffset;

	if (left + floatingElemRect.width > editorScrollerRect.right) {
		left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
	}

	top -= anchorElementRect.top;
	left -= anchorElementRect.left;

	floatingElem.style.opacity = '1';
	floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}
