import { Dispatch, SetStateAction } from 'react';

import { Checkbox } from '@pushpay/inputs';
import { clsx } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { FeatureKey } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp } from '@src/myContext';

import { useStyles } from './darkModeToggleStyles';
import { ToggleIcon } from './ToggleIcon';

export type DarkModeToggleProps = ComponentProps<
	{
		isDarkMode: boolean;
		setIsDarkMode: Dispatch<SetStateAction<boolean>>;
	},
	typeof useStyles
>;

export const DarkModeToggle = ({
	classes: classesProp,
	isDarkMode,
	setIsDarkMode,
	'data-pp-at-target': targetId,
}: DarkModeToggleProps) => {
	const { getAppFeature } = useMyApp();
	const isDarkModeSupported = getAppFeature(FeatureKey.AppDarkMode)?.enabled;
	const { translate } = useTranslation('appDesign');
	const classes = useStyles(classesProp, { isDarkModeSupported });
	return (
		<Tooltip
			classes={{ root: classes.tooltips }}
			content={translate('preview.darkModeToggle.unavailable')}
			data-pp-at-target={`${targetId}-darkMode-toggle-tooltip`}
			disabled={isDarkModeSupported}
			placement="bottom"
		>
			<div className={classes.root} data-pp-at-target={targetId}>
				<p className={classes.toggleLabel} data-pp-at-target={`${targetId}-label`}>
					{translate('preview.darkModeToggle.label')}
				</p>
				<Checkbox
					classes={{
						root: classes.checkboxRoot,
						wrapper: classes.checkboxWrapper,
					}}
					data-pp-at-target={`${targetId}-checkbox`}
					disabled={!isDarkModeSupported}
					id={`${targetId}-checkbox`}
					onChange={() => {
						setIsDarkMode(darkMode => !darkMode);
					}}
				>
					<div className={clsx(classes.switch, isDarkMode && classes.switchDark)}>
						<ToggleIcon
							className={clsx(classes.toggleIcon, isDarkMode && classes.toggleIconDark)}
							data-pp-at-target={`${targetId}-icon`}
							isDarkMode={isDarkMode}
							title={isDarkMode ? 'Use Light Mode' : 'Use Dark Mode'}
						/>
					</div>
				</Checkbox>
			</div>
		</Tooltip>
	);
};
