import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { useTranslation } from '@src/i18n';

import { AttributeTypename } from '../draggable';
import { AttributeCard } from './AttributeCard';
import { Campus } from './contents';

type CampusAttributeProps = {
	campusId: string;
	onCampusSelect?: (id: string) => void;
	onCampusDelete?: () => void;
};
const useStyle = createUseStyles((theme: Theme) => ({
	campus: {
		marginTop: theme.SPACING.SMALL,
	},
}));

export const CampusAttribute = ({
	campusId,
	onCampusSelect = () => {},
	onCampusDelete = () => {},
}: CampusAttributeProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyle(undefined);
	if (!campusId) {
		return null;
	}

	return (
		<AttributeCard
			attributeType={AttributeTypename.Campus}
			data-pp-at-target="campus-attribute"
			icon="custom-campus"
			title={translate('attribute.campus.label')}
			onDelete={onCampusDelete}
		>
			<div className={classes.campus}>
				<Campus campusId={campusId} onCampusSelect={onCampusSelect} />
			</div>
		</AttributeCard>
	);
};
