import { useEffect, memo, useRef, useState } from 'react';

import { Button } from '@pushpay/button';
import { ExpandStatus } from '@pushpay/expand';
import { InfoIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { ExpandableCard } from '@src/components/expandableCard';
import { useImageFieldContext } from '@src/context';
import { useTranslation } from '@src/i18n';
import { useEvent } from '@src/utils';

import { FileDropArea, ImagePreview, ImageUrlInput } from './components';
import { fileSizeLimit } from './components/shared';
import { acceptedFileTypes } from './components/shared/utils';

type ImageUploaderProps = {
	imageUrl?: string | File;
	inputExpandStatus?: ExpandStatus;
	onImageSelect?: (image: string | File | undefined) => void;
	maxFileSize?: number;
	readOnly?: boolean;
	isDisabled?: boolean;
	fieldLabel?: string;
};

const useStyles = createUseStyles((theme: Theme) => ({
	main: {
		paddingTop: 0,
	},
	removeButton: {
		color: theme.colors['productBrand-500'],
		font: theme.typography.button,

		'&:disabled': {
			opacity: 0.4,
			cursor: 'wait',
		},
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		font: theme.typography['text-4'],
	},
	titleTooltip: {
		marginLeft: theme.SPACING.XXSMALL,
		zIndex: 1,
	},
}));

export const ImageUploader = memo(
	({
		imageUrl: imageUrlProp,
		inputExpandStatus: inputExpandStatusProp = 'collapsed',
		onImageSelect,
		maxFileSize = fileSizeLimit,
		readOnly = false,
		isDisabled = false,
		fieldLabel,
		'data-pp-at-target': targetId,
	}: ComponentProps<ImageUploaderProps, undefined>) => {
		const [selectedFile, setSelectedFile] = useState(imageUrlProp);

		const classes = useStyles(undefined);
		const { translate } = useTranslation('appDesign');
		const { isProcessing } = useImageFieldContext();
		const mounted = useRef(false);

		const cardTitle = fieldLabel ?? translate('imageUploader.label');
		const acceptedFileTypesJoined = acceptedFileTypes.join(', ').toUpperCase();

		const syncSelectedFile = useEvent(() => {
			if (selectedFile !== imageUrlProp) {
				onImageSelect?.(selectedFile);
			}
		});

		useEffect(() => {
			if (!mounted.current) {
				mounted.current = true;
			} else {
				syncSelectedFile();
			}
		}, [syncSelectedFile, selectedFile]);

		useEffect(() => {
			setSelectedFile(imageUrlProp);
		}, [imageUrlProp]);

		const handleSelectedImageFile = (imageFile: string | File) => {
			setSelectedFile(imageFile);
		};

		return (
			<ExpandableCard
				actions={
					!readOnly && selectedFile ? (
						<Button
							className={classes.removeButton}
							disabled={isProcessing}
							type="button"
							onClick={() => setSelectedFile(undefined)}
						>
							{translate('imageUploader.removeImageButton')}
						</Button>
					) : null
				}
				classes={{ body: classes.main }}
				data-pp-at-target={targetId}
				initialExpandStatus="preexpanded"
				title={
					<div className={classes.title}>
						{cardTitle}
						<Tooltip
							classes={{ root: classes.titleTooltip }}
							content={translate('imageUploader.imageUploadTooltip', {
								acceptedFileTypes: acceptedFileTypesJoined,
							})}
							data-pp-at-target={`${targetId}-toggle-tooltip`}
							panelSpace="XXSMALL"
							placement="top"
						>
							<InfoIcon data-pp-at-target={`${targetId}-tip-icon`} />
						</Tooltip>
					</div>
				}
			>
				{!readOnly && !selectedFile && !isProcessing ? (
					<FileDropArea
						data-pp-at-target={targetId}
						disableUpload={isDisabled}
						inputExpandStatus={inputExpandStatusProp}
						maxFileSize={maxFileSize}
						urlInput={<ImageUrlInput disableUpload={isDisabled} onApplyImage={handleSelectedImageFile} />}
						onFileSelect={handleSelectedImageFile}
					/>
				) : null}
				<ImagePreview image={selectedFile} />
			</ExpandableCard>
		);
	}
);
