import { RecursivePartial } from '@pushpay/types';

import { ContainerFeed, ContainerType as ContainerSettingsQueryContainerType } from '@src/components/attributes/types';
import { convertToJson } from '@src/components/attributes/utils';
import { AttributeTypename } from '@src/components/draggable/types';
import { DEFAULT_FEATURED_LABEL } from '@src/components/formFields/FeaturedLabelField';
import {
	CardType,
	ContainerType,
	FeedProperty,
	GetContainerSettingsQuery,
	NavigationAction,
	RssFeed,
	RssFeedType,
	SaveContainerSettingsSchema,
	VimeoFeed,
	VimeoIdentifierType,
} from '@src/graphql/generated';

import { MAX_DHS_ITEMS } from '../components/ContainerCardDefinition';
import { ContainerSettings } from '../types';

const RssFeedAttributeTypeName = ['RssFeed', 'VideoFeed', 'PodcastFeed'];

const rssFeedTypeMap = new Map<AttributeTypename, RssFeedType>([
	[AttributeTypename.PodcastFeed, RssFeedType.Podcast],
	[AttributeTypename.RssFeed, RssFeedType.News],
	[AttributeTypename.VideoFeed, RssFeedType.Video],
]);

export function generateContainerSettingsFormInitialValues(
	containerSettings:
		| NonNullable<NonNullable<GetContainerSettingsQuery['organization']>['application']>['container']
		| null,
	applicationId: string
): RecursivePartial<SaveContainerSettingsSchema['input']> {
	let initialCardDefinition = {
		cardType: containerSettings?.type === ContainerType.Events ? CardType.Event : CardType.Default,
		maxItems: MAX_DHS_ITEMS,
		featuredLabel: DEFAULT_FEATURED_LABEL,
	};
	if (containerSettings?.cardDefinition) {
		initialCardDefinition = {
			...containerSettings?.cardDefinition,
			maxItems:
				containerSettings?.cardDefinition?.maxItems === -1
					? MAX_DHS_ITEMS
					: containerSettings?.cardDefinition?.maxItems,
			featuredLabel: containerSettings?.cardDefinition?.featuredCard?.label || DEFAULT_FEATURED_LABEL,
		};
	}

	return {
		...containerSettings,
		givingLink: containerSettings?.givingLink || '',
		cardDefinition: initialCardDefinition,
		applicationId,
		parentContainerId: containerSettings?.parentContainer?.id,
		containerId: containerSettings?.id,
		navigationAction: containerSettings?.navigationAction || NavigationAction.Unknown,
		campusId: containerSettings?.campus?.id,
		imageUrl: containerSettings?.image?.urls?.original || containerSettings?.image?.urls?.unprocessedImageUrl,
		...getFeedInitialData(containerSettings?.feed),
	};
}

export function getFeedInitialData(feed: ContainerFeed) {
	const feedType = feed?.__typename;
	return {
		youtubeFeed:
			feedType === 'YouTubeFeed'
				? {
						url: feed?.url || null,
						properties: generateProperties(feed?.properties),
				  }
				: null,
		rssFeed:
			feedType && RssFeedAttributeTypeName.includes(feedType)
				? {
						url: feed?.url || null,
						properties: generateProperties(feed?.properties),
						type: rssFeedTypeMap.get(feed?.__typename as AttributeTypename),
				  }
				: null,
		icalFeed:
			feedType === 'IcalFeed'
				? {
						url: feed?.url || null,
						properties: generateProperties(feed?.properties),
				  }
				: null,
		vimeoFeed:
			feedType === 'VimeoFeed'
				? {
						properties: generateProperties(feed?.properties),
						identifier: getFeed<VimeoFeed>(feed)?.identifiers?.type as VimeoIdentifierType,
						channelId: getFeed<VimeoFeed>(feed)?.identifiers?.channelId || '',
						userId: getFeed<VimeoFeed>(feed)?.identifiers?.userId || '',
						groupId: getFeed<VimeoFeed>(feed)?.identifiers?.groupId || '',
						showcaseId: getFeed<VimeoFeed>(feed)?.identifiers?.showcaseId || '',
				  }
				: null,
	};
}

export function generateProperties(
	properties: NonNullable<NonNullable<ContainerSettingsQueryContainerType>['feed']>['properties']
): string {
	return convertToJson(convertToItemTemplateRaw(properties), ['__typename', 'itemTemplateRaw']);
}

function getFeed<T extends VimeoFeed | RssFeed>(feed: ContainerFeed) {
	return feed && (feed as T);
}

export const generateContainerSettingsFormFieldVisibility = (containerSettings: ContainerSettings) => {
	const isFeedContainer = containerSettings?.feed !== null;
	const hasChildren = !!containerSettings?.children?.nodes?.length;
	const hasCardType = containerSettings?.cardDefinition?.cardType !== undefined;
	const canHaveCardDefinition = isFeedContainer && hasChildren && hasCardType;

	return {
		cardDefinition: canHaveCardDefinition,
		vimeoFeed: containerSettings?.feed?.__typename === 'VimeoFeed',
		rssFeed:
			(containerSettings?.feed?.__typename &&
				RssFeedAttributeTypeName.includes(containerSettings?.feed?.__typename)) ??
			false,
		youtubeFeed: containerSettings?.feed?.__typename === 'YouTubeFeed',
		icalFeed: containerSettings?.feed?.__typename === 'IcalFeed',
	};
};

function convertToItemTemplateRaw(properties?: FeedProperty | null) {
	if (!properties) {
		return properties;
	}
	return {
		...properties,
		itemTemplate: properties.itemTemplateRaw,
	};
}
