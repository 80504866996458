import { useMemo } from 'react';

import { OrgSwitcher, OrganizationDetails } from '@pushpay/shell';
import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { AccountType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyIdentity, useMyOrganizations } from '@src/myContext';
import { getAppDesignPath } from '@src/pages/shell';
import { useParams } from '@src/router';

const useStyles = createUseStyles({
	root: {},
	options: {
		'& > a': {
			whiteSpace: 'initial',
		},
	},
});

export function OrgSelector({
	classes: classesProp,
	className: classNameProp,
	'data-pp-at-target': targetId,
}: ComponentProps<unknown, typeof useStyles>) {
	const classes = useStyles(classesProp);
	const className = clsx(classes.root, classNameProp);
	const organizations = useMyOrganizations();
	const { administeredOrganizations, accountType } = useMyIdentity();
	const organizationLogoMap: Map<string, string> = useMemo(
		() => new Map(administeredOrganizations?.map(org => [org.key, org.logoUrl])),
		[administeredOrganizations]
	);
	const organizationDetails: OrganizationDetails[] = useMemo(
		() =>
			organizations
				.filter(org => org.organization?.name)
				.map(org => ({
					// Pushpay Admins can search Organizations by ECG ID
					...(accountType === AccountType.PushpayAdmin &&
						org.organization?.ecgId && { ecgId: org.organization.ecgId }),
					key: org.organizationKey,
					name: org.organization?.name as string,
					logoUrl: organizationLogoMap.get(org.organizationKey) as string,
					to: getAppDesignPath(org.organizationKey),
				})),
		[accountType, organizationLogoMap, organizations]
	);
	const { organizationKey } = useParams<'organizationKey'>();
	const { translate } = useTranslation();

	return (
		<OrgSwitcher
			buttonLabel={translate('organizationSwitcher.switchOrganization')}
			classes={{
				options: classes.options,
			}}
			className={className}
			clearButtonAriaLabel={translate('organizationSwitcher.clearSearch')}
			currentOrgKey={organizationKey}
			data-pp-at-target={targetId}
			organizations={organizationDetails}
			placeholder={translate('organizationSwitcher.searchPlaceholder')}
			selectOrganizationLabel={translate('organizationSwitcher.selectOrganization')}
		/>
	);
}
