import { RefObject, useCallback } from 'react';

import { Field } from '@pushpay/forms';

import { ImageField, ImageFieldRef } from '@src/components/formFields';
import { useGetItemImageQuery } from '@src/graphql/generated';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { usePolling } from '@src/utils';

import { ItemSettings } from '../types';

type ItemImageFieldProps = {
	field: Field<string>;
	image: NonNullable<ItemSettings>['image'];
	imageFieldRef: RefObject<ImageFieldRef>;
	itemId: string;
	parentContainerId: string;
	readOnly?: boolean;
	onImageProcessEnd: () => void;
	isDisabled?: boolean;
};

export function ItemImageField({
	field,
	image,
	imageFieldRef,
	itemId,
	parentContainerId,
	readOnly,
	onImageProcessEnd,
	isDisabled,
}: ItemImageFieldProps) {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();

	const { unprocessedImageUrl, original } = image?.urls ?? {};
	const isProcessingImage = !!unprocessedImageUrl && !original;

	const { startPolling, stopPolling } = useGetItemImageQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId: parentContainerId,
			itemId,
		},
		fetchPolicy: 'network-only',
		skip: !isProcessingImage,
	});

	const onPollEnd = useCallback(() => {
		if (original) {
			imageFieldRef.current?.resetField(original);
			onImageProcessEnd();
		}
	}, [imageFieldRef, onImageProcessEnd, original]);

	usePolling({
		inProgress: isProcessingImage,
		startPolling,
		stopPolling,
		onPollEnd,
	});

	return (
		<ImageField
			ref={imageFieldRef}
			field={field}
			imageType="item image"
			isDisabled={isDisabled}
			isProcessing={isProcessingImage}
			readOnly={readOnly}
		/>
	);
}
