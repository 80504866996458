import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { useTranslation } from '@src/i18n';

import { SelectedFile } from '../shared';
import { Thumbnail } from '../thumbnail';

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		paddingTop: '1px',

		'& > :not(:last-child)': {
			borderRight: `1px solid ${theme.colors['common-white']}`,
		},
	},
	label: {
		display: 'inline-block',
		font: theme.typography['text-4-semiBold'],
		color: theme.colors['text-default'],
		paddingBottom: theme.SPACING.XSMALL,
	},
}));

export const ImagePreview = ({ image }: { image?: SelectedFile }) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');

	return (
		<>
			{!image ? <span className={classes.label}>{translate('imageUploader.preview.label')}</span> : null}
			<div className={classes.container}>
				<Thumbnail
					image={image}
					recommendedHeight={576}
					recommendedWidth={576}
					title={translate('imageUploader.preview.square')}
					tooltipContent={translate('imageUploader.preview.squareTooltip')}
					type="square"
					hasPlaceholder
				/>
				<Thumbnail
					image={image}
					recommendedHeight={576}
					recommendedWidth={1024}
					title={translate('imageUploader.preview.16x9')}
					tooltipContent={translate('imageUploader.preview.16x9Tooltip')}
					type="16x9"
					hasPlaceholder
				/>
				<Thumbnail
					image={image}
					recommendedHeight={422}
					recommendedWidth={1024}
					title={translate('imageUploader.preview.panorama')}
					tooltipContent={translate('imageUploader.preview.panoramaTooltip')}
					type="panorama"
					hasPlaceholder
				/>
			</div>
		</>
	);
};
