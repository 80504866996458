import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	nameFieldWrapper: {
		margin: 0,
	},
	expandableCardBody: {
		paddingTop: 0,
	},
	expandableCardTitle: {
		font: theme.typography['heading-3'],
	},
	section: {
		paddingTop: theme.SPACING.SMALL,
	},
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
		color: theme.colors['text-default'],
	},
	floatingBar: {
		padding: `${theme.SPACING.SMALL} ${theme.SPACING.MEDIUM}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '900px',
		backgroundColor: theme.colors['background-surface'],
	},
	form: {
		paddingBottom: '100px',
		position: 'relative',
		isolation: 'isolate',
	},
	disabled: {
		opacity: '0.5',
		cursor: 'not-allowed',
	},
	tile: {
		paddingTop: theme.SPACING.SMALL,
	},
	stackItem: {
		paddingTop: theme.SPACING.SMALL,
	},
}));
