import { FormState, useMutateField, Field } from '@pushpay/forms';
import { RadioGroup } from '@pushpay/inputs';
import { Columns, Column } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { KeyMetricsPropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, LabelWrapper, TextFieldWrapper } from '../wrappers';

const useStyles = createUseStyles({
	noMargin: {
		margin: 0,
	},
	noPadding: {
		padding: 0,
	},
});

export type KeyMetricsProps = ComponentProps<
	{
		propertyField: FormState<KeyMetricsPropertyInput>;
	},
	undefined
>;

export const KeyMetrics = ({ propertyField, 'data-pp-at-target': targetId }: KeyMetricsProps) => {
	const { metrics, metricsCount: metricsCountField } = propertyField;
	const { mutateField } = useMutateField(metricsCountField);
	const metricsCount = metricsCountField?.value;

	const { translate } = useTranslation('appDesign');
	const classes = useStyles(undefined);

	const metricItems = [
		{ label: translate('keyMetrics.one'), value: 1 },
		{ label: translate('keyMetrics.two'), value: 2 },
		{ label: translate('keyMetrics.three'), value: 3 },
	];

	return (
		<ContentWrapper data-pp-at-target={targetId}>
			<LabelWrapper label={translate('keyMetrics.label')}>
				<RadioGroup
					groupName="group2"
					items={metricItems}
					radioStyle="Button"
					value={metricsCount}
					onChange={value => mutateField(value)}
				/>
			</LabelWrapper>
			<Columns classes={{ root: classes.noMargin }} space="SMALL" stackWhen="NEVER">
				{Array.from(Array(metricsCount)).map((_, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<Column key={index.toString()} classes={{ inner: index === 0 ? classes.noPadding : undefined }}>
						<ContentWrapper>
							<TextFieldWrapper
								aria-label={translate('keyMetrics.titleLabel')}
								data-pp-at-target={`${targetId}-title`}
								field={metrics[index]?.value as Field<string>}
								id={index.toString()}
								label={translate('keyMetrics.titleLabel')}
								labelPosition="top"
								showLabel
							/>
							<TextFieldWrapper
								aria-label={translate('keyMetrics.descriptionLabel')}
								data-pp-at-target={`${targetId}-description`}
								field={metrics[index]?.title as Field<string>}
								id={index.toString()}
								label={translate('keyMetrics.descriptionLabel')}
								labelPosition="top"
								showLabel
							/>
						</ContentWrapper>
					</Column>
				))}
			</Columns>
		</ContentWrapper>
	);
};
