import { useEffect, useState } from 'react';

import { FeatureFlag } from '../../settings/featureFlags';
import { featureFlagManager } from './featureFlagManager';

export function useFeature(featureName: FeatureFlag) {
	const [featureValue, setFeatureValue] = useState(featureFlagManager.computedFeatureValue(featureName));

	useEffect(
		function listenToFeatureFlagValueChanges() {
			return featureFlagManager.listen(() =>
				setFeatureValue(featureFlagManager.computedFeatureValue(featureName))
			);
		},
		[featureName]
	);

	return featureValue;
}
