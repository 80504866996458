import { PropsWithChildren, useState, useLayoutEffect } from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { animated } from '@react-spring/web';

import { Button } from '@pushpay/button';
import { InfoIcon, LoadingOvalIcon } from '@pushpay/iconography';
import { clsx } from '@pushpay/styles';
import { useMediaBreakpoint } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { XOR } from '@pushpay/types';

import { CarouselFile } from '@src/components/carouselUploader/types';
import { Icon as IconComponent } from '@src/components/icon';
import { useImageFieldContext } from '@src/context';
import { useTranslation } from '@src/i18n';

import { getResizedImage, getTemplateWidth, getTemplateHeight, Image, SelectedFile, loadImage } from '../shared';
import { RemoveButton } from './RemoveButton';
import { useStyles } from './thumbnailStyles';

export type ThumbnailProps = {
	title: string;
	tooltipContent?: string;
	recommendedWidth: number;
	recommendedHeight: number;
	type: 'square' | '16x9' | 'panorama';
	hasPlaceholder?: boolean;
	onDelete?: () => void;
	showTitle?: boolean;
} & XOR<
	{
		image?: SelectedFile;
	},
	{
		image: CarouselFile;
		onClickRemove: () => void;
	}
>;

type ClassesProp = ReturnType<typeof useStyles>;

function ImageCropOverlays({
	image,
	templateProportion,
	classes,
}: {
	image: Image;
	templateProportion: number;
	classes: ClassesProp;
}) {
	const { width: originalWidth, height: originalHeight } = image;
	const imageProportion = originalWidth / originalHeight;
	const croppedImageWidth =
		imageProportion > templateProportion ? originalHeight * templateProportion : originalWidth;
	const croppedImageHeight =
		imageProportion > templateProportion ? originalHeight : originalWidth / templateProportion;

	if (originalWidth > croppedImageWidth) {
		const cropRectWidth = (originalWidth - croppedImageWidth) / 2;
		const cropRectHeight = originalHeight;

		return cropRectWidth > 0 ? (
			<>
				<div
					className={clsx(classes.imageCrop, classes.imageCropLeft)}
					style={{
						width: cropRectWidth,
						height: cropRectHeight,
					}}
				/>
				<div
					className={clsx(classes.imageCrop, classes.imageCropRight)}
					style={{
						width: cropRectWidth,
						height: cropRectHeight,
					}}
				/>
			</>
		) : null;
	}

	const cropRectWidth = originalWidth;
	const cropRectHeight = (originalHeight - croppedImageHeight) / 2;

	return cropRectHeight > 0 ? (
		<>
			<div
				className={clsx(classes.imageCrop, classes.imageCropTop)}
				style={{
					width: cropRectWidth,
					height: cropRectHeight,
				}}
			/>
			<div
				className={clsx(classes.imageCrop, classes.imageCropBottom)}
				style={{
					width: cropRectWidth,
					height: cropRectHeight,
				}}
			/>
		</>
	) : null;
}

function ThumbnailWrapper({
	title,
	tooltipContent,
	classes,
	children,
	showTitle,
}: PropsWithChildren<{ title: string; tooltipContent: string | undefined; classes: ClassesProp; showTitle: boolean }>) {
	return (
		<div className={classes.card}>
			{showTitle && (
				<span className={classes.textWrapper}>
					<span>{title} </span>
					{tooltipContent && (
						<Tooltip
							classes={{ tooltip: classes.tooltip }}
							content={tooltipContent}
							panelSpace="XSMALL"
							placement="top"
						>
							<InfoIcon className={classes.infoIcon} />
						</Tooltip>
					)}
				</span>
			)}
			{children}
		</div>
	);
}

function AnimatedWrapper({
	title,
	recommendedWidth,
	recommendedHeight,
	image,
	children,
}: PropsWithChildren<Omit<ThumbnailProps, 'image'> & { image: CarouselFile }>) {
	const isTablet = useMediaBreakpoint('DESKTOP_AND_BELOW');
	const classes = useStyles(undefined, {
		isTablet,
		templateWidth: getTemplateWidth(isTablet),
		templateHeight: getTemplateHeight(isTablet),
	});

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: image.id as UniqueIdentifier,
		data: {
			title,
			recommendedHeight,
			recommendedWidth,
			image,
		},
	});

	const style = {
		transform: CSS.Transform.toString(
			transform !== null
				? {
						...transform,
						y: 0,
				  }
				: null
		),
		transition,
	};

	return (
		<animated.div
			{...attributes}
			{...listeners}
			ref={setNodeRef}
			className={clsx(classes.card, classes.overlay, isDragging && classes.dragging)}
			style={style}
		>
			{children}
		</animated.div>
	);
}

export function Thumbnail(props: ThumbnailProps) {
	const {
		title,
		tooltipContent,
		recommendedWidth,
		recommendedHeight,
		image,
		type,
		hasPlaceholder = false,
		onDelete,
		onClickRemove,
		showTitle = true,
	} = props;
	const isTablet = useMediaBreakpoint('DESKTOP_AND_BELOW');
	const classes = useStyles(undefined, {
		isTablet,
		templateWidth: getTemplateWidth(isTablet),
		templateHeight: getTemplateHeight(isTablet),
	});
	const { translate } = useTranslation('appDesign');
	const [thumbnailImage, setThumbnailImage] = useState<Image | null>(null);
	const { isProcessing } = useImageFieldContext();

	useLayoutEffect(() => {
		loadImage({
			image: onClickRemove ? image.file : image,
			onLoad: loadedImage => setThumbnailImage(getResizedImage(loadedImage, isTablet)),
		});

		if (!image) {
			setThumbnailImage(null);
		}
	}, [image, isTablet, onClickRemove]);

	const loadingSpinner = isProcessing ? (
		<span className={classes.spinner}>
			<LoadingOvalIcon fontSize={40} />
		</span>
	) : null;

	const ThumbnailItem = () => (
		<ThumbnailWrapper classes={classes} showTitle={showTitle} title={title} tooltipContent={tooltipContent}>
			{!image && hasPlaceholder ? (
				<div className={clsx(classes.templateContainer, isProcessing && classes.loading)}>
					<div className={classes.template}>
						<div className={clsx(classes.templateOverlay, classes[type])}>
							<span>{`${recommendedWidth} x ${recommendedHeight}`}</span>
							<span>{translate('imageUploader.preview.recommended')}</span>
						</div>
					</div>
					{loadingSpinner}
				</div>
			) : (
				<div className={classes.imageContainer}>
					{thumbnailImage ? (
						<>
							<div className={clsx(classes.imageWrapper, isProcessing && classes.loading)}>
								<img
									alt={title}
									height={thumbnailImage.height}
									src={thumbnailImage.url}
									width={thumbnailImage.width}
								/>
								<ImageCropOverlays
									classes={classes}
									image={thumbnailImage}
									templateProportion={recommendedWidth / recommendedHeight}
								/>
								{loadingSpinner}
							</div>
							{onDelete && (
								<Button className={classes.removeButton} type="button" onClick={onDelete}>
									<IconComponent classes={{ root: classes.removeIcon }} name="delete-3" />
									{translate('imageUploader.removeImageButton')}
								</Button>
							)}
						</>
					) : null}
				</div>
			)}
		</ThumbnailWrapper>
	);

	return onClickRemove ? (
		<AnimatedWrapper {...props}>
			<ThumbnailItem />
			<RemoveButton onClickRemove={() => onClickRemove()} />
		</AnimatedWrapper>
	) : (
		<ThumbnailItem />
	);
}
