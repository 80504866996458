import { IconProps } from '@pushpay/icon';
import { ServiceUnavailableIllustration as PushpayServiceUnavailableIllustration } from '@pushpay/illustration';
import { createUseStyles } from '@pushpay/styles';

import { illustrationStyles } from './illustrationStyles';

const useStyles = createUseStyles({
	...illustrationStyles,
});

export const ServiceUnavailableIllustration = (props: IconProps) => {
	const classes = useStyles(undefined);

	return (
		<PushpayServiceUnavailableIllustration
			className={classes.illustration}
			data-pp-at-target="service-unavaialable-illustration"
			{...props}
		/>
	);
};
