import { useCallback, useState } from 'react';

import unfetch from 'unfetch';

import { PresignedPost, useGenerateFileUploadPostMutation } from '@src/graphql/generated';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

type UseFileUploadMutationCallbacks = {
	updateField?: (val: any) => void;
};

export function useFileUploadMutation({ updateField = () => undefined }: UseFileUploadMutationCallbacks = {}) {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();

	const [selectedFile, setSelectedFile] = useState<string | File>();
	const [fileFormData, setFileFormData] = useState<FormData>();
	const [generateFileUploadPost] = useGenerateFileUploadPostMutation();

	const buildFormData = (presignedPost: PresignedPost | undefined | null, file: File) => {
		const formData = new FormData();

		if (presignedPost) {
			formData.append('key', presignedPost.key);
			formData.append('acl', presignedPost.acl);
			formData.append('Tagging', presignedPost.tagging);
			formData.append('X-Amz-Algorithm', presignedPost.algorithm);
			formData.append('X-Amz-Credential', presignedPost.credential);
			formData.append('X-Amz-Date', presignedPost.date);
			formData.append('Policy', presignedPost.policy);
			formData.append('X-Amz-Signature', presignedPost.signature);
			formData.append('X-Amz-Security-Token', presignedPost.token);
			formData.append('file', file);
			formData.append('url', presignedPost.url);
		}

		return formData;
	};

	const onFileSelect = useCallback(
		async (file: string | File | undefined) => {
			if (file === undefined || typeof file === 'string') {
				setSelectedFile(file);
				setFileFormData(undefined);
				updateField(file);
				return;
			}

			const result = await generateFileUploadPost({
				variables: {
					organizationKey,
					platformCampusKey,
					input: {
						applicationId,
					},
				},
			});

			if (result.errors !== undefined) {
				return;
			}

			const presignedPost = result.data?.generateFileUploadPost?.presignedPost;
			const formData = buildFormData(presignedPost, file);

			setSelectedFile(file);
			setFileFormData(formData);
			updateField(result.data?.generateFileUploadPost?.unprocessedImageUrl);
		},
		[generateFileUploadPost, organizationKey, platformCampusKey, applicationId, updateField]
	);

	const uploadFile = useCallback(async () => {
		let success = true;

		if (fileFormData) {
			const uploadUrl = fileFormData.get('url');

			if (typeof uploadUrl === 'string') {
				const response = await unfetch(uploadUrl, { method: 'POST', body: fileFormData });
				success = response.ok;
			}

			if (success) {
				setFileFormData(undefined);
			}
		}

		return success;
	}, [fileFormData]);

	return {
		selectedFile,
		setSelectedFile,
		onFileSelect,
		uploadFile,
	};
}
