/* @refresh reset */
import { FC, PropsWithChildren } from 'react';

import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	createHttpLink,
	ErrorPolicy,
	FetchPolicy,
	split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { createAuthLink, createRemoveClientDirectivesLink } from '@pushpay/apollo-links';
import { useAuthenticated } from '@pushpay/auth';

import { isTestWidgetEnabled } from '@src/components/testWidget';
import { getAppSettings, insertIf } from '@src/utils';

import { useCache } from '../cache';
import { GetApplicationPublishStatusDocument } from '../generated';
import { useApolloClientErrorHandlers } from './errors';
import { createWebSocketLink, useCustomScalarTypeLink, createTestWidgetLink } from './links';

const defaultPolicy = {
	fetchPolicy: 'no-cache' as Extract<FetchPolicy, 'no-cache'>,
	errorPolicy: 'all' as ErrorPolicy,
};

export const defaultMutateRefetchQueries = [GetApplicationPublishStatusDocument];

function useAppStudioClient() {
	const { schemaUrl, subscriptionUrl } = getAppSettings();
	const { getToken } = useAuthenticated();
	const abortController = new AbortController();
	const cache = useCache();

	return new ApolloClient({
		cache,
		defaultOptions: {
			query: {
				...defaultPolicy,
			},
			watchQuery: {
				...defaultPolicy,
			},
			mutate: {
				refetchQueries: defaultMutateRefetchQueries,
			},
		},
		link: ApolloLink.from([
			createRemoveClientDirectivesLink(['form']),
			useCustomScalarTypeLink(cache),
			useApolloClientErrorHandlers(),
			...insertIf(isTestWidgetEnabled(), createTestWidgetLink()),
			split(
				({ query }) => {
					const definition = getMainDefinition(query);
					return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
				},
				createWebSocketLink({ uri: subscriptionUrl, getToken }),
				createAuthLink({ getToken }).concat(
					createHttpLink({
						uri: schemaUrl,
						// https://github.com/apollographql/apollo-client/issues/6769 not abort the request
						fetchOptions: {
							signal: abortController.signal,
						},
					})
				)
			),
		]),
	});
}

export const apolloClient: { client?: ReturnType<typeof useAppStudioClient> } = {};

export const AppStudioApolloProvider: FC<PropsWithChildren> = ({ children }) => {
	const client = useAppStudioClient();
	apolloClient.client = client;

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
