import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@pushpay/button';
import { useTheme } from '@pushpay/theming';

import { Trans, useTranslation } from '@src/i18n';

export const SUCCESSFUL_KEY = 'attribute.processingCompleteMessage.text';
export const FAILED_KEY = 'attribute.processingFailedMessage.text';

type KeyType = typeof SUCCESSFUL_KEY | typeof FAILED_KEY;

type NotificationContentProps = {
	translationKey: KeyType;
	linkPath: string;
};

export const NotificationContent = ({ translationKey, linkPath }: NotificationContentProps) => {
	const { translate } = useTranslation('appDesign');
	const navigate = useNavigate();
	const theme = useTheme();
	const [searchParams] = useSearchParams();

	const text = translate(translationKey);
	const title = translate(translationKey);

	return (
		<Trans i18nKey={translationKey} namespace="appDesign">
			{title}
			<Button
				style={{ color: theme.colors['palette-blue'] }}
				type="button"
				onClick={() => {
					navigate({
						pathname: linkPath,
						search: searchParams.toString(),
					});
				}}
			>
				{text}
			</Button>
		</Trans>
	);
};
