import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		height: '40px',
		borderLeft: `1px solid ${theme.colors['grey-200']}`,
	},
}));

export function Divider({ classes: classesProp, className: classNameProp }: ComponentProps<unknown, typeof useStyles>) {
	const classes = useStyles(classesProp);
	const className = clsx(classes.root, classNameProp);
	return <div className={className} />;
}
