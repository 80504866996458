import { ReactNode } from 'react';

import { useFieldVisibility } from '@pushpay/forms';
import { RecurringPaymentIcon, WarningIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Tag } from '@pushpay/tag';
import { Theme } from '@pushpay/theming';

import { AttributeTypename } from '@src/components/draggable';
import { useFeature } from '@src/featureFlags';
import { FeedProcessState } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { AttributeCard } from './AttributeCard';
import { CalendarFeedFields, RssFeedFields, VimeoFeedFields, YouTubeFeedFields } from './contents';
import { AttributeFields, ContainerFeed } from './types';

type FeedAttributeProps = {
	inputFields: Omit<AttributeFields, 'campusId'>;
	enableDownloadButton: boolean;
	feed: ContainerFeed;
	onFeedDelete?: (feedType: AttributeTypename) => void;
	processFeed?: () => void;
};

const useStyles = createUseStyles((theme: Theme) => ({
	processStatus: {
		display: 'flex',
		alignItems: 'center',
		color: theme.colors['text-default'],
		fontSize: '16px',
		fontWeight: 'normal',
	},
	processStatusIcon: {
		marginLeft: theme.SPACING.XXSMALL,
		fontSize: '22px',
		animation: '$spin 2s linear infinite',
	},

	'@keyframes spin': {
		'0%': {
			transform: `scaleX(-1) rotate(0deg)`,
		},
		'100%': {
			transform: 'scaleX(-1) rotate(-360deg)',
		},
	},
	errorIcon: {
		marginRight: theme.SPACING.ICON_TO_TEXT_ERROR,
		flexShrink: 0,
	},
}));

export const FeedAttribute = ({
	onFeedDelete,
	inputFields,
	processFeed,
	feed,
	enableDownloadButton,
}: FeedAttributeProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const isInAppCalendarEnabled = useFeature('InAppCalendar');
	const { youtubeFeed, rssFeed, icalFeed, vimeoFeed } = inputFields;

	const { visible: youtubeFeedVisible } = useFieldVisibility(youtubeFeed);
	const { visible: vimeoFeedVisible } = useFieldVisibility(vimeoFeed);
	const { visible: rssFeedVisible } = useFieldVisibility(rssFeed);
	const { visible: icalFeedVisible } = useFieldVisibility(icalFeed);

	const onAttributeDelete = () => {
		if (onFeedDelete) {
			if (icalFeedVisible) {
				onFeedDelete(AttributeTypename.IcalFeed);
			}
			if (youtubeFeedVisible) {
				onFeedDelete(AttributeTypename.YouTubeFeed);
			}
			if (rssFeedVisible) {
				onFeedDelete(AttributeTypename.RssFeed);
			}
			if (vimeoFeedVisible) {
				onFeedDelete(AttributeTypename.VimeoFeed);
			}
		}
	};
	const extraTitle = isInAppCalendarEnabled ? getExtraTitle(feed) : getLegacyExtraTitle();

	if (youtubeFeedVisible) {
		return (
			<AttributeCard
				attributeType={AttributeTypename.YouTubeFeed}
				data-pp-at-target="feed-attribute"
				extraTitle={extraTitle}
				icon="custom-youtube"
				title={translate('attribute.youtube.label')}
				onDelete={onAttributeDelete}
			>
				<YouTubeFeedFields
					enableDownloadButton={enableDownloadButton}
					feed={feed}
					processFeed={processFeed}
					youTubeFields={youtubeFeed}
				/>
			</AttributeCard>
		);
	}
	if (icalFeedVisible) {
		return (
			<AttributeCard
				attributeType={AttributeTypename.IcalFeed}
				data-pp-at-target="ical-feed-attribute"
				extraTitle={extraTitle}
				icon="custom-events-feed"
				title={translate('attribute.ical.label')}
				onDelete={onAttributeDelete}
			>
				<CalendarFeedFields
					enableDownloadButton={enableDownloadButton}
					feed={feed}
					iCalFeedFields={icalFeed}
					processFeed={processFeed}
				/>
			</AttributeCard>
		);
	}
	if (vimeoFeedVisible) {
		return (
			<AttributeCard
				attributeType={AttributeTypename.VimeoFeed}
				data-pp-at-target="feed-attribute"
				extraTitle={extraTitle}
				icon="custom-vimeo"
				title={translate('attribute.vimeo.label')}
				onDelete={onAttributeDelete}
			>
				<VimeoFeedFields
					enableDownloadButton={enableDownloadButton}
					feed={feed}
					processFeed={processFeed}
					vimeoFields={vimeoFeed}
				/>
			</AttributeCard>
		);
	}

	if (rssFeedVisible) {
		return (
			<AttributeCard
				attributeType={AttributeTypename.RssFeed}
				data-pp-at-target="rss-feed-attribute"
				extraTitle={extraTitle}
				icon="rss-1"
				title={translate('attribute.rss.label')}
				onDelete={onAttributeDelete}
			>
				<RssFeedFields
					enableDownloadButton={enableDownloadButton}
					feed={feed}
					processFeed={processFeed}
					rssFeedFields={rssFeed}
				/>
			</AttributeCard>
		);
	}
	return null;

	function getLegacyExtraTitle() {
		if (!feed) {
			return null;
		}
		if ([FeedProcessState.Processing, FeedProcessState.Requested].includes(feed.processState)) {
			return (
				<div className={classes.processStatus}>
					<span>{`${translate('attribute.processState.processing')}...`}</span>
					<RecurringPaymentIcon className={classes.processStatusIcon} />
				</div>
			);
		}
		if (feed.processState === FeedProcessState.Complete) {
			return (
				<div className={classes.processStatus}>{translate('attribute.processingCompleteMessage.title')}</div>
			);
		}
		return null;
	}

	function getExtraTitle(containerFeed?: ContainerFeed) {
		if (!containerFeed) return null;

		let message: ReactNode | null;
		const feedProcessState: FeedProcessState = containerFeed.processState;

		switch (feedProcessState) {
			case FeedProcessState.Processing:
			case FeedProcessState.Requested: {
				message = (
					<>
						<span>{`${translate('attribute.processState.processing')}...`}</span>
						<RecurringPaymentIcon className={classes.processStatusIcon} />
					</>
				);
				break;
			}
			case FeedProcessState.Failed: {
				message = (
					<Tag
						displayStyle="error"
						icon={() => <WarningIcon className={classes.errorIcon} />}
						label={translate('attribute.processingFailedMessage.tag')}
					/>
				);
				break;
			}
			default: {
				message = null;
			}
		}

		return message ? <div className={classes.processStatus}>{message}</div> : null;
	}
};
