import React, { useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical';

import { BlankifyNode, insertBlankify, removeBlankify } from '@src/components/textEditor/nodes/BlankifyNode';

export const INSERT_BLANKIFY_COMMAND: LexicalCommand<null> = createCommand('INSERT_BLANKIFY_COMMAND');
export const REMOVE_BLANKIFY_COMMAND: LexicalCommand<null> = createCommand('REMOVE_BLANKIFY_COMMAND');

export function BlankifyPlugin(): React.JSX.Element | null {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor.hasNodes([BlankifyNode])) {
			throw new Error('BlankifyPlugin: BlankifyNode not registered on editor');
		}

		return mergeRegister(
			editor.registerCommand(
				INSERT_BLANKIFY_COMMAND,
				() => {
					insertBlankify();
					return false;
				},
				COMMAND_PRIORITY_EDITOR
			),
			editor.registerCommand(
				REMOVE_BLANKIFY_COMMAND,
				() => {
					removeBlankify();
					return true;
				},
				COMMAND_PRIORITY_EDITOR
			)
		);
	}, [editor]);

	return null;
}
