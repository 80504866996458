import { ActionButton } from '@pushpay/button';
import { FloatingBar } from '@pushpay/floating-bar';
import { CrossIcon } from '@pushpay/iconography';

import { useTranslation } from '@src/i18n';

import { SnackBarButton } from './SnackBarButton';
import { useStyles } from './snackBarStyle';
import { SnackBarActionProps } from './types';

export type SnackBarComponentProps = {
	isVisible: boolean;
	onClose: () => void;
	maxItemsToSelect: number;
	totalSelected: number;
	copyAction: SnackBarActionProps;
	moveAction: SnackBarActionProps;
	deleteAction?: SnackBarActionProps;
};

export const SnackBar = ({
	isVisible,
	onClose: onCloseHandler,
	maxItemsToSelect,
	totalSelected,
	copyAction,
	moveAction,
	deleteAction,
}: SnackBarComponentProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');

	return (
		<FloatingBar className={classes.floatingBar} sticky={false} visible={isVisible}>
			<div className={classes.selectedText}>
				<span>{translate('snackBarItems.selected', { maxItemsToSelect, totalSelected })}</span>
			</div>
			<div className={classes.buttons}>
				<SnackBarButton action={copyAction} type="copy" />
				<SnackBarButton action={moveAction} type="move" />
				{deleteAction ? <SnackBarButton action={deleteAction} type="delete" /> : null}
			</div>
			<ActionButton
				data-pp-at-target="snack-bar-close-button"
				displayStyle="secondary"
				icon={CrossIcon}
				onClick={onCloseHandler}
			/>
		</FloatingBar>
	);
};
