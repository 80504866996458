import { FormState } from '@pushpay/forms';
import { InfoIcon } from '@pushpay/iconography';
import { Select } from '@pushpay/inputs';
import { clsx } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';

import { Icon } from '@src/components/icon';
import {
	CardType,
	DataMap,
	GetItemPropertiesQuery,
	PropertyType,
	SaveContainerSettingsSchema,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { useStyle } from './dataMappingStyle';
import { HomeCardDetailFieldType } from './type';
import { useDataMapField } from './useDataMapField';
import { getHomeCardDetailFields } from './utils';

type DataMappingFieldProps = {
	field: FormState<SaveContainerSettingsSchema>['input']['cardDefinition']['dataMap'];
	itemProperties: NonNullable<
		NonNullable<
			NonNullable<NonNullable<GetItemPropertiesQuery['organization']>['application']>['container']
		>['item']
	>['properties'];
	cardType?: CardType | null;
};

const containsDataProperty = [
	'DefaultProperty',
	'TextProperty',
	'VideoProperty',
	'AudioProperty',
	'WebsiteProperty',
	'AppLinkProperty',
	'BlankifyProperty',
	'SmsProperty',
	'TextHtmlProperty',
] as Array<string | undefined>;

function getDataMapFieldValues(
	field: FormState<SaveContainerSettingsSchema>['input']['cardDefinition']['dataMap']
): Record<string, Pick<DataMap, 'position' | 'type'>> {
	return Object.keys(field).reduce(
		(acc, cur) => ({
			...acc,
			[cur]: {
				position: field[cur as keyof typeof field].position.value,
				type: field[cur as keyof typeof field].type.value,
			},
		}),
		{}
	);
}

export const DataMappingField = ({ cardType = CardType.Default, itemProperties, field }: DataMappingFieldProps) => {
	const classes = useStyle(undefined);
	const { translate } = useTranslation('appDesign');
	const { mutateDataMapPosition, mutateDataMapType } = useDataMapField(field);
	const feedLabelOptions = itemProperties.map(({ header, id }) => ({
		display: header ?? '',
		exampleData: '',
		value: id,
	}));

	const homeCardDetailFieldNameMap = {
		subtitle: translate('settings.container.dhs.dataMapping.subTitle'),
		summary: translate('settings.container.dhs.dataMapping.summary'),
		publishedTime: translate('settings.container.dhs.dataMapping.publishedTime'),
		audioUrl: translate('settings.container.dhs.dataMapping.audio'),
		videoUrl: translate('settings.container.dhs.dataMapping.video'),
	};
	const dataMap = getDataMapFieldValues(field);
	const homeCardDetailItems = getHomeCardDetailFields(cardType).map(homeCardDetailField => {
		const targetProperty = itemProperties.find(
			property =>
				property.position === dataMap?.[homeCardDetailField]?.position &&
				property.__typename?.toUpperCase() === `${dataMap?.[homeCardDetailField]?.type.toUpperCase()}PROPERTY`
		);
		let exampleData;
		if (containsDataProperty.includes(targetProperty?.__typename)) {
			exampleData = (targetProperty as { data: string }).data;
		}

		const selectedPropertyId = targetProperty?.id || '';
		if (selectedPropertyId && !exampleData) {
			exampleData = 'No data';
		}
		return {
			homeCardDetailField,
			selectedPropertyId,
			exampleData,
		};
	});
	const onPropertySelectHandler = (propertyId: string, cardField: HomeCardDetailFieldType) => {
		if (propertyId === '') {
			mutateDataMapType(cardField, null);
			mutateDataMapPosition(cardField, null);
		}
		const selectedProperty = itemProperties.find(property => property.id === propertyId);
		if (!selectedProperty) {
			return;
		}
		const { position, __typename } = selectedProperty;
		mutateDataMapType(cardField, __typename?.toUpperCase().replace('PROPERTY', '') as PropertyType);
		mutateDataMapPosition(cardField, position);
	};

	return (
		<div className={classes.root}>
			<div className={classes.label}>
				<p>{translate('settings.container.dhs.dataMapping.label')}</p>
				<Tooltip
					classes={{ root: classes.labelTooltip }}
					content={translate('settings.container.dhs.dataMapping.toolTip')}
					placement="top"
				>
					<InfoIcon />
				</Tooltip>
			</div>
			<div className={classes.header}>
				<p className={classes.homeCardDetail}>
					{translate('settings.container.dhs.dataMapping.homeCardDetail')}
				</p>
				<p className={classes.feedLabel}>{translate('settings.container.dhs.dataMapping.feedLabel')}</p>
				<p className={classes.example}>{translate('settings.container.dhs.dataMapping.exampleData')}</p>
			</div>

			{homeCardDetailItems.map(({ homeCardDetailField, selectedPropertyId, exampleData }) => (
				<div
					key={homeCardDetailField}
					className={classes.content}
					data-pp-at-target={`${homeCardDetailField}-item`}
				>
					<p className={classes.homeCardDetail}>{homeCardDetailFieldNameMap[homeCardDetailField]}</p>
					<div className={clsx(classes.feedLabelContent, classes.feedLabel)}>
						<Icon
							classes={{
								root: clsx({
									[classes.iconLinked]: selectedPropertyId,
									[classes.iconUnLinked]: !selectedPropertyId,
								}),
							}}
							name={selectedPropertyId ? 'link-1' : 'link-broken-2'}
						/>
						<Select
							classes={{ root: classes.feedLabelSelect }}
							id="test"
							options={[
								{
									display: `-${translate('settings.container.dhs.dataMapping.feedLabelNotLinked')}-`,
									value: '',
								},
							].concat(feedLabelOptions)}
							value={selectedPropertyId}
							onChange={selectedValue => {
								onPropertySelectHandler(selectedValue, homeCardDetailField);
							}}
						/>
					</div>
					<p className={classes.example} data-pp-at-target={`${homeCardDetailField}-item-example-data`}>
						{exampleData}
					</p>
				</div>
			))}
		</div>
	);
};
