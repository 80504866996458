import { FormState } from '@pushpay/forms';

import { CarouselPropertyInput } from '@src/graphql/generated';

export const CarouselFileUploadType = {
	URL: 'url',
	File: 'file',
} as const;

type CarouselFileUploadType = typeof CarouselFileUploadType;

export type CarouselFile =
	| {
			id: string;
			type: CarouselFileUploadType['URL'];
			file: string;
	  }
	| {
			id: string;
			type: CarouselFileUploadType['File'];
			file: File;
	  };

export type CarouselImagesDynamicFields = FormState<CarouselPropertyInput>['images'];

export type CarouselImagesField = FormState<CarouselPropertyInput>['images'][number];
