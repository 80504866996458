import { useCallback, useRef } from 'react';

import { matchPath } from 'react-router-dom';

import { loader as containerChildrenLoader } from '@src/pages/containerChildren';
import { loader as containerSettingsLoader } from '@src/pages/containerSettings';
import { loader as itemSettingsLoader } from '@src/pages/itemSettings';
// import { ROUTE_PATHS } from '@src/router'; this way is not working on cypress test.
import * as ROUTE_PATHS from '@src/router/routePaths';

const DATA_LOADER_ENTRIES = Object.entries({
	[ROUTE_PATHS.CONTAINER_CHILDREN]: containerChildrenLoader,
	[ROUTE_PATHS.CONTAINER_SETTINGS]: containerSettingsLoader,
	[ROUTE_PATHS.CONTAINER_ITEM]: itemSettingsLoader,
});

const LOADER_DELAY = 125; // ms

type DataLoaderParams = {
	applicationId: string;
	platformCampusKey?: string | null;
	campusId: string | null;
};

export function useDataLoader({ applicationId, platformCampusKey, campusId }: DataLoaderParams) {
	const timerRef = useRef<number | null>(null);

	const start = useCallback(
		(to: string | undefined, onFinish: () => void) => {
			timerRef.current = window.setTimeout(() => {
				if (!to) return;

				DATA_LOADER_ENTRIES.forEach(([path, loader]) => {
					const fullPath = [ROUTE_PATHS.ORGANIZATION_ROOT, ROUTE_PATHS.APP, path].join('/');
					const match = matchPath(
						fullPath,
						to.split('?')[0] // to remove query strings
					);

					if (match) {
						loader({ ...(match.params as any), platformCampusKey, applicationId, campusId });
					}
				});

				onFinish();
			}, LOADER_DELAY);
		},
		[applicationId, platformCampusKey, campusId]
	);

	const cancel = useCallback(() => {
		if (timerRef.current) {
			window.clearTimeout(timerRef.current);
			timerRef.current = null;
		}
	}, []);

	return [start, cancel] as const;
}
