import { FC, PropsWithChildren, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export const ScrollToTop: FC<PropsWithChildren> = ({ children }) => {
	const { pathname, search } = useLocation();

	useEffect(() => {
		// reset scroll position to top when URL and/or its query string changes
		document.querySelectorAll('[data-scrollable]').forEach(elem => elem.scrollTo(0, 0));
	}, [pathname, search]);

	return <>{children}</>;
};
