import { deepEqual } from '@pushpay/utils';

import { ItemTemplate, ContainerTemplate } from '@src/graphql/generated';

export const containerLayouts = [
	ContainerTemplate.List_72Dp,
	ContainerTemplate.List_72DpThumb,
	ContainerTemplate.List_90DpThumb,
	ContainerTemplate.List_132DpPhoto,
] as const;

export const itemLayouts = [
	ItemTemplate.DetailPlain,
	ItemTemplate.DetailSquare,
	ItemTemplate.DetailFull,
	ItemTemplate.DetailWebview,
] as const;

export type ContainerLayoutType = typeof containerLayouts;

export type ItemLayoutType = typeof itemLayouts;

export type LayoutKeys = ContainerLayoutType[number] | ItemLayoutType[number];

export const templates = {
	// Container templates
	[ContainerTemplate.List_72Dp]: {
		layoutType: ContainerTemplate.List_72Dp as const,
		options: {
			Icon: false,
			Distance: false,
		},
	},
	[ContainerTemplate.List_72DpIcon]: {
		layoutType: ContainerTemplate.List_72Dp as const,
		options: {
			Icon: true,
			Distance: false,
		},
	},
	[ContainerTemplate.List_72DpTidbit]: {
		layoutType: ContainerTemplate.List_72Dp as const,
		options: {
			Icon: false,
			Distance: true,
		},
	},
	[ContainerTemplate.List_72DpIconTidbit]: {
		layoutType: ContainerTemplate.List_72Dp as const,
		options: {
			Icon: true,
			Distance: true,
		},
	},
	[ContainerTemplate.List_72DpThumb]: {
		layoutType: ContainerTemplate.List_72DpThumb as const,
		options: null,
	},
	[ContainerTemplate.List_90DpThumb]: {
		layoutType: ContainerTemplate.List_90DpThumb as const,
		options: null,
	},
	[ContainerTemplate.List_132DpPhoto]: {
		layoutType: ContainerTemplate.List_132DpPhoto as const,
		options: { Label: true },
	},
	[ContainerTemplate.List_132DpPhotoNoLabel]: {
		layoutType: ContainerTemplate.List_132DpPhoto as const,
		options: { Label: false },
	},
	[ContainerTemplate.List_44DpTidbit]: {
		layoutType: ContainerTemplate.List_72Dp as const,
		options: {
			Icon: false,
			Distance: true,
		},
	},
	[ContainerTemplate.Unknown]: {
		layoutType: null,
		options: null,
	},
	// Item templates
	[ItemTemplate.DetailPlain]: {
		layoutType: ItemTemplate.DetailPlain as const,
		options: null,
	},
	[ItemTemplate.DetailSquare]: {
		layoutType: ItemTemplate.DetailSquare as const,
		options: null,
	},
	[ItemTemplate.DetailFull]: {
		layoutType: ItemTemplate.DetailFull as const,
		options: {
			Label: true,
			Overlay: true,
		},
	},
	[ItemTemplate.DetailFullNoLabel]: {
		layoutType: ItemTemplate.DetailFull as const,
		options: {
			Label: false,
			Overlay: false,
		},
	},
	[ItemTemplate.DetailFullNoOverlay]: {
		layoutType: ItemTemplate.DetailFull as const,
		options: {
			Label: true,
			Overlay: false,
		},
	},
	[ItemTemplate.DetailWebview]: {
		layoutType: ItemTemplate.DetailWebview as const,
		options: null,
	},
	[ItemTemplate.Unknown]: {
		layoutType: null,
		options: null,
	},
};

export type TemplatesType = keyof typeof templates;

export type Options = {
	Label?: boolean;
	Overlay?: boolean;
	Icon?: boolean;
	Distance?: boolean;
} | null;

export function getItemLayout(template: TemplatesType, hasAudioVideoProperty: boolean) {
	const { layouts, layoutType, layoutOptions } = getLayoutByTemplate(template, 'item');

	if (hasAudioVideoProperty) return { layouts, layoutType, layoutOptions: null };

	return { layouts, layoutType, layoutOptions };
}

export function getLayoutByTemplate(
	template: TemplatesType,
	type: 'container' | 'item'
): {
	layouts: ContainerLayoutType | ItemLayoutType;
	layoutType: LayoutKeys | null;
	layoutOptions: Options;
} {
	if (type === 'container') {
		return {
			layouts: containerLayouts,
			layoutType: templates[template]?.layoutType ?? null,
			layoutOptions: templates[template]?.options ?? null,
		};
	}

	return {
		layouts: itemLayouts,
		layoutType: templates[template]?.layoutType ?? null,
		layoutOptions: templates[template]?.options ?? null,
	};
}

export function getTemplateByLayout(layout: LayoutKeys, options: Options): TemplatesType {
	return (
		(Object.keys(templates) as Array<TemplatesType>).find(
			key => templates[key].layoutType === layout && deepEqual(templates[key].options as Options, options)
		) ?? layout
	);
}
