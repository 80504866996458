import { useTranslation } from '@src/i18n';

import { ServiceUnavailableIllustration } from '../illustrations';
import { BaseError } from './BaseError';

const targetId = 'page-not-found-error';

export const PageNotFoundError = () => {
	const { translate } = useTranslation();

	return (
		<BaseError
			data-pp-at-target={targetId}
			illustration={ServiceUnavailableIllustration}
			text={translate('errors.text.pageNotFoundError')}
			title={translate('errors.title.pageNotFoundError')}
			withGoBackLink
		/>
	);
};
