import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	card: {
		border: `1px solid ${theme.colors['grey-400']}`,
		borderRadius: '6px',
	},
	title: {
		font: theme.typography['heading-4'],
		height: '24px',
		lineHeight: '24px',
		marginBottom: theme.SPACING.XSMALL,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '5px 6px',
		height: '54px',
		borderRadius: '6px',
		backgroundColor: theme.colors['background-page'],
	},
	body: {
		padding: theme.SPACING.SMALL,
		marginTop: '1px',
		borderTop: `1px solid ${theme.colors['grey-400']}`,
	},
	toggle: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		font: theme.typography['text-4'],
		color: theme.colors['text-default'],
		marginRight: multiply(theme.SPACING.XSMALL, 1.5),
	},
	switch: {
		marginLeft: theme.SPACING.SMALL,
		minWidth: '48px',
	},
	switchRoot: {
		padding: '10px',
	},
	icon: {
		height: '14px',
		width: '14px',
		marginLeft: theme.SPACING.XXSMALL,
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	notificationWrapper: {
		position: 'relative',
		paddingBottom: theme.SPACING.SMALL,
	},
}));

export default useStyles;
