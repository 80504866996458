import { InMemoryCache } from '@apollo/client';

import introspectionResult from '../fragments/fragmentTypes.generated';
import { ContainerChild, TypedTypePolicies } from '../generated';
import { mergePagingOutputByField } from './policies';

export function useCache() {
	const typePolicies: TypedTypePolicies = {
		Organization: {
			// [] indicates that keyFields are immutable. Organization will be cached as a singleton
			// This means we do not need to include the organizationKey field on every query but the cache should be torn down when switching Orgs
			keyFields: [],
		},
		Container: {
			fields: {
				children: {
					keyArgs: false,
				},
				feed: {
					merge: true,
				},
			},
		},
		Application: {
			fields: {
				dynamicHomeScreen: {
					merge: true,
				},
			},
		},
		Identity: {
			keyFields: ['identityKey'],
			merge: true,
		},
		CursorPagedContainerChildren: {
			merge: mergePagingOutputByField<ContainerChild>('id'),
		},
		FeedProperty: {
			merge: true,
		},
		Image: {
			merge: true,
		},
	};

	return new InMemoryCache({
		typePolicies,
		possibleTypes: introspectionResult.possibleTypes,
	});
}
