import {
	createContext,
	PropsWithChildren,
	RefObject,
	MutableRefObject,
	useCallback,
	useContext,
	useEffect,
	useRef,
} from 'react';

import { useParams } from 'react-router-dom';

import { useDebounce } from '@pushpay/utils';

type ScrollToLastPagePositionContext = {
	containerRef?: RefObject<HTMLElement>;
	scrollToTop: () => void;
	scrollToLastPagePosition: (pageName: string) => void;
	pagesPosition: MutableRefObject<Record<string, number>>;
};

const ScrollToLastPagePositionContext = createContext<ScrollToLastPagePositionContext | null>(null);

export function ScrollToLastPagePositionContextProvider({
	children,
	containerRef,
}: PropsWithChildren<{
	containerRef?: RefObject<HTMLElement>;
}>) {
	const pagesPosition = useRef<Record<string, number>>({});

	const scrollToTop = useCallback(() => {
		if (containerRef?.current) {
			containerRef.current.scrollTo({ top: 0 });
		}
	}, [containerRef]);

	const scrollToLastPagePosition = useCallback(
		(pageName: string) => {
			if (containerRef?.current) {
				containerRef.current.scrollTo({ top: pagesPosition.current[pageName] ?? 0 });
			}
		},
		[containerRef, pagesPosition]
	);

	return (
		<ScrollToLastPagePositionContext.Provider
			value={{
				containerRef,
				scrollToTop,
				pagesPosition,
				scrollToLastPagePosition,
			}}
		>
			{children}
		</ScrollToLastPagePositionContext.Provider>
	);
}

export const useScrollToLastPagePositionContext = () => {
	const context = useContext(ScrollToLastPagePositionContext);
	if (!context) {
		throw new Error(
			'useScrollToLastPagePositionContext must be used within a ScrollToLastPagePositionContextProvider'
		);
	}
	return context;
};

export type PagePosition = 'top' | 'lastPosition';

export const useScrollToPagePosition = (pageName: string, position: PagePosition = 'top') => {
	const { containerRef, pagesPosition, scrollToTop, scrollToLastPagePosition } = useScrollToLastPagePositionContext();
	const { containerId } = useParams<'containerId'>();
	const pagePositionKey = containerId ? `${pageName}:${containerId}` : pageName;

	const [updatePosition] = useDebounce(() => {
		pagesPosition.current[pagePositionKey] = containerRef?.current?.scrollTop ?? 0;
	}, 100);

	useEffect(() => {
		const currentRef = containerRef?.current;
		if (position === 'top') {
			scrollToTop();
		}
		if (position === 'lastPosition') {
			scrollToLastPagePosition(pagePositionKey);
		}

		if (currentRef) {
			currentRef.addEventListener('scroll', updatePosition);
		}
		return () => {
			if (currentRef) {
				currentRef.removeEventListener('scroll', updatePosition);
			}
		};
	}, [containerRef, pagePositionKey, position, updatePosition, scrollToLastPagePosition, scrollToTop]);

	const clearPagesPosition = useCallback(() => {
		pagesPosition.current = {};
	}, [pagesPosition]);

	return { clearPagesPosition };
};
