import { useId, useState, useCallback } from 'react';

import { useMutateField } from '@pushpay/forms';
import { Checkbox } from '@pushpay/inputs';
import { Column, Columns } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { ExpandableAttributes } from '@src/components/expandableAttributes';
import { PropertyIconField } from '@src/components/formFields';
import { ItemPropertyField } from '@src/components/properties/types';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, LabelWrapper, TextFieldWrapper } from '../wrappers';

const useStyles = createUseStyles({
	noMargin: {
		margin: 0,
	},
	noPadding: {
		padding: 0,
	},
});

export type DefaultProps = ComponentProps<
	{
		propertyField: ItemPropertyField;
		isDisabled: boolean;
	},
	typeof undefined
>;

export const AdvancedSettings = ({ propertyField, isDisabled, 'data-pp-at-target': targetId }: DefaultProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyles(undefined);
	const [expanded, setExpanded] = useState(false);

	const {
		baseProperty: { header: headerField, isHidden: isHiddenField, icon: iconField },
	} = propertyField;

	const { value: isHidden } = isHiddenField;

	const { mutateField: mutateIsHiddenField } = useMutateField(isHiddenField);

	const handleIsHiddenChange = useCallback(
		(value: boolean) => {
			mutateIsHiddenField(value);
		},
		[mutateIsHiddenField]
	);

	return (
		<ExpandableAttributes
			data-pp-at-target={targetId}
			disabled={isDisabled}
			expand={expanded && !isDisabled}
			toggleName={translate('advancedSettings.label')}
			tooltipContent={translate('advancedSettings.tooltip')}
			onExpandChange={setExpanded}
		>
			<ContentWrapper>
				<Columns classes={{ root: classes.noMargin }} space="SMALL" stackWhen="NEVER">
					<Column classes={{ inner: classes.noPadding }}>
						<PropertyIconField field={iconField} />
					</Column>
					<Column>
						<TextFieldWrapper
							aria-label={translate('advancedSettings.propertyLabel')}
							data-pp-at-target={`${targetId}-property-label`}
							field={headerField}
							id={`property-label-${propertyField.baseProperty.position.value}`}
							label={translate('advancedSettings.propertyLabel')}
							labelPosition="top"
							showLabel
						/>
					</Column>
				</Columns>
				<LabelWrapper>
					<Checkbox checked={isHidden} id={useId()} onChange={handleIsHiddenChange}>
						{translate('advancedSettings.hidePropertyRow')}
					</Checkbox>
				</LabelWrapper>
			</ContentWrapper>
		</ExpandableAttributes>
	);
};
