import React from 'react';

import { useDroppable } from '@dnd-kit/core';

import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		flex: '1',
		minHeight: '60px',
	},
	dropIndicator: {
		borderTop: `4px solid ${theme.colors['blue-500']}`,
	},
}));

export const dropNewItemAreaId = 'dropNewItemArea';

type NewItemDroppableAreaProps = ComponentProps<
	{ parentId: string; children?: React.ReactNode },
	typeof useStyles,
	undefined
>;

export function NewItemDroppableArea({ parentId, children }: NewItemDroppableAreaProps) {
	const { setNodeRef, isOver, active } = useDroppable({
		id: dropNewItemAreaId,
		data: {
			parentId,
		},
	});
	const classes = useStyles(undefined);
	const newItem = !active?.data.current?.sortable;
	return (
		<div ref={setNodeRef} className={clsx(classes.root, { [classes.dropIndicator]: isOver && newItem })}>
			{children}
		</div>
	);
}
