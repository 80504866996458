import { useCallback } from 'react';

import { FormState, useMutateField } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import {
	getLayoutByTemplate,
	getTemplateByLayout,
	LayoutKeys,
	Options,
	TemplatesType,
} from '@src/components/layoutSettings/layoutDefinition';
import { LayoutSettingsComponent } from '@src/components/layoutSettings/LayoutSettingsComponent';
import { ContainerTemplate, ContainerType, SaveContainerSettingsSchema } from '@src/graphql/generated';
import { ContainerSettings } from '@src/pages/containerSettings/types';

export type ContainerSettingsProps = ComponentProps<
	{
		container: ContainerSettings;
		templateField: FormState<SaveContainerSettingsSchema['input']['template']>;
		readonly?: boolean;
	},
	typeof undefined
>;

export const ContainerLayoutSettings = ({ templateField, container, readonly }: ContainerSettingsProps) => {
	const { mutateField: mutateTemplate } = useMutateField(templateField);
	const childItems = container?.children.nodes.map(node => {
		if (node.__typename === 'ChildItem') {
			return node;
		}
		return null;
	});

	const hasYoutubeVideo = childItems?.some(child =>
		child?.item.properties.some(
			property =>
				property.__typename === 'VideoProperty' &&
				(property.header?.match(/(youtube)/i) || property.url?.match(/(youtube|youtu\.be)/i))
		)
	);

	const isYouTube = container?.feed?.__typename === 'YouTubeFeed' || hasYoutubeVideo;

	const onLayoutChange = useCallback(
		(selectedLayout: LayoutKeys, options: Options) => {
			mutateTemplate(getTemplateByLayout(selectedLayout, options) as ContainerTemplate);
		},
		[mutateTemplate]
	);
	if (!templateField.value) {
		return null;
	}
	const { layouts, layoutType, layoutOptions } = getLayoutByTemplate(
		templateField.value as TemplatesType,
		'container'
	);

	return (
		<LayoutSettingsComponent
			data-pp-at-target="layout-settings"
			isPlaceTemplate={container?.type === ContainerType.Places}
			isYouTube={isYouTube}
			layoutOptions={layoutOptions}
			layouts={layouts}
			layoutType={layoutType}
			readonly={readonly}
			isContainer
			onLayoutChange={onLayoutChange}
		/>
	);
};
