import { Field, TextField } from '@pushpay/forms';
import { LoadingOvalIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';

import { IconButton } from '@src/components/buttons';
import { Icon } from '@src/components/icon';
import { FeedProcessState } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n/translation';

const useStyle = createUseStyles((theme: Theme) => ({
	propertyFieldWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	propertyField: {
		flex: '1 1 auto',
	},
	fetchFeedIcon: {
		color: theme.colors['productBrand-500'],
		margin: '56px 6px 6px 6px',
	},
	textField: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
	},
}));

export type FeedCommonFieldsProps = {
	urlField?: Field<string>;
	propertiesField: Field<string>;
	processFeed?: () => void;
	enableDownloadButton: boolean;
	feedProcessState?: FeedProcessState;
};

export const FeedCommonFields = ({
	urlField,
	propertiesField,
	processFeed,
	enableDownloadButton,
	feedProcessState,
}: FeedCommonFieldsProps) => {
	const classes = useStyle(undefined);
	const { translate } = useTranslation('appDesign');
	const { translate: translateCommon } = useTranslation('common');
	const properties = propertiesField.value;
	let validProperties = true;
	try {
		if (properties !== '') {
			JSON.parse(properties);
		}
	} catch (e) {
		validProperties = false;
	}
	const isFeedProcessInProcessing = [FeedProcessState.Processing, FeedProcessState.Requested].includes(
		feedProcessState ?? FeedProcessState.Unknown
	);
	const enableProcessFeedButton = enableDownloadButton && !isFeedProcessInProcessing;
	return (
		<>
			{urlField && (
				<TextField
					classes={{
						label: classes.textField,
					}}
					field={urlField}
					label={translate('attribute.url')}
					labelPosition="top"
					showLabel
				/>
			)}

			<div className={classes.propertyFieldWrapper}>
				<TextField
					classes={{ wrapper: classes.propertyField, label: classes.textField }}
					customValidationFailureMessage={
						validProperties ? undefined : translateCommon('errors.propertiesFormatError')
					}
					field={propertiesField}
					label={translate('attribute.properties')}
					labelPosition="top"
					showLabel
				/>
				<IconButton
					aria-label={translate('attribute.processFeedButtonAriaLabel')}
					className={classes.fetchFeedIcon}
					disabled={!enableProcessFeedButton}
					displayStyle="text"
					icon={
						isFeedProcessInProcessing ? (
							<LoadingOvalIcon data-pp-at-target="feed-processing-spinner" fontSize={24} />
						) : (
							<Tooltip
								content={translate('attribute.processFeedButtonTooltip')}
								disabled={enableProcessFeedButton}
								placement="top"
							>
								<Icon name="cloud-download" />
							</Tooltip>
						)
					}
					onClick={processFeed}
				/>
			</div>
		</>
	);
};
