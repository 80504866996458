import { useCallback } from 'react';

import { Button } from '@pushpay/button';
import { DropdownDirection } from '@pushpay/dropdown';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { VisibilityToggle } from '@src/components/kebabMenu/kebabMenuItems/VisibilityToggle';
import { Link } from '@src/components/link';
import { useTranslation } from '@src/i18n';

import { CardType } from '../card';
import useStyles from './kebabMenuStyles';

export type KebabMenuProps = ComponentProps<
	{
		type: CardType;
		direction?: DropdownDirection;
		toggleHide?: (isHidden: boolean) => void;
		isHidden: boolean;
		isEditable: boolean;
		isDeletable?: boolean;
		isFeedItem?: boolean;
		hasInvalidTimeframeProperties?: boolean;
		hasNoTimeframeProperty?: boolean;
		rootOnlyChildName?: string;
		allowCopyMove?: boolean;
		editPath?: string;
		hideVisibilityToggle?: boolean;
		setIsModalOpen: (isOpen: boolean) => void;
		copyItems?: () => void;
		moveChild?: () => void;
	},
	typeof useStyles
>;

export type KebabMenuItemType = 'delete' | 'disabled' | 'common';

type KebabMenuItemProps = {
	label: string;
	type?: KebabMenuItemType;
	tooltipContent?: string;
	onClick?: () => void;
};

export const KebabMenuItem = (props: KebabMenuItemProps) => {
	const { label, type = 'common', tooltipContent, onClick, ...rest } = props;
	const classes = useStyles(undefined, { type });

	const button = (
		<Button
			className={classes.option}
			data-pp-at-target={`${label}-button`}
			disabled={type === 'disabled'}
			type="button"
			onClick={onClick}
			onKeyDown={ev => ev.stopPropagation()}
			{...rest}
		>
			<div className={classes.optionText}>{label}</div>
		</Button>
	);

	return tooltipContent ? (
		<Tooltip content={tooltipContent} placement="right">
			{button}
		</Tooltip>
	) : (
		button
	);
};

export const KebabMenu = ({
	copyItems,
	moveChild,
	type,
	direction,
	toggleHide,
	isHidden,
	isEditable,
	isDeletable = true,
	isFeedItem = false,
	rootOnlyChildName = '',
	allowCopyMove = true,
	editPath = '',
	hideVisibilityToggle = false,
	setIsModalOpen,
	classes: classesProp,
	'data-pp-at-target': targetId,
	hasInvalidTimeframeProperties = false,
	hasNoTimeframeProperty = false,
}: KebabMenuProps) => {
	const classes = useStyles(classesProp, {
		direction,
	});
	const { translate } = useTranslation('appDesign');
	const { translate: commonTranslate } = useTranslation('common');
	const isDeleteButtonDisabled = isFeedItem || !isDeletable;
	const deleteDisableText = isFeedItem
		? translate('kebabMenuItems.feedDeleteDisabledTooltip')
		: translate('kebabMenuItems.subContainerDeleteDisabledTooltip');

	const onVisibleItemClick = () => {
		if (toggleHide) {
			toggleHide(!isHidden);
		}
	};

	const translatedCardType: Record<CardType, string> = {
		item: translate('cardType.item'),
		ccb: translate('cardType.ccbModule'),
		container: translate('cardType.container'),
		resi: translate('cardType.container'),
	};

	const visibilityToggleLabel = translate(
		hasNoTimeframeProperty || hasInvalidTimeframeProperties || isHidden
			? 'kebabMenuItems.show'
			: 'kebabMenuItems.hide',
		{ type: translatedCardType[type] }
	);

	const getVisibilityToggleTooltipContent = useCallback(() => {
		if (hasNoTimeframeProperty) {
			return translate('tag.tooltip.noTimeframeProperty');
		}
		if (hasInvalidTimeframeProperties) {
			return translate('tag.tooltip.invalidTimeframeProperty');
		}

		return '';
	}, [hasNoTimeframeProperty, hasInvalidTimeframeProperties, translate]);

	const isVisibilityToggleDisabled = hasNoTimeframeProperty || hasInvalidTimeframeProperties;

	return (
		<div className={classes.kebabMenu}>
			{isEditable && (
				<Link data-pp-at-target={`${targetId}-edit-link`} displayStyle="unstyled" tabIndex={-1} to={editPath}>
					<KebabMenuItem label={translate('kebabMenuItems.edit', { type: translatedCardType[type] })} />
				</Link>
			)}
			{!hideVisibilityToggle && (
				<VisibilityToggle
					isDisabled={isVisibilityToggleDisabled}
					label={visibilityToggleLabel}
					targetId={targetId}
					tooltipLabel={getVisibilityToggleTooltipContent()}
					onClick={onVisibleItemClick}
				/>
			)}
			{allowCopyMove ? (
				<>
					{type === 'item' ? (
						<KebabMenuItem label={translate('kebabMenuItems.copy')} onClick={copyItems} />
					) : null}
					{rootOnlyChildName ? (
						<KebabMenuItem
							label={translate('kebabMenuItems.move')}
							tooltipContent={commonTranslate('copyAndMove.errors.rootChildMoveTooltip', {
								draggableName: rootOnlyChildName,
							})}
							type="disabled"
						/>
					) : (
						<KebabMenuItem label={translate('kebabMenuItems.move')} onClick={moveChild} />
					)}
				</>
			) : null}
			<KebabMenuItem
				label={translate('kebabMenuItems.delete')}
				tooltipContent={isDeleteButtonDisabled ? deleteDisableText : undefined}
				type={isDeleteButtonDisabled ? 'disabled' : 'delete'}
				onClick={() => setIsModalOpen(true)}
			/>
		</div>
	);
};
