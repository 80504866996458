import { useFieldVisibility, useMutateField } from '@pushpay/forms';

import { RssFeedType, VimeoIdentifierType } from '@src/graphql/generated';

import { AttributeTypename } from '../draggable';
import { AttributeFields } from './types';
import { convertToJson } from './utils';

export function useFeedMutateFields(inputFields: Omit<AttributeFields, 'campusId'>) {
	const { youtubeFeed, rssFeed, icalFeed, vimeoFeed } = inputFields;
	const { mutateField: mutateYoutubeFeedUrl } = useMutateField(youtubeFeed.url);
	const { mutateField: mutateYoutubeFeedProperties } = useMutateField(youtubeFeed.properties);
	const { mutateField: mutateRssFeedUrl } = useMutateField(rssFeed.url);
	const { mutateField: mutateRssFeedProperties } = useMutateField(rssFeed.properties);
	const { mutateField: mutateRssFeedType } = useMutateField(rssFeed.type);
	const { mutateField: mutateICalFeedUrl } = useMutateField(icalFeed.url);
	const { mutateField: mutateICalFeedProperties } = useMutateField(icalFeed.properties);
	const { mutateField: mutateVimeoProperties } = useMutateField(vimeoFeed.properties);
	const { mutateField: mutateVimeoIdentifier } = useMutateField(vimeoFeed.identifier);
	const { setVisibility: setYoutubeFeedVisibility } = useFieldVisibility(youtubeFeed);
	const { setVisibility: setVimeoFeedVisibility } = useFieldVisibility(vimeoFeed);
	const { setVisibility: setRssFeedVisibility } = useFieldVisibility(rssFeed);
	const { setVisibility: setIcalFeedVisibility } = useFieldVisibility(icalFeed);

	const onAddFeed = (feedType: AttributeTypename, feedFields: Record<string, any>) => {
		const properties = convertToJson(feedFields.properties);
		if (feedType === AttributeTypename.YouTubeFeed) {
			mutateYoutubeFeedUrl(feedFields.url);
			mutateYoutubeFeedProperties(properties);
			setYoutubeFeedVisibility(true);
		}
		if (
			feedType === AttributeTypename.RssFeed ||
			feedType === AttributeTypename.PodcastFeed ||
			feedType === AttributeTypename.VideoFeed
		) {
			mutateRssFeedUrl(feedFields.url);
			mutateRssFeedProperties(properties);
			mutateRssFeedType(RssFeedType.News);
			setRssFeedVisibility(true);
		}
		if (feedType === AttributeTypename.IcalFeed) {
			mutateICalFeedUrl(feedFields.url);
			mutateICalFeedProperties(properties);
			setIcalFeedVisibility(true);
		}
		if (feedType === AttributeTypename.VimeoFeed) {
			mutateVimeoProperties(properties);
			mutateVimeoIdentifier(VimeoIdentifierType.Showcase);
			setVimeoFeedVisibility(true);
		}
	};

	const onDeleteFeed = (feedType: AttributeTypename) => {
		if (feedType === AttributeTypename.YouTubeFeed) {
			mutateYoutubeFeedUrl('');
			mutateYoutubeFeedProperties('');
			setYoutubeFeedVisibility(false);
		}
		if (
			feedType === AttributeTypename.RssFeed ||
			feedType === AttributeTypename.PodcastFeed ||
			feedType === AttributeTypename.VideoFeed
		) {
			mutateRssFeedUrl('');
			mutateRssFeedProperties('');
			mutateRssFeedType(null);
			setRssFeedVisibility(false);
		}
		if (feedType === AttributeTypename.IcalFeed) {
			mutateICalFeedUrl('');
			mutateICalFeedProperties('');
			setIcalFeedVisibility(false);
		}
		if (feedType === AttributeTypename.VimeoFeed) {
			mutateVimeoProperties('');
			setVimeoFeedVisibility(false);
		}
	};

	return { onAddFeed, onDeleteFeed };
}
