import { createUseStyles, multiply, subtract } from '@pushpay/styles';
import { Theme, ChMSTheme } from '@pushpay/theming';

type StyleParam = {
	isTablet: boolean;
};

const TABLET_PANE_WIDTH = '140px';
const DESKTOP_PANE_WIDTH = '216px';

export const useStyles = createUseStyles((theme: Theme) => ({
	root: ({ isTablet }: StyleParam) => ({
		margin: theme.SPACING.SMALL,
		marginRight: subtract(theme.SPACING.SMALL, '2px'),
		padding: 0,
		width: isTablet ? TABLET_PANE_WIDTH : DESKTOP_PANE_WIDTH,
		border: 'none',
	}),
	draggableWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		marginLeft: '-10px',
	},
	title: {
		font: theme.typography['heading-3'],
		color: theme.colors['grey-900'],
		margin: `0 0 ${theme.SPACING.XSMALL}`,
	},
	hint: ({ isTablet }: { isTablet: boolean }) => ({
		fontSize: isTablet ? '12px' : '14px',
		color: theme.colors['grey-700'],
		marginBottom: theme.SPACING.SMALL,
	}),
	draggables: {
		width: '100%',
		height: '20px',
		fontSize: '12px',
		marginBottom: multiply(theme.SPACING.XSMALL, 1.5),
		lineHeight: '20px',
		textAlign: 'center',
		borderRadius: theme.SHAPE.ROUNDED_CORNERS,
		color: theme.colors['common-white'],
	},
	dhs: {
		opacity: '40%',
	},
	dhsHint: {
		fontSize: '14px',
		padding: theme.SPACING.FIELD_PADDING_V,
		color: theme.colors['text-heading'],
		borderRadius: theme.SHAPE.ROUNDED_CORNERS,
		backgroundColor: theme.colors['blue-100'],
	},
	ccbDraggables: {
		backgroundColor: ChMSTheme.colors['productBrand-500'],
	},
	resiDraggables: {
		backgroundColor: 'var(--color-resi)',
	},
	disabledDraggables: {
		opacity: 0.3,
	},
	emptyDraggablesWrapper: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: theme.SPACING.LARGE,
	},
	emptyDraggablesLabel: {
		textAlign: 'center',
		color: theme.colors['text-placeholder'],
		fontSize: '14px',
	},
	emptyDraggablesIcon: {
		height: '68px',
		width: '60px',
	},
	loading: {
		opacity: 0.5,
	},
}));
