import { PropsWithChildren, Suspense, useEffect } from 'react';

import { Permission, Permissions } from '@pushpay/identity.permissions';

import { ErrorBoundary, GeneralError } from '@src/components/errors';
import { PagePosition, useScrollToPagePosition } from '@src/context/scrollToLastPagePositionContext';
import { EntitlementKey } from '@src/graphql/generated';
import { useMyApp, useMyEntitlements, useMyPermissions } from '@src/myContext';
import { RedirectToOrganization } from '@src/pages/shell/RedirectToOrganization';
import { useParams } from '@src/router';

type AppDesignPageProps = {
	page: string;
	position?: PagePosition;
	clearPagesPosition?: boolean;
	entitlement?: EntitlementKey;
	permission?: Permission;
};

export function AppDesignPage({
	children,
	page,
	position = 'top',
	clearPagesPosition = false,
	permission = Permissions.basic,
	entitlement,
}: PropsWithChildren<AppDesignPageProps>) {
	const { clearPagesPosition: clear } = useScrollToPagePosition(page, position);
	const { organizationKey } = useParams<'organizationKey'>();
	const hasPageAccess = usePageAccess(permission, entitlement);

	useEffect(() => {
		if (clearPagesPosition) {
			clear();
		}
	}, [clear, clearPagesPosition]);

	if (!hasPageAccess) {
		return <RedirectToOrganization organizationKey={organizationKey} />;
	}

	return (
		<ErrorBoundary fallback={GeneralError}>
			<Suspense>{children}</Suspense>
		</ErrorBoundary>
	);
}

function usePageAccess(permission: Permission, entitlement?: EntitlementKey) {
	const { hasAppStudioPermission } = useMyPermissions();
	const { hasOrganizationEntitlement } = useMyEntitlements();
	const {
		dhs: { enabled: isDhsEnabled },
	} = useMyApp();

	const hasPermission = permission === Permissions.basic || hasAppStudioPermission;
	const hasEntitlement = !entitlement || hasOrganizationEntitlement(entitlement);
	let hasFeature = true;

	if (entitlement === EntitlementKey.AppDynamicHomeScreen) {
		hasFeature = isDhsEnabled;
	}

	return hasPermission && hasEntitlement && hasFeature;
}
