import { useMemo } from 'react';

import { useTheme } from '@pushpay/app-components/dist/app/hooks/useTheme';
import Accessor from '@pushpay/app-components/dist/app/utils/accessor';
import { createUseStyles } from '@pushpay/styles';
import { Theme, useMediaBreakpoint } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { AppPreviewContextProvider } from '@src/context';

import { HomeIndicator } from './HomeIndicator';
import { StatusBar } from './Statusbar';

const DESKTOP_PREVIEW_CONTENT_WIDTH = 246;
const DESKTOP_PREVIEW_CONTENT_HEIGHT = 402;
const TABLET_PREVIEW_CONTENT_WIDTH = 214;
const TABLET_PREVIEW_CONTENT_HEIGHT = 352;
const IPHONE_13_PRO_WIDTH = 390;
const IPHONE_13_PRO_HEIGHT = 844;
const IPHONE_13_PRO_STATUS_BAR_HEIGHT = 47;
const IPHONE_13_PRO_HOME_INDICATOR_HEIGHT = 46;
export const PREVIEW_CONTENT_HEIGHT =
	IPHONE_13_PRO_HEIGHT - IPHONE_13_PRO_STATUS_BAR_HEIGHT - IPHONE_13_PRO_HOME_INDICATOR_HEIGHT - 49 - 64; // 49 is the height of the bottom tab bar, 64 is the height of the header

type FillColor = 'black' | 'white';

type StyleParams = {
	contentScale: number;
	indicatorBackground: string;
	indicatorColor: FillColor;
	statusBarBackground: string;
	statusBarColor: FillColor;
};

const useStyles = createUseStyles((theme: Theme) => {
	const fillColor: Record<FillColor, string> = {
		black: theme.colors['common-black'],
		white: theme.colors['common-white'],
	};

	return {
		root: ({ contentScale }: StyleParams) => ({
			display: 'flex',
			flexDirection: 'column',
			height: `${IPHONE_13_PRO_HEIGHT}px`,
			width: `${IPHONE_13_PRO_WIDTH}px`,
			transform: `scale(${contentScale})`,
		}),
		content: {
			width: `${IPHONE_13_PRO_WIDTH}px`,
		},
		statusBar: ({ statusBarBackground }: StyleParams) => ({
			width: '100%',
			height: `${IPHONE_13_PRO_STATUS_BAR_HEIGHT}px`,
			flexGrow: 0,
			color: statusBarBackground,
			marginBottom: '-2px',
			zIndex: 1,
		}),
		indicator: ({ indicatorBackground }: StyleParams) => ({
			width: '100%',
			height: `${IPHONE_13_PRO_HOME_INDICATOR_HEIGHT}px`,
			flexGrow: 0,
			color: indicatorBackground,
		}),
		statusBarFill: ({ statusBarColor }: StyleParams) => ({
			fill: fillColor[statusBarColor],
		}),
		homeIndicatorFill: ({ indicatorColor }: StyleParams) => ({
			fill: fillColor[indicatorColor],
		}),
	};
});

type PreviewScaleContainerProps = ComponentProps<
	{
		appSettings: Accessor;
		titleBarTheme?: string;
	},
	typeof useStyles
>;

export const PreviewScaleContainer = ({
	children,
	classes: classesProp,
	titleBarTheme,
	appSettings,
	'data-pp-at-target': targetId,
}: PreviewScaleContainerProps) => {
	const isTablet = useMediaBreakpoint('DESKTOP_AND_BELOW');
	const { width: phoneScreenWidth, height: phoneScreenHeight } = useMemo(
		() =>
			isTablet
				? {
						width: TABLET_PREVIEW_CONTENT_WIDTH,
						height: TABLET_PREVIEW_CONTENT_HEIGHT,
				  }
				: {
						width: DESKTOP_PREVIEW_CONTENT_WIDTH,
						height: DESKTOP_PREVIEW_CONTENT_HEIGHT,
				  },
		[isTablet]
	);
	const scaling = useMemo(
		() =>
			// Round the scale to the two decimal places to fit the phone frame
			Math.round((phoneScreenWidth / IPHONE_13_PRO_WIDTH) * 100) / 100,
		[phoneScreenWidth]
	);

	const { themeColors, isDarkMode } = useTheme();
	const classes = useStyles(classesProp, {
		statusBarBackground: themeColors.background.navigationBar,
		statusBarColor: isDarkMode || titleBarTheme === 'dark' ? 'white' : 'black',
		indicatorBackground: themeColors.background.tabBar,
		indicatorColor: getContrastingColor(themeColors.background.tabBar),
		contentScale: scaling,
	});

	return (
		<div className={classes.root}>
			<StatusBar
				classes={{
					root: classes.statusBar,
				}}
				className={classes.statusBarFill}
				data-pp-at-target={`${targetId}-status-bar`}
			/>
			<div
				className={classes.content}
				data-pp-at-target={`${targetId}-content`}
				style={{
					display: 'flex',
					flex: 1,
				}}
			>
				<AppPreviewContextProvider
					appSettings={appSettings}
					screenHeight={phoneScreenHeight / scaling}
					screenWidth={phoneScreenWidth / scaling}
				>
					{children}
				</AppPreviewContextProvider>
			</div>
			<HomeIndicator
				classes={{
					root: classes.indicator,
				}}
				className={classes.homeIndicatorFill}
				data-pp-at-target={`${targetId}-home-indicator`}
			/>
		</div>
	);
};

function getContrastingColor(colorHexCode: string): FillColor {
	// Strip the leading "#" if present
	const hexCode = colorHexCode.replace(/^#/, '');

	// Convert the hex to RGB
	const r = parseInt(hexCode.substring(0, 2), 16);
	const g = parseInt(hexCode.substring(2, 4), 16);
	const b = parseInt(hexCode.substring(4, 6), 16);

	// Get the luminance
	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

	// Return 'black' if the luminance is greater than 0.5, else 'white'
	return luminance > 0.5 ? 'black' : 'white';
}
