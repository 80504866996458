import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

type ParentContext = {
	parentCampus: string | undefined;
	parentContainerId: string | undefined;
};

const ParentContext = createContext<ParentContext | null>(null);
ParentContext.displayName = 'ParentContext';

function ParentContextProvider({ children, parentCampus, parentContainerId }: PropsWithChildren<ParentContext>) {
	const parentContextValue: ParentContext = useMemo(
		() => ({ parentCampus, parentContainerId }),
		[parentCampus, parentContainerId]
	);

	return <ParentContext.Provider value={parentContextValue}>{children}</ParentContext.Provider>;
}

function useParentContext(): ParentContext {
	const context = useContext(ParentContext);
	if (!context) {
		throw new Error('useParentContext must be used within a ParentContextProvider');
	}
	return context;
}

export { ParentContextProvider, useParentContext };
