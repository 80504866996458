import { useEffect, useState } from 'react';

import { FormState, TextField, useMutateField } from '@pushpay/forms';
import { Select } from '@pushpay/inputs';
import { Stack, Tiles } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ExpandableAttributes } from '@src/components/expandableAttributes';
import { SaveContainerSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	inputLabel: {
		display: 'flex',
	},
	inputLabelTooltip: {
		marginLeft: theme.SPACING.XXSMALL,
	},
	titleTile: {
		paddingTop: 0,
	},
	field: {
		margin: `${theme.SPACING.FIELD_SPACING_V} 0 0 0`,
	},
	label: {
		font: theme.typography['text-4'],
		[theme.mediaBreakpoint('MOBILE')]: {
			width: '100%',
		},
	},
	selectRoot: {
		marginTop: theme.SPACING.XSMALL,
	},
}));

const relatedContentRecords = {
	youMightAlsoLike: 'You might also like',
	inThisSeries: 'In this series',
	recommendedForYou: 'Recommended for you',
	inThisPlaylist: 'In this playlist',
	latest: 'Latest',
	custom: 'Custom',
};

type RelatedContentRecordsKey = keyof typeof relatedContentRecords;
const predefinedRelatedContentValues = Object.keys(relatedContentRecords).reduce<string[]>(
	(acc, key) =>
		relatedContentRecords[key as RelatedContentRecordsKey] === relatedContentRecords.custom
			? acc
			: [...acc, relatedContentRecords[key as RelatedContentRecordsKey]],
	[]
);

const useRelatedOptions = () => {
	const { translate } = useTranslation('appDesign');
	return [
		{
			display: translate('settings.container.relatedContent.alsoLike'),
			value: relatedContentRecords.youMightAlsoLike,
		},
		{
			display: translate('settings.container.relatedContent.inThisSeries'),
			value: relatedContentRecords.inThisSeries,
		},
		{
			display: translate('settings.container.relatedContent.recommended'),
			value: relatedContentRecords.recommendedForYou,
		},
		{
			display: translate('settings.container.relatedContent.inThisPlaylist'),
			value: relatedContentRecords.inThisPlaylist,
		},
		{ display: translate('settings.container.relatedContent.latest'), value: relatedContentRecords.latest },
		{ display: translate('settings.container.relatedContent.custom'), value: relatedContentRecords.custom },
	];
};

type RelatedContentSettingProps = {
	field: FormState<SaveContainerSettingsSchema>['input']['relatedContent'];
};

export const RelatedContentSetting = ({
	classes: classesProp,
	field,
	'data-pp-at-target': targetId,
}: ComponentProps<RelatedContentSettingProps, typeof useStyles>) => {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const { mutateField: mutateRelatedContentEnabled } = useMutateField(field.isEnabled);
	const { mutateField: mutateTitle } = useMutateField(field.title);
	const isCustomLabel = !predefinedRelatedContentValues.includes(field.title.value);
	const [showCustomInput, setShowCustomInput] = useState(isCustomLabel);

	useEffect(() => setShowCustomInput(isCustomLabel), [isCustomLabel]);

	return (
		<ExpandableAttributes
			classes={{ root: classes.root }}
			data-pp-at-target={targetId}
			disabled={false}
			expand={field.isEnabled.value}
			id="related-content-toggle"
			title={translate('settings.container.relatedContent.title')}
			toggleName={translate('settings.container.relatedContent.toggle')}
			tooltipContent={translate('settings.container.relatedContent.titleToolTip')}
			onExpandChange={mutateRelatedContentEnabled}
		>
			<Stack>
				<Tiles classes={{ tile: classes.titleTile }} columns={[2]}>
					<div className={classes.field}>
						<label className={classes.label} htmlFor="relatedContent">
							{translate('settings.container.relatedContent.selectLabel')}
						</label>
						<Select
							classes={{
								root: classes.selectRoot,
							}}
							data-pp-at-target={`${targetId}-options`}
							id="relatedContent"
							options={useRelatedOptions()}
							value={isCustomLabel ? relatedContentRecords.custom : field.title.value}
							onChange={value => {
								if (value === relatedContentRecords.custom) {
									mutateTitle('');
								} else {
									mutateTitle(value);
								}
							}}
						/>
					</div>
					{showCustomInput && (
						<TextField
							field={field.title}
							label={translate('settings.container.relatedContent.customInputLabel')}
							labelPosition="top"
							placeholder={translate('settings.container.relatedContent.customInputPlaceholder')}
							showLabel
						/>
					)}
				</Tiles>
			</Stack>
		</ExpandableAttributes>
	);
};
