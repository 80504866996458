import { CardType, CardProps } from '@src/components/card';
import { DraggableIcon } from '@src/components/draggable';
import { ContainerType } from '@src/graphql/generated';
import { generatePath, ROUTE_PATHS } from '@src/router';
import { containerNames } from '@src/shared/cardUtils';
import { validateItemProperties } from '@src/shared/validation';
import { getContainerCardType, getItemCardType } from '@src/utils';

import { ContainerChild } from './types';

export function mapContainerChildToCardLegacy(
	node: ContainerChild,
	parentContainerId: string | undefined,
	parentContainerType: ContainerType,
	applicationId: string,
	canHaveChildren = false
): CardProps {
	let cardType: CardType = 'container';
	let icon: DraggableIcon = 'file-1';
	let mirrored = false;
	let campus;
	let path;
	let editPath;
	let containerType;
	let isFeedItem = false;
	let isDeletable = true;
	let rootOnlyChildName = ''; // Give or Message container
	let itemType;
	let isHidden = node.hidden && !isFeedItem;
	let hasInvalidTimeframeProperties: boolean | undefined = false;

	if (node.__typename === 'ChildItem' && parentContainerId) {
		const { id: itemId, item } = node;
		const { type, feedId, isMirrored } = item;

		itemType = type;
		cardType = getItemCardType(type);
		icon = 'file-1';
		mirrored = isMirrored;
		path = generatePath(ROUTE_PATHS.CONTAINER_ITEM, { containerId: parentContainerId, itemId });
		isFeedItem = !!feedId;

		const { hasInvalidTimeframeProperties: invalidTimeframeProperties } = validateItemProperties(item);

		hasInvalidTimeframeProperties = invalidTimeframeProperties;

		if (hasInvalidTimeframeProperties) {
			isHidden = true;
		}
	}

	if (node.__typename === 'ChildContainer') {
		cardType = getContainerCardType(node.container?.type);
		containerType = node.container?.type;
		icon = 'folder-1';
		path = generatePath(canHaveChildren ? ROUTE_PATHS.CONTAINER_CHILDREN : ROUTE_PATHS.CONTAINER_SETTINGS, {
			containerId: node.id,
		});
		editPath = generatePath(ROUTE_PATHS.CONTAINER_SETTINGS, {
			containerId: node.id,
		});
		if (node.container?.campus?.name) {
			campus = node.container.campus.name;
		}
		isDeletable = node.container?.isDeletable;

		rootOnlyChildName = containerNames[node.container?.type] ?? '';
	}

	return {
		icon: node.icon ? (node.icon as DraggableIcon) : icon,
		name: node.name,
		type: cardType,
		containerType,
		parentContainerType,
		itemType,
		position: node.position,
		isDeletable,
		isHidden,
		isMirrored: mirrored,
		isFeedItem,
		campus: campus || undefined,
		id: node.id,
		isEditable: true,
		rootOnlyChildName,
		'data-pp-at-target': 'cards',
		path,
		editPath,
		parentId: parentContainerId,
		hideVisibilityToggle: isFeedItem,
		applicationId,
		hasInvalidTimeframeProperties,
	};
}
