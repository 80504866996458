import { CardProps } from '@src/components/card';
import { NEW_CARD_PREFIX } from '@src/components/card/Card';
import { useShowNotification } from '@src/components/notification';
import {
	ChildItemFragment,
	ChildItemFragmentDoc,
	CopyItemInput,
	CopyItemSuccess,
	ItemTemplate,
	ItemType,
	useCopyItemsMutation,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useHistoryState } from '@src/shared/hooks';
import { insertToArrayBefore } from '@src/utils/array';

export function useOptimizedCopyItemsMutation({
	organizationKey,
	platformCampusKey,
	applicationId,
}: {
	organizationKey: string;
	platformCampusKey?: string | null;
	applicationId: string;
}) {
	const [mutate] = useCopyItemsMutation();
	const { translate } = useTranslation('common');
	const showNotification = useShowNotification();
	const { updateHistoryState } = useHistoryState();

	const copyItems = ({ parentContainerId, items }: { parentContainerId: string; items: CardProps[] }) => {
		const itemsToCopy: CopyItemInput[] = items.map(item => ({
			itemId: item.id,
			copiedItemName: translate('copyAndMove.copyName', { name: item.name }),
		}));

		mutate({
			variables: {
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
					parentContainerId,
					copyItems: itemsToCopy,
				},
			},
			update(cache, { data }) {
				cache.modify({
					id: cache.identify({
						__typename: 'Container',
						id: parentContainerId,
					}),
					fields: {
						children(existing, { readField }) {
							if (data?.copyItems.__typename !== 'CopyItemsOutput') {
								let errorContent;

								if (data?.copyItems.__typename === 'RootContainerError') {
									errorContent = translate('copyAndMove.errors.copyRootContainer');
								} else if (data?.copyItems.__typename === 'InvalidRequestError') {
									errorContent =
										items.length > 1
											? translate('copyAndMove.errors.copyItems')
											: translate('copyAndMove.errors.copyItem', { itemName: items[0].name });
								}

								if (errorContent) {
									showNotification({
										isOpen: true,
										type: 'error',
										title: translate('errors.title.error'),
										content: errorContent,
									});
								}

								return existing;
							}

							const copiedItems = data?.copyItems?.copyItemResults;
							if (!copiedItems?.length) {
								return existing;
							}

							const nodeIds = existing.nodes.map((nodeRef: any) => readField('id', nodeRef));
							const { itemId: lastItemId } = itemsToCopy[itemsToCopy.length - 1];

							let anyItemFailed = false;
							let nextIndex = nodeIds.findIndex((id: string) => id === lastItemId) + 1;
							let newList = { ...existing };

							copiedItems.forEach(item => {
								if (item.__typename === 'CopyItemSuccess') {
									const { copiedItem } = item as CopyItemSuccess;

									const newChildItemRef = cache.writeFragment<ChildItemFragment>({
										data: {
											id: copiedItem.id,
											name: copiedItem.name,
											icon: copiedItem.icon,
											hidden: copiedItem.isHidden,
											item: {
												id: copiedItem.id,
												isMirrored: copiedItem.isMirrored,
												type: copiedItem.type,
												feedId: copiedItem.feedId,
												image: copiedItem.image,
												properties: copiedItem.properties,
											},
											position: -1,
											__typename: 'ChildItem',
										},
										variables: {
											organizationKey,
										},
										fragment: ChildItemFragmentDoc,
										fragmentName: 'ChildItem',
									});

									newList = {
										...newList,
										nodes: insertToArrayBefore(newList.nodes, nextIndex, newChildItemRef),
									};
									nextIndex++;
								} else if (item.__typename === 'CopyItemError') {
									anyItemFailed = true;
								}
							});

							if (anyItemFailed) {
								showNotification({
									isOpen: true,
									type: 'error',
									title: translate('errors.title.error'),
									content: translate('copyAndMove.errors.copyItems'),
								});
							}

							return newList;
						},
					},
				});
				if (data?.copyItems.__typename === 'CopyItemsOutput') {
					const { copyItemResults } = data?.copyItems;

					const copiedItemIds = copyItemResults
						.filter((result): result is CopyItemSuccess => result.__typename === 'CopyItemSuccess')
						.map(success => success.copiedItem.id);

					updateHistoryState({ recentlyCopiedChildIds: copiedItemIds });
				}
			},
			optimisticResponse: {
				copyItems: {
					copyItemResults: items.map<CopyItemSuccess>(item => ({
						itemId: item.id,
						copiedItem: {
							id: NEW_CARD_PREFIX + crypto.randomUUID(),
							type: item.type as ItemType,
							name: translate('copyAndMove.copyName', { name: item.name }),
							icon: item.icon,
							isHidden: item.isHidden,
							isMirrored: item.isMirrored,
							template: ItemTemplate.Unknown,
							actions: [],
							related: [],
							feedId: null,
							image: null,
							properties: [],
						},
						__typename: 'CopyItemSuccess',
					})),
					__typename: 'CopyItemsOutput',
				},
			},
		});
	};

	return { copyItems };
}
