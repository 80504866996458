import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState, useRef } from 'react';

import { Notification } from './Notification';
import { NotificationState } from './types';

type NotificationContext = {
	showNotification: Dispatch<SetStateAction<NotificationState>>;
	notificationWrapper: React.RefObject<HTMLDivElement>;
};

const NotificationContext = createContext<NotificationContext | null>(null);

export function NotificationProvider({ children }: React.PropsWithChildren) {
	const [notificationState, setNotificationState] = useState<NotificationState>({
		isOpen: false,
	});
	const wrapperRef = useRef<HTMLDivElement>(null);

	const notificationContextValue: NotificationContext = useMemo(
		() => ({
			showNotification: setNotificationState,
			notificationWrapper: wrapperRef,
		}),
		[]
	);

	const dismissNotification = useCallback(() => {
		setNotificationState({ ...notificationState, isOpen: false });
	}, [setNotificationState, notificationState]);

	return (
		<NotificationContext.Provider value={notificationContextValue}>
			<Notification
				data-pp-at-target="notification"
				dismiss={dismissNotification}
				notificationState={notificationState}
				wrapper={wrapperRef}
			/>
			{children}
		</NotificationContext.Provider>
	);
}

export function useShowNotification() {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error('useShowNotification must be used inside a NotificationProvider');
	}
	return context.showNotification;
}

export function useNotificationWrapper() {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error('useNotificationWrapper must be used inside a NotificationProvider');
	}

	return context.notificationWrapper;
}
