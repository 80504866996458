import { useEffect, useRef } from 'react';

import { useLocation, useNavigationType } from 'react-router-dom';
import unfetch from 'unfetch';

import { SPA_VERSION_CHECK } from '@src/router/routePaths';
import { getAppSettings } from '@src/utils';

import { ErrorBoundary } from '../errors';
import { isTestWidgetEnabled, useTestWidgetSettings } from '../testWidget';

type SpaVersionJson = {
	version: string;
};

const CacheBusterImplementation = () => {
	const currentLocation = useLocation();
	const { version } = getAppSettings();
	const forceReload = useRef(false);

	const { pathname } = useLocation();
	const navigationType = useNavigationType();

	const [applicationSetting] = useTestWidgetSettings('application');

	useEffect(() => {
		const getCurrentVersion = () =>
			isTestWidgetEnabled() && applicationSetting.simulateCacheBusting ? 'old_version' : version;

		const checkSpaVersion = async () => {
			const currentVersion = getCurrentVersion();
			const response = await unfetch(SPA_VERSION_CHECK, { method: 'GET' });
			const { version: remoteVersion }: SpaVersionJson = await response.json();
			if (currentVersion !== remoteVersion) {
				forceReload.current = true;
			} else {
				forceReload.current = false;
			}
		};

		if (navigationType === 'PUSH' && forceReload.current) {
			window.location.reload();
			return;
		}
		checkSpaVersion();
	}, [version, currentLocation, navigationType, pathname, applicationSetting.simulateCacheBusting]);

	return null;
};

const CacheBuster = () => (
	<ErrorBoundary>
		<CacheBusterImplementation />
	</ErrorBoundary>
);

export default CacheBuster;
