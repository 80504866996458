import initi18n, { InitOptions } from '@pushpay/i18n';

import { onUntranslatedKey } from '@src/raygun';
import { getAppSettings } from '@src/utils';

import { TranslationLanguage } from './translation';

export function initI18n() {
	const { version } = getAppSettings();
	const options: InitOptions<TranslationLanguage> = {
		debug: DEVELOPMENT,
		ns: 'common',
		defaultNS: 'common',
		fallbackLng: false,
		appendNamespaceToMissingKey: true,
		backend: { queryStringParams: version },
		parseMissingKeyHandler: onUntranslatedKey,
		interpolation: {
			skipOnVariables: false,
		},
	};

	return initi18n({ options });
}
