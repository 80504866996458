import { PropsWithChildren, useEffect, useRef } from 'react';

import { useEvent } from '@dnd-kit/utilities';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import {
	useScrollElementIntoViewActions,
	useScrollElementIntoViewState,
} from '@src/context/scrollElementIntoViewContext';
import { useScrollToLastPagePositionContext } from '@src/context/scrollToLastPagePositionContext';
import { useFeature } from '@src/featureFlags';

type Props = {
	elementId: string;
} & PropsWithChildren;

export const ScrollElementIntoView = ({ children, elementId }: Props) => {
	const targetRef = useRef<HTMLDivElement>(null);
	const { containerRef: pageContainerRef } = useScrollToLastPagePositionContext();
	const { clearElementId } = useScrollElementIntoViewActions();
	const { elementId: elementIdFromState } = useScrollElementIntoViewState();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const scrollIntoView = useEvent(() => {
		const target = targetRef.current;
		const pageContainer = pageContainerRef?.current;

		if (!(target && pageContainer)) return;

		if (isInAppCalendarEnabled) {
			scrollIntoViewIfNeeded(target, { behavior: 'smooth', block: 'center', scrollMode: 'if-needed' });
		} else {
			pageContainer.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
		}
	});

	useEffect(() => {
		const shouldScrollIntoView = elementId === elementIdFromState;

		if (!shouldScrollIntoView) return;

		scrollIntoView();
		clearElementId();
	}, [elementId, elementIdFromState, clearElementId, scrollIntoView]);

	return <div ref={targetRef}>{children}</div>;
};
