import { useLayoutEffect, RefObject, useRef, useCallback, useTransition } from 'react';

import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $getRoot, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';

import { sanitizeHtml } from '@src/components/textEditor/plugins/BlankifyPlugin';
import {
	fromClassNamesToInlineStyles,
	pushpayEditorTheme,
	StyleParams,
} from '@src/components/textEditor/PushpayEditorTheme';

export type EditorStateContent = {
	text?: string;
	textHtml?: string;
	isInitialHtmlUpdated?: boolean;
};
type OnEditPluginProps = {
	forwardedRef: RefObject<HTMLDivElement>;
	includeBlankifyOption?: boolean;
	styleParams: StyleParams;
	onEdit: (editorStateContent: EditorStateContent) => void;
};

const LEXICAL_EMPTY_CONTENT = `<p class="${pushpayEditorTheme.paragraph}"><br></p>`;

export function OnEditPlugin({ forwardedRef, includeBlankifyOption, onEdit, styleParams }: OnEditPluginProps): null {
	const [editor] = useLexicalComposerContext();
	const focusRef = useRef<{ hasFocus: boolean; initialHtml: string }>({ hasFocus: false, initialHtml: '' });
	const editorStateContentRef = useRef<EditorStateContent & { rawTextHtml?: string }>({});
	const [, startTransition] = useTransition();

	const editHandler = useCallback(() => {
		const element = forwardedRef?.current;
		const { hasFocus, initialHtml } = focusRef?.current;
		const { text, rawTextHtml } = editorStateContentRef?.current;
		const isInitialHtmlUpdated = initialHtml !== rawTextHtml;

		if (element && hasFocus) {
			let textHtml = '';
			if (rawTextHtml) {
				const sanitizedHtml = includeBlankifyOption ? sanitizeHtml(rawTextHtml) : rawTextHtml;
				textHtml = fromClassNamesToInlineStyles(sanitizedHtml, styleParams);
			}

			onEdit({ isInitialHtmlUpdated, text, textHtml });
		}
	}, [forwardedRef, includeBlankifyOption, styleParams, onEdit]);

	useLayoutEffect(() => {
		focusRef.current.hasFocus = editor.getRootElement() === document.activeElement;

		return mergeRegister(
			editor.registerCommand(
				FOCUS_COMMAND,
				() => {
					focusRef.current.hasFocus = true;
					if (!focusRef.current.initialHtml) {
						const textHtml = $generateHtmlFromNodes(editor);
						focusRef.current.initialHtml = textHtml;
					}
					return false;
				},
				COMMAND_PRIORITY_LOW
			),
			editor.registerUpdateListener(({ editorState, dirtyElements, dirtyLeaves, prevEditorState }) => {
				editorState.read(() => {
					if ((dirtyElements.size === 0 && dirtyLeaves.size === 0) || prevEditorState.isEmpty()) {
						return;
					}

					const textContentWithoutLineBreaks = $getRoot()
						.getTextContent()
						.replace(/[\n\r\f]/g, '');
					const rawTextHtml = $generateHtmlFromNodes(editor);

					editorStateContentRef.current =
						rawTextHtml === LEXICAL_EMPTY_CONTENT
							? { text: '', rawTextHtml: '' }
							: { text: textContentWithoutLineBreaks, rawTextHtml };

					startTransition(editHandler);
				});
			})
		);
	}, [editor, editHandler]);

	return null;
}
