import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { getShadow } from '@src/shared/styles';

type StyleParams = {
	isTablet: boolean;
	templateWidth: number;
	templateHeight: number;
};

export const useStyles = createUseStyles((theme: Theme) => ({
	card: ({ templateWidth, templateHeight }: StyleParams) => ({
		alignItems: 'center',
		backgroundColor: theme.colors['grey-200'],
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		minHeight: `${templateHeight + 40}px`,
		minWidth: `${templateWidth + 20}px`,
		border: 'none',
	}),
	textWrapper: {
		alignItems: 'center',
		display: 'flex',
		color: theme.colors['text-default'],
		font: theme.typography['text-5'],
		height: '18px',
		marginTop: multiply(theme.SPACING.XSMALL, 1.5),
	},
	infoIcon: {
		marginLeft: theme.SPACING.XXSMALL,
		fontSize: theme.SPACING.MEDIUM_ICON_SIZE,
		cursor: 'help',
	},
	imageCrop: {
		position: 'absolute',
		opacity: 0.6,
		backgroundColor: theme.colors['common-white'],
	},
	imageCropTop: {
		top: 0,
		borderBottom: `1px dashed ${theme.colors['common-black']}`,
	},
	imageCropBottom: {
		bottom: 0,
		borderTop: `1px dashed ${theme.colors['common-black']}`,
	},
	imageCropLeft: {
		top: 0,
		left: 0,
		borderRight: `1px dashed ${theme.colors['common-black']}`,
	},
	imageCropRight: {
		top: 0,
		right: 0,
		borderLeft: `1px dashed ${theme.colors['common-black']}`,
	},
	imageContainer: {
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	imageWrapper: {
		display: 'flex',
		position: 'relative',
	},
	tooltip: {
		padding: '5px 20px',
	},
	templateContainer: {
		backgroundColor: theme.colors['grey-700'],
		margin: `${multiply(theme.SPACING.XSMALL, 1.5)} 0`,
		position: 'relative',
	},
	template: ({ templateWidth, templateHeight }: StyleParams) => ({
		alignItems: 'center',
		backgroundColor: theme.colors['common-white'],
		display: 'flex',
		justifyContent: 'center',
		opacity: 0.7,
		width: `${templateWidth}px`,
		height: `${templateHeight}px`,
	}),
	templateOverlay: ({ isTablet }: StyleParams) => ({
		alignItems: 'center',
		backgroundColor: theme.colors['grey-700'],
		border: `1px dashed rgba(0,0,0,0.6)`,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',

		'& > span': {
			color: theme.colors['common-white'],
			fontWeight: theme.typography.font.weight.semiBold,
		},

		'& > span:first-child': {
			fontSize: multiply(theme.typography.baseSize, isTablet ? 0.9 : 1),
			lineHeight: theme.SPACING.FIELD_HEIGHT,
		},

		'& > span:last-child': {
			fontSize: multiply(theme.typography.baseSize, isTablet ? 0.6 : 0.75),
			lineHeight: theme.SPACING.SMALL,
		},
	}),
	square: ({ templateHeight }: StyleParams) => ({
		width: `${templateHeight}px`,
		height: `${templateHeight}px`,
	}),
	'16x9': ({ templateWidth }: StyleParams) => ({
		width: `${templateWidth}px`,
		height: `${(576 / 1024) * templateWidth}px`,
	}),
	panorama: ({ templateWidth }: StyleParams) => ({
		width: `${templateWidth}px`,
		height: `${(422 / 1024) * templateWidth}px`,
	}),
	removeButton: {
		color: theme.colors['productBrand-500'],
		font: theme.typography.button,
		fontSize: '14px',
		margin: `${theme.SPACING.XSMALL} 0`,
	},
	removeIcon: {
		fontSize: '16px',
		marginRight: theme.SPACING.XXSMALL,
	},
	loading: {
		opacity: 0.4,
		cursor: 'wait',
	},
	spinner: {
		color: theme.colors['productBrand-500'],
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		cursor: 'grab',
	},
	dragging: {
		zIndex: 1,
		'&:hover, &:focus': {
			...getShadow(),
			cursor: 'grabbing',
		},
	},
}));
