import { multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const profileStyles = (theme: Theme) => ({
	buttonsContainer: {
		width: '100%',
		justifyContent: 'space-between',
	},
	cardBody: {
		padding: `${theme.SPACING.LARGE} ${theme.SPACING.MEDIUM}`,
	},
	fieldWrapper: {
		margin: 0,
	},
	pageWrapper: {
		margin: `${multiply(theme.SPACING.XLARGE, 2)} ${theme.SPACING.XLARGE}`,
	},
	saveButton: {
		width: '120px',
	},
});
