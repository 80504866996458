import { arrayMove } from '@dnd-kit/sortable';

import { ReorderContainerChildrenInput, useReorderContainerChildrenMutation } from '@src/graphql/generated';
import { usePendo } from '@src/pendo';

export function useOptimizedReorderContainerChildrenMutation() {
	const [mutate] = useReorderContainerChildrenMutation();
	const { pendoTrackEvent } = usePendo();

	const getNewIndex = (insertBeforeIndex: number, nodeCount: number, moveDown: boolean) => {
		const isLastPosition = insertBeforeIndex === -1;
		if (isLastPosition) {
			return nodeCount - 1;
		}

		if (moveDown) {
			return insertBeforeIndex - 1;
		}

		return insertBeforeIndex;
	};

	const reorderContainerChildren = ({
		organizationKey,
		platformCampusKey,
		input,
		moveDown,
		draggableType,
	}: {
		organizationKey: string;
		platformCampusKey?: string | null;
		input: ReorderContainerChildrenInput;
		moveDown: boolean;
		draggableType: string;
	}) => {
		const { applicationId, parentContainerId, containerChildId, insertBefore } = input;
		mutate({
			variables: {
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
					parentContainerId,
					containerChildId,
					insertBefore,
				},
			},
			update(cache, { data }) {
				cache.modify({
					id: cache.identify({ id: parentContainerId, __typename: 'Container' }),
					fields: {
						children(existing, { readField }) {
							const nodeIds = existing.nodes.map((nodeRef: any) => readField('id', nodeRef));
							const oldIndex = nodeIds.findIndex((id: string) => id === containerChildId);
							const insertBeforeIndex = nodeIds.findIndex((id: string) => id === insertBefore);
							const newIndex = getNewIndex(insertBeforeIndex, nodeIds.length, moveDown);

							const isOptimisticResponse =
								data?.reorderContainerChildren.updatedContainerChildren[0]?.position === -1;

							if (!isOptimisticResponse) {
								pendoTrackEvent('Draggable Position Change', {
									draggableType,
									oldPosition: oldIndex,
									newPosition: newIndex,
								});
							}

							const result = arrayMove(existing.nodes, oldIndex, newIndex);

							return { ...existing, nodes: result };
						},
					},
				});
			},
			optimisticResponse: () => ({
				reorderContainerChildren: {
					updatedContainerChildren: [
						{
							id: containerChildId,
							position: -1,
						},
					],
				},
			}),
		});
	};

	return reorderContainerChildren;
}
