import { useId } from 'react';

import { Field, FormState, useMutateField } from '@pushpay/forms';
import { Switch } from '@pushpay/inputs';
import { Column, Columns } from '@pushpay/layout';
import { ComponentProps } from '@pushpay/types';

import { ShellCapabilityKey, WebsitePropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp } from '@src/myContext';

import { InAppBrowserSwitch } from '../shared';
import { ContentWrapper, LabelWrapper, TextFieldWrapper } from '../wrappers';

export type WebsiteProps = ComponentProps<
	{
		propertyField: FormState<WebsitePropertyInput>;
	},
	typeof undefined
>;

export const Website = ({ propertyField, 'data-pp-at-target': targetId }: WebsiteProps) => {
	const { getAppShellCapability } = useMyApp();
	// Resi VOD and In-app Browser share the same shell requirement
	const isInAppBrowserSupported = getAppShellCapability(ShellCapabilityKey.ResiVodDraggable)?.isSupported ?? false;
	const id = useId();

	const { label: labelField, url: urlField, isExternal: isExternalField } = propertyField;
	const { value: isExternal } = isExternalField;

	const { mutateField: mutateIsExternal } = useMutateField(isExternalField);

	const { translate } = useTranslation('appDesign');

	const handleIsExternalToggle = (value: boolean) => {
		mutateIsExternal(value);
	};

	return (
		<ContentWrapper>
			<Columns space="SMALL" stackWhen="NEVER">
				<Column width="2/3">
					<TextFieldWrapper
						aria-label={translate('website.label')}
						data-pp-at-target={`${targetId}-website-label`}
						field={labelField as Field<string>}
						label={translate('website.label')}
						labelPosition="top"
						showLabel
					/>
				</Column>
				{isInAppBrowserSupported ? (
					<InAppBrowserSwitch checked={isExternal} id={id} label="" onChange={handleIsExternalToggle} />
				) : (
					<Column>
						<LabelWrapper label={translate('website.openExternally')}>
							<Switch checked={isExternal} id={id} onChange={handleIsExternalToggle} />
						</LabelWrapper>
					</Column>
				)}
			</Columns>
			<TextFieldWrapper
				aria-label={translate('website.url')}
				data-pp-at-target={`${targetId}-website-url`}
				field={urlField as Field<string>}
				label={translate('website.url')}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
