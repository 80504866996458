import { editorClassNames } from '@src/components/textEditor/PushpayEditorTheme';

export function transformToSpanTags(dom: Document): Document {
	Array.from(dom.getElementsByTagName('del')).forEach(delTag => {
		const spanTag = dom.createElement('span');
		const style = editorClassNames.ppEditorTheme__textStrikethrough;

		Object.assign(spanTag.style, style);

		spanTag.textContent = delTag.textContent;

		delTag.parentNode?.replaceChild(spanTag, delTag);
	});

	Array.from(dom.getElementsByTagName('s')).forEach(sTag => {
		if (sTag.parentNode !== null && sTag.firstChild) {
			const fragment = document.createDocumentFragment();

			fragment.appendChild(sTag.firstChild);
			sTag.parentNode.replaceChild(fragment, sTag);
		}
	});

	return dom;
}
