/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */

import { DraggableItemData, DraggablePropertyData } from '@src/components/draggable/types';
import { useDraggablesDataContext } from '@src/context/draggableData/draggablesDataContext';
import { ItemPropertyInput } from '@src/graphql/generated';
import { mockPropertyId } from '@src/graphql/mock/constantKeys';
import { getParentIdAndInsertBeforeId, isDraggableType } from '@src/pages/appDesign/helper';
import { DropHandler } from '@src/pages/appDesign/type';
import { propertyInputTypeObj } from '@src/pages/itemSettings/utils';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { useOptimizedCreateItemMutation } from '../useOptimizedCreateItemMutation';

export function useCreatingItemHandler() {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { drop } = useDraggablesDataContext();
	const createItem = useOptimizedCreateItemMutation();

	const onCreatingItemDropHandler: DropHandler = (event, draggingItem) => {
		const { over } = event;
		if (!over) {
			return;
		}
		const { parentId, insertBeforeId } = getParentIdAndInsertBeforeId(over);

		if (isDraggableType(draggingItem)) {
			if (draggingItem.type === 'item') {
				drop(draggingItem.name);
				handleCreatingItem(parentId, insertBeforeId);
			}
		}

		function handleCreatingItem(parentId: string, insertBeforeId: string | null) {
			if (draggingItem && !isDraggableType(draggingItem)) {
				return;
			}
			const createItemAction = draggingItem?.actions.find(
				({ action_type, model }) => action_type === 'create' && model === 'item'
			);
			const createItemActionData = createItemAction?.data as DraggableItemData;
			const properties: ItemPropertyInput[] = draggingItem?.actions
				.filter(({ action_type, model }) => action_type === 'create' && model === 'property')
				.map((rawProperty, index) => {
					const propertyData = rawProperty?.data as DraggablePropertyData;
					const actionBar =
						propertyData.actionBar !== undefined && propertyData.hideOriginalProperty !== undefined
							? {
									position: propertyData.actionBar,
									hideOriginalProperty: propertyData.hideOriginalProperty,
							  }
							: null;
					return {
						[propertyInputTypeObj[propertyData.type]]: {
							baseProperty: {
								id: (window as any).Cypress ? mockPropertyId : crypto.randomUUID(), // window.Cypress is set when running under Cypress
								action: propertyData.action,
								actionBar,
								header: propertyData.header,
								icon: propertyData.icon,
								isHidden: false,
								position: index,
							},
							...propertyData.bos,
							...propertyData?.getPropertySpecificFields?.(),
						},
					};
				});
			createItem({
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
					parentContainerId: parentId,
					name: createItemActionData.name,
					icon: draggingItem?.icon,
					type: createItemActionData.type,
					template: createItemActionData.template,
					insertBefore: insertBeforeId,
					properties,
				},
			});
		}
	};

	return onCreatingItemDropHandler;
}
