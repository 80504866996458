import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyle = createUseStyles((theme: Theme) => ({
	root: {
		width: '100%',
	},
	label: {
		display: 'flex',
		color: theme.colors['text-default'],
		font: theme.typography['text-4'],
		alignItems: 'center',
		marginBottom: theme.SPACING.XSMALL,
		'& p': {
			margin: 0,
		},
	},
	labelTooltip: {
		marginLeft: theme.SPACING.XXSMALL,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: theme.colors['grey-300'],
		marginTop: theme.SPACING.XSMALL,
		padding: '0 8px',
		fontSize: '13px',
		borderTopLeftRadius: theme.SHAPE.ROUNDED_CORNERS,
		borderTopRightRadius: theme.SHAPE.ROUNDED_CORNERS,
		'& p': {
			margin: '10px 0',
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '0 8px',
		backgroundColor: theme.colors['grey-100'],
		borderBottom: `1px solid ${theme.colors['border-surface']}`,
		borderBottomLeftRadius: theme.SHAPE.ROUNDED_CORNERS,
		borderBottomRightRadius: theme.SHAPE.ROUNDED_CORNERS,
	},
	homeCardDetail: {
		flex: '1 1 25%',
		font: theme.typography['text-4-semiBold'],
	},
	feedLabel: {
		flex: '1 1 30%',
		paddingLeft: theme.SPACING.XSMALL,
		font: theme.typography['text-4-semiBold'],
	},
	example: {
		flex: '1 1 45%',
		font: theme.typography['text-4-semiBold'],
		paddingLeft: theme.SPACING.XSMALL,
		display: 'box',
		lineClamp: 2,
		boxOrient: 'vertical',
		overflow: 'hidden',
	},
	feedLabelContent: {
		display: 'flex',
		fontSize: '24px',
		alignItems: 'center',
		padding: '12px 0 12px 8px',
	},
	feedLabelSelect: {
		flex: '1 1 auto',
		marginLeft: theme.SPACING.XSMALL,
	},
	columnSpace: {
		paddingLeft: theme.SPACING.XXSMALL,
	},
	columnsRoot: {
		marginLeft: 0,
	},
	iconLinked: {
		color: theme.colors['blue-700'],
		fontWeight: 'bold',
	},
	iconUnLinked: {
		color: theme.colors['grey-600'],
		fontWeight: 'bold',
	},
}));
