import { Button } from '@pushpay/button';
import { CrossIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { useTranslation } from '@src/i18n';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'grid',
		placeContent: 'center',
		width: '100%',
		backgroundColor: theme.colors['common-white'],
		cursor: 'default',
		padding: '17px 0px',
	},
	button: {
		color: theme.colors['productBrand-500'],
		font: theme.typography.button,
		'&:hover': {
			color: theme.colors['productBrand-600'],
		},
	},
	icon: {
		display: 'grid',
		placeContent: 'center',
		color: theme.colors['common-white'],
		backgroundColor: theme.colors['productBrand-500'],
		width: '16px',
		height: '16px',
		borderRadius: '15px',
		marginRight: theme.SPACING.XSMALL,
		marginTop: '3px',
		'button:hover &': {
			backgroundColor: theme.colors['productBrand-600'],
		},
	},
}));

type RemoveButtonProps = {
	onClickRemove: () => void;
};

export const RemoveButton = ({ onClickRemove }: RemoveButtonProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	return (
		<div className={classes.root} onPointerDown={ev => ev.stopPropagation()}>
			<Button className={classes.button} type="button" onClick={onClickRemove}>
				<span className={classes.icon}>
					<CrossIcon displaySize="small" />
				</span>
				{translate('carousel.removeImage')}
			</Button>
		</div>
	);
};
