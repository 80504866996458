import { useCallback } from 'react';

import { EntitlementKey } from '@src/graphql/generated';

import { useMyOrganization } from '.';

export function useMyEntitlements() {
	const { details } = useMyOrganization();

	const hasOrganizationEntitlement = useCallback(
		(entitlement: EntitlementKey) => {
			const entitlements = details?.entitlements ?? [];
			return entitlements.some(e => e.key === entitlement);
		},
		[details]
	);

	return {
		hasOrganizationEntitlement,
	};
}
