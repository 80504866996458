import { Button } from '@pushpay/button';
import { StackItem } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { Group, SettingSwitch } from '../components';
import { useTestWidgetSettings } from '../useTestWidgetSettings';

const useStyles = createUseStyles({
	root: {},
});

export const ApplicationGroup = ({
	classes: classesProp,
	'data-pp-at-target': targetId,
	...rest
}: ComponentProps<unknown, typeof useStyles>) => {
	const [{ pendoWhitelistEnabled, simulateCacheBusting }, dispatch] = useTestWidgetSettings('application');

	return (
		<Group data-pp-at-target={targetId} groupName="Application" {...rest}>
			<StackItem alignH="right">
				<Button
					data-pp-at-target={`${targetId}-reset`}
					displaySize="small"
					displayStyle="text"
					type="button"
					onClick={() => dispatch({ type: 'applicationReset' })}
				>
					Reset
				</Button>
			</StackItem>
			<SettingSwitch
				checked={simulateCacheBusting}
				data-pp-at-target={`${targetId}-cache-busting`}
				id={`${targetId}-cache-busting`}
				onChange={enabled => dispatch({ type: 'application', simulateCacheBusting: enabled })}
			>
				Simulate Cache Busting enabled
			</SettingSwitch>
			<SettingSwitch
				checked={pendoWhitelistEnabled}
				data-pp-at-target={`${targetId}-pendo-whitelist`}
				id={`${targetId}-pendo-whitelist-enabled`}
				onChange={enabled => dispatch({ type: 'application', pendoWhitelistEnabled: enabled })}
			>
				Pendo whitelist enabled
			</SettingSwitch>
		</Group>
	);
};
