import { useId } from 'react';

import { FormState, useMutateField } from '@pushpay/forms';
import { TextArea } from '@pushpay/inputs';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { TextPropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, LabelWrapper } from '../wrappers';

const useStyles = createUseStyles((theme: Theme) => ({
	description: {
		minHeight: theme.SPACING.XLARGE,
		resize: 'vertical',
	},
}));

type PlainTextInput = FormState<Required<TextPropertyInput>>;
export type TextProps = ComponentProps<
	{
		propertyField: FormState<TextPropertyInput>;
	},
	typeof undefined
>;

export const Text = ({ propertyField, 'data-pp-at-target': targetId }: TextProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyles(undefined);

	const { textDescription: textField } = propertyField as PlainTextInput;
	const { mutateField: mutateText } = useMutateField(textField);

	const handleTextChange = (value: string) => {
		mutateText(value);
	};

	return (
		<ContentWrapper>
			<LabelWrapper label={translate('text.description')}>
				<TextArea
					aria-label={translate('text.description')}
					classes={{ field: classes.description }}
					data-pp-at-target={`${targetId}-text-description`}
					id={useId()}
					placeholder={translate('text.description')}
					rows={10}
					value={textField?.value || ''}
					onChange={handleTextChange}
				/>
			</LabelWrapper>
		</ContentWrapper>
	);
};
