import { useEffect } from 'react';

import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $getRoot, $setSelection, LexicalNode } from 'lexical';

import { usePropertyListContext } from '@src/components/properties/contexts';
import { send } from '@src/raygun';
import { useEvent } from '@src/utils';

import { cleanHtmlTags, transformToSpanTags } from './utils';

interface HtmlPluginProps {
	initialHtml?: string;
}

enum NodeTypes {
	text = 'text',
	linebreak = 'linebreak',
}

export function HtmlPlugin({ initialHtml }: HtmlPluginProps) {
	const [editor] = useLexicalComposerContext();
	const { isFormDirty } = usePropertyListContext();

	const initializeEditor = useEvent(() => {
		if (typeof initialHtml !== 'string') return;

		editor.update(() => {
			try {
				const htmlString = cleanHtmlTags(initialHtml);
				const parser = new DOMParser();
				const dom = transformToSpanTags(parser.parseFromString(htmlString, 'text/html'));

				const nodes = $generateNodesFromDOM(editor, dom).reduce<LexicalNode[]>((acc, node) => {
					const nodeType = node.getType();

					const isTextNode = nodeType === NodeTypes.text;
					const isLinebreakNode = nodeType === NodeTypes.linebreak;
					const isEmptyTextNode = isTextNode && node.getTextContent().trim() === '';

					if (isEmptyTextNode || isLinebreakNode) {
						return acc;
					}

					if (isTextNode) {
						acc.push($createParagraphNode().append(node));
					} else {
						acc.push(node);
					}

					return acc;
				}, []);

				const root = $getRoot();
				root.clear();
				root.select();
				root.append(...nodes);
				$setSelection(null);
			} catch (err) {
				send(err as Error);
			}
		});
	});

	useEffect(() => {
		if (!isFormDirty) {
			initializeEditor();
		}
	}, [initializeEditor, isFormDirty]);

	return null;
}
