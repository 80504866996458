import { DragEndEvent } from '@dnd-kit/core';

import { getItemDraggableDefinitions } from '@src/components/draggable';
import { Draggable, DraggablePropertyData } from '@src/components/draggable/types';
import { useDraggablesDataContext } from '@src/context/draggableData/draggablesDataContext';
import { useMyApp } from '@src/myContext';
import { isDraggableType } from '@src/pages/appDesign/helper';
import { propertyInputTypeObj } from '@src/pages/itemSettings/utils';

import { AddProperty } from './useMutateProperties';

type AddPropertyDropHandler = (
	event: DragEndEvent,
	draggingItem: Draggable,
	addProperty: AddProperty,
	tempPosition: number,
	newPropertyId?: string
) => void;

export function useAddProperty() {
	const { drop } = useDraggablesDataContext();
	const {
		currentApp: { productVersion },
	} = useMyApp();

	const onAddingPropertyDropHandler: AddPropertyDropHandler = (
		event,
		draggingItem,
		onAddPropertyHandler,
		tempPosition,
		newPropertyId = undefined
	) => {
		const { over } = event;
		if (!over) {
			return;
		}
		if (isDraggableType(draggingItem)) {
			if (draggingItem.type === 'property') {
				drop(draggingItem.name);
				const itemProperties = getItemDraggableDefinitions({
					productVersion,
				}).map(property => property.name);
				if (itemProperties.includes(draggingItem.name)) {
					const propertyData = draggingItem.actions.find(
						x => x.action_type === 'create' && x.model === 'property'
					);
					if (propertyData === undefined) {
						return;
					}
					const data = propertyData.data as DraggablePropertyData;
					const actionBar =
						data.actionBar !== undefined && data.hideOriginalProperty !== undefined
							? {
									position: data.actionBar,
									hideOriginalProperty: data.hideOriginalProperty,
							  }
							: {
									position: 0,
									hideOriginalProperty: false,
							  };

					const { header, icon, action, type, bos } = data;
					const propertyInputType = propertyInputTypeObj[type];

					const newId = newPropertyId ?? crypto.randomUUID();

					const propertyFields = {
						[propertyInputType]: {
							baseProperty: {
								id: newId,
								header,
								icon,
								action,
								actionBar,
								isHidden: false,
								position: tempPosition,
							},
							...bos,
							...draggingItem?.getPropertySpecificFields?.(),
						},
					};

					const insertBeforeId = event.over?.data.current?.id;

					onAddPropertyHandler(propertyFields, newId, insertBeforeId, draggingItem.label);
				}
			}
		}
	};

	return onAddingPropertyDropHandler;
}
