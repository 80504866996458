import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const arrowIconWidth = 32;

export const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		marginRight: '10px',
		padding: 0,
	},
	dropdownSummary: {
		padding: `0 ${theme.SPACING.FIELD_PADDING_H} 0 0`,
	},
	container: {
		border: `1px solid ${theme.colors['grey-400']}`,
		'&:hover': {
			borderColor: theme.colors['border-input-hover'],
		},
		'&:focus, &:focus-within': {
			borderColor: theme.colors['grey-1100'],
		},
		'&:focus:hover, &:focus-within:hover': {
			borderColor: theme.colors['grey-1100'],
		},
	},
	contentContainer: {
		display: 'flex',
		width: '100%',
		color: theme.colors['text-default'],
		fontSize: theme.typography.baseSize,
		minHeight: multiply(theme.SPACING.FIELD_HEIGHT, 2),
		padding: theme.SPACING.FIELD_PADDING,
		paddingRight: 0,
		'& > div:last-of-type': {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
	},
	iconContainer: {
		display: 'flex',
		paddingRight: '5px',
	},
	selection: {
		width: `calc(100% - ${arrowIconWidth}px)`,
	},
	searchInput: {
		'& + svg': {
			width: theme.SPACING.LARGE_ICON_SIZE,
			height: theme.SPACING.LARGE_ICON_SIZE,
		},
	},
	option: {},
	overlay: {},
}));
