import Icon, { IconProps } from '@pushpay/icon';

import { ItemTemplate, ContainerTemplate } from '@src/graphql/generated';

import { LayoutKeys } from './layoutDefinition';

type LayoutIconProps = {
	type: LayoutKeys;
} & IconProps;

export const LayoutIcon = ({ className, type, ...rest }: LayoutIconProps) => {
	const getIcon = (layoutType: LayoutKeys) => {
		switch (layoutType) {
			case ItemTemplate.DetailPlain:
				return (
					<path
						d="M11 10h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0 12h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0 12h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm4-20h44a1 1 0 1 1 0 2H15a1 1 0 1 1 0-2zm0 12h44a1 1 0 1 1 0 2H15a1 1 0 1 1 0-2zm0 12h44a1 1 0 1 1 0 2H15a1 1 0 1 1 0-2z"
						fill="currentColor"
					/>
				);
			case ItemTemplate.DetailSquare:
				return (
					<path
						d="M31 10h12v12H31zM19 24h36a1 1 0 1 1 0 2H19a1 1 0 1 1 0-2zm-8 10h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0-4h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm4 8h44a1 1 0 1 1 0 2H15a1 1 0 1 1 0-2z"
						fill="currentColor"
					/>
				);
			case ItemTemplate.DetailFull:
				return (
					<path
						d="M10 10h54v16H10zm1 24h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0-4h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm4 8h44a1 1 0 1 1 0 2H15a1 1 0 1 1 0-2z"
						fill="currentColor"
					/>
				);
			case ItemTemplate.DetailWebview:
				return (
					<>
						<path
							d="M10 10h54v16H10zm1 24h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0-4h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm4 8h44a1 1 0 1 1 0 2H15a1 1 0 1 1 0-2z"
							fill="currentColor"
						/>
						<path d="M13 22h18a1 1 0 1 1 0 2H13a1 1 0 1 1 0-2z" fill="#fff" />
					</>
				);

			case ContainerTemplate.List_72Dp:
				return (
					<path
						d="M11 10h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0 12h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0 12h52a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0-20h44a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0 12h44a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2zm0 12h44a1 1 0 1 1 0 2H11a1 1 0 1 1 0-2z"
						fill="currentColor"
					/>
				);
			case ContainerTemplate.List_72DpThumb:
				return (
					<path
						d="M10 9h8v8h-8zm0 12h8v8h-8zm0 12h8v8h-8zm13-23h40a1 1 0 1 1 0 2H23a1 1 0 1 1 0-2zm0 4h33.778a1 1 0 1 1 0 2H23a1 1 0 1 1 0-2zm0 8h40a1 1 0 1 1 0 2H23a1 1 0 1 1 0-2zm0 4h33.778a1 1 0 1 1 0 2H23a1 1 0 1 1 0-2zm0 8h40a1 1 0 1 1 0 2H23a1 1 0 1 1 0-2zm0 4h33.778a1 1 0 1 1 0 2H23a1 1 0 1 1 0-2z"
						fill="currentColor"
					/>
				);
			case ContainerTemplate.List_90DpThumb:
				return (
					<path
						d="M10 9h12v8H10zm0 12h12v8H10zm0 12h12v8H10zm17-23h36a1 1 0 1 1 0 2H27a1 1 0 1 1 0-2zm0 4h30.37a1 1 0 1 1 0 2H27a1 1 0 1 1 0-2zm0 8h36a1 1 0 1 1 0 2H27a1 1 0 1 1 0-2zm0 4h30.37a1 1 0 1 1 0 2H27a1 1 0 1 1 0-2zm0 8h36a1 1 0 1 1 0 2H27a1 1 0 1 1 0-2zm0 4h30.37a1 1 0 1 1 0 2H27a1 1 0 1 1 0-2z"
						fill="currentColor"
					/>
				);
			case ContainerTemplate.List_132DpPhoto:
				return <path d="M13 6h48v10H13zm0 14h48v10H13zm0 14h48v10H13z" fill="currentColor" />;
			default:
				return null;
		}
	};

	return (
		<Icon className={className} viewBox="0 0 74 50" {...rest}>
			{getIcon(type)}
		</Icon>
	);
};
