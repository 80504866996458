import { memo } from 'react';

import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { SearchInput } from '@src/components/searchInput';

type SearchProps = {
	value: string;
	onInputChange: (value: string) => void;
	onReset: () => void;
};

export const useStyles = createUseStyles(() => ({
	root: {},
	searchInput: {
		width: '50%',
		maxWidth: '380px',
	},
}));

export const Search = memo(
	({
		value,
		onInputChange,
		onReset,
		classes: classesProp,
		className: classNameProp,
		'data-pp-at-target': targetId,
	}: ComponentProps<SearchProps, typeof useStyles>) => {
		const classes = useStyles(classesProp);
		const className = clsx(classes.root, classNameProp);

		return (
			<SearchInput
				classes={{ root: classes.searchInput }}
				className={className}
				data-pp-at-target={`${targetId}-search`}
				initialValue={value}
				onInputChange={onInputChange}
				onReset={onReset}
			/>
		);
	}
);
