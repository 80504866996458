import { arrayMove } from '@dnd-kit/sortable';
import produce, { Draft } from 'immer';

import { FieldPath, FormState } from '@pushpay/forms/lib/types/types';

import { SaveItemSettingsSchema } from '@src/graphql/generated';
import { ItemPropertiesFields } from '@src/pages/itemSettings/types';

export type DynamicPropertyFields = ItemPropertiesFields[number][];

export const getBaseProperty = (propertyField: ItemPropertiesFields[number]) => {
	const [{ baseProperty }] = Object.values(propertyField);

	return baseProperty;
};

export const getLastKeyAndNestedProperty = (fields: any, fieldPath: FieldPath) => {
	const path = [...fieldPath];
	const lastKey = path.pop();

	const nestedProperty = path.reduce((currentProp, prop) => currentProp[prop], fields);

	return { lastKey, nestedProperty };
};

export const findPropertyIndexById = (dynamicPropertyFields: DynamicPropertyFields, propertyId: string) =>
	dynamicPropertyFields.findIndex(propertyField => {
		const [property] = Object.values(propertyField);

		return property.baseProperty.id.value === propertyId;
	});

export const insertPropertyBeforeId = (
	dynamicPropertyFields: DynamicPropertyFields,
	newProperty: ItemPropertiesFields[number],
	insertBeforeId: string
) => {
	const propertyFields = [...dynamicPropertyFields];
	const insertBeforeIndex = findPropertyIndexById(propertyFields, insertBeforeId);

	propertyFields.splice(insertBeforeIndex, 0, newProperty);

	return propertyFields;
};

export const reorderPositions = (
	dynamicPropertyFields: DynamicPropertyFields,
	newId: string,
	insertBeforeId: string
) => {
	const fromIndex = findPropertyIndexById(dynamicPropertyFields, newId);
	const toIndex = findPropertyIndexById(dynamicPropertyFields, insertBeforeId);

	return arrayMove(dynamicPropertyFields, fromIndex, toIndex);
};

export const alignPositionsSequentially = (
	dynamicPropertyFields: DynamicPropertyFields,
	formState: FormState<SaveItemSettingsSchema>
) => {
	let newPosition = 0;

	return dynamicPropertyFields.reduce((acc, dynamicProperty) => {
		const {
			position: { path },
		} = getBaseProperty(dynamicProperty);

		return produce(acc, (draft: Draft<any>) => {
			const { lastKey, nestedProperty } = getLastKeyAndNestedProperty(draft, path.concat('value'));

			if (nestedProperty && lastKey) {
				nestedProperty[lastKey] = newPosition;
				newPosition++;
			}
		});
	}, formState);
};

export const alignPositionsSequentiallyBeforeDeletion = (
	dynamicPropertyFields: DynamicPropertyFields,
	formState: FormState<SaveItemSettingsSchema>,
	propertyId: string
) => {
	let newPosition = 0;

	return dynamicPropertyFields.reduce((acc, dynamicProperty) => {
		const {
			position: { path },
			id: { value: fieldId },
		} = getBaseProperty(dynamicProperty);

		return produce(acc, (draft: Draft<any>) => {
			const { lastKey, nestedProperty } = getLastKeyAndNestedProperty(draft, path.concat('value'));

			if (fieldId !== propertyId && lastKey && nestedProperty) {
				nestedProperty[lastKey] = newPosition;
				newPosition++;
			}
		});
	}, formState);
};
