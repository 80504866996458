import React, {
	PropsWithChildren,
	MouseEvent as ReactMouseEvent,
	KeyboardEvent,
	Ref,
	createContext,
	RefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	useContext,
} from 'react';

import { Button } from '@pushpay/button';
import { ComponentProps } from '@pushpay/types';

import { useStyles } from './DropDownStyle';

type DropDownContextType = {
	registerItem: (ref: RefObject<HTMLButtonElement>) => void;
};
type ClassesProp = ReturnType<typeof useStyles>;

const DropDownContext = createContext<DropDownContextType | null>(null);

type DropDownItem = ComponentProps<
	{
		onClick: (event: ReactMouseEvent<HTMLButtonElement>) => void;
		title?: string;
	},
	undefined
>;

type DropDownItems = PropsWithChildren<{
	dropDownRef: Ref<HTMLDivElement>;
	classes: ClassesProp;
	onClose: () => void;
}>;

type DropDown = PropsWithChildren<{
	disabled?: boolean;
	buttonAriaLabel?: string;
	buttonClassName: string;
	buttonIconClassName?: string;
	buttonLabel?: string;
	stopCloseOnClickSelf?: boolean;
}>;

export function DropDownItem({ children, className, onClick, title }: DropDownItem) {
	const ref = useRef<HTMLButtonElement>(null);

	const dropDownContext = useContext(DropDownContext);

	if (dropDownContext === null) {
		throw new Error('DropDownItem must be used within a DropDown');
	}

	const { registerItem } = dropDownContext;

	useEffect(() => {
		if (ref.current !== null) {
			registerItem(ref);
		}
	}, [registerItem]);

	return (
		<Button ref={ref} className={className} title={title} type="button" onClick={onClick}>
			{children}
		</Button>
	);
}

function DropDownItems({ children, dropDownRef, classes, onClose }: DropDownItems) {
	const [items, setItems] = useState<RefObject<HTMLButtonElement>[]>();
	const [highlightedItem, setHighlightedItem] = useState<RefObject<HTMLButtonElement>>();

	const registerItem = useCallback(
		(itemRef: RefObject<HTMLButtonElement>) => {
			setItems(prev => (prev ? [...prev, itemRef] : [itemRef]));
		},
		[setItems]
	);

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (!items) return;

		const { key } = event;

		if (['Escape', 'ArrowUp', 'ArrowDown', 'Tab'].includes(key)) {
			event.preventDefault();
		}

		if (key === 'Escape' || key === 'Tab') {
			onClose();
		} else if (key === 'ArrowUp') {
			setHighlightedItem(prev => {
				if (!prev) return items[0];
				const index = items.indexOf(prev) - 1;
				return items[index === -1 ? items.length - 1 : index];
			});
		} else if (key === 'ArrowDown') {
			setHighlightedItem(prev => {
				if (!prev) return items[0];
				return items[items.indexOf(prev) + 1];
			});
		}
	};

	const contextValue = useMemo(
		() => ({
			registerItem,
		}),
		[registerItem]
	);

	useEffect(() => {
		if (items && !highlightedItem) {
			setHighlightedItem(items[0]);
		}

		if (highlightedItem && highlightedItem.current) {
			highlightedItem.current.focus();
		}
	}, [items, highlightedItem]);

	return (
		<DropDownContext.Provider value={contextValue}>
			<div ref={dropDownRef} className={classes.dropdown} role="button" tabIndex={0} onKeyDown={handleKeyDown}>
				{children}
			</div>
		</DropDownContext.Provider>
	);
}

export function DropDown({
	disabled = false,
	buttonLabel,
	buttonAriaLabel,
	buttonClassName,
	buttonIconClassName,
	children,
	stopCloseOnClickSelf,
}: DropDown): React.JSX.Element {
	const classes = useStyles(undefined);
	const dropDownRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [showDropDown, setShowDropDown] = useState(false);

	const handleClose = () => {
		setShowDropDown(false);
		if (buttonRef && buttonRef.current) {
			buttonRef.current.focus();
		}
	};

	useEffect(() => {
		const button = buttonRef.current;
		const dropDown = dropDownRef.current;

		if (showDropDown && button !== null && dropDown !== null) {
			dropDown.style.left = `${dropDown.offsetWidth + 20}px`;
		}
	}, [showDropDown]);

	useEffect(() => {
		const button = buttonRef.current;

		if (button !== null && showDropDown) {
			const handle = (event: MouseEvent) => {
				const { target } = event;
				if (stopCloseOnClickSelf) {
					if (dropDownRef.current && dropDownRef.current.contains(target as Node)) return;
				}
				if (!button.contains(target as Node)) {
					setShowDropDown(false);
				}
			};
			document.addEventListener('click', handle);

			return () => {
				document.removeEventListener('click', handle);
			};
		}

		return undefined;
	}, [showDropDown, stopCloseOnClickSelf]);

	return (
		<>
			<Button
				ref={buttonRef}
				aria-label={buttonAriaLabel || buttonLabel}
				className={buttonClassName}
				disabled={disabled}
				type="button"
				onClick={() => setShowDropDown(!showDropDown)}
			>
				{buttonIconClassName && <span className={buttonIconClassName} />}
				{buttonLabel && <span className={classes.text}>{buttonLabel}</span>}
				<i className={classes.chevronDown} />
			</Button>

			{showDropDown && (
				<DropDownItems classes={classes} dropDownRef={dropDownRef} onClose={handleClose}>
					{children}
				</DropDownItems>
			)}
		</>
	);
}
