import { useCallback, useMemo, useState } from 'react';

import { Button } from '@pushpay/button';
import { ComponentProps } from '@pushpay/types';

import { SnackBar } from '@src/components/snackBar';
import { useCopyAndMoveDispatchContext } from '@src/context';
import { useTranslation } from '@src/i18n';
import { useConfirmModal } from '@src/pages/containerSettings/components/useConfirmModal';

import { ConfirmationDialog, DestinationModal } from '../modals';
import { SelectedContainer } from '../modals/DestinationModal';
import { Card, CardProps } from './Card';
import useStyles from './cardListStyles';
import { useCardState } from './hooks/useCardState';

const MAX_ITEMS_TO_SELECT = 20;

export type CardListProps = ComponentProps<
	{
		cards: CardProps[];
		parentContainerId: string;
		isRootContainer?: boolean;
	},
	typeof useStyles
>;

export const CardList = ({
	cards,
	parentContainerId,
	isRootContainer = false,
	classes: classesProp,
	'data-pp-at-target': targetId,
}: CardListProps) => {
	const classes = useStyles(classesProp);
	const {
		selectItem,
		unselectItem,
		hasItemWithId,
		hasContainerInList,
		hasRestrictedRootTypes,
		getItemCount,
		clear,
		copyItemsHandler,
		getSelectedItems,
		moveChildrenHandler,
	} = useCopyAndMoveDispatchContext();
	const { translate } = useTranslation('appDesign');
	const { translate: commonTranslate } = useTranslation('common');

	const totalSelectedCards = getItemCount();
	const hasSelectedCards = totalSelectedCards > 0;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMovingUsingKebabMenu, setIsMovingUsingKebabMenu] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen, onProceed, onModalReset] = useConfirmModal(hasSelectedCards);
	const { isRecentlyCopied, isRecentlyCreated, isRecentlyMoved } = useCardState();

	let isCopyDisabled = true;
	let copyTooltipContent;

	if (isRootContainer) {
		copyTooltipContent = commonTranslate('copyAndMove.errors.copyRootContainer');
	} else if (hasContainerInList()) {
		copyTooltipContent = commonTranslate('copyAndMove.errors.copyContainer');
	} else {
		isCopyDisabled = false;
	}

	const selectedContainers = getSelectedItems();
	const { rootOnlyChildName } =
		selectedContainers.find(({ rootOnlyChildName: rootChildName }) =>
			rootChildName !== '' ? rootChildName : undefined
		) ?? {};
	const isMoveDisabled = rootOnlyChildName !== undefined;

	const moveTooltipContent = commonTranslate('copyAndMove.errors.rootChildMoveTooltip', {
		draggableName: isMoveDisabled ? rootOnlyChildName : '',
	});

	const isUnderSelectionLimit = totalSelectedCards < MAX_ITEMS_TO_SELECT;

	const selectedContainerIds = useMemo(
		() =>
			getSelectedItems()
				.filter(({ type }) => type === 'container')
				.map(({ id }) => id),
		[getSelectedItems]
	);

	const scrollToLastSelectedPosition = useCallback((target: HTMLDivElement | undefined) => {
		target?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}, []);

	const onCardSelect = useCallback(
		(isCardSelected: boolean, selectedCard: CardProps) => {
			setIsMovingUsingKebabMenu(false);

			if (isCardSelected && isUnderSelectionLimit) {
				return selectItem(selectedCard);
			}

			return unselectItem(selectedCard.id);
		},
		[selectItem, unselectItem, isUnderSelectionLimit]
	);

	const onCloseSnackBar = () => {
		setIsConfirmModalOpen(true);
	};

	const onContinueSelecting = () => {
		setIsConfirmModalOpen(false);
		onModalReset();
	};

	const onClearSelection = () => {
		clear();
		setIsConfirmModalOpen(false);
		onProceed();
	};

	const getSelectedItemsSortedByIndex = useCallback(() => {
		const cardIndexList = new Map(Array.from(cards, ({ id }, index) => [id, index]));
		const selectedCards = getSelectedItems();

		return selectedCards.sort((a, b) => (cardIndexList.get(a.id) as number) - (cardIndexList.get(b.id) as number));
	}, [cards, getSelectedItems]);

	const onCopyItems = useCallback(() => {
		const selectedItemsSorted = getSelectedItemsSortedByIndex();

		if (selectedItemsSorted.length) {
			const lastSelectedItemByIndex = selectedItemsSorted.at(-1);

			copyItemsHandler({ containerId: parentContainerId, items: selectedItemsSorted });

			if (lastSelectedItemByIndex) {
				scrollToLastSelectedPosition(lastSelectedItemByIndex.target);
			}
		}
	}, [copyItemsHandler, getSelectedItemsSortedByIndex, parentContainerId, scrollToLastSelectedPosition]);

	const onMoveChildrenBySelectingCards = () => {
		setIsModalOpen(true);
	};

	const onMoveChildByKebabMenu = useCallback(
		(card: CardProps) => {
			setIsModalOpen(true);
			setIsMovingUsingKebabMenu(true);
			selectItem(card);
		},
		[selectItem]
	);

	const onMoveCloseHandler = () => {
		setIsModalOpen(false);

		if (isMovingUsingKebabMenu) {
			clear();
			setIsMovingUsingKebabMenu(false);
		}
	};

	const onMoveSelectHandler = useCallback(
		(newParentContainer: SelectedContainer) => {
			setIsModalOpen(false);
			setIsMovingUsingKebabMenu(false);

			const selectedCards = getSelectedItemsSortedByIndex();
			const selectedChildren = selectedCards.map(card => ({ childId: card.id }));

			if (selectedChildren.length === 0) return;

			moveChildrenHandler({
				parentContainerId,
				newParentContainer,
				selectedChildren,
			});
		},
		[getSelectedItemsSortedByIndex, moveChildrenHandler, parentContainerId]
	);

	return (
		<>
			<div aria-describedby="Card List" className={classes.list} data-pp-at-target={targetId}>
				{cards.map(item => {
					const isChecked = hasItemWithId(item.id) && !isMovingUsingKebabMenu;
					const isCardDisabled = !isChecked && !isUnderSelectionLimit;
					const shouldCardBeHighlighted =
						isRecentlyCreated(item) || isRecentlyCopied(item) || isRecentlyMoved(item);

					return (
						<Card
							{...item}
							key={item.id}
							data-pp-at-target={targetId}
							isAnyOtherCardSelected={hasSelectedCards}
							isCardDisabled={isCardDisabled}
							isChecked={isChecked}
							isHighlighted={shouldCardBeHighlighted}
							onCardSelect={onCardSelect}
							onMoveChild={onMoveChildByKebabMenu}
						/>
					);
				})}
			</div>
			<SnackBar
				copyAction={{
					isDisabled: isCopyDisabled,
					buttonHandler: onCopyItems,
					tooltipContent: copyTooltipContent,
				}}
				isVisible={!isMovingUsingKebabMenu && totalSelectedCards > 0}
				maxItemsToSelect={MAX_ITEMS_TO_SELECT}
				moveAction={{
					isDisabled: isMoveDisabled,
					buttonHandler: onMoveChildrenBySelectingCards,
					tooltipContent: isMoveDisabled ? moveTooltipContent : undefined,
				}}
				totalSelected={totalSelectedCards}
				onClose={onCloseSnackBar}
			/>
			<ConfirmationDialog
				bodyText={translate('selectCardConfirmationDialog.modalText')}
				cancelButtonHandler={onContinueSelecting}
				cancelButtonLabel={translate('selectCardConfirmationDialog.cancel')}
				closeButtonHandler={onContinueSelecting}
				confirmButton={
					<Button displayStyle="primary" type="button" onClick={onClearSelection}>
						{translate('selectCardConfirmationDialog.confirm')}
					</Button>
				}
				isConfirmModalOpen={isConfirmModalOpen}
				setIsConfirmModalOpen={setIsConfirmModalOpen}
				title={translate('selectCardConfirmationDialog.modalTitle')}
			/>
			{isModalOpen && (
				<DestinationModal
					actionType="move"
					disabledContainers={selectedContainerIds}
					hasRestrictedRootTypes={hasRestrictedRootTypes()}
					isModalOpen={isModalOpen}
					isRootContainer={isRootContainer}
					parentContainerId={parentContainerId}
					totalSelection={getItemCount()}
					onCloseButton={onMoveCloseHandler}
					onSelectButton={onMoveSelectHandler}
				/>
			)}
		</>
	);
};
