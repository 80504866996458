import { useEffect, useState } from 'react';

import { ComponentProps } from '@pushpay/types';

import { useGlobalContext, useErrorContext } from '@src/context';
import { CampusSelector } from '@src/pages/appChrome/components/topNav/components/selectors';
import { useParams } from '@src/router';

import { DarkModeToggle } from '../DarkModeToggle';
import { AppPreview } from './contents';
import { useStyles } from './previewStyles';

type PreviewProps = ComponentProps<unknown, typeof useStyles>;

export const Preview = ({ classes: classesProp }: PreviewProps) => {
	const classes = useStyles(classesProp);
	const [isDarkMode, setIsDarkMode] = useState(false);
	const { organizationKey } = useParams<'organizationKey'>();
	const { application } = useGlobalContext();
	const { isPageNotFoundError } = useErrorContext();

	useEffect(() => {
		setIsDarkMode(false);
	}, [organizationKey, application]);

	if (isPageNotFoundError) {
		return null;
	}

	return (
		<div className={classes.previewContainer}>
			<CampusSelector
				classes={{ root: classes.campusSelector, container: classes.campusSelectorOverlay }}
				data-pp-at-target="app-campus-selector"
			/>
			<div className={classes.preview}>
				<div className={classes.previewContent}>
					<AppPreview isDarkMode={isDarkMode} />
				</div>
				<div className={classes.phoneChrome} />
				<DarkModeToggle
					data-pp-at-target="preview-dark-mode-toggle"
					isDarkMode={isDarkMode}
					setIsDarkMode={setIsDarkMode}
				/>
			</div>
		</div>
	);
};
