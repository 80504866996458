import { forwardRef, useImperativeHandle } from 'react';

import { Field, useMutateField } from '@pushpay/forms';

import { ImageFieldContext } from '@src/context';
import { useTranslation } from '@src/i18n';
import { useFileUploadMutation } from '@src/shared/hooks';
import { useEvent } from '@src/utils';

import ImageUploader from '../imageUploader';
import { useShowNotification } from '../notification';

type ImageFieldProps = {
	field: Field<string>;
	imageType: string;
	isProcessing: boolean;
	readOnly?: boolean;
	isDisabled?: boolean;
	fieldLabel?: string;
};

export type ImageFieldRef = {
	uploadFile: () => Promise<boolean>;
	resetField: (val?: string) => void;
};

export const ImageField = forwardRef<ImageFieldRef, ImageFieldProps>(
	({ field, imageType, isProcessing, readOnly = false, isDisabled, fieldLabel }, ref) => {
		const { mutateField: mutateImageUrl } = useMutateField(field);
		const { translate } = useTranslation('common');
		const showNotification = useShowNotification();

		const updateField = useEvent((val: any) => {
			if (val !== field.value) {
				mutateImageUrl(typeof val === 'string' ? val : '');
			}
		});

		const { selectedFile, setSelectedFile, onFileSelect, uploadFile } = useFileUploadMutation({
			updateField,
		});

		useImperativeHandle(ref, () => ({
			uploadFile: async () => {
				const success = await uploadFile();

				if (!success) {
					showNotification({
						isOpen: true,
						type: 'error',
						title: translate('errors.title.error'),
						content: translate('errors.text.fileUploadError', { file: imageType }),
					});
				}

				return success;
			},
			resetField: val => {
				setSelectedFile(val);
			},
		}));

		// show unsaved selected image if it exists, else show saved image
		const image = selectedFile ?? field.value;

		if (readOnly && !image) {
			return null;
		}

		return (
			<ImageFieldContext.Provider value={{ isProcessing }}>
				<ImageUploader
					data-pp-at-target="image-field-uploader"
					fieldLabel={fieldLabel}
					imageUrl={image}
					isDisabled={isDisabled}
					readOnly={readOnly}
					onImageSelect={onFileSelect}
				/>
			</ImageFieldContext.Provider>
		);
	}
);
