import { isVimeo, isYouTube, CONSTANTS } from '@src/pages/itemSettings/utils';

import { PropertyInputType, PropertyInputValue, VideoProperty } from '../types';

const { YOUTUBE_ICON, VIMEO_ICON } = CONSTANTS;
export const getPropertyIcon = <T extends PropertyInputType>(type: T, property: PropertyInputValue<T>) => {
	const icon = property.baseProperty.icon?.value || '';

	if (type === 'videoPropertyInput') {
		const {
			url: { value: url },
		} = property as VideoProperty;

		if (isYouTube(url || '')) {
			return YOUTUBE_ICON;
		}

		if (isVimeo(url || '')) {
			return VIMEO_ICON;
		}

		return icon;
	}

	return icon;
};
