import { useCallback } from 'react';

import { AppSelectorIcon } from '@pushpay/iconography';
import { Option } from '@pushpay/inputs';
import { ComponentProps } from '@pushpay/types';

import { Selector } from '@src/components/selectors/Selector';
import { useGlobalContext } from '@src/context/globalContext';
import { useMyOrganization } from '@src/myContext';

import { useStyles } from './selectorStyles';

export const AppSelector = ({ 'data-pp-at-target': targetId }: ComponentProps<unknown, undefined>) => {
	const { application: currentApplication, updateGlobalContext } = useGlobalContext();
	const { applications } = useMyOrganization();

	const options: Option<string>[] = applications.map(app => ({
		display: app.name,
		value: app.id,
	}));

	const classes = useStyles(undefined);

	const onChange = useCallback(
		(applicationId: string) => {
			const appCampuses = applications.find(app => app.id === applicationId)?.campuses || [];
			updateGlobalContext({ application: applicationId, campus: appCampuses[0]?.id || null });
		},
		[applications, updateGlobalContext]
	);

	return (
		<Selector
			classes={classes}
			data-pp-at-target={targetId}
			dropdownAlignment="left"
			getOptionKey={option => option.value}
			icon={AppSelectorIcon}
			options={options}
			showSearch={options.length > 10}
			sortOrder="asc"
			value={currentApplication}
			onChange={onChange}
		/>
	);
};
