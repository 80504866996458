import { WebSocketLink } from '@apollo/client/link/ws';

type CreateWebSocketLinkOptions = {
	uri: string;
	getToken: () => Promise<string | undefined>;
};

export function createWebSocketLink({ uri, getToken }: CreateWebSocketLinkOptions) {
	return new WebSocketLink({
		uri,
		options: {
			lazy: true,
			reconnect: true,
			connectionParams: async () => ({
				authToken: await getToken(),
			}),
		},
	});
}
