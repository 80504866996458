import { createContext, FC, useContext, useMemo, useState, useEffect, PropsWithChildren, useCallback } from 'react';

import {
	GetContainerChildrenQuery,
	GetContainerSettingsQuery,
	GetItemQuery,
	GetDhsCardsQuery,
} from '@src/graphql/generated';

export enum ContentType {
	DHS,
	CONTAINER_CHILDREN,
	CONTAINER_SETTING,
	ITEM,
	ROOT,
}

type ContentStateContext = {
	type: ContentType | undefined;
	contentData: GetItemQuery | GetContainerChildrenQuery | GetContainerSettingsQuery | GetDhsCardsQuery | undefined;
	error: boolean;
	loading: boolean;
	loadingPreview: boolean;
};

type ContentDispatchContext = {
	useSetContent: (
		{ type, contentData, error, loading }: Omit<ContentStateContext, 'loadingPreview'>,
		deferUpdate?: boolean
	) => void;
};

const ContentStateContext = createContext<ContentStateContext | null>(null);
ContentStateContext.displayName = 'ContentStateContext';

const ContentDispatchContext = createContext<ContentDispatchContext | null>(null);
ContentDispatchContext.displayName = 'ContentDispatchContext';

const ContentContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [content, setContent] = useState<ContentStateContext>({
		type: undefined,
		contentData: undefined,
		error: false,
		loading: true,
		loadingPreview: false,
	});

	const updateContent: ContentDispatchContext['useSetContent'] = useCallback((newContent, deferUpdate) => {
		if (deferUpdate && newContent.loading) {
			setContent(prev => ({ ...prev, loading: true }));
		} else {
			setContent({ ...newContent, loading: false, loadingPreview: newContent.loading });
		}
	}, []);

	const contentStateContextValue = useMemo<ContentStateContext>(
		() => ({
			...content,
		}),
		[content]
	);

	const contentDispatchContextValue: ContentDispatchContext = useMemo(
		() => ({
			useSetContent: ({ type, contentData, error, loading }, deferUpdate = true) => {
				useEffect(() => {
					updateContent({ type, contentData, error, loading }, deferUpdate);
				}, [type, contentData, error, loading, deferUpdate]);
			},
		}),
		[updateContent]
	);

	return (
		<ContentStateContext.Provider value={contentStateContextValue}>
			<ContentDispatchContext.Provider value={contentDispatchContextValue}>
				{children}
			</ContentDispatchContext.Provider>
		</ContentStateContext.Provider>
	);
};

function useContentStateContext(): ContentStateContext {
	const context = useContext(ContentStateContext);
	if (!context) {
		throw new Error('useContentStateContext must be used within a ContentContext');
	}
	return context;
}

function useContentDispatchContext(): ContentDispatchContext {
	const context = useContext(ContentDispatchContext);
	if (!context) {
		throw new Error('useContentDispatchContext must be used within a ContentContext');
	}
	return context;
}

export { ContentContextProvider, ContentStateContext, useContentStateContext, useContentDispatchContext };
