import { FC, PropsWithChildren } from 'react';

import { AccessDeniedError } from '@src/components/errors/AccessDeniedError';
import { useTranslation } from '@src/i18n';
import { useMyPermissions } from '@src/myContext';

export const EnforceMasAccess: FC<PropsWithChildren> = ({ children }) => {
	const { hasAppStudioPermission } = useMyPermissions();
	const { translate } = useTranslation();

	if (hasAppStudioPermission) {
		return <>{children}</>;
	}

	return <AccessDeniedError error={translate('errors.text.noAppStudioAccessError')} />;
};
