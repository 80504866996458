import { DragDropIcon } from '@pushpay/iconography';
import { ComponentProps } from '@pushpay/types';

import { CardHeader } from '@src/components/card';
import { DraggableIcon } from '@src/components/draggable';
import { Icon as IconComponent } from '@src/components/icon';
import { CONSTANTS } from '@src/pages/itemSettings/utils';

import { useStyles } from './propertyHeaderStyles';

export type PropertyHeaderProps = ComponentProps<
	{
		id: string;
		icon: string;
		header: string;
		type: typeof CONSTANTS.PROPERTY_TYPE;
	},
	typeof useStyles
>;

export const PropertyHeader = ({ classes: classesProp, icon, header }: PropertyHeaderProps) => {
	const classes = useStyles(classesProp);

	return (
		<div className={classes.card}>
			<div className={classes.header}>
				<div className={classes.title}>
					<div className={classes.headerGroup}>
						<DragDropIcon className={classes.dragHandle} displaySize="small" />
						<IconComponent classes={{ root: classes.icon }} name={icon as DraggableIcon} />
						<CardHeader classes={{ header: classes.label }} headerText={header} />
					</div>
				</div>
			</div>
		</div>
	);
};
