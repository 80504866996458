import { useEffect } from 'react';

import { Button } from '@pushpay/button';
import { EmptyTableIllustration } from '@pushpay/illustration';
import { Heading } from '@pushpay/layout';
import { clsx } from '@pushpay/styles';

import { Icon } from '@src/components/icon';
import { GetContainerChildrenQuery, useGetContainerChildrenQuery } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { CONTAINER_CHILDREN_PAGING_SIZE } from '@src/pages/constants';
import { ContainerChildrenLoader } from '@src/pages/containerChildren';

import { CardList } from './CardList';
import useStyles from './navigationStyles';
import { NavigationProps } from './types';

export type ChildrenNavigationProps = Omit<NavigationProps, 'parentContainerId'> & {
	currentContainerId: string;
	onGoBack: () => void;
};

export type ParentContainer = NonNullable<
	NonNullable<GetContainerChildrenQuery['organization']>['application']
>['container'];

export const ChildrenNavigation = ({
	organizationKey,
	platformCampusKey,
	applicationId,
	currentContainerId,
	setCurrentContainer,
	onGoBack,
	onTriggerScroll,
	isInitialContainer,
	...props
}: ChildrenNavigationProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const {
		loading: isLoadingContainer,
		data,
		networkStatus,
		fetchMore,
	} = useGetContainerChildrenQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-first',
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId: currentContainerId,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
	});

	const isLoading = isLoadingContainer && !data;

	useEffect(() => {
		if (!isLoadingContainer) {
			onTriggerScroll();
		}
	}, [isLoadingContainer, onTriggerScroll]);

	const currentContainer = data?.organization?.application?.container;
	const childrenTree = currentContainer?.children;

	setCurrentContainer({
		container: currentContainer,
		isRootContainer: false,
	});

	const onLoadMore = (size: number) =>
		fetchMore({
			variables: {
				paging: {
					size,
					after: childrenTree?.paging.nextCursor,
				},
			},
		});

	return (
		<>
			<div className={clsx(classes.title, classes.bold)}>
				{!isLoading && (
					<>
						<Button displayStyle="unstyled" type="button" onClick={onGoBack}>
							<Icon classes={{ root: classes.backIcon }} name="arrow-31" />
							<span>{currentContainer?.name}</span>
						</Button>
						{isInitialContainer && (
							<div className={classes.initialLocationTag}>
								{translate('destinationModal.currentLocation')}
							</div>
						)}
					</>
				)}
			</div>
			{!isLoading && childrenTree?.nodes.length === 0 ? (
				<div className={classes.emptyList}>
					<EmptyTableIllustration background="white" className={classes.emptyIllustration} />
					<Heading classes={{ root: classes.heading }} level="3">
						{translate('containerChildren.emptyIllustrationTitle')}
					</Heading>
				</div>
			) : (
				<ContainerChildrenLoader data={childrenTree} networkStatus={networkStatus} onLoadMore={onLoadMore}>
					<CardList
						cards={childrenTree?.nodes}
						destinationContainer={props.selectedContainer}
						parentDestinationContainer={currentContainer}
						{...props}
					/>
				</ContainerChildrenLoader>
			)}
		</>
	);
};
