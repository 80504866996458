import { FormState } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { SaveItemSettingsSchema } from '@src/graphql/generated';

import { IconField } from './IconField';

type PropertyIconFieldProps = ComponentProps<
	{
		field: FormState<SaveItemSettingsSchema>['input']['icon'];
	},
	typeof useStyles
>;

const useStyles = createUseStyles((theme: Theme) => ({
	field: {
		marginRight: theme.SPACING.SMALL,
		marginTop: 0,
	},
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
	},
}));

export const PropertyIconField = ({ classes: classesProp, field }: PropertyIconFieldProps) => {
	const classes = useStyles(classesProp);

	return <IconField classes={classes} field={field} isReadOnly={false} />;
};
