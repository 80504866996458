import { FC, PropsWithChildren } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import { AuthProvider } from '@pushpay/auth';
import { OnClickOutsideProvider } from '@pushpay/utils';

import CacheBuster from '@src/components/cacheBuster';
import { ErrorBoundary, GeneralError, AccessDeniedError } from '@src/components/errors';
import { NotificationProvider } from '@src/components/notification';
import { TestWidget } from '@src/components/testWidget';
import { AppStudioApolloProvider } from '@src/graphql/client';
import { getAppSettings } from '@src/utils';

import { Me } from './Me';
import { ScrollToTop } from './ScrollToTop';

const AuthProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
	const { authConfiguration } = getAppSettings();
	const navigate = useNavigate();

	return (
		<AuthProvider
			config={authConfiguration}
			redirect={url => navigate(url, { replace: true })}
			renderError={() => <AccessDeniedError />}
		>
			{children}
		</AuthProvider>
	);
};

export const Shell = () => (
	<AuthProviderWrapper>
		<ErrorBoundary fallback={GeneralError}>
			<CacheBuster />
			<NotificationProvider>
				<AppStudioApolloProvider>
					<OnClickOutsideProvider>
						<Me>
							<ScrollToTop>
								<Outlet />
							</ScrollToTop>
						</Me>
						<TestWidget />
					</OnClickOutsideProvider>
				</AppStudioApolloProvider>
			</NotificationProvider>
		</ErrorBoundary>
	</AuthProviderWrapper>
);
