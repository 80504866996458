import { Field, SelectField } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { useTranslation } from '@src/i18n';

type FeaturedLabelFieldProps = {
	field: Field<string>;
};

const useStyle = createUseStyles((theme: Theme) => ({
	content: {
		flex: '1 1 auto',
	},
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
	},
}));

export const DEFAULT_FEATURED_LABEL = 'Featured';

export const FeaturedLabelField = ({ field }: FeaturedLabelFieldProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyle(undefined);
	const { featuredLabelOptions } = useDhsSettingsOptions();

	return (
		<SelectField
			classes={{ content: classes.content, label: classes.label }}
			defaultValue={DEFAULT_FEATURED_LABEL}
			field={field}
			label={translate('itemSetting.dhsSetting.featuredLabel')}
			options={featuredLabelOptions}
			showLabel
		/>
	);
};

function useDhsSettingsOptions() {
	const { translate } = useTranslation('appDesign');

	const featuredLabelOptions = [
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.noLabel'), value: '' },
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.featured'), value: 'Featured' },
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.recommended'), value: 'Recommended' },
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.special'), value: 'Special' },
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.highlight'), value: 'Highlight' },
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.new'), value: 'New' },
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.promo'), value: 'Promo' },
		{ display: translate('itemSetting.dhsSetting.featuredLabelOptions.spotlight'), value: 'Spotlight' },
	];

	return {
		featuredLabelOptions,
	};
}
