import { useRef } from 'react';

import Expand, { useExpand } from '@pushpay/expand';
import Notification from '@pushpay/notification';

import { ExpanderButton } from '../buttons';
import { useStyles } from './expandableNotificationStyles';

type ExpandableNotificationProps = {
	title: string;
	content: string;
};

export const ExpandableNotification = ({ title, content }: ExpandableNotificationProps) => {
	const classes = useStyles(undefined);
	const [expandStatus, toggleExpand] = useExpand('expanded');
	const notificationWrapperRef = useRef(null);

	return (
		<>
			<div ref={notificationWrapperRef} className={classes.notificationWrapper} />
			<Notification
				classes={{
					root: classes.root,
					icon: classes.icon,
					message: classes.contentWrapper,
					title: classes.title,
					content: classes.content,
				}}
				closeButtonAriaLabel=""
				title={title}
				type="info"
				wrapper={notificationWrapperRef}
				isOpen
			>
				<div className={classes.expandButton}>
					<ExpanderButton
						displayStyle="unstyled"
						expanded={expandStatus === 'expanded'}
						toggle={toggleExpand}
					/>
				</div>
				<Expand.Panel expandStatus={expandStatus} overflowVisible>
					{content}
				</Expand.Panel>
			</Notification>
		</>
	);
};
