import { MouseEvent, Ref } from 'react';

import { Moment } from 'moment';

import { TimePicker, TimePickerMethods } from '@pushpay/inputs';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { forwardRef } from '@pushpay/types';
import { useForwardedRef } from '@pushpay/utils';

import { DraggableIcon } from '@src/components/draggable';
import { useTranslation } from '@src/i18n';

import { InputField, InputFieldProps } from './InputField';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexGrow: 1,
	},
	input: {
		flexGrow: 1,
	},
	time: {
		padding: `0 ${theme.SPACING.FIELD_PADDING_H}`,
		marginLeft: theme.SPACING.XXSMALL,
		backgroundColor: theme.colors['background-surface'],
		border: `${theme.SPACING.FIELD_BORDER_WIDTH} solid ${theme.colors['border-input']}`,
		borderRadius: theme.SHAPE.ROUNDED_CORNERS,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	timeReadOnly: {
		backgroundColor: theme.colors['background-input-readonly'],
		borderColor: theme.colors['border-input-disabled'],
	},
	icon: {
		fontSize: 16,
	},
}));

export type TimeInputFieldProps = Omit<InputFieldProps, 'value' | 'onChange'> & {
	value?: Moment;
	format?: string;
	onChange?: (value?: Moment) => void;
	disableClear?: boolean;
};

const noOp = () => {};
export const TimeInputField = forwardRef((props: TimeInputFieldProps, ref: Ref<TimePickerMethods>) => {
	const { value, format = 'hh:mm A', onChange = noOp, readOnly, disableClear, ...rest } = props;

	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');

	const [bindRef, localRef] = useForwardedRef<TimePickerMethods>(ref);

	const onIconPress = (event: MouseEvent<HTMLDivElement>) => {
		if (!disableClear && value !== undefined) {
			event.preventDefault();
			event.stopPropagation();

			localRef.current?.clear();
		}
	};

	const getIconAfter = (): DraggableIcon => {
		if (disableClear) {
			return 'clock-2';
		}

		return value !== undefined ? 'close' : 'clock-2';
	};

	return (
		<div className={classes.root}>
			<TimePicker
				ref={bindRef}
				alignment="left"
				amLabel={translate('timepicker.am')}
				classes={{ root: classes.input }}
				closeButtonLabel={translate('timepicker.close')}
				pmLabel={translate('timepicker.pm')}
				value={value}
				onClose={onChange}
			>
				<TimePicker.Trigger disabled={readOnly}>
					<InputField
						classes={{ root: classes.input }}
						iconAfter={getIconAfter()}
						readOnly={readOnly}
						value={value?.format(format) || ''}
						onIconAfterPress={onIconPress}
						{...rest}
					/>
				</TimePicker.Trigger>
			</TimePicker>
		</div>
	);
});
