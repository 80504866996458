import Tooltip from '@pushpay/tooltip';

import { KebabMenuItem } from '@src/components/kebabMenu/KebabMenu';

type VisibilityToggleProps = {
	label: string;
	isDisabled: boolean;
	onClick?: () => void;
	tooltipLabel?: string;
	targetId?: string;
};

export const VisibilityToggle = ({ label, isDisabled, onClick, tooltipLabel, targetId }: VisibilityToggleProps) => {
	const type = isDisabled ? 'disabled' : 'common';

	if (tooltipLabel) {
		return (
			<Tooltip
				content={tooltipLabel}
				data-pp-at-target={`${targetId}-hidden-tooltip`}
				panelSpace="XSMALL"
				placement="left"
			>
				<KebabMenuItem label={label} type={type} onClick={onClick} />
			</Tooltip>
		);
	}

	return <KebabMenuItem label={label} type={type} onClick={onClick} />;
};
