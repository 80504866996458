import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

/**
 * returns whether the target element's content is larger than its visible width
 */
export function useIsOverflowed() {
	const target = useRef<HTMLDivElement>(null);
	const [isOverflowed, setIsOverflowed] = useState(false);

	const checkOverflowed = useCallback(() => {
		setIsOverflowed((target.current && target.current.scrollWidth > target.current.clientWidth) || false);
	}, []);

	useLayoutEffect(() => {
		checkOverflowed();
	}, [checkOverflowed]);

	useResizeObserver(target, () => {
		checkOverflowed();
	});

	return [target, isOverflowed] as const;
}
