import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useSearchInputStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	field: {
		height: multiply(theme.SPACING.FIELD_HEIGHT, 2),
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	button: {
		padding: `0 ${multiply(theme.SPACING.XSMALL, 1.5)}`,
		borderRadius: `0 ${theme.SHAPE.ROUNDED_CORNERS} ${theme.SHAPE.ROUNDED_CORNERS} 0`,
	},
	textInput: {
		width: '100%',
	},
	resetButton: {
		padding: 0,
		position: 'absolute',
		right: multiply(theme.SPACING.XSMALL, 1.5),
		color: theme.colors['text-default'],
		'&:hover, &:focus': {
			background: 'none',
		},
	},
}));
