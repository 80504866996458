import moment from 'moment';

import { TimeframePropertyInput } from '@src/graphql/generated';
import { roundDateTime } from '@src/utils/dateTime';

export const getTimeframeDefaultInput = (): Omit<TimeframePropertyInput, 'baseProperty'> => {
	const currentTime = moment();
	const roundingInterval = moment.duration(30, 'minutes');

	const startTime = roundDateTime(currentTime, roundingInterval, 'ceil');
	const endTime = roundDateTime(currentTime.add(1, 'hour'), roundingInterval, 'ceil');

	return { startTime, endTime, allDay: false };
};
