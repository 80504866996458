import { CardType } from '@src/components/card';
import { ContainerType, ItemType } from '@src/graphql/generated';

export function getItemCardType(itemType?: ItemType): CardType {
	switch (itemType) {
		case ItemType.CcbGroup:
		case ItemType.CcbGroups:
			return 'ccb';
		default:
			return 'item';
	}
}

export function getContainerCardType(containerType?: ContainerType): CardType {
	switch (containerType) {
		case ContainerType.CcbCheckIn:
		case ContainerType.CcbGroups:
		case ContainerType.CcbPublicNeeds:
		case ContainerType.CcbServing:
			return 'ccb';
		case ContainerType.Resi:
			return 'resi';
		default:
			return 'container';
	}
}
