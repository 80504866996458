import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { animated } from '@react-spring/web';

import { FormState } from '@pushpay/forms';
import { DeleteIcon, DragDropIcon } from '@pushpay/iconography';
import { clsx } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { IconButton } from '@src/components/buttons';
import { CardHeader } from '@src/components/card';
import { DraggableIcon } from '@src/components/draggable';
import { ExpandableCard } from '@src/components/expandableCard';
import { Icon as IconComponent } from '@src/components/icon';
import {
	Address,
	AddContact,
	AppLink,
	Audio,
	BlankifyHtml,
	CallToAction,
	Carousel,
	Default,
	Email,
	Facetime,
	Give,
	KeyMetrics,
	Phone,
	Sms,
	TextHtml,
	Text,
	Timeframe,
	UserNote,
	Video,
	Website,
	AdvancedSettings,
	Share,
} from '@src/components/properties/contents';
import { ScrollElementIntoView } from '@src/components/scrollElementIntoView';
import { useFeature } from '@src/featureFlags';
import {
	AddressPropertyInput,
	AppLinkPropertyInput,
	AudioPropertyInput,
	BlankifyPropertyInput,
	CallToActionPropertyInput,
	DefaultPropertyInput,
	GivePropertyInput,
	SmsPropertyInput,
	TextHtmlPropertyInput,
	TextPropertyInput,
	UserNotePropertyInput,
	WebsitePropertyInput,
	CarouselPropertyInput,
	TimeframePropertyInput,
	VideoPropertyInput,
	KeyMetricsPropertyInput,
	FacetimePropertyInput,
	EmailPropertyInput,
	PhonePropertyInput,
	SharePropertyInput,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { CONSTANTS, propertyInputTypeObj, isYouTube } from '@src/pages/itemSettings/utils';
import { assertNever } from '@src/utils';

import { usePropertyContext, usePropertyListContext } from './contexts';
import { useStyles } from './propertyStyles';
import { ItemPropertyField, PropertyInputType } from './types';
import { ContentWrapper } from './wrappers';

export type ContentProps = ComponentProps<
	{
		propertyField: ItemPropertyField;
		propertyInputType: PropertyInputType;
	},
	typeof useStyles
>;
export type PropertyProps = ContentProps & {
	onDelete: () => void;
};

function Content({ propertyField, propertyInputType, 'data-pp-at-target': targetId }: ContentProps) {
	switch (propertyInputType) {
		case 'addToContactsPropertyInput':
			return <AddContact data-pp-at-target={targetId} />;
		case 'addressPropertyInput':
			return (
				<Address
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<AddressPropertyInput>}
				/>
			);
		case 'appLinkPropertyInput':
			return (
				<AppLink
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<AppLinkPropertyInput>}
				/>
			);
		case 'audioPropertyInput':
			return (
				<Audio data-pp-at-target={targetId} propertyField={propertyField as FormState<AudioPropertyInput>} />
			);
		case 'blankifyPropertyInput':
			return (
				<BlankifyHtml
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<BlankifyPropertyInput>}
				/>
			);
		case 'callToActionPropertyInput':
			return (
				<CallToAction
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<CallToActionPropertyInput>}
				/>
			);
		case 'carouselPropertyInput':
			return (
				<Carousel
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<CarouselPropertyInput>}
				/>
			);
		case 'defaultPropertyInput':
			return (
				<Default
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<DefaultPropertyInput>}
				/>
			);
		case 'emailPropertyInput':
			return (
				<Email data-pp-at-target={targetId} propertyField={propertyField as FormState<EmailPropertyInput>} />
			);
		case 'facetimePropertyInput':
			return (
				<Facetime
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<FacetimePropertyInput>}
				/>
			);
		case 'givePropertyInput':
			return <Give data-pp-at-target={targetId} propertyField={propertyField as FormState<GivePropertyInput>} />;
		case 'keyMetricsPropertyInput':
			return (
				<KeyMetrics
					data-pp-at-target={`${targetId}-keyMetrics`}
					propertyField={propertyField as FormState<KeyMetricsPropertyInput>}
				/>
			);
		case 'phonePropertyInput':
			return (
				<Phone data-pp-at-target={targetId} propertyField={propertyField as FormState<PhonePropertyInput>} />
			);
		case 'sharePropertyInput':
			return (
				<Share data-pp-at-target={targetId} propertyField={propertyField as FormState<SharePropertyInput>} />
			);
		case 'smsPropertyInput':
			return <Sms data-pp-at-target={targetId} propertyField={propertyField as FormState<SmsPropertyInput>} />;
		case 'textPropertyInput':
			return <Text data-pp-at-target={targetId} propertyField={propertyField as FormState<TextPropertyInput>} />;
		case 'textHtmlPropertyInput':
			return (
				<TextHtml
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<TextHtmlPropertyInput>}
				/>
			);
		case 'timeframePropertyInput':
			return (
				<Timeframe
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<TimeframePropertyInput>}
				/>
			);
		case 'userNotePropertyInput':
			return (
				<UserNote
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<UserNotePropertyInput>}
				/>
			);
		case 'videoPropertyInput':
			return (
				<Video data-pp-at-target={targetId} propertyField={propertyField as FormState<VideoPropertyInput>} />
			);
		case 'websitePropertyInput':
			return (
				<Website
					data-pp-at-target={targetId}
					propertyField={propertyField as FormState<WebsitePropertyInput>}
				/>
			);
		default:
			// asserts all PropertyInputType values are covered
			assertNever(propertyInputType);
	}
}

const mapIcon = (icon: string) => {
	const mapping: Record<string, string> = {
		'play-1': 'custom-youtube',
	};

	return mapping[icon] ?? icon;
};

export function Property({
	classes: classesProp,
	onDelete,
	propertyField,
	propertyInputType,
	'data-pp-at-target': targetId,
}: PropertyProps) {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const { icon, header, propertyExpandStatus, hasOnlyOneTimeframeProperty } = usePropertyContext();
	const { disabledForFeedItem, isEventItem } = usePropertyListContext();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const {
		baseProperty: {
			id: { value: id },
		},
	} = propertyField;

	const { attributes, listeners, transform, transition, setNodeRef, isDragging, over, active } = useSortable({
		id: id as string,
		disabled: disabledForFeedItem,
		data: {
			id,
			icon,
			header,
			type: CONSTANTS.PROPERTY_TYPE,
		},
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	const propertyIcon = mapIcon(icon);
	const overCurrent = over?.id === id && !active?.data.current?.sortable;

	const urlValue = (propertyField as FormState<VideoPropertyInput>).url?.value ?? '';
	const isYouTubeVideo = isYouTube(urlValue) || isYouTube(header);

	const hasAdvancedSettings = propertyInputType !== propertyInputTypeObj.VIDEO || !isYouTubeVideo;

	const isOnlyTimeframeProperty =
		hasOnlyOneTimeframeProperty &&
		propertyInputType === propertyInputTypeObj.TIMEFRAME &&
		isEventItem &&
		isInAppCalendarEnabled;

	return (
		<animated.div
			ref={setNodeRef}
			className={clsx(classes.card, overCurrent && classes.insertBefore)}
			style={style}
		>
			<ScrollElementIntoView elementId={id ?? ''}>
				<ExpandableCard
					actions={
						<div className={classes.headerGroup}>
							<Tooltip
								content={
									isOnlyTimeframeProperty
										? translate('property.disableRemoveTooltip')
										: translate('property.removeTooltip')
								}
								data-pp-at-target={`${targetId}-toggle-tooltip`}
								placement="top"
							>
								<IconButton
									aria-label="delete"
									className={clsx(
										(disabledForFeedItem || isOnlyTimeframeProperty) && classes.disabled
									)}
									data-pp-at-target={`${targetId}-delete-button`}
									disabled={isOnlyTimeframeProperty}
									icon={
										<DeleteIcon
											classes={{ root: classes.icon }}
											data-pp-at-target={`${targetId}-delete-icon`}
										/>
									}
									onClick={onDelete}
								/>
							</Tooltip>
						</div>
					}
					attributes={attributes}
					data-pp-at-target={`${targetId}-card`}
					externalExpandStatus={propertyExpandStatus}
					initialExpandStatus="preexpanded"
					isBeingDragged={isDragging}
					isDisabled={disabledForFeedItem}
					listeners={listeners}
					title={
						<div className={classes.headerGroup}>
							<DragDropIcon
								className={classes.dragHandle}
								data-pp-at-target={`${targetId}-card-drag-handle`}
								displaySize="small"
							/>
							<IconComponent
								classes={{ root: classes.icon }}
								data-pp-at-target={`${targetId}-card-icon`}
								name={propertyIcon as DraggableIcon}
							/>
							<CardHeader classes={{ header: classes.label }} headerText={header} />
						</div>
					}
					hasDivider
					isDraggable
				>
					<ContentWrapper>
						<Content
							data-pp-at-target={targetId}
							propertyField={propertyField}
							propertyInputType={propertyInputType}
						/>
						{hasAdvancedSettings && (
							<AdvancedSettings isDisabled={Boolean(disabledForFeedItem)} propertyField={propertyField} />
						)}
					</ContentWrapper>
				</ExpandableCard>
			</ScrollElementIntoView>
		</animated.div>
	);
}
