import React from 'react';

import { TextField } from '@pushpay/forms';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { DraggableIcon } from '@src/components/draggable/types';
import { Icon } from '@src/components/icon';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		position: 'relative',
	},
	field: {
		flexDirection: 'column',
	},
	icon: {
		position: 'absolute',
		bottom: '12px',
		height: '18px',
		width: '18px',
		left: '12px',
		right: '4px',
		zIndex: 1,
	},
	readOnly: {
		color: theme.colors['text-disabled'],
	},
	wrapper: {},
	label: {},
	content: {
		'& input': {
			paddingLeft: '34px',
		},
	},
}));

type TextWithIconFieldProps = ComponentProps<
	{
		icon?: DraggableIcon;
		value?: string;
	} & Pick<
		React.ComponentProps<typeof TextField>,
		'field' | 'label' | 'labelPosition' | 'readOnly' | 'showLabel' | 'suppressRequiredText' | 'tooltip'
	>,
	typeof useStyles
>;

export function TextWithIconField({
	classes: classesProp,
	field,
	label,
	labelPosition,
	readOnly,
	showLabel,
	suppressRequiredText,
	tooltip,
	icon,
	value: valueProp,
}: TextWithIconFieldProps) {
	const classes = useStyles(classesProp);
	const value = valueProp || field.value;

	return (
		<div className={classes.root}>
			<Icon
				classes={{ root: clsx(classes.icon, readOnly && classes.readOnly) }}
				name={icon ?? (value as DraggableIcon)}
			/>
			<TextField
				classes={{
					root: classes.field,
					label: classes.label,
					wrapper: classes.wrapper,
					content: classes.content,
				}}
				defaultValue={value}
				field={field}
				label={label}
				labelPosition={labelPosition}
				readOnly={readOnly}
				showLabel={showLabel}
				suppressRequiredText={suppressRequiredText}
				textInputProps={valueProp ? ({ value } as any) : undefined}
				tooltip={tooltip}
			/>
		</div>
	);
}
