import { useState } from 'react';

import { AwesomeQR } from 'awesome-qr';

import { Button } from '@pushpay/button';
import { QrCodeIcon } from '@pushpay/iconography';
import Notification from '@pushpay/notification';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { useTranslation, Trans } from '@src/i18n';
import { useMyApp } from '@src/myContext';

import { topNavItemStyles } from '../topNavItemStyles';

const CUSTOMER_SUCCESS_EMAIL = 'care@pushpay.com';

const useStyles = createUseStyles((theme: Theme) => ({
	...topNavItemStyles(theme),
	downLinkIcon: {
		color: theme.colors['grey-900'],
		fontSize: '1.5em',
	},
	toolTips: {
		width: '166px',
	},
	notification: {
		zIndex: theme.ELEVATION.LAYOUT.Z_INDEX + 1,
	},
	button: {
		margin: `0 ${theme.SPACING.XSMALL}`,
	},
}));

const blobToBase64 = (blob: Blob): Promise<string> => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise((resolve): void => {
		reader.onloadend = (): void => {
			if (typeof reader.result === 'string') {
				resolve(reader.result);
			}
		};
	});
};

const downloadQRCode = async ({
	brandmark,
	qrCodeText,
	name,
}: {
	brandmark: string;
	qrCodeText: string;
	name: string;
}): Promise<void> => {
	const response = await fetch(brandmark);
	const blob = await response.blob();
	const base64 = await blobToBase64(blob);
	const buffer = await new AwesomeQR({
		text: qrCodeText,
		size: 400,
		logoScale: 0.2,
		logoCornerRadius: 8,
		correctLevel: AwesomeQR.CorrectLevel.H,
		margin: 20,
		logoImage: base64,
		dotScale: 1,
	}).draw();

	const a = document.createElement('a');
	a.href = buffer as string;
	a.download = `QR_App Download_${name}.jpg`;
	a.click();
};

type ErrorType = 'misConfigQrCodeError' | 'qrCodeGenerationError';

export const DownloadLink = ({ 'data-pp-at-target': targetId }: ComponentProps<unknown, undefined>) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('common');
	const [qrCodeError, setQrCodeError] = useState<ErrorType | null>(null);
	const {
		currentApp: {
			name,
			downloadLink,
			images: { brandmark },
		},
	} = useMyApp();

	const NotificationContent =
		qrCodeError === 'misConfigQrCodeError' ? (
			<Trans i18nKey="errors.text.misConfigQrCodeError" namespace="common">
				App QR code is unavailable. Please
				<a href={`mailto:${CUSTOMER_SUCCESS_EMAIL}`} rel="noopener noreferrer" target="_blank">
					contact us
				</a>
				to set up your QR code
			</Trans>
		) : (
			translate('errors.text.qrCodeGenerationError')
		);

	const handleDownloadClick = async () => {
		if (!brandmark || !downloadLink) {
			setQrCodeError('misConfigQrCodeError');
			return;
		}

		try {
			await downloadQRCode({
				brandmark,
				qrCodeText: downloadLink,
				name,
			});
		} catch (error) {
			if (error instanceof TypeError) {
				setQrCodeError('qrCodeGenerationError');
			} else {
				setQrCodeError('misConfigQrCodeError');
			}
		}
	};

	return (
		<>
			<Tooltip
				classes={{
					tooltip: classes.toolTips,
				}}
				content={translate('downloadLink')}
				data-pp-at-target={`${targetId}-tooltip`}
				panelSpace="XXSMALL"
				placement="bottom"
			>
				<Button
					className={clsx(classes.item, classes.button)}
					data-pp-at-target={targetId}
					type="button"
					onClick={handleDownloadClick}
				>
					<QrCodeIcon className={classes.downLinkIcon} data-pp-at-target={`${targetId}-icon`} />
				</Button>
			</Tooltip>
			<Notification
				classes={{ root: classes.notification }}
				closeButtonAriaLabel="Close"
				data-pp-at-target={`${targetId}-notification`}
				dismiss={() => setQrCodeError(null)}
				isOpen={qrCodeError !== null}
				title={translate('errors.title.error')}
				type="error"
			>
				{NotificationContent}
			</Notification>
		</>
	);
};
