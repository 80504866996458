import { Over } from '@dnd-kit/core';

import { CardProps } from '@src/components/card';
import { Draggable } from '@src/components/draggable/types';
import { dropNewItemAreaId } from '@src/components/droppable/NewItemDroppableArea';
import type { PropertyHeaderProps } from '@src/components/properties';
import { CONSTANTS } from '@src/pages/itemSettings/utils';

export function isDraggableType(draggingItem: Draggable | CardProps | PropertyHeaderProps): draggingItem is Draggable {
	return (draggingItem as Draggable).actions !== undefined;
}

export function isPropertyType(item: object) {
	return (item as PropertyHeaderProps).type === CONSTANTS.PROPERTY_TYPE;
}

export function getParentIdAndInsertBeforeId(over: Over) {
	let parentId: string;
	let insertBeforeId: string | null;
	if (over.id === dropNewItemAreaId) {
		[parentId, insertBeforeId] = [over.data?.current?.parentId, null];
	} else {
		insertBeforeId = over.id.toString();
		parentId = over.data.current?.parentId;
	}
	return { parentId, insertBeforeId };
}
