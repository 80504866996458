import { useHistoryState } from '@src/shared/hooks';

import { CardProps } from '../Card';

export const useCardState = () => {
	const {
		historyState: { recentlyCopiedChildIds = [], recentlyMovedChildIds = [], recentlyCreatedChildId },
	} = useHistoryState();

	/**
	 * Checks if the card is included in the most recent moved cards
	 */
	const isRecentlyMoved = (card: CardProps): boolean => recentlyMovedChildIds.includes(card.id);

	/**
	 * Checks if the card is the most recently created card.
	 */
	const isRecentlyCreated = (card: CardProps): boolean => recentlyCreatedChildId === card.id;

	/**
	 * Checks if the card is included in the most recent copied cards
	 */
	const isRecentlyCopied = (card: CardProps): boolean => recentlyCopiedChildIds.includes(card.id);

	return { isRecentlyMoved, isRecentlyCreated, isRecentlyCopied };
};
