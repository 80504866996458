import { createContext, useContext, useState, PropsWithChildren, useEffect, useMemo, SetStateAction } from 'react';

import { FormState } from '@pushpay/forms';

import { SaveContainerSettingsSchema, SaveItemSettingsSchema } from '@src/graphql/generated';

type SetFormStateContext = FormState<SaveContainerSettingsSchema> | FormState<SaveItemSettingsSchema> | null;

type SetFormContext = {
	setFormState: (mutate: SetStateAction<FormState<SaveItemSettingsSchema | SaveItemSettingsSchema>>) => void;
};

type FormContext = {
	formState: SetFormStateContext;
	formContext: SetFormContext;
};

type FormDispatchContext = {
	useSetForm: (props: { formState: SetFormStateContext; formContext?: SetFormContext }) => void;
};

const defaultFormContext = { setFormState: () => {} };

const FormContext = createContext<FormContext>({ formState: null, formContext: defaultFormContext });
FormContext.displayName = 'FormContext';

const FormDispatchContext = createContext<FormDispatchContext | null>(null);
FormDispatchContext.displayName = 'FormDispatchContext';

function FormContextProvider({ children }: PropsWithChildren) {
	const [formState, setFormState] = useState<SetFormStateContext>(null);
	const [formContext, setFormContext] = useState<SetFormContext>(defaultFormContext);

	const formDispatchContextValue: FormDispatchContext = useMemo(
		() => ({
			useSetForm: ({ formState: providedFormState, formContext: providedFormContext }) => {
				useEffect(() => {
					setFormState(providedFormState);

					if (providedFormContext) {
						setFormContext(providedFormContext);
					}

					return () => {
						setFormState(null);
					};
				}, [providedFormContext, providedFormState]);
			},
		}),
		[]
	);

	const formContextValue = useMemo(
		() => ({
			formState,
			formContext,
		}),
		[formContext, formState]
	);

	return (
		<FormContext.Provider value={formContextValue}>
			<FormDispatchContext.Provider value={formDispatchContextValue}>{children}</FormDispatchContext.Provider>
		</FormContext.Provider>
	);
}

function useFormContext(): FormContext {
	return useContext(FormContext);
}

function useFormDispatchContext(): FormDispatchContext {
	const context = useContext(FormDispatchContext);
	if (!context) {
		throw new Error('useFormDispatchContext must be used within a FormContextProvider');
	}
	return context;
}

export { FormContextProvider, FormContext, useFormContext, useFormDispatchContext };
