import { Button } from '@pushpay/button';
import Expand, { ExpandStatus } from '@pushpay/expand';
import { PlusMinusIcon } from '@pushpay/iconography';
import { Stack } from '@pushpay/layout';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

const useStyles = createUseStyles((theme: Theme) => ({
	wrapper: {
		width: '100%',
	},
	button: {
		border: `1px solid ${theme.colors['grey-500']}`,
		borderRadius: theme.SHAPE.ROUNDED_CORNERS,
		font: theme.typography['text-5'],
		justifyContent: 'space-between',
		padding: theme.SPACING.GUTTER,
		width: '100%',
	},
	icon: {
		width: theme.SPACING.LARGE_ICON_SIZE,
		height: theme.SPACING.LARGE_ICON_SIZE,
	},
	panel: {
		backgroundColor: 'rgba(245, 246, 250, 0.3)',
		border: `1px solid ${theme.colors['grey-500']}`,
		borderRadius: theme.SHAPE.ROUNDED_CORNERS,
		font: theme.typography['text-5'],
		marginTop: '-1px',
		padding: '10px',
	},
}));

export const Group = ({
	groupName,
	classes: classesProp,
	className: classNameProp,
	'data-pp-at-target': targetId,
	children,
	...rest
}: ComponentProps<{ groupName: string }, undefined>) => {
	const classes = useStyles(classesProp);
	const className = clsx(classes.wrapper, classNameProp);

	return (
		<Expand.Item className={className} data-pp-at-target={targetId} {...rest}>
			{(expandStatus: ExpandStatus, updateExpandStatus: () => void) => (
				<>
					<Button
						className={classes.button}
						data-pp-at-target={`${targetId}-button`}
						type="button"
						onClick={updateExpandStatus}
					>
						{groupName}
						<PlusMinusIcon
							className={classes.icon}
							data-pp-at-target={`${targetId}-icon`}
							mode={expandStatus === 'collapsed' ? 'plus' : 'minus'}
						/>
					</Button>
					<Expand.Panel data-pp-at-target={`${targetId}-panel`} expandStatus={expandStatus}>
						<Stack className={classes.panel} space={10}>
							{children}
						</Stack>
					</Expand.Panel>
				</>
			)}
		</Expand.Item>
	);
};
