import { FormState, getDynamicFieldsItems } from '@pushpay/forms';

import { SaveItemSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { PreviewPlaceholder } from '../PreviewPlaceholder';

type ItemWebviewProps = {
	input: FormState<SaveItemSettingsSchema>['input'];
};

export function ItemWebview({ input }: ItemWebviewProps) {
	const { translate } = useTranslation('appDesign');
	const properties = getDynamicFieldsItems(input.properties);

	const websitePropertyInput = properties.find(property => !!property.websitePropertyInput)?.websitePropertyInput;
	const url = websitePropertyInput?.url.value;

	const onClickHandler = () => {
		if (url) {
			window.open(url, '_blank');
		}
	};

	return <PreviewPlaceholder placeholderText={translate('preview.webviewPlaceholder')} onClick={onClickHandler} />;
}
