export const PRODUCT_SWITCHER_LEARN_MORE_APPS = 'https://pushpay.com/product/mobile-app';
export const PRODUCT_SWITCHER_LEARN_MORE_APPS_CATHOLIC = 'https://pushpay.com/product/catholic/mobile-app';
export const PRODUCT_SWITCHER_LEARN_MORE_CHMS = 'https://pushpay.com/product/chms-software';
export const PRODUCT_SWITCHER_LEARN_MORE_CHMS_CATHOLIC = 'https://pushpay.com/product/catholic/chms-software';
export const PRODUCT_SWITCHER_LEARN_MORE_GIVING = 'https://pushpay.com/product/church-giving';
export const PRODUCT_SWITCHER_LEARN_MORE_GIVING_CATHOLIC = 'https://pushpay.com/product/catholic/church-giving';
export const PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS = 'https://pushpay.com/product/insights';
export const PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS_CATHOLIC = 'https://pushpay.com/product/catholic/insights';

export const PUSHPAY_PRIVACY_POLICY = 'https://pushpay.com/legal-center/privacy/';
export const PUSHPAY_TERMS_AND_CONDITIONS = 'https://pushpay.com/legal-center/terms/';
