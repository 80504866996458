import { Loader } from '@googlemaps/js-api-loader';

import { getAppSettings } from '@src/utils';

export function initGoogleMaps() {
	const { googleMapsApiKey } = getAppSettings();
	const loader = new Loader({
		apiKey: googleMapsApiKey,
		version: 'weekly',
	});

	loader.importLibrary('geocoding');
}
