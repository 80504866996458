import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { Trans } from '@src/i18n';

const useStyles = createUseStyles((theme: Theme) => ({
	description: {
		font: theme.typography['text-5'],
		backgroundColor: theme.colors['background-page'],
		border: `1px solid ${theme.colors['grey-400']}`,
		borderRadius: '3px',
		padding: `${multiply(theme.SPACING.XSMALL, 1.5)} ${theme.SPACING.SMALL}`,
	},
}));

export type AddContactProps = ComponentProps<unknown, undefined>;

export const AddContact = ({ 'data-pp-at-target': targetId }: AddContactProps) => {
	const classes = useStyles(undefined);

	return (
		<div className={classes.description} data-pp-at-target={targetId}>
			<Trans i18nKey="addContact.description" namespace="appDesign">
				In-app “<b>Save contact</b>” action is successfully added
			</Trans>
		</div>
	);
};
