import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

type HistoryState = {
	recentlyMovedChildIds?: string[];
	recentlyCopiedChildIds?: string[];
	recentlyCreatedChildId?: string;
};

/**
 * Returns the history state of the current URL, and a function to update it.
 */
export const useHistoryState = (): {
	historyState: HistoryState;
	updateHistoryState: (incomingState: HistoryState) => void;
} => {
	const { state, pathname, search, hash } = useLocation();
	const navigate = useNavigate();

	const updateState = useCallback(
		(incomingState: HistoryState) => {
			navigate({ pathname, search, hash }, { replace: true, state: { ...state, ...incomingState } });
		},
		[state, navigate, pathname, search, hash]
	);

	return { historyState: state ?? {}, updateHistoryState: updateState };
};
