import { useNavigate } from 'react-router-dom';

import { Button } from '@pushpay/button';
import { IconProps } from '@pushpay/icon';
import { createUseStyles, clsx, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { useLoadingDispatchContext } from '@src/context';
import { useTranslation } from '@src/i18n';
import { getAppSettings } from '@src/utils';

type BasePageErrorProps = {
	title: string;
	text?: string;
	illustration: React.ComponentType<IconProps>;
	withGetHelpLink?: boolean;
	withReloadLink?: boolean;
	withGoBackLink?: boolean;
};

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		margin: `${multiply(theme.SPACING.GUTTER, 20)} auto 0`,
		textAlign: 'center',

		[theme.mediaBreakpoint('TABLET_AND_BELOW')]: {
			marginTop: multiply(theme.SPACING.GUTTER, 10),
		},
	},
	title: {
		margin: multiply(theme.SPACING.GUTTER, 2),
	},
	text: {
		lineHeight: 1.5,
		margin: `0 auto ${multiply(theme.SPACING.GUTTER, 2)}`,
		maxWidth: '410px',
	},
	ctaContainer: {
		marginTop: theme.SPACING.LARGE,
		'& > :not(:last-child)': {
			marginRight: theme.SPACING.SMALL,
		},
	},
}));

type BaseErrorComponentProps = ComponentProps<BasePageErrorProps, typeof useStyles>;

const BaseError = (props: BaseErrorComponentProps) => {
	const {
		title,
		text,
		illustration: Illustration,
		withGetHelpLink,
		withReloadLink,
		withGoBackLink,
		className: classNameProp,
		classes: classesProp,
		'data-pp-at-target': targetId,
		...rest
	} = props;
	const classes = useStyles(classesProp);
	const className = clsx(classNameProp, classes.root);
	const { translate } = useTranslation();
	const { useFinishLoading } = useLoadingDispatchContext();
	const { helpLinkUrl } = getAppSettings();
	const navigate = useNavigate();

	useFinishLoading();

	return (
		<div className={className} data-pp-at-target={targetId} {...rest}>
			<Illustration data-pp-at-target={`${targetId}-illustration`} title={title} />
			<h3 data-pp-at-target={`${targetId}-title`}>{title}</h3>
			{text && <p className={classes.text}>{text}</p>}
			<div className={classes.ctaContainer}>
				{withGetHelpLink && (
					<Button
						data-pp-at-target={`${targetId}-error-link`}
						displaySize="large"
						displayStyle={withReloadLink ? 'secondary' : 'primary'}
						type="button"
						onClick={() => {
							window.location.href = helpLinkUrl;
						}}
					>
						{translate('errors.CTA.getHelp')}
					</Button>
				)}
				{withReloadLink && (
					<Button
						data-pp-at-target={`${targetId}-reload-page`}
						displaySize="large"
						displayStyle="primary"
						type="button"
						onClick={() => {
							window.location.reload();
						}}
					>
						{translate('errors.CTA.reloadPage')}
					</Button>
				)}
				{withGoBackLink && (
					<Button
						data-pp-at-target={`${targetId}-go-back`}
						displaySize="large"
						displayStyle="text"
						type="button"
						onClick={() => navigate(-1)}
					>
						{translate('errors.CTA.goBack')}
					</Button>
				)}
			</div>
		</div>
	);
};

export { BaseError };
