import { useGetProfileQuery, useGetSupportedCountriesQuery } from '@src/graphql/generated';
import { useQueries } from '@src/graphql/utils';

import { ProfilePage as ProfileComponent } from './Profile';
import { ProfileLoadingOrError } from './ProfileLoadingOrError';

const targetId = 'profile';

export const Profile = () => {
	const { data, loading } = useQueries(
		useGetProfileQuery({ fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' }),
		useGetSupportedCountriesQuery({ fetchPolicy: 'cache-first' })
	);

	if (loading) {
		return <ProfileLoadingOrError data-pp-at-target={targetId} isLoading />;
	}

	const [profileData, supportedCountriesData] = data;
	if (profileData && profileData.me && supportedCountriesData) {
		return (
			<ProfileComponent
				countries={supportedCountriesData.supportedCountriesDetails}
				data-pp-at-target={targetId}
				profile={profileData.me}
			/>
		);
	}

	return <ProfileLoadingOrError data-pp-at-target={targetId} isError />;
};
