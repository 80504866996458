import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	wrapper: {
		paddingTop: '52px',
		position: 'relative',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.colors['grey-200'],
		border: `3px dashed ${theme.colors['grey-400']}`,
		borderRadius: '6px',
		color: theme.colors['text-placeholder'],
		marginTop: theme.SPACING.XXSMALL,
		marginBottom: theme.SPACING.SMALL,
		padding: `${theme.SPACING.MEDIUM} 0`,
		position: 'relative',
	},
	containerDragged: {
		backgroundColor: theme.colors['common-white'],
		borderColor: theme.colors['productBrand-500'],
	},
	uploadIcon: {
		fontSize: multiply(theme.SPACING.LARGE_ICON_SIZE, 2),
		color: theme.colors['grey-500'],
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			display: 'none',
		},
	},
	title: {
		fontSize: multiply(theme.typography.baseSize, 1.25),
		fontWeight: theme.typography.font.weight.semiBold,
		lineHeight: '28px',
		marginTop: theme.SPACING.GUTTER,
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			fontSize: theme.typography.baseSize,
			lineHeight: '16px',
		},
	},
	subTitle: {
		fontSize: multiply(theme.typography.baseSize, 1.25),
		lineHeight: '26px',
		marginTop: theme.SPACING.GUTTER,
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			fontSize: theme.typography.baseSize,
			lineHeight: '16px',
		},
	},
	chooseButton: {
		marginBottom: theme.SPACING.MEDIUM,
		marginTop: theme.SPACING.GUTTER,
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			marginBottom: theme.SPACING.SMALL,
		},
		'&:disabled': {
			cursor: 'not-allowed',
		},
	},
	imageUrl: {
		margin: '16px auto 0',
		maxWidth: '440px',
		overflow: 'hidden',
		padding: '0 20px',
		height: '130px',
		transition: `max-height ${theme.DURATIONS.SHORT} ease-in-out`,
		'&:disabled': {
			cursor: 'not-allowed',
		},
	},
	imageUrlWrapper: {
		width: '100%',
	},
	fileInput: {
		display: 'none',
	},
	notification: {
		color: theme.colors['blue-500'],
		backgroundColor: theme.colors['blue-100'],
		width: '100%',
		height: theme.SPACING.XLARGE,
		position: 'absolute',
		zIndex: 0,
	},
	icon: {
		marginRight: theme.SPACING.XSMALL,
	},
}));
