import PhoneChrome from '@assets/images/iPhone_Frame.svg';

import { createUseStyles, subtract } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const PHONE_DIMENSIONS = {
	BASE: {
		width: '275px',
		height: '556px',
	},
	SMALL: {
		width: '240px',
		height: '485px',
	},
};

export const useStyles = createUseStyles((theme: Theme) => ({
	phoneChrome: {
		background: `url(${PhoneChrome}) 0 0/100% 100% no-repeat`,
		position: 'absolute',
		top: `calc(${theme.SPACING.LARGE} + 1px)`,
		width: `calc(${PHONE_DIMENSIONS.BASE.width} + 1px)`,
		height: PHONE_DIMENSIONS.BASE.height,
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			top: theme.SPACING.MEDIUM,
			...PHONE_DIMENSIONS.SMALL,
		},
		pointerEvents: 'none',
	},
	previewContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	previewContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		...PHONE_DIMENSIONS.BASE,
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			...PHONE_DIMENSIONS.SMALL,
		},
	},
	preview: {
		padding: theme.SPACING.LARGE,
		marginLeft: 'auto',
		position: 'relative',
		[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
			padding: theme.SPACING.MEDIUM,
		},
	},
	campusSelector: {
		marginTop: theme.SPACING.MEDIUM,
		marginRight: 0,
		'& > div': {
			width: subtract(PHONE_DIMENSIONS.BASE.width, theme.SPACING.XSMALL),
			[theme.mediaBreakpoint('DESKTOP_AND_BELOW')]: {
				width: subtract(PHONE_DIMENSIONS.SMALL.width, theme.SPACING.XXSMALL),
			},
		},
	},
	campusSelectorOverlay: {
		'& > div[role=presentation]': {
			width: '100%',
		},
	},
}));
