import PencilFill from '@assets/icons/pencil-fill.svg';
import Trash from '@assets/icons/trash.svg';

import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { StyleParams } from '@src/components/textEditor/PushpayEditorTheme';

export const useStyles = createUseStyles((theme: Theme) => ({
	linkEditor: {
		display: 'block',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 10,
		maxWidth: '400px',
		width: '100%',
		opacity: 0,
		backgroundColor: theme.colors['common-white'],
		boxShadow: theme.ELEVATION.EIGHT,
		borderRadius: '8px',
		transition: 'opacity 0.5s',
		willChange: 'transform',
	},
	button: {
		width: '20px',
		height: '20px',
		display: 'inline-block',
		padding: '6px',
		borderRadius: '8px',
		cursor: 'pointer',
		margin: '0 2px',
	},
	linkInput: {
		display: 'block',
		width: 'calc(100% - 75px)',
		boxSizing: 'border-box',
		margin: '12px 12px',
		padding: '15px 12px',
		borderRadius: '15px',
		backgroundColor: theme.colors['grey-200'],
		fontSize: theme.typography.baseSize,
		color: theme.colors['grey-1100'],
		border: 0,
		outline: 0,
		position: 'relative',
		fontFamily: 'inherit',
	},
	checkBox: {
		padding: '0 0 14px 14px',
	},
	linkView: ({ primaryColor }: StyleParams) => ({
		display: 'block',
		width: 'calc(100% - 24px)',
		margin: '8px 12px',
		padding: '8px 12px',
		borderRadius: '15px',
		fontSize: theme.typography.baseSize,
		color: theme.colors['grey-1100'],
		border: 0,
		outline: 0,
		position: 'relative',
		fontFamily: 'inherit',
		'& a': {
			color: primaryColor ?? theme.colors['blue-500'],
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			width: 'calc(100% - 70px)',
			display: 'block',
		},
	}),
	linkEdit: {
		backgroundImage: `url(${PencilFill})`,
		backgroundSize: '16px',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '35px',
		verticalAlign: '-0.25em',
		position: 'absolute',
		right: '30px',
		top: '9px',
		bottom: 0,
		cursor: 'pointer',
	},
	linkTrash: {
		backgroundImage: `url(${Trash})`,
		backgroundSize: '16px',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '35px',
		verticalAlign: '-0.25em',
		position: 'absolute',
		right: 0,
		top: '9px',
		bottom: 0,
		cursor: 'pointer',
	},
	linkCancel: {
		width: '18px',
		verticalAlign: '-0.25em',
		marginRight: '31px',
		position: 'absolute',
		right: 7,
		top: '17px',
		bottom: 0,
		cursor: 'pointer',
		padding: 0,
	},
	editModeIconSize: {
		fontSize: multiply(theme.SPACING.SMALL_ICON_SIZE, 2),
	},
	linkConfirm: {
		width: '21px',
		verticalAlign: '-0.25em',
		marginRight: '10px',
		position: 'absolute',
		right: 0,
		top: '17px',
		bottom: 0,
		cursor: 'pointer',
		padding: 0,
	},
	switchWrapper: {
		transform: 'scale(0.95)',
	},
}));
