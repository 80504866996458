import { useState, useRef, useEffect, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';

import { Button, SaveButton, SaveButtonProps } from '@pushpay/button';
import { FloatingBar } from '@pushpay/floating-bar';
import { Form, TextField } from '@pushpay/forms';
import { Column, Columns, Stack, Tiles } from '@pushpay/layout';
import { clsx } from '@pushpay/styles';

import { ExpandableCard } from '@src/components/expandableCard';
import { SettingsIconField, ImageFieldRef } from '@src/components/formFields';
import { InteractionBlocker } from '@src/components/interactionBlocker';
import { ItemLayoutSettings } from '@src/components/layoutSettings';
import { ConfirmationDialog } from '@src/components/modals';
import { useShowNotification } from '@src/components/notification';
import { PropertyPanelWrapper } from '@src/components/properties';
import { useFormDispatchContext, useCarouselPendingUploadImageMapActions } from '@src/context';
import { useFeature } from '@src/featureFlags';
import { ItemType, useSaveItemSettingsForm } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { useEvent } from '@src/utils';

import { useConfirmModal } from '../containerSettings/components/useConfirmModal';
import { DHSItemSetting } from './components/DHSItemSetting';
import { ItemActions } from './components/ItemActions';
import { ItemImageField } from './components/ItemImageField';
import { useStyles } from './itemStyle';
import { FeedType, ItemSettings } from './types';
import { generateItemSettingsFormInitialValues, generateItemSettingsFormFieldVisibility } from './utils';
import { getFormAccessibility } from './utils/formAccessibility';
import { isExpiredItem } from './utils/validation';

export type ItemSettingsFormProps = {
	onDiscard?: () => void;
	item: ItemSettings;
	isDHSEnabled: boolean;
	parentContainerFeedType?: FeedType;
	isFeedItem?: boolean;
};

export type ItemSettingsFormRefType = {
	onConfirmDelete: () => void;
	resetBeforeDelete: () => void;
};

export const ItemSettingsForm = forwardRef<ItemSettingsFormRefType, ItemSettingsFormProps>((props, ref) => {
	const { item, isDHSEnabled, parentContainerFeedType, isFeedItem = false, onDiscard: onDiscardProp } = props;

	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const { translate: translateCommon } = useTranslation('common');
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { useSetForm } = useFormDispatchContext();

	const imageFieldRef = useRef<ImageFieldRef>(null);
	const { discardAllPendingUploadImages, uploadAllPendingUploadImages } = useCarouselPendingUploadImageMapActions();
	const [isUploading, setIsUploading] = useState(false);
	const showNotification = useShowNotification();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');
	const isInFeedContainer = !!parentContainerFeedType;

	const { isFullyDisabled, isOnlyDHSEnabled } = getFormAccessibility(item, isInFeedContainer, isInAppCalendarEnabled);
	const isFormDisabled = isFullyDisabled || isOnlyDHSEnabled;

	const parentContainer = item?.parentContainer;

	const initialData = useMemo(
		() => ({
			organizationKey,
			platformCampusKey,
			input: generateItemSettingsFormInitialValues(item, applicationId),
		}),
		[applicationId, item, platformCampusKey, organizationKey]
	);

	const { formContext, formState, dirty, reset, submit, saving, successResult, graphQLError } =
		useSaveItemSettingsForm({
			initialData,
			fieldVisibility: {
				input: generateItemSettingsFormFieldVisibility(item),
			},
			mutationOptions: {
				update(cache, { data }) {
					if (data?.saveItemSettings?.updatedItem) {
						const { id, name, icon } = data.saveItemSettings.updatedItem;
						cache.modify({
							id: cache.identify({
								id,
								__typename: 'ChildItem',
							}),
							fields: {
								name: () => name,
								icon: () => icon,
							},
						});
					}
				},
			},
		});

	useSetForm({ formState, formContext });

	const isDirty = isFullyDisabled ? false : dirty;

	const [isConfirmModalOpen, setIsConfirmModalOpen, onProceed, onModalReset, onConfirmDelete] =
		useConfirmModal(isDirty);
	const [isDiscarding, setIsDiscarding] = useState(false);
	const [hasMutationFailed, setHasMutationFailed] = useState(false);

	const hasNetworkError = !!graphQLError;

	const onNetworkError = useEvent(() => {
		if (isConfirmModalOpen) {
			setHasMutationFailed(true);
		}
	});

	useEffect(() => {
		if (hasNetworkError) {
			onNetworkError();
		}
	}, [hasNetworkError, onNetworkError]);

	useEffect(() => {
		if (isConfirmModalOpen && hasMutationFailed) {
			setHasMutationFailed(false);
			setIsConfirmModalOpen(false);
			onModalReset();
		}
	}, [isConfirmModalOpen, hasMutationFailed, setIsConfirmModalOpen, onModalReset]);

	const onItemImageProcessEnd = useEvent(() => {
		reset(initialData);
	});

	const onReset = () => {
		reset();
		onProceed();
	};

	const onSubmit = async () => {
		setIsUploading(true);

		const isImageUploadSuccess = (await imageFieldRef.current?.uploadFile()) ?? true;
		const isCarouselUploadSuccess = await uploadAllPendingUploadImages(formState.input.properties);

		if (!isCarouselUploadSuccess) {
			showNotification({
				isOpen: true,
				type: 'error',
				title: translateCommon('errors.title.error'),
				content: translateCommon('errors.text.fileUploadError', { file: 'Carousel images' }),
			});
		}

		setIsUploading(false);

		if (isImageUploadSuccess && isCarouselUploadSuccess) {
			submit();
		}
	};

	const onDiscard = () => {
		setIsConfirmModalOpen(true);
		setIsDiscarding(true);
	};

	const onConfirmDiscard = () => {
		if (onDiscardProp) {
			onDiscardProp();
		}
		imageFieldRef.current?.resetField();
		discardAllPendingUploadImages();
		onReset();
	};

	const onContinueEditing = () => {
		setIsConfirmModalOpen(false);
		setIsDiscarding(false);
		onModalReset();
	};

	const getSaveButtonState = () => {
		let status: SaveButtonProps['state'] = 'idle';
		if (saving || isUploading) {
			status = 'saving';
		} else if (successResult) {
			status = 'success';
		}
		return status;
	};

	const resetBeforeDelete = useCallback(() => {
		reset();
		imageFieldRef.current?.resetField();
		discardAllPendingUploadImages();
	}, [reset, discardAllPendingUploadImages]);

	useImperativeHandle(
		ref,
		() => ({
			onConfirmDelete,
			resetBeforeDelete,
		}),
		[onConfirmDelete, resetBeforeDelete]
	);

	const saveButtonState = getSaveButtonState();
	const saveButton = (
		<SaveButton
			idleStateButtonText={translate('actions.saveButton.text.idle')}
			savingStateButtonText={translate('actions.saveButton.text.saving')}
			state={saveButtonState}
			successStateButtonText={translate('actions.saveButton.text.success')}
			onSave={onSubmit}
			onSuccess={onReset}
		/>
	);
	const discardButton = (
		<Button displayStyle="primary" type="button" onClick={onConfirmDiscard}>
			{translate('actions.discard.text.idle')}
		</Button>
	);

	const getConfirmationDialogOptions = () => {
		if (isDiscarding) {
			return {
				title: translate('discardDialog.modalTitle'),
				bodyText: translate('discardDialog.modalText'),
				cancelButtonHandler: onContinueEditing,
				cancelButtonLabel: translate('discardDialog.cancel'),
				confirmButton: discardButton,
			};
		}

		return {
			title: translate('confirmationDialog.modalTitle'),
			bodyText: translate('confirmationDialog.modalText'),
			cancelButtonHandler: isConfirmModalOpen ? onConfirmDiscard : onDiscard,
			cancelButtonLabel: translate('confirmationDialog.discard'),
			confirmButton: saveButton,
		};
	};

	if (!item) {
		return null;
	}

	const confirmationDialogOptions = getConfirmationDialogOptions();

	const isEventItem = item.type === ItemType.Event;

	return (
		<>
			<Form
				className={clsx(classes.form, isFullyDisabled && classes.disabled)}
				data-pp-at-target="items-setting-form"
				disabled={isFullyDisabled}
				formContext={formContext}
			>
				<ExpandableCard
					classes={{
						body: classes.expandableCardBody,
						title: classes.expandableCardTitle,
					}}
					data-pp-at-target="items"
					initialExpandStatus="preexpanded"
					isDisabled={isFullyDisabled}
					title="General"
				>
					<Stack classes={{ stackItem: classes.stackItem }}>
						<Columns>
							<Column width="3/4">
								<TextField
									classes={{
										wrapper: classes.nameFieldWrapper,
										label: classes.label,
									}}
									field={formState.input.name}
									label={translate('itemSetting.itemNameLabel')}
									labelPosition="top"
									readOnly={isOnlyDHSEnabled}
									showLabel
								/>
							</Column>
							<Column width="1/4">
								<SettingsIconField
									classes={{
										label: classes.label,
									}}
									field={formState.input.icon}
									template={item.parentContainer?.template}
								/>
							</Column>
						</Columns>

						<Tiles
							classes={{
								tile: classes.tile,
							}}
							columns={1}
						>
							<ItemLayoutSettings inputField={formState.input} isDisabled={isFormDisabled} />
						</Tiles>
					</Stack>
					<div className={classes.section}>
						{item.parentContainer && (
							<ItemImageField
								field={formState.input.imageUrl}
								image={item.image}
								imageFieldRef={imageFieldRef}
								isDisabled={isFormDisabled}
								itemId={item.id}
								parentContainerId={item.parentContainer.id}
								readOnly={false}
								onImageProcessEnd={onItemImageProcessEnd}
							/>
						)}
					</div>
					<ItemActions
						classes={{ root: classes.section }}
						disabled={isFormDisabled}
						fields={formState.input.properties}
					/>
					{isDHSEnabled && (
						<DHSItemSetting
							cardDefinition={item?.cardDefinition}
							classes={{ root: classes.section }}
							fields={formState.input.cardDefinition}
							isEventItem={isEventItem}
							isExpiredEventItem={isEventItem && isExpiredItem(formState.input.properties)}
							isFeedItem={isFeedItem}
							itemProperties={item?.properties}
							parentContainerFeedType={parentContainerFeedType}
						/>
					)}
				</ExpandableCard>
				<PropertyPanelWrapper
					isDisabled={isFormDisabled}
					isEventItem={isEventItem}
					isFormDirty={isDirty}
					itemId={item?.id ?? ''}
					parentContainer={parentContainer}
					properties={item?.properties ?? []}
					propertiesFields={formState.input.properties}
				/>
				<ConfirmationDialog
					bodyText={confirmationDialogOptions.bodyText}
					cancelButtonHandler={confirmationDialogOptions.cancelButtonHandler}
					cancelButtonLabel={confirmationDialogOptions.cancelButtonLabel}
					closeButtonHandler={onContinueEditing}
					confirmButton={confirmationDialogOptions.confirmButton}
					isConfirmModalOpen={isConfirmModalOpen}
					setIsConfirmModalOpen={setIsConfirmModalOpen}
					title={confirmationDialogOptions.title}
				/>
				<InteractionBlocker isActive={saveButtonState !== 'idle'} />
			</Form>

			<FloatingBar
				className={classes.floatingBar}
				data-pp-at-target="FloatingBar-id"
				sticky={false}
				visible={!!successResult || isDirty || saving}
			>
				<Button
					disabled={saveButtonState !== 'idle'}
					displaySize="large"
					displayStyle="text"
					type="button"
					onClick={onDiscard}
				>
					{translate('actions.discard.text.idle')}
				</Button>
				{saveButton}
			</FloatingBar>
		</>
	);
});
