import { useState, useCallback, MouseEvent } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { animated } from '@react-spring/web';

import { ActionButton } from '@pushpay/button';
import Dropdown, { DropdownDirection } from '@pushpay/dropdown';
import { DragDropIcon, KebabIcon, MirroredIcon, EyeClosedIcon, InfoIcon } from '@pushpay/iconography';
import { Checkbox } from '@pushpay/inputs';
import { clsx } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { DraggableIcon } from '@src/components/draggable';
import { Icon } from '@src/components/icon';
import { KebabMenu, kebabMenuOptionHeight } from '@src/components/kebabMenu';
import { Link } from '@src/components/link';
import { DeleteModal } from '@src/components/modals';
import { TagWithTooltip } from '@src/components/tag';
import { useFeature } from '@src/featureFlags';
import { ContainerType, ItemType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useAppDesignPath } from '@src/pages/shell';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { CardHeader } from './CardHeader';
import useStyles from './cardStyles';

export type CardType = 'container' | 'item' | 'ccb' | 'resi';
export const NEW_CARD_PREFIX = 'new-card';

const HiddenIcon = () => {
	const { iconTag } = useStyles(undefined);

	return <EyeClosedIcon classes={{ root: iconTag }} />;
};

export type CardProps = ComponentProps<
	{
		icon: DraggableIcon;
		name: string;
		type: CardType;
		parentContainerType: ContainerType;
		containerType?: ContainerType;
		itemType?: ItemType;
		isDeletable?: boolean;
		isHidden: boolean;
		isMirrored: boolean;
		isFeedItem?: boolean;
		isChecked?: boolean;
		isCardDisabled?: boolean;
		isContainerFeedType?: boolean;
		isExpiredEvent?: boolean;
		position: number;
		applicationId: string;
		campus?: string;
		id: string;
		parentId?: string;
		isEditable: boolean;
		dragOverlay?: boolean;
		path?: string;
		editPath?: string;
		hideVisibilityToggle?: boolean;
		isAnyOtherCardSelected?: boolean;
		target?: HTMLDivElement;
		isHighlighted?: boolean;
		rootOnlyChildName?: string;
		hasInvalidTimeframeProperties?: boolean;
		hasNoTimeframeProperty?: boolean;
		onToggleVisibility?: (isHidden: boolean) => void;
		onDelete?: () => void;
		onCardSelect?: (isCardSelected: boolean, selectedCard: CardProps) => void;
		onCopyItem?: () => void;
		onMoveChild?: (card: CardProps) => void;
	},
	typeof useStyles
>;

function AnimatedWrapper({
	icon,
	name,
	type,
	isHidden,
	isMirrored,
	isDragDisabled = false,
	id,
	parentId,
	classes: classesProp,
	dragOverlay,
	children,
	isHighlighted,
	onMouseOutHandler = () => {},
	onMouseOverHandler = () => {},
	onClickHandler = () => {},
}: Pick<
	CardProps,
	| 'classes'
	| 'id'
	| 'parentId'
	| 'icon'
	| 'name'
	| 'type'
	| 'isHidden'
	| 'isMirrored'
	| 'children'
	| 'dragOverlay'
	| 'isHighlighted'
> & {
	isDragDisabled: boolean;
	onMouseOutHandler?: () => void;
	onMouseOverHandler?: () => void;
	onClickHandler?: (event: MouseEvent<HTMLDivElement>) => void;
}) {
	const classes = useStyles(classesProp);
	const { attributes, over, listeners, setNodeRef, transform, transition, isDragging, active } = useSortable({
		id,
		disabled: isDragDisabled,
		data: {
			parentId,
			icon,
			name,
			type,
			isHidden,
			isMirrored,
			id,
			isEditable: false,
		},
	});
	const overCurrent = over?.id === id && !active?.data.current?.sortable;
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const isNewCardCreating = id.startsWith(NEW_CARD_PREFIX);
	const shouldBeHighlighted = !isNewCardCreating && isHighlighted;

	return (
		<animated.div
			{...attributes}
			{...listeners}
			ref={setNodeRef}
			className={clsx(
				classes.card,
				shouldBeHighlighted && classes.highlight,
				isNewCardCreating && classes.shimmer,
				dragOverlay ? classes.overlay : classes.noOverlay,
				isDragging && classes.dragging,
				overCurrent && classes.insertBefore
			)}
			data-pp-at-target={isNewCardCreating ? 'new-card-loading-skeleton' : undefined}
			style={style}
			onClick={onClickHandler}
			onMouseOut={onMouseOutHandler}
			onMouseOver={onMouseOverHandler}
		>
			{children}
		</animated.div>
	);
}

export function Card(props: CardProps) {
	const {
		icon,
		name,
		type,
		parentContainerType,
		isDeletable,
		isHidden,
		isMirrored,
		isContainerFeedType,
		isFeedItem,
		campus,
		id,
		isEditable,
		classes: classesProp,
		path = '',
		editPath,
		hideVisibilityToggle,
		isChecked = false,
		isCardDisabled = false,
		isAnyOtherCardSelected = false,
		rootOnlyChildName = '',
		onToggleVisibility = () => {},
		onDelete,
		onCardSelect,
		onCopyItem,
		onMoveChild,
		'data-pp-at-target': targetId,
		hasInvalidTimeframeProperties = false,
		hasNoTimeframeProperty = false,
		isExpiredEvent,
	} = props;
	const classes = useStyles(classesProp);
	const { organizationKey } = useGetQueryAndMutationVars();
	const { translate } = useTranslation('appDesign');
	const cardPath = useAppDesignPath(organizationKey, path);
	const cardEditPath = useAppDesignPath(organizationKey, editPath || path);
	const [dropdownDirection, setDropdownDirection] = useState<DropdownDirection>('bottom');

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isHover, setIsHover] = useState<boolean>(false);
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const cardTypes: Record<CardType, string> = {
		item: classes.itemCard,
		ccb: classes.CCBModuleCard,
		container: classes.containerCard,
		resi: classes.resiCard,
	};

	const onChangeHandler = useCallback(
		(isCardSelected: boolean, selectedCard: CardProps) => {
			if (onCardSelect) {
				onCardSelect(isCardSelected, selectedCard);
			}
		},
		[onCardSelect]
	);

	const onDeleteClick = () => {
		setIsModalOpen(true);
	};

	const moveChildHandler = useCallback(() => {
		if (onMoveChild) {
			onMoveChild({ ...props });
		}
	}, [onMoveChild, props]);

	const isEventsContainer = parentContainerType === ContainerType.Events;
	const isDragDisabled = isModalOpen || isAnyOtherCardSelected || isEventsContainer;
	const actionButtonClickHandler = (e: MouseEvent) => {
		const kebabMenuMaximumHeight = kebabMenuOptionHeight * 5;
		if (
			e.currentTarget.getBoundingClientRect().bottom + kebabMenuMaximumHeight >
			window.document.documentElement.clientHeight
		) {
			setDropdownDirection('top');
		} else {
			setDropdownDirection('bottom');
		}
	};

	const infoIcon = <InfoIcon classes={{ root: classes.tagIcon }} displaySize="medium" />;

	const getVisibilityIconTooltipContent = useCallback(() => {
		if (hasNoTimeframeProperty) {
			return translate('tag.tooltip.noTimeframeProperty');
		}
		if (hasInvalidTimeframeProperties) {
			return translate('tag.tooltip.invalidTimeframeProperty');
		}

		return translate('kebabMenuItems.show', { type });
	}, [hasNoTimeframeProperty, hasInvalidTimeframeProperties, type, translate]);

	return (
		<AnimatedWrapper
			isDragDisabled={isDragDisabled}
			{...props}
			classes={{
				card: clsx(
					!isCardDisabled && classes.onHover,
					isChecked && classes.selectedCard,
					isDragDisabled && classes.cursorPointer,
					isCardDisabled && classes.disabledCard
				),
			}}
			onClickHandler={event => {
				onChangeHandler(!isChecked, { ...props, target: event.target as HTMLDivElement });
			}}
			onMouseOutHandler={() => {
				setIsHover(false);
			}}
			onMouseOverHandler={() => {
				setIsHover(true);
			}}
		>
			<div className={clsx(classes.cardContainer, cardTypes[type])}>
				<div className={classes.dragDropIconContainer}>
					{isHover && !isDragDisabled && (
						<DragDropIcon
							className={classes.dragDropIcon}
							data-pp-at-target={`${targetId}-drag-handle`}
							displaySize="small"
						/>
					)}
				</div>

				<Checkbox
					checked={isChecked}
					classes={{
						root: clsx(classes.iconContainer),
					}}
					disabled={isCardDisabled}
					id={id}
					onChange={() => {}}
					onClick={e => e.stopPropagation()}
				/>

				<Link
					classes={{ root: classes.cardLink }}
					data-pp-at-target={`${targetId}-link`}
					displayStyle="unstyled"
					tabIndex={-1}
					to={cardPath}
					onClick={event => {
						if (isAnyOtherCardSelected) {
							event.preventDefault();
						} else {
							event.stopPropagation();
						}
					}}
				>
					<div className={classes.iconContainer}>
						<Icon
							classes={{
								root: clsx(classes.icon, cardTypes[type]),
							}}
							name={icon}
						/>
					</div>
					<div className={classes.nameContainer}>
						<CardHeader
							classes={{ header: classes.name, headerWrapper: classes.cardName }}
							data-pp-at-target={`${targetId}-${id}-name`}
							headerText={name}
						/>
					</div>
					<div className={classes.tagsContainer}>
						{campus && (
							<TagWithTooltip
								classes={{
									tag: classes.campusTag,
								}}
								data-pp-at-target={targetId}
								displayStyle="success"
								label={campus}
							/>
						)}
						{isHidden && (
							<TagWithTooltip
								data-pp-at-target={targetId}
								displayStyle="default"
								label={translate('tag.hidden')}
								{...(hasInvalidTimeframeProperties && {
									icon: () => infoIcon,
									tooltipLabel: translate('tag.tooltip.invalidTimeframeProperty'),
								})}
								{...(hasNoTimeframeProperty && {
									icon: () => infoIcon,
									tooltipLabel: translate('tag.tooltip.noTimeframeProperty'),
								})}
							/>
						)}
						{isExpiredEvent && (
							<TagWithTooltip
								data-pp-at-target={targetId}
								displayStyle="error"
								label={translate('tag.expired')}
							/>
						)}
					</div>
				</Link>
			</div>

			<div className={classes.end}>
				{isHidden && (
					<Tooltip
						content={getVisibilityIconTooltipContent()}
						data-pp-at-target={`${targetId}-hidden-tooltip`}
						panelSpace="XSMALL"
						placement="top"
					>
						<ActionButton
							aria-label={translate('kebabMenuItems.show', { type })}
							data-pp-at-target={`${targetId}-hidden-button`}
							disabled={
								hasNoTimeframeProperty ||
								hasInvalidTimeframeProperties ||
								isChecked ||
								isAnyOtherCardSelected
							}
							displayStyle="secondary"
							icon={HiddenIcon}
							onClick={e => {
								e.stopPropagation();
								onToggleVisibility(false);
							}}
						/>
					</Tooltip>
				)}
				{isMirrored && (
					<Tooltip
						content="Mirrored"
						data-pp-at-target={`${targetId}-mirrored-tooltip`}
						panelSpace="XSMALL"
						placement="top"
					>
						<MirroredIcon className={classes.iconTag} data-pp-at-target={`${targetId}-mirrored-icon`} />
					</Tooltip>
				)}
				{isInAppCalendarEnabled && isContainerFeedType && isFeedItem && (
					<Tooltip content={translate('cardType.feedItem')} placement="top">
						<div className={classes.feedIconContainer}>
							<Icon classes={{ root: classes.feedIcon }} name="rss-1" />
						</div>
					</Tooltip>
				)}
				<Dropdown
					classes={{ summary: classes.buttonContainer }}
					closeOnBlur={!isModalOpen}
					data-pp-at-target={`${targetId}-dropdown`}
					dropdownDirection={dropdownDirection}
					lockFocus={!isModalOpen}
					overlay={close => (
						<KebabMenu
							copyItems={() => {
								if (onCopyItem) {
									onCopyItem();
									close();
								}
							}}
							data-pp-at-target={`${targetId}-kebab-menu`}
							direction={dropdownDirection}
							editPath={cardEditPath}
							hasInvalidTimeframeProperties={hasInvalidTimeframeProperties}
							hasNoTimeframeProperty={hasNoTimeframeProperty}
							hideVisibilityToggle={hideVisibilityToggle}
							isDeletable={isDeletable}
							isEditable={isEditable}
							isFeedItem={isFeedItem && isContainerFeedType}
							isHidden={isHidden}
							moveChild={moveChildHandler}
							rootOnlyChildName={rootOnlyChildName}
							setIsModalOpen={onDeleteClick}
							toggleHide={onToggleVisibility}
							type={type}
						/>
					)}
					hideDropdownArrow
					onClick={ev => ev.stopPropagation()}
					onMouseDown={ev => ev.preventDefault()}
					onPointerDown={ev => ev.stopPropagation()}
				>
					<ActionButton
						aria-label={`Action Menu ${type}`}
						className={classes.buttonIcon}
						data-pp-at-target={`${targetId}-${type.replace(' ', '')}-action-button`}
						disabled={isChecked || isAnyOtherCardSelected}
						displayStyle="secondary"
						icon={KebabIcon}
						tabIndex={-1}
						onClick={actionButtonClickHandler}
					/>
				</Dropdown>
			</div>

			<DeleteModal
				isDeletable={isDeletable}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				translatedCardType={type}
				onDelete={onDelete}
			/>
		</AnimatedWrapper>
	);
}
