import { useEffect, useMemo } from 'react';

import { FormState } from '@pushpay/forms';

import { AppPreviewNavigationData, useAppPreviewNavigationUpdateContext, useFormContext } from '@src/context';
import { GetContainerSettingsQuery, SaveContainerSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { getContainerCardType } from '@src/utils';

import { PreviewPlaceholder } from '../PreviewPlaceholder';
import { ContainerPreviewData } from '../types';
import { ContainerPreview } from './ContainerPreview';

type ContainerSettingsPreviewProps = {
	data: GetContainerSettingsQuery;
	formState: FormState<SaveContainerSettingsSchema>;
};

function ContainerSettingsPreviewContentImpl({ data, formState }: ContainerSettingsPreviewProps) {
	const { translate } = useTranslation('appDesign');
	const { input } = formState;

	const { updateNavigationData } = useAppPreviewNavigationUpdateContext();

	const containerId = input.containerId.value;
	const containerName = input.name.value;
	const navAction = input.navigationAction.value?.toLowerCase();
	const containerCampusId = input.campusId.value;
	const icon = input.icon.value;

	const currentNavigationData = useMemo<AppPreviewNavigationData>(
		() => ({
			containerCampusId,
			containerId,
			icon,
			navAction,
			screenTitle: containerName,
		}),
		[containerCampusId, containerId, containerName, icon, navAction]
	);

	useEffect(() => {
		updateNavigationData?.(currentNavigationData);
	}, [updateNavigationData, currentNavigationData]);

	const container = data.organization?.application?.container;
	if (!container) return null;

	if (getContainerCardType(container.type) === 'ccb') {
		return <PreviewPlaceholder placeholderText={translate('preview.ccbContainerPlaceholder')} />;
	}

	const containerData: ContainerPreviewData = {
		id: container.id,
		children: container.children.nodes,
		images: container.image?.urls,
		subtitle: input.subtitle.value,
		template: input.template.value || container.template,
		type: input.type.value || container.type,
		resiLink: input.resiLink.value,
	};

	return <ContainerPreview container={containerData} />;
}

export function ContainerSettingsPreviewContent(props: Omit<ContainerSettingsPreviewProps, 'formState'>) {
	const { formState } = useFormContext();
	if (!formState) {
		return null;
	}

	return (
		<ContainerSettingsPreviewContentImpl
			{...props}
			formState={formState as FormState<SaveContainerSettingsSchema>}
		/>
	);
}
