import React, { ErrorInfo, PropsWithChildren } from 'react';

import { send } from '@src/raygun';

export type FallbackProps = {
	error?: Error;
};

type ErrorBoundaryProps = {
	onError?: (error: Error, customData: ErrorInfo) => void;
	fallback?: React.ComponentType<FallbackProps>;
};

type ErrorBoundaryState = {
	error: Error | null;
};

export class ErrorBoundary extends React.Component<PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
	state: Readonly<ErrorBoundaryState> = { error: null };

	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		send(error, [{ errorInfo }]);

		const { onError } = this.props;
		if (onError) {
			onError(error, errorInfo);
		}
	}

	render() {
		const { fallback: Fallback, children } = this.props;
		const { error } = this.state;

		if (error !== null) {
			return Fallback ? <Fallback error={error} /> : null;
		}

		return children;
	}
}
