import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const TOGGLE_LIGHT_THEME = '#d67d00';
export const TOGGLE_DARK_THEME = '#262626';

export const useStyles = createUseStyles((theme: Theme) => ({
	root: ({ isDarkModeSupported }: { isDarkModeSupported?: boolean }) => ({
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		...(!isDarkModeSupported && { opacity: 0.3 }),
	}),
	checkboxRoot: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	checkboxWrapper: {
		paddingLeft: 0,
		height: '26px',
		'&::before, &::after': {
			display: 'none',
		},
	},
	switch: {
		height: '26px',
		width: '48px',
		position: 'relative',
		backgroundColor: TOGGLE_LIGHT_THEME,
		borderRadius: '16px',
	},
	switchDark: {
		backgroundColor: TOGGLE_DARK_THEME,
	},
	toggleIcon: {
		position: 'absolute',
		left: 0,
		height: '16px',
		width: '16px',
		padding: '4px',
		boxSizing: 'content-box',
		backgroundColor: theme.colors['common-white'],
		border: `1px solid ${TOGGLE_LIGHT_THEME}`,
		borderRadius: theme.SHAPE.CIRCULAR,
		transition: 'all 0.3s',
	},
	toggleIconDark: {
		border: `1px solid ${TOGGLE_DARK_THEME}`,
		left: '50%',
	},
	toggleLabel: {
		margin: `${theme.SPACING.SMALL} 0 ${theme.SPACING.XSMALL} 0`,
		font: theme.typography['text-4'],
		lineHeight: theme.SPACING.SMALL,
		color: theme.colors['text-heading'],
	},
	tooltips: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
}));
