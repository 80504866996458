import { CardLoadingSkeleton } from '@src/components/card/CardLoadingSkeleton';

export function ContainerChildrenLoadingSkeleton({ rows = 10, ariaLabel }: { rows?: number; ariaLabel?: string }) {
	return (
		<div aria-label={ariaLabel}>
			{Array.from({ length: rows }, (_, index) => (
				<CardLoadingSkeleton key={index.toString()} />
			))}
		</div>
	);
}
