import { useState } from 'react';

type AddItem<T> = <U extends T & { id: string }>(item: U) => void;

export type MapState<T> = {
	itemsMap: Map<string, T>;
	addItem: AddItem<T>;
	deleteItem: (id: string) => void;
	hasItemWithId: (id: string) => boolean;
	getItemCount: () => number;
	getAllItems: () => T[];
	clear: () => void;
};

export const useMapState = <T>(): MapState<T> => {
	const [itemsMap, setItemsMap] = useState(new Map<string, T>());

	const getAllItems = () => Array.from(itemsMap.values());

	const addItem: AddItem<T> = item => {
		if (!itemsMap.has(item.id)) {
			setItemsMap(new Map(itemsMap.set(item.id, item)));
		}
	};

	const deleteItem = (id: string) => {
		if (itemsMap.has(id)) {
			const newMap = new Map(itemsMap);
			newMap.delete(id);
			setItemsMap(newMap);
		}
	};

	const clear = () => {
		const newMap = new Map();
		setItemsMap(newMap);
	};

	const hasItemWithId = (id: string) => itemsMap.has(id);

	const getItemCount = () => itemsMap.size;

	return { itemsMap, addItem, deleteItem, hasItemWithId, getItemCount, getAllItems, clear };
};
