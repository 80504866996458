import { PropertyInputType } from '@src/components/properties/types';
import { PropertyType } from '@src/graphql/generated';

import { ItemPropertiesFields } from '../types';

export enum CONSTANTS {
	YOUTUBE_ICON = 'play-1',
	YOUTUBE_HEADER = 'Youtube',
	VIMEO_ICON = 'custom-vimeo',
	VIMEO_HEADER = 'Vimeo',
	PROPERTY_TYPE = 'PROPERTY_TYPE',
}

export const propertyInputTypeObj: {
	[key in PropertyType]: PropertyInputType;
} = {
	[PropertyType.AddToContacts]: 'addToContactsPropertyInput',
	[PropertyType.Address]: 'addressPropertyInput',
	[PropertyType.AppLink]: 'appLinkPropertyInput',
	[PropertyType.Audio]: 'audioPropertyInput',
	[PropertyType.Blankify]: 'blankifyPropertyInput',
	[PropertyType.CallToAction]: 'callToActionPropertyInput',
	[PropertyType.Carousel]: 'carouselPropertyInput',
	[PropertyType.Default]: 'defaultPropertyInput',
	[PropertyType.Give]: 'givePropertyInput',
	[PropertyType.KeyMetrics]: 'keyMetricsPropertyInput',
	[PropertyType.Share]: 'sharePropertyInput',
	[PropertyType.Sms]: 'smsPropertyInput',
	[PropertyType.Text]: 'textPropertyInput',
	[PropertyType.TextHtml]: 'textHtmlPropertyInput',
	[PropertyType.Timeframe]: 'timeframePropertyInput',
	[PropertyType.Unknown]: 'defaultPropertyInput',
	[PropertyType.UserNote]: 'userNotePropertyInput',
	[PropertyType.Video]: 'videoPropertyInput',
	[PropertyType.Website]: 'websitePropertyInput',
};

export const isVimeo = (value: string) => /vimeo/i.test(value);
export const isYouTube = (value: string) => /(youtube|youtu\.be)/i.test(value);

export const getSortedPropertiesFields = (dynamicFieldsProperties: ItemPropertiesFields[number][]) => {
	const sortedPropertiesFields = [...dynamicFieldsProperties].sort((a, b) => {
		const [propertyA] = Object.values(a);
		const [propertyB] = Object.values(b);

		const valueA = propertyA.baseProperty.position.value ?? Number.MAX_SAFE_INTEGER;
		const valueB = propertyB.baseProperty.position.value ?? Number.MAX_SAFE_INTEGER;
		return valueA - valueB;
	});

	return sortedPropertiesFields;
};

export const getPropertiesIds = (dynamicFieldsProperties: ItemPropertiesFields[number][]) =>
	dynamicFieldsProperties.map(propertyField => {
		const [property] = Object.values(propertyField);

		return property.baseProperty.id.value ?? 0;
	});
