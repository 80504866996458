import { useTranslation } from '@src/i18n';
import { getAppSettings } from '@src/utils';

import { ServiceUnavailableIllustration } from '../illustrations';
import { BaseError } from './BaseError';
import { FallbackProps } from './ErrorBoundary';

const targetId = 'general-error';

export const GeneralError = ({ error }: FallbackProps) => {
	const { translate } = useTranslation();
	const { env } = getAppSettings();

	return (
		<BaseError
			data-pp-at-target={targetId}
			illustration={ServiceUnavailableIllustration}
			text={(env === 'dev' && error?.message) || translate('errors.text.generalError')}
			title={translate('errors.title.generalError')}
			withGetHelpLink
			withReloadLink
		/>
	);
};
