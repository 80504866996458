import { useEffect, useRef } from 'react';

import { useEvent } from './useEvent';

type PollingProps = {
	inProgress: boolean;
	startPolling: (interval: number) => void;
	stopPolling: () => void;
	onPollEnd?: () => void;
	interval?: number;
};

const DEFAULT_POLL_INTERVAL = 3000; // polls every 3 seconds

export function usePolling({
	inProgress,
	startPolling,
	stopPolling,
	onPollEnd,
	interval = DEFAULT_POLL_INTERVAL,
}: PollingProps) {
	const isPolling = useRef(false);
	const onCleanUp = useEvent(() => {
		if (isPolling.current) {
			stopPolling();
		}
	});

	useEffect(() => {
		if (!isPolling.current && inProgress) {
			startPolling(interval);
			isPolling.current = true;
		}

		if (isPolling.current && !inProgress) {
			stopPolling();
			onPollEnd?.();
			isPolling.current = false;
		}
	}, [inProgress, interval, onPollEnd, startPolling, stopPolling]);

	useEffect(() => onCleanUp, [onCleanUp]);
}
