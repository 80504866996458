import { RefObject } from 'react';

import { useVirtualizer } from '@tanstack/react-virtual';

import { ICON_NAME } from '@src/components/icon';

export const useIconPickerReactVirtual = (containerRef: RefObject<HTMLDivElement>, data: Array<ICON_NAME>) => {
	const containerWidth = containerRef.current?.clientWidth || 600;
	const minimalWidth = 60;
	const height = 44;
	const rowGap = 16;
	const columnCount = Math.floor(containerWidth / minimalWidth);
	const leftWidth = (containerWidth - columnCount * minimalWidth) / columnCount;
	const rowCount = Math.ceil(data.length / columnCount);
	const rowVirtualizer = useVirtualizer({
		count: rowCount,
		getScrollElement: () => containerRef.current,
		estimateSize: () => height + rowGap,
		overscan: 3,
	});
	const columnVirtualizer = useVirtualizer({
		horizontal: true,
		count: columnCount,
		getScrollElement: () => containerRef.current,
		estimateSize: () => minimalWidth + leftWidth,
		overscan: 0,
	});

	return {
		rowVirtualizer,
		columnVirtualizer,
		columnCount,
		rowCount,
	};
};
