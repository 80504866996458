import React, { useRef } from 'react';

import { NetworkStatus } from '@apollo/client';

import { SkeletonForm } from '@pushpay/skeleton';

import { Banner } from '@src/components/banner';
import { PageNotFoundError } from '@src/components/errors';
import { ContentType, useBreadcrumbDispatchContext, useContentDispatchContext, useErrorContext } from '@src/context';
import { apolloClient } from '@src/graphql/client';
import {
	GetContainerSettingsDocument,
	GetContainerSettingsQuery,
	useGetContainerSettingsQuery,
} from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';
import { useParams } from '@src/router';
import { useItemAndContainerDeleteMutation, useGetQueryAndMutationVars } from '@src/shared/hooks';
import { useItemAndContainerVisibilityMutation } from '@src/shared/hooks/useItemAndContainerVisibilityMutation';
import { getContainerCardType } from '@src/utils';

import { CONTAINER_CHILDREN_PAGING_SIZE } from '../constants';
import { ContainerSettingsForm, ContainerSettingFormRefType } from './ContainerSettingsForm';

type LoaderParams = {
	organizationKey: string;
	platformCampusKey?: string | null;
	applicationId: string;
	containerId: string;
};

export function loader({ organizationKey, platformCampusKey, applicationId, containerId }: LoaderParams) {
	apolloClient.client?.query<GetContainerSettingsQuery>({
		query: GetContainerSettingsDocument,
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
		fetchPolicy: 'cache-first',
	});
}

export function ContainerSettings() {
	const { containerId } = useParams<{ organizationKey: string; containerId: string }>();
	const { organizationKey, platformCampusKey } = useGetQueryAndMutationVars();
	const { currentApp, dhs } = useMyApp();
	const containerSettingFormRef = useRef<ContainerSettingFormRefType>(null);
	const { useSetContent } = useContentDispatchContext();
	const { useUpdatePageNotFoundError } = useErrorContext();
	const { loading, error, data, refetch, networkStatus } = useGetContainerSettingsQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId: currentApp?.id || '',
			containerId,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
		fetchPolicy: 'cache-first',
	});
	const isLoadingData = loading && !data;
	const { generateOnToggleVisibilityHandler } = useItemAndContainerVisibilityMutation({
		organizationKey,
		platformCampusKey,
		applicationId: currentApp?.id || '',
	});

	const { useQueryData } = useBreadcrumbDispatchContext();
	const contentType = ContentType.CONTAINER_SETTING;
	useQueryData({ data, type: contentType }, isLoadingData);
	useSetContent({ type: contentType, contentData: data, loading: isLoadingData, error: !!error });
	const container = data?.organization?.application?.container;

	const { generateDeleteHandler } = useItemAndContainerDeleteMutation({
		organizationKey,
		platformCampusKey,
		applicationId: currentApp?.id || '',
	});

	const containerType = getContainerCardType(container?.type);
	const parentContainerId = container?.parentContainer?.id as string;

	const isContainerNotFound = !isLoadingData && container === null;

	const onDeleteHandler = () => {
		containerSettingFormRef?.current?.resetBeforeDelete();
		generateDeleteHandler({
			id: containerId,
			type: containerType,
			parentId: parentContainerId,
			onCompleted: containerSettingFormRef?.current?.onConfirmDelete,
		})();
	};

	useUpdatePageNotFoundError(isContainerNotFound);

	return (
		<React.Suspense fallback={<p>loading...</p>}>
			{isContainerNotFound && <PageNotFoundError />}
			{container && (
				<div>
					{containerId && containerType && (
						<Banner
							contentType={contentType}
							isDeletable={container?.isDeletable}
							isEditable={false}
							isHidden={container.isHidden || false}
							name={container.name || ''}
							type={containerType}
							onDelete={onDeleteHandler}
							onToggleVisibility={generateOnToggleVisibilityHandler(
								containerId,
								'container',
								parentContainerId
							)}
						/>
					)}
					{networkStatus === NetworkStatus.loading ? (
						<SkeletonForm rows={5} sections={2} />
					) : (
						<ContainerSettingsForm
							ref={containerSettingFormRef}
							containerSettings={container}
							isDHSEnabled={dhs.enabled}
							refetchContainerSettings={() => {
								refetch();
							}}
						/>
					)}
				</div>
			)}
		</React.Suspense>
	);
}
