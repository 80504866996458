import { ComponentProps } from 'react';

import { FormState } from '@pushpay/forms';

import { ContainerTemplate, SaveContainerSettingsSchema, SaveItemSettingsSchema } from '@src/graphql/generated';

import { IconField } from './IconField';

type IconFieldProps = Omit<ComponentProps<typeof IconField>, 'isReadOnly'> & {
	field: FormState<SaveContainerSettingsSchema | SaveItemSettingsSchema>['input']['icon'];
	template?: ContainerTemplate;
	readOnly?: boolean;
};

function isFieldReadOnly(readOnly: boolean | undefined, template: ContainerTemplate | undefined): boolean {
	const readOnlyTemplates = [
		ContainerTemplate.Root,
		ContainerTemplate.List_72DpIcon,
		ContainerTemplate.List_72DpIconTidbit,
	];

	const isReadOnly = readOnly || !readOnlyTemplates.includes(template ?? ContainerTemplate.Unknown);

	return isReadOnly;
}

export const SettingsIconField = ({ classes, field, template, readOnly = false }: IconFieldProps) => (
	<IconField classes={classes} field={field} isReadOnly={isFieldReadOnly(readOnly, template)} />
);
