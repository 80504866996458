import { memo, useCallback, useState, useDeferredValue } from 'react';

import fuzzysort from 'fuzzysort';

import { Card } from '@pushpay/card';
import { Heading, Stack } from '@pushpay/layout';
import Modal from '@pushpay/modal';
import { clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { ICON_NAME_LIST, ICON_NAME } from '@src/components/icon';
import { useTranslation } from '@src/i18n';

import { Footer } from './Footer';
import { IconContainer } from './IconContainer';
import { useIconPickerStyles } from './iconPickerStyles';
import { Search } from './Search';

const defaultValue = '';

export type IconPickerProps = {
	isOpen: boolean;
	onClickCloseButton: () => void;
	onClickCancelButton: () => void;
	onClickSelectButton: (value: string) => void;
};

export const IconPicker = memo(
	({
		isOpen,
		onClickCloseButton,
		onClickCancelButton,
		onClickSelectButton,
		classes: classesProp,
		className: classNameProp,
		'data-pp-at-target': targetId,
	}: ComponentProps<IconPickerProps, typeof useIconPickerStyles>) => {
		const classes = useIconPickerStyles(classesProp);
		const className = clsx(classes.root, classNameProp);

		const { translate } = useTranslation('appDesign');
		const modalTranslate = useTranslation('common').translate;

		const [selectedIcon, setSelectedIcon] = useState<ICON_NAME | undefined>(undefined);
		const [searchResults, setSearchResults] = useState<ICON_NAME[]>(ICON_NAME_LIST);

		const deferredSearchResults = useDeferredValue(searchResults);
		const setSelectedIconHandler = useCallback((name: ICON_NAME) => {
			setSelectedIcon(name);
		}, []);

		const getSearchResults = useCallback(
			(current: string) =>
				fuzzysort
					.go(current, ICON_NAME_LIST, {
						threshold: -10000,
						limit: 80,
					})
					.map(item => item.target) as ICON_NAME[],
			[]
		);

		const clearState = useCallback(() => {
			setSelectedIcon(undefined);
			setSearchResults(ICON_NAME_LIST);
		}, []);

		const onInputChangeHandler = useCallback(
			(current: string) => {
				if (selectedIcon) {
					setSelectedIcon(undefined);
				}
				if (!current) {
					setSearchResults(ICON_NAME_LIST);
					return;
				}
				setSearchResults(getSearchResults(current));
			},
			[selectedIcon, getSearchResults]
		);

		const onClickCancelButtonHandler = useCallback(() => {
			clearState();
			onClickCancelButton();
		}, [clearState, onClickCancelButton]);

		const onClickSelectButtonHandler = useCallback(() => {
			clearState();
			if (selectedIcon) {
				onClickSelectButton(selectedIcon);
			}
		}, [selectedIcon, clearState, onClickSelectButton]);

		const onClickCloseButtonHandler = useCallback(() => {
			clearState();
			onClickCloseButton();
		}, [clearState, onClickCloseButton]);

		const footer = () => (
			<Footer
				data-pp-at-target={targetId}
				isDisabled={!selectedIcon}
				onClickCancelButton={onClickCancelButtonHandler}
				onClickSelectButton={onClickSelectButtonHandler}
			/>
		);
		return (
			<Modal
				classes={{ modal: classes.modal, mask: classes.mask, closeModalButton: classes.closeModalButton }}
				className={className}
				closeButtonLabel={modalTranslate('modal.close')}
				data-pp-at-target={targetId}
				isOpen={isOpen}
				onClickCloseButton={onClickCloseButtonHandler}
			>
				<Card
					classes={{ body: classes.cardBody, footer: classes.footer }}
					data-pp-at-target={`${targetId}-card`}
					renderFooter={footer}
				>
					<Stack>
						<Heading
							classes={{ root: classes.heading }}
							data-pp-at-target={`${targetId}-heading`}
							level="2"
						>
							{translate('iconPicker.title')}
						</Heading>
						<Search
							data-pp-at-target={targetId}
							value={defaultValue}
							onInputChange={onInputChangeHandler}
							onReset={clearState}
						/>
						<div className={classes.scrollContainer}>
							<IconContainer
								data-pp-at-target={targetId}
								searchResults={deferredSearchResults}
								selectedIcon={selectedIcon}
								onSelect={setSelectedIconHandler}
							/>
						</div>
					</Stack>
				</Card>
			</Modal>
		);
	}
);
