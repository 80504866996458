import { useCallback } from 'react';

import unfetch from 'unfetch';

import { CarouselFile, CarouselFileUploadType } from '@src/components/carouselUploader/types';
import { useGenerateFileUploadPostMutation } from '@src/graphql/generated';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

export type ImageFileDataForUploadObject = {
	id: string;
	imageFileDataForUpload: ImageFileDataForUpload;
};

export type ImageFileDataForUpload = {
	key: string;
	acl: string;
	Tagging: string;
	'X-Amz-Algorithm': string;
	'X-Amz-Credential': string;
	'X-Amz-Date': string;
	Policy: string;
	'X-Amz-Signature': string;
	'X-Amz-Security-Token': string;
	file: File;
	url: string;
};

type OnCarouselFileSelectCallback = (
	imageUrl: string,
	previewImageData: CarouselFile,
	uploadImageData: ImageFileDataForUploadObject | undefined
) => void | undefined;

export function useCarouselFileUploadMutation(
	onCarouselFileSelectCallback: OnCarouselFileSelectCallback = () => undefined
) {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const [generateFileUploadPost] = useGenerateFileUploadPostMutation();

	const onCarouselFileSelect = useCallback(
		async (carouselFile: CarouselFile) => {
			if (carouselFile.type === CarouselFileUploadType.URL) {
				onCarouselFileSelectCallback(carouselFile.file, carouselFile, undefined);
				return;
			}

			const result = await generateFileUploadPost({
				variables: {
					organizationKey,
					platformCampusKey,
					input: { applicationId },
				},
			});

			if (!result.data) return;

			const { presignedPost, unprocessedImageUrl } = result.data.generateFileUploadPost;

			const imageFileData: ImageFileDataForUpload = {
				key: presignedPost.key,
				acl: presignedPost.acl,
				Tagging: presignedPost.tagging,
				'X-Amz-Algorithm': presignedPost.algorithm,
				'X-Amz-Credential': presignedPost.credential,
				'X-Amz-Date': presignedPost.date,
				Policy: presignedPost.policy,
				'X-Amz-Signature': presignedPost.signature,
				'X-Amz-Security-Token': presignedPost.token,
				file: carouselFile.file,
				url: presignedPost.url,
			};

			const imageFileDataObject: ImageFileDataForUploadObject = {
				id: carouselFile.id,
				imageFileDataForUpload: imageFileData,
			};

			onCarouselFileSelectCallback(unprocessedImageUrl, carouselFile, imageFileDataObject);
		},
		[generateFileUploadPost, organizationKey, platformCampusKey, applicationId, onCarouselFileSelectCallback]
	);

	const uploadCarouselFiles = useCallback(
		async (imageFileDataForUploadObjects: ImageFileDataForUploadObject[]): Promise<boolean> => {
			const uploadPromises = imageFileDataForUploadObjects.map(async dataObject => {
				const formData = new FormData();
				const {
					imageFileDataForUpload: { url },
				} = dataObject;

				Object.entries(dataObject.imageFileDataForUpload).forEach(([key, value]) => {
					formData.append(key, value);
				});

				return unfetch(url, { method: 'POST', body: formData });
			});

			const results = await Promise.all(uploadPromises);
			const isUploadSuccessful = results.every(result => result.ok);

			return isUploadSuccessful;
		},
		[]
	);

	return {
		onCarouselFileSelect,
		uploadCarouselFiles,
	};
}
