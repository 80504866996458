import { FieldFunctionOptions, StoreObject } from '@apollo/client';

import { CursorPagingOutput } from '@src/graphql/generated';

import { mergeArrayByField } from './merge-array-by-field';

export function mergePagingOutputByField<
	TItem extends StoreObject,
	TCollection extends { paging: CursorPagingOutput } = { nodes: TItem[]; paging: CursorPagingOutput }
>(keyField: keyof TItem, collectionField: keyof TCollection = 'nodes' as keyof TCollection) {
	return (existing: TCollection | undefined, incoming: TCollection, options: FieldFunctionOptions) => {
		const { [collectionField]: incomingItems, paging: incomingPaging, ...rest } = incoming;
		const mergedItems = mergeArrayByField<TItem>(keyField)(
			existing?.[collectionField] as unknown as TItem[],
			incomingItems as unknown as TItem[],
			options
		);
		const mergedPaging = {
			...existing?.paging,
			...incomingPaging,
		};

		return {
			...rest,
			paging: mergedPaging,
			[collectionField]: mergedItems,
		};
	};
}
