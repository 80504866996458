import { CSSProperties } from '@pushpay/styles';
import { AppStudioTheme as theme } from '@pushpay/theming';

export type StyleParams = {
	primaryColor?: string;
};

const LIGHT_GRAY = '#e6e6e6';

export const classNames = {
	ppEditorTheme__h1: {
		font: theme.typography['heading-1'],
		fontWeight: 600,
		margin: 0,
	},
	ppEditorTheme__h2: {
		font: theme.typography['heading-2'],
		margin: 0,
	},
	ppEditorTheme__h3: {
		font: theme.typography['heading-3'],
		margin: 0,
	},
	ppEditorTheme__indent: {
		'--lexical-indent-base-value': '40px',
	},
	ppEditorTheme__link: ({ primaryColor }: StyleParams) => ({
		color: primaryColor ?? theme.colors['blue-500'],
		textDecoration: 'none',
	}),
	'ppEditorTheme__link:hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	ppEditorTheme__listItem: {
		margin: '0 32px',
	},
	ppEditorTheme__nestedListItem: {
		listStyleType: 'none',
	},
	ppEditorTheme__ol1: {
		padding: 0,
		margin: 0,
		listStylePosition: 'inside',
	},
	ppEditorTheme__ol2: {
		padding: 0,
		margin: 0,
		listStyleType: 'upper-alpha',
		listStylePosition: 'inside',
	},
	ppEditorTheme__ol3: {
		padding: 0,
		margin: 0,
		listStyleType: 'lower-alpha',
		listStylePosition: 'inside',
	},
	ppEditorTheme__ol4: {
		padding: 0,
		margin: 0,
		listStyleType: 'upper-roman',
		listStylePosition: 'inside',
	},
	ppEditorTheme__ol5: {
		padding: 0,
		margin: 0,
		listStyleType: 'lower-roman',
		listStylePosition: 'inside',
	},
	ppEditorTheme__ul: {
		padding: 0,
		margin: 0,
	},
	ppEditorTheme__ltr: {
		textAlign: 'left',
	},
	ppEditorTheme__paragraph: {
		margin: '6px 0',
		fontSize: '18px',
		position: 'relative',
	},
	ppEditorTheme__quote: ({ primaryColor }: StyleParams) => ({
		margin: '10px 0',
		fontSize: '18px',
		fontStyle: 'italic',
		color: theme.colors['grey-1100'],
		borderLeft: `3px solid ${primaryColor ?? theme.colors['blue-700']}`,
		paddingLeft: '23px',
	}),
	ppEditorTheme__rtl: {
		textAlign: 'right',
	},
	ppEditorTheme__textBold: {
		fontWeight: 'bold',
	},
	ppEditorTheme__textItalic: {
		fontStyle: 'italic',
	},
	ppEditorTheme__textStrikethrough: {
		textDecoration: 'line-through',
	},
	ppEditorTheme__textUnderline: {
		textDecoration: 'underline',
	},
	ppEditorTheme__textUnderlineStrikethrough: {
		textDecoration: 'underline line-through',
	},
	blankify: {
		padding: '1px 4px',
		borderRadius: '2px',
		backgroundColor: LIGHT_GRAY,
		color: theme.colors['grey-900'],
	},
};

const classNamesMap = (styleParams: StyleParams) =>
	Object.fromEntries(
		Object.entries(classNames).map(([styleKey, styleValue]) => [
			`& .${styleKey}`,
			typeof styleValue === 'function' ? styleValue(styleParams) : styleValue,
		])
	) as CSSProperties;

export const themeStyles = (styleParams: StyleParams): CSSProperties => classNamesMap(styleParams);
