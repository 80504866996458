import { ContentType, useContentStateContext } from '@src/context';
import {
	GetContainerChildrenQuery,
	GetContainerSettingsQuery,
	GetDhsCardsQuery,
	GetItemQuery,
} from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';

import { ContainerChildrenPreviewContent, ContainerSettingsPreviewContent } from './ContainerChildrenPreviewContent';
import { DhsPreviewContent } from './DhsPreviewContent';
import { ItemPreviewContent } from './ItemPreviewContent';

export function PreviewContent() {
	const {
		dhs: { enabled: isDhsEnabled },
	} = useMyApp();
	const { type, error, contentData } = useContentStateContext();

	if (error || !contentData) return null;

	switch (type) {
		case ContentType.CONTAINER_CHILDREN:
			return (
				<ContainerChildrenPreviewContent
					data={contentData as GetContainerChildrenQuery}
					isRootContainer={false}
				/>
			);
		case ContentType.CONTAINER_SETTING:
			return <ContainerSettingsPreviewContent data={contentData as GetContainerSettingsQuery} />;
		case ContentType.ITEM:
			return <ItemPreviewContent data={contentData as GetItemQuery} />;
		case ContentType.DHS:
			return <DhsPreviewContent data={contentData as GetDhsCardsQuery} />;
		case ContentType.ROOT:
			return isDhsEnabled ? (
				<DhsPreviewContent data={contentData as GetDhsCardsQuery} />
			) : (
				<ContainerChildrenPreviewContent data={contentData as GetContainerChildrenQuery} isRootContainer />
			);
		default:
			return null;
	}
}
