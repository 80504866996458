import { createUseStyles } from '@pushpay/styles';
import { Tag, TagProps } from '@pushpay/tag';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { getTruncatedText } from '@src/utils';

export const getTruncatedTagText = (label: string) => {
	const TRUNCATE_TAG_LENGTH = 20;
	return label.length > TRUNCATE_TAG_LENGTH ? getTruncatedText(label, TRUNCATE_TAG_LENGTH) : label;
};

const useStyles = createUseStyles({
	tag: {},
	headerWrapper: {
		width: '100%',
	},
});

type TagWithTooltipProps = TagProps & { tooltipLabel?: string };

export function TagWithTooltip({
	icon,
	label,
	tooltipLabel,
	displayStyle,
	'data-pp-at-target': targetId,
	classes: classesProp,
}: ComponentProps<TagWithTooltipProps, typeof useStyles>) {
	const classes = useStyles(classesProp);

	const truncatedLabel = getTruncatedTagText(label);
	const isTagTruncated = truncatedLabel !== label;

	const tooltipContent = tooltipLabel || label;
	const isTooltipDisabled = !tooltipLabel && !isTagTruncated;

	return (
		<Tooltip
			className={classes.headerWrapper}
			content={tooltipContent}
			data-pp-at-target={`${targetId}-tooltip`}
			disabled={isTooltipDisabled}
			panelSpace="XSMALL"
			placement="top"
		>
			<Tag
				classes={{ root: classes.tag }}
				data-pp-at-target={`${targetId}-tag`}
				displayStyle={displayStyle}
				icon={icon}
				label={truncatedLabel}
			/>
		</Tooltip>
	);
}
