import { useCallback, useRef } from 'react';

import { Button } from '@pushpay/button';
import { Card } from '@pushpay/card';
import { Heading, Inline, Stack } from '@pushpay/layout';
import Modal from '@pushpay/modal';
import Tooltip from '@pushpay/tooltip';

import { ContainerType } from '@src/graphql/generated';
import { Trans, useTranslation } from '@src/i18n';
import { loader as containerChildrenLoader } from '@src/pages/containerChildren';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { getTruncatedText } from '@src/utils';

import { ChildrenNavigation } from './ChildrenNavigation';
import { useDestinationModalReducer, DestinationModalActions } from './destinationModalReducer';
import useStyles from './navigationStyles';
import { RootNavigation } from './RootNavigation';
import {
	NavigationContainer,
	DestinationModalProps,
	SelectedContainer,
	ContainerChildren,
	EventsContainerChildrenType,
} from './types';

const targetId = 'destination-modal';

export const DestinationModal = (props: DestinationModalProps) => {
	const {
		parentContainerId: initialContainerId,
		isRootContainer: isRootContainerProp = false,
		hasRestrictedRootTypes,
		...componentProps
	} = props;
	const { totalSelection, actionType, isModalOpen, onCloseButton, onSelectButton } = componentProps;

	const classes = useStyles(undefined);

	const { translate } = useTranslation('appDesign');
	const { translate: modalTranslate } = useTranslation('common');

	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const [{ selectedContainer, currentContainerId, isRootContainer }, dispatch] = useDestinationModalReducer({
		initialContainerId,
		isRootContainer: isRootContainerProp,
		hasRestrictedRootTypes,
	});

	const scrollRef = useRef<HTMLDivElement>(null);
	const scrollList = useRef<Record<string, number>>({});
	const currentContainerRef = useRef<NavigationContainer>();
	const setCurrentContainer = (currentContainer: NavigationContainer) => {
		currentContainerRef.current = currentContainer;
	};

	const prefetchContainerChildren = () => {
		const selectedContainerId = selectedContainer?.id;

		return selectedContainerId
			? containerChildrenLoader({
					organizationKey,
					platformCampusKey,
					applicationId,
					containerId: selectedContainerId,
			  })
			: null;
	};

	const onSelectButtonHandler = async () => {
		await prefetchContainerChildren();

		if (selectedContainer) {
			onSelectButton(selectedContainer);
		}

		dispatch({ type: DestinationModalActions.ResetState });
	};

	const onCloseButtonHandler = () => {
		dispatch({ type: DestinationModalActions.ResetState });
		onCloseButton();
	};

	const scrollListContainerId = currentContainerId ?? currentContainerRef.current?.container?.id ?? 'RootContainer';

	const onOpenChildrenHandler = (container: SelectedContainer) => {
		dispatch({ type: DestinationModalActions.ViewContainer, container });

		scrollList.current[scrollListContainerId] = scrollRef.current?.scrollTop ?? 0;
		scrollRef.current?.scrollTo({ top: 0 });
	};

	const onGoBackHandler = () => {
		scrollRef.current?.scrollTo({ top: 0 });
		scrollList.current[scrollListContainerId] = 0;

		const currentContainer = currentContainerRef.current?.container;
		const isContainerChildren = (container: any): container is ContainerChildren =>
			container && 'parentContainer' in container;

		if (isRootContainer || !isContainerChildren(currentContainer) || !currentContainer?.parentContainer) return;

		const { parentContainer } = currentContainer;

		const sourceHasEventsContainers = currentContainer.children.nodes.some(
			child =>
				child.__typename === 'ChildContainer' &&
				child.container.type === ContainerType.Events && {
					id: child?.container.id,
					eventsContainerChildren: true,
				}
		) as unknown as EventsContainerChildrenType[];

		dispatch({
			type: DestinationModalActions.ViewContainer,
			container: {
				id: parentContainer.id,
				name: parentContainer.name,
				isRootContainer: parentContainer.parentContainer === null,
				isFeedContainer: !!parentContainer.feed?.id,
				isEventsContainer: currentContainer?.type === ContainerType.Events,
				eventsContainerChildren: sourceHasEventsContainers,
			},
		});
	};

	const onSelectHandler = (container: SelectedContainer) => {
		if (container.id === selectedContainer?.id) {
			const currentContainer = currentContainerRef.current?.container;

			dispatch({
				type: DestinationModalActions.SelectContainer,
				container: currentContainer
					? {
							id: currentContainer.id,
							name: currentContainer.name,
							isRootContainer: !!currentContainerRef.current?.isRootContainer,
					  }
					: null,
			});
		} else {
			dispatch({ type: DestinationModalActions.SelectContainer, container });
		}
	};

	const isSaveButtonDisabled =
		// selectedContainer is null when trying to move items/containers into the same container
		!selectedContainer ||
		// disable button when trying to move items or some restricted containers to the root container
		(selectedContainer.isRootContainer && hasRestrictedRootTypes) ||
		// disable button when trying to move to original container
		selectedContainer.id === initialContainerId ||
		// disable button when targeted container is a feed container
		selectedContainer.isFeedContainer;

	const footer = () => {
		const saveButton = (
			<Button
				className={classes.selectButton}
				data-pp-at-target={`${targetId}-${actionType}-button`}
				disabled={isSaveButtonDisabled}
				displayStyle="primary"
				type="button"
				onClick={onSelectButtonHandler}
			>
				{translate(`destinationModal.${actionType}.save`)}
			</Button>
		);
		const shouldShowRestrictedTooltip = isRootContainer && isSaveButtonDisabled && hasRestrictedRootTypes;

		return (
			<Inline>
				<Button
					data-pp-at-target={`${targetId}-cancel-button`}
					displaySize="large"
					displayStyle="text"
					type="button"
					onClick={onCloseButtonHandler}
				>
					{translate('destinationModal.cancel')}
				</Button>
				<Tooltip
					classes={{ root: classes.tooltip }}
					content={translate(`destinationModal.${actionType}.restrictedTooltip`)}
					disabled={!shouldShowRestrictedTooltip}
					placement="top"
				>
					{saveButton}
				</Tooltip>
			</Inline>
		);
	};

	const selectionMessage = translate(
		totalSelection > 1 ? 'destinationModal.selections' : 'destinationModal.selection',
		{
			totalSelection,
		}
	);

	const onTriggerScrollHandler = useCallback(() => {
		if (currentContainerId && scrollRef?.current && scrollList.current[currentContainerId] !== undefined) {
			scrollRef.current?.scrollTo({ top: scrollList.current[currentContainerId] });
		}
	}, [currentContainerId]);

	const navigationProps = {
		...componentProps,
		applicationId,
		organizationKey,
		platformCampusKey,
		selectedContainer,
		setCurrentContainer,
		isInitialContainer: currentContainerId === initialContainerId,
		onNavigate: onOpenChildrenHandler,
		onSelect: onSelectHandler,
		onTriggerScroll: onTriggerScrollHandler,
	};

	const containerName = selectedContainer?.name ? getTruncatedText(selectedContainer.name, 60) : '';

	return (
		<Modal
			classes={{ modal: classes.modal, closeModalButton: classes.closeModalButton }}
			closeButtonLabel={modalTranslate('modal.close')}
			data-pp-at-target={targetId}
			isOpen={isModalOpen}
			returnFocus={false}
			onClickCloseButton={onCloseButtonHandler}
		>
			<Card
				classes={{ body: classes.cardBody, root: classes.cardRoot, footer: classes.footer }}
				renderFooter={footer}
			>
				<Stack>
					<Heading classes={{ root: classes.heading }} level="2">
						{translate(`destinationModal.${actionType}.title`)}
					</Heading>
					<div className={classes.searchChildren}>
						<div className={classes.subtitle}>
							{selectedContainer ? (
								<Trans
									i18nKey={`destinationModal.${actionType}.selectedText`}
									namespace="appDesign"
									values={{ selectionMessage, destinationContainer: containerName }}
								>
									You are moving <strong>{selectionMessage}</strong> to{' '}
									<strong>{containerName}</strong> container
								</Trans>
							) : (
								<Trans
									i18nKey={`destinationModal.${actionType}.unselectedText`}
									namespace="appDesign"
									values={{ selectionMessage }}
								>
									Select a container as the destination to copy <strong>{selectionMessage}</strong>
								</Trans>
							)}
						</div>
					</div>
					<div ref={scrollRef} className={classes.childrenList}>
						{!isRootContainer ? (
							<ChildrenNavigation
								currentContainerId={currentContainerId ?? initialContainerId}
								onGoBack={onGoBackHandler}
								{...navigationProps}
							/>
						) : (
							<RootNavigation {...navigationProps} />
						)}
					</div>
				</Stack>
			</Card>
		</Modal>
	);
};
