import { ComponentProps } from 'react';

import moment from 'moment';

import EventsScreen from '@pushpay/app-components/dist/app/screens/Events/EventsScreen.legacy';

import { useAppPreviewContext, useGlobalContext } from '@src/context';
import {
	ChildContainerFragment,
	ChildContainerWithChildrenFragment,
	ChildItemFragment,
	useGetContainerChildrenWithNestedChildrenQuery,
} from '@src/graphql/generated';
import { CONTAINER_CHILDREN_PAGING_SIZE } from '@src/pages/constants';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { ContainerPreviewData } from '../types';
import { getImageMap, groupBy, noop, orderBy } from '../utils';

type ListItemData = ComponentProps<typeof EventsScreen>['data'];
type Events = ListItemData[string];

export function EventListLegacy({ container, template }: { container: ContainerPreviewData; template: string }) {
	const { appSettings, screenWidth } = useAppPreviewContext();
	const { campus: currentCampusId } = useGlobalContext();
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { data: containerWithNestedChildren } = useGetContainerChildrenWithNestedChildrenQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId: container.id,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
	});

	if (!containerWithNestedChildren?.organization?.application?.container) {
		return null;
	}
	const containerImageMap = getImageMap(container.images);
	const eventsData = getEventsData(
		{
			...container,
			children: containerWithNestedChildren.organization.application.container.children.nodes,
		},
		currentCampusId
	);
	const headerImage = containerImageMap?.lmw ?? null;

	return (
		<EventsScreen
			data={eventsData}
			fetchItemIfNeeded={noop}
			headerImage={headerImage}
			items={[] as any}
			screenWidth={screenWidth}
			settings={appSettings}
			template={template}
			onPressShare={noop}
			onRowPress={noop}
		/>
	);
}

function getEventsData(container: ContainerPreviewData, campusId: string | null): ListItemData {
	const { children } = container;
	if (!children) return {};

	const timestampNow = Math.floor(Date.now() / 1000);

	const eventsList = children
		.filter(child => !child.hidden)
		.reduce<Events>((acc, child) => {
			if (child.__typename === 'ChildItem') {
				return acc.concat(getEventsForItem(container.id, child, timestampNow));
			}

			if (isChildContainerWithChildren(child)) {
				if (child.container.campus && child.container.campus?.id !== campusId) {
					return acc;
				}
				const nestedChildrenEvents = child.container.children.nodes
					.filter(nestedChild => !nestedChild.hidden)
					.filter((nestedChild): nestedChild is ChildItemFragment => nestedChild.__typename === 'ChildItem')
					.map(x => getEventsForItem(child.container.id, x, timestampNow));
				return acc.concat(nestedChildrenEvents.flat());
			}

			return acc;
		}, []);

	const orderedEvents = orderBy(eventsList, ['start_time', 'name'], ['asc', 'desc']);

	const groupedEvents = groupBy(orderedEvents, event => {
		const section = {
			time: null,
			month: null,
		};

		return groupEventsByMonth(event, section);
	});

	return groupedEvents;
}

function isChildContainerWithChildren(
	value: ChildItemFragment | ChildContainerFragment | ChildContainerWithChildrenFragment
): value is ChildContainerWithChildrenFragment {
	return value.__typename === 'ChildContainer' && 'children' in value.container;
}

function getEventsForItem(parentId: string, childItem: ChildItemFragment, timestampNow: number): Events {
	const isStartTimeInFuture = (startTime: number, endTime: number | null) =>
		startTime > timestampNow || (endTime && endTime > timestampNow);

	const { id, icon, name } = childItem;

	return childItem.item.properties.reduce<Events>((acc, property) => {
		if (property.__typename !== 'TimeframeProperty' || !property.timeframe.startTime) {
			return acc;
		}

		const { startTime, endTime, allDay } = property.timeframe;

		const subtitle = allDay ? `${startTime.format('dddd')}, All day event` : startTime.format('dddd h:mm A');

		const isAllDayAndToday = allDay && moment.utc().isSame(moment.utc(startTime), 'd');

		const startTimeRounded = Math.floor(startTime.valueOf() / 1000);
		const endTimeRounded = endTime ? Math.floor(endTime.valueOf() / 1000) : null;

		if (isAllDayAndToday || isStartTimeInFuture(startTimeRounded, endTimeRounded)) {
			acc.push({
				uuid: id,
				start_time: startTimeRounded,
				end_time: endTimeRounded,
				all_day: allDay ? 1 : 0,
				parent: parentId,
				name,
				icon: icon ?? null,
				template: 'detail-plain',
				images: null,
				subtitle,
				timeframe: {
					start_time: startTime.valueOf(),
					end_time: endTime?.valueOf(),
				},
				type: 'item',
			});
		}

		return acc;
	}, []);
}

function groupEventsByMonth(
	event: Events[number],
	section: {
		time: number | null;
		month: number | null;
	}
) {
	const monthOfEvent = moment.unix(event.start_time).month();
	if (!section.time || monthOfEvent !== section.month) {
		// eslint-disable-next-line no-param-reassign
		section.time = event.start_time;
		// eslint-disable-next-line no-param-reassign
		section.month = monthOfEvent;
	}

	return `s${section.time}`;
}
