import { useId } from 'react';

import { Field, FormState } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { UserNotePropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, TextFieldWrapper } from '../wrappers';

export type UserNoteProps = ComponentProps<
	{
		propertyField: FormState<UserNotePropertyInput>;
	},
	typeof undefined
>;

export const UserNote = ({ propertyField, 'data-pp-at-target': targetId }: UserNoteProps) => {
	const { translate } = useTranslation('appDesign');

	const { hintText } = propertyField;

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={translate('userNote.label')}
				data-pp-at-target={`${targetId}-user-note-hint-text`}
				field={hintText as Field<string>}
				id={useId()}
				label={translate('userNote.label')}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
