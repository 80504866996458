import { setContext } from '@apollo/client/link/context';

import { testWidgetManager } from '@src/components/testWidget';

type TestWidgetHeaders = Partial<Record<'X-PP-Testing-Unavailable' | 'X-PP-Testing-Delay' | 'X-PP-Feature', string>>;

export function createTestWidgetLink() {
	return setContext(async (_request, { headers }) => {
		const {
			featureFlagOverrides,
			serviceAvailability: { delay, ...services },
		} = testWidgetManager.settings;

		const flagOverrides = Object.entries(featureFlagOverrides)
			.map(([key, value]) => `${key}:${value}`)
			.join(';');

		const unavailableServices = Object.entries(services)
			.filter(([, available]) => !available)
			.map(([serviceName]) => serviceName)
			.join(';');

		const testWidgetHeaders: TestWidgetHeaders = {
			...(flagOverrides && { 'X-PP-Feature': flagOverrides }),
			...(delay && { 'X-PP-Testing-Delay': Math.max(0, delay).toString() }),
			...(unavailableServices && { 'X-PP-Testing-Unavailable': unavailableServices }),
		};

		return {
			headers: {
				...headers,
				...testWidgetHeaders,
			},
		};
	});
}
