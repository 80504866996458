import moment from 'moment-timezone';

import { SaveButtonProps } from '@pushpay/button';

import { AddressArgumentsInput, GetProfileQuery, useUpdateProfileForm } from '@src/graphql/generated';
import { useParams } from '@src/router';

import { notEmpty } from './utils';

export function useEditProfileForm(profile: NonNullable<GetProfileQuery['me']>) {
	const { organizationKey } = useParams<'organizationKey'>();
	const {
		identityKey,
		firstName,
		lastName,
		address: addressWithTypename,
		emailAddresses,
		clientIpAddress,
		birthday,
		olsonTimeZone,
	} = profile;
	const { __typename, ...address } = addressWithTypename ?? {};
	const { successResult, saving, ...updateProfileFormProps } = useUpdateProfileForm({
		initialData: {
			organizationKey,
			input: {
				commandId: `${identityKey}_${moment.utc().toDate().getTime()}`,
				identityKey,
				firstName,
				lastName,
				address: address as AddressArgumentsInput, // inconsistent string to enum GQL typings for country and countryCode
				emailAddresses: ['', ...emailAddresses.map(email => email?.emailAddress).filter(notEmpty)],
				userContext: {
					ipAddress: clientIpAddress?.ipV4 ?? '',
					olsonTimeZone: moment.tz.guess(), // user the browsers timezone to set the user context not the user's profile
					browser: {
						userAgent: window.navigator.userAgent,
					},
				},
				birthday,
				timeZone: olsonTimeZone,
			},
		},
	});

	// eslint-disable-next-line no-nested-ternary
	const saveButtonState: SaveButtonProps['state'] = successResult ? 'success' : saving ? 'saving' : 'idle';

	return {
		saveButtonState,
		saving,
		...updateProfileFormProps,
	};
}
