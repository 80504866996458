import { Moment } from 'moment';

import { Maybe } from '@src/graphql/generated';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const formatIsoDate = (value: Moment) => value.format(ISO_DATE_FORMAT);

export const getFormattedDate = (date: Maybe<Moment> | undefined) => {
	const dateString = date ? date.format('dddd, MMMM D, YYYY') : '';
	const timeString = date ? date.format('h:mm A') : '';

	return [dateString, timeString];
};

export function getTruncatedText(text: string, maxLength: number) {
	if (text.length > maxLength - 1) {
		const breakIndex = text.lastIndexOf(' ', maxLength - 1);
		return `${text.substring(0, breakIndex)}\u2026`;
	}
	return text;
}

/**
 * Replace underscores with dots and convert to lowercase
 * @example
 * // returns abc.def
 * underscoreToDot('ABC_DEF');
 */
export function underscoreToDot(str: string) {
	return str.replace(/_/g, '.').toLowerCase();
}
