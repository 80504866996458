import { useCallback, useMemo } from 'react';

import { useGlobalContext } from '@src/context/globalContext';
import { FeatureKey, Feature, MyOrganizationQuery, ShellCapabilityKey, ShellCapability } from '@src/graphql/generated';

import { useMyOrganization } from '.';

type Application = NonNullable<NonNullable<MyOrganizationQuery>['organization']>['applications'][number];

export function useMyApp() {
	const { applications } = useMyOrganization();
	const { application: applicationId } = useGlobalContext();

	const currentApp = useMemo(
		() => applications.find(app => app.id === applicationId),
		[applications, applicationId]
	) as Application;

	const dhs = useMemo(
		() => ({
			enabled: currentApp.dynamicHomeScreen?.isEnabled ?? false,
			published: currentApp.dynamicHomeScreen?.publish ?? false,
		}),
		[currentApp]
	);

	const getAppFeature = useCallback(
		(featureKey: FeatureKey): Feature | undefined =>
			currentApp.features.find(appFeature => appFeature.key === featureKey),
		[currentApp]
	);

	const getAppShellCapability = useCallback(
		(shellCapabilityKey: ShellCapabilityKey): ShellCapability | undefined =>
			currentApp.shellCapabilities.find(sc => sc.key === shellCapabilityKey),
		[currentApp]
	);

	return {
		currentApp,
		dhs,
		getAppFeature,
		getAppShellCapability,
	};
}
