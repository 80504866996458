import { ReactNode } from 'react';

import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentPropsWithoutPpAtTarget } from '@pushpay/types';

const useStyles = createUseStyles((theme: Theme) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		font: theme.typography['text-4'],
		height: theme.SPACING.MEDIUM,
		marginBottom: theme.SPACING.XSMALL,

		'& > :not(:first-child)': {
			marginLeft: theme.SPACING.XXSMALL,
		},
	},
	content: {
		font: theme.typography['text-5'],
	},
}));

export type LabelWrapperProps = ComponentPropsWithoutPpAtTarget<
	{
		label?: ReactNode;
	},
	typeof useStyles
>;

export const LabelWrapper = ({ label, classes: classesProp, children }: LabelWrapperProps) => {
	const classes = useStyles(classesProp);

	return (
		<div className={classes.wrapper}>
			{label !== undefined ? <div className={classes.label}>{label}</div> : null}
			<div className={classes.content}>{children}</div>
		</div>
	);
};
