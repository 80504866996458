import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	card: {
		border: `1px solid ${theme.colors['grey-400']}`,
		borderRadius: '6px',
		backgroundColor: theme.colors['background-surface'],
		cursor: 'grabbing',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '54px',
		padding: multiply(theme.SPACING.XSMALL, 1.5),
	},
	title: {
		font: theme.typography['heading-4'],
		color: theme.colors['grey-1100'],
		paddingLeft: theme.SPACING.XXSMALL,
		maxWidth: 'calc(100% - 108px)',
	},
	headerGroup: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginRight: theme.SPACING.XSMALL,
		},
	},
	dragHandle: {
		color: theme.colors['grey-400'],
		marginLeft: multiply(theme.SPACING.XSMALL, -1.5),
		flex: 'none',
	},
	icon: {
		color: theme.colors['grey-900'],
		fontSize: '20px',
		flex: 'none',
	},
	label: {
		color: theme.colors['text-heading'],
		font: theme.typography['heading-4'],
	},
}));
