import { isVimeo, isYouTube, CONSTANTS } from '@src/pages/itemSettings/utils';

import { PropertyInputType, PropertyInputValue, VideoProperty } from '../types';

const { YOUTUBE_HEADER, VIMEO_HEADER } = CONSTANTS;
export const getPropertyHeader = <T extends PropertyInputType>(type: T, property: PropertyInputValue<T>) => {
	const { baseProperty } = property;
	const header = baseProperty.header?.value ?? '';

	if (type === 'videoPropertyInput') {
		const {
			url: { value: url },
		} = property as VideoProperty;

		if (isYouTube(url || '')) {
			return YOUTUBE_HEADER;
		}

		if (isVimeo(url || '')) {
			return VIMEO_HEADER;
		}

		return header;
	}

	return header;
};
