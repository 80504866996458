import { DragOverlay } from '@dnd-kit/core';

import { ComponentProps } from '@pushpay/types';

import { PropertyHeaderProps, PropertyHeader } from '@src/components/properties';
import { isDraggableType, isPropertyType } from '@src/pages/appDesign/helper';

import { Card, CardProps } from '../card';
import { DraggableComponent } from './DraggableComponent';
import { Draggable } from './types';

export type DraggableOverlayProps = ComponentProps<
	{ draggingItem: Draggable | CardProps | PropertyHeaderProps | null },
	undefined
>;

const DraggableContent = ({ draggingItem }: DraggableOverlayProps) => {
	if (!draggingItem) return null;

	if (isDraggableType(draggingItem)) {
		return (
			<DraggableComponent
				data-pp-at-target="draggable-overlay"
				icon={draggingItem.icon}
				label={draggingItem.label}
				type={draggingItem.type}
				dragOverlay
			/>
		);
	}

	if (isPropertyType(draggingItem)) {
		return <PropertyHeader {...(draggingItem as PropertyHeaderProps)} />;
	}

	return <Card {...(draggingItem as CardProps)} data-pp-at-target="drag-overlay" dragOverlay />;
};

export const DraggableOverlay = ({ draggingItem }: DraggableOverlayProps) => (
	<DragOverlay>
		<DraggableContent draggingItem={draggingItem} />
	</DragOverlay>
);
