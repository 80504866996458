import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
		color: theme.colors['text-default'],
	},
	textFieldWrapper: {
		margin: 0,
	},
	tile: {
		paddingTop: theme.SPACING.XSMALL,
	},
	expandableCardTitle: {
		font: theme.typography['heading-3'],
	},
	expandableCardBody: {
		paddingTop: 0,
	},
	generalStack: {
		marginBottom: theme.SPACING.SMALL,
	},
	section: {
		paddingTop: theme.SPACING.SMALL,
	},
	floatingBar: {
		padding: `${theme.SPACING.SMALL} ${theme.SPACING.MEDIUM}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '900px',
		backgroundColor: theme.colors['background-surface'],
	},
	form: {
		paddingBottom: '100px',
		isolation: 'isolate',
	},
	stackItem: {
		paddingTop: theme.SPACING.SMALL,
	},
	subTitleRoot: {
		marginTop: theme.SPACING.XSMALL,
	},
}));
