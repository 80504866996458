import ArrowClockwise from '@assets/icons/arrow-clockwise.svg';
import ArrowCounterclockwise from '@assets/icons/arrow-counterclockwise.svg';
import Quote from '@assets/icons/chat-square-quote.svg';
import HorizontalRule from '@assets/icons/horizontal-rule.svg';
import Indent from '@assets/icons/indent.svg';
import Link from '@assets/icons/link.svg';
import NumberedList from '@assets/icons/list-ol.svg';
import BulletList from '@assets/icons/list-ul.svg';
import Outdent from '@assets/icons/outdent.svg';
import Paragraph from '@assets/icons/text-paragraph.svg';
import Bold from '@assets/icons/type-bold.svg';
import H1 from '@assets/icons/type-h1.svg';
import H2 from '@assets/icons/type-h2.svg';
import H3 from '@assets/icons/type-h3.svg';
import Italic from '@assets/icons/type-italic.svg';
import Strikethrough from '@assets/icons/type-strikethrough.svg';
import Underline from '@assets/icons/type-underline.svg';

import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	toolbar: {
		display: 'flex',
		borderBottom: `1px solid ${theme.colors['grey-400']}`,
		padding: '4px',
		backgroundColor: theme.colors['common-white'],
		verticalAlign: 'middle',
		overflow: 'auto',
		height: '36px',
		top: 0,
		zIndex: 2,
	},
	toolbarItem: {
		border: 0,
		display: 'flex',
		borderRadius: '10px',
		padding: '8px',
		cursor: 'pointer',
		verticalAlign: 'middle',
		flexShrink: 0,
		alignItems: 'center',
		justifyContent: 'space-between',

		'&:disabled': {
			cursor: 'not-allowed',
			'& i': {
				opacity: '0.2',
			},
		},
	},
	toolbarItemInactive: {
		background: 'none',
	},
	toolbarItemActive: {
		backgroundColor: theme.colors['grey-200'],
		'& i': {
			opacity: 1,
		},
	},
	blockType: {
		backgroundSize: 'contain',
		display: 'block',
		width: '18px',
		height: '18px',
		margin: '2px',
	},
	spaced: {
		marginRight: '2px',
	},
	format: {
		backgroundSize: 'contain',
		height: '18px',
		width: '18px',
		verticalAlign: '-0.25em',
		display: 'flex',
		opacity: '0.6',
	},
	item: {
		margin: '0 8px 0 8px',
		padding: '8px',
		color: theme.colors['text-heading'],
		cursor: 'pointer',
		lineHeight: '16px',
		fontSize: theme.typography.baseSize,
		display: 'flex',
		alignContent: 'center',
		flexDirection: 'row',
		flexShrink: 0,
		justifyContent: 'space-between',
		backgroundColor: theme.colors['common-white'],
		borderRadius: '8px',
		border: 0,
		maxWidth: '250px',
		minWidth: '100px',

		'&:first-child': {
			marginTop: '8px',
		},
		'&:last-child': {
			marginBottom: '8px',
		},
		'&:hover': {
			backgroundColor: theme.colors['grey-200'],
		},
		'&:focus-visible': {
			outline: `1px auto ${theme.colors['blue-700']}`,
		},
	},
	divider: {
		width: '1px',
		backgroundColor: theme.colors['grey-200'],
		margin: '0 4px',
	},
	text: {
		display: 'flex',
		lineHeight: '20px',
		flexGrow: 1,
		minWidth: '150px',
	},
	dropDownActive: {
		backgroundColor: theme.colors['blue-100'],
	},
	dropdownItemActive: {
		backgroundColor: theme.colors['blue-100'],

		'& > i': {
			opacity: 1,
		},
	},
	icon: {
		display: 'flex',
		width: '20px',
		height: '20px',
		userSelect: 'none',
		marginRight: '12px',
		lineHeight: '16px',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
	},
	blankifyIcon: {
		fontSize: '17px',
		fontWeight: theme.typography.font.weight.semiBold,
	},

	undo: {
		backgroundImage: `url(${ArrowCounterclockwise})`,
	},
	redo: {
		backgroundImage: `url(${ArrowClockwise})`,
	},
	bold: {
		backgroundImage: `url(${Bold})`,
	},
	italic: {
		backgroundImage: `url(${Italic})`,
	},
	underline: {
		backgroundImage: `url(${Underline})`,
	},
	link: {
		backgroundImage: `url(${Link})`,
	},
	strikethrough: {
		backgroundImage: `url(${Strikethrough})`,
	},
	horizontalRule: {
		backgroundImage: `url(${HorizontalRule})`,
	},
	paragraph: {
		backgroundImage: `url(${Paragraph})`,
	},
	h1: {
		backgroundImage: `url(${H1})`,
	},
	h2: {
		backgroundImage: `url(${H2})`,
	},
	h3: {
		backgroundImage: `url(${H3})`,
	},
	bullet: {
		backgroundImage: `url(${BulletList})`,
	},
	number: {
		backgroundImage: `url(${NumberedList})`,
	},
	quote: {
		backgroundImage: `url(${Quote})`,
	},
	indent: {
		backgroundImage: `url(${Indent})`,
	},
	outdent: {
		backgroundImage: `url(${Outdent})`,
	},
}));
