import { useCallback } from 'react';

import { CampusSelectorIcon } from '@pushpay/iconography';
import { ComponentProps } from '@pushpay/types';

import { Selector } from '@src/components/selectors/Selector';
import { useGlobalContext } from '@src/context/globalContext';
import { useMyApp } from '@src/myContext';

import { useStyles } from './selectorStyles';

export function useCampusOptions() {
	const {
		currentApp: { campuses },
	} = useMyApp();
	return (
		campuses.map(campus => ({
			display: campus.name,
			value: campus.id,
		})) || []
	);
}

export const CampusSelector = ({
	'data-pp-at-target': targetId,
	classes: classesProp,
}: ComponentProps<unknown, typeof useStyles>) => {
	const { application: currentApplication, campus: currentCampus, updateGlobalContext } = useGlobalContext();
	const classes = useStyles(classesProp);
	const options = useCampusOptions();

	const onChange = useCallback(
		(campusId: string | null) => {
			updateGlobalContext({ application: currentApplication, campus: campusId });
		},
		[currentApplication, updateGlobalContext]
	);

	if (options.length === 0) {
		return null;
	}

	return (
		<Selector
			classes={classes}
			data-pp-at-target={targetId}
			dropdownAlignment="left"
			getOptionKey={option => option.value || option.display}
			icon={CampusSelectorIcon}
			options={options}
			showSearch={options.length > 10}
			sortOrder="asc"
			value={currentCampus}
			onChange={onChange}
		/>
	);
};
