import { createUseStyles } from '@pushpay/styles';

export const useStyles = createUseStyles({
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'transparent',
		zIndex: 9999,
	},
});

export type InteractionBlockerProps = {
	isActive: boolean;
};

export const InteractionBlocker = ({ isActive }: InteractionBlockerProps) => {
	const classes = useStyles(undefined);

	return isActive ? <div className={classes.overlay} /> : <></>;
};
