import { useCallback, useEffect, useRef, useState, useMemo } from 'react';

import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { horizontalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';

import { useDynamicFieldsMutation } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { CarouselImagesField, CarouselFile } from '@src/components/carouselUploader/types';
import { Thumbnail } from '@src/components/imageUploader/components';
import { SortableContext } from '@src/context';
import { useTranslation } from '@src/i18n';

const THUMBNAIL_SIZE = 576;

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		paddingTop: '1px',
		marginBottom: theme.SPACING.SMALL,
		overflowX: 'auto',
		overflowY: 'hidden',
		scrollBehavior: 'smooth',

		'& > :not(:last-child)': {
			borderRight: `1px solid ${theme.colors['common-white']}`,
		},
	},
	label: {
		color: theme.colors['text-default'],
		fontWeight: theme.typography.font.weight.semiBold,
		lineHeight: theme.SPACING.FIELD_HEIGHT,
	},
}));

type CarouselPreviewProps = {
	imagesFields: CarouselImagesField[];
	carouselImages: CarouselFile[];
};

export const CarouselPreview = ({ carouselImages, imagesFields }: CarouselPreviewProps) => {
	const classes = useStyles(undefined);
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
	const { translate } = useTranslation('appDesign');

	const containerRef = useRef<HTMLDivElement>(null);
	const imageCountRef = useRef<number | null>(null);

	const { removeItem, replaceItems } = useDynamicFieldsMutation();

	const reorderItems = arrayMove;
	const fields = useMemo(
		() =>
			imagesFields.map((imagesField: any) => ({
				id: imagesField?.existingImageInput?.id || imagesField?.newImageInput?.id,
			})),
		[imagesFields]
	);
	const getIndex = (id: UniqueIdentifier) => fields.findIndex(field => field.id?.value === id);
	const activeIndex = activeId ? getIndex(activeId) : -1;

	useEffect(() => {
		if (!imageCountRef.current) {
			imageCountRef.current = imagesFields.length;
			return;
		}

		if (imagesFields.length > imageCountRef.current) {
			const container = containerRef.current;
			if (container) {
				container.scrollLeft = container.scrollWidth;
			}
		}

		imageCountRef.current = imagesFields.length;
	}, [imagesFields]);

	const onClickRemove = useCallback(
		(fieldIndex: number) => {
			removeItem(fieldIndex);
		},
		[removeItem]
	);

	return (
		<DndContext
			onDragCancel={() => setActiveId(null)}
			onDragEnd={({ over }) => {
				setActiveId(null);

				if (over) {
					const overIndex = getIndex(over.id);
					if (activeIndex !== overIndex) {
						const reorderedImagesFields = reorderItems(imagesFields, activeIndex, overIndex);
						const imagesToReplace = reorderedImagesFields.map((imagesField: any) => {
							if (imagesField?.existingImageInput) {
								return {
									existingImageInput: {
										id: imagesField?.existingImageInput?.id?.value,
									},
								};
							}

							return {
								newImageInput: {
									id: imagesField?.newImageInput?.id?.value,
									unprocessedImageUrl: imagesField?.newImageInput?.unprocessedImageUrl?.value,
								},
							};
						});
						replaceItems(imagesToReplace);
					}
				}
			}}
			onDragStart={({ active }) => {
				if (!active) {
					return;
				}
				setActiveId(active.id);
			}}
		>
			<SortableContext
				items={fields.map(field => ({
					id: field.id?.value,
				}))}
				strategy={horizontalListSortingStrategy}
			>
				<div ref={containerRef} className={classes.container}>
					{carouselImages
						.filter(carouselImage => fields.find(field => field.id?.value === carouselImage.id))
						.map((carouselImage, index) => (
							<Thumbnail
								key={carouselImage.id}
								image={carouselImage}
								recommendedHeight={THUMBNAIL_SIZE}
								recommendedWidth={THUMBNAIL_SIZE}
								showTitle={false}
								title={translate('imageUploader.preview.square')}
								tooltipContent={translate('imageUploader.preview.squareTooltip')}
								type="square"
								onClickRemove={() => onClickRemove(index)}
							/>
						))}
				</div>
			</SortableContext>
		</DndContext>
	);
};
