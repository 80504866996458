import { useCallback, useMemo, useEffect } from 'react';

import { MyOrganizationQuery, MeQuery, CatholicEntity } from '@src/graphql/generated';
import { useMyIdentity, useMyApp, useMyOrganization } from '@src/myContext';
import { getEnumKeyByValue } from '@src/utils';

import { PendoInitializeArgs, PendoUpdateArgs } from './types';
import { useValidatePendoGuide, ValidatePendoGuide } from './useValidatePendoGuide';

declare const pendo: {
	initialize(args: PendoInitializeArgs): void;
	events: {
		validateGuide(validateGuide: ValidatePendoGuide): void;
	};
	updateOptions(args: PendoUpdateArgs): void;
	track(eventName: string, args: Record<string, string | number | null>): void;
};

export function usePendo() {
	const { validatePendoGuide } = useValidatePendoGuide();
	const myIdentity = useMyIdentity();

	const pendoEvents = useMemo(
		() => ({
			events: {
				ready: () => pendo.events.validateGuide(validatePendoGuide),
			},
		}),
		[validatePendoGuide]
	);

	const pendoVisitorMetadata = useCallback(
		(
			identity: NonNullable<MeQuery['me']>,
			application?: NonNullable<MyOrganizationQuery['organization']>['applications'][number]
		) => {
			const { identityKey, primaryEmailAddress, lastName, firstName } = identity;

			return {
				id: identityKey,
				email: primaryEmailAddress?.emailAddress ?? '',
				firstName: firstName ?? '',
				lastName: lastName ?? '',
				appName: application?.name,
				appId: application?.id,
				productVersion: application?.productVersion,
				productType: application?.productType,
			};
		},
		[]
	);

	const pendoAccountMetadata = useCallback((organization: NonNullable<MyOrganizationQuery['organization']>) => {
		const { key, details } = organization;
		const catholicEntity = getEnumKeyByValue(CatholicEntity, details?.catholicEntity ?? CatholicEntity.Unknown);

		return {
			id: details?.ecgId ?? '',
			name: details?.name ?? '',
			// this is named organizationId for backwards compatibility
			// with Platform and MAS
			organizationId: key,
			country: details?.shippingAddress?.countryCode ?? '',
			catholicEntity,
		};
	}, []);

	const initializePendo = useCallback(
		(identity: NonNullable<MeQuery['me']>) => {
			pendo.initialize({
				visitor: pendoVisitorMetadata(identity),
				...pendoEvents,
			});
		},
		[pendoVisitorMetadata, pendoEvents]
	);

	const useUpdatePendo = () => {
		const currentOrganization = useMyOrganization();
		const { currentApp } = useMyApp();

		useEffect(() => {
			if (currentOrganization && currentApp) {
				pendo.updateOptions({
					visitor: pendoVisitorMetadata(myIdentity, currentApp),
					account: pendoAccountMetadata(currentOrganization),
					...pendoEvents,
				});
			}
		}, [currentOrganization, currentApp]);
	};

	const pendoTrackEvent = useCallback((eventName: string, eventData: Record<string, string | number | null>) => {
		pendo.track(eventName, eventData);
	}, []);

	return {
		initializePendo,
		useUpdatePendo,
		pendoTrackEvent,
	};
}
