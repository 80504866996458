import { useReducer } from 'react';

import { SelectedContainer } from './types';

type DestinationModalState = {
	initialValues: {
		initialContainerId: string;
		isRootContainer: boolean;
		hasRestrictedRootTypes?: boolean;
	};
	currentContainerId: string;
	isRootContainer: boolean;
	selectedContainer: SelectedContainer | null;
};

export const DestinationModalActions = {
	ResetState: 'reset_state',
	ViewContainer: 'view_container',
	SelectContainer: 'select_container',
} as const;

type DestinationModalActions = typeof DestinationModalActions;

type DestinationModalAction =
	| {
			type: DestinationModalActions['ViewContainer'];
			container: SelectedContainer;
	  }
	| {
			type: DestinationModalActions['SelectContainer'];
			container: SelectedContainer | null;
	  }
	| {
			type: DestinationModalActions['ResetState'];
	  };

function destinationModalReducer(state: DestinationModalState, action: DestinationModalAction): DestinationModalState {
	const validateSelectedContainer = (selectedContainer: SelectedContainer | null) => {
		if (selectedContainer === null) return null;

		const canMove = !selectedContainer.isRootContainer || !state.initialValues.hasRestrictedRootTypes;
		const isInitialContainer = selectedContainer.id === state.initialValues.initialContainerId;

		return canMove && !isInitialContainer ? selectedContainer : null;
	};

	switch (action.type) {
		case DestinationModalActions.ViewContainer:
			return {
				...state,
				currentContainerId: action.container.id,
				selectedContainer: validateSelectedContainer(action.container),
				isRootContainer: action.container.isRootContainer,
			};

		case DestinationModalActions.SelectContainer:
			return {
				...state,
				selectedContainer: validateSelectedContainer(action.container),
			};

		case DestinationModalActions.ResetState:
			return {
				...state,
				currentContainerId: state.initialValues.initialContainerId,
				isRootContainer: state.initialValues.isRootContainer,
				selectedContainer: null,
			};

		default:
			return state;
	}
}

export function useDestinationModalReducer({
	initialContainerId,
	hasRestrictedRootTypes,
	isRootContainer,
}: DestinationModalState['initialValues']) {
	return useReducer(destinationModalReducer, {
		initialValues: {
			initialContainerId,
			isRootContainer,
			hasRestrictedRootTypes,
		},
		currentContainerId: initialContainerId,
		isRootContainer,
		selectedContainer: null,
	});
}
