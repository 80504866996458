import { useMemo } from 'react';

import { SelectField } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { RssFeedType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { getFeedInitialData } from '@src/pages/containerSettings/utils/containerSettingFormInitialDataGenerator';

import { ContainerFeed, RssFeedInputFields } from '../types';
import { FeedCommonFields, FeedCommonFieldsProps } from './FeedCommonFields';

export type RssFeedProps = ComponentProps<
	{
		feed: ContainerFeed;
		rssFeedFields: RssFeedInputFields;
	} & Omit<FeedCommonFieldsProps, 'urlField' | 'propertiesField' | 'feedProcessState'>,
	typeof undefined
>;

export const RssFeedFields = ({ rssFeedFields, feed, processFeed, enableDownloadButton }: RssFeedProps) => {
	const { translate } = useTranslation('appDesign');

	const { rssFeed: originalValue } = getFeedInitialData(feed);
	const isDirty =
		originalValue &&
		(rssFeedFields.url.value !== originalValue.url ||
			rssFeedFields.properties.value !== originalValue.properties ||
			rssFeedFields.type.value !== originalValue.type);

	const feedTypeOptions = useMemo(
		() => [
			{
				display: translate('attribute.rss.newsFeed'),
				value: RssFeedType.News,
			},
			{
				display: translate('attribute.rss.podcastFeed'),
				value: RssFeedType.Podcast,
			},
			{
				display: translate('attribute.rss.videoFeed'),
				value: RssFeedType.Video,
			},
		],
		[translate]
	);

	return (
		<>
			<SelectField<RssFeedType | null>
				field={rssFeedFields.type}
				label={translate('attribute.rss.type')}
				labelPosition="top"
				options={feedTypeOptions}
			/>
			<FeedCommonFields
				enableDownloadButton={!isDirty && enableDownloadButton}
				feedProcessState={feed?.processState}
				processFeed={processFeed}
				propertiesField={rssFeedFields.properties}
				urlField={rssFeedFields.url}
			/>
		</>
	);
};
