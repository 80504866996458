import { BLANKIFY } from '@src/components/textEditor/nodes/BlankifyNode';

const SPAN_BLANKIFY_PATTERN_REGEX = `<span id="([\\w-]+)" class="${BLANKIFY}"><span>([^<]+)<\\/span><\\/span>|<span id="[\\w-]+" class="${BLANKIFY}"><\\/span>`;

export function sanitizeHtml(rawHtmlString: string) {
	const htmlString = rawHtmlString.trim();

	if (!htmlString) return '';

	const regex = new RegExp(SPAN_BLANKIFY_PATTERN_REGEX, 'g');
	const sanitizedHtml = htmlString.replaceAll(regex, (_, id, textContent) =>
		id && textContent ? `<span id="${id}" class="${BLANKIFY}">${textContent}</span>` : ''
	);

	return sanitizedHtml;
}
