import { ReactNode, HTMLAttributes, Fragment } from 'react';

import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

const termWidth = '175px';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		width: '100%',
	},
	term: {
		width: termWidth,
		margin: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		color: theme.colors['grey-700'],
		paddingRight: theme.SPACING.XSMALL,

		'& ~ &': {
			paddingTop: theme.SPACING.SMALL,
		},
	},
	details: {
		width: `calc(100% - ${termWidth})`,
		margin: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',

		'& ~ &': {
			paddingTop: theme.SPACING.SMALL,
		},
	},
}));

type DescriptionListItemOptions = {
	classes?: Partial<Pick<ReturnType<typeof useStyles>, 'term' | 'details'>>;
};

type DescriptionListProps = {
	hideOverflow?: boolean;
	items: [ReactNode, ReactNode, DescriptionListItemOptions?][];
};

type DescriptionListComponentProps = ComponentProps<
	DescriptionListProps,
	typeof useStyles,
	HTMLAttributes<HTMLDListElement>
>;

export const DescriptionList = ({
	items,
	classes: classesProp,
	className: classNameProp,
}: DescriptionListComponentProps) => {
	const classes = useStyles(classesProp);
	const className = clsx(classNameProp, classes.root);

	return (
		<dl className={className}>
			{items.map(([label, description, options], i) => (
				<Fragment key={typeof label === 'string' ? label : i}>
					<dt className={clsx(classes.term, options?.classes?.term)}>{label}</dt>
					<dd className={clsx(classes.details, options?.classes?.details)}>{description}</dd>
				</Fragment>
			))}
		</dl>
	);
};
