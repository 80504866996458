import { RefObject, useCallback } from 'react';

import { Field } from '@pushpay/forms';

import { ImageField, ImageFieldRef } from '@src/components/formFields/ImageField';
import { useGetContainerImageQuery } from '@src/graphql/generated';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { usePolling } from '@src/utils';

import { ContainerSettings } from '../types';

type ContainerImageFieldProps = {
	containerId: string;
	field: Field<string>;
	image: NonNullable<ContainerSettings>['image'];
	imageFieldRef: RefObject<ImageFieldRef>;
	readOnly?: boolean;
	fieldLabel?: string;
};

export function ContainerImageField({
	containerId,
	field,
	image,
	imageFieldRef,
	readOnly,
	fieldLabel,
}: ContainerImageFieldProps) {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();

	const { unprocessedImageUrl, original } = image?.urls ?? {};
	const isProcessingImage = !!unprocessedImageUrl && !original;

	const { startPolling, stopPolling } = useGetContainerImageQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId,
		},
		fetchPolicy: 'network-only',
		skip: !isProcessingImage,
	});

	const onPollEnd = useCallback(() => {
		if (original) {
			imageFieldRef.current?.resetField(original);
		}
	}, [imageFieldRef, original]);

	usePolling({
		inProgress: isProcessingImage,
		startPolling,
		stopPolling,
		onPollEnd,
	});

	return (
		<ImageField
			ref={imageFieldRef}
			field={field}
			fieldLabel={fieldLabel}
			imageType="container image"
			isProcessing={isProcessingImage}
			readOnly={readOnly}
		/>
	);
}
