import { useEffect, useState } from 'react';

import { ExpandStatus } from '@pushpay/expand';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { FileDropArea, ImageUrlInput } from '@src/components/imageUploader/components';
import { fileSizeLimit } from '@src/components/imageUploader/components/shared';

import { usePropertyListContext } from '../properties/contexts';
import { CarouselPreview } from './components/carouselPreview';
import { CarouselImagesField, CarouselFile, CarouselFileUploadType } from './types';

type CarouselUploaderProps = {
	imagesFields: CarouselImagesField[];
	selectedFiles: CarouselFile[];
	onImageSelect: (file: CarouselFile) => void;
	inputExpandStatus?: ExpandStatus;
	maxFileSize?: number;
	isImageProcessing: boolean;
};

const useStyles = createUseStyles({
	wrapper: {},
});

const IMAGE_COUNT_LIMIT = 6;

export const CarouselUploader = ({
	imagesFields,
	selectedFiles,
	onImageSelect,
	inputExpandStatus: inputExpandStatusProp = 'collapsed',
	maxFileSize = fileSizeLimit,
	isImageProcessing = true,
	'data-pp-at-target': targetId,
}: ComponentProps<CarouselUploaderProps, undefined>) => {
	const [selectedFile, setSelectedFile] = useState<CarouselFile>();
	const classes = useStyles(undefined);
	const { disabledForFeedItem } = usePropertyListContext();

	const imageLimitReached = imagesFields.length >= IMAGE_COUNT_LIMIT;
	const isUploadDisabled = imageLimitReached || isImageProcessing || disabledForFeedItem;

	useEffect(() => {
		if (!selectedFile) return;

		onImageSelect(selectedFile);
	}, [selectedFile, onImageSelect]);

	const handleSelectedImageFile = (imageFile: string | File) => {
		const carouselFile: CarouselFile =
			typeof imageFile === 'string'
				? {
						id: crypto.randomUUID(),
						type: CarouselFileUploadType.URL,
						file: imageFile,
				  }
				: {
						id: crypto.randomUUID(),
						type: CarouselFileUploadType.File,
						file: imageFile,
				  };

		setSelectedFile(carouselFile);
	};

	return (
		<div className={classes.wrapper}>
			{imagesFields.length > 0 && <CarouselPreview carouselImages={selectedFiles} imagesFields={imagesFields} />}
			<FileDropArea
				data-pp-at-target={targetId}
				disableUpload={isUploadDisabled}
				imageLimitReached={imageLimitReached}
				inputExpandStatus={inputExpandStatusProp}
				maxFileSize={maxFileSize}
				urlInput={<ImageUrlInput disableUpload={isUploadDisabled} onApplyImage={handleSelectedImageFile} />}
				carousel
				onFileSelect={handleSelectedImageFile}
			/>
		</div>
	);
};
