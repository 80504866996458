import { lazy, Suspense } from 'react';

import { LoaderFunctionArgs } from 'react-router-dom';

import { GlobalContextProvider } from '@src/context/globalContext';
import { apolloClient } from '@src/graphql/client';
import { MyOrganizationQuery, MyOrganizationDocument } from '@src/graphql/generated';

import { EnforceMasAccess } from './EnforceMasAccess';
import { MyOrganization } from './MyOrganization';

const AppChrome = lazy(() => import(/* webpackChunkName: 'appChrome' */ '@src/pages/appChrome'));

export async function loader({ params }: LoaderFunctionArgs) {
	return apolloClient.client !== undefined
		? apolloClient.client.query<MyOrganizationQuery>({
				query: MyOrganizationDocument,
				variables: {
					organizationKey: params.organizationKey,
				},
				fetchPolicy: 'cache-first',
		  })
		: null;
}

export function OrgRoot() {
	return (
		<EnforceMasAccess>
			<MyOrganization>
				<GlobalContextProvider>
					<Suspense>
						<AppChrome />
					</Suspense>
				</GlobalContextProvider>
			</MyOrganization>
		</EnforceMasAccess>
	);
}
