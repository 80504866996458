import Icon, { IconProps } from '@pushpay/icon';
import { createUseStyles } from '@pushpay/styles';

import { illustrationStyles } from './illustrationStyles';

const useStyles = createUseStyles({
	...illustrationStyles,
});

export const NoAccessIllustration = (props: IconProps) => {
	const classes = useStyles(undefined);

	return (
		<Icon className={classes.illustration} viewBox="0 0 160 153" {...props}>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(-641.000000, -289.000000)">
					<g transform="translate(641.000000, 289.000000)">
						<path
							d="M144.868348,45.836382 C158.747168,69.321157 165.371149,97.5818277 154.764892,116.488059 C144.198064,135.354819 116.401,144.82767 87.7759362,145.340782 C59.150873,145.814424 29.6978122,137.328329 13.6504283,115.303952 C-2.39695554,93.2401045 -5.03866247,57.677445 9.70758218,33.6795572 C24.4538268,9.72113957 56.5485946,-2.67250646 83.1628062,0.485110362 C109.816447,3.64272718 130.98953,22.3516069 144.868348,45.836382 Z"
							fill="#FFFFFF"
							fillOpacity="0.6"
							fillRule="nonzero"
						/>
						<g stroke="#CCEDE9" strokeWidth="2" transform="translate(13.000000, 2.000000)">
							<path d="M142.113593,78.9947685 C143.209489,105.019025 136.058032,131.841765 118.634165,143.080138 C101.261202,154.302758 73.6335932,149.907675 49.3424815,137.575998 C25.0691338,125.210986 4.13228326,104.909378 0.556374107,79.1515178 C-3.00177095,53.3603208 10.7832613,22.1462064 33.9784767,8.45362589 C57.1559279,-5.20561828 89.7104216,-1.36091135 110.659293,13.1743297 C131.641306,27.7271535 141.017697,52.9705113 142.113593,78.9947685 Z" />
						</g>
						<g strokeWidth="2.8" transform="translate(59.000000, 45.000000)">
							<path
								d="M21,-1.4 C24.411746,-1.4 27.4966163,-0.108294495 29.7310996,1.96777346 C32.0042207,4.07974005 33.4,7.00028489 33.4,10.2201471 L33.4,10.2201471 L33.4,15.4 L8.6,15.4 L8.6,10.2201471 C8.6,7.00028489 9.99577929,4.07974005 12.2689004,1.96777346 C14.5033837,-0.108294495 17.588254,-1.4 21,-1.4 Z"
								stroke="#B9CDCA"
							/>
							<rect height="40.8" rx="4.97777778" stroke="#B9CDCA" width="44.8" x="-1.4" y="15.6" />
							<circle cx="21" cy="36" r="5.4" stroke="#00A38F" />
						</g>
					</g>
				</g>
			</g>
		</Icon>
	);
};
