import { Breadcrumb } from '@pushpay/breadcrumb';
import { clsx } from '@pushpay/styles';

import { Link } from '@src/components/link';
import { useBreadcrumbStateContext } from '@src/context';

import { useStyles } from './breadcrumbStyles';

export type BreadcrumbType = {
	name: string;
	path?: string;
};

export const Breadcrumbs = () => {
	const classes = useStyles(undefined);
	const { breadcrumbs, loading } = useBreadcrumbStateContext();

	const updatedBreadcrumbs = [...breadcrumbs];

	// replace breadcrumb after root with ellipsis
	if (updatedBreadcrumbs.length >= 4) {
		updatedBreadcrumbs[1] = { name: '...' };
	}

	return (
		<div className={clsx(classes.root, loading && classes.loading)}>
			<Breadcrumb
				breadcrumbItems={updatedBreadcrumbs.map(breadcrumb => ({
					name: breadcrumb.name,
					to: breadcrumb.path || '',
				}))}
				itemComponent={Link}
			/>
		</div>
	);
};
