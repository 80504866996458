import { useApolloClient } from '@apollo/client';

import { MeDocument, MeQuery } from '@src/graphql/generated';
import { clearUserContextForRaygun } from '@src/raygun';

export const useMyIdentity = () => {
	const { cache } = useApolloClient();
	const data = cache.readQuery<MeQuery>({ query: MeDocument });
	return data?.me as NonNullable<MeQuery['me']>;
};

export const clearMyIdentity = () => {
	clearUserContextForRaygun();
};
