import { RefObject, useRef } from 'react';

import { useDndMonitor } from '@dnd-kit/core';
import scrollIntoView from 'scroll-into-view-if-needed';

import { useScrollToLastPagePositionContext } from '@src/context/scrollToLastPagePositionContext';
import { useFeature } from '@src/featureFlags';

export function useDragAutoScroll(dropAreaRef: RefObject<HTMLElement>, doNotScrollAfterDropArea = false) {
	const lastPositionRef = useRef<number | null>(null);
	const { containerRef } = useScrollToLastPagePositionContext();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const container = containerRef?.current;
	const dropArea = dropAreaRef.current;

	const scrollTo = (top: number | null) => {
		if (container && top !== null) {
			container.scrollTo({ top, behavior: 'smooth' });
		}
	};

	const scrollToLastPosition = () => {
		scrollTo(lastPositionRef.current);
	};

	useDndMonitor({
		onDragStart() {
			if (!container || !dropArea || (doNotScrollAfterDropArea && container.scrollTop >= dropArea.offsetTop)) {
				return;
			}

			lastPositionRef.current = container.scrollTop;

			if (isInAppCalendarEnabled) {
				scrollIntoView(dropArea, { behavior: 'smooth', block: 'start', scrollMode: 'if-needed' });
			} else {
				scrollTo(dropArea.offsetTop);
			}
		},
		onDragEnd({ over }) {
			if (over !== null) {
				lastPositionRef.current = null;
				return;
			}

			scrollToLastPosition();
		},
		onDragCancel: scrollToLastPosition,
	});
}
