import { ReactNode, HTMLAttributes } from 'react';

import { Heading } from '@pushpay/layout';
import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

const useSectionHeadingStyles = createUseStyles({
	root: {},
	withAction: {
		display: 'flex',
		flex: '1 1 auto',
		position: 'relative',
	},
	action: {
		position: 'absolute',
		right: 0,
		top: '50%',
		transform: 'translateY(-50%)',
	},
});

export const SectionHeading = ({
	action,
	classes: classesProp,
	className: classNameProp,
	children,
	...rest
}: ComponentProps<
	{ action?: () => ReactNode },
	typeof useSectionHeadingStyles,
	Omit<HTMLAttributes<HTMLHeadElement>, 'color'>
>) => {
	const classes = useSectionHeadingStyles(classesProp);
	const className = clsx(classNameProp, classes.root, action && classes.withAction);
	return (
		<Heading className={className} level="3" {...rest}>
			{children}
			{action && <div className={classes.action}>{action()}</div>}
		</Heading>
	);
};
