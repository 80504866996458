import { useId } from 'react';

import { FormState, Field } from '@pushpay/forms';
import { InfoIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { CarouselImageFieldWrapper } from '@src/components/formFields';
import { acceptedFileTypes } from '@src/components/imageUploader/components/shared/utils';
import { CarouselPropertyInput, CarouselProperty } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { usePropertyListContext } from '../contexts';
import { ContentWrapper, LabelWrapper, TextFieldWrapper } from '../wrappers';

export type CarouselProps = ComponentProps<
	{
		propertyField: FormState<CarouselPropertyInput>;
	},
	typeof undefined
>;

const useStyles = createUseStyles((theme: Theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
	},
	titleTooltip: {
		marginLeft: theme.SPACING.XXSMALL,
		zIndex: 1,
	},
}));

export const Carousel = ({ propertyField, 'data-pp-at-target': targetId }: CarouselProps) => {
	const { images: imagesDynamicFields, title, baseProperty } = propertyField;
	const { translate } = useTranslation('appDesign');
	const { itemId, properties } = usePropertyListContext();
	const classes = useStyles(undefined);

	const carouselProperty = properties.find(property => property.id === baseProperty?.id?.value);
	const images = (carouselProperty && (carouselProperty as CarouselProperty)?.images) ?? [];
	const acceptedFileTypesJoined = acceptedFileTypes.join(', ').toUpperCase();

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={translate('carousel.label')}
				data-pp-at-target={`${targetId}-text-description`}
				field={title as Field<string>}
				id={useId()}
				label={translate('carousel.label')}
				labelPosition="top"
				showLabel
			/>

			<LabelWrapper
				label={
					<div className={classes.title}>
						{translate('carousel.images')}
						<Tooltip
							classes={{ root: classes.titleTooltip }}
							content={translate('imageUploader.imageUploadTooltip', {
								acceptedFileTypes: acceptedFileTypesJoined,
							})}
							data-pp-at-target={`${targetId}-toggle-tooltip`}
							panelSpace="XXSMALL"
							placement="top"
						>
							<InfoIcon data-pp-at-target={`${targetId}-tip-icon`} />
						</Tooltip>
					</div>
				}
			>
				<CarouselImageFieldWrapper
					carouselId={baseProperty.id.value}
					images={images}
					imagesDynamicFields={imagesDynamicFields}
					itemId={itemId}
				/>
			</LabelWrapper>
		</ContentWrapper>
	);
};
