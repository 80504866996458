/* eslint-disable @typescript-eslint/no-shadow */

import { getParentIdAndInsertBeforeId, isDraggableType } from '@src/pages/appDesign/helper';
import { DropHandler } from '@src/pages/appDesign/type';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { useOptimizedReorderContainerChildrenMutation } from '../useOptimizedReorderContainerChildrenMutation';

export function useReorderContainerHandler() {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const reorderContainerChildren = useOptimizedReorderContainerChildrenMutation();
	const onReorderContainerDropHandler: DropHandler = (event, draggingItem) => {
		const { over, active } = event;
		if (!over) {
			return;
		}

		// eslint-disable-next-line prefer-const
		let { parentId, insertBeforeId } = getParentIdAndInsertBeforeId(over);
		if (!isDraggableType(draggingItem) && over.data.current?.sortable) {
			const activeItemPosition = active.rect.current.initial?.top;
			const overItemPosition = over.rect.top;
			const moveDown = activeItemPosition !== undefined && activeItemPosition < overItemPosition;
			if (moveDown) {
				const { index, items } = over.data.current.sortable;
				const nextSibling = items[index + 1] ?? null;
				insertBeforeId = nextSibling;
			}
			if (draggingItem.id !== insertBeforeId) {
				reorderContainerChildren({
					organizationKey,
					platformCampusKey,
					input: {
						applicationId,
						parentContainerId: parentId,
						containerChildId: draggingItem.id,
						insertBefore: insertBeforeId,
					},
					moveDown,
					draggableType: draggingItem.type,
				});
			}
		}
	};

	return onReorderContainerDropHandler;
}
