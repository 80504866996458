import { GetContainerChildrenQuery } from '@src/graphql/generated';

import { ContainerPreview } from './ContainerPreview';

export function ContainerChildrenPreviewContent({
	data,
	isRootContainer,
}: {
	data: GetContainerChildrenQuery;
	isRootContainer: boolean;
}) {
	const container = data.organization?.application?.container;
	if (!container) return null;

	const containerData = {
		id: container.id,
		children: container.children.nodes,
		images: container.image?.urls,
		subtitle: container.subtitle,
		template: container.template,
		type: container.type,
	};

	return <ContainerPreview container={containerData} isRootContainer={isRootContainer} />;
}
