export const getTemplateWidth = (isTablet: boolean) => (isTablet ? 100 : 160);
export const getTemplateHeight = (isTablet: boolean) => (isTablet ? 80 : 120);
export const acceptedFileTypes = ['jpg', 'jpeg', 'png', 'bmp'];
export const fileSizeLimit = 1024 * 1024 * 10; // 10 MB
export const convertBytesToMB = (fileSize: number) => fileSize / (1024 * 1024);

export type Image = {
	width: number;
	height: number;
	url: string;
};

export type SelectedFile = string | File | undefined;

export const getResizedImage = (
	{ width: originalWidth, height: originalHeight, url }: Image,
	isTablet: boolean
): Image => {
	const templateWidth = getTemplateWidth(isTablet);
	const templateHeight = getTemplateHeight(isTablet);
	let width = originalWidth;
	let height = originalHeight;

	if (originalWidth > originalHeight) {
		width = templateWidth;
		height = (templateWidth * originalHeight) / originalWidth;

		if (height > templateHeight) {
			width *= templateHeight / height;
			height = templateHeight;
		}
	} else {
		width = (templateHeight * originalWidth) / originalHeight;
		height = templateHeight;

		if (width > templateWidth) {
			width = templateWidth;
			height *= templateWidth / width;
		}
	}

	return {
		width,
		height,
		url,
	};
};

export const generateImageId = () => Math.random().toString(32).slice(2);

export const loadImage = ({
	image,
	onLoad,
	onError,
}: {
	image: SelectedFile;
	onLoad: (image: Image) => void;
	onError?: () => void;
}) => {
	const imageEl = new window.Image();

	imageEl.onload = ({ target }) => {
		const { naturalWidth, naturalHeight, src } = target as HTMLImageElement;

		onLoad({
			width: naturalWidth,
			height: naturalHeight,
			url: src,
		});
	};

	if (onError !== undefined) {
		imageEl.onerror = onError;
	}

	const isFile = image instanceof File;
	const objectUrl = isFile ? URL.createObjectURL(image) : image;

	if (objectUrl) {
		imageEl.src = objectUrl;
	}
};
