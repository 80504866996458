import { useMemo } from 'react';

import { useCopyAndMoveDispatchContext } from '@src/context';

import { ParentContainer } from './ChildrenNavigation';
import { DestinationCard } from './DestinationCard';
import { SelectedContainer, ContainerChild, EventsContainerChildrenType } from './types';

export type CardListProps = {
	cards?: ContainerChild[];
	eventsContainerChildren?: EventsContainerChildrenType[];
	isRootLevelContainer?: boolean;
	disabledContainers?: string[];
	destinationContainer?: SelectedContainer | null;
	parentDestinationContainer?: ParentContainer;
	onSelect: (card: SelectedContainer) => void;
	onNavigate: (children: SelectedContainer) => void;
};

export const CardList = ({
	cards,
	eventsContainerChildren,
	isRootLevelContainer = false,
	disabledContainers,
	destinationContainer,
	parentDestinationContainer,
	onSelect,
	onNavigate,
}: CardListProps) => {
	const { getSelectedItems } = useCopyAndMoveDispatchContext();
	const selectedCards = useMemo(() => getSelectedItems(), [getSelectedItems]);

	return (
		<ul>
			{cards?.map(card => (
				<DestinationCard
					card={card}
					destinationContainer={destinationContainer}
					disabledContainers={disabledContainers}
					eventsContainerChildren={eventsContainerChildren}
					isRootLevelContainer={isRootLevelContainer}
					parentDestinationContainer={parentDestinationContainer}
					selectedCards={selectedCards}
					onNavigate={onNavigate}
					onSelect={onSelect}
				/>
			))}
		</ul>
	);
};
