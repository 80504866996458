export const CHAR_MAP = {
	'custom-more': 'E66A',
	'custom-pulpit': 'E66B',
	'custom-church': 'E66C',
	'custom-cross': 'E66D',
	'custom-prayer': 'E66E',
	'custom-share': 'E66F',
	'custom-facebook': 'E670',
	'custom-twitter': 'E671',
	'custom-blankify': 'E672',
	'custom-events-feed': 'E673',
	'custom-simpleview': 'E674',
	'custom-app': 'E675',
	'custom-android': 'E676',
	'custom-ekklesia': 'E677',
	'custom-ccb': 'E678',
	'custom-vimeo': 'E67A',
	'custom-miles': 'E67B',
	'custom-bluebridge-1': 'E67D',
	'custom-bluebridge-2': 'E67E',
	'custom-amazon': 'E67F',
	'custom-dribbble': 'E680',
	'custom-github': 'E681',
	'custom-instagram': 'E682',
	'custom-itunes': 'E683',
	'custom-linkedin': 'E684',
	'bin-1': 'E000',
	'bin-2': 'E001',
	binocular: 'E002',
	'clip-1': 'E004',
	'clip-2': 'E005',
	'crosshair-1': 'E006',
	'crosshair-2': 'E007',
	'crosshair-3': 'E008',
	cutter: 'E009',
	'delete-1': 'E00A',
	'delete-2': 'E00B',
	'edit-1': 'E00C',
	'edit-2': 'E00D',
	'edit-3': 'E00E',
	hide: 'E00F',
	ink: 'E010',
	'key-1': 'E011',
	'key-2': 'E012',
	'link-1': 'E013',
	'link-2': 'E014',
	'link-3': 'E015',
	'link-broken-1': 'E016',
	'link-broken-2': 'E017',
	'lock-1': 'E018',
	'lock-2': 'E019',
	'lock-3': 'E01A',
	'lock-4': 'E01B',
	'lock-5': 'E01C',
	'lock-unlock-1': 'E01D',
	'lock-unlock-2': 'E01E',
	magnifier: 'E01F',
	'pen-1': 'E020',
	'pen-2': 'E021',
	'pen-3': 'E022',
	'pen-4': 'E023',
	'pencil-1': 'E024',
	'pencil-2': 'E025',
	'pencil-3': 'E026',
	'pin-1': 'E027',
	'pin-2': 'E028',
	'power-1': 'E029',
	'power-2': 'E02A',
	'preview-1': 'E02B',
	'preview-2': 'E02C',
	'scissor-1': 'E02D',
	'scissor-2': 'E02E',
	'media-library': 'E030',
	'type-1': 'E031',
	'type-2': 'E032',
	'type-3': 'E033',
	'type-4': 'E034',
	'zoom-area': 'E035',
	'zoom-in': 'E036',
	'zoom-out': 'E037',
	'cursor-1': 'E038',
	'cursor-2': 'E039',
	'cursor-add': 'E03A',
	'cursor-duplicate': 'E03B',
	'cursor-move': 'E03C',
	'cursor-move-2': 'E03D',
	'cursor-select-area': 'E03E',
	hand: 'E03F',
	'hand-block': 'E040',
	'hand-grab-1': 'E041',
	'hand-grab-2': 'E042',
	'hand-point': 'E043',
	'hand-touch-1': 'E044',
	'hand-touch-2': 'E045',
	'hand-touch-3': 'E046',
	'hand-touch-4': 'E047',
	'bookmark-1': 'E048',
	'bookmark-2': 'E049',
	'bookmark-3': 'E04A',
	'bookmark-4': 'E04B',
	'tag-1': 'E04C',
	'tag-2': 'E04D',
	'tag-add': 'E04E',
	'tag-delete': 'E04F',
	'tags-1': 'E050',
	'tags-2': 'E051',
	'anchor-point-1': 'E052',
	'anchor-point-2': 'E053',
	'arrange-1': 'E054',
	'arrange-2': 'E055',
	artboard: 'E056',
	'brush-1': 'E057',
	'brush-2': 'E058',
	bucket: 'E059',
	crop: 'E05A',
	'dropper-1': 'E05B',
	'dropper-2': 'E05C',
	'dropper-3': 'E05D',
	glue: 'E05E',
	grid: 'E05F',
	layers: 'E060',
	'magic-wand-1': 'E061',
	'magic-wand-2': 'E062',
	magnet: 'E063',
	marker: 'E064',
	palette: 'E065',
	'pen-5': 'E066',
	'pen-6': 'E067',
	quill: 'E068',
	reflect: 'E069',
	roller: 'E06A',
	'ruler-1': 'E06B',
	'ruler-2': 'E06C',
	'scale-diagonal-1': 'E06D',
	'scale-diagonal-2': 'E06E',
	'scale-horizontal': 'E06F',
	'scale-tool-1': 'E070',
	'scale-tool-2': 'E071',
	'scale-tool-3': 'E072',
	'scale-vertical': 'E073',
	'shear-tool': 'E074',
	spray: 'E075',
	stamp: 'E076',
	'stationery-1': 'E077',
	'stationery-2': 'E078',
	'stationery-3': 'E079',
	vector: 'E07A',
	'award-1': 'E07B',
	'award-2': 'E07C',
	'award-3': 'E07D',
	'award-4': 'E07E',
	'award-5': 'E07F',
	'award-6': 'E080',
	'crown-1': 'E081',
	'crown-2': 'E082',
	'crown-3': 'E083',
	fire: 'E084',
	'flag-1': 'E085',
	'flag-2': 'E086',
	'flag-3': 'E087',
	'flag-4': 'E088',
	'flag-5': 'E089',
	'flag-6': 'E08A',
	'flag-7': 'E08B',
	'flag-8': 'E08C',
	'google-plus-1': 'E08D',
	'google-plus-2': 'E08E',
	'hand-like-1': 'E08F',
	'hand-like-2': 'E090',
	'hand-unlike-1': 'E091',
	'hand-unlike-2': 'E092',
	'heart-1': 'E093',
	'heart-2': 'E094',
	'heart-angel': 'E095',
	'heart-broken': 'E096',
	'heart-minus': 'E097',
	'heart-plus': 'E098',
	present: 'E099',
	'rank-1': 'E09A',
	'rank-2': 'E09B',
	ribbon: 'E09C',
	'star-1': 'E09D',
	'star-2': 'E09E',
	'star-3': 'E09F',
	'star-4': 'E0A0',
	'star-5': 'E0A1',
	'star-6': 'E0A2',
	'star-7': 'E0A3',
	'star-8': 'E0A4',
	'star-9': 'E0A5',
	'star-10': 'E0A6',
	trophy: 'E0A7',
	'bubble-2': 'E0A8',
	'bubble-add-1': 'E0A9',
	'bubble-add-2': 'E0AA',
	'bubble-add-3': 'E0AB',
	'bubble-ask-1': 'E0AC',
	'bubble-ask-2': 'E0AD',
	'bubble-attention-1': 'E0AE',
	'bubble-attention-2': 'E0AF',
	'bubble-attention-3': 'E0B0',
	'bubble-attention-4': 'E0B1',
	'bubble-attention-5': 'E0B2',
	'bubble-attention-6': 'E0B3',
	'bubble-attention-7': 'E0B4',
	'bubble-block-1': 'E0B5',
	'bubble-block-2': 'E0B6',
	'bubble-block-3': 'E0B7',
	'bubble-chat-1': 'E0B8',
	'bubble-chat-2': 'E0B9',
	'bubble-check-1': 'E0BA',
	'bubble-check-2': 'E0BB',
	'bubble-check-3': 'E0BC',
	'bubble-comment-1': 'E0BD',
	'bubble-comment-2': 'E0BE',
	'bubble-conversation-1': 'E0BF',
	'bubble-conversation-2': 'E0C0',
	'bubble-conversation-3': 'E0C1',
	'bubble-conversation-4': 'E0C2',
	'bubble-conversation-5': 'E0C3',
	'bubble-conversation-6': 'E0C4',
	'bubble-delete-1': 'E0C5',
	'bubble-delete-2': 'E0C6',
	'bubble-delete-3': 'E0C7',
	'bubble-edit-1': 'E0C8',
	'bubble-edit-2': 'E0C9',
	'bubble-edit-3': 'E0CA',
	'bubble-heart-1': 'E0CB',
	'bubble-heart-2': 'E0CC',
	'bubble-minus-1': 'E0CD',
	'bubble-minus-2': 'E0CE',
	'bubble-minus-3': 'E0CF',
	'bubble-quote-1': 'E0D0',
	'bubble-quote-2': 'E0D1',
	'bubble-smiley-3': 'E0D2',
	'bubble-smiley-4': 'E0D3',
	'bubble-smiley-smile': 'E0D4',
	'bubble-smiley-wink': 'E0D5',
	'bubble-star-1': 'E0D6',
	'bubble-star-2': 'E0D7',
	'bubble-star-3': 'E0D8',
	'chat-1': 'E0D9',
	'chat-2': 'E0DA',
	'chat-3': 'E0DB',
	'chat-4': 'E0DC',
	'chat-5': 'E0DD',
	'chat-bubble-1': 'E0DE',
	'chat-bubble-2': 'E0DF',
	'smiley-happy-1': 'E0E0',
	'smiley-happy-2': 'E0E1',
	'smiley-happy-3': 'E0E2',
	'smiley-happy-4': 'E0E3',
	'smiley-happy-5': 'E0E4',
	'smiley-relax': 'E0E5',
	'smiley-sad': 'E0E6',
	'smiley-surprise': 'E0E7',
	'thinking-1': 'E0E8',
	'thinking-2': 'E0E9',
	'call-1': 'E0EA',
	'call-2': 'E0EB',
	'call-3': 'E0EC',
	'call-4': 'E0ED',
	'call-add': 'E0EE',
	'call-block': 'E0EF',
	'call-delete': 'E0F0',
	'call-in': 'E0F1',
	'call-minus': 'E0F2',
	'call-out': 'E0F3',
	contact: 'E0F4',
	fax: 'E0F5',
	'hang-up': 'E0F6',
	message: 'E0F7',
	'mobile-phone-1': 'E0F8',
	'mobile-phone-2': 'E0F9',
	'phone-1': 'E0FA',
	'phone-2': 'E0FB',
	'phone-3': 'E0FC',
	'phone-4': 'E0FD',
	'phone-vibration': 'E0FE',
	'signal-fine': 'E0FF',
	'signal-full': 'E100',
	'signal-high': 'E101',
	'signal-no': 'E102',
	'signal-poor': 'E103',
	'signal-weak': 'E104',
	smartphone: 'E105',
	tape: 'E106',
	'camera-symbol-1': 'E107',
	'camera-symbol-2': 'E108',
	'camera-symbol-3': 'E109',
	'antenna-1': 'E10B',
	'antenna-2': 'E10C',
	'antenna-3': 'E10D',
	'hotspot-1': 'E10E',
	'hotspot-2': 'E10F',
	link: 'E110',
	'megaphone-1': 'E111',
	'megaphone-2': 'E112',
	radar: 'E113',
	'rss-1': 'E114',
	'rss-2': 'E115',
	satellite: 'E116',
	'address-1': 'E117',
	'address-2': 'E118',
	'address-3': 'E119',
	forward: 'E11A',
	'inbox-1': 'E11B',
	'inbox-2': 'E11C',
	'inbox-3': 'E11D',
	'inbox-4': 'E11E',
	'letter-1': 'E11F',
	'letter-2': 'E120',
	'letter-3': 'E121',
	'letter-4': 'E122',
	'letter-5': 'E123',
	'mail-1': 'E124',
	'mail-2': 'E125',
	'mail-add': 'E126',
	'mail-attention': 'E127',
	'mail-block': 'E128',
	'mail-box-1': 'E129',
	'mail-box-2': 'E12A',
	'mail-box-3': 'E12B',
	'mail-checked': 'E12C',
	'mail-compose': 'E12D',
	'mail-delete': 'E12E',
	'mail-favorite': 'E12F',
	'mail-inbox': 'E130',
	'mail-lock': 'E131',
	'mail-minus': 'E132',
	'mail-read': 'E133',
	'mail-recieved-1': 'E134',
	'mail-recieved-2': 'E135',
	'mail-search-1': 'E136',
	'mail-search-2': 'E137',
	'mail-sent-1': 'E138',
	'mail-sent-2': 'E139',
	'mail-setting': 'E13A',
	'mail-star': 'E13B',
	'mail-sync': 'E13C',
	'mail-time': 'E13D',
	'outbox-1': 'E13E',
	'outbox-2': 'E13F',
	'plane-paper-1': 'E140',
	'plane-paper-2': 'E141',
	'reply-mail-1': 'E142',
	'reply-mail-2': 'E143',
	'connection-1': 'E144',
	'connection-2': 'E145',
	'connection-3': 'E146',
	'contacts-1': 'E147',
	'contacts-2': 'E148',
	'contacts-3': 'E149',
	'contacts-4': 'E14A',
	female: 'E14B',
	'gender-female': 'E14C',
	'gender-male': 'E14D',
	genders: 'E14E',
	'id-1': 'E14F',
	'id-2': 'E150',
	'id-3': 'E151',
	'id-4': 'E152',
	'id-5': 'E153',
	'id-6': 'E154',
	'id-7': 'E155',
	'id-8': 'E156',
	male: 'E157',
	'profile-1': 'E158',
	'profile-2': 'E159',
	'profile-3': 'E15A',
	'profile-4': 'E15B',
	'profile-5': 'E15C',
	'profile-6': 'E15D',
	'profile-athlete': 'E15E',
	'profile-bussiness-man': 'E15F',
	'profile-chef': 'E160',
	'profile-cop': 'E161',
	'profile-doctor-1': 'E162',
	'profile-doctor-2': 'E163',
	'profile-gentleman-1': 'E164',
	'profile-gentleman-2': 'E165',
	'profile-graduate': 'E166',
	'profile-king': 'E167',
	'profile-lady-1': 'E168',
	'profile-lady-2': 'E169',
	'profile-man': 'E16A',
	'profile-nurse1': 'E16B',
	'profile-nurse-2': 'E16C',
	'profile-prisoner': 'E16D',
	'profile-serviceman-1': 'E16E',
	'profile-serviceman-2': 'E16F',
	'profile-spy': 'E170',
	'profile-teacher': 'E171',
	'profile-thief': 'E172',
	'user-1': 'E173',
	'user-2': 'E174',
	'user-add-1': 'E175',
	'user-add-2': 'E176',
	'user-block-1': 'E177',
	'user-block-2': 'E178',
	'user-checked-1': 'E179',
	'user-checked-2': 'E17A',
	'user-delete-1': 'E17B',
	'user-delete-2': 'E17C',
	'user-edit-1': 'E17D',
	'user-edit-2': 'E17E',
	'user-heart-1': 'E17F',
	'user-heart-2': 'E180',
	'user-lock-1': 'E181',
	'user-lock-2': 'E182',
	'user-minus-1': 'E183',
	'user-minus-2': 'E184',
	'user-search-1': 'E185',
	'user-search-2': 'E186',
	'user-setting-1': 'E187',
	'user-setting-2': 'E188',
	'user-star-1': 'E189',
	'user-star-2': 'E18A',
	'bag-shopping-1': 'E18B',
	'bag-shopping-2': 'E18C',
	'bag-shopping-3': 'E18D',
	'basket-1': 'E18E',
	'basket-2': 'E18F',
	'basket-3': 'E190',
	'basket-add': 'E191',
	'basket-minus': 'E192',
	briefcase: 'E193',
	'cart-1': 'E194',
	'cart-2': 'E195',
	'cart-3': 'E196',
	'cart-4': 'E197',
	cut: 'E198',
	'handbag-1': 'E199',
	'handbag-2': 'E19A',
	'purse-1': 'E19B',
	'purse-2': 'E19C',
	'qr-code': 'E19D',
	'receipt-1': 'E19E',
	'receipt-2': 'E19F',
	'receipt-3': 'E1A0',
	'receipt-4': 'E1A1',
	'shopping-1': 'E1A2',
	'sign-new-1': 'E1A3',
	'sign-new-2': 'E1A4',
	'sign-parking': 'E1A5',
	'signal-star': 'E1A6',
	'trolley-1': 'E1A7',
	'trolley-2': 'E1A8',
	'trolley-3': 'E1A9',
	'trolley-load': 'E1AA',
	'trolley-off': 'E1AB',
	'wallet-1': 'E1AC',
	'wallet-2': 'E1AD',
	'wallet-3': 'E1AE',
	'camera-1': 'E1AF',
	'camera-2': 'E1B0',
	'camera-3': 'E1B1',
	'camera-4': 'E1B2',
	'camera-5': 'E1B3',
	'camera-back': 'E1B4',
	'camera-focus': 'E1B5',
	'camera-frames': 'E1B6',
	'camera-front': 'E1B7',
	'camera-graph-1': 'E1B8',
	'camera-graph-2': 'E1B9',
	'camera-landscape': 'E1BA',
	'camera-lens-1': 'E1BB',
	'camera-lens-2': 'E1BC',
	'camera-light': 'E1BD',
	'camera-portrait': 'E1BE',
	'camera-view': 'E1BF',
	'film-1': 'E1C0',
	'film-2': 'E1C1',
	'photo-1': 'E1C2',
	'photo-2': 'E1C3',
	'photo-frame': 'E1C4',
	'photos-1': 'E1C5',
	'photos-2': 'E1C6',
	polaroid: 'E1C7',
	'signal-camera-1': 'E1C8',
	'signal-camera-2': 'E1C9',
	'user-photo': 'E1CA',
	'backward-1': 'E1CB',
	'dvd-player': 'E1CC',
	'eject-1': 'E1CD',
	'film-3': 'E1CE',
	'forward-1': 'E1CF',
	handycam: 'E1D0',
	'movie-play-1': 'E1D1',
	'movie-play-2': 'E1D2',
	'movie-play-3': 'E1D3',
	'next-1': 'E1D4',
	'pause-1': 'E1D5',
	'play-1': 'E1D6',
	player: 'E1D7',
	'previous-1': 'E1D8',
	'record-1': 'E1D9',
	slate: 'E1DA',
	'stop-1': 'E1DB',
	television: 'E1DC',
	'video-camera-1': 'E1DD',
	'video-camera-2': 'E1DE',
	'backward-2': 'E1DF',
	cd: 'E1E0',
	'eject-2': 'E1E1',
	'equalizer-2': 'E1E2',
	'equalizer-3': 'E1E3',
	'forward-2': 'E1E4',
	gramophone: 'E1E5',
	'gramophone-record-2': 'E1E6',
	guitar: 'E1E7',
	headphone: 'E10A',
	'headphone-1': 'E1E8',
	'headphone-2': 'E1E9',
	'microphone-1': 'E1EA',
	'microphone-2': 'E1EB',
	'microphone-3': 'E1EC',
	'movie-play-4': 'E1ED',
	'music-note-1': 'E1EE',
	'music-note-2': 'E1EF',
	'music-note-3': 'E1F0',
	'music-note-4': 'E1F1',
	'next-2': 'E1F2',
	'notes-1': 'E1F3',
	'notes-2': 'E1F4',
	'pause-2': 'E1F5',
	piano: 'E1F6',
	'play-2': 'E1F7',
	playlist: 'E1F8',
	'previous-2': 'E1F9',
	'radio-1': 'E1FA',
	'radio-2': 'E1FB',
	'record-2': 'E1FC',
	recorder: 'E1FD',
	saxophone: 'E1FE',
	'speaker-1': 'E1FF',
	'speaker-2': 'E200',
	'speaker-3': 'E201',
	'stop-2': 'E202',
	'tape-1': 'E203',
	trumpet: 'E204',
	'volume-down-1': 'E205',
	'volume-down-2': 'E206',
	'volume-loud-1': 'E207',
	'volume-loud-2': 'E208',
	'volume-low-1': 'E209',
	'volume-low-2': 'E20A',
	'volume-medium-1': 'E20B',
	'volume-medium-2': 'E20C',
	'volume-mute-1': 'E20D',
	'volume-mute-2': 'E20E',
	'volume-mute-3': 'E20F',
	'volume-up-1': 'E210',
	'volume-up-2': 'E211',
	walkman: 'E212',
	cloud: 'E213',
	'cloud-add': 'E214',
	'cloud-checked': 'E215',
	'cloud-delete': 'E216',
	'cloud-download': 'E217',
	'cloud-minus': 'E218',
	'cloud-refresh': 'E219',
	'cloud-sync': 'E21A',
	'cloud-upload': 'E21B',
	'download-1': 'E21C',
	'download-2': 'E21D',
	'download-3': 'E21E',
	'download-4': 'E21F',
	'download-5': 'E220',
	'download-6': 'E221',
	'download-7': 'E222',
	'download-8': 'E223',
	'download-9': 'E224',
	'download-10': 'E225',
	'download-11': 'E226',
	'download-12': 'E227',
	'download-13': 'E228',
	'download-14': 'E229',
	'download-15': 'E22A',
	'download-file': 'E22B',
	'download-folder': 'E22C',
	'goal-1': 'E22D',
	'goal-2': 'E22E',
	'transfer-1': 'E22F',
	'transfer-2': 'E230',
	'transfer-3': 'E231',
	'transfer-4': 'E232',
	'transfer-5': 'E233',
	'transfer-6': 'E234',
	'transfer-7': 'E235',
	'transfer-8': 'E236',
	'transfer-9': 'E237',
	'transfer-10': 'E238',
	'transfer-11': 'E239',
	'transfer-12': 'E23A',
	'upload-1': 'E23B',
	'upload-2': 'E23C',
	'upload-3': 'E23D',
	'upload-4': 'E23E',
	'upload-5': 'E23F',
	'upload-6': 'E240',
	'upload-7': 'E241',
	'upload-8': 'E242',
	'upload-9': 'E243',
	'upload-10': 'E244',
	'upload-11': 'E245',
	'upload-12': 'E246',
	'clipboard-1': 'E247',
	'clipboard-2': 'E248',
	'clipboard-3': 'E249',
	'clipboard-add': 'E24A',
	'clipboard-block': 'E24B',
	'clipboard-checked': 'E24C',
	'clipboard-delete': 'E24D',
	'clipboard-edit': 'E24E',
	'clipboard-minus': 'E24F',
	'document-1': 'E250',
	'document-2': 'E251',
	'file-1': 'E252',
	'file-2': 'E253',
	'file-add': 'E254',
	'file-attention': 'E255',
	'file-block': 'E256',
	'file-bookmark': 'E257',
	'file-checked': 'E258',
	'file-code': 'E259',
	'file-delete': 'E25A',
	'file-download': 'E25B',
	'file-edit': 'E25C',
	'file-favorite-1': 'E25D',
	'file-favorite-2': 'E25E',
	'file-graph-1': 'E25F',
	'file-graph-2': 'E260',
	'file-home': 'E261',
	'file-image-1': 'E262',
	'file-image-2': 'E263',
	'file-list': 'E264',
	'file-lock': 'E265',
	'file-media': 'E266',
	'file-minus': 'E267',
	'file-music': 'E268',
	'file-new': 'E269',
	'file-registry': 'E26A',
	'file-search': 'E26B',
	'file-setting': 'E26C',
	'file-sync': 'E26D',
	'file-table': 'E26E',
	'file-thumbnail': 'E26F',
	'file-time': 'E270',
	'file-transfer': 'E271',
	'file-upload': 'E272',
	'file-zip': 'E273',
	'files-1': 'E274',
	'files-2': 'E275',
	'files-3': 'E276',
	'files-4': 'E277',
	'files-5': 'E278',
	'files-6': 'E279',
	'hand-file-1': 'E27A',
	'hand-file-2': 'E27B',
	'note-paper-1': 'E27C',
	'note-paper-2': 'E27D',
	'note-paper-add': 'E27E',
	'note-paper-attention': 'E27F',
	'note-paper-block': 'E280',
	'note-paper-checked': 'E281',
	'note-paper-delete': 'E282',
	'note-paper-download': 'E283',
	'note-paper-edit': 'E284',
	'note-paper-favorite': 'E285',
	'note-paper-lock': 'E286',
	'note-paper-minus': 'E287',
	'note-paper-search': 'E288',
	'note-paper-sync': 'E289',
	'note-paper-upload': 'E28A',
	print: 'E28B',
	'folder-1': 'E28C',
	'folder-2': 'E28D',
	'folder-3': 'E28E',
	'folder-4': 'E28F',
	'folder-add': 'E290',
	'folder-attention': 'E291',
	'folder-block': 'E292',
	'folder-bookmark': 'E293',
	'folder-checked': 'E294',
	'folder-code': 'E295',
	'folder-delete': 'E296',
	'folder-download': 'E297',
	'folder-edit': 'E298',
	'folder-favorite': 'E299',
	'folder-home': 'E29A',
	'folder-image': 'E29B',
	'folder-lock': 'E29C',
	'folder-media': 'E29D',
	'folder-minus': 'E29E',
	'folder-music': 'E29F',
	'folder-new': 'E2A0',
	'folder-search': 'E2A1',
	'folder-setting': 'E2A2',
	'folder-share-1': 'E2A3',
	'folder-share-2': 'E2A4',
	'folder-sync': 'E2A5',
	'folder-transfer': 'E2A6',
	'folder-upload': 'E2A7',
	'folder-zip': 'E2A8',
	'add-1': 'E2A9',
	'add-2': 'E2AA',
	'add-3': 'E2AB',
	'add-4': 'E2AC',
	'add-tag': 'E2AD',
	'arrow-1': 'E2AE',
	'arrow-2': 'E2AF',
	'arrow-down-1': 'E2B0',
	'arrow-down-2': 'E2B1',
	'arrow-left-1': 'E2B2',
	'arrow-left-2': 'E2B3',
	'arrow-move-1': 'E2B4',
	'arrow-move-down': 'E2B5',
	'arrow-move-left': 'E2B6',
	'arrow-move-right': 'E2B7',
	'arrow-move-up': 'E2B8',
	'arrow-right-1': 'E2B9',
	'arrow-right-2': 'E2BA',
	'arrow-up-1': 'E2BB',
	'arrow-up-2': 'E2BC',
	back: 'E2BD',
	'center-expand': 'E2BE',
	'center-reduce': 'E2BF',
	'delete-1-1': 'E2C0',
	'delete-2-1': 'E2C1',
	'delete-3': 'E2C2',
	'delete-4': 'E2C3',
	'delete-tag': 'E2C4',
	'expand-horizontal': 'E2C5',
	'expand-vertical': 'E2C6',
	'forward-3': 'E2C7',
	infinity: 'E2C8',
	loading: 'E2C9',
	'log-out-1': 'E2CA',
	'log-out-2': 'E2CB',
	'loop-1': 'E2CC',
	'loop-2': 'E2CD',
	'loop-3': 'E2CE',
	'minus-1': 'E2CF',
	'minus-2': 'E2D0',
	'minus-3': 'E2D1',
	'minus-4': 'E2D2',
	'minus-tag': 'E2D3',
	'move-diagonal-1': 'E2D4',
	'move-diagonal-2': 'E2D5',
	'move-horizontal-1': 'E2D6',
	'move-horizontal-2': 'E2D7',
	'move-vertical-1': 'E2D8',
	'move-vertical-2': 'E2D9',
	'next-1-1': 'E2DA',
	'next-2-1': 'E2DB',
	'power-1-1': 'E2DC',
	'power-2-1': 'E2DD',
	'power-3': 'E2DE',
	'power-4': 'E2DF',
	recycle: 'E2E0',
	refresh: 'E2E1',
	repeat: 'E2E2',
	return: 'E2E3',
	'scale-all-1': 'E2E4',
	'scale-center': 'E2E5',
	'scale-horizontal-1': 'E2E6',
	'scale-horizontal-2': 'E2E7',
	'scale-reduce-1': 'E2E8',
	'scale-reduce-2': 'E2E9',
	'scale-reduce-3': 'E2EA',
	'scale-spread-1': 'E2EB',
	'scale-spread-2': 'E2EC',
	'scale-spread-3': 'E2ED',
	'scale-vertical-1': 'E2EE',
	'scale-vertical-2': 'E2EF',
	'scroll-horizontal-1': 'E2F0',
	'scroll-horizontal-2': 'E2F1',
	'scroll-omnidirectional-1': 'E2F2',
	'scroll-omnidirectional-2': 'E2F3',
	'scroll-vertical-1': 'E2F4',
	'scroll-vertical-2': 'E2F5',
	shuffle: 'E2F6',
	split: 'E2F7',
	'sync-1': 'E2F8',
	'sync-2': 'E2F9',
	timer: 'E2FA',
	transfer: 'E2FB',
	'transfer-1-1': 'E2FC',
	'check-1': 'E2FD',
	'check-2': 'E2FE',
	'check-3': 'E2FF',
	'check-box': 'E300',
	'check-bubble': 'E301',
	'check-circle-1': 'E302',
	'check-circle-2': 'E303',
	'check-list': 'E304',
	'check-shield': 'E305',
	cross: 'E306',
	'cross-bubble': 'E307',
	'cross-shield': 'E308',
	'briefcase-1': 'E309',
	'brightness-high': 'E30A',
	'brightness-low': 'E30B',
	'hammer-1': 'E30C',
	'hammer-2': 'E30D',
	pulse: 'E30E',
	scale: 'E30F',
	'screw-driver': 'E310',
	'setting-adjustment': 'E311',
	'setting-gear': 'E312',
	'setting-gears-1': 'E313',
	'setting-gears-2': 'E314',
	'setting-wrenches': 'E315',
	'switch-1': 'E316',
	'switch-2': 'E317',
	wrench: 'E318',
	'alarm-1': 'E319',
	'alarm-clock': 'E31A',
	'alarm-off': 'E31B',
	'alarm-snooze': 'E31C',
	bell: 'E31D',
	'calendar-1': 'E31E',
	'calendar-2': 'E31F',
	'clock-1': 'E320',
	'clock-2': 'E321',
	'clock-3': 'E322',
	'hourglass-1': 'E323',
	'hourglass-2': 'E324',
	'timer-1': 'E325',
	'timer-3-quarter-1': 'E326',
	'timer-3-quarter-2': 'E327',
	'timer-full-1': 'E328',
	'timer-full-2': 'E329',
	'timer-half-1': 'E32A',
	'timer-half-2': 'E32B',
	'timer-half-3': 'E32C',
	'timer-half-4': 'E32D',
	'timer-quarter-1': 'E32E',
	'timer-quarter-2': 'E32F',
	'watch-1': 'E330',
	'watch-2': 'E331',
	'alert-1': 'E332',
	'alert-2': 'E333',
	'alert-3': 'E334',
	information: 'E335',
	'nuclear-1': 'E336',
	'nuclear-2': 'E337',
	'question-mark': 'E338',
	abacus: 'E339',
	'amex-card': 'E33A',
	atm: 'E33B',
	balance: 'E33C',
	'bank-1': 'E33D',
	'bank-2': 'E33E',
	'bank-note-1': 'E33F',
	'bank-note-2': 'E340',
	'bank-note-3': 'E341',
	bitcoins: 'E342',
	board: 'E343',
	'box-1': 'E344',
	'box-2': 'E345',
	'box-3': 'E346',
	'box-download': 'E347',
	'box-shipping': 'E348',
	'box-upload': 'E349',
	'business-chart-1': 'E34A',
	'business-chart-2': 'E34B',
	'calculator-1': 'E34C',
	'calculator-2': 'E34D',
	'calculator-3': 'E34E',
	'cash-register': 'E34F',
	'chart-board': 'E350',
	'chart-down': 'E351',
	'chart-up': 'E352',
	check: 'E353',
	'coins-1': 'E354',
	'coins-2': 'E355',
	court: 'E356',
	'credit-card': 'E357',
	'credit-card-lock': 'E358',
	delivery: 'E359',
	'dollar-bag': 'E35A',
	'dollar-currency-1': 'E35B',
	'dollar-currency-2': 'E35C',
	'dollar-currency-3': 'E35D',
	'dollar-currency-4': 'E35E',
	'euro-bag': 'E35F',
	'euro-currency-1': 'E360',
	'euro-currency-2': 'E361',
	'euro-currency-3': 'E362',
	'euro-currency-4': 'E363',
	forklift: 'E364',
	'hand-card': 'E365',
	'hand-coin': 'E366',
	keynote: 'E367',
	'master-card': 'E368',
	money: 'E369',
	'parking-meter': 'E36A',
	'percent-1': 'E36B',
	'percent-2': 'E36C',
	'percent-3': 'E36D',
	'percent-4': 'E36E',
	'percent-5': 'E36F',
	'percent-up': 'E370',
	'pie-chart-1': 'E371',
	'pie-chart-2': 'E372',
	'piggy-bank': 'E373',
	'pound-currency-1': 'E374',
	'pound-currency-2': 'E375',
	'pound-currency-3': 'E376',
	'pound-currency-4': 'E377',
	'safe-1': 'E378',
	'safe-2': 'E379',
	shop: 'E37A',
	sign: 'E37B',
	trolley: 'E37C',
	'truck-1': 'E37D',
	'truck-2': 'E37E',
	'visa-card': 'E37F',
	'yen-currency-1': 'E380',
	'yen-currency-2': 'E381',
	'yen-currency-3': 'E382',
	'yen-currency-4': 'E383',
	'add-marker-1': 'E384',
	'add-marker-1-1': 'E385',
	'add-marker-2': 'E386',
	'add-marker-2-1': 'E387',
	'add-marker-3': 'E388',
	'compass-1': 'E389',
	'compass-2': 'E38A',
	'compass-3': 'E38B',
	'delete-marker-1': 'E38C',
	'delete-marker-1-1': 'E38D',
	'delete-marker-2': 'E38E',
	'delete-marker-2-1': 'E38F',
	'favorite-marker-1': 'E390',
	'favorite-marker-1-1': 'E391',
	'favorite-marker-2': 'E392',
	'favorite-marker-2-1': 'E393',
	globe: 'E394',
	location: 'E395',
	'map-1': 'E396',
	'map-location': 'E397',
	'map-marker-1': 'E398',
	'map-marker-1-1': 'E399',
	'map-marker-2': 'E39A',
	'map-marker-3': 'E39B',
	'map-marker-pin': 'E39C',
	'map-pin': 'E39D',
	'marker-1': 'E39E',
	'marker-1-1': 'E39F',
	'marker-2': 'E3A0',
	'marker-2-1': 'E3A1',
	'marker-pin-1': 'E3A2',
	'marker-pin-2': 'E3A3',
	'marker-pin-location': 'E3A4',
	'minus-marker-1': 'E3A5',
	'minus-marker-1-1': 'E3A6',
	'minus-marker-2': 'E3A7',
	'minus-marker-2-1': 'E3A8',
	'minus-marker-3': 'E3A9',
	anchor: 'E3AA',
	bank: 'E3AB',
	beach: 'E3AC',
	boat: 'E3AD',
	'building-1': 'E3AE',
	'building-2': 'E3AF',
	'building-3': 'E3B0',
	'buildings-1': 'E3B1',
	'buildings-2': 'E3B2',
	'buildings-3': 'E3B3',
	'buildings-4': 'E3B4',
	castle: 'E3B5',
	column: 'E3B6',
	'direction-sign': 'E3B7',
	factory: 'E3B8',
	fence: 'E3B9',
	garage: 'E3BA',
	'globe-1': 'E3BB',
	'globe-2': 'E3BC',
	'house-1': 'E3BD',
	'house-2': 'E3BE',
	'house-3': 'E3BF',
	'house-4': 'E3C0',
	library: 'E3C1',
	'light-house': 'E3C2',
	pisa: 'E3C3',
	skyscraper: 'E3C4',
	temple: 'E3C5',
	'treasure-map': 'E3C6',
	tree: 'E3C7',
	'tree-pine': 'E3C8',
	attention: 'E3C9',
	'bug-1': 'E3CA',
	'bug-2': 'E3CB',
	css3: 'E3CC',
	firewall: 'E3CD',
	html5: 'E3CE',
	'plugins-1': 'E3CF',
	'plugins-2': 'E3D0',
	script: 'E3D1',
	'new-window': 'E3D2',
	'window-1': 'E3D3',
	'window-2': 'E3D4',
	'window-3': 'E3D5',
	'window-add': 'E3D6',
	'window-alert': 'E3D7',
	'window-check': 'E3D8',
	'window-code-1': 'E3D9',
	'window-code-2': 'E3DA',
	'window-code-3': 'E3DB',
	'window-column': 'E3DC',
	'window-delete': 'E3DD',
	'window-denied': 'E3DE',
	'window-download-1': 'E3DF',
	'window-download-2': 'E3E0',
	'window-edit': 'E3E1',
	'window-favorite': 'E3E2',
	'window-graph-1': 'E3E3',
	'window-graph-2': 'E3E4',
	'window-hand': 'E3E5',
	'window-home': 'E3E6',
	'window-like': 'E3E7',
	'window-list-1': 'E3E8',
	'window-list-2': 'E3E9',
	'window-lock': 'E3EA',
	'window-minimize': 'E3EB',
	'window-minus': 'E3EC',
	'window-refresh': 'E3ED',
	'window-register': 'E3EE',
	'window-search': 'E3EF',
	'window-selection': 'E3F0',
	'window-setting': 'E3F1',
	'window-sync': 'E3F2',
	'window-thumbnails-1': 'E3F3',
	'window-thumbnails-2': 'E3F4',
	'window-time': 'E3F5',
	'window-upload-1': 'E3F6',
	'window-upload-2': 'E3F7',
	'windows-selection': 'E3F8',
	database: 'E3F9',
	'database-alert': 'E3FA',
	'database-block': 'E3FB',
	'database-check': 'E3FC',
	'database-delete': 'E3FD',
	'database-download': 'E3FE',
	'database-edit': 'E3FF',
	'database-lock': 'E400',
	'database-minus': 'E401',
	'database-network': 'E402',
	'database-plus': 'E403',
	'database-refresh': 'E404',
	'database-search': 'E405',
	'database-setting': 'E406',
	'database-sync': 'E407',
	'database-time': 'E408',
	'database-upload': 'E409',
	'battery-charging': 'E40A',
	'battery-full': 'E40B',
	'battery-high': 'E40C',
	'battery-low': 'E40D',
	'battery-medium': 'E40E',
	'cd-1': 'E40F',
	'cd-2': 'E410',
	chip: 'E411',
	computer: 'E412',
	disc: 'E413',
	filter: 'E414',
	'floppy-disk': 'E415',
	gameboy: 'E416',
	'harddisk-1': 'E417',
	'harddisk-2': 'E418',
	imac: 'E419',
	'ipad-1': 'E41A',
	'ipad-2': 'E41B',
	ipod: 'E41C',
	'joystick-1': 'E41D',
	'joystick-2': 'E41E',
	'joystick-3': 'E41F',
	'keyboard-1': 'E420',
	'keyboard-2': 'E421',
	'kindle-1': 'E422',
	'kindle-2': 'E423',
	'laptop-1': 'E424',
	'laptop-2': 'E425',
	'memory-card': 'E426',
	'mobile-phone': 'E427',
	'mouse-1': 'E428',
	'mouse-2': 'E429',
	mp3player: 'E42A',
	'plug-1': 'E42B',
	'plug-2': 'E42C',
	'plug-slot': 'E42D',
	printer: 'E42E',
	projector: 'E42F',
	remote: 'E430',
	router: 'E431',
	'screen-1': 'E432',
	'screen-2': 'E433',
	'screen-3': 'E434',
	'screen-4': 'E435',
	'smartphone-1': 'E436',
	'television-1': 'E437',
	'typewriter-1': 'E438',
	'typewriter-2': 'E439',
	'usb-1': 'E43A',
	'usb-2': 'E43B',
	webcam: 'E43C',
	'wireless-router-1': 'E43D',
	'wireless-router-2': 'E43E',
	bluetooth: 'E43F',
	ethernet: 'E440',
	'ethernet-slot': 'E441',
	'firewire-1': 'E442',
	'firewire-2': 'E443',
	'network-1': 'E444',
	'network-2': 'E445',
	'server-1': 'E446',
	'server-2': 'E447',
	'server-3': 'E448',
	usb: 'E449',
	'wireless-signal': 'E44A',
	'book-1': 'E44B',
	'book-2': 'E44C',
	'book-3': 'E44D',
	'book-4': 'E44E',
	'book-5': 'E44F',
	'book-6': 'E450',
	'book-7': 'E451',
	'book-8': 'E452',
	'book-download-1': 'E453',
	'book-download-2': 'E454',
	'book-favorite-1': 'E455',
	'book-favorite-2': 'E456',
	'bookmark-1-1': 'E457',
	'bookmark-2-1': 'E458',
	'bookmark-3-1': 'E459',
	'bookmark-4-1': 'E45A',
	'books-1': 'E45B',
	'books-2': 'E45C',
	'books-3': 'E45D',
	'briefcase-2': 'E45E',
	'contact-book-1': 'E45F',
	'contact-book-2': 'E460',
	'contact-book-3': 'E461',
	'contact-book-4': 'E462',
	copyright: 'E463',
	'creative-commons': 'E464',
	cube: 'E465',
	'data-filter': 'E466',
	'document-box-1': 'E467',
	'document-box-2': 'E468',
	'document-box-3': 'E469',
	'drawer-1': 'E46A',
	'drawer-2': 'E46B',
	'drawer-3': 'E46C',
	envelope: 'E46D',
	file: 'E46E',
	files: 'E46F',
	'filter-1': 'E470',
	'filter-2': 'E471',
	'layers-1': 'E472',
	'list-1': 'E473',
	'list-2': 'E474',
	'newspaper-1': 'E475',
	'newspaper-2': 'E476',
	'registry-1': 'E477',
	'registry-2': 'E478',
	'shield-1': 'E479',
	'shield-2': 'E47A',
	'shield-3': 'E47B',
	sketchbook: 'E47C',
	'sound-book': 'E47D',
	'thumbnails-1': 'E47E',
	'thumbnails-2': 'E47F',
	graph: 'E480',
	'hierarchy-1': 'E481',
	'hierarchy-2': 'E482',
	'hierarchy-3': 'E483',
	'hierarchy-4': 'E484',
	'hierarchy-5': 'E485',
	'hierarchy-6': 'E486',
	'hierarchy-7': 'E487',
	'network-1-1': 'E488',
	'network-2-1': 'E489',
	backpack: 'E48A',
	'balance-1': 'E48B',
	bed: 'E48C',
	bench: 'E48D',
	bricks: 'E48F',
	bullets: 'E490',
	buoy: 'E491',
	campfire: 'E492',
	can: 'E493',
	candle: 'E494',
	'cctv-1': 'E496',
	'cctv-2': 'E497',
	chair: 'E498',
	'chair-director': 'E499',
	cigarette: 'E49A',
	'construction-sign': 'E49B',
	diamond: 'E49C',
	disabled: 'E49D',
	door: 'E49E',
	drawer: 'E49F',
	driller: 'E4A0',
	dumbbells: 'E4A1',
	'fire-extinguisher': 'E4A2',
	flashlight: 'E4A3',
	'gas-station': 'E4A4',
	'lamp-1': 'E4A6',
	'lamp-2': 'E4A7',
	'lamp-3': 'E4A8',
	'lamp-4': 'E4A9',
	'lightbulb-1': 'E4AA',
	'lightbulb-2': 'E4AB',
	'measuring-tape': 'E4AC',
	'mine-cart': 'E4AD',
	ring: 'E4AF',
	'scale-1': 'E4B0',
	shovel: 'E4B1',
	'smoke-no': 'E4B2',
	'sofa-1': 'E4B3',
	'sofa-2': 'E4B4',
	'sofa-3': 'E4B5',
	target: 'E4B6',
	torch: 'E4B7',
	'traffic-cone': 'E4B8',
	'traffic-light-1': 'E4B9',
	'traffic-light-2': 'E4BA',
	'treasure-chest-1': 'E4BB',
	'treasure-chest-2': 'E4BC',
	trowel: 'E4BD',
	'watering-can': 'E4BE',
	weigh: 'E4BF',
	'academic-cap': 'E4C0',
	'baseball-helmet': 'E4C1',
	beanie: 'E4C2',
	'bike-helmet': 'E4C3',
	bow: 'E4C4',
	cap: 'E4C5',
	chaplin: 'E4C6',
	'chef-hat': 'E4C7',
	'cloth-hanger': 'E4C8',
	fins: 'E4C9',
	'football-helmet': 'E4CA',
	glasses: 'E4CB',
	'glasses-1': 'E4CC',
	'glasses-2': 'E4CD',
	'magician-hat': 'E4CE',
	'monocle-1': 'E4CF',
	'monocle-2': 'E4D0',
	necktie: 'E4D1',
	'safety-helmet': 'E4D2',
	'scuba-tank': 'E4D3',
	'shirt-1': 'E4D4',
	'shirt-2': 'E4D5',
	'shirt-3': 'E4D6',
	sneakers: 'E4D7',
	snorkel: 'E4D8',
	sombrero: 'E4D9',
	sunglasses: 'E4DA',
	'tall-hat': 'E4DB',
	trousers: 'E4DC',
	'walking-stick': 'E4DD',
	'arrow-redo': 'E4DE',
	'arrow-undo': 'E4DF',
	bold: 'E4E0',
	columns: 'E4E1',
	eraser: 'E4E2',
	'font-color': 'E4E3',
	html: 'E4E4',
	italic: 'E4E5',
	'list-1-1': 'E4E6',
	'list-2-1': 'E4E7',
	'list-3': 'E4E8',
	'list-4': 'E4E9',
	paragraph: 'E4EA',
	paste: 'E4EB',
	'print-preview': 'E4EC',
	quote: 'E4ED',
	strikethrough: 'E4EE',
	text: 'E4EF',
	'text-wrapping-1': 'E4F0',
	'text-wrapping-2': 'E4F1',
	'text-wrapping-3': 'E4F2',
	underline: 'E4F3',
	'align-center': 'E4F4',
	'align-left': 'E4F5',
	'align-right': 'E4F6',
	'all-caps': 'E4F7',
	'arrange-2-1': 'E4F8',
	'arrange-2-2': 'E4F9',
	'arrange-2-3': 'E4FA',
	'arrange-2-4': 'E4FB',
	'arrange-3-1': 'E4FC',
	'arrange-3-2': 'E4FD',
	'arrange-3-3': 'E4FE',
	'arrange-3-4': 'E4FF',
	'arrange-3-5': 'E500',
	'arrange-4-1': 'E501',
	'arrange-4-2': 'E502',
	'arrange-4-3': 'E503',
	'arrange-5': 'E504',
	'consolidate-all': 'E505',
	'decrease-indent-1': 'E506',
	'decrease-indent-2': 'E507',
	'horizontal-page': 'E508',
	'increase-indent-1': 'E509',
	'increase-indent-2': 'E50A',
	justify: 'E50B',
	'leading-1': 'E50C',
	'leading-2': 'E50D',
	'left-indent': 'E50E',
	'right-indent': 'E50F',
	'small-caps': 'E510',
	'vertical-page': 'E511',
	'alt-mac': 'E512',
	'alt-windows': 'E513',
	'arrow-down': 'E514',
	'arrow-down-left': 'E515',
	'arrow-down-right': 'E516',
	'arrow-left': 'E517',
	'arrow-right': 'E518',
	'arrow-up': 'E519',
	'arrow-up-left': 'E51A',
	'arrow-up-right': 'E51B',
	'asterisk-1': 'E51C',
	'asterisk-2': 'E51D',
	'back-tab-1': 'E51E',
	'back-tab-2': 'E51F',
	'backward-delete': 'E520',
	blank: 'E521',
	eject: 'E522',
	'enter-1': 'E523',
	'enter-2': 'E524',
	escape: 'E525',
	'page-down': 'E526',
	'page-up': 'E527',
	'return-1': 'E528',
	'shift-1': 'E529',
	'shift-2': 'E52A',
	tab: 'E52B',
	apple: 'E52C',
	beer: 'E52D',
	boil: 'E52E',
	'bottle-1': 'E52F',
	'bottle-2': 'E530',
	'bottle-3': 'E531',
	'bottle-4': 'E532',
	bread: 'E533',
	'burger-1': 'E534',
	'burger-2': 'E535',
	'cake-1': 'E536',
	'cake-2': 'E537',
	champagne: 'E538',
	cheese: 'E539',
	cocktail: 'E53A',
	'cocktail-1': 'E53B',
	'cocktail-2': 'E53C',
	'coffee-pot': 'E53D',
	cup1: 'E53E',
	'cup-2': 'E53F',
	'deep-fry': 'E540',
	'energy-drink': 'E541',
	'espresso-machine': 'E542',
	'food-dome': 'E543',
	'fork-and-knife': 'E544',
	'fork-and-spoon': 'E545',
	grape: 'E546',
	grater: 'E547',
	grill: 'E548',
	'hot-drinks-glass': 'E549',
	hotdog: 'E54A',
	'ice-cream-1': 'E54B',
	'ice-cream-2': 'E54C',
	'ice-cream-3': 'E54D',
	'ice-drinks-galss': 'E54E',
	juicer: 'E54F',
	'kitchen-timer': 'E550',
	milk: 'E551',
	orange: 'E552',
	oven: 'E553',
	'pan-fry': 'E554',
	'pepper-salt': 'E555',
	pizza: 'E556',
	popcorn: 'E557',
	serving: 'E558',
	soda: 'E559',
	'soda-can-1': 'E55A',
	'soda-can-2': 'E55B',
	steam: 'E55C',
	'tea-pot': 'E55D',
	'thermometer-high': 'E55E',
	'thermometer-low': 'E55F',
	'thermometer-medium': 'E560',
	water: 'E561',
	wine: 'E562',
	ambulance: 'E563',
	'beaker-1': 'E564',
	'beaker-2': 'E565',
	blood: 'E566',
	drug: 'E567',
	'first-aid': 'E568',
	heartpulse: 'E56A',
	'hospital-1': 'E56B',
	'hospital-2': 'E56C',
	'hospital-sign-1': 'E56D',
	'hospital-sign-2': 'E56E',
	'hospital-sign-3': 'E56F',
	medicine: 'E570',
	microscope: 'E571',
	'mortar-and-pestle': 'E572',
	plaster: 'E573',
	'pulse-graph-1': 'E574',
	'pulse-graph-2': 'E575',
	'pulse-graph-3': 'E576',
	'red-cross': 'E577',
	stethoscope: 'E578',
	syringe: 'E579',
	'yin-yang': 'E57A',
	balloon: 'E57B',
	'briefcase-lock': 'E57C',
	card: 'E57D',
	cards: 'E57E',
	'cards-2': 'E57F',
	curtain: 'E580',
	'dice-1': 'E581',
	'dice-2': 'E582',
	'happy-smiley': 'E583',
	pacman: 'E584',
	'pacman-ghost': 'E585',
	'sad-smiley': 'E586',
	'sign-1': 'E587',
	smileys: 'E588',
	'suitcase-1': 'E589',
	'suitcase-2': 'E58A',
	tetris: 'E58B',
	'ticket-1': 'E58C',
	'ticket-2': 'E58D',
	'ticket-3': 'E58E',
	virus: 'E58F',
	'cloud-1': 'E590',
	'cloud-lightning': 'E591',
	clouds: 'E592',
	'first-quarter-moon': 'E593',
	'full-moon': 'E594',
	hail: 'E595',
	'heavy-rain': 'E596',
	'moon-cloud': 'E597',
	rain: 'E598',
	'rain-lightning': 'E599',
	snow: 'E59A',
	sun: 'E59B',
	'sun-cloud': 'E59C',
	thermometer: 'E59D',
	'third-quarter-moon': 'E59E',
	umbrella: 'E59F',
	'waning-crescent-moon': 'E5A0',
	'waning-gibbous-moon': 'E5A1',
	'waxing-crescent-moon': 'E5A2',
	'waxing-gibbous-moon': 'E5A3',
	bicycle: 'E5A4',
	'bus-1': 'E5A5',
	'bus-2': 'E5A6',
	'car-1': 'E5A7',
	'car-2': 'E5A8',
	'car-3': 'E5A9',
	'car-4': 'E5AA',
	helicopter: 'E5AB',
	'mountain-bike': 'E5AC',
	pickup: 'E5AD',
	'plane-1': 'E5AE',
	'plane-2': 'E5AF',
	'plane-landing': 'E5B0',
	'plane-takeoff': 'E5B1',
	road: 'E5B2',
	'road-bike': 'E5B3',
	rocket: 'E5B4',
	scooter: 'E5B5',
	ship: 'E5B6',
	train: 'E5B7',
	tram: 'E5B8',
	cactus: 'E5B9',
	clover: 'E5BA',
	flower: 'E5BB',
	'hand-eco': 'E5BC',
	'hand-globe': 'E5BD',
	leaf: 'E5BE',
	'light-eco': 'E5BF',
	'potted-plant-1': 'E5C0',
	'potted-plant-2': 'E5C1',
	'2-fingers-double-tap': 'E5C2',
	'2-fingers-down-swipe': 'E5C3',
	'2-fingers-horizontal-swipe': 'E5C4',
	'2-fingers-left-swipe': 'E5C5',
	'2-fingers-omnidirectional-swipe': 'E5C6',
	'2-fingers-right-swipe': 'E5C7',
	'2-fingers-tab-hold': 'E5C8',
	'2-fingers-tap': 'E5C9',
	'2-fingers-up-swipe': 'E5CA',
	'2-fingers-vertical-swipe': 'E5CB',
	'double-tap': 'E5CC',
	'drag-down': 'E5CD',
	'drag-horizontal': 'E5CE',
	'drag-left': 'E5CF',
	'drag-right': 'E5D0',
	'drag-up': 'E5D1',
	'drag-vertical': 'E5D2',
	'filck-down': 'E5D3',
	'flick-up': 'E5D4',
	'horizontal-flick': 'E5D5',
	'left-flick': 'E5D6',
	'omnidirectional-drag': 'E5D7',
	'omnidirectional-flick': 'E5D8',
	'omnidirectional-swipe': 'E5D9',
	pinch: 'E5DA',
	'right-flick': 'E5DB',
	'rotate-clockwise': 'E5DC',
	'rotate-counterclockwise': 'E5DD',
	spread: 'E5DE',
	'swipe-down': 'E5DF',
	'swipe-horizontal': 'E5E0',
	'swipe-left': 'E5E1',
	'swipe-right': 'E5E2',
	'swipe-up': 'E5E3',
	'swipe-vertical': 'E5E4',
	tap: 'E5E5',
	'tap-hold': 'E5E6',
	'vertical-flick': 'E5E7',
	'arrow-1-1': 'E5E8',
	'arrow-2-1': 'E5E9',
	'arrow-3': 'E5EA',
	'arrow-4': 'E5EB',
	'arrow-5': 'E5EC',
	'arrow-6': 'E5ED',
	'arrow-7': 'E5EE',
	'arrow-8': 'E5EF',
	'arrow-9': 'E5F0',
	'arrow-10': 'E5F1',
	'arrow-11': 'E5F2',
	'arrow-12': 'E5F3',
	'arrow-13': 'E5F4',
	'arrow-14': 'E5F5',
	'arrow-15': 'E5F6',
	'arrow-16': 'E5F7',
	'arrow-17': 'E5F8',
	'arrow-18': 'E5F9',
	'arrow-19': 'E5FA',
	'arrow-20': 'E5FB',
	'arrow-21': 'E5FC',
	'arrow-22': 'E5FD',
	'arrow-23': 'E5FE',
	'arrow-24': 'E5FF',
	'arrow-25': 'E600',
	'arrow-26': 'E601',
	'arrow-27': 'E602',
	'arrow-28': 'E603',
	'arrow-29': 'E604',
	'arrow-30': 'E605',
	'arrow-31': 'E606',
	'arrow-32': 'E607',
	'arrow-33': 'E608',
	'arrow-34': 'E609',
	'arrow-35': 'E60A',
	'arrow-36': 'E60B',
	'arrow-37': 'E60C',
	'arrow-38': 'E60D',
	'arrow-39': 'E60E',
	'arrow-40': 'E60F',
	'arrow-41': 'E610',
	'arrow-42': 'E611',
	'arrow-43': 'E612',
	'arrow-44': 'E613',
	'arrow-45': 'E614',
	'arrow-46': 'E615',
	'arrow-47': 'E616',
	'arrow-48': 'E617',
	'arrow-49': 'E618',
	'arrow-50': 'E619',
	'arrow-51': 'E61A',
	'arrow-52': 'E61B',
	'arrow-53': 'E61C',
	'arrow-54': 'E61D',
	'arrow-55': 'E61E',
	'arrow-56': 'E61F',
	'arrow-57': 'E620',
	'arrow-58': 'E621',
	'arrow-59': 'E622',
	'arrow-60': 'E623',
	'arrow-61': 'E624',
	'arrow-62': 'E625',
	'arrow-63': 'E626',
	'arrow-64': 'E627',
	'arrow-65': 'E628',
	'arrow-66': 'E629',
	'arrow-67': 'E62A',
	'arrow-68': 'E62B',
	'arrow-69': 'E62C',
	'arrow-70': 'E62D',
	'arrow-71': 'E62E',
	'arrow-72': 'E62F',
	'arrow-circle-1': 'E630',
	'arrow-circle-2': 'E631',
	'arrow-circle-3': 'E632',
	'arrow-circle-4': 'E633',
	'arrow-circle-5': 'E634',
	'arrow-circle-6': 'E635',
	'arrow-circle-7': 'E636',
	'arrow-circle-8': 'E637',
	'arrow-circle-9': 'E638',
	'arrow-circle-10': 'E639',
	'arrow-circle-11': 'E63A',
	'arrow-circle-12': 'E63B',
	'arrow-circle-13': 'E63C',
	'arrow-circle-14': 'E63D',
	'arrow-circle-15': 'E63E',
	'arrow-circle-16': 'E63F',
	'arrow-circle-17': 'E640',
	'arrow-circle-18': 'E641',
	'arrow-circle-19': 'E642',
	'arrow-circle-20': 'E643',
	'arrow-circle-21': 'E644',
	'arrow-circle-22': 'E645',
	'arrow-circle-23': 'E646',
	'arrow-circle-24': 'E647',
	'arrow-circle-25': 'E648',
	'arrow-circle-26': 'E649',
	'arrow-circle-27': 'E64A',
	'arrow-circle-28': 'E64B',
	'arrow-circle-29': 'E64C',
	'arrow-circle-30': 'E64D',
	'arrow-delete-1': 'E64E',
	'arrow-delete-2': 'E64F',
	'arrow-dot-1': 'E650',
	'arrow-dot-2': 'E651',
	'arrow-dot-3': 'E652',
	'arrow-dot-4': 'E653',
	'arrow-dot-5': 'E654',
	'arrow-dot-6': 'E655',
	'arrow-rectangle-1': 'E656',
	'arrow-rectangle-2': 'E657',
	'arrow-rectangle-3': 'E658',
	'arrow-rectangle-4': 'E659',
	'arrow-rectangle-5': 'E65A',
	'arrow-rectangle-6': 'E65B',
	'arrow-rectangle-7': 'E65C',
	'arrow-rectangle-8': 'E65D',
	'arrow-rectangle-9': 'E65E',
	'arrow-rectangle-10': 'E65F',
	'arrow-rectangle-11': 'E660',
	'arrow-rectangle-12': 'E661',
	'arrow-rectangle-13': 'E662',
	'arrow-rectangle-14': 'E663',
	'arrow-rectangle-15': 'E664',
	'arrow-rectangle-16': 'E665',
	'arrow-rectangle-17': 'E666',
	'arrow-rectangle-18': 'E667',
	'arrow-rectangle-19': 'E668',
	'arrow-rectangle-20': 'E669',
	'custom-resi': 'E696',
};

export type ICON_NAME = keyof typeof CHAR_MAP;
export const ICON_NAME_LIST = Object.keys(CHAR_MAP) as ICON_NAME[];
