import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	floatingBar: {
		padding: `${theme.SPACING.SMALL} ${theme.SPACING.MEDIUM}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '476px',
		backgroundColor: theme.colors['background-surface'],
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px',
	},
	selectedText: {
		color: theme.colors['productBrand-700'],
		fontWeight: 600,
	},
}));
