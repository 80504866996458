import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { AccessDeniedError } from '@src/components/errors';
import { useMyOrganizations } from '@src/myContext';
import { combinePaths, generatePath, ROUTE_PATHS } from '@src/router';

export const getAppDesignPath = (organizationKey: string) =>
	generatePath(combinePaths(ROUTE_PATHS.ORGANIZATION_ROOT, ROUTE_PATHS.APP), {
		organizationKey,
	});

export const useAppDesignPath = (organizationKey: string, ...paths: string[]) => {
	const [searchParams] = useSearchParams();
	const basePath = combinePaths(getAppDesignPath(organizationKey), ...paths);

	return `${basePath}?${searchParams.toString()}`;
};

export const useAppDesignNavigate = (organizationKey: string) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const appDesignPath = getAppDesignPath(organizationKey);

	return (...paths: string[]) => {
		const basePath = combinePaths(appDesignPath, ...paths);
		navigate(`${basePath}?${searchParams.toString()}`);
	};
};

export const useRootPath = (organizationKey: string, ...paths: string[]) => {
	const [searchParams] = useSearchParams();
	const rootPath = generatePath(combinePaths(ROUTE_PATHS.ORGANIZATION_ROOT, ...paths), {
		organizationKey,
	});

	return `${rootPath}?${searchParams.toString()}`;
};

export const RedirectToOrganization = ({ organizationKey }: { organizationKey?: string }) => {
	const orgs = useMyOrganizations();
	const [searchParams] = useSearchParams();

	const currentOrg = orgs.find(org => org.organizationKey === organizationKey) || orgs[0];

	if (!currentOrg) {
		return <AccessDeniedError />;
	}

	const redirectTo = `${getAppDesignPath(currentOrg.organizationKey)}?${searchParams.toString()}`;

	return <Navigate to={redirectTo} replace />;
};
