import { AttributeTypename, Draggable } from '../types';

export const addAttributeCampus: Draggable = {
	icon: 'column',
	label: 'Campus',
	name: 'add_attribute_campus',
	actions: [
		{
			action_type: 'create',
			model: 'attribute',
			data: {
				type: AttributeTypename.Campus,
			},
		},
	],
	unique: true,
	type: 'attribute',
};

export const addAttributeFeedEvents: Draggable = {
	icon: 'custom-events-feed',
	label: 'iCal feed',
	name: 'add_attribute_feed_events',
	actions: [
		{
			action_type: 'create',
			model: 'attribute',
			data: {
				type: AttributeTypename.IcalFeed,
				bos: {
					feedType: 'ical',
					feedProperties: {
						actionBarArray: ['Save event', 'Share'],
						itemTemplate: 'detail-plain',
						shareProperties: ['Description', 'Save event', 'Address'],
					},
				},
			},
		},
	],
	unique: true,
	type: 'attribute',
};

export const addAttributeFeedRSS: Draggable = {
	icon: 'rss-2',
	label: 'RSS feed',
	name: 'add_attribute_feed',
	actions: [
		{
			action_type: 'create',
			model: 'attribute',
			data: {
				type: AttributeTypename.RssFeed,
				bos: {
					feedType: 'rss',
					feedProperties: null,
					feedURL: null,
				},
			},
		},
	],
	unique: true,
	type: 'attribute',
};

export const addAttributeFeedYouTube: Draggable = {
	icon: 'custom-youtube',
	label: 'YouTube feed',
	actions: [
		{
			action_type: 'create',
			model: 'attribute',
			data: {
				type: AttributeTypename.YouTubeFeed,
				bos: {
					feedType: 'youtube',
					feedURL: 'https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails,snippet,status',
					feedProperties: { playlistId: 'INSERT PLAYLIST ID HERE' },
				},
			},
		},
	],
	name: 'add_attribute_feed_youtube',
	unique: true,
	type: 'attribute',
};

export const addAttributeFeedVimeo: Draggable = {
	icon: 'custom-vimeo',
	label: 'Vimeo feed',
	actions: [
		{
			action_type: 'create',
			model: 'attribute',
			data: {
				type: AttributeTypename.VimeoFeed,
				bos: {
					feedType: 'vimeo',
					feedProperties: {
						actionBarArray: ['Video', 'Share'],
						itemTemplate: 'detail-full-no-overlay',
						shareProperties: ['Video'],
					},
					vimeoFeedIdentifiers: {
						feedType: 'showcase',
					},
				},
			},
		},
	],
	name: 'add_attribute_feed_vimeo',
	unique: true,
	type: 'attribute',
};
