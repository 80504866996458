import { FC, PropsWithChildren } from 'react';

import { Button } from '@pushpay/button';
import { RemoveIcon } from '@pushpay/iconography';
import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	pill: {
		border: `1px solid ${theme.colors['grey-400']}`,
		borderRadius: '16.5px',
		height: '34px',
		backgroundColor: theme.colors['background-surface'],
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,

		'&:hover': {
			backgroundColor: theme.colors['grey-200'],
		},
		marginRight: theme.SPACING.XSMALL,
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: multiply(theme.SPACING.XSMALL, 1.5),
		paddingRight: theme.SPACING.XSMALL,
	},
	icon: {
		fontSize: theme.SPACING.MEDIUM_ICON_SIZE,
		color: theme.colors['grey-900'],
		marginLeft: theme.SPACING.SMALL,
	},
}));

type PillButtonProps = {
	onClick: () => void;
};

export const PillButton: FC<PropsWithChildren<PillButtonProps>> = ({ onClick, children }) => {
	const classes = useStyles(undefined);

	return (
		<Button className={classes.pill} type="button" onClick={onClick}>
			<div className={classes.content}>
				{children}
				<RemoveIcon className={classes.icon} />
			</div>
		</Button>
	);
};
