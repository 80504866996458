import { useCallback, useState } from 'react';

import { Button } from '@pushpay/button';
import { FormState, useMutateField } from '@pushpay/forms';
import { LibrarySearchIcon } from '@pushpay/iconography';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { IconPicker } from '@src/components/iconPicker';
import { TextWithIconField } from '@src/components/textWithIconField';
import { SaveContainerSettingsSchema, SaveItemSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

type IconFieldProps = ComponentProps<
	{
		field: FormState<SaveContainerSettingsSchema | SaveItemSettingsSchema>['input']['icon'];
		isReadOnly: boolean;
	},
	typeof useStyles
>;

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'flex-end',
		position: 'relative',
	},
	iconTooltip: {
		position: 'absolute',
		height: '24px',
		right: '18px',
		bottom: '9px',
	},
	searchIcon: {
		width: '27px',
		height: '24px',
	},
	field: {
		marginRight: theme.SPACING.SMALL,
	},
	fieldRoot: {
		flexGrow: 1,
		width: '100%',
		paddingRight: theme.SPACING.XSMALL,
	},
	fieldWrapper: {
		marginRight: theme.SPACING.XLARGE,
	},
	label: {},
}));

export const IconField = ({ classes: classesProp, field, isReadOnly }: IconFieldProps) => {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const { mutateField: mutateIconField } = useMutateField(field);
	const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);
	const openIconPicker = useCallback(() => setIsIconPickerOpen(true), []);
	const dismissIconPicker = useCallback(() => setIsIconPickerOpen(false), []);
	const handleIconSelect = useCallback(
		(input: string) => {
			mutateIconField(input);
			dismissIconPicker();
		},
		[dismissIconPicker, mutateIconField]
	);

	return (
		<div className={classes.root}>
			<TextWithIconField
				classes={{
					field: clsx(!isReadOnly && classes.field),
					label: classes.label,
					root: classes.fieldRoot,
					wrapper: clsx(!isReadOnly && classes.fieldWrapper),
				}}
				field={field}
				label={translate('settings.container.icon')}
				readOnly={isReadOnly}
				tooltip={(isReadOnly && translate('settings.container.disabledIconTooltip')) || ''}
				showLabel
			/>
			{!isReadOnly && (
				<Tooltip
					classes={{ root: classes.iconTooltip }}
					content={translate('settings.container.iconTooltip')}
					data-pp-at-target="tooltip"
					placement="top"
				>
					<Button
						aria-label={translate('settings.container.iconTooltip')}
						data-pp-at-target="search-icon-button"
						type="button"
						onClick={openIconPicker}
					>
						<LibrarySearchIcon className={classes.searchIcon} />
					</Button>
				</Tooltip>
			)}
			<IconPicker
				data-pp-at-target="icon-picker"
				isOpen={isIconPickerOpen}
				onClickCancelButton={dismissIconPicker}
				onClickCloseButton={dismissIconPicker}
				onClickSelectButton={handleIconSelect}
			/>
		</div>
	);
};
