import { useId } from 'react';

import Expand from '@pushpay/expand';
import { InfoIcon } from '@pushpay/iconography';
import { Switch } from '@pushpay/inputs';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

import useStyles from './expandableAttributesLegacyStyles';

export type ExpandableAttributesProps = ComponentProps<
	{
		title?: string;
		toggleName: string;
		tooltipContent: string;
		disabled: boolean;
		canBeFeatured?: boolean;
		isFeatured?: boolean;
		onIsFeaturedChange?: (isFeatured: boolean) => void;
		featureSwitchOn?: boolean;
		expand?: boolean;
		onExpandChange?: (expand: boolean) => void;
	},
	typeof useStyles
>;

export const ExpandableAttributesLegacy = ({
	title,
	toggleName,
	disabled,
	tooltipContent,
	canBeFeatured,
	isFeatured,
	onIsFeaturedChange,
	classes: classesProp,
	children,
	expand,
	onExpandChange,
	'data-pp-at-target': targetId,
}: ExpandableAttributesProps) => {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const handleFeatureToggle = (value: boolean) => {
		if (onIsFeaturedChange) {
			onIsFeaturedChange(value);
		}
	};
	const handleExpandToggle = (value: boolean) => {
		if (onExpandChange) {
			onExpandChange(value);
		}
	};

	return (
		<div className={classes.root}>
			{title && <div className={classes.title}>{title}</div>}
			<div className={classes.card}>
				<div className={classes.header}>
					<div className={classes.actions}>
						<Switch
							checked={expand}
							classes={{
								root: classes.switchRoot,
								wrapper: classes.toggle,
								switch: classes.switch,
							}}
							data-pp-at-target={`${targetId}-toggle-${expand ? 'on' : 'off'}`}
							disabled={disabled}
							id={useId()}
							onChange={handleExpandToggle}
						>
							{tooltipContent && (
								<Tooltip
									content={tooltipContent}
									data-pp-at-target={`${targetId}-toggle-tooltip`}
									panelSpace="XXSMALL"
									placement="top"
								>
									<InfoIcon className={classes.icon} data-pp-at-target={`${targetId}-tip-icon`} />
								</Tooltip>
							)}
							{toggleName}
						</Switch>
						{canBeFeatured && (
							<Switch
								checked={isFeatured}
								classes={{
									root: classes.switchRoot,
									wrapper: classes.toggle,
									switch: classes.switch,
								}}
								data-pp-at-target={`${targetId}-featured-toggle`}
								disabled={disabled}
								id="featured-toggle"
								onChange={handleFeatureToggle}
							>
								<Tooltip
									content={translate('settings.featuredTooltip')}
									data-pp-at-target={`${targetId}-toggle-tooltip`}
									panelSpace="XXSMALL"
									placement="top"
								>
									<InfoIcon className={classes.icon} data-pp-at-target={`${targetId}-tip-icon`} />
								</Tooltip>
								{translate('settings.featured')}
							</Switch>
						)}
					</div>
				</div>

				<Expand.Panel
					data-pp-at-target={`${targetId}-expand-panel`}
					expandStatus={expand ? 'expanded' : 'collapsed'}
					overflowVisible
				>
					<div className={classes.body}>{children}</div>
				</Expand.Panel>
			</div>
		</div>
	);
};
