import { Fragment, memo, useRef } from 'react';

import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { ICON_NAME } from '@src/components/icon';

import { IconComponent } from './IconComponent';
import { useIconPickerReactVirtual } from './useIconPickerReactVirtual';

const useStyles = createUseStyles({
	root: {
		position: 'relative',
		overflowY: 'auto',
		overflowX: 'hidden',
		height: '50vh',
		width: '50vw',
		padding: '8px 16px',
	},
	iconItem: {
		position: 'absolute',
		top: 0,
		left: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

type IconContainerProps = {
	selectedIcon: ICON_NAME | undefined;
	searchResults: ICON_NAME[];
	onSelect: (icon: ICON_NAME) => void;
};

export const IconContainer = memo(
	({
		selectedIcon,
		searchResults,
		onSelect,
		classes: classesProp,
		className: classNameProp,
		'data-pp-at-target': targetId,
	}: ComponentProps<IconContainerProps, typeof useStyles>) => {
		const classes = useStyles(classesProp);
		const className = clsx(classes.root, classNameProp);
		const parentRef = useRef<HTMLDivElement>(null);
		const { rowVirtualizer, columnVirtualizer, columnCount } = useIconPickerReactVirtual(parentRef, searchResults);
		return (
			<div ref={parentRef} className={className} data-pp-at-target={`${targetId}-scroll`}>
				{rowVirtualizer.getVirtualItems().map(virtualRow => (
					<Fragment key={virtualRow.index}>
						{columnVirtualizer.getVirtualItems().map(virtualColumn => {
							const itemIndex = virtualRow.index * columnCount + virtualColumn.index;
							return (
								<div
									key={itemIndex}
									className={classes.iconItem}
									style={{
										width: `${virtualColumn.size}px`,
										height: `${virtualRow.size}px`,
										transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
									}}
								>
									{searchResults[itemIndex] && (
										<IconComponent
											key={searchResults[itemIndex]}
											data-pp-at-target={targetId}
											iconName={searchResults[itemIndex]}
											selectedIcon={selectedIcon}
											onSelect={onSelect}
										/>
									)}
								</div>
							);
						})}
					</Fragment>
				))}
			</div>
		);
	}
);
