import { Button } from '@pushpay/button';
import { StackItem } from '@pushpay/layout';
import { ComponentProps } from '@pushpay/types';

import { Group, SettingNumberInput, SettingSwitch } from '../components';
import { useTestWidgetSettings } from '../useTestWidgetSettings';

export const ServiceAvailabilityGroup = ({ 'data-pp-at-target': targetId }: ComponentProps<unknown, undefined>) => {
	const [{ delay, 'mobile-application-studio': appStudio, identity, organization }, dispatch] =
		useTestWidgetSettings('serviceAvailability');

	return (
		<Group data-pp-at-target={targetId} groupName="Service Availability">
			<StackItem alignH="right">
				<Button
					data-pp-at-target={`${targetId}-reset`}
					displaySize="small"
					displayStyle="text"
					type="button"
					onClick={() => dispatch({ type: 'serviceAvailabilityReset' })}
				>
					Reset
				</Button>
			</StackItem>
			<SettingNumberInput
				data-pp-at-target={`${targetId}-delay`}
				max={10000}
				value={delay}
				onChange={(newDelay: number | null) => dispatch({ type: 'serviceAvailability', delay: newDelay })}
			>
				Delay (ms)
			</SettingNumberInput>
			<SettingSwitch
				checked={appStudio}
				data-pp-at-target={`${targetId}-app-studio`}
				id={`${targetId}-app-studio`}
				onChange={available =>
					dispatch({ type: 'serviceAvailability', 'mobile-application-studio': available })
				}
			>
				App Studio API
			</SettingSwitch>
			<SettingSwitch
				checked={identity}
				data-pp-at-target={`${targetId}-identity`}
				id={`${targetId}-identity`}
				onChange={available => dispatch({ type: 'serviceAvailability', identity: available })}
			>
				Identity service
			</SettingSwitch>
			<SettingSwitch
				checked={organization}
				data-pp-at-target={`${targetId}-organization`}
				id={`${targetId}-organization`}
				onChange={available => dispatch({ type: 'serviceAvailability', organization: available })}
			>
				Organization service
			</SettingSwitch>
		</Group>
	);
};
