import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

type ScrollElementIntoViewContext = {
	elementId?: string;
	setElementId: Dispatch<SetStateAction<string | undefined>>;
};

const ScrollElementIntoViewContext = createContext<ScrollElementIntoViewContext | null>(null);
ScrollElementIntoViewContext.displayName = 'ScrollElementIntoViewContext';

export function ScrollElementIntoViewProvider({ children }: PropsWithChildren) {
	const [elementId, setElementId] = useState<string>();

	return (
		<ScrollElementIntoViewContext.Provider value={{ elementId, setElementId }}>
			{children}
		</ScrollElementIntoViewContext.Provider>
	);
}

export function useScrollElementIntoViewState(): { elementId?: string } {
	const context = useContext(ScrollElementIntoViewContext);

	if (!context) {
		throw new Error('useScrollElementIntoView must be used within a ScrollElementIntoViewProvider');
	}

	const { elementId } = context;

	return { elementId };
}

export function useScrollElementIntoViewActions() {
	const context = useContext(ScrollElementIntoViewContext);

	if (!context) {
		throw new Error('useScrollElementIntoView must be used within a ScrollElementIntoViewProvider');
	}

	const { setElementId } = context;

	const setElementIdToScrollTo = (elementId: string) => {
		setElementId(elementId);
	};

	const clearElementId = () => {
		setElementId(undefined);
	};

	return { setElementIdToScrollTo, clearElementId };
}
