import { useId } from 'react';

import { DropdownAlignment } from '@pushpay/dropdown';
import Icon from '@pushpay/icon';
import { Select, Option } from '@pushpay/inputs';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

import { useStyles } from './selectorStyles';

export type SelectorProps<T> = ComponentProps<
	{
		options: Option<T>[];
		value: T;
		onChange: (val: T) => void;
		disabled?: boolean;
		icon?: typeof Icon;
		dropdownAlignment?: DropdownAlignment;
		sortOrder?: 'asc' | 'desc';
		showSearch?: boolean;
		getOptionKey?: (val: Option<T>) => string;
	},
	typeof useStyles
>;

export const Selector = <T,>(props: SelectorProps<T>) => {
	const {
		options: optionsProp,
		value,
		onChange,
		icon: SelectorIcon,
		classes: classesProp,
		'data-pp-at-target': targetId,
		disabled = false,
		dropdownAlignment,
		sortOrder,
		showSearch,
		getOptionKey,
	} = props;

	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');

	const renderSelection = (option?: Option<T>) => (
		<div className={classes.contentContainer}>
			{SelectorIcon && (
				<div className={classes.iconContainer}>
					<SelectorIcon data-pp-at-target={`${targetId}-icon`} displaySize="large" />
				</div>
			)}
			<div title={option?.display}>{option?.display}</div>
		</div>
	);

	const renderOption = (option: Option<T>) => (
		<div className={classes.option} title={option.display}>
			{option.display}
		</div>
	);

	const options = sortOrder
		? optionsProp.sort((a, b) =>
				sortOrder === 'asc' ? a.display.localeCompare(b.display) : b.display.localeCompare(a.display)
		  )
		: optionsProp;

	return (
		<Select
			classes={{
				container: classes.container,
				root: classes.root,
				selection: classes.selection,
				summary: classes.dropdownSummary,
				overlay: classes.overlay,
				searchInput: classes.searchInput,
			}}
			data-pp-at-target={targetId}
			disabled={disabled}
			dropdownAlignment={dropdownAlignment}
			getOptionKey={getOptionKey}
			id={useId()}
			options={options}
			renderOption={renderOption}
			renderSelection={renderSelection}
			searchPlaceholder={translate('selectors.searchPlaceholder')}
			showSearch={showSearch}
			value={value}
			onChange={onChange}
		/>
	);
};
