import { $isLinkNode } from '@lexical/link';
import { addClassNamesToElement, isHTMLElement } from '@lexical/utils';
import type {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	GridSelection,
	LexicalNode,
	NodeKey,
	NodeSelection,
	RangeSelection,
	SerializedElementNode,
} from 'lexical';
import { $applyNodeReplacement, $getSelection, $isElementNode, $isRangeSelection, ElementNode, Spread } from 'lexical';

export const BLANKIFY = 'blankify';

export type SerializedBlankifyNode = Spread<
	{
		id: string;
		className: typeof BLANKIFY;
	},
	SerializedElementNode
>;

function isBlankifyElement(x: Node): x is HTMLSpanElement {
	return isHTMLElement(x) && x.tagName === 'SPAN' && x.classList.contains(BLANKIFY);
}

function convertBlankifyElement(domNode: Node): DOMConversionOutput {
	let node = null;
	if (isBlankifyElement(domNode)) {
		const content = domNode.textContent;
		if (content !== null && content !== '') {
			node = $createBlankifyNode(domNode.getAttribute('id') || undefined);
		}
	}
	return { node };
}

export class BlankifyNode extends ElementNode {
	__id: string;

	constructor(id?: string, key?: NodeKey) {
		super(key);
		this.__id = id || `${new Date().getTime()}-${Math.round(Math.random() * 1000000)}`;
	}

	static getType(): string {
		return BLANKIFY;
	}

	static clone(node: BlankifyNode): BlankifyNode {
		return new BlankifyNode(node.__key);
	}

	createDOM(): HTMLSpanElement {
		const element = document.createElement('span');
		element.id = this.__id;

		addClassNamesToElement(element, BLANKIFY);

		return element;
	}

	exportDOM(): DOMExportOutput {
		const element = document.createElement('span');
		element.id = this.__id;
		element.textContent = this.__text;

		addClassNamesToElement(element, BLANKIFY);

		return { element };
	}

	updateDOM(prevNode: BlankifyNode, span: HTMLSpanElement): boolean {
		const id = this.__id;

		if (id !== prevNode.__id) {
			if (id) {
				span.setAttribute('id', id);
			} else {
				span.removeAttribute('id');
			}
		}
		return false;
	}

	static importDOM(): DOMConversionMap | null {
		return {
			span: (domNode: HTMLElement) => {
				if (!domNode.classList.contains(BLANKIFY)) {
					return null;
				}
				return {
					conversion: convertBlankifyElement,
					priority: 1,
				};
			},
		};
	}

	static importJSON(serializedNode: SerializedBlankifyNode): BlankifyNode {
		const node = $createBlankifyNode();
		node.setFormat(serializedNode.format);
		node.setIndent(serializedNode.indent);
		node.setDirection(serializedNode.direction);

		return node;
	}

	exportJSON(): SerializedBlankifyNode {
		return {
			...super.exportJSON(),
			id: this.getLatest().__id,
			type: BLANKIFY,
			className: BLANKIFY,
			version: 1,
		};
	}

	insertNewAfter(selection: RangeSelection, restoreSelection = true): null | ElementNode {
		const element = this.getParentOrThrow().insertNewAfter(selection, restoreSelection);
		if ($isElementNode(element)) {
			const blankifyNode = $createBlankifyNode();
			element.append(blankifyNode);
			return blankifyNode;
		}
		return null;
	}

	static canInsertTextBefore(): true {
		return true;
	}

	static canInsertTextAfter(): false {
		return false;
	}

	static canBeEmpty(): false {
		return false;
	}

	static isInline(): true {
		return true;
	}

	extractWithChild(child: LexicalNode, selection: RangeSelection | NodeSelection | GridSelection): boolean {
		if (!$isRangeSelection(selection)) {
			return false;
		}

		const anchorNode = selection.anchor.getNode();
		const focusNode = selection.focus.getNode();

		return this.isParentOf(anchorNode) && this.isParentOf(focusNode) && selection.getTextContent().length > 0;
	}
}

function $createBlankifyNode(id?: string): BlankifyNode {
	return $applyNodeReplacement(new BlankifyNode(id));
}

export function $isBlankifyNode(node: LexicalNode | null | undefined): node is BlankifyNode {
	return node instanceof BlankifyNode;
}

function $getAncestor<NodeType extends LexicalNode = LexicalNode>(
	node: LexicalNode,
	predicate: (ancestor: LexicalNode) => ancestor is NodeType
): null | LexicalNode {
	let parent: null | LexicalNode = node;

	while (parent !== null && !predicate(parent)) {
		parent = parent.getParent();
	}

	return parent;
}

function $getBlankifyAncestor(node: LexicalNode): null | LexicalNode {
	return $getAncestor(node, $isBlankifyNode);
}

export function insertBlankify(): void {
	const selection = $getSelection();

	if (!$isRangeSelection(selection)) {
		return;
	}

	const nodes = selection.extract();

	if (nodes.length === 1) {
		const firstNode = nodes[0];
		const blankifyNode = $isBlankifyNode(firstNode) ? firstNode : $getBlankifyAncestor(firstNode);

		if (blankifyNode !== null) {
			return;
		}
	}

	const prevParent: ElementNode | BlankifyNode | null = null;
	let blankifyNode: BlankifyNode | null = null;

	nodes.forEach(node => {
		const parent = node.getParent();
		const isLinkNode = $isLinkNode(node) || $isLinkNode(parent);

		if (parent === blankifyNode || parent === null || $isBlankifyNode(parent) || isLinkNode) {
			return;
		}

		if ($isElementNode(node) && !node.isInline()) {
			if (selection.getNodes().length <= 1) {
				blankifyNode = $createBlankifyNode();

				const newNode = node.createParentElementNode();
				newNode.append(blankifyNode);

				selection.insertNodes([newNode]);
			}

			return;
		}

		if (!parent.is(prevParent) && !$isBlankifyNode(parent)) {
			blankifyNode = $createBlankifyNode();
			node.insertBefore(blankifyNode);
		}

		if (blankifyNode !== null) {
			blankifyNode.append(node);
		}
	});
}

export function removeBlankify(): void {
	const selection = $getSelection();

	if (!$isRangeSelection(selection)) {
		return;
	}
	const nodes = selection.extract();

	nodes.forEach(node => {
		const parent = node.getParent();

		if ($isBlankifyNode(parent)) {
			const children = parent.getChildren();

			for (let i = 0; i < children.length; i++) {
				parent.insertBefore(children[i]);
			}

			parent.remove();
		} else {
			const blankifyNode = $getBlankifyAncestor(node);

			if (blankifyNode !== null) {
				blankifyNode.remove();
			}
		}
	});
}
