import { useCallback } from 'react';

import { getDynamicFields, getDynamicFieldsItems, updateDynamicFieldsPaths } from '@pushpay/forms';
import { FieldPath, FormState } from '@pushpay/forms/lib/types/types';
import { set, assign } from '@pushpay/utils';

import { useFormContext } from '@src/context';
import { SaveItemSettingsSchema } from '@src/graphql/generated';
import { ItemPropertiesFields } from '@src/pages/itemSettings/types';
import { usePendo } from '@src/pendo';

import {
	type DynamicPropertyFields,
	alignPositionsSequentially,
	alignPositionsSequentiallyBeforeDeletion,
	findPropertyIndexById,
	reorderPositions,
	insertPropertyBeforeId,
} from './helpers';

type PropertyValues = Record<string, any>;
export type AddProperty = (
	newPropertyValues: PropertyValues,
	newId: string,
	insertBeforeId: string | undefined,
	name: string
) => void;
type UpdatePropertyPositions = (fromPropertyId: string, toPropertyId: string) => void;
type RemoveProperty = (propertyId: string) => void;

export function useMutateProperties(
	dynamicPropertyFields: DynamicPropertyFields,
	itemTemplate: (index: number, initialData?: PropertyValues) => PropertyValues,
	propertiesFieldsPath: FieldPath
) {
	const {
		formContext: { setFormState },
	} = useFormContext();
	const { pendoTrackEvent } = usePendo();

	const onAddPropertyHandler: AddProperty = useCallback(
		(newPropertyValues, newId, insertBeforeId, name) => {
			setFormState((currentFormState: FormState<SaveItemSettingsSchema>) => {
				if (!newId || newId === insertBeforeId) return currentFormState;

				const index = dynamicPropertyFields.length;
				const newProperty = itemTemplate(index, newPropertyValues) as ItemPropertiesFields[number];
				const newItemPath = propertiesFieldsPath.concat(index);
				const newFormState = assign(currentFormState, newItemPath, newProperty);

				pendoTrackEvent('Property draggable dropped', {
					draggableType: 'property',
					draggableName: name,
					itemName: newFormState.input.name.value,
					itemType: newFormState.input.type.value,
				});

				if (!insertBeforeId) {
					return alignPositionsSequentially(dynamicPropertyFields, newFormState);
				}

				const newPropertyFields = insertPropertyBeforeId(dynamicPropertyFields, newProperty, insertBeforeId);

				return alignPositionsSequentially(newPropertyFields, newFormState);
			});
		},
		[setFormState, dynamicPropertyFields, itemTemplate, propertiesFieldsPath, pendoTrackEvent]
	);

	const onUpdatePropertyPositionsHandler: UpdatePropertyPositions = useCallback(
		(fromPropertyId, toPropertyId) => {
			setFormState((currentFormState: FormState<SaveItemSettingsSchema>) => {
				if (!fromPropertyId || !toPropertyId || fromPropertyId === toPropertyId) return currentFormState;

				const newPropertyFields = reorderPositions(dynamicPropertyFields, fromPropertyId, toPropertyId);

				return alignPositionsSequentially(newPropertyFields, currentFormState);
			});
		},
		[dynamicPropertyFields, setFormState]
	);

	const onRemovePropertyHandler: RemoveProperty = useCallback(
		propertyId => {
			setFormState((currentFormState: FormState<SaveItemSettingsSchema>) => {
				const newFormState = alignPositionsSequentiallyBeforeDeletion(
					dynamicPropertyFields,
					currentFormState,
					propertyId
				);

				const currentDynamicFields = getDynamicFields(newFormState, propertiesFieldsPath);
				const propertyFields = getDynamicFieldsItems<ItemPropertiesFields[number]>(currentDynamicFields);
				const indexToDelete = findPropertyIndexById(propertyFields, propertyId);

				if (indexToDelete !== -1) {
					const { [indexToDelete]: removed, ...rest } = currentDynamicFields;

					return set(
						currentFormState,
						propertiesFieldsPath,
						updateDynamicFieldsPaths(rest, propertiesFieldsPath)
					);
				}

				return currentFormState;
			});
		},
		[setFormState, dynamicPropertyFields, propertiesFieldsPath]
	);

	return { onAddPropertyHandler, onUpdatePropertyPositionsHandler, onRemovePropertyHandler };
}
