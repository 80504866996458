import { useId } from 'react';

import { Field, FormState, PhoneNumberField } from '@pushpay/forms';
import { Columns, Column } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { SmsPropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, LabelWrapper, TextFieldWrapper } from '../wrappers';

const useStyles = createUseStyles((theme: Theme) => ({
	noMargin: {
		margin: 0,
	},
	noPadding: {
		padding: 0,
	},
	labelWrapper: {
		marginBottom: theme.SPACING.XSMALL,
	},
}));

export type SmsProps = ComponentProps<
	{
		propertyField: FormState<SmsPropertyInput>;
	},
	typeof undefined
>;

export const Sms = ({ propertyField, 'data-pp-at-target': targetId }: SmsProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyles(undefined);

	const { messageBody, messageLabel, phoneNumber } = propertyField;

	return (
		<ContentWrapper>
			<Columns classes={{ root: classes.noMargin }} space="SMALL" stackWhen="NEVER">
				<Column classes={{ inner: classes.noPadding }}>
					<LabelWrapper classes={{ label: classes.labelWrapper }} label={translate('sms.phoneNumber')}>
						<PhoneNumberField
							aria-label={translate('sms.phoneNumber')}
							classes={{ root: classes.noMargin }}
							data-pp-at-target={`${targetId}-sms-phone-number`}
							fields={{ number: phoneNumber as Field<string> }}
							id={useId()}
							labels={{ number: translate('sms.phoneNumber') }}
						/>
					</LabelWrapper>
				</Column>
				<Column>
					<TextFieldWrapper
						aria-label={translate('sms.label')}
						data-pp-at-target={`${targetId}-sms-label`}
						field={messageLabel as Field<string>}
						id={useId()}
						label={translate('sms.label')}
						labelPosition="top"
						showLabel
					/>
				</Column>
			</Columns>
			<TextFieldWrapper
				aria-label={translate('sms.presetMessage')}
				data-pp-at-target={`${targetId}-sms-preset-message`}
				field={messageBody as Field<string>}
				id={useId()}
				label={translate('sms.presetMessage')}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
