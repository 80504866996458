import {
	$isTextNode,
	DOMConversion,
	DOMConversionMap,
	DOMConversionOutput,
	TextNode,
	SerializedTextNode,
} from 'lexical';

/**
 * custom TextNode for converting inline "text-decoration" styles to corresponding Lexical classes
 */
export class ExtendedTextNode extends TextNode {
	static getType(): string {
		return 'extended-text';
	}

	static clone(node: ExtendedTextNode): ExtendedTextNode {
		return new ExtendedTextNode(node.__text, node.__key);
	}

	static importDOM(): DOMConversionMap | null {
		const importers = TextNode.importDOM();
		return {
			...importers,
			// strikethrough, underline
			span: () => ({
				conversion: patchStyleConversion(importers?.span),
				priority: 1,
			}),
			// italic
			em: () => ({
				conversion: patchStyleConversion(importers?.em),
				priority: 1,
			}),
			// bold
			strong: () => ({
				conversion: patchStyleConversion(importers?.strong),
				priority: 1,
			}),
		};
	}

	static importJSON(serializedNode: SerializedTextNode): TextNode {
		return TextNode.importJSON(serializedNode);
	}

	exportJSON(): SerializedTextNode {
		return super.exportJSON();
	}
}

function patchStyleConversion(
	originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
	return node => {
		const original = originalDOMConverter?.(node);
		if (!original) {
			return null;
		}
		const originalOutput = original.conversion(node);

		if (!originalOutput) {
			return originalOutput;
		}

		const { textDecoration } = node.style;

		return {
			...originalOutput,
			forChild: (lexicalNode, parent) => {
				const originalForChild = originalOutput?.forChild ?? (x => x);
				const result = originalForChild(lexicalNode, parent);
				if ($isTextNode(result)) {
					if (textDecoration.includes('underline') && !result.hasFormat('underline')) {
						result.toggleFormat('underline');
					}
					if (textDecoration.includes('line-through') && !result.hasFormat('strikethrough')) {
						result.toggleFormat('strikethrough');
					}
				}
				return result;
			},
		};
	};
}
