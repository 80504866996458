import React, { useCallback, useMemo, ChangeEvent, useState, useEffect } from 'react';

import { InfoIcon } from '@pushpay/iconography';
import { RadioGroup, Checkbox } from '@pushpay/inputs';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { ContainerTemplate, ItemTemplate } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import {
	containerLayouts,
	ContainerLayoutType,
	itemLayouts,
	ItemLayoutType,
	LayoutKeys,
	Options,
} from './layoutDefinition';
import { LayoutIcon } from './LayoutIcon';

const TILE_WIDTH = '74px';
const TILE_HEIGHT = '50px';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: '5px',
	},
	containerRoot: {
		paddingBottom: '20px',
	},
	title: {
		font: theme.typography['heading-4'],
		margin: `${theme.SPACING.XSMALL} 0`,
	},
	radioWrapper: {
		width: TILE_WIDTH,
		height: TILE_HEIGHT,
		paddingLeft: 0,
		color: theme.colors['grey-600'],
		'&:before': { display: 'none' },
	},
	radioTile: {
		marginRight: '10px',
	},
	tileChecked: {
		border: `1px solid ${theme.colors['blue-700']}`,
		color: theme.colors['blue-700'],
	},
	iconWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	labelWrapper: {
		marginTop: '15px',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'space-between',
		maxHeight: '65px',
	},
	icon: {
		width: TILE_WIDTH,
		height: TILE_HEIGHT,
	},
	labelTip: {
		height: '14px',
		width: '14px',
		marginLeft: theme.SPACING.XXSMALL,
	},
	optionsWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	checkBox: {
		font: theme.typography['text-4'],
		lineHeight: theme.SPACING.SMALL,
		padding: '10px 10px 10px 0',
	},
	radioRoot: {
		marginRight: theme.SPACING.SMALL,
	},
	layoutLabel: {
		marginTop: '5px',
		fontSize: '12px',
		lineHeight: '16px',
		textAlign: 'center',
	},
}));

export type LayoutSettingsComponentProps = ComponentProps<
	{
		layouts: ContainerLayoutType | ItemLayoutType;
		layoutType: LayoutKeys | null;
		layoutOptions: Options;
		isPlaceTemplate: boolean;
		isYouTube?: boolean;
		onLayoutChange: (selectedLayout: LayoutKeys, options: Options) => void;
		readonly?: boolean;
		isDisabled?: boolean;
		isContainer?: boolean;
	},
	typeof useStyles
>;

export const LayoutSettingsComponent = ({
	classes: classesProp,
	layouts,
	layoutType,
	layoutOptions,
	isPlaceTemplate,
	onLayoutChange,
	isYouTube = false,
	readonly = false,
	isDisabled,
	isContainer = false,
	'data-pp-at-target': targetId,
}: LayoutSettingsComponentProps) => {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const [options, setOptions] = useState(layoutType ? { [layoutType]: layoutOptions } : {});

	useEffect(() => {
		setOptions(prevOptions => ({
			...prevOptions,
			...(layoutType ? { [layoutType]: layoutOptions } : null),
		}));
	}, [layoutType, layoutOptions]);

	const filteredOptions = useMemo(() => {
		if (!layoutType) {
			return {};
		}
		const optionCopy = { ...options[layoutType] };
		// Only template with TIDBIT type have the distance option
		if (!isPlaceTemplate) {
			delete optionCopy.Distance;
		}
		return optionCopy;
	}, [options, layoutType, isPlaceTemplate]);

	const handleLayoutSelect = (value: LayoutKeys) => {
		onLayoutChange(value, options[value]);
	};

	const handleOptionClick = useCallback(
		(value: boolean, e: ChangeEvent<HTMLInputElement>) => {
			if (layoutType) {
				const isLabelUnchecked = e.target.id === 'Label' && value === false;
				const hasOverlayOption = options[layoutType]?.Overlay !== undefined;

				// If the label is unchecked, we need to set the overlay option to false manually because onLayoutChange doesn't recognize '{ Label: false, Overlay: true }' as a change
				const setOverlayOption = isLabelUnchecked && hasOverlayOption;

				onLayoutChange(layoutType, {
					...options[layoutType],
					[e.target.id]: value,
					...(setOverlayOption && {
						Overlay: false,
					}),
				});
			}
		},
		[onLayoutChange, layoutType, options]
	);

	const getLayoutLabel = (layout: string, isLayoutContainer: boolean) => {
		let layoutLabel: string;

		if (isLayoutContainer) {
			const label = containerLayouts.find(containerLayout => layout === containerLayout);
			switch (label) {
				case ContainerTemplate.List_72Dp:
				default:
					layoutLabel = translate('settings.layout.textLayoutLabel');
					break;
				case ContainerTemplate.List_72DpThumb:
					layoutLabel = translate('settings.layout.squareLayoutLabel');
					break;
				case ContainerTemplate.List_90DpThumb:
					layoutLabel = translate('settings.layout.rectangularLayoutLabel');
					break;
				case ContainerTemplate.List_132DpPhoto:
					layoutLabel = translate('settings.layout.bannerLayoutLabel');
					break;
			}
		} else {
			const label = itemLayouts.find(itemLayout => layout === itemLayout);
			switch (label) {
				case ItemTemplate.DetailPlain:
				default:
					layoutLabel = translate('settings.layout.textLayoutLabel');
					break;
				case ItemTemplate.DetailSquare:
					layoutLabel = translate('settings.layout.detailSquareLayoutLabel');
					break;
				case ItemTemplate.DetailFull:
					layoutLabel = translate('settings.layout.fullLayoutLabel');
					break;
				case ItemTemplate.DetailWebview:
					layoutLabel = translate('settings.layout.webviewLayoutLabel');
					break;
			}
		}

		return layoutLabel;
	};

	return (
		<>
			<div className={classes.title}>{translate('settings.layout.title')}</div>
			<div className={clsx(classes.root, isContainer && classes.containerRoot)}>
				<RadioGroup
					classes={{
						root: classes.radioRoot,
						radioTile: classes.radioTile,
						radioWrapper: classes.radioWrapper,
						tileChecked: classes.tileChecked,
					}}
					data-pp-at-target={`${targetId}-radio-group`}
					disabled={isDisabled}
					groupName="layout"
					items={layouts?.map(layout => ({
						label: (
							<div className={classes.labelWrapper}>
								<div key={layout} className={classes.iconWrapper}>
									<LayoutIcon
										className={classes.icon}
										data-pp-at-target={`${targetId}-${layout}`}
										title={layout}
										type={layout}
									/>
								</div>
								<div className={classes.layoutLabel}>{getLayoutLabel(layout, isContainer)}</div>
							</div>
						),
						value: layout,
					}))}
					radioStyle="Tile"
					readOnly={readonly}
					value={layoutType as LayoutKeys}
					onChange={handleLayoutSelect}
				/>
				{filteredOptions && (
					<div className={classes.optionsWrapper}>
						{(Object.keys(filteredOptions) as Array<keyof Exclude<Options, null>>).map(option => {
							/* Disable the Label option and show a tooltip if the container is a youtube feed */
							const disableLabelForYouTube = isYouTube && option === 'Label';
							return (
								<React.Fragment key={option}>
									<Checkbox
										key={option}
										checked={filteredOptions[option] && !disableLabelForYouTube}
										classes={{
											root: classes.checkBox,
										}}
										data-pp-at-target={`${targetId}-${option}`}
										disabled={disableLabelForYouTube || isDisabled}
										id={option}
										readOnly={readonly}
										onChange={handleOptionClick}
									>
										{translate('settings.layout.checkbox', {
											type: `$t(settings.layout.${option})`,
										})}
									</Checkbox>
									{disableLabelForYouTube && (
										<Tooltip
											content={translate('settings.container.disabledLabelForYouTube')}
											data-pp-at-target={`${targetId}-name`}
											panelSpace="XSMALL"
											placement="top"
										>
											<InfoIcon
												className={classes.labelTip}
												data-pp-at-target={`${targetId}-tip-icon`}
											/>
										</Tooltip>
									)}
								</React.Fragment>
							);
						})}
					</div>
				)}
			</div>
		</>
	);
};
