import { useMemo } from 'react';

import { Box } from '@pushpay/layout';
import Skeleton from '@pushpay/skeleton';
import { clsx, createUseStyles } from '@pushpay/styles';
import { useMediaBreakpoint } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

const useStyles = createUseStyles({
	root: {},
	skeleton: {},
	row: {},
	content: {},
});

type SkeletonDescriptionListComponentProps = ComponentProps<{ rows: number }, typeof useStyles>;

export const SkeletonDescriptionList = ({
	classes: classesProp,
	className: classNameProp,
	rows: rowsProp,
	...rest
}: SkeletonDescriptionListComponentProps) => {
	const classes = useStyles(classesProp);
	const className = clsx(classNameProp, classes.root);
	const isMobile = useMediaBreakpoint('TABLET_AND_BELOW');
	const rows = useMemo(() => {
		if (isMobile) {
			return new Array(rowsProp * 2).fill(null).map((_, i) => ({ width: i % 2 === 0 ? 50 : 70 }));
		}
		return new Array(rowsProp).fill(null).map(_ => ({ width: 50 }));
	}, [rowsProp, isMobile]);

	const skeletonClasses = useMemo(
		() => ({ root: classes.skeleton, row: classes.row, content: classes.content }),
		[classes]
	);

	return (
		<Box className={className}>
			<Skeleton classes={skeletonClasses} rows={rows} {...rest} />
		</Box>
	);
};
