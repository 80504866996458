import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		color: theme.colors['blue-500'],
		position: 'relative',
	},
	icon: {
		marginRight: theme.SPACING.SMALL_ICON_SIZE,
		alignSelf: 'flex-start',
		marginTop: '6px',
	},
	contentWrapper: {
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		padding: 0,
	},
	content: {
		width: '100%',
		paddingBottom: theme.SPACING.XXSMALL,
	},
	title: {
		alignSelf: 'center',
	},
	expandButton: {
		justifySelf: 'flex-end',
	},
	notificationWrapper: {
		position: 'relative',
		paddingBottom: theme.SPACING.SMALL,
		zIndex: 0,
	},
	textContent: {
		minHeight: '20px',
		overflow: 'visible',
		position: 'relative',
		top: `-${theme.SPACING.XXSMALL}`,
	},
}));
