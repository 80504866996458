import { createContext, ReactNode, useContext, useLayoutEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
	GetContainerChildrenQuery,
	GetContainerSettingsQuery,
	GetDhsCardsQuery,
	GetItemQuery,
	Container,
	Maybe,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { BreadcrumbType } from '@src/pages/appDesign/components/Breadcrumb';
import { getAppDesignPath } from '@src/pages/shell';
import { useParams } from '@src/router';
import { assertNever } from '@src/utils';

import { ContentType } from './contentContext';

type QueryData = {
	data: GetContainerChildrenQuery | GetContainerSettingsQuery | GetItemQuery | GetDhsCardsQuery | undefined;
	type: ContentType;
};

export type BreadcrumbStateContextType = {
	breadcrumbs: BreadcrumbType[];
	loading?: boolean;
};

type BreadcrumbDispatchContextType = {
	useQueryData: (data: QueryData, loading: boolean) => void;
};

type BreadcrumbParent = Maybe<Pick<Container, 'id' | 'name' | 'parentContainer'>>;

const BreadcrumbStateContext = createContext<BreadcrumbStateContextType | null>(null);
BreadcrumbStateContext.displayName = 'BreadcrumbStateContext';

const BreadcrumbDispatchContext = createContext<BreadcrumbDispatchContextType | null>(null);
BreadcrumbDispatchContext.displayName = 'BreadcrumbDispatchContext';

function getBreadcrumbParents(parent: BreadcrumbParent | undefined, orgKey: string): BreadcrumbType[] {
	if (!parent) return [];

	return [
		...getBreadcrumbParents(parent.parentContainer, orgKey),
		{
			name: parent.name,
			path: `${getAppDesignPath(orgKey)}${parent.id ? `/container/${parent.id}` : ''}`,
		},
	];
}

function useBreadcrumbs(queryData?: QueryData): BreadcrumbType[] {
	const { translate } = useTranslation('appDesign');
	const { organizationKey } = useParams<'organizationKey'>();
	const rootPage = translate('breadcrumb.content');
	const [searchParams] = useSearchParams();

	let currentName;
	let parents: BreadcrumbType[] = [];
	let node;

	if (queryData?.data) {
		const { data, type } = queryData;

		switch (type) {
			case ContentType.DHS:
				currentName = translate('breadcrumb.DHS');
				parents = getBreadcrumbParents({ id: '', name: rootPage }, organizationKey);
				break;

			case ContentType.CONTAINER_CHILDREN:
				node = (data as GetContainerChildrenQuery).organization?.application?.container;
				currentName = node?.name;
				parents = getBreadcrumbParents(node?.parentContainer as BreadcrumbParent, organizationKey);
				break;

			case ContentType.CONTAINER_SETTING:
				node = (data as GetContainerSettingsQuery).organization?.application?.container;
				currentName = translate('breadcrumb.containerSettings', { containerName: node?.name as string });
				parents = getBreadcrumbParents(node as BreadcrumbParent, organizationKey);
				break;

			case ContentType.ITEM:
				node = (data as GetItemQuery).organization?.application?.container?.item;
				currentName = node?.name;
				parents = getBreadcrumbParents(node?.parentContainer as BreadcrumbParent, organizationKey);
				break;

			case ContentType.ROOT:
				break;

			default:
				// asserts all ContentType values are covered
				assertNever(type);
		}
	}

	const breadcrumbs: BreadcrumbType[] = [
		...parents,
		{
			name: currentName || '',
		},
	].filter(b => b.name !== '');

	breadcrumbs[0] = {
		name: rootPage,
		path: breadcrumbs.length > 1 ? `${getAppDesignPath(organizationKey)}` : undefined,
	};

	return breadcrumbs.map(breadcrumb => ({
		...breadcrumb,
		path: breadcrumb.path ? `${breadcrumb.path}?${searchParams.toString()}` : undefined,
	}));
}

function BreadcrumbContextProvider({ children }: { children?: ReactNode }) {
	const [queryData, setQueryData] = useState<QueryData>();
	const [isLoading, setIsLoading] = useState(false);
	const breadcrumbs = useBreadcrumbs(queryData);

	const breadcrumbStateContextValue: BreadcrumbStateContextType = useMemo(
		() => ({
			breadcrumbs,
			loading: isLoading,
		}),
		[breadcrumbs, isLoading]
	);

	const breadcrumbDispatchContextValue: BreadcrumbDispatchContextType = useMemo(
		() => ({
			useQueryData: (data: QueryData, loading: boolean) => {
				useLayoutEffect(() => {
					setIsLoading(loading);

					if (!loading) {
						setQueryData(data);
					}
				}, [data, loading]);
			},
		}),
		[]
	);

	return (
		<BreadcrumbStateContext.Provider value={breadcrumbStateContextValue}>
			<BreadcrumbDispatchContext.Provider value={breadcrumbDispatchContextValue}>
				{children}
			</BreadcrumbDispatchContext.Provider>
		</BreadcrumbStateContext.Provider>
	);
}

function useBreadcrumbStateContext(): BreadcrumbStateContextType {
	const context = useContext(BreadcrumbStateContext);
	if (!context) {
		throw new Error('useBreadcrumbStateContext must be used within a BreadcrumbContextProvider');
	}
	return context;
}

function useBreadcrumbDispatchContext(): BreadcrumbDispatchContextType {
	const context = useContext(BreadcrumbDispatchContext);
	if (!context) {
		throw new Error('useBreadcrumbDispatchContext must be used within a BreadcrumbContextProvider');
	}
	return context;
}

export { BreadcrumbStateContext, BreadcrumbContextProvider, useBreadcrumbStateContext, useBreadcrumbDispatchContext };
