import { useTranslation } from '@src/i18n';

import { NoAccessIllustration } from '../illustrations';
import { BaseError } from './BaseError';

const targetId = 'access-denied-error';

export const AccessDeniedError = ({ error }: { error?: string }) => {
	const { translate } = useTranslation();

	return (
		<BaseError
			data-pp-at-target={targetId}
			illustration={NoAccessIllustration}
			text={error}
			title={translate('errors.title.accessDeniedError')}
			withGetHelpLink={!!error}
		/>
	);
};
