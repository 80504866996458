import { getAppSettings } from '@src/utils';

import { send } from './config';

export const onUntranslatedKey = (key: string) => {
	const { env } = getAppSettings();

	if (!DEVELOPMENT) {
		const error = new Error('⚠ Untranslated key!');
		send(error, [{ key }]);
	}

	return env === 'dev' || env === 'cat' ? `⚠ MISSING KEY:${key}` : key;
};
