import { FC, PropsWithChildren } from 'react';

import { ArrowSingleIcon } from '@pushpay/iconography';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { IconButton } from './IconButton';

export const useStyles = createUseStyles((theme: Theme) => ({
	icon: {
		fontSize: theme.SPACING.MEDIUM_ICON_SIZE,
		color: theme.colors['grey-900'],
		transition: `all ${theme.DURATIONS.SHORT}`,
		cursor: 'pointer',
	},
	withChildren: {
		marginLeft: theme.SPACING.XSMALL,
	},
	disabled: {
		cursor: 'not-allowed',
	},
}));

type ExpanderButtonProps = {
	expanded: boolean;
	toggle: () => void;
	isDisabled?: boolean;
} & Pick<React.ComponentProps<typeof IconButton>, 'displayStyle'>;

export const ExpanderButton: FC<PropsWithChildren<ExpanderButtonProps>> = ({
	expanded,
	toggle,
	children,
	isDisabled,
	...rest
}) => {
	const classes = useStyles(undefined);

	return (
		<IconButton
			aria-label="expand"
			disabled={isDisabled}
			icon={
				<ArrowSingleIcon
					className={clsx(classes.icon, children && classes.withChildren, isDisabled && classes.disabled)}
					data-pp-at-target=""
					direction={expanded ? 'up' : 'down'}
				/>
			}
			onClick={toggle}
			{...rest}
		>
			{children}
		</IconButton>
	);
};
