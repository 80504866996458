import moment from 'moment';

import { set } from '@pushpay/utils';

import { GetContainerChildrenQuery, PropertyType } from '@src/graphql/generated';
import { ContainerChild } from '@src/shared/types';

const getEventStartTime = (child: ContainerChild) => {
	if (child.__typename === 'ChildItem') {
		const timeframeProperty = child.item.properties.find(property => property.type === PropertyType.Timeframe);

		if (timeframeProperty?.__typename === 'TimeframeProperty' && timeframeProperty.timeframe.startTime) {
			return timeframeProperty.timeframe.startTime.unix();
		}
	}

	return moment().unix();
};

const sortByName = (a: ContainerChild, b: ContainerChild) => {
	const nameA = a.name.toUpperCase();
	const nameB = b.name.toUpperCase();
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
};
const getSortedChildContainers = (containerChildren: ContainerChild[]) =>
	containerChildren.filter(containerChild => containerChild.__typename === 'ChildContainer').sort(sortByName);

const getSortedChildItems = (containerChildren: ContainerChild[]) =>
	containerChildren
		.filter(containerChild => containerChild.__typename === 'ChildItem')
		.sort((a, b) => getEventStartTime(a) - getEventStartTime(b));

// TODO: Remove this 'filterEventsData' method while removing 'InAppCalendar' feature flag.
export function filterEventsData(data: GetContainerChildrenQuery) {
	const containerChildren = data.organization?.application?.container?.children?.nodes;

	if (!containerChildren) {
		return data;
	}

	const removeOldEvents = (child: typeof containerChildren[number]) => {
		if (child.__typename !== 'ChildItem' || !child.item.feedId) {
			return true;
		}

		const timeframeProperty = child.item.properties.find(property => property.type === PropertyType.Timeframe);

		if (timeframeProperty && timeframeProperty.__typename === 'TimeframeProperty') {
			const { startTime, endTime, allDay } = timeframeProperty.timeframe;
			const isToday = moment.utc().isSame(moment.utc(startTime), 'd');

			if (allDay && isToday) return true;

			const now = moment().unix();
			const start = startTime?.unix();
			const end = endTime?.unix();

			return (start && start > now) || (end && end > now);
		}

		return true;
	};

	return set(
		data,
		'organization.application.container.children.nodes',
		containerChildren.filter(removeOldEvents).sort((a, b) => getEventStartTime(a) - getEventStartTime(b))
	);
}

export const sortEventsData = (data: GetContainerChildrenQuery) => {
	const containerChildren = data.organization?.application?.container?.children?.nodes;

	if (!containerChildren) {
		return data;
	}

	const sortedChildContainers = getSortedChildContainers(containerChildren);
	const sortedChildItems = getSortedChildItems(containerChildren);

	return set(data, 'organization.application.container.children.nodes', [
		...sortedChildContainers,
		...sortedChildItems,
	]);
};
