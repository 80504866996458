import { Field, FormState } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { EmailPropertyInput } from '@src/graphql/generated';

import { ContentWrapper, TextFieldWrapper } from '../wrappers';

export type EmailProps = ComponentProps<
	{
		propertyField: FormState<EmailPropertyInput>;
	},
	typeof undefined
>;

export const Email = ({ propertyField, 'data-pp-at-target': targetId }: EmailProps) => {
	const {
		emailAddress: fieldInput,
		baseProperty: { header },
	} = propertyField;

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={header?.value}
				data-pp-at-target={`${targetId}-text-field`}
				field={fieldInput as Field<string>}
				label={header?.value}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
