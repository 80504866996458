import { useCallback, useId } from 'react';

import { FormState, useMutateField } from '@pushpay/forms';
import { LoadingOvalIcon } from '@pushpay/iconography';
import { TextInput } from '@pushpay/inputs';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { IconButton } from '@src/components/buttons';
import { Icon } from '@src/components/icon';
import { VideoPropertyInput, useFetchItemVideoThumbnailMutation } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { isVimeo, isYouTube, CONSTANTS } from '@src/pages/itemSettings/utils';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { useParentContext, usePropertyContext, usePropertyListContext } from '../contexts';
import { ContentWrapper, LabelWrapper } from '../wrappers';

const useStyle = createUseStyles((theme: Theme) => ({
	propertyFieldWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',

		'& > *': {
			flex: '1 1 auto',
		},
	},
	icon: {
		flex: '0 0 auto',
		color: theme.colors['productBrand-500'],
		margin: '6px',
	},
}));

const { YOUTUBE_ICON, YOUTUBE_HEADER, VIMEO_ICON, VIMEO_HEADER } = CONSTANTS;
type VideoInput = FormState<Required<VideoPropertyInput>>;
export type VideoProps = ComponentProps<
	{
		propertyField: FormState<VideoPropertyInput>;
	},
	typeof undefined
>;

export const Video = ({ propertyField, 'data-pp-at-target': targetId }: VideoProps) => {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const classes = useStyle(undefined);
	const {
		baseProperty: { id: propertyId, icon: iconField, header: headerField },
		description: descriptionField,
		url: urlField,
	} = propertyField as VideoInput;
	const headerText = headerField?.value ?? '';

	const { mutateField: mutateDescription } = useMutateField(descriptionField);
	const { mutateField: mutateUrl } = useMutateField(urlField);
	const { header, setIcon, setHeader, isFirstOfType } = usePropertyContext();
	const { itemId, isFormDirty } = usePropertyListContext();
	const { parentContainerId } = useParentContext();

	const { translate } = useTranslation('appDesign');
	const labelPlaceholder = translate('video.labelPlaceholder', { type: headerText });
	const urlPlaceholder = translate('video.urlPlaceholder', { type: headerText });
	const watchText = translate('video.watch');

	const isVimeoVideo = isVimeo(urlField.value || '');
	const isYouTubeVideo = isYouTube(urlField.value || '');

	const isLabelDisabled = useCallback(() => {
		if (headerText.toLowerCase() === YOUTUBE_HEADER.toLowerCase()) {
			return !isVimeoVideo;
		}

		return isYouTubeVideo;
	}, [headerText, isVimeoVideo, isYouTubeVideo]);

	const [fetchThumbnail, { loading: isFetchingThumbnail }] = useFetchItemVideoThumbnailMutation({
		variables: {
			organizationKey,
			platformCampusKey,
			input: {
				applicationId,
				itemId,
				parentContainerId: parentContainerId as string,
				videoPropertyId: propertyId.value,
			},
		},
		update(cache, { data }) {
			cache.modify({
				id: cache.identify({
					__typename: 'Item',
					id: itemId,
				}),
				fields: {
					image() {
						return data?.fetchItemVideoThumbnail.image;
					},
				},
			});
		},
	});

	const convertToYoutube = () => {
		setIcon(YOUTUBE_ICON);
		setHeader(YOUTUBE_HEADER);
		mutateDescription(watchText);
	};

	const convertToVimeo = () => {
		const fromProperty = headerText;

		setIcon(VIMEO_ICON);
		setHeader(VIMEO_HEADER);

		if (fromProperty.toLowerCase() === YOUTUBE_HEADER.toLowerCase()) {
			mutateDescription('');
		}
	};

	const convertToProperty = () => {
		const fromProperty = header;
		const toProperty = headerText;

		// back to the original property
		if (fromProperty.toLowerCase() !== toProperty.toLowerCase()) {
			setIcon(iconField?.value || '');
			setHeader(headerText);

			if (toProperty.toLowerCase() === YOUTUBE_HEADER.toLowerCase()) {
				mutateDescription(watchText);
			} else if (fromProperty.toLowerCase() === YOUTUBE_HEADER.toLowerCase()) {
				mutateDescription('');
			}
		}
	};

	const updatePropertyFromUrl = (value: string) => {
		if (isYouTube(value)) {
			convertToYoutube();

			return;
		}

		if (isVimeo(value)) {
			convertToVimeo();

			return;
		}

		convertToProperty();
	};

	const onUrlChange = (url: string) => {
		mutateUrl(url);
		if (url) {
			updatePropertyFromUrl(url);
		} else {
			convertToProperty();
		}
	};

	const handleFetchThumbnail = () => {
		fetchThumbnail();
	};

	const iconTooltipText = isFormDirty
		? translate('attribute.video.fetchThumbnailDisabled')
		: translate('attribute.video.fetchThumbnail');

	const canFetchThumbnail = isFirstOfType && (isYouTubeVideo || isVimeoVideo);

	return (
		<ContentWrapper>
			<LabelWrapper label={translate('video.label')}>
				<TextInput
					aria-label={labelPlaceholder}
					data-pp-at-target={`${targetId}-video-label`}
					id={useId()}
					placeholder={labelPlaceholder}
					readOnly={isLabelDisabled()}
					value={descriptionField.value || ''}
					onChange={mutateDescription}
				/>
			</LabelWrapper>
			<div className={classes.propertyFieldWrapper}>
				<LabelWrapper label={translate('video.url')}>
					<TextInput
						aria-label={urlPlaceholder}
						data-pp-at-target={`${targetId}-video-url`}
						id={useId()}
						placeholder={urlPlaceholder}
						value={urlField.value || ''}
						onChange={onUrlChange}
					/>
				</LabelWrapper>
				{canFetchThumbnail && (
					<IconButton
						aria-label={iconTooltipText}
						className={classes.icon}
						disabled={isFormDirty}
						displayStyle="text"
						icon={
							isFetchingThumbnail ? (
								<LoadingOvalIcon data-pp-at-target="thumbnail-fetching-spinner" fontSize={24} />
							) : (
								<Tooltip content={iconTooltipText} placement="top">
									<Icon name="cloud-download" />
								</Tooltip>
							)
						}
						onClick={handleFetchThumbnail}
					/>
				)}
			</div>
		</ContentWrapper>
	);
};
