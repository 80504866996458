import { Field, FormState, TextField } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { useDraggableContainersDefinition } from '@src/components/draggable/definitions/container';
import { SaveContainerSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

const useStyles = createUseStyles({
	label: {},
	wrapper: {},
});

type ContainerTypeFieldProps = ComponentProps<
	{
		field: FormState<SaveContainerSettingsSchema>['input']['type'];
	},
	typeof useStyles
>;

export const ContainerTypeField = ({ classes: classesProp, field }: ContainerTypeFieldProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyles(classesProp);

	const containerType = useContainerTypeName(field.value || '');

	return (
		<TextField
			classes={{
				label: classes.label,
				wrapper: classes.wrapper,
			}}
			field={field as Field<string>}
			label={translate('settings.container.type')}
			labelPosition="top"
			textInputProps={{ value: containerType } as any}
			tooltip={translate('settings.container.typeTooltip')}
			readOnly
			showLabel
			suppressRequiredText
		/>
	);
};

function useContainerTypeName(type: string) {
	const definitions = useDraggableContainersDefinition();

	const containerDefinition = Object.values(definitions).find(({ actions }) => actions[0]?.data?.type === type);

	return containerDefinition?.label ?? type;
}
