import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import sha256 from 'hash.js/lib/hash/sha/256';

import { isTestWidgetEnabled, testWidgetManager } from '@src/components/testWidget';
import {
	ValidatePendoGuideDocument,
	ValidatePendoGuideQuery,
	ValidatePendoGuideQueryVariables,
} from '@src/graphql/generated';

export function useValidatePendoGuide() {
	const client = useApolloClient();

	const validatePendoGuide = useCallback(
		async (guideContent: string, guide: { id: string }) => {
			const whitelistDisabled =
				isTestWidgetEnabled() && !testWidgetManager.settings.application.pendoWhitelistEnabled;
			if (whitelistDisabled) {
				return;
			}

			const hash = sha256().update(guideContent).digest('hex');

			const { data, errors } = await client.query<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>({
				query: ValidatePendoGuideDocument,
				variables: { hash },
			});

			if (errors || !data || !data.isGuideValid) {
				// Pendo async API wants rejected promise (NOT return/resolve false) if guide invalid
				const errorMessage = `Pendo guide is not whitelisted: ${guide.id}`;
				throw new Error(errorMessage);
			}
		},
		[client]
	);

	return {
		validatePendoGuide,
	};
}

export type ValidatePendoGuide = ReturnType<typeof useValidatePendoGuide>['validatePendoGuide'];
