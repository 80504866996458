import { Button, SaveButton } from '@pushpay/button';
import { Card } from '@pushpay/card';
import { EmailField, RuleType } from '@pushpay/forms';
import { Stack, Inline, StackItem } from '@pushpay/layout';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { DescriptionList } from '@src/components/descriptionList';
import { PageSection, SectionHeading } from '@src/components/layout';
import { useShowNotification } from '@src/components/notification';
import { AccountType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { profileStyles } from './profileStyles';
import { PersonalDetailsSectionProps } from './types';

const useProfileStyles = createUseStyles((theme: Theme) => ({
	...profileStyles(theme),
	email: {
		minHeight: '30px',
		marginTop: '-5px',
		overflow: 'hidden',
	},
	truncateText: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	unconfirmedEmail: {
		paddingRight: theme.SPACING.XSMALL,
	},
	unconfirmedEmailWrapper: {
		display: 'flex',
		overflow: 'hidden',
		color: theme.colors['grey-600'],
	},
	updateEmailInput: {
		width: '400px',
	},
	updateEmailStackItems: {
		width: '100%',
	},
	updateEmailWrapper: {
		alignItems: 'baseline',
		width: '100%',
	},
}));

export const PersonalDetailsSection = ({
	profile,
	emailAddressField,
	saveButtonState,
	editing,
	startEditing,
	stopEditing,
	'data-pp-at-target': targetId,
	classes: classesProp,
}: ComponentProps<PersonalDetailsSectionProps, typeof useProfileStyles>) => {
	const { translate } = useTranslation('profile');
	const classes = useProfileStyles(classesProp);
	const showNotification = useShowNotification();
	const { accountType, firstName, lastName, primaryEmailAddress, emailAddresses } = profile;
	const isPushpayAdmin = accountType === AccountType.PushpayAdmin;
	const unconfirmedEmail =
		!isPushpayAdmin && emailAddresses.find(e => e !== primaryEmailAddress?.emailAddress && !e?.verified);
	const updateEmailButton = !isPushpayAdmin && !editing && (
		<Button
			data-pp-at-target={`${targetId}-update-email-button`}
			displayStyle="text"
			type="button"
			onClick={() => startEditing('updateEmail')}
		>
			{translate('personalDetailsSection.updateEmailAddress')}
		</Button>
	);

	return (
		<PageSection>
			<SectionHeading data-pp-at-target={`${targetId}-heading`}>
				{translate('personalDetailsSection.title')}
			</SectionHeading>
			<Card classes={{ body: classes.cardBody }} data-pp-at-target={`${targetId}-card`}>
				<DescriptionList
					data-pp-at-target={`${targetId}-personal-details`}
					items={[
						[translate('personalDetailsSection.firstName'), firstName],
						[translate('personalDetailsSection.lastName'), lastName],
						[
							<Inline className={classes.email}>
								{translate('personalDetailsSection.emailAddress')}
							</Inline>,
							<Stack space="XSMALL">
								<Inline className={classes.email}>
									{primaryEmailAddress?.emailAddress}
									{updateEmailButton}
								</Inline>
								{unconfirmedEmail && (
									<div className={classes.unconfirmedEmailWrapper}>
										<span className={clsx(classes.truncateText, classes.unconfirmedEmail)}>
											{unconfirmedEmail.emailAddress}
										</span>{' '}
										({translate('personalDetailsSection.unconfirmed')})
									</div>
								)}
								{!isPushpayAdmin && editing === 'updateEmail' && (
									<StackItem space="SMALL">
										<Inline
											classes={{ stackItem: classes.updateEmailStackItems }}
											className={classes.updateEmailWrapper}
											space="SMALL"
											stackWhen="TABLET_AND_BELOW"
										>
											<EmailField
												classes={{
													wrapper: classes.fieldWrapper,
													input: classes.updateEmailInput,
												}}
												data-pp-at-target={`${targetId}-update-email`}
												emailInputProps={{ focusOnMount: true }}
												extraValidationRules={[
													{ type: RuleType.Required },
													{ type: RuleType.EmailAddress },
												]}
												field={emailAddressField}
												id="email"
												label={translate('personalDetailsSection.emailAddress')}
												suppressRequiredText
											/>
											<Inline className={classes.buttonsContainer}>
												<SaveButton
													classes={{ root: classes.saveButton }}
													data-pp-at-target={`${targetId}-update-email-save`}
													idleStateButtonText={translate('saveButton.text.idle')}
													savingStateButtonText={translate('saveButton.text.saving')}
													state={saveButtonState}
													successStateButtonText={translate('saveButton.text.success')}
													type="submit"
													onSuccess={() => {
														showNotification({
															type: 'warning',
															title: translate('personalDetailsSection.emailSent'),
															content: translate(
																'personalDetailsSection.pleaseFollowTheLink'
															),
														});
														stopEditing();
													}}
												>
													{translate('update')}
												</SaveButton>
												{saveButtonState === 'idle' && (
													<Button
														data-pp-at-target={`${targetId}-update-email-button`}
														displayStyle="text"
														type="button"
														onClick={stopEditing}
													>
														{translate('cancel')}
													</Button>
												)}
											</Inline>
										</Inline>
									</StackItem>
								)}
							</Stack>,
						],
					]}
				/>
			</Card>
		</PageSection>
	);
};
