import { useId, useReducer } from 'react';

import unfetch from 'unfetch';

import { Button } from '@pushpay/button';
import { ErrorIcon, LoadingOvalIcon, RemoveIcon } from '@pushpay/iconography';
import { TextInput } from '@pushpay/inputs';
import Tooltip from '@pushpay/tooltip';

import { useTranslation } from '@src/i18n';

import { Image, loadImage } from '../shared';
import { acceptedFileTypes } from '../shared/utils';
import { ImageUrlInputActions, imageUploaderReducer, initialState } from './imageUrlInputReducer';
import { useStyles } from './imageUrlInputStyles';

type ImageUrlInputProps = {
	disableUpload?: boolean;
	onApplyImage: (imageUrl: string) => void;
};

export const ImageUrlInput = ({ disableUpload, onApplyImage }: ImageUrlInputProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const { translate: translateCommon } = useTranslation('common');
	const [{ imageUrl, isInvalidFileType, isInvalidUrl, isLoading }, dispatch] = useReducer(imageUploaderReducer, {
		...initialState,
	});

	const acceptedFileTypesJoined = acceptedFileTypes.join(', ').toUpperCase();
	const hasError = isInvalidFileType || isInvalidUrl;

	const handleImageUrlChange = (urlInInput: string) => {
		dispatch({ type: ImageUrlInputActions.SetUrl, imageUrl: urlInInput });
	};

	const handleReset = () => {
		dispatch({ type: ImageUrlInputActions.ResetUrl });
	};

	const handleApplyImageFromUrl = async (url: string) => {
		if (url === '') return;

		try {
			dispatch({ type: ImageUrlInputActions.ApplyUrlStart });

			const response = await unfetch(url, { method: 'HEAD' });

			const imageType = response.headers.get('content-type')?.replace('image/', '');

			if (!(imageType && acceptedFileTypes.includes(imageType))) {
				dispatch({ type: ImageUrlInputActions.InvalidFileType });
				return;
			}

			loadImage({
				image: url,
				onError: async () => {
					dispatch({ type: ImageUrlInputActions.InvalidUrl });
				},
				onLoad: (image: Image) => {
					onApplyImage(image.url);
					dispatch({ type: ImageUrlInputActions.ApplyUrlSuccess });
				},
			});
		} catch (err) {
			dispatch({ type: ImageUrlInputActions.InvalidUrl });
		}
	};

	return (
		<>
			<TextInput
				classes={{ field: classes.imageUrlInput, wrapper: classes.imageUrlInputWrapper }}
				data-pp-at-target="imageUrl"
				disabled={disableUpload}
				id={useId()}
				invalid={isInvalidUrl}
				placeholder={translate('imageUploader.urlPlaceholder')}
				value={imageUrl}
				onChange={handleImageUrlChange}
			>
				{imageUrl !== '' ? (
					<Button className={classes.resetButton} type="button" onClick={handleReset}>
						<RemoveIcon displaySize="large" />
					</Button>
				) : null}
				<Button
					className={classes.applyButton}
					disabled={imageUrl === '' || disableUpload}
					type="button"
					onClick={() => handleApplyImageFromUrl(imageUrl)}
				>
					<Tooltip
						content={translate('imageUploader.buttonTooltip')}
						disabled={!disableUpload}
						placement="top"
					>
						{translate('imageUploader.applyButton')}
					</Tooltip>
				</Button>
				{isLoading ? (
					<span className={classes.spinner}>
						<LoadingOvalIcon />
					</span>
				) : null}
			</TextInput>
			{hasError && (
				<div className={classes.errorMessageContainer}>
					<ErrorIcon className={classes.errorIcon} />
					<strong className={classes.boldText}>Error</strong>
					<span className={classes.errorMessage}>
						{isInvalidUrl && translate('imageUploader.invalidUrl')}
						{isInvalidFileType &&
							translateCommon('errors.text.fileTypeUnsupported', {
								acceptedFileTypes: acceptedFileTypesJoined,
							})}
					</span>
				</div>
			)}
		</>
	);
};
