import moment, { Duration, Moment } from 'moment';

const roundDateTime = (date: Moment, roundingInterval: Duration, roundType: 'ceil' | 'floor') => {
	const roundedMultiple = Math[roundType](Number(date) / Number(roundingInterval));
	const roundedDateTime = roundedMultiple * Number(roundingInterval);

	return moment(roundedDateTime);
};

export { roundDateTime };
