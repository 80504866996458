import { useState, useEffect, useTransition, memo } from 'react';

import LandingScreenWrapper from '@pushpay/app-components/dist/app/screens/VODScreen/LandingScreenWrapper';

import { useAppPreviewContext } from '@src/context';
import { validateUrl } from '@src/utils';

import { ContainerPreviewData } from '../types';
import { getImageMap } from '../utils';

const LandingScreen = memo(LandingScreenWrapper);

export function ResiMediaLibrary({ container: { images, resiLink } }: { container: ContainerPreviewData }) {
	const { screenWidth } = useAppPreviewContext();
	const [resiFeedUrl, setResiFeedUrl] = useState<string | null>(null);
	const [, startTransition] = useTransition();

	const containerImageMap = getImageMap(images);
	const heroImageUrl = containerImageMap?.lmw ?? undefined;

	useEffect(() => {
		if (!resiLink) {
			setResiFeedUrl(null);
		} else if (validateUrl(resiLink)) {
			startTransition(() => {
				setResiFeedUrl(resiLink);
			});
		}
	}, [resiLink]);

	return resiFeedUrl ? (
		<LandingScreen heroImageUrl={heroImageUrl} screenWidth={screenWidth} siteUrl={resiFeedUrl} />
	) : null;
}
