import { FC, PropsWithChildren } from 'react';

import { AccessDeniedError } from '@src/components/errors';
import { useMeQuery } from '@src/graphql/generated';
import { usePendo } from '@src/pendo';

import { TopNav } from '../appChrome/components/topNav';

const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => (
	<>
		<TopNav data-pp-at-target="top-nav" withMeData={false} withMyOrgData={false} />
		{children}
	</>
);

export const Me: FC<PropsWithChildren> = ({ children }) => {
	const { initializePendo } = usePendo();
	const { data, loading } = useMeQuery({
		fetchPolicy: 'cache-first',
		onCompleted({ me }) {
			if (me) {
				initializePendo(me);
			}
		},
	});

	if (data?.me) {
		return <>{children}</>;
	}

	if (loading) {
		return null;
	}

	return (
		<ErrorWrapper>
			<AccessDeniedError />
		</ErrorWrapper>
	);
};
