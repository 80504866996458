import { useMemo } from 'react';

import { UseTranslationOptions } from '@pushpay/i18n';

import { isTestWidgetEnabled, useI18nTransforms } from '@src/components/testWidget';
import { insertIf } from '@src/utils';

import {
	useTranslation as useTranslationBase,
	getTransWithDefaultNamespace,
	NamespaceKey,
	Translate as TranslateBase,
} from './translation';

export { initI18n } from './initI18n';

function usePostProcessors() {
	const testWidgetTransforms = useI18nTransforms();
	return useMemo(() => [...insertIf(isTestWidgetEnabled(), testWidgetTransforms)], [testWidgetTransforms]);
}

export function useTranslation<TNamespaceKey extends NamespaceKey = 'common'>(
	namespace: TNamespaceKey = 'common' as TNamespaceKey,
	options?: UseTranslationOptions
) {
	return useTranslationBase(namespace, options, usePostProcessors());
}

export const Trans = getTransWithDefaultNamespace('common');

export type Translate<TNamespaceKey extends NamespaceKey = 'common'> = TranslateBase<TNamespaceKey>;
export type TranslateMoment = ReturnType<typeof useTranslation>['translateMoment'];
