import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

type AppPreviewNavigationData = {
	screenTitle?: string;
	navAction?: string;
	containerCampusId?: string | null;
	containerId?: string | null;
	icon?: string;
	shouldShowBookmark?: boolean;
};

type AppPreviewNavigationContext = {
	navigationData?: AppPreviewNavigationData;
};

type AppPreviewNavigationUpdateContext = {
	updateNavigationData?: (data: AppPreviewNavigationData) => void;
};

const AppPreviewNavigationContext = createContext<AppPreviewNavigationContext | null>(null);
AppPreviewNavigationContext.displayName = 'AppPreviewNavigationContext';

const AppPreviewNavigationUpdateContext = createContext<AppPreviewNavigationUpdateContext | null>(null);
AppPreviewNavigationContext.displayName = 'AppPreviewNavigationContext';

function AppPreviewNavigationContextProvider({
	children,
	navigationData,
	updateNavigationData,
}: PropsWithChildren<AppPreviewNavigationContext & AppPreviewNavigationUpdateContext>) {
	const appPreviewNavigationContextValue: AppPreviewNavigationContext = useMemo(
		() => ({
			navigationData,
		}),
		[navigationData]
	);

	const appPreviewNavigationUpdateContextValue: AppPreviewNavigationUpdateContext = useMemo(
		() => ({
			updateNavigationData,
		}),
		[updateNavigationData]
	);

	return (
		<AppPreviewNavigationContext.Provider value={appPreviewNavigationContextValue}>
			<AppPreviewNavigationUpdateContext.Provider value={appPreviewNavigationUpdateContextValue}>
				{children}
			</AppPreviewNavigationUpdateContext.Provider>
		</AppPreviewNavigationContext.Provider>
	);
}

function useAppPreviewNavigationContext(): AppPreviewNavigationContext {
	const context = useContext(AppPreviewNavigationContext);
	if (!context) {
		throw new Error('useAppPreviewNavigationContext must be used within a AppPreviewNavigationContextProvider');
	}
	return context;
}

function useAppPreviewNavigationUpdateContext(): AppPreviewNavigationUpdateContext {
	const context = useContext(AppPreviewNavigationUpdateContext);
	if (!context) {
		throw new Error('useAppPreviewUpdateContext must be used within a AppPreviewContextProvider');
	}
	return context;
}

export type { AppPreviewNavigationData };
export { AppPreviewNavigationContextProvider, useAppPreviewNavigationContext, useAppPreviewNavigationUpdateContext };
