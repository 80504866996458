import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react';

import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';

import { useMyOrganization } from '@src/myContext';
import { getAppDesignPath } from '@src/pages/shell/RedirectToOrganization';
import { useParams } from '@src/router';
import { useEvent } from '@src/utils';

const removeEmptyKeys = (obj: Record<string, unknown>) =>
	Object.entries(obj)
		.filter(([_, val]) => !!val)
		.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

function useDocumentTitle(applicationId: string, campusId: string | null) {
	const { applications } = useMyOrganization();

	const selectedApp = applications.find(app => app.id === applicationId);
	const selectedCampus = selectedApp?.campuses.find(appCampus => appCampus.id === campusId);

	const appName = selectedApp?.name;
	const campusName = selectedCampus?.name;

	useEffect(() => {
		const prevTitle = document.title;
		document.title = `${document.title}${appName ? ` - ${appName}` : ''}${campusName ? ` - ${campusName}` : ''}`;

		return () => {
			document.title = prevTitle;
		};
	}, [appName, campusName]);
}

const APPLICATION_LOCAL_STORAGE_KEY = 'application';

function useAppCampusParams(searchParams: URLSearchParams): GlobalContextFields {
	let campus = searchParams.get('campus');
	let application = searchParams.get('application') || localStorage.getItem(APPLICATION_LOCAL_STORAGE_KEY);

	const { applications } = useMyOrganization();
	if (!application || !applications.find(app => app.id === application)) {
		application = applications
			.slice()
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))[0].id;
	}
	localStorage.setItem(APPLICATION_LOCAL_STORAGE_KEY, application);

	const appCampuses = applications.find(app => app.id === application)?.campuses || [];

	if (!campus || !appCampuses.find(appCampus => appCampus.id === campus)) {
		campus = appCampuses[0]?.id || null;
	}

	return { application, campus };
}

type GlobalContextFields = {
	application: string;
	campus: string | null;
};

type GlobalContext = GlobalContextFields & {
	updateGlobalContext: (fields: GlobalContextFields) => void;
};

const GlobalContext = createContext<GlobalContext | null>(null);
GlobalContext.displayName = 'GlobalContext';

const GlobalContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { application, campus } = useAppCampusParams(searchParams);

	const { organizationKey } = useParams<'organizationKey'>();
	const navigate = useNavigate();
	useDocumentTitle(application, campus);

	const onInit = useEvent(() => {
		const newParams = removeEmptyKeys({ application, campus });
		setSearchParams(createSearchParams(newParams), { replace: true });
	});

	const updateGlobalContext = useCallback(
		({ application: applicationId, campus: campusId }: GlobalContextFields) => {
			const newParams = createSearchParams(
				removeEmptyKeys({
					application: applicationId,
					campus: campusId,
				})
			).toString();

			if (applicationId !== application) {
				navigate({
					pathname: getAppDesignPath(organizationKey),
					search: newParams,
				});
			}

			if (campusId !== campus) {
				setSearchParams(newParams);
			}
		},
		[application, campus, navigate, organizationKey, setSearchParams]
	);

	const globalContextValue: GlobalContext = useMemo(
		() => ({
			application,
			campus,
			updateGlobalContext,
		}),
		[application, campus, updateGlobalContext]
	);

	useEffect(() => {
		onInit();
	}, [onInit, organizationKey]);

	return <GlobalContext.Provider value={globalContextValue}>{children}</GlobalContext.Provider>;
};

function useGlobalContext() {
	const context = useContext(GlobalContext);
	if (!context) {
		throw new Error('useGlobalContext must be used within a GlobalContextProvider');
	}
	return context;
}

export { GlobalContext, GlobalContextProvider, useGlobalContext };
