import { getTimeframeDefaultInput } from '@src/components/properties/helpers';
import { PropertyType, ItemPropertyActionType, ShellCapabilityKey } from '@src/graphql/generated';

import { Draggable } from '../types';

export const addPropertyGive: Draggable = {
	icon: 'heart-1',
	label: 'Give',
	name: 'add_property_give',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				header: 'Give',
				type: PropertyType.Give,
				icon: 'heart-1',
				action: {
					click: ItemPropertyActionType.OpenUrl,
				},
				actionBar: 0,
				hideOriginalProperty: false,
			},
		},
	],
	unique: true,
	type: 'property',
};

export const addPropertyAddToContacts: Draggable = {
	icon: 'user-add-2',
	label: 'Add contact',
	name: 'add_property_add_to_contacts',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.AddToContacts,
				icon: 'user-add-2',
				header: 'Save contact',
				action: {
					click: ItemPropertyActionType.AddToContacts,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: true,
	type: 'property',
};

export const addPropertyAddress: Draggable = {
	icon: 'map-pin',
	label: 'Address',
	name: 'add_property_address',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Address,
				header: 'Directions',
				icon: 'map-pin',
				action: {
					click: ItemPropertyActionType.Directions,
				},
				actionBar: 0,
				hideOriginalProperty: false,
			},
		},
	],
	unique: true,
	type: 'property',
};

export const addPropertyAppLink: Draggable = {
	icon: 'log-out-1',
	label: 'App link',
	name: 'add_property_app_link',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.AppLink,
				header: 'App link',
				icon: 'log-out-1',
				bos: {
					label: 'App link',
				},
				action: {
					click: ItemPropertyActionType.AppLink,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyAudio: Draggable = {
	icon: 'music-note-3',
	label: 'Podcast',
	name: 'add_property_audio',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Audio,
				header: 'Podcast',
				icon: 'play-2',
				bos: {
					label: 'Listen',
				},
				action: {
					click: ItemPropertyActionType.PlayAudio,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyBlankify: Draggable = {
	icon: 'custom-blankify',
	label: 'Blankify',
	name: 'add_property_blankify',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Blankify,
				header: 'Fill in the blank',
				icon: 'custom-blankify',
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyEmail: Draggable = {
	icon: 'address-1',
	label: 'Email',
	name: 'add_property_email',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Default,
				icon: 'address-1',
				header: 'Email',
				action: {
					click: ItemPropertyActionType.SendEmail,
				},
				actionBar: 0,
				hideOriginalProperty: false,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyPhone: Draggable = {
	icon: 'call-1',
	label: 'Phone',
	name: 'add_property_phone',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Default,
				icon: 'call-1',
				header: 'Phone',
				action: {
					click: ItemPropertyActionType.Call,
				},
				actionBar: 0,
				hideOriginalProperty: false,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyFacetime: Draggable = {
	icon: 'camera-symbol-1',
	label: 'Facetime',
	name: 'add_property_facetime',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Default,
				icon: 'camera-symbol-1',
				header: 'Facetime',
				action: {
					click: ItemPropertyActionType.Facetime,
				},
				actionBar: 0,
				hideOriginalProperty: false,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyShare: Draggable = {
	icon: 'custom-share',
	label: 'Share',
	name: 'add_property_share',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Share,
				icon: 'custom-share',
				header: 'Share',
				action: {
					click: ItemPropertyActionType.Share,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: true,
	type: 'property',
};

export const addPropertySMS: Draggable = {
	icon: 'bubble-comment-1',
	label: 'Text message',
	name: 'add_property_sms',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Sms,
				header: 'Text',
				icon: 'bubble-comment-1',
				action: {
					click: ItemPropertyActionType.AppLink,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyText: Draggable = {
	icon: 'note-paper-2',
	label: 'Plain text',
	name: 'add_property_text',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Text,
				header: 'Description',
				icon: 'note-paper-2',
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyTextHTML: Draggable = {
	icon: 'text-wrapping-2',
	label: 'Rich text',
	name: 'add_property_text_html',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.TextHtml,
				header: 'Description',
				icon: 'text-wrapping-2',
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyTimeframe: Draggable = {
	icon: 'calendar-1',
	label: 'Timeframe',
	name: 'add_property_timeframe',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Timeframe,
				header: 'Save event',
				icon: 'calendar-1',
				action: {
					click: ItemPropertyActionType.AddToCalendar,
				},
				actionBar: 0,
				hideOriginalProperty: false,
				bos: {
					...getTimeframeDefaultInput(),
				},
			},
		},
	],
	getPropertySpecificFields: getTimeframeDefaultInput,
	unique: true,
	type: 'property',
};

export const addPropertyUserNote: Draggable = {
	icon: 'note-paper-edit',
	label: 'User notes',
	name: 'add_property_user_note',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.UserNote,
				header: 'Notes',
				bos: {
					hintText: 'Tap to add notes',
				},
				icon: 'note-paper-edit',
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyVideo: Draggable = {
	icon: 'movie-play-1',
	label: 'Video',
	name: 'add_property_video',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Video,
				header: 'Video',
				icon: 'movie-play-1',
				bos: {
					description: 'Watch',
				},
				action: {
					click: ItemPropertyActionType.PlayVideo,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyYouTube: Draggable = {
	icon: 'custom-youtube',
	label: 'YouTube',
	name: 'add_property_youtube',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Video,
				header: 'Youtube',
				icon: 'custom-youtube',
				bos: {
					description: 'Watch',
					isEmbed: true,
				},
				action: {
					click: ItemPropertyActionType.PlayVideo,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyVimeo: Draggable = {
	icon: 'custom-vimeo',
	label: 'Vimeo',
	name: 'add_property_vimeo',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Video,
				header: 'Vimeo',
				icon: 'custom-vimeo',
				bos: {
					description: 'Watch',
					isEmbed: true,
				},
				action: {
					click: ItemPropertyActionType.PlayVideo,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyWebsite: Draggable = {
	icon: 'cloud',
	label: 'Website',
	name: 'add_property_website_in',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Website,
				icon: 'cloud',
				header: 'Website',
				bos: {
					isExternal: true,
				},
				action: {
					click: ItemPropertyActionType.OpenUrl,
				},
				actionBar: 0,
				hideOriginalProperty: false,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyCallToAction: Draggable = {
	icon: 'hand-touch-1',
	label: 'Call to action',
	name: 'add_property_call_to_action',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.CallToAction,
				header: 'Call to action',
				icon: 'hand-touch-1',
				bos: {
					isExternal: true,
				},
				action: {
					click: ItemPropertyActionType.OpenUrl,
				},
				actionBar: 0,
				hideOriginalProperty: false,
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyCarousel: Draggable = {
	icon: 'photos-1',
	label: 'Carousel',
	name: 'add_property_carousel',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				header: 'Carousel',
				icon: 'photos-1',
				type: PropertyType.Carousel,
				hideOriginalProperty: true,
				actionBar: 0,
				bos: {
					images: [],
				},
			},
		},
	],
	unique: false,
	type: 'property',
};

export const addPropertyResi: Draggable = {
	icon: 'custom-resi',
	label: 'Resi',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				type: PropertyType.Video,
				header: 'Watch',
				icon: 'custom-resi',
				bos: {
					description: 'Watch',
					isEmbed: true,
				},
				action: {
					click: ItemPropertyActionType.PlayVideo,
				},
				actionBar: 0,
				hideOriginalProperty: true,
			},
		},
	],
	name: 'add_property_resi',
	unique: false,
	type: 'property',
	category: 'Resi',
	requiredShellCapability: ShellCapabilityKey.ResiDraggable,
};

export const addPropertyKeyMetrics: Draggable = {
	icon: 'chart-board',
	label: 'Key metrics',
	name: 'add_property_key_metrics',
	actions: [
		{
			action_type: 'create',
			model: 'property',
			data: {
				header: 'Key metrics',
				icon: 'chart-board',
				type: PropertyType.KeyMetrics,
				hideOriginalProperty: true,
				actionBar: 0,
				bos: {
					metricsCount: 0,
					metrics: [],
				},
			},
		},
	],
	unique: true,
	type: 'property',
};
