import { createContext, FC, PropsWithChildren, useContext, useLayoutEffect, useMemo, useState } from 'react';

type LoadingStateContext = {
	isLoading: boolean;
};

type LoadingDispatchContext = {
	useFinishLoading: (loading?: boolean) => void;
};

const LoadingStateContext = createContext<LoadingStateContext | null>(null);
LoadingStateContext.displayName = 'LoadingStateContext';

const LoadingDispatchContext = createContext<LoadingDispatchContext | null>(null);
LoadingDispatchContext.displayName = 'LoadingDispatchContext';

const LoadingContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);

	const loadingStateContextValue: LoadingStateContext = useMemo(
		() => ({
			isLoading,
		}),
		[isLoading]
	);

	const loadingDispatchContextValue: LoadingDispatchContext = useMemo(
		() => ({
			useFinishLoading: (loading?: boolean) => {
				useLayoutEffect(() => {
					if (!loading) {
						setIsLoading(false);
					}
				}, [loading]);
			},
		}),
		[]
	);

	return (
		<LoadingStateContext.Provider value={loadingStateContextValue}>
			<LoadingDispatchContext.Provider value={loadingDispatchContextValue}>
				{children}
			</LoadingDispatchContext.Provider>
		</LoadingStateContext.Provider>
	);
};

function useLoadingStateContext(): LoadingStateContext {
	const context = useContext(LoadingStateContext);
	if (!context) {
		throw new Error('useLoadingStateContext must be used within a LoadingContextProvider');
	}
	return context;
}

function useLoadingDispatchContext(): LoadingDispatchContext {
	const context = useContext(LoadingDispatchContext);
	if (!context) {
		return { useFinishLoading: () => {} };
	}
	return context;
}

export {
	LoadingStateContext,
	LoadingDispatchContext,
	LoadingContextProvider,
	useLoadingStateContext,
	useLoadingDispatchContext,
};
