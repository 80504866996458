import { Moment } from 'moment';

import { NEW_CARD_PREFIX } from '@src/components/card/Card';
import { TimeframeProperty, ItemType, Timeframe as TimeframeType } from '@src/graphql/generated';
import { ItemSettings } from '@src/pages/itemSettings/types';

import { Item } from './types';

// This is necessary due to iCal Worker saving the end date as the start of day for all day events
// After iCal Worker produces non-inclusive end day, switch to `subtract(1, 'second')` instead
// See: https://pushpay.atlassian.net/browse/CAPPS-12501
export const getCorrectedEndDate = (endTime: Moment, allDay: boolean) =>
	allDay ? endTime.clone().endOf('day') : endTime.clone();

export const getImpliedEndDate = (startTime: Moment, allDay: boolean) =>
	allDay ? startTime.clone().endOf('day') : startTime.clone();

export const isTimeFrameExpired = (timeframe: TimeframeType) => {
	const { startTime, endTime, allDay } = timeframe;

	if (!startTime) {
		return false;
	}

	const endDate = endTime ? getCorrectedEndDate(endTime, allDay) : getImpliedEndDate(startTime, allDay);

	return endDate.isBefore(Date.now());
};

export const isExpiredEvent = (item: NonNullable<ItemSettings | Item>) => {
	type PropertyWithTimeframe = Pick<TimeframeProperty, 'timeframe'>;

	const isEventItem = item.type === ItemType.Event;
	if (!isEventItem) {
		return false;
	}

	const timeframeProperties = item.properties.filter(
		property => property.__typename === 'TimeframeProperty'
	) as PropertyWithTimeframe[];

	if (timeframeProperties.length === 0) {
		return false;
	}

	const areAllTimeframesExpired = timeframeProperties.every(property => isTimeFrameExpired(property.timeframe));

	return areAllTimeframesExpired;
};

const hasInvalidTimeframeProperties = (timeframeProperties: Array<TimeframeProperty>) => {
	if (timeframeProperties.length === 0) {
		return false;
	}

	const allMissingStartDate = timeframeProperties.every(timeframeProperty => !timeframeProperty.timeframe.startTime);

	return allMissingStartDate;
};

const hasNoTimeframeProperty = (type: ItemType, timeframeProperties: Array<TimeframeProperty>, isNewCard: boolean) =>
	type === ItemType.Event && timeframeProperties.length === 0 && !isNewCard;

export function validateItemProperties(item: ItemSettings | Item) {
	if (!item) {
		return {};
	}
	const { type, id, properties = [] } = item;
	const timeframeProperties = properties.filter(
		property => property.__typename === 'TimeframeProperty'
	) as Array<TimeframeProperty>;

	const isNewCard = id.startsWith(NEW_CARD_PREFIX);

	return {
		hasInvalidTimeframeProperties: hasInvalidTimeframeProperties(timeframeProperties),
		hasNoTimeframeProperty: hasNoTimeframeProperty(type, timeframeProperties, isNewCard),
	};
}
