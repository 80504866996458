import Icon, { IconProps } from '@pushpay/icon';

export const HomeIndicator = ({ className, ...rest }: IconProps) => (
	<Icon viewBox="0 0 390 46" {...rest}>
		<title>Preview home indicator</title>
		<path d="M390 0H0c.1 25.42 20.75 46 46.21 46h297.58C369.25 46 389.9 25.42 390 0z" />
		<path
			className={className}
			d="M130.5 32.89h129a2.5 2.5 0 0 1 2.5 2.5h0a2.5 2.5 0 0 1-2.5 2.5h-129a2.5 2.5 0 0 1-2.5-2.5h0a2.5 2.5 0 0 1 2.5-2.5z"
		/>
	</Icon>
);
