import { useGlobalContext } from '@src/context';
import { useMyApp } from '@src/myContext';
import { useParams } from '@src/router';

export function useGetQueryAndMutationVars() {
	const { organizationKey } = useParams<'organizationKey'>();
	const {
		currentApp: { id, platformCampusKey },
	} = useMyApp();
	const { campus: campusId } = useGlobalContext();

	return {
		organizationKey,
		applicationId: id,
		platformCampusKey,
		campusId,
	};
}
