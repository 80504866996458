import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import Reset from '@pushpay/reset';

import { LoadingScreen } from '@src/components/loadingScreen';
import { ThemeProvider } from '@src/components/themeProvider';
import { Init } from '@src/pages/shell';
import { router } from '@src/router';

const App = () => (
	<ThemeProvider>
		<Reset />
		<Init />
		<LoadingScreen>
			<RouterProvider router={router} />
		</LoadingScreen>
	</ThemeProvider>
);

const appContainer = document.getElementById('app');
if (appContainer) {
	const root = createRoot(appContainer);
	root.render(<App />);
}
