import { useMemo, useCallback } from 'react';

import { useFeature } from '@src/featureFlags';
import { ContainerType, ItemType, ProductVersion } from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';

import { Draggable } from '../types';
import {
	addAttributeCampus,
	addAttributeFeedRSS,
	addAttributeFeedEvents,
	addAttributeFeedYouTube,
	addAttributeFeedVimeo,
} from './attribute';
import { useDraggableContainersDefinition } from './container';
import { useDraggableItemsDefinition } from './item';
import {
	addPropertyAddress,
	addPropertyAddToContacts,
	addPropertyAppLink,
	addPropertyAudio,
	addPropertyBlankify,
	addPropertyCallToAction,
	addPropertyCarousel,
	addPropertyEmail,
	addPropertyFacetime,
	addPropertyGive,
	addPropertyKeyMetrics,
	addPropertyPhone,
	addPropertyResi,
	addPropertyShare,
	addPropertySMS,
	addPropertyText,
	addPropertyTextHTML,
	addPropertyTimeframe,
	addPropertyUserNote,
	addPropertyVideo,
	addPropertyVimeo,
	addPropertyWebsite,
	addPropertyYouTube,
} from './property';

const defaultItemDraggables = [
	addPropertyAddToContacts,
	addPropertyAddress,
	addPropertyAppLink,
	addPropertyAudio,
	addPropertyBlankify,
	addPropertyEmail,
	addPropertyPhone,
	addPropertyFacetime,
	addPropertyShare,
	addPropertySMS,
	addPropertyText,
	addPropertyTextHTML,
	addPropertyTimeframe,
	addPropertyUserNote,
	addPropertyVideo,
	addPropertyYouTube,
	addPropertyVimeo,
	addPropertyWebsite,
	addPropertyCallToAction,
];

// TODO [CAPPS-12284]: Should be removed along with InAppCalendar FF removal
const legacyDefaultContainerSettingsDraggables = [
	addAttributeCampus,
	addAttributeFeedRSS,
	addAttributeFeedEvents,
	addAttributeFeedYouTube,
	addAttributeFeedVimeo,
];

const defaultContainerSettingsDraggables = [
	addAttributeCampus,
	addAttributeFeedRSS,
	addAttributeFeedYouTube,
	addAttributeFeedVimeo,
];

export function useDraggablesDefinition() {
	const isInAppCalendarEnabled = useFeature('InAppCalendar');
	const {
		currentApp: { productVersion },
	} = useMyApp();

	const isEA4App = productVersion === ProductVersion.Ea4;
	const {
		addContainer,
		addContainerEvents,
		addContainerGive,
		addContainerMessages,
		addContainerPlaces,
		addContainerSelectCampus,
		addContainerCCBCheckIn,
		addContainerCCBGroups,
		addContainerCCBServing,
		addContainerCCBPublicNeeds,
		addContainerResiLibrary,
	} = useDraggableContainersDefinition();
	const {
		addItem,
		addItemEventLegacy,
		addItemEvent,
		addItemDestination,
		addItemContact,
		addItemMedia,
		addItemSermon,
		addItemWebview,
		addItemImpact,
	} = useDraggableItemsDefinition();
	const defaultContainerChildrenDraggables = useMemo(
		() => [
			addContainer,
			addContainerEvents,
			addContainerPlaces,
			...(isEA4App ? [addContainerSelectCampus] : []),
			addItem,
			isInAppCalendarEnabled ? addItemEvent : addItemEventLegacy,
			addItemDestination,
			addItemContact,
			addItemMedia,
			addItemSermon,
			addItemWebview,
			...(isEA4App ? [addItemImpact] : []),
			addContainerCCBCheckIn,
			addContainerCCBGroups,
			addContainerCCBServing,
			addContainerCCBPublicNeeds,
			addContainerResiLibrary,
		],
		[
			addContainer,
			addContainerEvents,
			addContainerPlaces,
			isEA4App,
			addContainerSelectCampus,
			addItem,
			isInAppCalendarEnabled,
			addItemEvent,
			addItemEventLegacy,
			addItemDestination,
			addItemContact,
			addItemMedia,
			addItemSermon,
			addItemWebview,
			addItemImpact,
			addContainerCCBCheckIn,
			addContainerCCBGroups,
			addContainerCCBServing,
			addContainerCCBPublicNeeds,
			addContainerResiLibrary,
		]
	);
	const getContainerChildrenDraggables = useCallback(
		(type: ContainerType): Array<Draggable> => {
			switch (type) {
				case ContainerType.Default:
					return defaultContainerChildrenDraggables;
				case ContainerType.Events:
					return [addContainerEvents, isInAppCalendarEnabled ? addItemEvent : addItemEventLegacy];
				case ContainerType.Places:
					return [addItemDestination];
				case ContainerType.Root:
					return [
						addContainer,
						addContainerEvents,
						...(isEA4App ? [addContainerGive] : []),
						addContainerPlaces,
						addContainerMessages,
						addContainerCCBCheckIn,
						addContainerCCBGroups,
						addContainerCCBServing,
						addContainerCCBPublicNeeds,
						addContainerResiLibrary,
					];
				default:
					return [];
			}
		},
		[
			addContainer,
			addContainerCCBCheckIn,
			addContainerCCBGroups,
			addContainerCCBPublicNeeds,
			addContainerCCBServing,
			addContainerEvents,
			addContainerGive,
			addContainerMessages,
			addContainerPlaces,
			addContainerResiLibrary,
			addItemDestination,
			addItemEvent,
			addItemEventLegacy,
			defaultContainerChildrenDraggables,
			isEA4App,
			isInAppCalendarEnabled,
		]
	);

	return {
		getContainerChildrenDraggables,
	};
}

export const getItemDraggableDefinitions = ({
	type,
	productVersion,
}: {
	type?: ItemType;
	productVersion: ProductVersion;
}): Array<Draggable> => {
	const isEA4App = productVersion === ProductVersion.Ea4;
	const itemDraggables = isEA4App
		? [addPropertyGive, ...defaultItemDraggables, addPropertyCarousel, addPropertyResi]
		: defaultItemDraggables;

	const webViewDraggables = itemDraggables.filter(
		property => ![addPropertyFacetime, addPropertyCarousel].includes(property)
	);

	switch (type) {
		case ItemType.Impact:
		case undefined:
			return isEA4App ? [...itemDraggables, addPropertyKeyMetrics] : itemDraggables;
		case ItemType.Webview:
			return webViewDraggables;
		default:
			return itemDraggables;
	}
};

export const getContainerSettingsDraggables = ({
	type,
	productVersion,
	isInAppCalendarEnabled,
}: {
	type?: ContainerType;
	productVersion: ProductVersion;
	// TODO [CAPPS-12284]: Should be removed along with InAppCalendar FF removal
	isInAppCalendarEnabled: boolean;
}): Array<Draggable> => {
	const isEA4App = productVersion === ProductVersion.Ea4;
	switch (type) {
		case ContainerType.Default:
			return isInAppCalendarEnabled
				? defaultContainerSettingsDraggables
				: legacyDefaultContainerSettingsDraggables;
		case ContainerType.Events:
			return isEA4App ? [addAttributeCampus, addAttributeFeedEvents] : [addAttributeFeedEvents];
		case ContainerType.Give:
		case ContainerType.Places:
		case ContainerType.Resi:
			return isEA4App ? [addAttributeCampus] : [];
		default:
			return [];
	}
};
