import { CardType } from '@src/components/card';
import { defaultMutateRefetchQueries } from '@src/graphql/client';
import {
	useUpdateContainerVisibilityMutation,
	useUpdateItemVisibilityMutation,
	GetContainerChildrenDocument,
	GetContainerVisibilityDocument,
} from '@src/graphql/generated';
import { CONTAINER_CHILDREN_PAGING_SIZE } from '@src/pages/constants';
import { assertNever } from '@src/utils';

export function useItemAndContainerVisibilityMutation({
	organizationKey,
	platformCampusKey,
	applicationId,
}: {
	organizationKey: string;
	platformCampusKey?: string | null;
	applicationId: string;
}) {
	const [mutateContainerVisibility] = useUpdateContainerVisibilityMutation({
		update(cache, { data }) {
			cache.modify({
				id: cache.identify({
					__typename: 'ChildContainer',
					id: data?.updateContainerVisibility.updatedContainer.id,
				}),
				fields: {
					hidden() {
						return data?.updateContainerVisibility.updatedContainer.isHidden as boolean;
					},
				},
			});
			cache.modify({
				id: cache.identify({
					__typename: 'Container',
					id: data?.updateContainerVisibility.updatedContainer.id,
				}),
				fields: {
					hidden() {
						return data?.updateContainerVisibility.updatedContainer.isHidden as boolean;
					},
				},
			});
		},
	});
	const [mutateItemVisibility] = useUpdateItemVisibilityMutation({
		update(cache, { data }) {
			cache.modify({
				id: cache.identify({
					__typename: 'ChildItem',
					id: data?.updateItemVisibility.updatedItem.id,
				}),
				fields: {
					hidden() {
						return data?.updateItemVisibility.updatedItem.isHidden as boolean;
					},
				},
			});
			cache.modify({
				id: cache.identify({
					__typename: 'Item',
					id: data?.updateItemVisibility.updatedItem.id,
				}),
				fields: {
					hidden() {
						return data?.updateItemVisibility.updatedItem.isHidden as boolean;
					},
				},
			});
		},
	});

	const generateOnToggleVisibilityHandler =
		(id: string, type: CardType, parentId?: string | null) => (isHidden: boolean) => {
			switch (type) {
				case 'item': {
					if (parentId) {
						mutateItemVisibility({
							variables: {
								organizationKey,
								platformCampusKey,
								input: {
									applicationId,
									containerId: parentId,
									itemId: id,
									isHidden,
								},
							},
							optimisticResponse: {
								updateItemVisibility: {
									updatedItem: {
										id,
										isHidden,
									},
								},
							},
							refetchQueries: [
								...defaultMutateRefetchQueries,
								{
									query: GetContainerVisibilityDocument,
									variables: {
										organizationKey,
										platformCampusKey,
										applicationId,
										containerId: parentId,
									},
								},
							],
						});
					}
					break;
				}
				case 'ccb':
				case 'container':
				case 'resi': {
					const containerChildrenQuery = {
						query: GetContainerChildrenDocument,
						variables: {
							organizationKey,
							platformCampusKey,
							applicationId,
							containerId: id,
							paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
						},
					};
					const refetchQueries = parentId
						? [
								containerChildrenQuery,
								{
									query: GetContainerVisibilityDocument,
									variables: {
										organizationKey,
										platformCampusKey,
										applicationId,
										containerId: parentId,
									},
								},
						  ]
						: [containerChildrenQuery];
					mutateContainerVisibility({
						variables: {
							organizationKey,
							platformCampusKey,
							input: {
								applicationId,
								containerId: id,
								isHidden,
							},
						},
						optimisticResponse: {
							updateContainerVisibility: {
								updatedContainer: {
									id,
									isHidden,
									__typename: 'Container',
								},
								__typename: 'UpdateContainerVisibilityResult',
							},
						},
						refetchQueries: [...defaultMutateRefetchQueries, ...refetchQueries],
					});
					break;
				}
				default:
					assertNever(type);
			}
		};
	return { generateOnToggleVisibilityHandler };
}
