import { useId } from 'react';

import { TextField, TextFieldProps as TextFieldPropsDefault } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
	},
	root: {
		marginTop: 0,
	},
}));

type TextFieldProps = Omit<TextFieldPropsDefault, 'label' | 'aria-label'> & {
	['aria-label']?: string | null;
	label?: string | null;
};

export const TextFieldWrapper = ({
	classes: classesProp,
	id,
	'aria-label': ariaLabel,
	label,
	...props
}: TextFieldProps) => {
	const classes = useStyles(classesProp);
	const generatedId = useId();
	const fieldId = id ?? generatedId;

	return <TextField aria-label={ariaLabel ?? ''} classes={classes} id={fieldId} label={label ?? ''} {...props} />;
};
