import { useCallback, useEffect, useState, RefObject } from 'react';

// extends DragEvent type to set dataTransfer field as not nullable
type ImageDragEvent = Omit<DragEvent, 'dataTransfer'> & { dataTransfer: DataTransfer };

function isImageDragged(e: DragEvent): e is ImageDragEvent {
	if (e.dataTransfer?.items?.[0]) {
		const itemType = e.dataTransfer.items[0].type;
		return itemType.indexOf('image') === 0;
	}

	return false;
}

export function useImageDrag(dropArea: RefObject<HTMLDivElement>, onImageDrop: (imageFile: File) => void) {
	const [dragCount, setDragCount] = useState(0);

	const onDrag = useCallback((e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const onDragIn = useCallback((e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (isImageDragged(e)) {
			setDragCount(count => count + 1);
		}
	}, []);

	const onDragOut = useCallback((e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (isImageDragged(e)) {
			setDragCount(count => count - 1);
		}
	}, []);

	const onDrop = useCallback(
		(e: DragEvent) => {
			e.preventDefault();
			e.stopPropagation();
			if (isImageDragged(e)) {
				const imageFile = e.dataTransfer.items[0].getAsFile();
				if (imageFile) {
					onImageDrop(imageFile);
				}
				e.dataTransfer.clearData();
				setDragCount(0);
			}
		},
		[onImageDrop]
	);

	useEffect(() => {
		const dropAreaRef = dropArea.current;

		dropAreaRef?.addEventListener('dragover', onDrag);
		dropAreaRef?.addEventListener('dragenter', onDragIn);
		dropAreaRef?.addEventListener('dragleave', onDragOut);
		dropAreaRef?.addEventListener('drop', onDrop);

		return () => {
			dropAreaRef?.removeEventListener('dragover', onDrag);
			dropAreaRef?.removeEventListener('dragenter', onDragIn);
			dropAreaRef?.removeEventListener('dragleave', onDragOut);
			dropAreaRef?.removeEventListener('drop', onDrop);
		};
	}, [dropArea, onDrag, onDragIn, onDragOut, onDrop]);

	return {
		isDragged: dragCount > 0,
	};
}
