import { Field, FormState } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { GivingLinkField } from '@src/components/formFields/GivingLinkField';
import { GivePropertyInput } from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';

import { useParentContext } from '../contexts';
import { ContentWrapper } from '../wrappers';

export type GiveProps = ComponentProps<
	{
		propertyField: FormState<GivePropertyInput>;
	},
	typeof undefined
>;

export const Give = ({ propertyField, 'data-pp-at-target': targetId }: GiveProps) => {
	const { currentApp } = useMyApp();
	const { parentCampus } = useParentContext();

	const { givingLink } = propertyField;

	const campusGivingLink = currentApp?.campuses.find(campus => campus.id === parentCampus)?.givingLink;

	return (
		<ContentWrapper>
			<GivingLinkField
				key="givingPropertyLinkField"
				applicationGivingLink={currentApp?.givingLink}
				campusGivingLink={campusGivingLink}
				data-pp-at-target={`${targetId}-giving-link`}
				field={givingLink as Field<string>}
				isGiveProperty
			/>
		</ContentWrapper>
	);
};
