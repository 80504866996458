import { DraggableAttributeData } from '@src/components/draggable/types';
import { useDraggablesDataContext } from '@src/context/draggableData/draggablesDataContext';
import { isDraggableType } from '@src/pages/appDesign/helper';
import { DropHandler } from '@src/pages/appDesign/type';

export function useAddingContainerAttributeHandler() {
	const { drop } = useDraggablesDataContext();
	const onAddingAttributeDropHandler: DropHandler = (event, draggingItem) => {
		const { over } = event;
		if (!over) {
			return;
		}
		if (isDraggableType(draggingItem)) {
			if (draggingItem.type === 'attribute' && over.data?.current) {
				drop(draggingItem.name);
				if (draggingItem.name === 'add_attribute_campus') {
					over.data?.current.addCampus();
				}
				if (
					[
						'add_attribute_feed_youtube',
						'add_attribute_feed_vimeo',
						'add_attribute_feed_events',
						'add_attribute_feed',
					].includes(draggingItem.name)
				) {
					const eventAttributeData = draggingItem.actions[0].data as DraggableAttributeData;

					over.data?.current.addFeed(eventAttributeData?.type, {
						url: eventAttributeData?.bos?.feedURL,
						properties: eventAttributeData?.bos?.feedProperties,
					});
				}
			}
		}
	};
	return onAddingAttributeDropHandler;
}
