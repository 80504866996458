import { useCallback, useMemo } from 'react';

import { getDynamicFieldsItems, useDynamicFieldsMutation } from '@pushpay/forms';

import { CarouselUploader } from '@src/components/carouselUploader';
import {
	CarouselFile,
	CarouselFileUploadType,
	CarouselImagesDynamicFields,
} from '@src/components/carouselUploader/types';
import {
	useCarouselPendingUploadImageMapActions,
	useCarouselPendingUploadImageObject,
} from '@src/context/carouselPendingUploadImagesContext';
import { Image } from '@src/graphql/generated';
import { ImageFileDataForUploadObject, useCarouselFileUploadMutation } from '@src/shared/hooks';
import { useEvent } from '@src/utils';

type CarouselImageFieldProps = {
	imagesDynamicFields: CarouselImagesDynamicFields;
	images: Image[];
	isImageProcessing: boolean;
	carouselId: string;
};

export const CarouselImageField = ({
	imagesDynamicFields,
	images,
	isImageProcessing,
	carouselId,
}: CarouselImageFieldProps) => {
	const { previewImageDataList } = useCarouselPendingUploadImageObject(carouselId);
	const { addPendingUploadImage } = useCarouselPendingUploadImageMapActions();

	const imagesFields = getDynamicFieldsItems(imagesDynamicFields);
	const { addItem } = useDynamicFieldsMutation();

	const updateCarouselField = useEvent(
		(imageUrl: string, previewImageData: CarouselFile, uploadImageData?: ImageFileDataForUploadObject) => {
			addPendingUploadImage({
				carouselId,
				previewImageData,
				uploadImageData,
			});

			addItem({
				newImageInput: {
					id: previewImageData.id,
					unprocessedImageUrl: imageUrl,
				},
			});
		}
	);

	const { onCarouselFileSelect } = useCarouselFileUploadMutation(updateCarouselField);

	const onImageSelect = useCallback(
		(file: CarouselFile) => {
			onCarouselFileSelect(file);
		},
		[onCarouselFileSelect]
	);

	const imagesForPreview: CarouselFile[] = useMemo(
		() =>
			images
				.filter(image => !previewImageDataList.find(previewImage => previewImage.id === image.id))
				.map(
					(image): CarouselFile => ({
						id: image.id,
						type: CarouselFileUploadType.URL,
						file: image.urls?.original,
					})
				)
				.concat(previewImageDataList),
		[images, previewImageDataList]
	);

	return (
		<CarouselUploader
			imagesFields={imagesFields}
			isImageProcessing={isImageProcessing}
			selectedFiles={imagesForPreview}
			onImageSelect={onImageSelect}
		/>
	);
};
