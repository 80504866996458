import { CardType, GetItemQuery } from '@src/graphql/generated';

type Item = NonNullable<
	NonNullable<NonNullable<NonNullable<NonNullable<GetItemQuery>['organization']>['application']>['container']>['item']
>;
export type GetItemQueryProperties = Item['properties'];
export type GetItemCardDefinition = Item['cardDefinition'];

export function getCardTypeOptions(isImpactItem: boolean, itemProperties?: GetItemQueryProperties) {
	let cardTypeOptions = isImpactItem ? [CardType.Impact] : [CardType.Default, CardType.Special, CardType.Intro];

	if (!isImpactItem && itemProperties) {
		const dynamicCardTypeList = itemProperties
			.filter(itemProperty => {
				const { __typename: type = '' } = itemProperty;
				return ['VideoProperty', 'AudioProperty', 'TimeframeProperty'].includes(type);
			})
			.filter(itemProperty => {
				if (itemProperty.__typename === 'VideoProperty' || itemProperty.__typename === 'AudioProperty') {
					return !!itemProperty.url;
				}

				if (itemProperty.__typename === 'TimeframeProperty') {
					return !!itemProperty.timeframe;
				}

				return true;
			})
			.map(itemProperty => {
				const { __typename: type } = itemProperty;

				if (type === 'VideoProperty') {
					return CardType.Video;
				}

				if (type === 'AudioProperty') {
					return CardType.Audio;
				}

				if (type === 'TimeframeProperty') {
					return CardType.Event;
				}

				return CardType.Default;
			});

		// Using Set to remove duplicate card types
		cardTypeOptions = cardTypeOptions.concat([...new Set(dynamicCardTypeList)]);
	}

	return cardTypeOptions.map(cardType => ({
		display: cardType.toLowerCase().charAt(0).toUpperCase() + cardType.toLowerCase().slice(1),
		value: cardType,
	}));
}
