import { ReactNode } from 'react';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

import Expand, { ExpandStatus, useExpand } from '@pushpay/expand';
import { clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';
import { useUpdateEffect } from '@pushpay/utils';

import { ExpanderButton } from '@src/components/buttons';
import { useFeature } from '@src/featureFlags';
import { useEvent } from '@src/utils';

import useStyles from './expandableCardStyles';

export type ExpandableCardProps = ComponentProps<
	{
		title: ReactNode;
		actions?: ReactNode;
		initialExpandStatus?: ExpandStatus;
		hasDivider?: boolean;
		isDraggable?: boolean;
		showDragBorder?: boolean;
		isBeingDragged?: boolean;
		listeners?: SyntheticListenerMap;
		attributes?: {
			role: string;
			tabIndex: number;
			'aria-pressed': boolean | undefined;
			'aria-roledescription': string;
			'aria-describedby': string;
		};
		isDisabled?: boolean;
		externalExpandStatus?: ExpandStatus;
		isCollapsible?: boolean;
	},
	typeof useStyles
>;

export const ExpandableCard = ({
	title,
	actions,
	initialExpandStatus = 'collapsed',
	hasDivider = false,
	classes: classesProp,
	children,
	'data-pp-at-target': targetId,
	isDraggable,
	isBeingDragged,
	showDragBorder,
	listeners,
	attributes,
	isDisabled,
	externalExpandStatus,
	isCollapsible = true,
}: ExpandableCardProps) => {
	const classes = useStyles(classesProp);
	const [expandStatus, toggleExpand] = useExpand(initialExpandStatus);
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const externalToggleExpand = useEvent(() => {
		// preexpanded and expanded are both status when the panel
		// is expanded so we should not toggle even if externalExpandStatus
		// & expandStatus aren't the same 'expanded' status.
		const isExternalExpanded = externalExpandStatus !== 'collapsed';
		const isExpanded = expandStatus !== 'collapsed';

		if (!externalExpandStatus || isExternalExpanded === isExpanded) return;

		toggleExpand();
	});

	// This effect only runs when externalExpandStatus updates. We don't want to run
	// externalToggleExpand on the first render so that it can use initialExpandStatus
	// properly.
	useUpdateEffect(() => {
		externalToggleExpand();
	}, [externalExpandStatus, externalToggleExpand]);

	const cursorClass = isDisabled ? classes.disabledDragCursor : isDraggable && classes.dragCursor;

	return (
		<div
			className={clsx(
				isInAppCalendarEnabled ? classes.card : classes.cardLegacy,
				isBeingDragged && classes.dragging,
				showDragBorder && classes.dropAreaBorder
			)}
			data-pp-at-target={`${targetId}-expand-card-root`}
		>
			{isDisabled && isInAppCalendarEnabled && <div className={classes.disabledOverlay} />}
			<div className={clsx(classes.header, cursorClass)} {...listeners} {...attributes}>
				<div className={classes.title}>{title}</div>
				<div className={classes.actions}>
					{actions}
					{isCollapsible && (
						<ExpanderButton
							expanded={
								!isBeingDragged ? expandStatus === 'expanded' || expandStatus === 'preexpanded' : false
							}
							isDisabled={isDisabled}
							toggle={toggleExpand}
						/>
					)}
				</div>
			</div>

			{!isBeingDragged && (
				<Expand.Panel
					data-pp-at-target={`${targetId}-expand-id`}
					expandStatus={isCollapsible ? expandStatus : 'preexpanded'}
					overflowVisible
				>
					<div
						className={clsx(
							classes.body,
							hasDivider && classes.divider,
							isDisabled && classes.disabledDragCursor
						)}
					>
						{children}
					</div>
				</Expand.Panel>
			)}
		</div>
	);
};
