import { useMemo } from 'react';

import { ApolloError, QueryResult } from '@apollo/client';

type UseQueriesResult = {
	loading: boolean;
};

export function useQueries<T1, T2>(
	firstQuery: QueryResult<T1, any>,
	secondQuery: QueryResult<T2, any>
): UseQueriesResult & { data: [T1?, T2?]; error: [ApolloError?, ApolloError?] };
export function useQueries<T1, T2, T3>(
	firstQuery: QueryResult<T1, any>,
	secondQuery: QueryResult<T2, any>,
	thirdQuery: QueryResult<T3, any>
): UseQueriesResult & { data: [T1?, T2?, T3?]; error: [ApolloError?, ApolloError?, ApolloError?] };
export function useQueries<T1, T2, T3, T4>(
	firstQuery: QueryResult<T1, any>,
	secondQuery: QueryResult<T2, any>,
	thirdQuery: QueryResult<T3, any>,
	fourthQuery: QueryResult<T4, any>
): UseQueriesResult & { data: [T1?, T2?, T3?, T4?]; error: [ApolloError?, ApolloError?, ApolloError?, ApolloError?] };
export function useQueries(...args: QueryResult[]) {
	const loading = args.some(x => x.loading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const data = useMemo(() => args.map(x => x.data), [...args.map(x => x.data)]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const error = useMemo(() => args.map(x => x.error), [...args.map(x => x.error)]);

	return {
		loading,
		data,
		error,
	};
}
