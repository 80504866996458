import { memo } from 'react';

import { Button } from '@pushpay/button';
import { Inline } from '@pushpay/layout';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

type FooterProps = {
	onClickCancelButton: () => void;
	onClickSelectButton: () => void;
	isDisabled: boolean;
};

export const Footer = memo(
	({
		isDisabled,
		onClickCancelButton,
		onClickSelectButton,
		'data-pp-at-target': targetId,
	}: ComponentProps<FooterProps, typeof undefined>) => {
		const { translate } = useTranslation('appDesign');
		return (
			<Inline>
				<Button
					data-pp-at-target={`${targetId}-cancel-button`}
					displayStyle="text"
					type="button"
					onClick={onClickCancelButton}
				>
					{translate('iconPicker.cancel')}
				</Button>
				<Button
					data-pp-at-target={`${targetId}-select-button`}
					disabled={isDisabled}
					displayStyle="primary"
					type="button"
					onClick={onClickSelectButton}
				>
					{translate('iconPicker.select')}
				</Button>
			</Inline>
		);
	}
);
