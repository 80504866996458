import { useState, useRef } from 'react';

import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';

import { getPrimaryColor } from '@pushpay/app-components/dist/app/utils/settings_utils';
import { ComponentProps } from '@pushpay/types';

import { useGetAppSettings } from '@src/shared/hooks';

import { usePropertyListContext } from '../properties/contexts';
import { BlankifyNode, ExtendedTextNode } from './nodes';
import {
	EditorStateContent,
	BlankifyPlugin,
	FloatingLinkEditorPlugin,
	HtmlPlugin,
	LinkPlugin,
	OnEditPlugin,
	ToolbarPlugin,
} from './plugins';
import { pushpayEditorTheme } from './PushpayEditorTheme';
import { useStyles } from './TextEditorStyles';

export type TextEditorProps = ComponentProps<
	{
		textHtml?: string;
		includeBlankifyOption?: boolean;
		onEdit?: (editorStateContent: EditorStateContent) => void;
	},
	undefined
>;

const editorConfig = {
	namespace: 'ppEditorConfig',
	theme: pushpayEditorTheme,
	nodes: [
		AutoLinkNode,
		LinkNode,
		ListNode,
		ListItemNode,
		HorizontalRuleNode,
		HeadingNode,
		QuoteNode,
		BlankifyNode,
		ExtendedTextNode,
	],
	onError(error: Error) {
		throw error;
	},
};

export function TextEditor({ textHtml, includeBlankifyOption, onEdit }: TextEditorProps) {
	const containerRef = useRef<HTMLDivElement>(null);
	const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
	const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
	const { disabledForFeedItem } = usePropertyListContext();
	const settings = useGetAppSettings();

	const primaryColor = getPrimaryColor(settings?.appSettings);
	const styleParams = { primaryColor };

	const classes = useStyles(undefined, styleParams);

	const onRef = (_floatingAnchorElem: HTMLDivElement) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	return (
		<div ref={containerRef}>
			<LexicalComposer initialConfig={{ ...editorConfig, editable: !disabledForFeedItem }}>
				<div className={classes.container}>
					<ToolbarPlugin
						includeBlankifyOption={includeBlankifyOption}
						setIsLinkEditMode={setIsLinkEditMode}
					/>
					<RichTextPlugin
						contentEditable={
							<div className={classes.editorScroller}>
								<div ref={onRef} className={classes.editor}>
									<ContentEditable className={classes.contentEditable} />
								</div>
							</div>
						}
						ErrorBoundary={LexicalErrorBoundary}
						placeholder={null}
					/>
					<HistoryPlugin />
					<ListPlugin />
					<LinkPlugin />
					<HorizontalRulePlugin />
					<HtmlPlugin initialHtml={textHtml} />
					{includeBlankifyOption && <BlankifyPlugin />}
					{onEdit && (
						<OnEditPlugin
							forwardedRef={containerRef}
							includeBlankifyOption={includeBlankifyOption}
							styleParams={styleParams}
							onEdit={onEdit}
						/>
					)}
					{floatingAnchorElem && (
						<FloatingLinkEditorPlugin
							anchorElem={floatingAnchorElem}
							isLinkEditMode={isLinkEditMode}
							setIsLinkEditMode={setIsLinkEditMode}
						/>
					)}
				</div>
			</LexicalComposer>
		</div>
	);
}
