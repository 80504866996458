import { useCallback, useEffect, useRef, useState } from 'react';

import { unstable_useBlocker as useBlocker } from 'react-router-dom';

export function useConfirmModal(dirty: boolean) {
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const blocker = useBlocker(dirty);
	const blockerRef = useRef<typeof blocker | null>(blocker);

	useEffect(() => {
		blockerRef.current = blocker;

		return () => {
			blockerRef.current = null;
		};
	}, [blocker]);

	const onConfirmDelete = useCallback(() => {
		blockerRef.current?.proceed?.();
	}, []);

	const onProceed = () => {
		if (blocker.state === 'blocked') {
			blocker.proceed();
		}
	};

	const onReset = () => {
		if (blocker.state === 'blocked') {
			blocker.reset();
		}
	};

	useEffect(() => {
		const handleBeforeUnload = (e: any) => {
			e.returnValue = true; // Gecko + IE
			return true; // Webkit, Safari, Chrome
		};

		if (dirty) {
			if (blocker.state === 'blocked') {
				setIsConfirmModalOpen(true);
			}
			window.addEventListener('beforeunload', handleBeforeUnload);
		} else {
			setIsConfirmModalOpen(false);
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [dirty, blocker]);

	return [isConfirmModalOpen, setIsConfirmModalOpen, onProceed, onReset, onConfirmDelete] as const;
}
