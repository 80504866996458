import Icon, { IconProps } from '@pushpay/icon';

export const EmptyDraggablesIcon = (props: IconProps) => (
	<Icon viewBox="0 0 60 68" {...props}>
		<path
			d="M5.218 7.869l37.786-2.619c2.876-.199 5.37 1.969 5.573 4.844l2.636 37.362c.203 2.878-1.966 5.376-4.844 5.579L8.576 55.655c-2.876.199-5.37-1.969-5.573-4.844L.368 13.449C.165 10.571 2.334 8.073 5.212 7.87z"
			fill="#cacbd1"
		/>
		<path
			d="M9.137 3.985l37.786-2.619c2.876-.199 5.37 1.969 5.573 4.844l2.636 37.362c.203 2.878-1.966 5.376-4.844 5.579l-37.793 2.619c-2.876.199-5.37-1.969-5.573-4.844L4.287 9.565C4.084 6.687 6.252 4.189 9.13 3.986z"
			fill="#f1f1f5"
		/>
		<g transform="matrix(.9903 -.139173 .139173 .9903 21.09419 34.967509)">
			<path
				d="M29.621 4.046c-.623.003-1.239.121-1.818.348-.927-1.472-2.553-2.368-4.304-2.371-.623.003-1.239.121-1.818.348a5.08 5.08 0 0 0-8.61 0c-.578-.227-1.194-.345-1.816-.348-2.818 0-5.102 2.264-5.102 5.057v1.011h-1.02c-1.497.114-2.883.823-3.844 1.966S-.127 12.68.031 14.16v2.023c0 6.01 3.294 16.182 15.642 16.182h6.806c6.759-.008 12.237-5.437 12.244-12.137V9.103c0-1.341-.538-2.627-1.494-3.576s-2.254-1.481-3.608-1.481z"
				fill="#aeb1b5"
				fillRule="nonzero"
			/>
			<path
				d="M30.642 20.228c0 4.469-3.655 8.091-8.163 8.091h-6.808c-11.236 0-11.559-10.899-11.559-12.137V14.16c0-.848.355-2.023 1.02-2.023h1.02v2.023c0 1.117.914 2.023 2.041 2.023s2.041-.906 2.041-2.023V7.08c0-.559.457-1.011 1.02-1.011s1.02.453 1.02 1.011v2.023c0 1.117.914 2.023 2.041 2.023s2.041-.906 2.041-2.023V5.058c0-.559.457-1.011 1.02-1.011s1.02.453 1.02 1.011v4.046c0 1.117.914 2.023 2.041 2.023s2.041-.906 2.041-2.023V7.08c0-.559.457-1.011 1.02-1.011s1.02.453 1.02 1.011v5.057c0 1.117.914 2.023 2.041 2.023s2.041-.906 2.041-2.023V9.103c0-.559.457-1.011 1.02-1.011s1.02.453 1.02 1.011v11.125z"
				fill="#fff"
			/>
		</g>
	</Icon>
);
