import Icon, { IconProps } from '@pushpay/icon';

import { TOGGLE_LIGHT_THEME, TOGGLE_DARK_THEME } from './darkModeToggleStyles';

type ToggleIconProps = {
	isDarkMode: boolean;
} & IconProps;

export const ToggleIcon = ({ isDarkMode, ...rest }: ToggleIconProps) => (
	<Icon viewBox="0 0 16 16" {...rest}>
		{isDarkMode ? (
			<path
				d="M6.8 1.93c.21-.03.42.05.56.2.13.16.19.37.13.58a5.3 5.3 0 005.87 6.62c.2-.02.41.06.55.21.14.16.19.37.13.57a6.63 6.63 0 01-7.27 4.83A6.59 6.59 0 011 8.42c0-3.3 2.5-6.08 5.8-6.5zm7.62 3.38c.16 0 .3.12.31.27l.03.17c.06.4.39.73.8.8l.17.02c.15.02.27.15.27.3 0 .16-.12.3-.27.31l-.17.03a.95.95 0 00-.8.8l-.03.16a.31.31 0 01-.3.27.31.31 0 01-.32-.27L14.08 8a.95.95 0 00-.8-.8l-.17-.02a.31.31 0 01-.27-.3c0-.16.12-.3.27-.31l.17-.03a.95.95 0 00.8-.8l.03-.16a.31.31 0 01.31-.27zM11.58 0c.16 0 .29.11.31.27l.04.29a1.9 1.9 0 001.61 1.6l.3.03c.15.03.27.16.27.31 0 .16-.12.29-.28.31l-.29.04a1.9 1.9 0 00-1.6 1.6l-.05.28a.31.31 0 01-.31.27.31.31 0 01-.31-.27l-.05-.29a1.9 1.9 0 00-1.6-1.59l-.3-.04a.31.31 0 010-.62l.3-.04a1.9 1.9 0 001.6-1.6l.05-.28a.31.31 0 01.3-.27z"
				fill={TOGGLE_DARK_THEME}
				fillRule="nonzero"
			/>
		) : (
			<path
				d="M8 13.69c.37 0 .67.3.67.67v.97a.67.67 0 01-1.34 0v-.97c0-.37.3-.67.67-.67zm-4.97-1.67a.67.67 0 11.95.95l-.7.69a.67.67 0 11-.94-.95zm9 .01a.67.67 0 01.94 0l.69.68a.67.67 0 01-.95.95l-.69-.7a.67.67 0 01.01-.93zM8 3.1a4.9 4.9 0 11-.01 9.81A4.9 4.9 0 018 3.1zM1.64 7.33a.67.67 0 010 1.34H.67a.67.67 0 010-1.34zm13.7 0a.67.67 0 010 1.34h-.98a.67.67 0 010-1.34zM2.34 2.35a.67.67 0 01.94 0l.69.68a.67.67 0 11-.95.95l-.69-.7a.67.67 0 01.01-.93zm10.36 0a.67.67 0 01.95.94l-.7.69a.67.67 0 11-.94-.95zM8 0c.37 0 .67.3.67.67v.97a.67.67 0 01-1.34 0V.67C7.33.3 7.63 0 8 0z"
				fill={TOGGLE_LIGHT_THEME}
				fillRule="nonzero"
			/>
		)}
	</Icon>
);
