import { useCallback, useMemo } from 'react';

import { getDynamicFieldsItems } from '@pushpay/forms';
import { InfoIcon } from '@pushpay/iconography';
import { Tiles } from '@pushpay/layout';
import { clsx, createUseStyles, multiply } from '@pushpay/styles';
import { Theme, useMediaBreakpoint } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';
import { ItemPreviewProps } from '@src/pages/appDesign/components/Preview/contents/ItemPreviewContent/ItemPreview';

import { ItemPropertiesFields } from '../types';
import { ItemAction, ActionBarPositionField, HideOriginalPropertyField } from './ItemAction';

type ItemActionsProps = {
	fields: ItemPropertiesFields;
	disabled?: boolean;
};

type ActionBarProperty = {
	propertyId: string;
	propertyName: string;
	actionBarPositionField: ActionBarPositionField;
	actionBarHideOriginalPropertyField: HideOriginalPropertyField;
	isPropertySelected: boolean;
};

type InputProperties = ItemPreviewProps['input']['properties'][number];

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	title: {
		display: 'flex',
		alignItems: 'center',
		font: theme.typography['heading-4'],
		height: theme.SPACING.MEDIUM,
		marginBottom: multiply(theme.SPACING.XSMALL, 1.5),
	},
	titleTooltip: {
		marginLeft: theme.SPACING.XXSMALL,
		zIndex: 1,
	},
}));

export const ItemActions = ({
	'data-pp-at-target': targetId,
	classes: classesProp,
	disabled = false,
	fields,
	className,
}: ComponentProps<ItemActionsProps, typeof useStyles>) => {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const isTablet = useMediaBreakpoint('DESKTOP_AND_BELOW');

	const getPropertyType = useCallback(
		(property: InputProperties) => {
			const inputType = Object.keys(property)[0] as keyof InputProperties;

			switch (inputType) {
				case 'addressPropertyInput':
					return translate('defaultValue.propertyDefinition.addressProperty.header');
				case 'addToContactsPropertyInput':
					return translate('defaultValue.propertyDefinition.addToContactsProperty.header');
				case 'appLinkPropertyInput':
					return translate('defaultValue.propertyDefinition.appLinkProperty.header');
				case 'audioPropertyInput':
					return translate('defaultValue.propertyDefinition.audioProperty.header');
				case 'blankifyPropertyInput':
					return translate('defaultValue.propertyDefinition.blankifyProperty.header');
				case 'callToActionPropertyInput':
					return translate('defaultValue.propertyDefinition.callToActionProperty.header');
				case 'carouselPropertyInput':
					return translate('defaultValue.propertyDefinition.carouselProperty.header');
				case 'givePropertyInput':
					return translate('defaultValue.propertyDefinition.giveProperty.header');
				case 'keyMetricsPropertyInput':
					return translate('defaultValue.propertyDefinition.keyMetricsProperty.header');
				case 'sharePropertyInput':
					return translate('defaultValue.propertyDefinition.shareProperty.header');
				case 'smsPropertyInput':
					return translate('defaultValue.propertyDefinition.smsProperty.header');
				case 'textHtmlPropertyInput':
					return translate('defaultValue.propertyDefinition.textHtmlProperty.header');
				case 'textPropertyInput':
					return translate('defaultValue.propertyDefinition.textProperty.header');
				case 'timeframePropertyInput':
					return translate('defaultValue.propertyDefinition.timeFrameProperty.header');
				case 'userNotePropertyInput':
					return translate('defaultValue.propertyDefinition.userNoteProperty.header');
				case 'videoPropertyInput':
					if (property.videoPropertyInput.baseProperty.icon.value === 'custom-vimeo')
						return translate('defaultValue.propertyDefinition.vimeoProperty.header');
					if (property.videoPropertyInput.baseProperty.icon.value === 'custom-resi')
						return translate('defaultValue.propertyDefinition.resiProperty.header');
					return translate('defaultValue.propertyDefinition.videoProperty.header');
				case 'websitePropertyInput':
					return translate('defaultValue.propertyDefinition.websiteProperty.header');
				// TODO CAPPS-11518:
				// After being blocked by CAPPS-11515, this can be uncommented and updates need to be made to their respective item property components
				// case 'emailPropertyInput':
				//   return translate('defaultValue.propertyDefinition.emailProperty.header');
				// case 'facetimePropertyInput':
				//   return translate('defaultValue.propertyDefinition.facetimeProperty.header');
				// case 'phonePropertyInput':
				//   return translate('defaultValue.propertyDefinition.phoneProperty.header');
				default:
					return '';
			}
		},
		[translate]
	);
	const propertiesFields = getDynamicFieldsItems(fields);
	const actionBarProperties = useMemo(() => {
		const propertyList: ActionBarProperty[] = [];

		propertiesFields.forEach(propertyField => {
			const [property] = Object.values(propertyField);
			const propertyHasAction = property?.baseProperty?.action?.click?.value !== null;
			if (propertyHasAction) {
				const { baseProperty } = property;
				propertyList.push({
					propertyId: baseProperty.id.value as string,
					propertyName: baseProperty.header.value || getPropertyType(propertyField),
					actionBarPositionField: baseProperty.actionBar?.position,
					actionBarHideOriginalPropertyField: baseProperty.actionBar?.hideOriginalProperty,
					isPropertySelected: (baseProperty.actionBar?.position.value ?? 0) > 0,
				});
			}
		});

		return propertyList;
	}, [getPropertyType, propertiesFields]);

	const getItemActionDataForPosition = useMemo(
		() => (actionBarPosition: number) => {
			const isSelectedForCurrentPosition = (actionBarPositionField: ActionBarPositionField) =>
				actionBarPositionField.value === actionBarPosition;
			const currentSelectedProperty = actionBarProperties.find(({ actionBarPositionField }) =>
				isSelectedForCurrentPosition(actionBarPositionField)
			);
			const defaultActionBarPositionField = {
				id: `temp-field-${actionBarPosition}`,
				value: 0,
			} as ActionBarPositionField;
			const defaultDisplayOption = {
				display: translate('itemSetting.itemActionPlaceHolder'),
				value: `default-option-id`,
				field: defaultActionBarPositionField,
			};
			const displayOptions = [
				...actionBarProperties
					.filter(
						({ isPropertySelected, actionBarPositionField }) =>
							!isPropertySelected || isSelectedForCurrentPosition(actionBarPositionField)
					)
					.map(({ propertyId, propertyName, actionBarPositionField }) => ({
						display: propertyName,
						value: propertyId,
						field: actionBarPositionField,
					})),
				defaultDisplayOption,
			];

			return {
				selectedValue: currentSelectedProperty?.propertyId || defaultDisplayOption.value,
				hideOriginalPropertyField: currentSelectedProperty?.actionBarHideOriginalPropertyField || null,
				actionBarPositionField:
					currentSelectedProperty?.actionBarPositionField || defaultActionBarPositionField,
				currentPosition: actionBarPosition,
				options: displayOptions,
			};
		},
		[actionBarProperties, translate]
	);
	const itemActionsData = [1, 2, 3, 4].map(getItemActionDataForPosition);

	return (
		<div className={clsx(classes.root, className)}>
			<div className={classes.title}>
				{translate('itemSetting.itemActions')}
				<Tooltip
					classes={{ root: classes.titleTooltip }}
					content={translate('itemSetting.itemActionsTooltip')}
					data-pp-at-target={`${targetId}-toggle-tooltip`}
					panelSpace="XXSMALL"
					placement="top"
				>
					<InfoIcon data-pp-at-target={`${targetId}-tip-icon`} />
				</Tooltip>
			</div>
			<Tiles columns={isTablet ? 2 : 4} data-pp-at-target={targetId}>
				{itemActionsData.map(
					({
						currentPosition,
						options,
						selectedValue,
						actionBarPositionField,
						hideOriginalPropertyField,
					}) => (
						<div key={`item-action-bar-${currentPosition}`}>
							<ItemAction
								actionBarPositionField={actionBarPositionField}
								disabled={disabled}
								hideOriginalPropertyField={hideOriginalPropertyField}
								options={options}
								position={currentPosition}
								selectedValue={selectedValue}
							/>
						</div>
					)
				)}
			</Tiles>
		</div>
	);
};
