import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useIconPickerStyles = createUseStyles((theme: Theme) => ({
	root: {},
	modal: {
		borderRadius: theme.SHAPE.ROUNDED_CORNERS,
	},
	cardBody: {
		padding: theme.SPACING.LARGE,
		backgroundColor: theme.colors['background-page'],
	},
	scrollContainer: {
		backgroundColor: theme.colors['background-surface'],
		borderRadius: '4px',
	},
	heading: {
		color: theme.colors['text-heading'],
	},
	mask: {
		backgroundColor: theme.colors['grey-600'],
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	closeModalButton: {
		top: theme.SPACING.LARGE,
		right: theme.SPACING.MEDIUM,
	},
}));
