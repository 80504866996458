import { useCallback, useMemo } from 'react';

import { Permission, Permissions } from '@pushpay/identity.permissions';

import { useParams } from '@src/router';

import { useMyIdentity } from './myIdentity';

const hasAPermission = (permission: Permission, perms?: { permissions?: { name: string }[] }) =>
	perms?.permissions?.some(p => p?.name === permission.name) ?? false;

type PermissionParams = 'organizationKey' | 'campusKey';

export const useMyPermissions = () => {
	const { allPermissions } = useMyIdentity();
	const { organizationKey: organizationKeyParam } = useParams<PermissionParams>();

	const hasOrganizationPermission = useCallback(
		(permission: Permission, organizationKeyArg?: string) => {
			const organizationKey = organizationKeyArg ?? organizationKeyParam;
			const currentOrganizationPermissions = allPermissions?.organizationPermissions.find(
				op => op?.organizationKey === organizationKey
			);
			return hasAPermission(permission, currentOrganizationPermissions) ?? false;
		},
		[allPermissions?.organizationPermissions, organizationKeyParam]
	);

	const hasPlatformCampusPermission = useCallback(
		(permission: Permission, organizationKeyArg?: string) => {
			const organizationKey = organizationKeyArg ?? organizationKeyParam;
			const currentOrganizationPermissions = allPermissions?.organizationPermissions.find(
				op => op?.organizationKey === organizationKey
			);

			const currentPlatformCampusPermissions = currentOrganizationPermissions?.campusPermissions.filter(cp =>
				cp?.permissions.some(p => p?.name === permission.name)
			);

			return currentPlatformCampusPermissions && currentPlatformCampusPermissions.length > 0;
		},
		[allPermissions?.organizationPermissions, organizationKeyParam]
	);

	const hasOrganizationOrCampusPermission = useCallback(
		(permission: Permission, organizationKeyArg?: string) =>
			hasOrganizationPermission(permission, organizationKeyArg) ||
			hasPlatformCampusPermission(permission, organizationKeyArg),
		[hasOrganizationPermission, hasPlatformCampusPermission]
	);

	const hasAppStudioPermission = useMemo(
		() => hasOrganizationOrCampusPermission(Permissions.mobileApplicationStudioAccess),
		[hasOrganizationOrCampusPermission]
	);

	return {
		hasOrganizationPermission,
		hasPlatformCampusPermission,
		hasOrganizationOrCampusPermission,
		hasAppStudioPermission,
	};
};
