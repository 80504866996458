import { ChildContainerFragment, ChildItemFragment, ContainerType } from '@src/graphql/generated';

type FieldsEnablement = {
	isLayoutOptionsEnabled: boolean;
	isTitleBarDropdownDisplayed: boolean;
	isTitleBarTextDisplayed: boolean;
	isDHSOptionsEnabled: boolean;
	isRelatedContentOptionsEnabled: boolean;
	isAttributeEnabled: boolean;
	isGivingLinkEnabled: boolean;
	isSubtitleEnabled: boolean;
	isResiFeedEnabled: boolean;
	isImageEnabled: boolean;
};

export function getFieldsEnablement(
	containerType: ContainerType,
	containerChildren: (ChildContainerFragment | ChildItemFragment)[],
	isInAppCalendarEnabled: boolean
): FieldsEnablement | null {
	const defaultEnablement = {
		isGivingLinkEnabled: false,
		isDHSOptionsEnabled: true,
		isRelatedContentOptionsEnabled: true,
		isLayoutOptionsEnabled: true,
		isTitleBarDropdownDisplayed: true,
		isTitleBarTextDisplayed: false,
		isAttributeEnabled: true,
		isSubtitleEnabled: true,
		isResiFeedEnabled: false,
		isImageEnabled: true,
	};
	switch (containerType) {
		case ContainerType.Default:
			return {
				...defaultEnablement,
			};
		case ContainerType.All:
			return {
				...defaultEnablement,
				isDHSOptionsEnabled: false,
				isRelatedContentOptionsEnabled: false,
				isAttributeEnabled: false,
			};
		case ContainerType.Give:
			return {
				...defaultEnablement,
				isGivingLinkEnabled: true,
				isDHSOptionsEnabled: false,
				isRelatedContentOptionsEnabled: false,
				isLayoutOptionsEnabled: false,
				isTitleBarDropdownDisplayed: false,
			};
		case ContainerType.Events: {
			const hasChildEventContainers =
				containerChildren
					.filter((child): child is ChildContainerFragment => child.__typename === 'ChildContainer')
					.filter(child => child.container.type === ContainerType.Events).length > 0;

			return {
				...defaultEnablement,
				isTitleBarTextDisplayed: isInAppCalendarEnabled && hasChildEventContainers,
				isTitleBarDropdownDisplayed: !isInAppCalendarEnabled,
				isLayoutOptionsEnabled: false,
			};
		}
		case ContainerType.SelectCampus:
		case ContainerType.Favorites:
		case ContainerType.PushCategory:
		case ContainerType.CcbCheckIn:
		case ContainerType.CcbGroups:
		case ContainerType.CcbPublicNeeds:
		case ContainerType.CcbServing:
			return {
				...defaultEnablement,
				isDHSOptionsEnabled: false,
				isRelatedContentOptionsEnabled: false,
				isLayoutOptionsEnabled: false,
				isTitleBarDropdownDisplayed: false,
				isAttributeEnabled: false,
				isSubtitleEnabled: false,
			};
		case ContainerType.Messages:
			return {
				...defaultEnablement,
				isDHSOptionsEnabled: false,
				isRelatedContentOptionsEnabled: false,
				isLayoutOptionsEnabled: false,
				isTitleBarDropdownDisplayed: false,
				isAttributeEnabled: false,
				isSubtitleEnabled: false,
				isImageEnabled: false,
			};
		case ContainerType.Places:
			return {
				...defaultEnablement,
				isDHSOptionsEnabled: false,
			};
		case ContainerType.Resi:
			return {
				...defaultEnablement,
				isDHSOptionsEnabled: false,
				isRelatedContentOptionsEnabled: false,
				isLayoutOptionsEnabled: false,
				isTitleBarDropdownDisplayed: false,
				isSubtitleEnabled: false,
				isResiFeedEnabled: true,
			};
		default:
			return null;
	}
}
