import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	label: {
		font: theme.typography['text-4'],
		margin: `${multiply(theme.SPACING.XSMALL, 1.5)} 0 ${theme.SPACING.XSMALL} 0`,
	},
	inputLabel: {
		display: 'flex',
		font: theme.typography['text-4'],
		color: theme.colors['text-default'],
	},
	inputLabelTooltip: {
		marginLeft: theme.SPACING.XXSMALL,
	},
	checkboxInput: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		padding: 0,
	},
	checkboxInputLabel: {
		font: theme.typography['text-4'],
		alignItems: 'center',
		width: 'auto',
		lineHeight: theme.SPACING.SMALL,
		margin: `0 0 0 ${theme.SPACING.XSMALL}`,
	},
	checkboxTile: {
		flexBasis: 'unset',
		maxWidth: 'unset',
	},
	stackItem: {
		paddingTop: 0,
	},
	tile: {
		padding: `0 0 0 ${theme.SPACING.MEDIUM}`,
	},
	column: {
		marginTop: theme.SPACING.SMALL,
	},
	expandableAttributesBody: {
		paddingTop: 0,
	},
	title: {
		font: theme.typography['heading-4'],
		height: '24px',
		lineHeight: '24px',
		marginBottom: theme.SPACING.XSMALL,
	},
}));
