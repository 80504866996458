import { Stack } from '@pushpay/layout';
import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentPropsWithoutPpAtTarget } from '@pushpay/types';

const usePageSectionStyles = createUseStyles({
	detailsPageSections: {
		maxWidth: '1200px',
	},
});

export const PageSections = ({
	detailsPage,
	children,
	classes: classesProp,
	className: classNameProp,
	...rest
}: ComponentPropsWithoutPpAtTarget<{ detailsPage?: boolean }, typeof usePageSectionStyles>) => {
	const classes = usePageSectionStyles(classesProp);
	const className = clsx(classNameProp, detailsPage && classes.detailsPageSections);

	return (
		<Stack className={className} space={['LARGE', 'XLARGE']} {...rest}>
			{children}
		</Stack>
	);
};
