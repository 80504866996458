import { useApolloClient } from '@apollo/client';

import { MyOrganizationDocument, MyOrganizationQuery } from '@src/graphql/generated';
import { useParams } from '@src/router';

export function useMyOrganization() {
	const client = useApolloClient();
	const { organizationKey } = useParams<'organizationKey'>();
	const data = client.readQuery<MyOrganizationQuery>({
		query: MyOrganizationDocument,
		variables: { organizationKey },
	});

	if (data === null) {
		throw new Error('useMyOrganization must be used within child components of MyOrganization');
	}

	return data?.organization as NonNullable<MyOrganizationQuery['organization']>;
}
