import { useEffect, useState } from 'react';

import { useTestWidgetSettings } from '@src/components/testWidget/useTestWidgetSettings';
import { featureFlagManager } from '@src/featureFlags';

export function useTestWidgetFeatureFlags() {
	const [featureFlagOverrides, dispatch] = useTestWidgetSettings('featureFlagOverrides');
	const [allFeatureFlags, setAllFeatureFlags] = useState(featureFlagManager.allFlags);

	useEffect(function listToFeatureFlagValueChanges() {
		return featureFlagManager.listen(() => setAllFeatureFlags(featureFlagManager.allFlags));
	}, []);

	return {
		allFeatureFlags,
		featureFlagOverrides,
		dispatch,
	};
}
