import { useCallback } from 'react';

import { FormState, useMutateField } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { TextEditor, EditorStateContent } from '@src/components/textEditor';
import { BlankifyPropertyInput } from '@src/graphql/generated';

export type BlankifyHtmlProps = ComponentProps<{ propertyField: FormState<BlankifyPropertyInput> }, undefined>;

export const BlankifyHtml = ({ propertyField }: BlankifyHtmlProps) => {
	const { content } = propertyField;

	const { mutateField: mutateBlankify } = useMutateField(content as FormState<BlankifyPropertyInput['content']>);

	const onEditHandler = useCallback(
		(editorStateContent: EditorStateContent) => {
			const { textHtml } = editorStateContent;

			mutateBlankify(textHtml);
		},
		[mutateBlankify]
	);

	const textHtml = content?.value || '';

	return <TextEditor textHtml={textHtml} includeBlankifyOption onEdit={onEditHandler} />;
};
