import { FormState } from '@pushpay/forms';

import { useFormContext } from '@src/context';
import { GetItemQuery, ItemTemplate, SaveItemSettingsSchema } from '@src/graphql/generated';

import { ItemPreview } from './ItemPreview';
import { ItemWebview } from './ItemWebview';

export function ItemPreviewContent({ data }: { data: GetItemQuery }) {
	const { formState } = useFormContext();
	const container = data.organization?.application?.container;

	if (!formState || !container?.item) {
		return null;
	}

	const { input } = formState as FormState<SaveItemSettingsSchema>;

	if (input.template.value === ItemTemplate.DetailWebview) {
		return <ItemWebview input={input} />;
	}

	return <ItemPreview container={container} input={input} />;
}
