import { lazy } from 'react';

import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { EntitlementKey } from '@src/graphql/generated';
import { AppDesign, AppDesignPage } from '@src/pages/appDesign';
import { Profile } from '@src/pages/profile';
import { Shell, OrgRoot, orgRootLoader } from '@src/pages/shell';
import { RedirectToOrganization } from '@src/pages/shell/RedirectToOrganization';

import * as ROUTE_PATHS from './routePaths';

const ContainerChildren = lazy(
	() => import(/* webpackChunkName: 'containerChildren' */ '@src/pages/containerChildren')
);
const ContainerSettings = lazy(
	() => import(/* webpackChunkName: 'containerSettings' */ '@src/pages/containerSettings')
);
const DHS = lazy(() => import(/* webpackChunkName: 'dhs' */ '@src/pages/dhs'));
const Item = lazy(() => import(/* webpackChunkName: 'item' */ '@src/pages/itemSettings'));
const Root = lazy(() => import(/* webpackChunkName: 'root' */ '@src/pages/root'));

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Shell />}>
			<Route element={<OrgRoot />} loader={orgRootLoader} path={`${ROUTE_PATHS.ORGANIZATION_ROOT}/*`}>
				<Route element={<AppDesign />} path={`${ROUTE_PATHS.APP}/*`}>
					<Route
						element={
							<AppDesignPage page="containerChildren" position="lastPosition">
								<ContainerChildren />
							</AppDesignPage>
						}
						path={ROUTE_PATHS.CONTAINER_CHILDREN}
					/>
					<Route
						element={
							<AppDesignPage page="containerSettings">
								<ContainerSettings />
							</AppDesignPage>
						}
						path={ROUTE_PATHS.CONTAINER_SETTINGS}
					/>
					<Route
						element={
							<AppDesignPage entitlement={EntitlementKey.AppDynamicHomeScreen} page="dhs">
								<DHS />
							</AppDesignPage>
						}
						path={ROUTE_PATHS.DHS}
					/>
					<Route
						element={
							<AppDesignPage page="item">
								<Item />
							</AppDesignPage>
						}
						path={ROUTE_PATHS.CONTAINER_ITEM}
					/>
					<Route
						element={
							<AppDesignPage page="root" position="lastPosition" clearPagesPosition>
								<Root data-pp-at-target="root" />
							</AppDesignPage>
						}
						path="*"
					/>
				</Route>
				<Route element={<Profile />} path={ROUTE_PATHS.PROFILE} />
				<Route element={<RedirectToOrganization />} path="*" />
			</Route>
			<Route element={<RedirectToOrganization />} path="*" />
		</Route>
	)
);
