import { AnchorHTMLAttributes, ReactNode, useMemo, useState } from 'react';

import { NavLink } from 'react-router-dom';

import { useLinkStyles } from '@pushpay/link';
import { createUseStyles, clsx } from '@pushpay/styles';
import { ComponentProps, XOR } from '@pushpay/types';

import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { useDataLoader } from './useDataLoader';

type LinkProps = XOR<
	{
		/**
		 * Specifies the URL of the page the link goes to
		 */
		href: string;
	},
	{
		/**
		 * Specifies the route path goes to
		 */
		to: string;
	}
> & {
	children: ReactNode;
	displayStyle?: 'text' | 'unstyled';
	preload?: boolean;
};

const useStyles = createUseStyles({
	root: {},
	text: {},
	active: {},
	unstyled: {},
});

type LinkComponentProps = ComponentProps<LinkProps, typeof useStyles, AnchorHTMLAttributes<HTMLAnchorElement>>;

/**
 * Will enhance the string provided as rel attribute on link with required values if target is specified
 * @param target link target attribute
 * @param rel link rel attribute
 * @returns the computed rel attribute value guaranteed to contain 'noreferrer noopener' if target is specified
 */
function computeRelAttribute(target: string | undefined, rel: string | undefined): string | undefined {
	if (!target) {
		return rel;
	}

	const definedRelParts = rel ? rel.split(' ') : [];
	const requiredRelParts = ['noreferrer', 'noopener'];
	const uniqueRelParts = Array.from(new Set([...definedRelParts, ...requiredRelParts]));
	return uniqueRelParts.join(' ');
}

export const Link = ({
	to,
	href,
	target,
	rel,
	displayStyle = 'text',
	preload: preloadProp = true,
	classes: classesProp,
	className: classNameProp,
	children,
	...rest
}: LinkComponentProps) => {
	const linkClasses = useLinkStyles();
	const classes = useStyles(classesProp);
	const { platformCampusKey, applicationId, campusId } = useGetQueryAndMutationVars();
	const [startLoader, cancelLoader] = useDataLoader({
		applicationId,
		campusId,
		platformCampusKey,
	});

	const [preload, setPreload] = useState(preloadProp);

	const className = clsx(
		classNameProp,
		classes.root,
		displayStyle && linkClasses[displayStyle],
		displayStyle && classes[displayStyle]
	);

	const computedRel = useMemo(() => computeRelAttribute(target, rel), [target, rel]);
	const startPreload = () => startLoader(to, () => setPreload(false));

	return to ? (
		<NavLink
			className={({ isActive }) => (isActive ? clsx(className, classes.active) : className)}
			rel={computedRel}
			target={target}
			to={to}
			{...(preload && {
				onMouseEnter: startPreload,
				onTouchStart: startPreload,
				onMouseLeave: cancelLoader,
			})}
			{...rest}
		>
			{children}
		</NavLink>
	) : (
		<a className={className} href={href} rel={computedRel} target={target} {...rest}>
			{children}
		</a>
	);
};
