import { DropdownDirection } from '@pushpay/dropdown';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { getShadow } from '@src/shared/styles';

import { KebabMenuItemType } from './KebabMenu';

export const kebabMenuOptionHeight = 32;

export type KebabMenuStyleProps = {
	direction?: DropdownDirection;
	type?: KebabMenuItemType;
};

const useStyles = createUseStyles((theme: Theme) => {
	const optionTextColor = {
		common: theme.colors['text-default'],
		disabled: theme.colors['text-disabled'],
		delete: theme.colors['red-500'],
	};

	return {
		kebabMenu: ({ direction = 'bottom' }: KebabMenuStyleProps) => ({
			backgroundColor: theme.colors['common-white'],
			position: 'absolute',
			right: '-50px',
			...(direction === 'top' && { bottom: 0 }),
			zIndex: 5,
			display: 'flex',
			flexDirection: 'column',
			...getShadow(),
			borderRadius: theme.SHAPE.ROUNDED_CORNERS,
		}),
		option: ({ type = 'common' }: KebabMenuStyleProps) => ({
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			width: '220px',
			height: `${kebabMenuOptionHeight}px`,
			margin: '0px',
			padding: '6px 12px',
			borderRadius: theme.SHAPE.ROUNDED_CORNERS,
			...(type !== 'disabled' && {
				'&:hover': {
					backgroundColor: theme.colors['grey-200'],
				},
			}),
			...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
		}),
		optionText: ({ type = 'common' }: KebabMenuStyleProps) => ({
			color: optionTextColor[type],
		}),
		optionIcon: {
			color: theme.colors['text-default'],
			margin: '8px 12px',
			width: theme.SPACING.MEDIUM_ICON_SIZE,
			height: theme.SPACING.MEDIUM_ICON_SIZE,
		},
		disabledIcon: {
			color: theme.colors['text-disabled'],
		},
	};
});

export default useStyles;
