import { Form } from '@pushpay/forms';
import { Heading } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { PageSections } from '@src/components/layout';
import { useTranslation } from '@src/i18n';

import { LocationSection } from './LocationSection';
import { PersonalDetailsSection } from './PersonalDetailsSection';
import { profileStyles } from './profileStyles';
import { ProfileProps } from './types';
import { useEditingProfile } from './useEditingProfile';
import { useEditProfileForm } from './useEditProfileForm';

const useProfileStyles = createUseStyles((theme: Theme) => ({
	...profileStyles(theme),
	root: {
		width: '100%',
	},
}));

export type ProfileComponentProps = ComponentProps<ProfileProps, typeof useProfileStyles>;

export const ProfilePage = ({ profile, countries, 'data-pp-at-target': targetId }: ProfileComponentProps) => {
	const classes = useProfileStyles(undefined);
	const { translate } = useTranslation('profile');
	const { formState, submit, formContext, saving, reset, saveButtonState } = useEditProfileForm(profile);
	const editingProfile = useEditingProfile(reset);
	const {
		input: { timeZone, emailAddresses },
	} = formState;
	const { olsonTimeZone } = profile;

	return (
		<Form
			classes={{ root: classes.root }}
			data-pp-at-target={`${targetId}-form`}
			disabled={saving}
			formContext={formContext}
			onSubmit={submit}
		>
			<PageSections className={classes.pageWrapper} detailsPage>
				<Heading data-pp-at-target={`${targetId}-heading`} level="1">
					{translate('pageTitle')}
				</Heading>
				<PersonalDetailsSection
					emailAddressField={emailAddresses[0]}
					profile={profile}
					saveButtonState={saveButtonState}
					{...editingProfile}
					data-pp-at-target={`${targetId}-personal-details`}
				/>
				<LocationSection
					countries={countries}
					olsonTimeZone={olsonTimeZone}
					saveButtonState={saveButtonState}
					timeZoneField={timeZone}
					{...editingProfile}
					data-pp-at-target={`${targetId}-location`}
				/>
			</PageSections>
		</Form>
	);
};
