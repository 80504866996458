import type { EditorThemeClasses } from 'lexical';

export const pushpayEditorTheme: EditorThemeClasses = {
	heading: {
		h1: 'ppEditorTheme__h1',
		h2: 'ppEditorTheme__h2',
	},
	indent: 'ppEditorTheme__indent',
	link: 'ppEditorTheme__link',
	list: {
		listitem: 'ppEditorTheme__listItem',
		nested: {
			listitem: 'ppEditorTheme__nestedListItem',
		},
		olDepth: [
			'ppEditorTheme__ol1',
			'ppEditorTheme__ol2',
			'ppEditorTheme__ol3',
			'ppEditorTheme__ol4',
			'ppEditorTheme__ol5',
		],
		ul: 'ppEditorTheme__ul',
	},
	ltr: 'ppEditorTheme__ltr',
	paragraph: 'ppEditorTheme__paragraph',
	quote: 'ppEditorTheme__quote',
	rtl: 'ppEditorTheme__rtl',
	text: {
		bold: 'ppEditorTheme__textBold',
		italic: 'ppEditorTheme__textItalic',
		strikethrough: 'ppEditorTheme__textStrikethrough',
		underline: 'ppEditorTheme__textUnderline',
		underlineStrikethrough: 'ppEditorTheme__textUnderlineStrikethrough',
	},
	blankify: 'blankify',
};
