import { PropsWithChildren, useState } from 'react';

import {
	DndContext,
	DragEndEvent,
	DragStartEvent,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';

import { CardProps } from '@src/components/card';
import { DraggableOverlay } from '@src/components/draggable';
import { Draggable } from '@src/components/draggable/types';
import { PropertyHeaderProps } from '@src/components/properties';
import { useDraggablesDataContext } from '@src/context/draggableData/draggablesDataContext';

import { CONSTANTS } from '../itemSettings/utils';
import { useAddingContainerAttributeHandler } from './hooks/dragEndHandlers/useAddingContainerAttributeHandler';
import { useCreatingContainerHandler } from './hooks/dragEndHandlers/useCreatingContainerHandler';
import { useCreatingItemHandler } from './hooks/dragEndHandlers/useCreatingItemHandler';
import { useReorderContainerHandler } from './hooks/dragEndHandlers/useReorderContainerHandler';
import { useLocalizedAnnouncementsAndInstructions } from './hooks/useLocalizedAnnouncementsAndInstructions';
import { DropHandler } from './type';

export function AppDesignDragAndDrop({ children }: PropsWithChildren) {
	const { draggables } = useDraggablesDataContext();
	const [draggingItem, setDraggingItem] = useState<Draggable | CardProps | PropertyHeaderProps | null>(null);
	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 5,
		},
	});
	const touchSensor = useSensor(TouchSensor, {
		activationConstraint: {
			distance: 5,
		},
	});
	const keyboardSensor = useSensor(KeyboardSensor);
	const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
	const dropHandlers: DropHandler[] = [
		useReorderContainerHandler(),
		useCreatingContainerHandler(),
		useCreatingItemHandler(),
		useAddingContainerAttributeHandler(),
	];
	const { announcements, screenReaderInstructions } = useLocalizedAnnouncementsAndInstructions();

	const onDragStartHandler = ({ active }: DragStartEvent) => {
		if (active.data.current?.sortable) {
			const isPropertyType = active.data.current?.type === CONSTANTS.PROPERTY_TYPE;

			if (isPropertyType) {
				const { id, icon, header, type } = active.data?.current;
				const headerProps: PropertyHeaderProps = { id, icon, header, type };

				setDraggingItem(headerProps);
			} else {
				const { id, parentId, icon, name, type, isHidden, isMirrored } = active.data?.current;
				const cardProps = {
					id,
					parentId,
					icon,
					name,
					type,
					isHidden,
					isMirrored,
				} as CardProps;
				setDraggingItem(cardProps);
			}
		} else {
			const draggingItemData = draggables.find(item => item.name === active?.data?.current?.name);
			if (draggingItemData) {
				setDraggingItem(draggingItemData);
			}
		}
	};

	const onDragEndHandler = (event: DragEndEvent) => {
		const isPropertyType = draggingItem?.type === CONSTANTS.PROPERTY_TYPE || draggingItem?.type === 'property';

		if (!draggingItem || isPropertyType) {
			setDraggingItem(null);
			return;
		}

		dropHandlers.forEach(handler => handler(event, draggingItem));
		setDraggingItem(null);
	};

	return (
		<DndContext
			accessibility={{ announcements, screenReaderInstructions }}
			sensors={sensors}
			onDragEnd={onDragEndHandler}
			onDragStart={onDragStartHandler}
		>
			{children}
			<DraggableOverlay draggingItem={draggingItem} />
		</DndContext>
	);
}
