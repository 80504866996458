import { Field, SelectField } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { CallToActionType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

type CTAFieldProps = {
	field: Field<CallToActionType | null>;
	cardDefinitionType: 'impact' | 'base';
};

const useStyle = createUseStyles((theme: Theme) => ({
	content: {
		flex: '1 1 auto',
	},
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
	},
}));

export const CTAField = ({ field, cardDefinitionType }: CTAFieldProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyle(undefined);
	let ctaOptions: { display: string; value: CallToActionType | null }[] = [
		{ display: translate('itemSetting.dhsSetting.ctaOptions.readMore'), value: CallToActionType.ReadMore },
		{ display: translate('itemSetting.dhsSetting.ctaOptions.learnMore'), value: CallToActionType.LearnMore },
		{ display: translate('itemSetting.dhsSetting.ctaOptions.seeCampaign'), value: CallToActionType.SeeCampaign },
		{ display: translate('itemSetting.dhsSetting.ctaOptions.seeDetails'), value: CallToActionType.SeeDetails },
	];
	const extraCTAOptions = [
		{
			display: translate('itemSetting.dhsSetting.ctaOptions.getInvolved'),
			value: CallToActionType.GetInvolved,
		},
		{
			display: translate('itemSetting.dhsSetting.ctaOptions.none'),
			value: null,
		},
	];
	if (cardDefinitionType === 'impact') {
		ctaOptions = ctaOptions.concat(extraCTAOptions);
	}

	return (
		<SelectField
			classes={{ content: classes.content, label: classes.label }}
			data-pp-at-target="dhs-options-CTA-text"
			defaultValue={CallToActionType.ReadMore}
			field={field}
			label={translate('itemSetting.dhsSetting.dhsCTALabel')}
			options={ctaOptions}
			showLabel
		/>
	);
};
