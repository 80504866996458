import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

type IllustrationWithTextProps = {
	illustration: React.ReactNode;
	title?: string;
	text?: string | React.ReactNode;
};
const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '130px',
		'& > p': {
			width: '400px',
			textAlign: 'center',
		},
	},
	title: {
		marginBottom: theme.SPACING.SMALL,
	},
	text: {
		margin: 0,
		color: theme.colors['text-default'],
		lineHeight: theme.SPACING.MEDIUM,
	},
}));
export function IllustrationWithText({ illustration, title, text }: IllustrationWithTextProps) {
	const classes = useStyles(undefined);
	return (
		<div className={classes.root}>
			{illustration}
			{title && <h3 className={classes.title}>{title}</h3>}
			{typeof text === 'string' ? <p className={classes.text}>{text}</p> : text}
		</div>
	);
}
