import { FeatureFlag, FeatureFlags } from '@src/featureFlags';

const testWidgetStorageKey = '@pushpay/testWidget';

export const initialSettings = {
	visible: false,
	application: {
		pendoWhitelistEnabled: true,
		simulateCacheBusting: false,
	},
	serviceAvailability: {
		delay: 0 as number | null,
		'mobile-application-studio': true,
		identity: true,
		organization: true,
	},
	i18n: {
		accents: false,
		brackets: false,
		extraLength: 0 as number | null,
		mirror: false,
		underscores: false,
	},
	featureFlagOverrides: {} as Partial<FeatureFlags>,
};

export const extraLengthMax = 500;

export type TestWidgetSettings = typeof initialSettings;
export type TestWidgetSettingsGroup = keyof TestWidgetSettings;
export type TestWidgetApplicationSettings = TestWidgetSettings['application'];
export type TestWidgetI18nSettings = TestWidgetSettings['i18n'];
export type TestWidgetServiceAvailabilitySettings = TestWidgetSettings['serviceAvailability'];

export type TestWidgetSettingsAction =
	| { type: 'showHide' }
	| ({
			type: 'application';
	  } & Partial<{ [x in keyof TestWidgetApplicationSettings]: TestWidgetApplicationSettings[x] }>)
	| { type: 'applicationReset' }
	| ({
			type: 'i18n';
	  } & Partial<{ [x in keyof TestWidgetI18nSettings]: TestWidgetI18nSettings[x] }>)
	| { type: 'i18nReset' }
	| ({ type: 'serviceAvailability' } & Partial<{
			[x in keyof TestWidgetServiceAvailabilitySettings]: TestWidgetServiceAvailabilitySettings[x];
	  }>)
	| { type: 'serviceAvailabilityReset' }
	| ({ type: 'featureFlagOverrides' } & Record<FeatureFlag, boolean>)
	| { type: 'featureFlagOverridesReset' };

export function loadTestWidgetSettings(): TestWidgetSettings {
	const storedSettings = window.localStorage.getItem(testWidgetStorageKey);

	if (!storedSettings) {
		return initialSettings;
	}

	try {
		return JSON.parse(storedSettings);
	} catch {
		return initialSettings;
	}
}

export function saveTestWidgetSettings(settings: TestWidgetSettings) {
	window.localStorage.setItem(testWidgetStorageKey, JSON.stringify(settings));
}
