// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import audioDefaultImage from '@pushpay/app-components/dist/app/images/audio-card-img.png';
import DynamicHomeScreen from '@pushpay/app-components/dist/app/screens/DynamicHome/DynamicHomeScreen';
import { Metrics } from '@pushpay/app-components/dist/app/themes';
import { KeyMetrics } from '@pushpay/app-components/dist/app/types/content';
import { CardViewProps } from '@pushpay/app-components/dist/app/types/dhs';
import { formatTime, getRelativeTimestamp } from '@pushpay/app-components/dist/app/utils/timeframe';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import placeHolderImage from '@pushpay/app-components/dist/assets/placeholder_lmw.png';

import { convertSnakeCaseToUpperCase } from '@src/components/attributes/utils';
import { useAppPreviewContext } from '@src/context';
import { ApplicationSettingKey, FeatureKey, GetDhsCardsQuery } from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';
import { getTruncatedText, underscoreToDot } from '@src/utils';

import { DhsCard, ItemPropertyType } from './types';
import { noop } from './utils';

// TODO: current react-native-web can't support numberOfLines prop on text component
// so make it close to 3 lines by truncate to 100 char
// https://github.com/necolas/react-native-web/issues/13
const CARD_CATEGORY_MAX_LENGTH = 100;

const CARD_TYPE_MAP = {
	DefaultCard: 'default',
	SpecialCard: 'special',
	ImpactCard: 'impact',
	IntroCard: 'intro',
	EventCard: 'timeframe',
	VideoCard: 'video',
	AudioCard: 'audio',
} as const;

// not added to translation so that it matches mobile app which only supports english
const TEXT_MAP = {
	upcomingEvent: 'UPCOMING EVENT',
	event: 'EVENT',
	ready: 'READY',
	seeDetails: 'SEE DETAILS',
} as const;

export function DhsPreviewContent({ data }: { data: GetDhsCardsQuery }) {
	const { currentApp: application, getAppFeature } = useMyApp();
	const { appSettings, screenWidth } = useAppPreviewContext();

	const isProfileEnabled = getAppFeature(FeatureKey.AppProfile)?.enabled || false;
	const primaryColor = appSettings.get(underscoreToDot(ApplicationSettingKey.UiColorPrimary));

	const cards =
		data.organization?.application?.dynamicHomeScreen?.cards
			.map(card => ({
				...card,
				uuid: card.id, // used internally by DynamicHomeScreen component
			}))
			.sort(card => (card.item.cardDefinition?.isFeatured ? -1 : 0)) ?? []; // show featured card first

	if (cards.length === 0) {
		return null;
	}

	const cardActions = {
		openVideoModal: noop,
		playAudio: noop,
		pauseAudio: noop,
		stopAudio: noop,
		updateCardEvent: noop,
		updateCardItemProperties: noop,
		updateVideoStatus: noop,
		sendVideoEvent: noop,
		fetchItemIfNeeded: noop,
	};

	return (
		<DynamicHomeScreen
			cardActions={cardActions}
			cards={cards as any}
			getCardProps={getCardProps}
			isPullingToRefresh={false}
			pullToRefreshError={null}
			renderFooter={cards.length > 1}
			scrollRef={undefined as any}
			updatedAt={null}
			onBookmarkSignIn={noop}
			onDismissPullToRefreshError={noop}
			onPressCard={noop}
			onPressCategory={noop}
			onPressShare={noop}
			onRefresh={noop}
		/>
	);

	function getCardProps(uuid: string, isFirst: boolean) {
		const card = cards.find(({ id }) => id === uuid) as DhsCard;
		const isFeatured = card.item.cardDefinition?.isFeatured || false;

		// card type checks
		const cardType = card.__typename;
		const isEventCard = cardType === 'EventCard';
		const isAudioCard = cardType === 'AudioCard';
		const isVideoCard = cardType === 'VideoCard';
		const isSpecialCard = cardType === 'SpecialCard';
		const isIntroCard = cardType === 'IntroCard';
		const isImpactCard = cardType === 'ImpactCard';

		const getItemProperty = (itemType: ItemPropertyType) =>
			card.item?.properties
				?.filter(({ __typename }) => __typename === itemType)
				.sort((a, b) => a.position - b.position)?.[0];

		const hasItemProperty = (itemType: ItemPropertyType) => getItemProperty(itemType) !== undefined;

		const isAudioIncluded = (isAudioCard && card.audioUrl) || hasItemProperty('AudioProperty');
		const isVideoIncluded = getVideoUrl() !== '';

		const image = getImageUrl();
		const color = primaryColor || 'white';

		function getImageUrl() {
			const itemImage = card.item?.image?.urls?.dynamicHomeScreen || card.item?.image?.urls?.unprocessedImageUrl;
			const containerImage =
				card.item?.parentContainer?.image?.urls?.dynamicHomeScreen ||
				card.item?.parentContainer?.image?.urls?.unprocessedImageUrl;
			const applicationImage = application.images.homeScreenPlaceholder;
			const fallbackImage = itemImage || containerImage || applicationImage;

			// multi-media card
			if (isAudioIncluded && isVideoIncluded) {
				return fallbackImage;
			}

			if (isEventCard) {
				if (isFirst) return itemImage || applicationImage;

				return itemImage;
			}

			if (isImpactCard) {
				return itemImage || containerImage;
			}

			if (!fallbackImage) {
				if (isAudioIncluded && !isVideoIncluded) {
					return audioDefaultImage;
				}
				if (!isEventCard && !isImpactCard) {
					return placeHolderImage;
				}
			}

			return fallbackImage;
		}

		function getCardHeight(cardWidth: number) {
			if (isVideoIncluded) {
				return (cardWidth / 16) * 9;
			}
			if (isAudioIncluded) {
				return (cardWidth / 12) * 5;
			}
			return (cardWidth / 4) * 3;
		}

		function getEventLabel(startTime: string | undefined) {
			if (!startTime) return null;
			const start = new Date(startTime).getTime();
			const current = new Date().getTime();
			return current < start ? TEXT_MAP.upcomingEvent : TEXT_MAP.event;
		}

		function getVideoUrl() {
			const videoProperty = getItemProperty('VideoProperty');
			const videoUrl = isVideoCard && card.videoUrl;
			return (videoProperty?.__typename === 'VideoProperty' && videoProperty.url) || videoUrl || '';
		}

		function isBookmarkable() {
			return (
				!hasItemProperty('TimeframeProperty') &&
				(hasItemProperty('AudioProperty') || hasItemProperty('VideoProperty'))
			);
		}

		function getCommonProps(): Omit<
			CardViewProps,
			| 'applicationUuid'
			| 'eventUpdated'
			| 'itemPropertiesUpdated'
			| 'location'
			| 'showVideoPlayer'
			| 'videoId'
			| 'videoType'
			| 'youtubeApikey'
		> {
			const { cardDefinition, parentContainer } = card.item;
			const hasBaseCardDefinition = cardDefinition?.__typename === 'BaseCardDefinition';
			const hasImpactCardDefinition = cardDefinition?.__typename === 'ImpactCardDefinition';

			const shareProperty = !isEventCard ? getItemProperty('ShareProperty') : null;
			const sharable = shareProperty?.__typename === 'ShareProperty' && shareProperty.sharedProperties.length > 0;
			const { showPublishedTime, showCategory } = hasBaseCardDefinition
				? cardDefinition.displayProperties
				: {
						showPublishedTime: parentContainer?.cardDefinition?.showPublishedTime,
						showCategory: parentContainer?.cardDefinition?.showCategory,
				  };
			const { publishedTime } = card as { publishedTime?: number };
			const cardAge =
				!isFeatured && !isEventCard && showPublishedTime && publishedTime
					? getRelativeTimestamp(publishedTime)
					: '';
			const label = showCategory ? getTruncatedText(card.tabName, 30).toUpperCase() : '';
			const footerLabel = isEventCard ? TEXT_MAP.seeDetails : label;
			const showFooter = isEventCard || showCategory || showPublishedTime || sharable;
			const timeframeProperty = getItemProperty('TimeframeProperty');
			const timeframe =
				timeframeProperty?.__typename === 'TimeframeProperty'
					? {
							all_day: timeframeProperty.timeframe.allDay ? true.toString() : null,
							start_time: timeframeProperty.timeframe.startTime?.format(),
							end_time: timeframeProperty.timeframe.endTime?.format(),
					  }
					: null;

			const date = isEventCard && timeframe ? formatTime(timeframe) : '';
			const fullWidth = isFirst || isSpecialCard || isIntroCard || isFeatured;
			const cardWidth = fullWidth ? screenWidth - Metrics.cardHorizontalSpacing * 2 : screenWidth - 20;
			const cardHeight = getCardHeight(cardWidth);

			const actionButtonText =
				(isSpecialCard || isIntroCard || isImpactCard) &&
				card.callToActionText &&
				convertSnakeCaseToUpperCase(card.callToActionText);
			const description = getTruncatedText(card.summary, CARD_CATEGORY_MAX_LENGTH);
			const featuredCard =
				card.item.cardDefinition?.featuredCard || card.item.parentContainer?.cardDefinition?.featuredCard;

			const keyMetrics = isImpactCard
				? ({
						metricsCount: card.keyMetrics.length,
						metrics: card.keyMetrics.reduce(
							(prev, metric, index) => ({
								...prev,
								[`col${index + 1}`]: {
									title: metric.title,
									value: metric.value,
								},
							}),
							{}
						),
				  } as KeyMetrics)
				: null;
			const { showKeyMetrics, showTitle } = hasImpactCardDefinition
				? cardDefinition.displayProperties
				: {
						showKeyMetrics: false,
						showTitle: false,
				  };
			const eventLabel = isEventCard && !isFirst ? getEventLabel(timeframe?.start_time) : '';
			const videoUrl = getVideoUrl();

			return {
				headerBgColor: color,
				title: card.title,
				subtitle: card.subtitle,
				description,
				actionButtonText: actionButtonText || '',
				actionButtonBgColor: color,
				cardType: CARD_TYPE_MAP[cardType || 'DefaultCard'],
				featured: isFeatured,
				featuredLabel: featuredCard?.label?.toUpperCase() || '',
				showFooter,
				cardAge,
				footerLabel,
				date,
				previewUri: image,
				previewBgColor: color,
				sharable: sharable ? [] : null,
				timeframe,
				width: cardWidth,
				height: cardHeight,
				keyMetrics,
				showKeyMetrics,
				showTitle,
				eventLabel: eventLabel || '',
				videoStatus: isVideoIncluded ? TEXT_MAP.ready : null,
				audioStatus: isAudioIncluded ? TEXT_MAP.ready : null,
				showBookmark: isProfileEnabled && !isIntroCard && isBookmarkable(),
				bookmarkInputs: {} as any,
				videoUrl,
			};
		}

		return getCommonProps() as CardViewProps;
	}
}
