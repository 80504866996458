import { ComponentProps, useMemo } from 'react';

import DefaultListComponent from '@pushpay/app-components/dist/app/screens/Default/DefaultScreen';

import { useAppPreviewContext, useGlobalContext } from '@src/context';
import {
	ContainerTemplate,
	ChildItemFragment,
	DefaultProperty,
	PropertyType,
	AppLinkProperty,
	SmsProperty,
	TextHtmlProperty,
	TextProperty,
	WebsiteProperty,
} from '@src/graphql/generated';
import { getTruncatedText } from '@src/utils';

import { ContainerPreviewData } from '../types';
import { getImageMap, noop } from '../utils';

const CONTAINER_TEMPLATES_132DP = [ContainerTemplate.List_132DpPhoto, ContainerTemplate.List_132DpPhotoNoLabel];

type ListItemData = ComponentProps<typeof DefaultListComponent>['data'];
type ImageMap = ReturnType<typeof getImageMap>;

export function DefaultList({ container, template }: { container: ContainerPreviewData; template: string }) {
	const { appSettings, screenWidth } = useAppPreviewContext();
	const { campus: currentCampusId } = useGlobalContext();

	const containerImageMap = getImageMap(container.images);
	const canHaveSubtitle = !CONTAINER_TEMPLATES_132DP.includes(container.template);
	const subtitle = canHaveSubtitle ? container.subtitle : null;
	const listData = useMemo(
		() => getListItemData(container, currentCampusId, containerImageMap, subtitle),
		[container, containerImageMap, currentCampusId, subtitle]
	);
	const headerImage = containerImageMap?.lmw && canHaveSubtitle ? containerImageMap.lmw : null;

	return (
		<DefaultListComponent
			data={listData}
			headerImage={headerImage}
			screenWidth={screenWidth}
			settings={appSettings}
			template={template}
			onEndReached={noop}
			onEndReachedThreshold={2160}
			onRowPress={noop}
		/>
	);
}

function getSubtitle(matchingProperty: ChildItemFragment['item']['properties'][number]) {
	switch (matchingProperty.type) {
		case PropertyType.Website:
		case PropertyType.AppLink:
			return (matchingProperty as AppLinkProperty | WebsiteProperty).label;
		case PropertyType.Sms:
			return (matchingProperty as SmsProperty).messageLabel;
		case PropertyType.TextHtml:
			return (matchingProperty as TextHtmlProperty).text;
		case PropertyType.Text:
			return (matchingProperty as TextProperty).textDescription;
		default:
			return (matchingProperty as DefaultProperty).data;
	}
}

function getListItemData(
	container: ContainerPreviewData,
	currentCampusId: string | null,
	containerImage: ImageMap,
	subtitleType?: string | null
): ListItemData {
	const { children } = container;
	if (!children) return [];

	return children
		.filter(child => !child.hidden)
		.reduce<ListItemData>((acc, child) => {
			let hasDifferentCampus = false;
			let isAudio = false;
			let images: ImageMap = null;
			let subtitle = '';

			if (child.__typename === 'ChildContainer') {
				hasDifferentCampus = !!child.container.campus && child.container.campus.id !== currentCampusId;
				images = getImageMap(child.container.image?.urls);
			} else if (child.__typename === 'ChildItem') {
				isAudio = !!child.item.properties.find(property => property.__typename === 'AudioProperty');
				images = getImageMap(child.item.image?.urls);

				if (subtitleType) {
					const matchingProperty = child.item.properties
						.filter(property => property.header?.toLowerCase() === subtitleType.toLowerCase())
						.sort((a, b) => a.position - b.position)[0];

					if (matchingProperty) {
						subtitle = getSubtitle(matchingProperty) ?? subtitle;
					}
				}
			}

			if (hasDifferentCampus) {
				return acc;
			}

			const name = subtitle ? child.name : getTruncatedText(child.name, 25);

			if (!images && !isAudio) {
				images = containerImage;
			}

			acc.push({
				uuid: child.id,
				images,
				icon: child.icon,
				subtitle,
				name,
			});

			return acc;
		}, []);
}
