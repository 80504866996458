import { createErrorLink } from '@pushpay/apollo-links';

import { useShowNotification } from '@src/components/notification';
import { useTranslation } from '@src/i18n/translation';
import { send } from '@src/raygun';

export function useApolloClientErrorHandlers() {
	const { translate } = useTranslation('common');
	const showNotification = useShowNotification();
	const showGenericNetworkAndServerError = () => {
		showNotification({
			isOpen: true,
			type: 'error',
			title: translate('errors.title.error'),
			content: translate('errors.text.networkOrServerError'),
		});
	};

	const handleNetworkError = (errorMessage: string, error: Error) => {
		// page reload will redirect user to login page
		if (errorMessage.includes('Login required')) {
			window.location.reload();
			return;
		}
		showGenericNetworkAndServerError();
		// eslint-disable-next-line no-console
		console.error(errorMessage);
		send(error);
	};

	const handleGraphQLErrors = (errorMessage: string) => {
		// eslint-disable-next-line no-console
		console.error(errorMessage);
		// better to check extension code, but BFF API doesn't forward it so far.
		if (errorMessage.includes('Internal Server Error')) {
			showGenericNetworkAndServerError();
		}
	};
	return createErrorLink({ handleGraphQLErrors, handleNetworkError });
}
