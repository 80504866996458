import { CardType, GetItemPropertiesQuery } from '@src/graphql/generated';

import { ContainerDataMap, HomeCardDetailFieldType } from './type';

export function getCardTypeOptions(
	dataMap?: ContainerDataMap,
	itemProperties?: NonNullable<
		NonNullable<
			NonNullable<NonNullable<GetItemPropertiesQuery['organization']>['application']>['container']
		>['item']
	>['properties']
) {
	const cardTypeOptions = new Set<CardType>([CardType.Default, CardType.Special, CardType.Intro]);
	if (dataMap?.videoUrl) {
		cardTypeOptions.add(CardType.Video);
	}
	if (dataMap?.audioUrl) {
		cardTypeOptions.add(CardType.Audio);
	}
	const cardTypeOptionsWithItemPropertyReferred =
		itemProperties?.reduce((options: Set<CardType>, itemProperty) => {
			if (itemProperty?.__typename === 'VideoProperty' && itemProperty.data) {
				options.add(CardType.Video);
			}
			if (itemProperty?.__typename === 'AudioProperty' && itemProperty.data) {
				options.add(CardType.Audio);
			}
			if (
				itemProperty?.__typename === 'TimeframeProperty' &&
				(itemProperty.timeframe.startTime || itemProperty.timeframe.endTime)
			) {
				options.add(CardType.Event);
			}
			return options;
		}, cardTypeOptions) ?? cardTypeOptions;

	return [...cardTypeOptionsWithItemPropertyReferred].map(option => ({
		display: option.charAt(0).toUpperCase() + option.toLowerCase().slice(1),
		value: option,
	}));
}

export function getHomeCardDetailFields(cardType: CardType | null): HomeCardDetailFieldType[] {
	const defaultFields: HomeCardDetailFieldType[] = ['summary', 'videoUrl', 'audioUrl'];
	switch (cardType) {
		case CardType.Video:
		case CardType.Audio:
		case CardType.Default:
		case CardType.Special:
			return ['subtitle', ...defaultFields, 'publishedTime'];
		case CardType.Intro:
			return [...defaultFields, 'publishedTime'];
		default:
			return defaultFields;
	}
}
