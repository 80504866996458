import { HTMLAttributes } from 'react';

import streamlineOutline from '@pushpay/app-components/dist/app/components/icons/svg_path/streamline-outline.json';
import PpIcon from '@pushpay/icon';
import { YouTubeIcon, CampusSelectorIcon, CrossIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { DraggableIcon } from '@src/components/draggable/types';
import { useTranslation } from '@src/i18n';

import { CHAR_MAP } from './charmap';

const useStyles = createUseStyles({
	root: {
		fontSize: '24px',
		lineHeight: 1,
	},
});

export type IconProps = ComponentProps<
	{
		name: DraggableIcon;
	},
	typeof useStyles,
	HTMLAttributes<HTMLDivElement>
>;

type SvgKey = keyof typeof streamlineOutline;

export const Icon = (props: IconProps) => {
	const { name, 'data-pp-at-target': targetId, classes: classProp, style } = props;
	const classes = useStyles(classProp);
	const { translate } = useTranslation('appDesign');

	switch (name) {
		case 'custom-youtube':
			return (
				<YouTubeIcon
					className={classes.root}
					data-pp-at-target={targetId}
					title={translate('draggables.youTubeDraggable')}
					{...(style && { style })}
				/>
			);
		case 'custom-campus':
			return (
				<CampusSelectorIcon
					className={classes.root}
					data-pp-at-target={targetId}
					title={translate('draggables.campusDraggable')}
					{...(style && { style })}
				/>
			);
		case 'close':
			return <CrossIcon className={classes.root} data-pp-at-target={targetId} {...(style && { style })} />;
		default: {
			const unicode = CHAR_MAP[name] as SvgKey;
			const path = streamlineOutline?.[unicode];

			return (
				<PpIcon
					classes={{
						root: classes.root,
					}}
					data-pp-at-target={targetId}
					stroke="currentColor"
					strokeWidth={8}
					title={name}
					viewBox="0 0 512 512"
					{...(style && { style })}
				>
					<path d={path} fill="currentColor" />
				</PpIcon>
			);
		}
	}
};
