import { Button } from '@pushpay/button';
import { Select } from '@pushpay/inputs';
import { StackItem } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

import { Group, SettingNumberInput, SettingSwitch } from '../components';
import { extraLengthMax } from '../testWidgetSettings';
import { useTestWidgetSettings } from '../useTestWidgetSettings';

const useStyles = createUseStyles((theme: Theme) => ({
	selectLanguage: {
		width: '100%',
	},
	selectLanguageSummary: {
		padding: '5px',
		width: '100%',
	},
	selectLanguageOverlay: {
		position: 'fixed',
		background: theme.colors['common-white'],
		boxShadow: theme.ELEVATION.EIGHT,
		width: '244px',
	},
}));

export const I18nGroup = ({
	classes: classesProp,
	'data-pp-at-target': targetId,
	...rest
}: ComponentProps<unknown, typeof useStyles>) => {
	const classes = useStyles(classesProp);
	const { language, changeLanguage } = useTranslation();
	const [{ accents, brackets, extraLength, mirror, underscores }, dispatch] = useTestWidgetSettings('i18n');

	return (
		<Group data-pp-at-target={targetId} groupName="i18n" {...rest}>
			<StackItem alignH="right">
				<Button
					data-pp-at-target={`${targetId}-reset`}
					displaySize="small"
					displayStyle="text"
					type="button"
					onClick={() => {
						changeLanguage('en');
						dispatch({ type: 'i18nReset' });
					}}
				>
					Reset
				</Button>
			</StackItem>
			<Select
				classes={{
					root: classes.selectLanguage,
					summary: classes.selectLanguageSummary,
					overlay: classes.selectLanguageOverlay,
				}}
				data-pp-at-target={`${targetId}-select-language`}
				id={`${targetId}-select-language`}
				options={[
					{ display: 'English', value: 'en' },
					{ display: 'Español', value: 'es' },
				]}
				value={language}
				onChange={selectedLanguage => {
					changeLanguage(selectedLanguage);
				}}
			/>
			<SettingSwitch
				checked={accents}
				data-pp-at-target={`${targetId}-accents`}
				id={`${targetId}-accents`}
				onChange={checked => dispatch({ type: 'i18n', accents: checked })}
			>
				Mark with accents
			</SettingSwitch>
			<SettingSwitch
				checked={brackets}
				data-pp-at-target={`${targetId}-brackets`}
				id={`${targetId}-brackets`}
				onChange={checked => dispatch({ type: 'i18n', brackets: checked })}
			>
				Brackets start and end
			</SettingSwitch>
			<SettingSwitch
				checked={mirror}
				data-pp-at-target={`${targetId}-mirror`}
				id={`${targetId}-mirror`}
				onChange={checked => dispatch({ type: 'i18n', mirror: checked })}
			>
				Mirror text
			</SettingSwitch>
			<SettingSwitch
				checked={underscores}
				data-pp-at-target={`${targetId}-underscores`}
				id={`${targetId}-underscores`}
				onChange={checked => dispatch({ type: 'i18n', underscores: checked })}
			>
				Replace with underscores
			</SettingSwitch>
			<SettingNumberInput
				data-pp-at-target={`${targetId}-extralength`}
				max={extraLengthMax}
				value={extraLength}
				onChange={(extraLengthPercentage: number | null) =>
					dispatch({ type: 'i18n', extraLength: extraLengthPercentage })
				}
			>
				Extra length (%)
			</SettingNumberInput>
		</Group>
	);
};
