import { useId } from 'react';

import { Field, FormState } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { AudioPropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, TextFieldWrapper } from '../wrappers';

export type AudioProps = ComponentProps<
	{
		propertyField: FormState<AudioPropertyInput>;
	},
	typeof undefined
>;

export const Audio = ({ propertyField, 'data-pp-at-target': targetId }: AudioProps) => {
	const { translate } = useTranslation('appDesign');

	const { label: labelField, url: urlField } = propertyField;

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={translate('audio.labelPlaceholder')}
				data-pp-at-target={`${targetId}-audio-label`}
				field={labelField as Field<string>}
				id={useId()}
				label={translate('audio.label')}
				labelPosition="top"
				showLabel
			/>
			<TextFieldWrapper
				aria-label={translate('audio.urlPlaceholder')}
				data-pp-at-target={`${targetId}-audio-url`}
				field={urlField as Field<string>}
				id={useId()}
				label={translate('audio.url')}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
