import { CSSProperties } from '@pushpay/styles';

export const getShadow = (): CSSProperties => ({
	boxShadow: '0 2px 6px 0 rgba(30,40,81,0.12)',
});

export const invisibleVerticalScroll = (): CSSProperties => ({
	overflowY: 'auto',
	scrollbarWidth: 'none' /* Firefox */,
	'-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
	'&::-webkit-scrollbar': {
		width: 0 /* Safari and Chrome */,
	},
});
