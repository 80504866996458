import { ComponentProps, PropsWithChildren } from 'react';

import { SortableContext as DndSortableContext, SortingStrategy } from '@dnd-kit/sortable';

type SortableContextProps = {
	items: ComponentProps<typeof DndSortableContext>['items'];
	strategy: SortingStrategy;
};

export function SortableContext({ children, items, strategy }: PropsWithChildren<SortableContextProps>) {
	return (
		<DndSortableContext items={items} strategy={strategy}>
			{children}
		</DndSortableContext>
	);
}
