import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	title: {
		font: theme.typography['heading-4'],
		height: '24px',
		lineHeight: '24px',
		marginBottom: theme.SPACING.XSMALL,
	},
	card: {
		border: `1px solid ${theme.colors['grey-400']}`,
		borderRadius: '6px',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '5px 6px',
		height: '54px',
		borderRadius: '6px',
		backgroundColor: theme.colors['background-page'],
	},
	body: {
		padding: theme.SPACING.SMALL,
		marginTop: '1px',
		borderTop: `1px solid ${theme.colors['grey-400']}`,
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	notificationWrapper: {
		position: 'relative',
		paddingBottom: theme.SPACING.SMALL,
	},
	expandPanel: {
		position: 'relative',
	},
	disabledOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: `${theme.colors['color-background-page']}`,
		pointerEvents: 'auto',
		opacity: 0.5,
		zIndex: 2,
		cursor: 'not-allowed',
	},
}));

export default useStyles;
