import { Dispatch, PropsWithChildren, createContext, useContext, useReducer } from 'react';

import { FormState, getDynamicFieldsItems } from '@pushpay/forms';

import { CarouselFile } from '@src/components/carouselUploader/types';
import { CarouselPropertyInput } from '@src/graphql/generated';
import { ItemPropertiesFields } from '@src/pages/itemSettings/types';
import { ImageFileDataForUploadObject, useCarouselFileUploadMutation } from '@src/shared/hooks';

const CarouselPendingUploadImageMapContext = createContext<CarouselPendingUploadImageMap | null>(null);
CarouselPendingUploadImageMapContext.displayName = 'CarouselPendingUploadImageMapContext';

const CarouselPendingUploadImageMapActionsContext = createContext<Dispatch<CarouselPendingUploadImageAction> | null>(
	null
);
CarouselPendingUploadImageMapActionsContext.displayName = 'CarouselPendingUploadImageMapActionsContext';

type CarouselPendingUploadImagesObject = {
	uploadImageDataList: ImageFileDataForUploadObject[];
	previewImageDataList: CarouselFile[];
};

type CarouselPendingUploadImageMap = {
	[carouselId: string]: CarouselPendingUploadImagesObject;
};

export const CarouselPendingUploadImageActions = {
	AddImage: 'add_image',
	DiscardAllImages: 'discard_all_images',
	DiscardImagesForOneCarousel: 'discard_images_for_one_carousel',
} as const;

type CarouselPendingUploadImageAction =
	| {
			type: 'add_image';
			carouselId: string;
			uploadImageData?: ImageFileDataForUploadObject;
			previewImageData: CarouselFile;
	  }
	| { type: 'discard_images_for_one_carousel'; carouselId: string }
	| { type: 'discard_all_images' };

const defaultUploadImageObject = {
	uploadImageDataList: [],
	previewImageDataList: [],
};

export function CarouselPendingUploadImagesProvider({ children }: PropsWithChildren) {
	const [carouselPendingImageUploadMap, dispatch] = useReducer(carouselPendingUploadImageMapReducer, {});

	return (
		<CarouselPendingUploadImageMapContext.Provider value={carouselPendingImageUploadMap}>
			<CarouselPendingUploadImageMapActionsContext.Provider value={dispatch}>
				{children}
			</CarouselPendingUploadImageMapActionsContext.Provider>
		</CarouselPendingUploadImageMapContext.Provider>
	);
}

export function useCarouselPendingUploadImageObject(carouselId: string): CarouselPendingUploadImagesObject {
	const uploadImageMap = useContext(CarouselPendingUploadImageMapContext);

	if (!uploadImageMap) {
		throw new Error(
			'useCarouselPendingUploadImageObject must be used within a CarouselPendingUploadImagesProvider'
		);
	}

	const uploadImageObject = uploadImageMap[carouselId];

	if (!uploadImageObject) return defaultUploadImageObject;

	return uploadImageObject;
}

export function useCarouselPendingUploadImageMap(): CarouselPendingUploadImageMap {
	const uploadImageMap = useContext(CarouselPendingUploadImageMapContext);

	if (!uploadImageMap) {
		throw new Error('useCarouselPendingUploadImageMap must be used within a CarouselPendingUploadImagesProvider');
	}

	return uploadImageMap;
}

export function useCarouselPendingUploadImageMapActions() {
	const dispatch = useContext(CarouselPendingUploadImageMapActionsContext);
	const uploadImageMap = useContext(CarouselPendingUploadImageMapContext);
	const { uploadCarouselFiles } = useCarouselFileUploadMutation();

	if (!dispatch || !uploadImageMap) {
		throw new Error(
			'useCarouselPendingUploadImageMapActions must be used within a CarouselPendingUploadImagesProvider'
		);
	}

	const discardPendingUploadImagesForOneCarousel = (carouselId: string) => {
		dispatch({ type: CarouselPendingUploadImageActions.DiscardImagesForOneCarousel, carouselId });
	};

	const discardAllPendingUploadImages = () => {
		dispatch({ type: CarouselPendingUploadImageActions.DiscardAllImages });
	};

	const addPendingUploadImage = ({
		carouselId,
		previewImageData,
		uploadImageData,
	}: {
		carouselId: string;
		previewImageData: CarouselFile;
		uploadImageData?: ImageFileDataForUploadObject;
	}) => {
		dispatch({ type: CarouselPendingUploadImageActions.AddImage, carouselId, previewImageData, uploadImageData });
	};

	const uploadAllPendingUploadImages = async (propertyFields: ItemPropertiesFields): Promise<boolean> => {
		const carouselDynamicFields = getDynamicFieldsItems(propertyFields).flatMap(dynamicField => {
			const [[propertyInputType, propertyField]] = Object.entries(dynamicField);

			return propertyInputType === 'carouselPropertyInput' ? propertyField : [];
		}) as FormState<CarouselPropertyInput>[];

		const newImagesFields = carouselDynamicFields.flatMap(fields => {
			const imagesFields = getDynamicFieldsItems(fields.images);

			return imagesFields.filter(imagesField => Boolean(imagesField?.newImageInput?.id));
		});

		// No new images to be uploaded so return early
		if (newImagesFields.length === 0) return true;

		const imageDataList = Object.values(uploadImageMap).flatMap(({ uploadImageDataList }) => uploadImageDataList);

		// Need to compare the images from the dynamic fields to the images saved in the context
		// since the dynamic fields is the source of truth. e.g. if you drag images to the carousel
		// and delete the carousel without saving it, it will immediately reflect in the dynamic
		// fields.
		const filteredImageDataList = imageDataList.filter(({ id }) =>
			newImagesFields.find((imagesField: any) => imagesField.newImageInput?.id.value === id)
		);

		const isCarouselUploadSuccess = await uploadCarouselFiles(filteredImageDataList);

		return isCarouselUploadSuccess;
	};

	return {
		discardPendingUploadImagesForOneCarousel,
		discardAllPendingUploadImages,
		addPendingUploadImage,
		uploadAllPendingUploadImages,
	};
}

function carouselPendingUploadImageMapReducer(
	carouselPendingImageUploadMap: CarouselPendingUploadImageMap,
	action: CarouselPendingUploadImageAction
) {
	switch (action.type) {
		case CarouselPendingUploadImageActions.AddImage: {
			const { carouselId, previewImageData, uploadImageData } = action;

			const imageObject = carouselPendingImageUploadMap[carouselId] ?? defaultUploadImageObject;

			const updatedImageObject: CarouselPendingUploadImagesObject = {
				uploadImageDataList: [...imageObject.uploadImageDataList],
				previewImageDataList: [...imageObject.previewImageDataList, previewImageData],
			};

			if (uploadImageData) updatedImageObject.uploadImageDataList.push(uploadImageData);

			return { ...carouselPendingImageUploadMap, [carouselId]: updatedImageObject };
		}
		case CarouselPendingUploadImageActions.DiscardAllImages: {
			const carouselIds = Object.keys(carouselPendingImageUploadMap);

			const updatedMap: CarouselPendingUploadImageMap = carouselIds.reduce(
				(updatedImageUploadMap: CarouselPendingUploadImageMap, carouselId: string) => ({
					...updatedImageUploadMap,
					[carouselId]: defaultUploadImageObject,
				}),
				{}
			);

			return updatedMap;
		}
		case CarouselPendingUploadImageActions.DiscardImagesForOneCarousel: {
			const { carouselId } = action;

			return {
				...carouselPendingImageUploadMap,
				[carouselId]: defaultUploadImageObject,
			};
		}
		default:
			return carouselPendingImageUploadMap;
	}
}
