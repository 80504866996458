import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import Accessor from '@pushpay/app-components/dist/app/utils/accessor';

type AppPreviewContext = {
	screenWidth: number;
	screenHeight: number;
	appSettings: Accessor;
};

const AppPreviewContext = createContext<AppPreviewContext | null>(null);
AppPreviewContext.displayName = 'AppPreviewContext';

function AppPreviewContextProvider({
	children,
	screenWidth,
	screenHeight,
	appSettings,
}: PropsWithChildren<AppPreviewContext>) {
	const appPreviewContextValue: AppPreviewContext = useMemo(
		() => ({
			screenWidth,
			screenHeight,
			appSettings,
		}),
		[screenWidth, screenHeight, appSettings]
	);

	return <AppPreviewContext.Provider value={appPreviewContextValue}>{children}</AppPreviewContext.Provider>;
}

function useAppPreviewContext(): AppPreviewContext {
	const context = useContext(AppPreviewContext);
	if (!context) {
		throw new Error('useAppPreviewContext must be used within a AppPreviewContextProvider');
	}
	return context;
}

export { AppPreviewContext, AppPreviewContextProvider, useAppPreviewContext };
