import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	imageUrlInput: {
		height: '40px',
		paddingRight: '120px',
		textOverflow: 'ellipsis',
	},
	imageUrlInputWrapper: {
		position: 'relative',
	},
	applyButton: {
		color: theme.colors['productBrand-500'],
		font: theme.typography.button,
		position: 'absolute',
		right: '30px',
		'&:disabled': {
			color: theme.colors['text-disabled'],
		},
	},
	resetButton: {
		color: theme.colors['grey-900'],
		position: 'absolute',
		right: '90px',
	},
	spinner: {
		display: 'flex',
		alignItems: 'center',
		color: theme.colors['productBrand-500'],
		position: 'absolute',
		right: 5,
		fontSize: theme.SPACING.LARGE_ICON_SIZE,
		animation: '0s linear 0.2s forwards $makeVisible',
		visibility: 'hidden',
	},
	'@keyframes makeVisible': {
		to: {
			visibility: 'visible',
		},
	},
	errorMessageContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		margin: `${theme.SPACING.FIELD_TO_MESSAGE} 0`,
		padding: 0,
		color: theme.colors['text-error'],
		font: theme.typography['text-5'],
		lineHeight: 1.3,
	},
	boldText: {
		marginRight: theme.SPACING.ICON_TO_TEXT_ERROR,
	},
	errorIcon: {
		marginTop: '0.15em',
		marginRight: theme.SPACING.ICON_TO_TEXT_ERROR,
		flexShrink: 0,
	},
	errorMessage: {
		whiteSpace: 'pre-line',
	},
}));
