import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	headerGroup: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginRight: theme.SPACING.XSMALL,
		},
	},
	label: {
		color: theme.colors['text-heading'],
		font: theme.typography['heading-4'],
	},
	button: {
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
	},
	dragHandle: {
		color: theme.colors['grey-400'],
		marginLeft: multiply(theme.SPACING.XSMALL, -1.5),
		flex: 'none',
	},
	icon: {
		color: theme.colors['grey-900'],
		fontSize: '20px',
		flex: 'none',
	},
	card: {
		cursor: 'default',
	},
	disabled: {
		cursor: 'not-allowed',
		opacity: 0.4,
	},
	insertBefore: {
		position: 'relative',
		transform: 'translateY(2px)',

		'&:before': {
			content: '""',
			position: 'absolute',
			top: '-8px',
			left: 0,
			width: '100%',
			height: '4px',
			backgroundColor: theme.colors['blue-500'],
		},
	},
}));
