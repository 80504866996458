function toSnakeCase(str: string) {
	return str
		.replace(/([a-z])([A-Z])/g, '$1_$2')
		.replace(/[\s_]+/g, '_')
		.toLowerCase();
}

export function convertSnakeCaseToUpperCase(str: string) {
	return str.replace(/_/, ' ').toUpperCase();
}

export function convertToJson(properties: any, excludeKeys?: string[]): string {
	const propertiesObj: Record<string, any> = {};

	if (properties) {
		Object.keys(properties).forEach(key => {
			if (excludeKeys?.includes(key)) {
				return;
			}
			const val = properties[key];
			if (val) {
				propertiesObj[toSnakeCase(key)] = val;
			}
		});
	}

	return JSON.stringify(propertiesObj);
}
