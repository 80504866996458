import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Button, SaveButton, SaveButtonProps } from '@pushpay/button';
import { SendEmailIcon } from '@pushpay/iconography';
import { ComponentProps } from '@pushpay/types';

import { ConfirmationDialog } from '@src/components/modals';
import { useShowNotification } from '@src/components/notification';
import {
	ApplicationPublishStatus,
	useGetApplicationPublishStatusQuery,
	usePublishChangesMutation,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp } from '@src/myContext';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { useStyles } from './actionStyles';

export type ActionProps = ComponentProps<unknown, typeof useStyles>;

export const Actions = ({ classes: classesProp, 'data-pp-at-target': targetId }: ActionProps) => {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const { translate: translateCommon } = useTranslation('common');
	const { organizationKey, platformCampusKey } = useGetQueryAndMutationVars();
	const {
		currentApp: { id: applicationId },
	} = useMyApp();
	const showNotification = useShowNotification();
	const { pathname } = useLocation();

	const {
		data,
		loading: queryLoading,
		startPolling,
		stopPolling,
		refetch,
	} = useGetApplicationPublishStatusQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
		},
		fetchPolicy: 'cache-and-network',
	});
	const [publishChanges, { loading: mutationLoading }] = usePublishChangesMutation();
	const [publishStarted, setPublishStarted] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	const onPublishChanges = async () => {
		setIsConfirmModalOpen(false);
		setPublishStarted(true);

		const result = await publishChanges({
			variables: {
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
				},
			},
		});

		if (result.errors !== undefined) {
			showNotification({
				isOpen: true,
				type: 'error',
				title: translateCommon('errors.title.generalError'),
				content: translateCommon('errors.text.generalError'),
			});
		}
	};

	const { publishStatus, pendingChanges: pendingCount } = data?.organization?.application ?? {
		publishStatus: ApplicationPublishStatus.Unknown,
		pendingChanges: 0,
	};

	const inProcess = publishStarted && (queryLoading || mutationLoading);
	const saveButtonState = getSaveButtonState(publishStatus, inProcess);
	const idleButtonText = translate('actions.publish.text.idle', {
		pendingCount: `${pendingCount > 99 ? '99+' : pendingCount}`,
	});

	useEffect(() => {
		if (saveButtonState === 'saving') {
			startPolling(5000); // poll every 5 seconds
		} else {
			stopPolling();
			setPublishStarted(false);
		}
	}, [saveButtonState, startPolling, stopPolling]);

	useEffect(() => {
		refetch();
	}, [pathname, refetch]);

	return (
		<>
			<div className={classes.root} data-pp-at-target={targetId}>
				<SaveButton
					classes={{ root: classes.publishButton }}
					data-pp-at-target={`${targetId}-publish`}
					disabled={pendingCount === 0 || saveButtonState === 'saving'}
					idleStateButtonText={idleButtonText}
					savingStateButtonText={translate('actions.publish.text.saving')}
					state={saveButtonState}
					successStateButtonText={translate('actions.publish.text.success')}
					type="button"
					onSave={() => setIsConfirmModalOpen(true)}
				>
					<SendEmailIcon
						aria-label="publish"
						className={classes.publishIcon}
						data-pp-at-target={`${targetId}-publish-icon`}
					/>
					{idleButtonText}
				</SaveButton>
			</div>
			<ConfirmationDialog
				bodyText={translate('publishConfirmationDialog.modalText')}
				cancelButtonLabel={translate('publishConfirmationDialog.cancel')}
				confirmButton={
					<Button displayStyle="primary" type="button" onClick={onPublishChanges}>
						{translate('publishConfirmationDialog.confirm')}
					</Button>
				}
				isConfirmModalOpen={isConfirmModalOpen}
				setIsConfirmModalOpen={setIsConfirmModalOpen}
				title={translate('publishConfirmationDialog.modalTitle')}
			/>
		</>
	);
};

function getSaveButtonState(publishStatus: ApplicationPublishStatus, inProcess: boolean): SaveButtonProps['state'] {
	if (inProcess || [ApplicationPublishStatus.InProcess, ApplicationPublishStatus.Requested].includes(publishStatus)) {
		return 'saving';
	}
	if (publishStatus === ApplicationPublishStatus.Live) {
		return 'success';
	}
	return 'idle';
}
