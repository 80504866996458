import { ReactNode, useId } from 'react';

import Expand from '@pushpay/expand';
import { ComponentProps } from '@pushpay/types';

import { useFeature } from '@src/featureFlags';

import { SwitchWithTooltip } from '../switch/SwitchWithTooltip';
import { ExpandableAttributesLegacy } from './ExpandableAttributesLegacy';
import useStyles from './expandableAttributesStyles';

export type ExpandableAttributesProps = ComponentProps<
	{
		title?: string;
		toggleName: string;
		tooltipContent: string;
		disabled: boolean;
		expand?: boolean;
		onExpandChange?: (expand: boolean) => void;
		extraAction?: ReactNode;
		// TODO [CAPPS-12284]: Unused props should be removed when InAppCalendar FF is removed
		isFeatured?: boolean;
		canBeFeatured?: boolean;
		onIsFeaturedChange?: (isFeatured: boolean) => void;
	},
	typeof useStyles
>;

export const ExpandableAttributes = (props: ExpandableAttributesProps) => {
	const {
		title,
		toggleName,
		disabled,
		tooltipContent,
		classes: classesProp,
		children,
		expand,
		onExpandChange,
		'data-pp-at-target': targetId,
		extraAction,
	} = props;

	const classes = useStyles(classesProp);
	const dhsToggleId = useId();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	if (!isInAppCalendarEnabled) {
		return <ExpandableAttributesLegacy {...props}>{children}</ExpandableAttributesLegacy>;
	}

	const handleExpandToggle = (value: boolean) => {
		if (onExpandChange) {
			onExpandChange(value);
		}
	};

	return (
		<div className={classes.root}>
			{title && <div className={classes.title}>{title}</div>}
			<div className={classes.card}>
				<div className={classes.header}>
					<div className={classes.actions}>
						<SwitchWithTooltip
							checked={expand}
							data-pp-at-target={`${targetId}-toggle-${expand ? 'on' : 'off'}`}
							disabled={disabled}
							id={dhsToggleId}
							tooltipContent={tooltipContent}
							onChange={handleExpandToggle}
						>
							{toggleName}
						</SwitchWithTooltip>
						{extraAction}
					</div>
				</div>

				<Expand.Panel
					className={classes.expandPanel}
					data-pp-at-target={`${targetId}-expand-panel`}
					expandStatus={expand ? 'expanded' : 'collapsed'}
					overflowVisible
				>
					{disabled && (
						<div
							className={classes.disabledOverlay}
							data-pp-at-target={`${targetId}-expand-panel-disabled-overlay`}
						/>
					)}
					<div className={classes.body}>{children}</div>
				</Expand.Panel>
			</div>
		</div>
	);
};
