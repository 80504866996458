import { Field, FormState } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { DefaultPropertyInput } from '@src/graphql/generated';

import { ContentWrapper, TextFieldWrapper } from '../wrappers';

export type DefaultProps = ComponentProps<
	{
		propertyField: FormState<DefaultPropertyInput>;
	},
	typeof undefined
>;

export const Default = ({ propertyField, 'data-pp-at-target': targetId }: DefaultProps) => {
	const {
		data: fieldInput,
		baseProperty: { header },
	} = propertyField;

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={header?.value}
				data-pp-at-target={`${targetId}-text-field`}
				field={fieldInput as Field<string>}
				label={header?.value}
				labelPosition="top"
				showLabel
			/>
		</ContentWrapper>
	);
};
