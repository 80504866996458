import { Card } from '@pushpay/card';
import { Heading } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { SkeletonDescriptionList } from '@src/components/descriptionList';
import { ServiceError } from '@src/components/errors';
import { PageSection, PageSections } from '@src/components/layout';
import { useTranslation } from '@src/i18n';

import { profileStyles } from './profileStyles';
import { ProfileLoadingOrErrorProps } from './types';

const useProfileStyles = createUseStyles((theme: Theme) => ({
	...profileStyles(theme),
	serviceError: {
		width: '100%',
		border: `1px solid ${theme.colors['border-surface']}`,
		borderRadius: '3px',
		margin: 0,
		backgroundColor: theme.colors['background-surface'],
	},
}));

export const ProfileLoadingOrError = ({
	isLoading = false,
	isError = false,
	'data-pp-at-target': targetId,
}: ComponentProps<ProfileLoadingOrErrorProps, typeof useProfileStyles>) => {
	const classes = useProfileStyles(undefined);
	const { translate } = useTranslation('profile');

	const loading = (sectionId: string, rows: number) => (
		<Card classes={{ body: classes.cardBody }} data-pp-at-target={`${targetId}-${sectionId}-card`}>
			<SkeletonDescriptionList data-pp-at-target={`${targetId}-${sectionId}-skeleton`} rows={rows} />
		</Card>
	);

	const error = (sectionId: string, errorMessage: string) => (
		<ServiceError
			className={classes.serviceError}
			data-pp-at-target={`${targetId}-${sectionId}-error`}
			errorMessage={errorMessage}
		/>
	);

	return (
		<PageSections className={classes.pageWrapper} detailsPage>
			<Heading data-pp-at-target={`${targetId}-heading`} level="1">
				{translate('pageTitle')}
			</Heading>
			<PageSection>
				<Heading data-pp-at-target={`${targetId}-personal-details-heading`} level="3">
					{translate('personalDetailsSection.title')}
				</Heading>
				{isLoading && !isError && loading('personal-details', 3)}
				{!isLoading &&
					isError &&
					error('personal-details', translate('personalDetailsSection.unableToLoadPersonalDetails'))}
			</PageSection>
			<PageSection>
				<Heading data-pp-at-target={`${targetId}-location-heading`} level="3">
					{translate('locationSection.title')}
				</Heading>
				{isLoading && !isError && loading('location', 1)}
				{!isLoading && isError && error('location', translate('locationSection.unableToLoadLocation'))}
			</PageSection>
		</PageSections>
	);
};
