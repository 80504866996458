import { NetworkStatus } from '@apollo/client';

import InfiniteScroll, { LoadingStatus } from '@pushpay/infinite-scroll';

import { useScrollToLastPagePositionContext } from '@src/context/scrollToLastPagePositionContext';
import { CursorPagedContainerChildren } from '@src/graphql/generated';

import { ContainerChildrenLoadingSkeleton } from './ContainerChildrenLoadingSkeleton';

type ContainerChildrenLoaderProps = {
	data?: CursorPagedContainerChildren;
	networkStatus: NetworkStatus;
	onLoadMore: (size: number) => void;
};

const FETCH_MORE_SIZE = 10;

export function ContainerChildrenLoader({
	data,
	networkStatus,
	onLoadMore,
	children,
}: React.PropsWithChildren<ContainerChildrenLoaderProps>) {
	const loadingStatus = getInfiniteScrollStatus(networkStatus, data);
	const hasNextItem = !!data?.paging.nextCursor;
	const { containerRef } = useScrollToLastPagePositionContext();

	return (
		<InfiniteScroll
			hasNextItem={hasNextItem}
			loadingStatus={loadingStatus}
			renderLoadingView={() => (
				<ContainerChildrenLoadingSkeleton
					ariaLabel={loadingStatus === 'fetchMore' ? 'fetch more loading' : 'content loading'}
				/>
			)}
			onLoadMore={() => {
				// To fix the infinite scroll issue due to scroll bar stuck issue
				if (containerRef?.current?.scrollTop) {
					containerRef?.current?.scrollTo({ top: containerRef?.current?.scrollTop - 0.1 });
				}
				onLoadMore(FETCH_MORE_SIZE);
			}}
		>
			{children}
		</InfiniteScroll>
	);
}

function getInfiniteScrollStatus(networkStatus: NetworkStatus, data: unknown): LoadingStatus {
	let result: LoadingStatus;
	if (networkStatus === NetworkStatus.fetchMore) {
		result = 'fetchMore';
	} else if (networkStatus === NetworkStatus.loading && !data) {
		result = 'loading';
	} else {
		result = 'ready';
	}
	return result;
}
