import Skeleton from '@pushpay/skeleton';
import { clsx } from '@pushpay/styles';

import useStyles from './cardStyles';

export function CardLoadingSkeleton() {
	const classes = useStyles(undefined);
	return (
		<div className={clsx(classes.card, classes.loading)}>
			<Skeleton
				data-pp-at-target="new-card-loading-skeleton"
				rows={[{ width: 100, avatar: true }]}
				style={{ width: '100%' }}
			/>
		</div>
	);
}
