import { getDynamicFieldsItems } from '@pushpay/forms';

import { Timeframe } from '@src/graphql/generated';
import { isTimeFrameExpired } from '@src/shared/validation';

import { ItemPropertiesFields } from '../types';
import { propertyInputTypeObj } from './properties';

export const isExpiredItem = (propertyFields: ItemPropertiesFields) => {
	const dynamicFields = getDynamicFieldsItems(propertyFields);

	const timeframeFields = dynamicFields.filter(field => {
		const [[type]] = Object.entries(field);
		return type === propertyInputTypeObj.TIMEFRAME;
	});

	const allTimeframeFieldsExpired = timeframeFields.every(field => {
		const timeFrame: Timeframe = {
			endTime: field.timeframePropertyInput.endTime.value?.clone(),
			startTime: field.timeframePropertyInput.startTime.value?.clone(),
			allDay: field.timeframePropertyInput.allDay.value,
		};

		return isTimeFrameExpired(timeFrame);
	});

	return allTimeframeFieldsExpired;
};
