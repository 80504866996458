import { Button } from '@pushpay/button';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';

import { useTranslation } from '@src/i18n';

import { SnackBarActionProps } from './types';

export type SnackBarButtonComponentProps = {
	type: 'copy' | 'move' | 'delete';
	action: SnackBarActionProps;
};

const targetId = 'snack-bar';

const useStyles = createUseStyles((theme: Theme) => ({
	button: {
		height: '32px',
		margin: '0px',
		padding: '6px 12px',
		color: theme.colors['grey-900'],
		'&:hover': {
			backgroundColor: theme.colors['grey-200'],
		},
		'&:focus, &:active': {
			backgroundColor: theme.colors['grey-300'],
		},
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
	},
	deleteText: {
		color: theme.colors['red-500'],
	},
}));

export const SnackBarButton = ({ type, action }: SnackBarButtonComponentProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');

	const button = (
		<Button
			className={clsx(classes.button, type === 'delete' && classes.deleteText)}
			data-pp-at-target={`${targetId}-${type}-button`}
			disabled={action.isDisabled}
			displayStyle="text"
			type="button"
			onClick={action.buttonHandler}
		>
			{translate(`snackBarItems.${type}`)}
		</Button>
	);

	return action.tooltipContent ? (
		<Tooltip content={action.tooltipContent} placement="top">
			{button}
		</Tooltip>
	) : (
		button
	);
};
