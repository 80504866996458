import { useState, useCallback } from 'react';

export function useEditingProfile(reset: () => void) {
	const [editing, setEditing] = useState<false | 'updateEmail' | 'editLocation'>(false);
	const stopEditing = useCallback(() => {
		setEditing(false);
		reset();
	}, [reset]);

	const startEditing = useCallback((edit: 'updateEmail' | 'editLocation') => {
		setEditing(edit);
	}, []);

	return {
		editing,
		stopEditing,
		startEditing,
	};
}

export type EditingProfile = ReturnType<typeof useEditingProfile>;
