import { FieldFunctionOptions, StoreObject } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

// This custom merge function is taken from Apollo client documentation "merging arrays of non-normalized objects"
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
export function mergeArrayByField<T extends StoreObject>(fieldName: keyof T) {
	return (existing: T[], incoming: T[], { mergeObjects, readField }: FieldFunctionOptions) => {
		const merged: any[] = existing ? existing.slice(0) : [];
		const fieldToIndex: Record<string, number> = Object.create(null);
		if (existing) {
			existing.forEach((item, index) => {
				fieldToIndex[readField<string>(fieldName as string, item) as string] = index;
			});
		}

		incoming.forEach(item => {
			const name = readField<string>(fieldName as string, item) as string;
			const index = fieldToIndex[name];
			if (typeof index === 'number') {
				// Merge the new item data with the existing item data.
				merged[index] = mergeObjects(merged[index], item);
			} else {
				// First time we've seen this item in this array.
				fieldToIndex[name] = merged.length;
				merged.push(item);
			}
		});

		return getSortedItems(merged, readField) as T[];
	};
}

function getSortedItems(items: any[], readField: ReadFieldFunction) {
	const newItems = items.filter(item => (readField<number>('position', item) ?? 0) === -1);
	const existingItems = items.filter(item => (readField<number>('position', item) ?? 0) !== -1);

	// sort existing items
	existingItems.sort((a, b) => {
		const aPosition = readField<number>('position', a) ?? 0;
		const bPosition = readField<number>('position', b) ?? 0;

		return aPosition - bPosition;
	});

	// merge new items with sorted existing items
	return items.reduce((merged, item) => {
		const itemPosition = readField<number>('position', item) ?? 0;
		const sortedItem = itemPosition === -1 ? newItems.shift() : existingItems.shift();

		return sortedItem ? [...merged, sortedItem] : merged;
	}, []);
}
