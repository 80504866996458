import { createUseStyles, negative } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { StyleParams, themeStyles } from './PushpayEditorTheme';

export const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		backgroundColor: theme.colors['common-white'],
		minWidth: '600px',
		color: theme.colors['grey-1100'],
		position: 'relative',
		lineHeight: '20px',
		fontWeight: theme.typography.font.weight.regular,
		textAlign: 'left',
		borderBottom: `1px solid ${theme.colors['grey-400']}`,
		margin: `0 ${negative(theme.SPACING.SMALL)}`,
	},
	editorScroller: {
		minHeight: '160px',
		maxHeight: '270px',
		border: 0,
		display: 'flex',
		position: 'relative',
		outline: 0,
		zIndex: 0,
		overflow: 'auto',
		resize: 'none',
	},
	editor: {
		flex: 'auto',
		position: 'relative',
		resize: 'vertical',
		zIndex: '-1',
	},
	contentEditable: (styleParams: StyleParams) => ({
		padding: theme.SPACING.MEDIUM,
		border: 0,
		fontSize: theme.typography.baseSize,
		display: 'block',
		position: 'relative',
		tabSize: 1,
		outline: 0,
		lineHeight: 1.6,

		'& .selected': {
			outline: `2px solid ${theme.colors['blue-700']}`,
			userSelect: 'none',
		},
		...themeStyles(styleParams),
	}),
}));
