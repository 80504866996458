import { createUseStyles } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { getTruncatedText, useIsOverflowed } from '@src/utils';

type CardHeaderProps = ComponentProps<
	{
		headerText: string;
	},
	typeof useStyles
>;

const useStyles = createUseStyles({
	header: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	headerWrapper: {
		width: '100%',
	},
});

export function CardHeader({ headerText, 'data-pp-at-target': targetId, classes: classesProp }: CardHeaderProps) {
	const classes = useStyles(classesProp);
	const [nameRef, isNameOverflowed] = useIsOverflowed();

	return (
		<Tooltip
			className={classes.headerWrapper}
			content={getTruncatedText(headerText, 200)}
			data-pp-at-target={targetId}
			disabled={!isNameOverflowed}
			panelSpace="XSMALL"
			placement="top"
		>
			<div ref={nameRef} className={classes.header}>
				{headerText}
			</div>
		</Tooltip>
	);
}
