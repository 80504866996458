import { useCallback } from 'react';

import { Button, SaveButton } from '@pushpay/button';
import { Card } from '@pushpay/card';
import { SelectField } from '@pushpay/forms';
import { InfoIcon } from '@pushpay/iconography';
import { Inline } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { DescriptionList } from '@src/components/descriptionList';
import { PageSection, SectionHeading } from '@src/components/layout';
import { useTranslation } from '@src/i18n';

import { profileStyles } from './profileStyles';
import { LocationSectionProps } from './types';
import { notEmpty, getOffset } from './utils';

const useProfileStyles = createUseStyles((theme: Theme) => ({
	...profileStyles(theme),
	timeZoneDetails: {
		overflow: 'visible',
	},
	timeZoneInput: {
		width: '400px',
	},
	timeZoneOverlay: {
		maxHeight: '320px',
	},
	timeZoneTerm: {
		display: 'flex',
	},
}));

export const LocationSection = ({
	countries,
	olsonTimeZone,
	saveButtonState,
	timeZoneField,
	editing,
	startEditing,
	stopEditing,
	'data-pp-at-target': targetId,
	classes: classesProp,
}: ComponentProps<LocationSectionProps, typeof useProfileStyles>) => {
	const { translate } = useTranslation('profile');
	const classes = useProfileStyles(classesProp);

	const getTimeZoneOptions = useCallback(() => {
		const validTimeZones = countries.map(x => x.timeZones).find(x => x?.some(t => t?.olsonName === olsonTimeZone));
		if (!validTimeZones) {
			return [];
		}
		return validTimeZones.filter(notEmpty).map(({ olsonName }) => {
			const offset = getOffset(olsonName);
			return {
				value: olsonName,
				display: translate('locationSection.timeZoneOffsetValue', { offset, timezone: olsonName }),
			};
		});
	}, [countries, olsonTimeZone, translate]);

	return (
		<PageSection>
			<SectionHeading
				action={() =>
					!editing && (
						<Button
							data-pp-at-target={`${targetId}-edit`}
							displayStyle="text"
							type="button"
							onClick={() => startEditing('editLocation')}
						>
							{translate('edit')}
						</Button>
					)
				}
				data-pp-at-target={`${targetId}-heading`}
			>
				{translate('locationSection.title')}
			</SectionHeading>
			<Card classes={{ body: classes.cardBody }} data-pp-at-target={`${targetId}-card`}>
				<DescriptionList
					data-pp-at-target={`${targetId}-location`}
					items={[
						[
							<Inline>
								{translate('locationSection.timeZone')}
								<Tooltip
									content={translate('locationSection.timeZoneTooltip')}
									data-pp-at-target={`${targetId}-timezone-info`}
									placement="top"
								>
									<InfoIcon
										data-pp-at-target={`${targetId}-timezone-info-icon`}
										displaySize="large"
									/>
								</Tooltip>
							</Inline>,
							editing === 'editLocation' ? (
								<SelectField
									classes={{ wrapper: classes.fieldWrapper, input: classes.timeZoneInput }}
									data-pp-at-target={`${targetId}-timezone-field`}
									field={timeZoneField}
									label={translate('locationSection.timeZone')}
									options={getTimeZoneOptions()}
									selectProps={{
										focusOnMount: true,
										classes: { overlay: classes.timeZoneOverlay },
									}}
								/>
							) : (
								translate('locationSection.timeZoneOffsetValue', {
									offset: getOffset(olsonTimeZone),
									timezone: olsonTimeZone,
								})
							),
							{ classes: { term: classes.timeZoneTerm, details: classes.timeZoneDetails } },
						],
					]}
				/>
			</Card>
			{editing === 'editLocation' && (
				<Inline className={classes.buttonsContainer}>
					<SaveButton
						classes={{ root: classes.saveButton }}
						data-pp-at-target={`${targetId}-save`}
						idleStateButtonText={translate('saveButton.text.idle')}
						savingStateButtonText={translate('saveButton.text.saving')}
						state={saveButtonState}
						successStateButtonText={translate('saveButton.text.success')}
						type="submit"
						onSuccess={stopEditing}
					/>
					{saveButtonState === 'idle' && (
						<Button
							data-pp-at-target={`${targetId}-cancel`}
							displayStyle="text"
							type="button"
							onClick={stopEditing}
						>
							{translate('cancel')}
						</Button>
					)}
				</Inline>
			)}
		</PageSection>
	);
};
