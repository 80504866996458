import { useCallback, useMemo } from 'react';

import { useAuthenticated } from '@pushpay/auth';
import { ProfileSwitcherIcon } from '@pushpay/iconography';
import { Link } from '@pushpay/link';
import { ProfileDropdown, ProfilePanel, ProfileMenu as ProfileMenuBase } from '@pushpay/shell';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme, useMediaBreakpoint } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';
import { clearMyIdentity, useMyIdentity } from '@src/myContext';
import { useRootPath } from '@src/pages/shell';
import { ROUTE_PATHS, useParams } from '@src/router';
import { getAppSettings } from '@src/utils';

const useStyles = createUseStyles((theme: Theme) => ({
	personalAccountLink: {
		borderTop: `1px solid ${theme.colors['grey-200']}`,
		color: theme.colors['text-default'],
	},
	profileName: {
		font: theme.typography['heading-5'],
	},
}));

export function ProfileMenu({
	classes: classesProp,
	'data-pp-at-target': targetId,
}: ComponentProps<unknown, typeof useStyles>) {
	const classes = useStyles(classesProp);
	const { logout } = useAuthenticated();
	const { givingPlatformUrl } = getAppSettings();
	const { identityKey, firstName, lastName, primaryEmailAddress, images } = useMyIdentity();
	const { organizationKey } = useParams<'organizationKey'>();
	const profilePath = useRootPath(organizationKey, ROUTE_PATHS.PROFILE);
	const { translate } = useTranslation();
	const isMobile = useMediaBreakpoint('TABLET_AND_BELOW');

	const profileProps = {
		profileId: identityKey,
		firstName: firstName ?? '',
		lastName: lastName ?? '',
		profileImgUrl: images?.avatar?.url ?? '',
	};

	const logoutUrl = useMemo(() => {
		const url = new URL('logout', givingPlatformUrl);
		url.searchParams.set('redirectUrl', givingPlatformUrl);
		return url.toString();
	}, [givingPlatformUrl]);

	const onLogout = useCallback(() => {
		clearMyIdentity();
		logout({ returnTo: logoutUrl });
	}, [logoutUrl, logout]);

	return (
		<ProfileDropdown
			alignment="right"
			data-pp-at-target={targetId}
			isMobile={isMobile}
			panels={[
				<>
					<ProfilePanel
						classes={{
							fullNameText: classes.profileName,
						}}
						data-pp-at-target={targetId}
						emailAddress={primaryEmailAddress?.emailAddress ?? ''}
						profileLabel={translate('profileMenu.profileLabel')}
						profilePath={profilePath}
						{...profileProps}
					/>
					<ProfileMenuBase
						data-pp-at-target={targetId}
						loggingOutLabel={translate('profileMenu.loggingOutLabel')}
						logoutLabel={translate('profileMenu.logoutLabel')}
						menuItems={[
							{
								className: clsx(classes.personalAccountLink),
								component: Link,
								leftElement: <ProfileSwitcherIcon />,
								label: translate('profileMenu.personalAccountLabel'),
								itemValue: 'personal',
								href: `${givingPlatformUrl}/pushpay`,
								target: '_blank',
							},
						]}
						onLogout={onLogout}
					/>
				</>,
			]}
			profileButtonAriaLabel="Open profile menu"
			{...profileProps}
		/>
	);
}
