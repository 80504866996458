import { useCallback } from 'react';

import { FormState, useMutateField } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { TextEditor, EditorStateContent } from '@src/components/textEditor';
import { TextHtmlPropertyInput } from '@src/graphql/generated';

export type TextHtmlProps = ComponentProps<{ propertyField: FormState<TextHtmlPropertyInput> }, undefined>;

export const TextHtml = ({ propertyField }: TextHtmlProps) => {
	const { text: textProp, textHtml: textHtmlProp } = propertyField;

	const { mutateField: mutateText } = useMutateField(textProp as FormState<TextHtmlPropertyInput['text']>);
	const { mutateField: mutateTextHtml } = useMutateField(
		textHtmlProp as FormState<TextHtmlPropertyInput['textHtml']>
	);

	const onEditHandler = useCallback(
		(editorStateContent: EditorStateContent) => {
			const { text, textHtml } = editorStateContent;

			mutateText(text);
			mutateTextHtml(textHtml);
		},
		[mutateText, mutateTextHtml]
	);

	const textHtml = textHtmlProp?.value || '';

	return <TextEditor textHtml={textHtml} onEdit={onEditHandler} />;
};
