import { ComponentProps } from '@pushpay/types';

import { Selector } from '@src/components/selectors/Selector';
import { useCampusOptions } from '@src/pages/appChrome/components/topNav/components/selectors';

export type CampusProps = ComponentProps<
	{
		campusId: string;
		onCampusSelect: (id: string) => void;
	},
	typeof undefined
>;

export const Campus = ({ campusId: id, onCampusSelect }: CampusProps) => {
	const options = useCampusOptions();

	return <Selector options={options} value={id} onChange={onCampusSelect} />;
};
