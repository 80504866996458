import { DynamicFieldsProvider } from '@pushpay/forms';
import { ComponentProps } from '@pushpay/types';

import { ScrollElementIntoViewProvider } from '@src/context/scrollElementIntoViewContext';
import { ItemProperties, ItemPropertiesFields, ItemSettings } from '@src/pages/itemSettings/types';

import { ParentContextProvider, PropertyListContextProvider } from './contexts';
import { PropertyPanel } from './PropertyPanel';

export type PropertyPanelWrapperProps = ComponentProps<
	{
		propertiesFields: ItemPropertiesFields;
		parentContainer?: NonNullable<ItemSettings>['parentContainer'];
		properties: ItemProperties;
		itemId: string;
		isDisabled?: boolean;
		isFormDirty?: boolean;
		isEventItem?: boolean;
	},
	typeof undefined
>;

export const PropertyPanelWrapper = ({
	itemId,
	propertiesFields,
	parentContainer,
	properties,
	isDisabled,
	isFormDirty,
	isEventItem,
}: PropertyPanelWrapperProps) => (
	<ScrollElementIntoViewProvider>
		<DynamicFieldsProvider dynamicFields={propertiesFields}>
			<ParentContextProvider parentCampus={parentContainer?.campus?.id} parentContainerId={parentContainer?.id}>
				<PropertyListContextProvider
					disabledForFeedItem={isDisabled}
					isEventItem={isEventItem}
					isFormDirty={isFormDirty}
					itemId={itemId}
					properties={properties}
				>
					<PropertyPanel propertiesFields={propertiesFields} />
				</PropertyListContextProvider>
			</ParentContextProvider>
		</DynamicFieldsProvider>
	</ScrollElementIntoViewProvider>
);
