import { useEffect } from 'react';

import { EmptyTableIllustration } from '@pushpay/illustration';
import { Heading } from '@pushpay/layout';
import { clsx } from '@pushpay/styles';

import { useTranslation } from '@src/i18n';
import { CONTAINER_CHILDREN_PAGING_SIZE } from '@src/pages/constants';
import { ContainerChildrenLoader, ContainerChildrenLoadingSkeleton } from '@src/pages/containerChildren';
import { useRootContent } from '@src/pages/root';

import { CardList } from './CardList';
import useStyles from './navigationStyles';
import { NavigationProps } from './types';

export type RootNavigationProps = Omit<NavigationProps, 'parentContainerId'>;

export const RootNavigation = ({
	organizationKey,
	platformCampusKey,
	applicationId,
	setCurrentContainer,
	onTriggerScroll,
	initialPageSize = CONTAINER_CHILDREN_PAGING_SIZE,
	isInitialContainer,
	...props
}: RootNavigationProps) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const { loading: isLoadingRootContainer, data, networkStatus, fetchMore } = useRootContent(initialPageSize);

	const isLoading = isLoadingRootContainer && !data;

	useEffect(() => {
		if (!isLoading) {
			onTriggerScroll();
		}
	}, [isLoading, onTriggerScroll]);

	const currentContainer = data?.organization?.application?.rootContainer;
	const childrenTree = currentContainer?.children;

	setCurrentContainer({
		container: currentContainer,
		isRootContainer: true,
	});

	if (isLoading) {
		return <ContainerChildrenLoadingSkeleton ariaLabel="content loading" />;
	}

	const isContainerEmpty = childrenTree?.nodes.length === 0;
	if (isContainerEmpty) {
		return (
			<div className={classes.emptyList}>
				<EmptyTableIllustration background="white" className={classes.emptyIllustration} />
				<Heading classes={{ root: classes.heading }} level="3">
					{translate('containerChildren.emptyIllustrationTitle')}
				</Heading>
			</div>
		);
	}

	const onLoadMore = (size: number) =>
		fetchMore({
			variables: {
				paging: {
					size,
					after: childrenTree?.paging.nextCursor,
				},
			},
		});

	return (
		<>
			<div className={clsx(classes.title, classes.bold)}>
				<Heading level="4">{currentContainer?.name}</Heading>
				{isInitialContainer && (
					<div className={classes.initialLocationTag}>{translate('destinationModal.currentLocation')}</div>
				)}
			</div>
			<ContainerChildrenLoader data={childrenTree} networkStatus={networkStatus} onLoadMore={onLoadMore}>
				<CardList cards={childrenTree?.nodes} {...props} />
			</ContainerChildrenLoader>
		</>
	);
};
