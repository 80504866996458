import { useCallback } from 'react';

import { Permissions } from '@pushpay/identity.permissions';
import {
	ProductSwitcher as ProductSwitcherBase,
	ProductSwitcherRedirectValue,
	ProductSwitcherProps,
} from '@pushpay/shell';
import { ComponentProps } from '@pushpay/types';

import { useFeature } from '@src/featureFlags';
import { EntitlementKey } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyEntitlements, useMyIdentity, useMyOrganization, useMyPermissions } from '@src/myContext';
import { EXTERNAL_URLS, useParams } from '@src/router';
import { getAppSettings } from '@src/utils';

export const ProductSwitcher = ({ 'data-pp-at-target': targetId }: ComponentProps<unknown, undefined>) => {
	const { capUrl, givingPlatformUrl, env } = getAppSettings();
	const { organizationKey } = useParams<'organizationKey'>();
	const { administeredOrganizations } = useMyIdentity();
	const { details } = useMyOrganization();
	const { hasOrganizationEntitlement } = useMyEntitlements();
	const { hasOrganizationOrCampusPermission } = useMyPermissions();
	const switchToInsights = useFeature('ImplementSwitchingToInsights');
	const hasChMS = details?.productInformation.chmsInformation.isProvisioned;
	const chmsUrl = details?.productInformation.chmsInformation.tenantUrl;
	const hasGiving = details?.isGivingPlatformActive;
	const isCatholic = details?.catholicSoftwareStatus;
	const { translate } = useTranslation('common');

	const buildUrl = useCallback(
		(value: ProductSwitcherRedirectValue): string => {
			const url = new URL('launch', capUrl);
			url.searchParams.set('redirect', encodeURIComponent(window.btoa(JSON.stringify(value))));

			return url.toString();
		},
		[capUrl]
	);

	const appStudioProps: ProductSwitcherProps['appStudioProps'] = {
		label: translate('productSwitcher.appStudio.label'),
		access: 'current',
		description: translate('productSwitcher.appStudio.description'),
		learnMoreLabel: '',
		learnMoreUrl: '',
	};

	const chmsProps: ProductSwitcherProps['chmsProps'] = {
		label: translate('productSwitcher.chms.label'),
		description: translate('productSwitcher.chms.description'),
		learnMoreLabel: translate('productSwitcher.chms.learnAbout'),
		learnMoreUrl: isCatholic
			? EXTERNAL_URLS.PRODUCT_SWITCHER_LEARN_MORE_CHMS_CATHOLIC
			: EXTERNAL_URLS.PRODUCT_SWITCHER_LEARN_MORE_CHMS,
		...(hasChMS && chmsUrl
			? {
					access: 'hasAccess',
					url: buildUrl({
						product: 'ccb',
						tenantUrl: chmsUrl,
					}),
			  }
			: { access: 'organizationNoProduct' }),
	};

	const getGivingProps = (): ProductSwitcherProps['givingProps'] => {
		const getAccess = () => {
			if (!organizationKey || !hasGiving) {
				return { access: 'organizationNoProduct' };
			}
			if (!administeredOrganizations?.some(organization => organization.key === organizationKey)) {
				return { access: 'noPermission' };
			}
			return {
				access: 'hasAccess',
				url: buildUrl({ product: 'cap', organizationKey }),
			};
		};

		return {
			label: translate('productSwitcher.giving.label'),
			description: translate('productSwitcher.giving.description'),
			learnMoreLabel: translate('productSwitcher.giving.learnAbout'),
			learnMoreUrl: isCatholic
				? EXTERNAL_URLS.PRODUCT_SWITCHER_LEARN_MORE_GIVING_CATHOLIC
				: EXTERNAL_URLS.PRODUCT_SWITCHER_LEARN_MORE_GIVING,
			...getAccess(),
		} as ProductSwitcherProps['givingProps'];
	};

	const getInsightsProps = (): ProductSwitcherProps['insightsProps'] => {
		const getAccess = () => {
			const organizationHasInsights =
				env !== 'sandbox' &&
				hasOrganizationEntitlement(EntitlementKey.Insights) &&
				details?.community?.isInSync;
			if (!organizationHasInsights) {
				return { access: 'organizationNoProduct' };
			}

			if (!hasOrganizationOrCampusPermission(Permissions.engagementAnalyticsAccess)) {
				return { access: 'noPermission' };
			}
			return {
				access: 'hasAccess',
				url: buildUrl({ product: 'insights', organizationKey }),
			};
		};

		return {
			label: translate('productSwitcher.insights.label'),
			description: translate('productSwitcher.insights.description'),
			learnMoreLabel: translate('productSwitcher.insights.learnAbout'),
			learnMoreUrl: isCatholic
				? EXTERNAL_URLS.PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS_CATHOLIC
				: EXTERNAL_URLS.PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS,
			...getAccess(),
		} as ProductSwitcherProps['insightsProps'];
	};

	return (
		<ProductSwitcherBase
			appStudioProps={appStudioProps}
			buttonLabel={translate('productSwitcher.buttonLabel')}
			chmsProps={chmsProps}
			currentProductDisplayStyle="icon"
			data-pp-at-target={targetId}
			givingProps={getGivingProps()}
			insightsProps={switchToInsights ? getInsightsProps() : undefined}
			noOrgAcessTitle={translate('productSwitcher.noOrgAccessTitle')}
			pushpayMarketing={{
				label: translate('productSwitcher.marketing.label'),
				title: translate('productSwitcher.marketing.title'),
				url: givingPlatformUrl,
			}}
			title={translate('productSwitcher.title')}
			tooltips={{
				noPermission: translate('productSwitcher.tooltips.noPermission'),
				organizationNoProduct: translate('productSwitcher.tooltips.organizationNoProduct'),
			}}
		/>
	);
};
