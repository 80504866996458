import { InfoIcon } from '@pushpay/iconography';
import { Switch } from '@pushpay/inputs';
import { createUseStyles, negative } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

import { LabelWrapper } from '../wrappers';

const useStyle = createUseStyles((theme: Theme) => ({
	content: {
		display: 'flex',
		gap: theme.SPACING.XSMALL,
		alignItems: 'center',
		font: theme.typography['text-4'],
		marginLeft: theme.SPACING.XSMALL,
	},
	icon: {
		height: '14px',
		width: '14px',
		marginLeft: negative(theme.SPACING.XXSMALL),
	},
	wrapper: {},
}));

type InAppBrowserSwitchProps = React.ComponentProps<typeof Switch> & { label?: string };

export function InAppBrowserSwitch({
	classes: classesProp,
	label,
	...rest
}: ComponentProps<InAppBrowserSwitchProps, typeof useStyle>) {
	const classes = useStyle(classesProp);
	const { translate } = useTranslation('appDesign');

	return (
		<LabelWrapper classes={{ content: classes.content, wrapper: classes.wrapper }} label={label}>
			<Switch {...rest} />
			{translate('itemSetting.inAppBrowser.label')}
			<Tooltip content={translate('itemSetting.inAppBrowser.tooltip')} panelSpace="XSMALL" placement="top">
				<InfoIcon className={classes.icon} />
			</Tooltip>
		</LabelWrapper>
	);
}
