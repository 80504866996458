import { NEW_CARD_PREFIX } from '@src/components/card/Card';
import { useFeature } from '@src/featureFlags';
import {
	ChildItemFragment,
	ChildItemFragmentDoc,
	CreateItemInput,
	useCreateItemMutation,
	ChildContainerFragment,
	ChildContainerFragmentDoc,
	ItemType,
} from '@src/graphql/generated';
import { usePendo } from '@src/pendo';
import { useHistoryState } from '@src/shared/hooks';
import { insertToArrayBefore } from '@src/utils/array';

export function useOptimizedCreateItemMutation() {
	const [mutate] = useCreateItemMutation();
	const { pendoTrackEvent } = usePendo();
	const { updateHistoryState } = useHistoryState();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');

	const createItem = ({
		organizationKey,
		platformCampusKey,
		input,
	}: {
		organizationKey: string;
		platformCampusKey?: string | null;
		input: CreateItemInput;
	}) => {
		const { applicationId, insertBefore, parentContainerId, name, icon, template, type, properties } = input;

		mutate({
			variables: {
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
					parentContainerId,
					name,
					icon,
					template,
					type,
					insertBefore,
					properties,
				},
			},
			update(cache, { data }) {
				cache.modify({
					id: cache.identify({
						__typename: 'Container',
						id: parentContainerId,
					}),
					fields: {
						children(existing, { readField }) {
							const createdItem = data?.createItem?.createdItem;
							if (!createdItem) {
								return existing;
							}
							const createdProperties =
								isInAppCalendarEnabled && createdItem.type === ItemType.Event
									? createdItem.properties
									: [];
							const newChildItemRef = cache.writeFragment<ChildItemFragment>({
								data: {
									id: createdItem.id,
									name: createdItem.name,
									icon: createdItem.icon,
									hidden: createdItem.isHidden,
									item: {
										id: createdItem.id,
										isMirrored: createdItem.isMirrored,
										type: createdItem.type,
										feedId: null,
										image: null,
										properties: createdProperties,
									},
									position: -1,
									__typename: 'ChildItem',
								},
								variables: {
									organizationKey,
								},
								fragment: ChildItemFragmentDoc,
								fragmentName: 'ChildItem',
							});

							const insertBeforeIndex = existing.nodes
								.map((nodeRef: any) => readField('id', nodeRef))
								.findIndex((id: string) => id === insertBefore);

							const isOptimisticResponse = createdItem.id.startsWith(NEW_CARD_PREFIX);
							if (!isOptimisticResponse) {
								const isLastPosition = insertBeforeIndex === -1;
								const draggedPosition = isLastPosition ? existing.nodes.length : insertBeforeIndex;

								const cachedChildContainer = cache.readFragment<ChildContainerFragment>({
									id: cache.identify({
										id: parentContainerId,
										__typename: 'ChildContainer',
									}),
									variables: {
										organizationKey,
									},
									fragment: ChildContainerFragmentDoc,
									fragmentName: 'ChildContainer',
								});

								pendoTrackEvent('Item draggable dropped', {
									draggableType: 'item',
									itemName: createdItem.name,
									itemType: createdItem.type,
									draggedPosition,
									parentContainerType: cachedChildContainer?.container?.type ?? '',
									parentContainerName: cachedChildContainer?.name ?? '',
								});
							}
							return {
								...existing,
								nodes: insertToArrayBefore(existing.nodes, insertBeforeIndex, newChildItemRef),
							};
						},
					},
				});

				if (data?.createItem.__typename === 'CreateItemResult') {
					const { createdItem } = data?.createItem;

					updateHistoryState({ recentlyCreatedChildId: createdItem.id });
				}
			},
			optimisticResponse: {
				createItem: {
					createdItem: {
						id: NEW_CARD_PREFIX + crypto.randomUUID(),
						isHidden: false,
						name: input.name,
						icon: input.icon,
						type: input.type,
						isMirrored: false,
						properties: [],
						__typename: 'Item',
					},
					__typename: 'CreateItemResult',
				},
			},
		});
	};
	return createItem;
}
