import { FormFieldProps, FormState, SelectField, TextField } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import {
	AppLinkProperty,
	ContainerTemplate,
	DefaultProperty,
	GiveProperty,
	SaveContainerSettingsSchema,
	SmsProperty,
	TextHtmlProperty,
	TextProperty,
	WebsiteProperty,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n/translation';

import { ContainerSettings } from './type';

const useStyle = createUseStyles({
	label: {},
});

export type SubtitleFieldProps = ComponentProps<
	{
		field: FormState<SaveContainerSettingsSchema['input']['subtitle']>;
		container: ContainerSettings;
		layoutTemplate: ContainerTemplate;
		readonly?: boolean;
	},
	typeof useStyle
>;

type SubtitleOptionPropertyType =
	| AppLinkProperty['__typename']
	| DefaultProperty['__typename']
	| GiveProperty['__typename']
	| SmsProperty['__typename']
	| TextHtmlProperty['__typename']
	| TextProperty['__typename']
	| WebsiteProperty['__typename'];

const allowedInSubTitleItemProperties: SubtitleOptionPropertyType[] = [
	'AppLinkProperty',
	'DefaultProperty',
	'GiveProperty',
	'SmsProperty',
	'TextHtmlProperty',
	'TextProperty',
	'WebsiteProperty',
];

const subtitleAllowedTemplates = [
	ContainerTemplate.List_72Dp,
	ContainerTemplate.List_72DpIcon,
	ContainerTemplate.List_72DpTidbit,
	ContainerTemplate.List_72DpIconTidbit,
	ContainerTemplate.List_72DpThumb,
	ContainerTemplate.List_90DpThumb,
	ContainerTemplate.List_44DpTidbit,
	ContainerTemplate.List_90DpSectionLegacy,
];

export function SubtitleField({
	field,
	container,
	layoutTemplate,
	classes: classesProp,
	readonly = false,
}: SubtitleFieldProps) {
	const classes = useStyle(classesProp);
	const { translate } = useTranslation('appDesign');
	const subtitleOptions = container?.children?.nodes
		.filter(node => node.__typename === 'ChildItem')
		.flatMap(childItem => {
			// type guard purpose
			if (childItem.__typename === 'ChildItem') {
				return childItem.item.properties;
			}
			return undefined;
		})
		.filter(prop => allowedInSubTitleItemProperties.includes(prop?.__typename as SubtitleOptionPropertyType))
		.map(prop => prop?.header || '')
		.filter((value, index, self) => self.indexOf(value) === index)
		.map(header => ({ display: header, value: header }));

	const showSubtitle = !!subtitleAllowedTemplates.find(layout => layout === layoutTemplate);

	if (!showSubtitle) return null;

	const fieldProps: FormFieldProps<string> = {
		field,
		label: translate('settings.container.subTitle'),
		labelPosition: 'top',
		showLabel: true,
	};

	return subtitleOptions?.length ? (
		<SelectField
			classes={{ label: classes.label }}
			options={[
				{ display: translate('settings.container.subTitleOptions.noAction'), value: null },
				...subtitleOptions,
			]}
			readOnly={readonly}
			{...fieldProps}
		/>
	) : (
		<TextField
			classes={{ label: classes.label }}
			textInputProps={{ value: translate('settings.container.noAvailableOptions'), disabled: true }}
			readOnly
			{...fieldProps}
		/>
	);
}
