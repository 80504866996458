import { useState, useCallback, memo, useId } from 'react';

import { Button } from '@pushpay/button';
import { RemoveIcon, SearchIcon } from '@pushpay/iconography';
import { TextInput } from '@pushpay/inputs';
import { clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { useTranslation } from '@src/i18n';

import { useSearchInputStyles } from './searchInputStyles';

const defaultValue = '';
export type SearchInputProps = {
	initialValue: string;
	placeholderText?: string;
	onSearch?: (value: string) => void;
	onInputChange?: (value: string) => void;
	onReset?: () => void;
};

export const SearchInput = memo(
	({
		initialValue,
		placeholderText,
		onSearch,
		onInputChange,
		onReset,
		classes: classesProp,
		className: classNameProp,
		'data-pp-at-target': targetId,
	}: ComponentProps<SearchInputProps, typeof useSearchInputStyles>) => {
		const { translate } = useTranslation('appDesign');
		const [value, setValue] = useState(initialValue);
		const classes = useSearchInputStyles(classesProp);
		const className = clsx(classes.root, classNameProp);

		const onSearchHandler = useCallback(() => {
			if (onSearch) {
				onSearch(value);
			}
		}, [value, onSearch]);

		const onInputChangeHandler = useCallback(
			(inputValue: string) => {
				setValue(inputValue);
				if (onInputChange) {
					onInputChange(inputValue);
				}
			},
			[onInputChange]
		);

		const onResetHandler = useCallback(() => {
			setValue(defaultValue);
			if (onReset) {
				onReset();
			}
		}, [onReset]);

		const onKeyDownHandler = useCallback(
			(event: React.KeyboardEvent<HTMLInputElement>) => {
				if (event.key !== 'Enter') return;

				event.preventDefault();
				event.stopPropagation();
				onSearchHandler();
			},
			[onSearchHandler]
		);

		return (
			<div className={className}>
				<TextInput
					autoComplete="off"
					classes={{
						field: classes.field,
						root: classes.textInput,
					}}
					data-pp-at-target={targetId}
					id={useId()}
					placeholder={placeholderText ?? translate('searchInput.placeholderText')}
					value={value}
					autoFocus
					onChange={onInputChangeHandler}
					onKeyDown={onKeyDownHandler}
				>
					{value && (
						<Button
							aria-label={translate('searchInput.resetButtonAriaLabel')}
							classes={{ root: classes.resetButton }}
							data-pp-at-target={`${targetId}-reset-button`}
							displayStyle="text"
							type="button"
							onClick={onResetHandler}
						>
							<RemoveIcon data-pp-at-target={`${targetId}-remove-icon`} />
						</Button>
					)}
				</TextInput>
				<Button
					aria-label={translate('searchInput.searchButtonAriaLabel')}
					className={classes.button}
					data-pp-at-target={`${targetId}-search-button`}
					displayStyle="primary"
					type="button"
					onClick={onSearchHandler}
				>
					<SearchIcon data-pp-at-target={`${targetId}-search-icon`} />
				</Button>
			</div>
		);
	}
);
