import { Field, FormState } from '@pushpay/forms';
import { Columns, Column } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { AppLinkPropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, TextFieldWrapper } from '../wrappers';

export type AppLinkProps = ComponentProps<
	{
		propertyField: FormState<AppLinkPropertyInput>;
	},
	undefined
>;

const useStyles = createUseStyles({
	noMargin: {
		marginLeft: 0,
	},
	noPadding: {
		paddingLeft: 0,
	},
});

export const AppLink = ({ propertyField, 'data-pp-at-target': targetId }: AppLinkProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyles(undefined);

	const {
		label: labelField,
		iosAppUrl: iosAppUrlField,
		iosDefaultUrl: iosDefaultUrlField,
		androidAppUrl: androidAppUrlField,
		androidDefaultUrl: androidDefaultUrlField,
	} = propertyField;

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={translate('appLink.labelPlaceholder')}
				data-pp-at-target={`${targetId}-app-link-label`}
				field={labelField as Field<string>}
				label={translate('appLink.labelPlaceholder')}
				labelPosition="top"
				showLabel
			/>
			<Columns classes={{ root: classes.noMargin }} space="SMALL" stackWhen="NEVER">
				<Column classes={{ inner: classes.noPadding }}>
					<TextFieldWrapper
						aria-label={translate('appLink.iOSAppUrl')}
						classes={{ rootLabelTop: classes.noMargin }}
						data-pp-at-target={`${targetId}-app-link-ios-app-url`}
						field={iosAppUrlField as Field<string>}
						label={translate('appLink.iOSAppUrl')}
						labelPosition="top"
						showLabel
					/>
				</Column>
				<Column>
					<TextFieldWrapper
						aria-label={translate('appLink.iOSDefaultUrl')}
						classes={{ rootLabelTop: classes.noMargin }}
						data-pp-at-target={`${targetId}-app-link-ios-default-url`}
						field={iosDefaultUrlField as Field<string>}
						label={translate('appLink.iOSDefaultUrl')}
						labelPosition="top"
						showLabel
					/>
				</Column>
			</Columns>
			<Columns classes={{ root: classes.noMargin }} space="SMALL" stackWhen="NEVER">
				<Column classes={{ inner: classes.noPadding }}>
					<TextFieldWrapper
						aria-label={translate('appLink.androidAppUrl')}
						classes={{ rootLabelTop: classes.noMargin }}
						data-pp-at-target={`${targetId}-app-link-android-app-url`}
						field={androidAppUrlField as Field<string>}
						label={translate('appLink.androidAppUrl')}
						labelPosition="top"
						showLabel
					/>
				</Column>
				<Column>
					<TextFieldWrapper
						aria-label={translate('appLink.androidDefaultUrl')}
						classes={{ rootLabelTop: classes.noMargin }}
						data-pp-at-target={`${targetId}-app-link-android-default-url`}
						field={androidDefaultUrlField as Field<string>}
						label={translate('appLink.androidDefaultUrl')}
						labelPosition="top"
						showLabel
					/>
				</Column>
			</Columns>
		</ContentWrapper>
	);
};
