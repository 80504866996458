import { FC, MouseEvent } from 'react';

import { TextInput, TextInputProps } from '@pushpay/inputs';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { DraggableIcon } from '../draggable';
import { Icon } from '../icon';

export type InputFieldProps = TextInputProps & {
	icon?: DraggableIcon;
	iconAfter?: DraggableIcon;
	onIconPress?: (event: MouseEvent<HTMLDivElement>) => void;
	onIconAfterPress?: (event: MouseEvent<HTMLDivElement>) => void;
};

const noOp = () => {};
const iconSize = 16;
const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		position: 'relative',
	},
	input: ({ icon, iconAfter }: { icon?: DraggableIcon; iconAfter?: DraggableIcon }) => {
		const padding = parseInt(theme.SPACING.FIELD_PADDING_H, 10);
		const offset = padding * 2 + iconSize - 4;

		return {
			paddingLeft: icon ? offset : padding,
			paddingRight: iconAfter ? offset : padding,
		};
	},
	container: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		display: 'flex',
		fontSize: '16px',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
		cursor: 'pointer',
		paddingLeft: theme.SPACING.FIELD_PADDING_H,
		paddingRight: theme.SPACING.FIELD_PADDING_H,
	},
	left: {
		left: 0,
	},
	right: {
		right: 0,
	},
	icon: {
		fontSize: iconSize,
	},
}));

export const InputField: FC<InputFieldProps> = ({
	classes: classesProp,
	className: classNameProp,
	icon,
	iconAfter,
	onIconPress = noOp,
	onIconAfterPress = noOp,
	...rest
}) => {
	const classes = useStyles(classesProp, { icon, iconAfter });
	const rootClassName = clsx(classNameProp, classes.root);
	const inputClassName = clsx(classNameProp, classes.input);

	return (
		<div className={rootClassName}>
			{icon && (
				<div
					className={clsx(classes.container, classes.left)}
					role="button"
					tabIndex={0}
					onClick={onIconPress}
					onKeyPress={noOp}
				>
					<Icon classes={{ root: classes.icon }} name={icon} />
				</div>
			)}
			<TextInput {...rest} classes={{ field: inputClassName }} />
			{iconAfter && (
				<div
					className={clsx(classes.container, classes.right)}
					role="button"
					tabIndex={0}
					onClick={onIconAfterPress}
					onKeyPress={noOp}
				>
					<Icon classes={{ root: classes.icon }} name={iconAfter} />
				</div>
			)}
		</div>
	);
};
