import { useCallback, useId } from 'react';

import { FormState, Field, useMutateField } from '@pushpay/forms';
import { Columns, Column } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { IconButton } from '@src/components/buttons';
import { Icon as IconComponent } from '@src/components/icon';
import { useShowNotification } from '@src/components/notification';
import { AddressPropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, TextFieldWrapper } from '../wrappers';

const useStyles = createUseStyles((theme: Theme) => ({
	columns: {
		marginLeft: 0,
	},
	noPadding: {
		padding: 0,
	},
	icon: {
		color: theme.colors['grey-900'],
		fontSize: '20px',
	},
	iconButton: {
		backgroundColor: theme.colors['grey-300'],
	},
	iconWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
	},
	iconPadding: { padding: '24px 0 0' },
}));

export type AddressProps = ComponentProps<
	{
		propertyField: FormState<AddressPropertyInput>;
	},
	undefined
>;

export const Address = ({ propertyField, 'data-pp-at-target': targetId }: AddressProps) => {
	const { translate } = useTranslation('appDesign');
	const { translate: translateCommon } = useTranslation();
	const classes = useStyles(undefined);
	const { street, city, state, zip, latitude, longitude } = propertyField;
	const { mutateField: mutateFieldLatitude } = useMutateField(latitude as Field<string | null>);
	const { mutateField: muatateFieldLongitude } = useMutateField(longitude as Field<string | null>);
	const showNotification = useShowNotification();

	const mutateGeocodeFields = useCallback(
		(latitudeValue: string | null, longitudeValue: string | null) => {
			if (latitude?.value !== latitudeValue) {
				mutateFieldLatitude(latitudeValue);
			}
			if (longitude?.value !== longitudeValue) {
				muatateFieldLongitude(longitudeValue);
			}
		},
		[latitude, longitude, mutateFieldLatitude, muatateFieldLongitude]
	);

	const getGeocode = useCallback(
		({ address }: google.maps.GeocoderRequest) => {
			const googleGeocoder =
				typeof google === 'object' && typeof google.maps === 'object' && new google.maps.Geocoder();
			if (!googleGeocoder) {
				showNotification({
					type: 'error',
					title: translateCommon('errors.title.error'),
					content: translateCommon('errors.text.networkOrServerError'),
				});
				return;
			}
			googleGeocoder.geocode({ address }, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					const location = results?.[0].geometry?.location;
					if (location) {
						const { lat, lng } = location;
						mutateGeocodeFields(lat().toString(), lng().toString());
						return;
					}
				}

				// Clear geocode related fields for any status other than 'OK'
				mutateGeocodeFields(null, null);
			});
		},
		[showNotification, translateCommon, mutateGeocodeFields]
	);

	return (
		<ContentWrapper>
			<TextFieldWrapper
				aria-label={translate('address.street')}
				data-pp-at-target={`${targetId}-address-street-field`}
				field={street as Field<string>}
				id={useId()}
				label={translate('address.street')}
				labelPosition="top"
				showLabel
			/>
			<Columns classes={{ root: classes.columns }} space="SMALL" stackWhen="NEVER">
				<Column classes={{ inner: classes.noPadding }} width="1/2">
					<TextFieldWrapper
						aria-label={translate('address.city')}
						data-pp-at-target={`${targetId}-address-city-field`}
						field={city as Field<string>}
						id={useId()}
						label={translate('address.city')}
						labelPosition="top"
						showLabel
					/>
				</Column>
				<Column>
					<TextFieldWrapper
						aria-label={translate('address.state')}
						data-pp-at-target={`${targetId}-address-state-field`}
						field={state as Field<string>}
						id={useId()}
						label={translate('address.state')}
						labelPosition="top"
						showLabel
					/>
				</Column>
				<Column>
					<TextFieldWrapper
						aria-label={translate('address.zip')}
						data-pp-at-target={`${targetId}-address-zip-field`}
						field={zip as Field<string>}
						id={useId()}
						label={translate('address.zip')}
						labelPosition="top"
						showLabel
					/>
				</Column>
			</Columns>
			<Columns classes={{ root: classes.columns }} space="SMALL" stackWhen="NEVER">
				<Column classes={{ inner: classes.noPadding }} width="4/5">
					<Columns space="SMALL" stackWhen="NEVER">
						<Column>
							<TextFieldWrapper
								aria-label={translate('address.latitude')}
								data-pp-at-target={`${targetId}-address-latitude-field`}
								field={latitude as Field<string>}
								id={useId()}
								label={translate('address.latitude')}
								labelPosition="top"
								showLabel
							/>
						</Column>
						<Column>
							<TextFieldWrapper
								aria-label={translate('address.longitude')}
								data-pp-at-target={`${targetId}-address-longitude-field`}
								field={longitude as Field<string>}
								id={useId()}
								label={translate('address.longitude')}
								labelPosition="top"
								showLabel
							/>
						</Column>
					</Columns>
				</Column>
				<Column classes={{ inner: classes.iconPadding }}>
					<div className={classes.iconWrapper}>
						<Tooltip
							aria-hidden={undefined}
							content={translate('address.geocode')}
							data-pp-at-target={`${targetId}-geocode-tooltip`}
							placement="top"
						>
							<IconButton
								aria-label={translate('address.geocode')}
								classes={{ button: classes.iconButton }}
								data-pp-at-target={`${targetId}-geocode`}
								icon={
									<IconComponent
										classes={{ root: classes.icon }}
										data-pp-at-target={`${targetId}-geocode-icon`}
										name="marker-pin-location"
									/>
								}
								size="medium"
								onClick={() => {
									const address = `${street?.value ?? ''}${city?.value ?? ''}${state?.value ?? ''}${
										zip?.value ?? ''
									}`;
									getGeocode({ address });
								}}
							/>
						</Tooltip>
					</div>
				</Column>
			</Columns>
		</ContentWrapper>
	);
};
