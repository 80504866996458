import { HelpCenterIcon } from '@pushpay/iconography';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { Link } from '@src/components/link';
import { getAppSettings } from '@src/utils';

import { topNavItemStyles } from '../topNavItemStyles';

const useStyles = createUseStyles((theme: Theme) => ({
	...topNavItemStyles(theme),
	helpCenterIcon: {
		color: theme.colors['grey-900'],
		fontSize: '1.5em',
	},
}));

export const HelpLink = ({ 'data-pp-at-target': targetId }: ComponentProps<unknown, undefined>) => {
	const classes = useStyles(undefined);
	const { helpLinkUrl } = getAppSettings();

	return (
		<Link
			className={clsx(classes.item, classes.itemLink)}
			data-pp-at-target={targetId}
			href={helpLinkUrl}
			target="_blank"
		>
			<HelpCenterIcon className={classes.helpCenterIcon} data-pp-at-target={`${targetId}-icon`} />
		</Link>
	);
};
