import { useEffect } from 'react';

import { Organization } from '@src/graphql/generated';

import { featureFlagManager } from './featureFlagManager';

export function useOrganizationFeatureFlagOverrides(organizationOverrides?: Organization['featureFlags']) {
	useEffect(
		function setOverrides() {
			if (organizationOverrides) {
				featureFlagManager.setOrganizationOverrides(organizationOverrides);
			}
		},
		[organizationOverrides]
	);
}
