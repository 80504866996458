import { ItemType } from '@src/graphql/generated';

import { ItemSettings } from '../types';

type FormAccessibility = {
	isFullyDisabled: boolean;
	isOnlyDHSEnabled: boolean;
};

export const getFormAccessibility = (
	item: ItemSettings,
	isInFeedContainer: boolean,
	isInAppCalendarEnabled: boolean
): FormAccessibility => {
	if (!isInAppCalendarEnabled) {
		return {
			isFullyDisabled: isInFeedContainer,
			isOnlyDHSEnabled: false,
		};
	}

	const isEventItem = item?.type === ItemType.Event;
	const isEventGeneratedFromFeed = isEventItem && !!item?.feedId;
	const isManualEvent = isEventItem && !item?.feedId;

	if (!isInFeedContainer || isManualEvent) {
		return {
			isFullyDisabled: false,
			isOnlyDHSEnabled: false,
		};
	}

	return {
		isFullyDisabled: !isEventGeneratedFromFeed,
		isOnlyDHSEnabled: isEventGeneratedFromFeed,
	};
};
