import { matchPath } from 'react-router-dom';

import { Button } from '@pushpay/button';
import { StackItem } from '@pushpay/layout';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { Group, SettingSwitch } from '@src/components/testWidget/components';
import { FeatureFlag, featureFlagManager } from '@src/featureFlags';
import { useMyOrganizationLazyQuery } from '@src/graphql/generated';
import { ROUTE_PATHS } from '@src/router';

import { useTestWidgetFeatureFlags } from './useTestWidgetFeatureFlags';

const useStyles = createUseStyles((theme: Theme) => ({
	switchFlagOverriden: {
		color: theme.colors['green-400'],
		backgroundColor: 'currentColor',
		borderColor: 'currentColor',
	},
	switchDisabled: {
		filter: 'blur(2px)',
		opacity: 0.5,
	},
}));

export const FeatureFlagsGroup = ({
	classes: classesProp,
	'data-pp-at-target': targetId,
}: ComponentProps<unknown, typeof useStyles>) => {
	const classes = useStyles(classesProp);

	const [getOrganization, { loading }] = useMyOrganizationLazyQuery({
		onCompleted: data => {
			if (data && data.organization) {
				featureFlagManager.setOrganizationOverrides(data.organization.featureFlags);
			}
		},
	});

	const fetchOrganizationFlags = () => {
		const match = matchPath(
			{
				path: ROUTE_PATHS.ORGANIZATION_ROOT,
				end: false,
			},
			window.location.pathname
		);
		if (!match?.params?.organizationKey) {
			// we aren't in an organization context
			return;
		}
		const { organizationKey } = match.params;

		getOrganization({ variables: { organizationKey } });
	};

	const { allFeatureFlags, featureFlagOverrides, dispatch } = useTestWidgetFeatureFlags();

	return (
		<Group data-pp-at-target={targetId} groupName="Feature Flags">
			<StackItem alignH="right">
				<Button
					data-pp-at-target={`${targetId}-reset`}
					displaySize="small"
					displayStyle="text"
					type="button"
					onClick={() => {
						dispatch({ type: 'featureFlagOverridesReset' });
						fetchOrganizationFlags();
					}}
				>
					Reset
				</Button>
			</StackItem>
			{allFeatureFlags.map(flag => (
				<SettingSwitch
					key={flag.name}
					checked={flag.value}
					classes={{
						root: clsx(loading && classes.switchDisabled),
						switch: clsx(flag.name in featureFlagOverrides && classes.switchFlagOverriden),
					}}
					data-pp-at-target={`${targetId}-${flag.name}`}
					disabled={loading}
					id={`${targetId}-${flag.name}`}
					onChange={flagValue =>
						dispatch({
							type: 'featureFlagOverrides',
							...({ [flag.name]: flagValue } as Record<FeatureFlag, boolean>),
						})
					}
				>
					{flag.name}
				</SettingSwitch>
			))}
		</Group>
	);
};
