import { Switch, SwitchProps } from '@pushpay/inputs';
import { createUseStyles } from '@pushpay/styles';

const useStyles = createUseStyles({
	root: {
		padding: 0,
		width: '100%',
	},
	switch: {
		height: '1em',
		width: '2em',
		marginRight: '10px',
	},
	thumb: {
		width: '1em',
		height: '1em',
	},
	thumbChecked: {
		'&:after': {
			transition: 'border-color 0.3s, opacity 0.3s 150ms',
		},
	},
	wrapper: {
		justifyContent: 'start',
		cursor: 'pointer',
	},
});

export const SettingSwitch = ({ classes: classesProp, ...rest }: SwitchProps) => {
	const classes = useStyles(classesProp);
	return <Switch classes={classes} {...rest} />;
};
