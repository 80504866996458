import { useCallback } from 'react';

import { DynamicFieldsProvider } from '@pushpay/forms';

import { CarouselImagesDynamicFields } from '@src/components/carouselUploader/types';
import { useParentContext } from '@src/components/properties/contexts';
import { useCarouselPendingUploadImageMapActions } from '@src/context';
import { Image, useGetCarouselImagesQuery } from '@src/graphql/generated';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { usePolling } from '@src/utils';

import { CarouselImageField } from './CarouselImageField';

type CarouselImageFieldWrapperProps = {
	carouselId: string;
	imagesDynamicFields: CarouselImagesDynamicFields;
	images: Image[];
	itemId: string;
};

export const CarouselImageFieldWrapper = ({
	images,
	itemId,
	imagesDynamicFields,
	carouselId,
}: CarouselImageFieldWrapperProps) => {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { parentContainerId } = useParentContext();
	const { discardPendingUploadImagesForOneCarousel } = useCarouselPendingUploadImageMapActions();

	const isImageProcessing = images.some(image => image?.urls?.unprocessedImageUrl && !image?.urls?.original);

	const { startPolling, stopPolling } = useGetCarouselImagesQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId: parentContainerId ?? '',
			itemId,
		},
		fetchPolicy: 'network-only',
		skip: !isImageProcessing,
	});

	const onPollEnd = useCallback(() => {
		const isStillProcessingImages = images.some(image => image?.urls?.original === undefined);

		if (!isStillProcessingImages) return;

		discardPendingUploadImagesForOneCarousel(carouselId);
	}, [images, discardPendingUploadImagesForOneCarousel, carouselId]);

	usePolling({
		inProgress: isImageProcessing,
		startPolling,
		stopPolling,
		onPollEnd,
	});

	return (
		<DynamicFieldsProvider dynamicFields={imagesDynamicFields}>
			<CarouselImageField
				carouselId={carouselId}
				images={images}
				imagesDynamicFields={imagesDynamicFields}
				isImageProcessing={isImageProcessing}
			/>
		</DynamicFieldsProvider>
	);
};
