import { createUseStyles } from '@pushpay/styles';

export const useStyles = createUseStyles({
	root: {
		overflow: 'hidden',
		padding: 0,
	},
	loading: {
		opacity: 0.5,
	},
});
