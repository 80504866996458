import { HeaderTemplate } from '@pushpay/shell';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { Divider } from './components/Divider';
import { HelpLink, DownloadLink } from './components/links';
import { ProductSwitcher } from './components/productSwitcher';
import { ProfileMenu } from './components/profileMenu';
import { AppSelector, OrgSelector } from './components/selectors';

export type TopNavProps = {
	withMyOrgData?: boolean;
	withMeData?: boolean;
};

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		position: 'relative',
	},
	toolBar: {
		paddingRight: 0,
	},
	rightSide: {
		paddingLeft: theme.SPACING.XSMALL,
	},
}));

export const TopNav = ({
	'data-pp-at-target': targetId,
	withMyOrgData = true,
	withMeData = true,
	classes: classesProp,
	className: classNameProp,
}: ComponentProps<TopNavProps, undefined>) => {
	const classes = useStyles(classesProp);
	const className = clsx(classes.root, classNameProp);

	return (
		<HeaderTemplate
			classes={{ toolBar: classes.toolBar, rightSide: classes.rightSide }}
			className={className}
			leftSection={
				<>
					{withMeData && withMyOrgData && (
						<>
							<ProductSwitcher data-pp-at-target={`${targetId}-product-switcher`} />
							<Divider />
						</>
					)}
					<OrgSelector />
				</>
			}
			rightSection={withMeData && <ProfileMenu data-pp-at-target={`${targetId}-profile-menu`} />}
			toolBar={
				withMyOrgData ? (
					<>
						<AppSelector data-pp-at-target={`${targetId}-app-selector`} />
						<DownloadLink data-pp-at-target={`${targetId}-download-link`} />
						<HelpLink data-pp-at-target={`${targetId}-help-link`} />
					</>
				) : (
					<HelpLink data-pp-at-target={`${targetId}-help-link`} />
				)
			}
		/>
	);
};
