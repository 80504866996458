import { useCallback, useLayoutEffect, useRef } from 'react';

type EventHandler = (...args: any[]) => void;

export function useEvent(handler: EventHandler) {
	const handlerRef = useRef<EventHandler>(() => {});

	useLayoutEffect(() => {
		handlerRef.current = handler;
	});

	return useCallback<EventHandler>((...args) => {
		const fn = handlerRef.current;
		return fn(...args);
	}, []);
}
