import { FC, ComponentProps as ReactComponentProps, PropsWithChildren } from 'react';

import { Stack } from '@pushpay/layout';

type PageSectionProps = PropsWithChildren<Pick<ReactComponentProps<typeof Stack>, 'stretchItem'>>;

export const PageSection: FC<PageSectionProps> = ({ children, ...rest }) => (
	<Stack space={['SMALL', 'MEDIUM']} {...rest}>
		{children}
	</Stack>
);
