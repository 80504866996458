import chevronDown from '@assets/icons/chevron-down.svg';

import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	dropdown: {
		zIndex: 10,
		display: 'block',
		position: 'absolute',
		top: '40px',
		boxShadow: theme.ELEVATION.EIGHT,
		borderRadius: '8px',
		minHeight: '40px',
		backgroundColor: theme.colors['common-white'],
	},
	chevronDown: {
		backgroundColor: 'transparent',
		backgroundSize: 'contain',
		display: 'inline-block',
		height: '8px',
		width: '8px',
		backgroundImage: `url(${chevronDown})`,
	},
	text: {
		display: 'flex',
		lineHeight: '20px',
		flexGrow: 1,
		minWidth: '150px',
	},
}));
