import { forwardRef, memo } from 'react';

import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import { animated } from '@react-spring/web';

import { DragDropIcon } from '@pushpay/iconography';
import { clsx } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { Icon as IconComponent } from '@src/components/icon';

import { useStyles } from './styles';
import { DraggableType, DraggableIcon, DraggableCategory } from './types';

export type DraggableComponentProps = ComponentProps<
	{
		type: DraggableType;
		icon: DraggableIcon;
		disabled?: boolean;
		label: string;
		dragOverlay?: boolean;
		dragging?: boolean;
		attributes?: {
			role?: string;
			roleDescription?: string;
			tabIndex?: number;
		};
		listeners?: DraggableSyntheticListeners;
		tooltip?: string;
		category?: DraggableCategory;
	},
	undefined
>;

export const DraggableComponent = memo(
	forwardRef<HTMLButtonElement, DraggableComponentProps>((props, ref) => {
		const {
			icon,
			type,
			disabled,
			label,
			dragOverlay,
			'data-pp-at-target': targetId,
			classes: classesProp,
			dragging,
			listeners,
			attributes,
			tooltip = '',
			category,
		} = props;
		const classes = useStyles(classesProp);
		const draggableType = category ?? type;

		return (
			<Tooltip
				classes={{ content: classes.tooltipContent }}
				content={tooltip}
				data-pp-at-target="draggable-component-tooltip"
				disabled={!tooltip}
				placement="right"
			>
				<animated.button
					ref={ref}
					aria-label={label}
					className={clsx(
						classes.root,
						disabled && classes.disabled,
						dragging && !dragOverlay && classes.placeholder,
						dragOverlay && classes.overlay
					)}
					data-pp-at-target={targetId}
					{...listeners}
					{...attributes}
					disabled={disabled}
				>
					{!dragging || dragOverlay ? (
						<>
							<DragDropIcon className={classes.handle} data-pp-at-target={`${targetId}-handle`} />
							<div className={classes.iconWrapper}>
								<IconComponent
									classes={{
										root: clsx(classes.icon, classes[draggableType]),
									}}
									data-pp-at-target={`${targetId}-icon`}
									name={icon}
								/>
								<p className={clsx(classes.label)}>{label}</p>
							</div>
						</>
					) : (
						<div className={classes.container}>
							<p className={clsx(classes.label, classes.placeholderLabel)}>{label}</p>
						</div>
					)}
				</animated.button>
			</Tooltip>
		);
	})
);
