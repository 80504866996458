import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const selectorWidth = '200px';

export const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		borderColor: theme.colors['border-input'],
		backgroundColor: theme.colors['common-white'],
		borderRadius: '3px',
		width: selectorWidth,
	},
	iconContainer: {
		color: theme.colors['grey-900'],
	},
	option: {
		overflow: 'hidden',
		maxWidth: '300px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	overlay: {
		minWidth: selectorWidth,
		overflowX: 'hidden',
	},
	root: {},
}));
