import { PropsWithChildren, createContext, useContext, useMemo, useState, useEffect } from 'react';

import { ExpandStatus } from '@pushpay/expand';

export type PropertyProviderType = PropsWithChildren<{
	icon: string;
	header: string;
	isFirstOfType?: boolean;
	hasOnlyOneTimeframeProperty?: boolean;
	propertyExpandStatus: ExpandStatus;
}>;

export type PropertyContextType = {
	icon: string;
	header: string;
	isFirstOfType: boolean;
	hasOnlyOneTimeframeProperty: boolean;
	setIcon: (icon: string) => void;
	setHeader: (header: string) => void;
	propertyExpandStatus: ExpandStatus;
};

const PropertyContext = createContext<PropertyContextType | null>(null);

export function PropertyProvider({
	icon: providedIcon,
	header: providedHeader,
	isFirstOfType = false,
	hasOnlyOneTimeframeProperty = false,
	children,
	propertyExpandStatus = 'preexpanded',
}: PropertyProviderType) {
	const [icon, setIcon] = useState(providedIcon);
	const [header, setHeader] = useState(providedHeader);

	useEffect(() => {
		setIcon(providedIcon);
	}, [providedIcon]);

	useEffect(() => {
		setHeader(providedHeader);
	}, [providedHeader]);

	const context = useMemo(
		() => ({
			icon,
			header,
			setIcon,
			setHeader,
			isFirstOfType,
			hasOnlyOneTimeframeProperty,
			propertyExpandStatus,
		}),
		[icon, header, isFirstOfType, hasOnlyOneTimeframeProperty, propertyExpandStatus]
	);

	return <PropertyContext.Provider value={context}>{children}</PropertyContext.Provider>;
}

export function usePropertyContext() {
	const context = useContext(PropertyContext);
	if (!context) {
		throw new Error('usePropertyContext must be used within an PropertyProvider');
	}

	return context;
}
