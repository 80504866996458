import { verticalListSortingStrategy } from '@dnd-kit/sortable';

import { HouseholdIcon } from '@pushpay/iconography';
import { Heading } from '@pushpay/layout';
import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { Banner } from '@src/components/banner';
import { CardList, CardProps } from '@src/components/card';
import { NEW_CARD_PREFIX } from '@src/components/card/Card';
import { useDraggablesDefinition } from '@src/components/draggable';
import { NewItemDroppableArea } from '@src/components/droppable/NewItemDroppableArea';
import { Link } from '@src/components/link';
import { ContentType, SortableContext, useBreadcrumbDispatchContext, useContentDispatchContext } from '@src/context';
import { useFeature } from '@src/featureFlags';
import {
	ContainerType,
	EntitlementKey,
	useGetContainerChildrenQuery,
	useGetDhsCardsQuery,
	useGetRootContentQuery,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp, useMyEntitlements } from '@src/myContext';
import { useAppDesignPath } from '@src/pages/shell';
import { ROUTE_PATHS } from '@src/router';
import { mapContainerChildToCard } from '@src/shared/cardUtils';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { useItemAndContainerDeleteMutation } from '@src/shared/hooks/useItemAndContainerDeleteMutation';
import { useItemAndContainerVisibilityMutation } from '@src/shared/hooks/useItemAndContainerVisibilityMutation';
import { getShadow } from '@src/shared/styles';

import { CONTAINER_CHILDREN_PAGING_SIZE } from '../constants';
import { ContainerChildrenLoader } from '../containerChildren';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
	},
	appContent: {
		font: theme.typography['heading-3'],
		color: theme.colors['text-heading'],
		paddingBottom: multiply(theme.SPACING.XSMALL, 1.5),
	},
	dhsCard: {
		display: 'flex',
		alignItems: 'center',
		font: theme.typography['text-4-semiBold'],
		color: theme.colors['productBrand-700'],
		height: '68px',
		borderRadius: '6px',
		marginBottom: theme.SPACING.MEDIUM,
		padding: `22px ${theme.SPACING.SMALL}`,
		backgroundColor: theme.colors['background-surface'],
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
		'&:hover': {
			textDecoration: 'none',
			...getShadow(),
		},
	},
	dhsCardIcon: {
		height: '24px',
		width: '24px',
		marginRight: '6px',
	},
	containerCard: {
		color: theme.colors['productBrand-700'],
	},
	itemCard: {
		color: theme.colors['productBrand-500'],
	},
	CCBModuleCard: {
		color: theme.colors['blue-500'],
	},
}));

export const Root = ({
	initialPageSize = CONTAINER_CHILDREN_PAGING_SIZE,
	'data-pp-at-target': targetId,
}: ComponentProps<{ initialPageSize?: number }, typeof useStyles>) => {
	const { translate } = useTranslation('appDesign');
	const { hasOrganizationEntitlement } = useMyEntitlements();
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const dhsPath = useAppDesignPath(organizationKey, ROUTE_PATHS.DHS);
	const {
		dhs: { enabled: isDhsEnabled },
	} = useMyApp();
	const {
		loading: isLoadingRootContainer,
		data: rootContainerData,
		networkStatus,
		fetchMore,
	} = useRootContent(initialPageSize);
	const isLoadingData = isLoadingRootContainer && !rootContainerData;
	const rootContainer = rootContainerData?.organization?.application?.rootContainer;
	const homeContainer = rootContainer?.children.nodes.filter(
		node => !node.hidden && !node.id.startsWith(NEW_CARD_PREFIX)
	)[0];

	useSetRootContent(homeContainer?.id);

	const { useQueryData } = useBreadcrumbDispatchContext();
	useQueryData({ data: rootContainerData, type: ContentType.ROOT }, isLoadingData);

	const { generateOnToggleVisibilityHandler } = useItemAndContainerVisibilityMutation({
		organizationKey,
		platformCampusKey,
		applicationId,
	});

	const { generateDeleteHandler } = useItemAndContainerDeleteMutation({
		organizationKey,
		platformCampusKey,
		applicationId,
	});
	const showDHSCard = hasOrganizationEntitlement(EntitlementKey.AppDynamicHomeScreen) && isDhsEnabled;

	const classes = useStyles(undefined);
	const { getContainerChildrenDraggables } = useDraggablesDefinition();

	const useInAppCalendar = useFeature('InAppCalendar');

	let listOfCards: CardProps[] = [];
	if (rootContainer) {
		const rootContainerId = rootContainer.id;
		const rootContainerType = ContainerType.Root;
		listOfCards =
			rootContainer.children?.nodes.map(node => {
				const canHaveChildren =
					node.__typename === 'ChildContainer' &&
					getContainerChildrenDraggables(node.container.type).length > 0;
				const card = mapContainerChildToCard(
					node,
					rootContainerId,
					rootContainerType,
					applicationId,
					canHaveChildren,
					useInAppCalendar
				);
				const parentId = card.type === 'item' ? rootContainerId : null;
				return {
					...card,
					onToggleVisibility: generateOnToggleVisibilityHandler(card.id, card.type, parentId),
					onDelete: generateDeleteHandler({
						id: card.id,
						type: card.type,
						parentId,
					}),
				};
			}) || [];
	}

	const children = rootContainer?.children;
	const onLoadMore = (size: number) =>
		fetchMore({
			variables: {
				paging: {
					size,
					after: children?.paging.nextCursor,
				},
			},
		});

	return (
		<div className={classes.root}>
			<Banner
				data-pp-at-target="root-banner"
				isEditable={false}
				isHidden={false}
				name={translate('content.title')}
			/>
			<div className={classes.content}>
				{showDHSCard && (
					<Link className={classes.dhsCard} data-pp-at-target={`${targetId}-dhs-card`} to={dhsPath}>
						<HouseholdIcon
							className={classes.dhsCardIcon}
							data-pp-at-target={`${targetId}-dhs-card-icon`}
						/>
						{translate('dhs.title')}
					</Link>
				)}
				<Heading
					classes={{
						root: classes.appContent,
					}}
					data-pp-at-target={`${targetId}-app-content-heading`}
					level="3"
				>
					{translate('content.appContent')}
				</Heading>
				<ContainerChildrenLoader data={children} networkStatus={networkStatus} onLoadMore={onLoadMore}>
					<SortableContext items={listOfCards.map(x => x.id)} strategy={verticalListSortingStrategy}>
						{rootContainer && (
							<CardList
								cards={listOfCards}
								data-pp-at-target="root-page-children-list"
								parentContainerId={rootContainer.id}
								isRootContainer
							/>
						)}
					</SortableContext>
				</ContainerChildrenLoader>
				{rootContainerData?.organization?.application?.rootContainer.id && (
					<NewItemDroppableArea parentId={rootContainerData.organization.application.rootContainer.id} />
				)}
			</div>
		</div>
	);
};

export function useRootContent(
	initialPageSize = CONTAINER_CHILDREN_PAGING_SIZE
): ReturnType<typeof useGetRootContentQuery> {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();

	return useGetRootContentQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-first',
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			paging: { size: initialPageSize },
		},
	});
}

function useSetRootContent(homeContainerId = '') {
	const { organizationKey, platformCampusKey, applicationId, campusId } = useGetQueryAndMutationVars();
	const {
		dhs: { enabled: isDhsEnabled },
	} = useMyApp();
	const { useSetContent } = useContentDispatchContext();

	const {
		loading: isLoadingDhsContent,
		data: dhsContentData,
		error: dhsContentError,
	} = useGetDhsCardsQuery({
		fetchPolicy: 'no-cache',
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			campusId,
		},
		skip: !isDhsEnabled,
	});

	const {
		loading: isLoadingContainerContent,
		data: containerContentData,
		error: containerContentError,
	} = useGetContainerChildrenQuery({
		fetchPolicy: 'cache-first',
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId: homeContainerId,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
		skip: isDhsEnabled || !homeContainerId,
	});

	useSetContent(
		{
			type: ContentType.ROOT,
			...(isDhsEnabled
				? {
						contentData: dhsContentData,
						loading: isLoadingDhsContent && !dhsContentData,
						error: !!dhsContentError,
				  }
				: {
						contentData: containerContentData,
						loading: isLoadingContainerContent && !containerContentData,
						error: !!containerContentError,
				  }),
		},
		false
	);
}
