import Icon, { IconProps } from '@pushpay/icon';

export const StatusBar = ({ className, ...rest }: IconProps) => (
	<Icon viewBox="0 0 390 47" {...rest}>
		<title>Preview status bar</title>
		<path
			d="M113.68 9.12c0 8.56 5.31 22.99 24.31 22.99h114.03c19 0 24.31-14.43 24.31-22.99S279.12 0 284.52 0H105.48c5.4 0 8.2.56 8.2 9.12z"
			fill="#18181d"
		/>
		<path d="M343.78 0h-59.27c-5.39 0-8.19.56-8.19 9.12s-5.31 22.99-24.31 22.99H137.98c-19 0-24.31-14.43-24.31-22.99S110.88 0 105.48 0H46.22C20.69 0 0 20.68 0 46.18V47h390v-.82C390 20.68 369.31 0 343.78 0z" />
		<g transform="matrix(.866 0 0 .866 15 1.858871)">
			<circle cx="138.87" cy="11.26" fill="#1f2023" r="6.94" />
			<circle cx="138.87" cy="11.26" fill="#0a0d13" r="4.28" />
			<circle cx="138.87" cy="11.26" fill="#091427" r="3.26" />
		</g>
		<g fillRule="evenodd">
			<path
				d="M357.39 20.09a2.67 2.67 0 0 1 2.67 2.67v6a2.67 2.67 0 0 1-2.67 2.67h-16.67a2.67 2.67 0 0 1-2.67-2.67v-6a2.67 2.67 0 0 1 2.67-2.67h16.67zm0 1h-16.67c-.87 0-1.59.67-1.66 1.52v6.14c0 .87.66 1.59 1.52 1.66h16.81c.87 0 1.59-.66 1.66-1.52v-6.14c0-.87-.66-1.59-1.52-1.66h-.14zm4.99 4.66a2.17 2.17 0 0 1-1.33 2v-4c.8.34 1.33 1.13 1.33 2z"
				opacity=".35"
				style={{
					isolation: 'isolate',
				}}
			/>
			<path
				className={className}
				d="M331.34 24.75c-1.61-1.53-3.74-2.38-5.95-2.38a8.6 8.6 0 0 0-5.95 2.38c-.12.12-.32.12-.43 0l-1.16-1.17c-.06-.06-.09-.14-.09-.23s.04-.17.1-.23a10.9 10.9 0 0 1 15.08 0c.06.06.1.14.1.23s-.03.17-.09.23l-1.16 1.17c-.12.12-.31.12-.43 0zm-2.66 2.69c-.9-.82-2.08-1.27-3.29-1.27-1.22 0-2.39.45-3.29 1.27-.12.12-.32.11-.43 0l-1.15-1.17c-.06-.06-.1-.14-.09-.23s.04-.17.1-.23a7.15 7.15 0 0 1 9.75 0c.06.06.1.14.1.23s-.03.17-.09.23l-1.15 1.17c-.12.12-.31.12-.43 0zm-1.07 1.51c.06-.06.1-.14.09-.23 0-.09-.04-.17-.1-.23a3.42 3.42 0 0 0-4.42 0 .29.29 0 0 0-.1.23c0 .09.03.17.09.23l2 2.02a.31.31 0 0 0 .22.09.31.31 0 0 0 .22-.09l2-2.02zm13.78-6.85a1.33 1.33 0 0 0-1.33 1.33v4.67a1.33 1.33 0 0 0 1.33 1.33h15.33a1.33 1.33 0 0 0 1.33-1.33v-4.67a1.33 1.33 0 0 0-1.33-1.33h-15.33zm-29.67-1.67h-1c-.55 0-1 .45-1 1v8.67c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8.67c0-.55-.45-1-1-1zm-5.67 2.33h1c.55 0 1 .45 1 1v6.33c0 .55-.45 1-1 1h-1c-.55 0-1-.45-1-1v-6.33c0-.55.45-1 1-1zm-3.67 2.33h-1c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1zm-4.67 2h-1c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1zm-242.89-.88c0 3.32-1.49 5.28-4 5.28-1.8 0-3.18-1.07-3.47-2.69h1.76c.23.75.88 1.21 1.73 1.21 1.44 0 2.3-1.35 2.3-3.6h-.12c-.46.94-1.4 1.48-2.56 1.48-1.88 0-3.25-1.38-3.25-3.28 0-2.02 1.52-3.47 3.66-3.47 1.44 0 2.58.68 3.25 1.95.46.81.7 1.87.7 3.12zm-5.88-1.65c0 1.13.81 1.93 1.94 1.93s1.95-.81 1.95-1.91-.85-1.97-1.95-1.97a1.91 1.91 0 0 0-1.95 1.94zm8.7.53c.65 0 1.09-.46 1.09-1.07s-.44-1.08-1.09-1.08a1.04 1.04 0 0 0-1.09 1.08c0 .62.45 1.07 1.09 1.07zm1.09 4.05a1.04 1.04 0 0 1-1.09 1.08 1.04 1.04 0 0 1-1.09-1.08c0-.62.45-1.08 1.09-1.08s1.09.46 1.09 1.08zm8.22 2.13v-1.84h1.33v-1.49h-1.33V21.4h-2.49l-4.02 6.45v1.57h4.82v1.84h1.69zm-4.87-3.29h3.21v-5.14h-.1l-3.12 5.04v.1zm11.89 3.29v-9.86h-1.76l-2.58 1.8v1.7l2.45-1.72h.12v8.09h1.76z"
			/>
		</g>
	</Icon>
);
