import { Switch } from '@pushpay/inputs';
import type { SwitchComponentProps } from '@pushpay/inputs/lib/types/switch';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	switch: {},
	switchDisabled: {
		backgroundColor: theme.colors['grey-400'],
		border: `2px solid ${theme.colors['grey-400']}`,
	},
	thumbDisabled: {
		backgroundColor: theme.colors['grey-200'],
		color: theme.colors['grey-400'],
	},
}));

// TODO: [CAPPS-12469] Move the disabled style changes to component library
export const SwitchWithDisabledStyle = ({
	children,
	disabled,
	classes: classesProp,
	...props
}: SwitchComponentProps) => {
	const classes = useStyles(classesProp);

	return (
		<Switch
			classes={{
				...classes,
				switch: clsx(classes.switch, disabled && classes.switchDisabled),
				thumb: clsx(disabled && classes.thumbDisabled),
			}}
			disabled={disabled}
			{...props}
		>
			{children}
		</Switch>
	);
};
