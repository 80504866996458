import { InfoIcon } from '@pushpay/iconography';
import type { SwitchComponentProps } from '@pushpay/inputs/lib/types/switch';
import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';

import { SwitchWithDisabledStyle } from './SwitchWithDisabledStyle';

type SwitchWithTooltipProps = {
	tooltipContent: string;
} & SwitchComponentProps;

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		padding: '10px',
	},
	wrapper: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		font: theme.typography['text-4'],
		color: theme.colors['text-default'],
		marginRight: multiply(theme.SPACING.XSMALL, 1.5),
	},
	icon: {
		height: '14px',
		width: '14px',
		marginLeft: theme.SPACING.XXSMALL,
	},
	switch: {
		marginLeft: theme.SPACING.SMALL,
		minWidth: '48px',
	},
}));

export const SwitchWithTooltip = ({
	children,
	disabled,
	classes: classesProp,
	'data-pp-at-target': targetId,
	tooltipContent,
	...props
}: SwitchWithTooltipProps) => {
	const classes = useStyles(classesProp);

	return (
		<SwitchWithDisabledStyle classes={classes} data-pp-at-target={targetId} {...props} disabled={disabled}>
			<Tooltip
				content={tooltipContent}
				data-pp-at-target={`${targetId}-toggle-tooltip`}
				panelSpace="XXSMALL"
				placement="top"
			>
				<InfoIcon className={classes.icon} data-pp-at-target={`${targetId}-tip-icon`} />
			</Tooltip>
			{children}
		</SwitchWithDisabledStyle>
	);
};
