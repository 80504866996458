import { ImageFormatUrls } from '@src/graphql/generated';

// function placeholder
export function noop(): any {
	return () => {};
}

export function snakeToKebabCase(str: string) {
	return str.replace(/_/g, '-').toLowerCase();
}

export function unescape(str: string) {
	const htmlEscapes: { [key: string]: string } = {
		'&amp;': '&',
		'&lt;': '<',
		'&gt;': '>',
		'&quot;': '"',
		'&#39;': "'",
		'&#96;': '`',
	};

	const reEscapedHtml = /&(?:amp|lt|gt|quot|#39|#96);/g;

	return str.replace(reEscapedHtml, match => htmlEscapes[match]);
}

export function getImageMap(urls?: ImageFormatUrls | null) {
	if (!urls) {
		return null;
	}

	return {
		sqr: urls.square,
		og: urls.original,
		lmw: urls.landscape,
		nat: urls.panorama,
		dhs: urls.dynamicHomeScreen,
	};
}

type Comparator<T> = (a: T, b: T) => number;

export function orderBy<T extends Record<string, any>>(array: T[], keys: string[], orders: ('asc' | 'desc')[]): T[] {
	const directions = orders.map(order => (order === 'desc' ? -1 : 1));

	const compareValues: Comparator<T> = (a, b) => {
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const direction = directions[i];

			const valueA = a[key];
			const valueB = b[key];

			if (valueA < valueB) {
				return -1 * direction;
			}
			if (valueA > valueB) {
				return 1 * direction;
			}
		}

		return 0;
	};

	return array.slice().sort(compareValues);
}

type Grouping<T> = { [key: string]: T[] };

export function groupBy<T>(array: T[], iteratee: (item: T) => string): Grouping<T> {
	return array.reduce<Grouping<T>>((result, item) => {
		const key = iteratee(item);
		const group = result[key];
		const newArray = group ? [...group, item] : [item];

		return {
			...result,
			[key]: newArray,
		};
	}, {});
}
