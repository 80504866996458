import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { Draggable } from '@src/components/draggable/types';

import { useDraggableDataProcessing } from './useDraggableDataProcessing';

type DraggablesDataContextValue = {
	draggables: Draggable[];
	drop: (name: string) => void;
};
const DraggablesDataContext = createContext<DraggablesDataContextValue | null>(null);

export const DraggableDataContextProvider = ({ children }: PropsWithChildren) => {
	const { generatedDraggables } = useDraggableDataProcessing();

	const [draggablesState, setDraggablesState] = useState(generatedDraggables);

	useEffect(() => {
		setDraggablesState(generatedDraggables);
	}, [generatedDraggables]);

	const createNewDraggable = (name: string) => {
		const droppedItemIndex = draggablesState.findIndex(item => item.name === name);
		const newState = [...draggablesState];
		newState.splice(droppedItemIndex, 1, {
			...draggablesState[droppedItemIndex],
			id: crypto.randomUUID(), // random Id to generate a new draggable instance.
		});
		setDraggablesState(newState);
	};

	return (
		<DraggablesDataContext.Provider value={{ draggables: draggablesState, drop: createNewDraggable }}>
			{children}
		</DraggablesDataContext.Provider>
	);
};

export function useDraggablesDataContext() {
	const context = useContext(DraggablesDataContext);
	if (!context) {
		throw new Error('useDraggablesDataContext must be used within a DraggablesDataContextProvider');
	}

	return context;
}
