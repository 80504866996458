import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px 0',
	},
	publishButton: {
		marginRight: `${theme.SPACING.MEDIUM}`,
	},
	publishIcon: {
		flexShrink: 0,
		marginRight: `${theme.SPACING.XSMALL}`,
	},
}));
