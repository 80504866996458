import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentPropsWithoutPpAtTarget } from '@pushpay/types';

const useStyles = createUseStyles((theme: Theme) => ({
	wrapper: {
		'& > *': {
			marginTop: theme.SPACING.SMALL,
		},
	},
}));

export type ContentWrapperProps = ComponentPropsWithoutPpAtTarget<unknown, typeof useStyles>;

export const ContentWrapper = ({ classes: classesProp, children }: ContentWrapperProps) => {
	const classes = useStyles(classesProp);

	return <div className={classes.wrapper}>{children}</div>;
};
