import { useCallback, useMemo } from 'react';

import {
	getContainerSettingsDraggables,
	getItemDraggableDefinitions,
	useDraggablesDefinition,
} from '@src/components/draggable';
import { Draggable, DraggableCategory } from '@src/components/draggable/types';
import { useFeature } from '@src/featureFlags';
import {
	Container,
	ContainerType,
	GetContainerChildrenQuery,
	GetContainerSettingsQuery,
	GetItemQuery,
	ItemType,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp } from '@src/myContext';
import { ContainerSettings } from '@src/pages/containerSettings/types';

import { ContentType, useContentStateContext } from '../contentContext';

export function useDraggableDataProcessing() {
	const {
		currentApp: { campuses, productVersion },
		getAppShellCapability,
	} = useMyApp();
	const hasCampus = campuses?.length > 0;
	const { translate } = useTranslation('appDesign');
	const isInAppCalendarEnabled = useFeature('InAppCalendar');
	const { type, contentData } = useContentStateContext();

	const { getContainerChildrenDraggables } = useDraggablesDefinition();

	const unsupportedDraggableTooltip: Record<DraggableCategory, string> = useMemo(
		() => ({
			ChMS: translate('draggables.disabledChMSDraggableTooltip'),
			Resi: translate('draggables.unsupportedResiDraggableTooltip'),
		}),
		[translate]
	);

	const disableUnsupportedDraggables = useCallback(
		(listOfDraggables: Draggable[]) =>
			listOfDraggables.map(draggable => {
				if (!draggable.category || !draggable.requiredShellCapability) {
					return draggable;
				}

				const isSupported = getAppShellCapability(draggable.requiredShellCapability)?.isSupported ?? false;

				return {
					...draggable,
					...(!isSupported && { disabled: true, tooltip: unsupportedDraggableTooltip[draggable.category] }),
					isSupported,
				};
			}) as Draggable[],
		[getAppShellCapability, unsupportedDraggableTooltip]
	);

	const getProcessedContainerSettingsDraggables = useCallback(
		(draggables: Draggable[], container: ContainerSettings | null) => {
			let containerSettingsDraggables = draggables;
			if (!hasCampus) {
				containerSettingsDraggables = containerSettingsDraggables.filter(
					draggable => draggable.name !== 'add_attribute_campus'
				);
			} else if (container?.campus) {
				containerSettingsDraggables = containerSettingsDraggables.map(draggable =>
					draggable.name === 'add_attribute_campus'
						? {
								...draggable,
								disabled: true,
								tooltip: translate('draggables.disabledCampusDraggableTooltip'),
						  }
						: draggable
				);
			}

			if (container?.feed) {
				containerSettingsDraggables = containerSettingsDraggables.map(draggable =>
					[
						'add_attribute_feed',
						'add_attribute_feed_vimeo',
						'add_attribute_feed_events',
						'add_attribute_feed_youtube',
					].includes(draggable.name)
						? {
								...draggable,
								disabled: true,
								tooltip: translate('draggables.disabledFeedDraggableTooltip'),
						  }
						: draggable
				);
			}
			return containerSettingsDraggables;
		},
		[hasCampus, translate]
	);

	const getProcessedContainerChildrenDraggables = useCallback(
		(
			draggables: Draggable[],
			isFeedContainer: boolean,
			isEventsContainer: boolean,
			parentContainer: Pick<Container, 'type'> | null
		) => {
			let containerChildrenDraggables = draggables;

			const isParentEventsContainer = parentContainer?.type === ContainerType.Events;

			if (isInAppCalendarEnabled && isEventsContainer && isParentEventsContainer) {
				containerChildrenDraggables = containerChildrenDraggables.map(draggable =>
					draggable.type === 'container'
						? {
								...draggable,
								disabled: true,
								tooltip: translate('draggables.disabledEventContainerDraggableTooltip'),
						  }
						: draggable
				);
			}
			if (isFeedContainer) {
				if (isInAppCalendarEnabled) {
					const isDisabled = (draggable: Draggable) => draggable.type === 'item' && !isEventsContainer;
					containerChildrenDraggables = containerChildrenDraggables.map(draggable =>
						isDisabled(draggable)
							? {
									...draggable,
									disabled: true,
									tooltip: translate('draggables.disabledItemDraggableTooltip'),
							  }
							: draggable
					);
				} else {
					containerChildrenDraggables = containerChildrenDraggables.map(draggable =>
						draggable.type === 'item'
							? {
									...draggable,
									disabled: true,
									tooltip: translate('draggables.disabledItemDraggableTooltip'),
							  }
							: draggable
					);
				}
			}
			if (!hasCampus) {
				containerChildrenDraggables = containerChildrenDraggables.filter(
					draggable => draggable.name !== 'add_container_select_campus'
				);
			}
			return containerChildrenDraggables;
		},
		[hasCampus, isInAppCalendarEnabled, translate]
	);

	const getProcessedItemSettingsDraggables = useCallback(
		(draggables: Draggable[], isFeedItem: boolean, isManualEvent: boolean) => {
			let itemDraggables = draggables;
			if (isFeedItem) {
				if (isInAppCalendarEnabled) {
					const isDisabled = (draggable: Draggable) => draggable.type === 'property' && !isManualEvent;
					itemDraggables = itemDraggables.map(draggable =>
						isDisabled(draggable)
							? {
									...draggable,
									disabled: true,
									tooltip: translate('draggables.disabledPropertyDraggableTooltip'),
							  }
							: draggable
					);
				} else {
					itemDraggables = itemDraggables.map(draggable =>
						draggable.type === 'property'
							? {
									...draggable,
									disabled: true,
									tooltip: translate('draggables.disabledPropertyDraggableTooltip'),
							  }
							: draggable
					);
				}
			}
			return itemDraggables;
		},
		[isInAppCalendarEnabled, translate]
	);

	const generatedDraggables = useMemo(() => {
		let draggables: Draggable[];

		switch (type) {
			case ContentType.CONTAINER_SETTING: {
				const container = (contentData as GetContainerSettingsQuery)?.organization?.application?.container;
				const containerSettingsDraggables = getContainerSettingsDraggables({
					type: container?.type,
					productVersion,
					isInAppCalendarEnabled,
				});

				draggables = getProcessedContainerSettingsDraggables(containerSettingsDraggables, container);

				break;
			}
			case ContentType.CONTAINER_CHILDREN: {
				const container = (contentData as GetContainerChildrenQuery)?.organization?.application?.container;
				const containerChildrenDraggables = getContainerChildrenDraggables(container?.type as ContainerType);

				const isFeedContainer = !!container?.feed?.id;
				const isEventsContainer = container?.type === ContainerType.Events;

				const parentContainer = container?.parentContainer || null;

				draggables = getProcessedContainerChildrenDraggables(
					containerChildrenDraggables,
					isFeedContainer,
					isEventsContainer,
					parentContainer
				);

				break;
			}

			case ContentType.ITEM: {
				const container = (contentData as GetItemQuery)?.organization?.application?.container;
				const isFeedItem = !!container?.feed;
				const item = container?.item;
				const itemDraggables = getItemDraggableDefinitions({ type: item?.type, productVersion });
				const isManualEvent = item?.type === ItemType.Event && !item?.feedId;

				draggables = getProcessedItemSettingsDraggables(itemDraggables, isFeedItem, isManualEvent);

				break;
			}
			case ContentType.ROOT: {
				draggables = getContainerChildrenDraggables(ContainerType.Root);
				break;
			}
			case ContentType.DHS:
			default:
				draggables = [];
				break;
		}

		draggables.sort((a, b) => {
			if (a.type === 'item' && b.type === 'container') {
				return 1;
			}
			if (a.type === 'container' && b.type === 'item') {
				return -1;
			}
			return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
		});

		return disableUnsupportedDraggables(draggables);
	}, [
		type,
		disableUnsupportedDraggables,
		contentData,
		productVersion,
		isInAppCalendarEnabled,
		getProcessedContainerSettingsDraggables,
		getContainerChildrenDraggables,
		getProcessedContainerChildrenDraggables,
		getProcessedItemSettingsDraggables,
	]);

	return { generatedDraggables };
}
