import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { ItemProperty } from '@src/graphql/generated';

type PropertyListContext = {
	properties: ItemProperty[];
	itemId: string;
	disabledForFeedItem?: boolean;
	isFormDirty?: boolean;
	isEventItem?: boolean;
};

const PropertyListContext = createContext<PropertyListContext | null>(null);
PropertyListContext.displayName = 'PropertyListContext';

export function PropertyListContextProvider({
	children,
	properties,
	itemId,
	disabledForFeedItem,
	isFormDirty,
	isEventItem,
}: PropsWithChildren<PropertyListContext>) {
	const propertyListContextValue: PropertyListContext = useMemo<PropertyListContext>(
		() => ({
			properties,
			itemId,
			disabledForFeedItem,
			isFormDirty,
			isEventItem,
		}),
		[disabledForFeedItem, isEventItem, isFormDirty, itemId, properties]
	);

	return <PropertyListContext.Provider value={propertyListContextValue}>{children}</PropertyListContext.Provider>;
}

export function usePropertyListContext(): PropertyListContext {
	const context = useContext(PropertyListContext);
	if (!context) {
		throw new Error('usePropertyListContext must be used within a PropertyListContextProvider');
	}
	return context;
}
