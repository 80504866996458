const ALLOWED_SCHEMAS = '(?:https?|mailto|ftp|tel|file|sms)';
const VALID_SCHEMA_REGEX = new RegExp(`^${ALLOWED_SCHEMAS}`, 'i');
const SAFE_URL_REG_EXP = new RegExp(`^(?:${ALLOWED_SCHEMAS}:|[^&:/?#]+(?:[/?#]|$))`, 'gi');
const URL_REG_EXP = new RegExp(
	`^(?:(?:(?:https?|ftp):)?\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff]\\.)+(?:[a-z\\u00a1-\\uffff]{2,}\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$`,
	'i'
);
export const HTTPS_PLACEHOLDER = 'https://';

export function sanitizeUrl(rawUrl: string): string {
	const url = String(rawUrl).trim();

	if (url.match(SAFE_URL_REG_EXP)) {
		const startsWithValidSchema = url.match(VALID_SCHEMA_REGEX);

		return startsWithValidSchema ? url : `${HTTPS_PLACEHOLDER}${url}`;
	}

	return HTTPS_PLACEHOLDER;
}

export function validateUrl(url: string): boolean {
	return url === HTTPS_PLACEHOLDER || URL_REG_EXP.test(url);
}
