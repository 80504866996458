/* eslint-disable camelcase */
import { useDraggable } from '@dnd-kit/core';

import { ComponentProps } from '@pushpay/types';

import { useCopyAndMoveDispatchContext } from '@src/context';

import { DraggableComponent, DraggableComponentProps } from './DraggableComponent';
import { Draggable as TDraggable } from './types';

export type DraggableProps = ComponentProps<
	{
		name: string;
	} & Omit<DraggableComponentProps, 'dragging' | 'attributes' | 'listeners'> &
		Pick<TDraggable, 'name' | 'actions' | 'id' | 'tooltip' | 'category'>,
	undefined
>;

export const Draggable = ({ id, name, disabled, actions, tooltip, ...props }: DraggableProps) => {
	const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
		id: id || name,
		disabled,
		data: {
			name,
		},
	});
	const { getItemCount } = useCopyAndMoveDispatchContext();
	const hasSelectedItems = getItemCount() > 0;

	return (
		<DraggableComponent
			ref={setNodeRef}
			attributes={attributes}
			disabled={disabled || hasSelectedItems}
			dragging={isDragging}
			listeners={listeners}
			tooltip={tooltip}
			{...props}
		/>
	);
};
