import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
	REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $isParentElementRTL, $setBlocksType } from '@lexical/selection';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import type { LexicalEditor } from 'lexical';
import {
	$createParagraphNode,
	$getSelection,
	$isRangeSelection,
	$isRootOrShadowRoot,
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	COMMAND_PRIORITY_CRITICAL,
	FORMAT_TEXT_COMMAND,
	INDENT_CONTENT_COMMAND,
	OUTDENT_CONTENT_COMMAND,
	REDO_COMMAND,
	SELECTION_CHANGE_COMMAND,
	UNDO_COMMAND,
} from 'lexical';

import { Button } from '@pushpay/button';
import { clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { Icon } from '@src/components/icon';
import { $isBlankifyNode } from '@src/components/textEditor/nodes';
import { useTranslation, Translate } from '@src/i18n';

import { INSERT_BLANKIFY_COMMAND, REMOVE_BLANKIFY_COMMAND } from '../BlankifyPlugin';
import { HTTPS_PLACEHOLDER, sanitizeUrl, getSelectedNode } from '../utils';
import { DropDown, DropDownItem } from './DropDown';
import { useStyles } from './ToolbarPluginStyles';

type ClassesProp = ReturnType<typeof useStyles>;

enum BlockTypes {
	BULLET = 'bullet',
	H1 = 'h1',
	H2 = 'h2',
	H3 = 'h3',
	NUMBER = 'number',
	PARAGRAPH = 'paragraph',
	QUOTE = 'quote',
}

type BlockFormatDropDown = {
	editor: LexicalEditor;
	blockType: BlockTypes;
	classes: ClassesProp;
	translate: Translate<'appDesign'>;
	disabled?: boolean;
};

const blockTypes: BlockTypes[] = Object.values(BlockTypes);

type ToolbarPluginProps = ComponentProps<
	{
		includeBlankifyOption?: boolean;
		setIsLinkEditMode: Dispatch<SetStateAction<boolean>>;
	},
	undefined
>;

function dropDownClasses({ isActive, classes }: { isActive: boolean; classes: ClassesProp }) {
	return clsx(classes.item, isActive && classes.dropDownActive, isActive && classes.dropdownItemActive);
}

function isBlockType(type: string): type is BlockTypes {
	return blockTypes.includes(type as BlockTypes);
}

function BlockFormatDropDown({
	editor,
	blockType,
	classes,
	translate,
	disabled = false,
}: BlockFormatDropDown): React.JSX.Element {
	const formatParagraph = () => {
		editor.update(() => {
			const selection = $getSelection();
			if ($isRangeSelection(selection)) {
				$setBlocksType(selection, () => $createParagraphNode());
			}
		});
	};

	const formatHeading = (headingSize: HeadingTagType) => {
		if (blockType !== headingSize) {
			editor.update(() => {
				const selection = $getSelection();
				if ($isRangeSelection(selection)) {
					$setBlocksType(selection, () => $createHeadingNode(headingSize));
				}
			});
		}
	};

	const formatBulletList = () => {
		if (blockType !== BlockTypes.BULLET) {
			editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
		} else {
			editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
		}
	};

	const formatNumberedList = () => {
		if (blockType !== BlockTypes.NUMBER) {
			editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
		} else {
			editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
		}
	};

	const formatQuote = () => {
		if (blockType !== BlockTypes.QUOTE) {
			editor.update(() => {
				const selection = $getSelection();
				if ($isRangeSelection(selection)) {
					$setBlocksType(selection, () => $createQuoteNode());
				}
			});
		}
	};

	return (
		<DropDown
			buttonAriaLabel={translate('richTextEditor.formattingOptions')}
			buttonClassName={classes.toolbarItem}
			buttonIconClassName={clsx(classes.icon, classes.blockType, isBlockType(blockType) && classes[blockType])}
			buttonLabel={translate(`richTextEditor.${blockType}`)}
			disabled={disabled}
		>
			<DropDownItem
				className={dropDownClasses({ isActive: blockType === BlockTypes.PARAGRAPH, classes })}
				onClick={formatParagraph}
			>
				<i className={clsx(classes.icon, classes.paragraph)} />
				<span className={classes.text}>{translate('richTextEditor.paragraph')}</span>
			</DropDownItem>
			<DropDownItem
				className={dropDownClasses({ isActive: blockType === BlockTypes.H1, classes })}
				onClick={() => formatHeading(BlockTypes.H1)}
			>
				<i className={clsx(classes.icon, classes.h1)} />
				<span className={classes.text}>{translate('richTextEditor.h1')}</span>
			</DropDownItem>
			<DropDownItem
				className={dropDownClasses({ isActive: blockType === BlockTypes.H2, classes })}
				onClick={() => formatHeading(BlockTypes.H2)}
			>
				<i className={clsx(classes.icon, classes.h2)} />
				<span className={classes.text}>{translate('richTextEditor.h2')}</span>
			</DropDownItem>
			<DropDownItem
				className={dropDownClasses({ isActive: blockType === BlockTypes.H3, classes })}
				onClick={() => formatHeading(BlockTypes.H3)}
			>
				<i className={clsx(classes.icon, classes.h3)} />
				<span className={classes.text}>{translate('richTextEditor.h3')}</span>
			</DropDownItem>
			<DropDownItem
				className={dropDownClasses({ isActive: blockType === BlockTypes.BULLET, classes })}
				onClick={formatBulletList}
			>
				<i className={clsx(classes.icon, classes.bullet)} />
				<span className={classes.text}>{translate('richTextEditor.bullet')}</span>
			</DropDownItem>
			<DropDownItem
				className={dropDownClasses({ isActive: blockType === BlockTypes.NUMBER, classes })}
				onClick={formatNumberedList}
			>
				<i className={clsx(classes.icon, classes.number)} />
				<span className={classes.text}>{translate('richTextEditor.number')}</span>
			</DropDownItem>
			<DropDownItem
				className={dropDownClasses({ isActive: blockType === BlockTypes.QUOTE, classes })}
				onClick={formatQuote}
			>
				<i className={clsx(classes.icon, classes.quote)} />
				<span className={classes.text}>{translate('richTextEditor.quote')}</span>
			</DropDownItem>
		</DropDown>
	);
}

function Divider({ classes }: { classes: ClassesProp }): React.JSX.Element {
	return <div className={classes.divider} />;
}

export function ToolbarPlugin({ includeBlankifyOption, setIsLinkEditMode }: ToolbarPluginProps): React.JSX.Element {
	const [editor] = useLexicalComposerContext();
	const [activeEditor, setActiveEditor] = useState(editor);
	const [blockType, setBlockType] = useState<BlockTypes>(BlockTypes.PARAGRAPH);
	const [isLink, setIsLink] = useState(false);
	const [isBlankify, setIsBlankify] = useState(false);
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);
	const [isStrikethrough, setIsStrikethrough] = useState(false);
	const [canUndo, setCanUndo] = useState(false);
	const [canRedo, setCanRedo] = useState(false);
	const [isRTL, setIsRTL] = useState(false);

	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');

	const $updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			let element =
				anchorNode.getKey() === 'root'
					? anchorNode
					: $findMatchingParent(anchorNode, e => {
							const parent = e.getParent();
							return parent !== null && $isRootOrShadowRoot(parent);
					  });

			if (element === null) {
				element = anchorNode.getTopLevelElementOrThrow();
			}

			const elementKey = element.getKey();
			const elementDOM = activeEditor.getElementByKey(elementKey);

			// Update text format
			setIsBold(selection.hasFormat('bold'));
			setIsItalic(selection.hasFormat('italic'));
			setIsUnderline(selection.hasFormat('underline'));
			setIsStrikethrough(selection.hasFormat('strikethrough'));
			setIsRTL($isParentElementRTL(selection));

			// Update links
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			if ($isLinkNode(parent) || $isLinkNode(node)) {
				setIsLink(true);
			} else {
				setIsLink(false);
			}

			// Update Blankify
			if ($isBlankifyNode(parent) || $isBlankifyNode(node)) {
				setIsBlankify(true);
			} else {
				setIsBlankify(false);
			}

			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
					const type = parentList ? parentList.getListType() : element.getListType();
					if (isBlockType(type)) {
						setBlockType(type);
					}
				} else {
					const type = $isHeadingNode(element) ? element.getTag() : element.getType();
					if (blockTypes.includes(type as BlockTypes)) {
						setBlockType(type as BlockTypes);
					}
				}
			}
		}
	}, [activeEditor]);

	useEffect(
		() =>
			mergeRegister(
				activeEditor.registerUpdateListener(({ editorState }) => {
					editorState.read(() => {
						$updateToolbar();
					});
				}),
				activeEditor.registerCommand<boolean>(
					CAN_UNDO_COMMAND,
					payload => {
						setCanUndo(payload);
						return false;
					},
					COMMAND_PRIORITY_CRITICAL
				),
				activeEditor.registerCommand<boolean>(
					CAN_REDO_COMMAND,
					payload => {
						setCanRedo(payload);
						return false;
					},
					COMMAND_PRIORITY_CRITICAL
				),
				activeEditor.registerCommand(
					SELECTION_CHANGE_COMMAND,
					(_payload, newEditor) => {
						$updateToolbar();
						setActiveEditor(newEditor);
						return false;
					},
					COMMAND_PRIORITY_CRITICAL
				)
			),
		[$updateToolbar, activeEditor]
	);

	const insertLink = useCallback(() => {
		if (!isLink) {
			activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(HTTPS_PLACEHOLDER));
			setIsLinkEditMode(true);
		} else {
			activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
			setIsLinkEditMode(false);
		}
	}, [activeEditor, isLink, setIsLinkEditMode]);

	const insertBlankify = useCallback(() => {
		if (!isBlankify) {
			activeEditor.dispatchCommand(INSERT_BLANKIFY_COMMAND, null);
		} else {
			activeEditor.dispatchCommand(REMOVE_BLANKIFY_COMMAND, null);
		}
	}, [activeEditor, isBlankify]);

	return (
		<div className={classes.toolbar}>
			<Button
				aria-label={translate('richTextEditor.undo')}
				className={clsx(classes.toolbarItem, classes.spaced)}
				disabled={!canUndo}
				title={translate('richTextEditor.undo')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
				}}
			>
				<i className={clsx(classes.format, classes.undo)} />
			</Button>
			<Button
				aria-label={translate('richTextEditor.redo')}
				className={classes.toolbarItem}
				disabled={!canRedo}
				title={translate('richTextEditor.redo')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(REDO_COMMAND, undefined);
				}}
			>
				<i className={clsx(classes.format, classes.redo)} />
			</Button>
			<Divider classes={classes} />
			<Button
				aria-label={translate('richTextEditor.boldAriaLabel')}
				className={clsx(
					classes.toolbarItem,
					classes.spaced,
					isBold ? classes.toolbarItemActive : classes.toolbarItemInactive
				)}
				title={translate('richTextEditor.bold')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
				}}
			>
				<i className={clsx(classes.format, classes.bold)} />
			</Button>
			<Button
				aria-label={translate('richTextEditor.italicAriaLabel')}
				className={clsx(
					classes.toolbarItem,
					classes.spaced,
					isItalic ? classes.toolbarItemActive : classes.toolbarItemInactive
				)}
				title={translate('richTextEditor.italic')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
				}}
			>
				<i className={clsx(classes.format, classes.italic)} />
			</Button>
			<Button
				aria-label={translate('richTextEditor.underlineAriaLabel')}
				className={clsx(
					classes.toolbarItem,
					classes.spaced,
					isUnderline ? classes.toolbarItemActive : classes.toolbarItemInactive
				)}
				title={translate('richTextEditor.underline')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
				}}
			>
				<i className={clsx(classes.format, classes.underline)} />
			</Button>
			<Button
				aria-label={translate('richTextEditor.strikethroughAriaLabel')}
				className={clsx(
					classes.toolbarItem,
					classes.spaced,
					isStrikethrough ? classes.toolbarItemActive : classes.toolbarItemInactive
				)}
				title={translate('richTextEditor.strikethrough')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
				}}
			>
				<i className={clsx(classes.format, classes.strikethrough)} />
			</Button>
			<Divider classes={classes} />
			<BlockFormatDropDown blockType={blockType} classes={classes} editor={activeEditor} translate={translate} />
			<Divider classes={classes} />
			<Button
				aria-label={translate('richTextEditor.outdent')}
				className={clsx(classes.toolbarItem, classes.spaced)}
				title={translate('richTextEditor.outdent')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
				}}
			>
				<i className={clsx(classes.icon, isRTL ? classes.indent : classes.outdent)} />
			</Button>
			<Button
				aria-label={translate('richTextEditor.indent')}
				className={clsx(classes.toolbarItem, classes.spaced)}
				title={translate('richTextEditor.indent')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
				}}
			>
				<i className={clsx(classes.icon, isRTL ? classes.outdent : classes.indent)} />
			</Button>
			<Button
				aria-label={translate('richTextEditor.divider')}
				className={clsx(classes.toolbarItem, classes.spaced)}
				title={translate('richTextEditor.divider')}
				type="button"
				onClick={() => {
					activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
				}}
			>
				<i className={clsx(classes.format, classes.horizontalRule)} />
			</Button>
			<Button
				aria-label={translate('richTextEditor.insertLink')}
				className={clsx(
					classes.toolbarItem,
					classes.spaced,
					isLink ? classes.toolbarItemActive : classes.toolbarItemInactive
				)}
				title={translate('richTextEditor.insertLink')}
				type="button"
				onClick={insertLink}
			>
				<i className={clsx(classes.format, classes.link)} />
			</Button>
			{includeBlankifyOption && (
				<Button
					aria-label={translate('richTextEditor.blankify')}
					className={clsx(
						classes.toolbarItem,
						classes.spaced,
						isBlankify ? classes.toolbarItemActive : classes.toolbarItemInactive
					)}
					title={translate('richTextEditor.blankify')}
					type="button"
					onClick={insertBlankify}
				>
					<Icon classes={{ root: classes.blankifyIcon }} name="custom-blankify" />
				</Button>
			)}
		</div>
	);
}
