import { useSearchParams, useNavigate } from 'react-router-dom';

import { Button } from '@pushpay/button';
import { useTheme } from '@pushpay/theming';

import { SelectedContainer } from '@src/components/modals/DestinationModal';
import { useShowNotification } from '@src/components/notification';
import { useScrollToLastPagePositionContext } from '@src/context/scrollToLastPagePositionContext';
import {
	GetContainerChildrenDocument,
	GetContainerChildrenQuery,
	MoveChildInput,
	MoveChildSuccess,
	useMoveChildrenMutation,
} from '@src/graphql/generated';
import { Trans, useTranslation } from '@src/i18n';
import { combinePaths, generatePath, ROUTE_PATHS } from '@src/router';

const getContainerChildrenPath = (organizationKey: string, containerId: string, isMovingToRoot: boolean) =>
	isMovingToRoot
		? generatePath(combinePaths(ROUTE_PATHS.ORGANIZATION_ROOT, ROUTE_PATHS.APP), {
				organizationKey,
		  })
		: generatePath(combinePaths(ROUTE_PATHS.ORGANIZATION_ROOT, ROUTE_PATHS.APP, ROUTE_PATHS.CONTAINER_CHILDREN), {
				organizationKey,
				containerId,
		  });

export function useOptimizedMoveChildrenMutation({
	organizationKey,
	platformCampusKey,
	applicationId,
}: {
	organizationKey: string;
	platformCampusKey?: string | null;
	applicationId: string;
}) {
	const [mutate] = useMoveChildrenMutation();
	const { scrollToTop } = useScrollToLastPagePositionContext();

	const { translate: translateCommon } = useTranslation('common');
	const { translate: translateAppDesign } = useTranslation('appDesign');
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const showNotification = useShowNotification();
	const theme = useTheme();

	const moveChildren = ({
		parentContainerId,
		newParentContainer,
		children,
	}: {
		parentContainerId: string;
		newParentContainer: SelectedContainer;
		children: MoveChildInput[];
	}) => {
		const { id: newParentContainerId, isRootContainer: isMovingToRoot } = newParentContainer;
		const newPath = getContainerChildrenPath(organizationKey, newParentContainerId, isMovingToRoot);

		mutate({
			variables: {
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
					parentContainerId,
					newParentContainerId,
					moveChildren: children,
				},
			},
			update(cache, { data }) {
				if (data?.moveChildren.__typename !== 'MoveChildrenOutput') {
					let errorContent;

					if (data?.moveChildren.__typename === 'RootContainerError') {
						errorContent = translateCommon('copyAndMove.errors.moveRootContainer');
					} else if (data?.moveChildren.__typename === 'InvalidRequestError') {
						errorContent =
							children.length > 1
								? translateCommon('copyAndMove.errors.moveChildren')
								: translateCommon('copyAndMove.errors.moveChild');
					}

					if (errorContent) {
						showNotification({
							isOpen: true,
							type: 'error',
							title: translateCommon('errors.title.error'),
							content: errorContent,
						});
					}
				}

				if (data?.moveChildren.__typename === 'MoveChildrenOutput') {
					const moveChildResults = data?.moveChildren?.moveChildResults;
					const movedChildrenIds = moveChildResults
						.filter(moveChildResult => moveChildResult.__typename === 'MoveChildSuccess')
						.map(moveChildSuccess => (moveChildSuccess as MoveChildSuccess).movedChild.id);

					const anyChildFailed =
						moveChildResults.filter(child => child.__typename === 'MoveChildError').length > 0;

					if (anyChildFailed) {
						showNotification({
							isOpen: true,
							type: 'error',
							title: translateCommon('errors.title.error'),
							content: translateCommon('copyAndMove.errors.moveChildren'),
						});
					}

					if (movedChildrenIds.length) {
						// Update the source container cache to remove children that have been moved.
						cache.modify({
							id: cache.identify({
								__typename: 'Container',
								id: parentContainerId,
							}),
							fields: {
								children(existingChildren, { readField }) {
									const updatedNodes = existingChildren.nodes.filter(
										(nodeRef: any) => !movedChildrenIds.includes(readField('id', nodeRef) ?? '')
									);

									const updatedChildren = {
										...existingChildren,
										nodes: updatedNodes,
										paging: {
											...existingChildren.paging,
											totalItemCount: updatedNodes.length,
										},
									};

									return updatedChildren;
								},
							},
						});

						const newParentContainerChildren = cache.readQuery<GetContainerChildrenQuery>({
							query: GetContainerChildrenDocument,
							variables: {
								organizationKey,
								applicationId,
								platformCampusKey,
								containerId: newParentContainerId,
							},
						});

						if (newParentContainerChildren) {
							cache.writeQuery({
								query: GetContainerChildrenDocument,
								variables: {
									organizationKey,
									applicationId,
									platformCampusKey,
									containerId: newParentContainerId,
								},
								data: {
									organization: {
										...newParentContainerChildren?.organization,
										application: {
											...newParentContainerChildren?.organization?.application,
											container: {
												...newParentContainerChildren?.organization?.application?.container,
												children: {
													...newParentContainerChildren?.organization?.application?.container
														?.children,
													nodes:
														data?.moveChildren?.newParentContainer?.children?.nodes ||
														newParentContainerChildren?.organization?.application?.container
															?.children?.nodes,
												},
											},
										},
									},
								},
							});
						}

						navigate(
							{ pathname: newPath, search: searchParams.toString() },
							{
								replace: false,
								state: { recentlyMovedChildIds: movedChildrenIds },
							}
						);
					}
				}
			},
			onCompleted: async () => {
				scrollToTop();

				showNotification({
					title: translateAppDesign('moveSuccessBanner.title'),
					isOpen: true,
					content: (
						<Trans i18nKey="moveSuccessBanner.text" namespace="appDesign">
							Item(s)/container(s) have been moved!
							<Button
								style={{ color: theme.colors['palette-blue'] }}
								type="button"
								onClick={() => {
									navigate(-1);
								}}
							>
								Return to previous page
							</Button>
						</Trans>
					),
				});
			},
		});
	};
	return { moveChildren };
}
