import { ReactNode } from 'react';

import { Button } from '@pushpay/button';
import { ButtonComponentProps } from '@pushpay/button/lib/types/button';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

const buttonSizes = {
	small: '32px',
	medium: '40px',
	large: '48px',
};

type StyleParams = {
	size?: keyof typeof buttonSizes;
};

export const useStyles = createUseStyles((theme: Theme) => ({
	button: {
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
	},
	noChildren: ({ size = 'small' }: StyleParams) => ({
		height: buttonSizes[size],
		width: buttonSizes[size],
		borderRadius: theme.SHAPE.CIRCULAR,

		'&:hover': {
			backgroundColor: theme.colors['grey-200'],
		},
	}),
}));

type IconButtonProps = {
	icon: ReactNode;
} & StyleParams &
	Omit<ButtonComponentProps, 'type'>;

export const IconButton = ({
	onClick,
	icon,
	classes: classesProp,
	children,
	size,
	'data-pp-at-target': targetId,
	...rest
}: ComponentProps<IconButtonProps, typeof useStyles>) => {
	const classes = useStyles(classesProp, { size });

	return (
		<Button
			className={clsx(classes.button, !children && classes.noChildren)}
			data-pp-at-target={targetId}
			type="button"
			onClick={onClick}
			{...rest}
		>
			{children}
			{icon}
		</Button>
	);
};
