import React, { useCallback, useRef } from 'react';

import { Button } from '@pushpay/button';
import Expand, { ExpandStatus, useExpand } from '@pushpay/expand';
import { UploadIcon } from '@pushpay/iconography';
import Notification from '@pushpay/notification';
import { clsx } from '@pushpay/styles';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { ExpanderButton } from '@src/components/buttons';
import { convertBytesToMB } from '@src/components/imageUploader/components/shared';
import { useShowNotification } from '@src/components/notification';
import { useTranslation } from '@src/i18n';

import { acceptedFileTypes } from '../shared/utils';
import { useStyles } from './fileDropAreaStyles';
import { useImageDrag } from './useImageDrag';

type FileDropAreaProps = {
	inputExpandStatus: ExpandStatus;
	urlInput: React.JSX.Element;
	maxFileSize: number;
	disableUpload?: boolean;
	carousel?: boolean;
	imageLimitReached?: boolean;
	onFileSelect: (file: File) => void;
};

export const FileDropArea = ({
	'data-pp-at-target': targetId,
	inputExpandStatus: inputExpandStatusProp,
	urlInput,
	maxFileSize,
	disableUpload,
	carousel,
	imageLimitReached = false,
	onFileSelect,
}: ComponentProps<FileDropAreaProps, undefined>) => {
	const classes = useStyles(undefined);
	const dropAreaRef = useRef<HTMLDivElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const wrapperRef = useRef(null);
	const { translate } = useTranslation('appDesign');
	const { translate: translateCommon } = useTranslation('common');
	const showNotification = useShowNotification();
	const [inputExpandStatus, toggleInputExpand] = useExpand(inputExpandStatusProp);

	const acceptedFileTypesForInputTag = acceptedFileTypes.map(type => `.${type}`).join(',');

	const validateFile = useCallback(
		(file: File) => {
			if (file.size > maxFileSize) {
				showNotification({
					isOpen: true,
					type: 'error',
					title: translateCommon('errors.title.error'),
					content: translateCommon('errors.text.fileSizeError', {
						fileSize: `${convertBytesToMB(maxFileSize)} MB`,
					}),
				});

				return false;
			}

			const imageType = file.type.replace('image/', '');

			if (!acceptedFileTypes.includes(imageType)) {
				showNotification({
					isOpen: true,
					type: 'error',
					title: translateCommon('errors.title.error'),
					content: translateCommon('errors.text.fileTypeUnsupported', {
						acceptedFileTypes: acceptedFileTypes.join(', ').toUpperCase(),
					}),
				});

				return false;
			}

			return true;
		},
		[maxFileSize, showNotification, translateCommon]
	);

	const handleFileSelect = () => {
		if (!fileInputRef.current?.files || fileInputRef.current?.files.length === 0) return;

		const currentFile = fileInputRef.current?.files[0];
		const isImageFile = currentFile.type.indexOf('image') === 0;

		if (isImageFile && validateFile(currentFile)) {
			onFileSelect(currentFile);
		}

		fileInputRef.current.value = '';
	};

	const { isDragged } = useImageDrag(dropAreaRef, file => {
		if (disableUpload || !validateFile(file)) return;

		onFileSelect(file);
	});

	return (
		<div ref={wrapperRef} className={clsx(carousel && classes.wrapper)}>
			<div
				ref={dropAreaRef}
				className={clsx(classes.container, !disableUpload && isDragged && classes.containerDragged)}
				data-pp-at-target={targetId}
			>
				<UploadIcon className={classes.uploadIcon} />
				<span className={classes.title}>{translate('imageUploader.title')}</span>
				<span className={classes.subTitle}>{translate('imageUploader.subTitle')}</span>
				<Tooltip
					content={translate('imageUploader.buttonTooltip')}
					disabled={!imageLimitReached}
					placement="top"
				>
					<Button
						className={classes.chooseButton}
						disabled={disableUpload}
						displaySize="large"
						displayStyle="primary"
						type="button"
						onClick={() => {
							fileInputRef.current?.click();
						}}
					>
						{translate('imageUploader.button')}
					</Button>
				</Tooltip>
				<ExpanderButton
					expanded={inputExpandStatus === 'expanded'}
					isDisabled={disableUpload}
					toggle={toggleInputExpand}
				>
					{translate('imageUploader.subButton')}
				</ExpanderButton>
				<Expand.Panel classes={{ root: classes.imageUrlWrapper }} expandStatus={inputExpandStatus}>
					<div className={classes.imageUrl}>{urlInput}</div>
				</Expand.Panel>
				<input
					ref={fileInputRef}
					accept={acceptedFileTypesForInputTag}
					className={classes.fileInput}
					type="file"
					onChange={handleFileSelect}
				/>
			</div>
			{carousel && (
				<Notification
					classes={{ root: classes.notification, icon: classes.icon }}
					closeButtonAriaLabel=""
					title={translate('imageUploader.noteTitle')}
					type="error"
					wrapper={wrapperRef}
					isOpen
				>
					{translate('imageUploader.noteContent')}
				</Notification>
			)}
		</div>
	);
};
