import { createUseStyles, multiply } from '@pushpay/styles';
import { ChMSTheme, Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: '65px',
		width: '65px',
		cursor: 'grab',
		marginLeft: theme.SPACING.FIELD_PADDING_V,
		marginBottom: theme.SPACING.FIELD_PADDING_V,
		border: 'none',
		borderRadius: '3px',
		backgroundColor: theme.colors['grey-200'],
		outline: 'none',
		fontFamily: theme.typography.font.family,
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
	},
	iconWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	handle: {
		position: 'absolute',
		top: '27.5px',
		left: '2px',
		fontSize: '10px',
		color: theme.colors['grey-600'],
	},
	label: {
		margin: `6px 0 0`,
		fontSize: '11px',
		color: theme.colors['text-default'],
	},
	placeholder: {
		cursor: 'default',
		border: `${multiply(theme.SPACING.XXSMALL, 0.5)} dashed ${theme.colors['grey-500']}`,
		backgroundColor: theme.colors['common-white'],
	},
	placeholderLabel: {
		marginTop: 0,
		color: theme.colors['text-placeholder'],
	},
	overlay: {
		cursor: 'grabbing',
		backgroundColor: theme.colors['grey-200'],
		opacity: 0.85,
		boxShadow: theme.ELEVATION.EIGHT,
	},
	disabled: {
		opacity: 0.4,
		cursor: 'not-allowed',
	},
	attribute: {
		color: theme.colors['grey-900'],
	},
	property: {
		color: theme.colors['grey-700'],
	},
	ChMS: {
		color: ChMSTheme.colors['productBrand-500'],
	},
	Resi: {
		color: 'var(--color-resi)',
	},
	item: {
		color: theme.colors['blue-700'],
	},
	container: {
		color: theme.colors['productBrand-700'],
	},
	tooltipContent: {
		paddingLeft: theme.SPACING.XXSMALL,
	},
	icon: {
		width: theme.SPACING.MEDIUM,
		height: theme.SPACING.MEDIUM,
	},
}));
