import { FC, PropsWithChildren } from 'react';

import { DeleteIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { IconButton } from '@src/components/buttons';
import { DraggableIcon } from '@src/components/draggable';
import { ExpandableCard } from '@src/components/expandableCard';
import { Icon as IconComponent } from '@src/components/icon';
import { ScrollElementIntoView } from '@src/components/scrollElementIntoView';
import { useTranslation } from '@src/i18n';

const useStyles = createUseStyles((theme: Theme) => ({
	headerGroup: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginRight: theme.SPACING.XSMALL,
		},
	},
	label: {
		color: theme.colors['text-heading'],
		font: theme.typography['heading-4'],
	},
	button: {
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
	},
	icon: {
		color: theme.colors['grey-900'],
		fontSize: '20px',
	},
}));

type AttributeCardProps = {
	attributeType: string;
	icon: DraggableIcon;
	title: string;
	extraTitle?: React.ReactNode;
	onDelete?: () => void;
};

export const AttributeCard: FC<ComponentProps<PropsWithChildren<AttributeCardProps>, typeof undefined>> = ({
	attributeType,
	icon,
	title,
	children,
	extraTitle,
	onDelete = () => {},
	'data-pp-at-target': targetId,
}) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');

	return (
		<ScrollElementIntoView elementId={attributeType}>
			<ExpandableCard
				actions={
					<div className={classes.headerGroup}>
						<Tooltip content={translate('attribute.removeTooltip')} placement="top">
							<IconButton
								aria-label="delete"
								icon={<DeleteIcon classes={{ root: classes.icon }} />}
								onClick={onDelete}
							/>
						</Tooltip>
					</div>
				}
				data-pp-at-target={targetId}
				initialExpandStatus="preexpanded"
				title={
					<div className={classes.headerGroup}>
						<IconComponent classes={{ root: classes.icon }} name={icon} />
						<span className={classes.label}>{title}</span>
						{extraTitle}
					</div>
				}
				hasDivider
			>
				{children}
			</ExpandableCard>
		</ScrollElementIntoView>
	);
};
