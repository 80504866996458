import { BLANKIFY } from '@src/components/textEditor/nodes/BlankifyNode';

import { StyleParams, classNames } from './PushpayEditorThemeStyles';

type StyleDeclaration = Partial<CSSStyleDeclaration> & { [propName: string]: string | number };

const classNamesWhiteList = [BLANKIFY];

function setStyleAttributes(node: HTMLElement, styleParams: StyleParams) {
	const { classList } = node;

	classList.forEach(className => {
		if (className in classNames) {
			const classValue = classNames[className as keyof typeof classNames];
			const classNameEntries = Object.entries(
				typeof classValue === 'function' ? classValue(styleParams) : classValue
			);

			classNameEntries.forEach(([styleKey, styleValue]) => {
				// eslint-disable-next-line no-param-reassign
				(node.style as StyleDeclaration)[styleKey] = styleValue;
			});
		}
	});
}

function addInLineStyle(node: HTMLElement, styleParams: StyleParams) {
	const children = [...(node.children as any as Array<HTMLElement>)];

	children.forEach((child: HTMLElement) => {
		if (child.className && !classNamesWhiteList.includes(child.className)) {
			setStyleAttributes(child, styleParams);
			child.removeAttribute('class');
		}

		addInLineStyle(child, styleParams);
	});

	return node;
}

export function fromClassNamesToInlineStyles(rawHtmlString: string, styleParams: StyleParams) {
	const htmlString = rawHtmlString.trim();
	if (!htmlString) return '';

	const container = document.createElement('div');
	container.innerHTML = htmlString;

	return addInLineStyle(container, styleParams).innerHTML;
}
