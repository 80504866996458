import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	modal: {
		width: '836px',
	},
	cardBody: {
		padding: theme.SPACING.LARGE,
	},
	cardRoot: { border: 'none' },
	closeModalButton: {
		top: theme.SPACING.LARGE,
		right: theme.SPACING.MEDIUM,
	},
	heading: {
		color: theme.colors['text-heading'],
	},
	searchChildren: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},
	subtitle: {
		alignSelf: 'center',
	},
	bold: {
		fontWeight: theme.typography.font.weight.semiBold,
	},
	selectButton: {
		'&:disabled, &:disabled:hover': {
			color: theme.colors['grey-600'],
		},
	},
	backIcon: {
		height: '18px',
		width: '18px',
		marginRight: '11px',
		strokeWidth: 30,
		color: theme.colors['grey-900'],
	},
	title: {
		position: 'sticky',
		top: 0,
		backgroundColor: theme.colors['common-white'],
		borderBottom: `2px solid ${theme.colors['grey-400']}`,
		display: 'flex',
		alignItems: 'center',
		zIndex: 1,

		'& button, & h4': {
			fontSize: '18px',
			height: '40px',
			margin: '8px',
			paddingLeft: '27px',
			alignItems: 'center',
			textAlign: 'left',

			'&>span': {
				maxWidth: '520px',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			},
		},

		'& h4': {
			paddingLeft: '23px',
			transform: 'translateY(0)',
			display: 'flex',
		},
	},
	initialLocationTag: {
		fontWeight: theme.typography.font.weight.semiBold,
		background: theme.colors['productBrand-100'],
		color: theme.colors['productBrand-500'],
		fontSize: '13px',
		padding: '4px 12px',
		borderRadius: '5px',
		marginLeft: '20px',
	},
	childrenList: {
		height: '409px',
		overflowY: 'scroll',
		overflowX: 'hidden',
		width: '100%',

		'& ul': {
			listStyle: 'none',
			paddingLeft: 0,

			'& li': {
				margin: 0,
			},

			'& li:not(:first-child)': {
				borderTop: `2px solid ${theme.colors['grey-400']}`,
			},
		},
	},
	emptyList: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '30px 0',
		minHeight: '330px',
	},
	emptyIllustration: {
		width: '160px',
		height: '160px',
	},
	tooltip: {
		zIndex: theme.ELEVATION.MODAL.Z_INDEX + 1,
	},
	footer: {
		border: 'none',
		display: 'flex',
		justifyContent: 'center',
	},
}));

export default useStyles;
