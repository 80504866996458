import { LoadingOvalIcon } from '@pushpay/iconography';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { ServiceError } from '@src/components/errors';
import { useTranslation } from '@src/i18n';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		gap: theme.SPACING.SMALL,
		padding: theme.SPACING.XLARGE,
		scale: 0.9,
	},
	error: {
		margin: 0,
	},
}));

export function PreviewLoading({ error }: { error: boolean }) {
	const { translate } = useTranslation();
	const classes = useStyles(undefined);

	return (
		<div className={classes.root}>
			{error ? (
				<ServiceError className={classes.error} errorMessage={translate('errors.text.networkOrServerError')} />
			) : (
				<>
					<LoadingOvalIcon fontSize={40} />
					{translate('loadingPreview')}
				</>
			)}
		</div>
	);
}
