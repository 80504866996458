import { Maybe } from 'graphql/jsutils/Maybe';

import Accessor from '@pushpay/app-components/dist/app/utils/accessor';

import {
	ApplicationSettingKey,
	GetApplicationSettingsQuery,
	useGetApplicationSettingsQuery,
} from '@src/graphql/generated';
import { underscoreToDot } from '@src/utils';

import { useGetQueryAndMutationVars } from './useGetQueryAndMutationVars';

type AppSetting = NonNullable<
	NonNullable<NonNullable<GetApplicationSettingsQuery>['organization']>['application']
>['setting'][number];

type Setting = {
	id: string;
	val: Maybe<string>;
	val_type: string;
	enabled: boolean;
};

type SettingMap = {
	[key: string]: Setting;
};

function getAppSettings(appSettings: AppSetting[]) {
	const settingsObj = appSettings.reduce<SettingMap>((acc, setting) => {
		const formattedSetting = {
			id: underscoreToDot(setting.key),
			val: setting.enabled ? setting.value : null, // Accessor will return the default value when val is null
			val_type: setting.valueType.toLowerCase(),
			enabled: setting.enabled,
		};
		acc[formattedSetting.id] = formattedSetting;

		return acc;
	}, {});

	return new Accessor(settingsObj);
}

export function useGetAppSettings() {
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();

	const { data: applicationSettingsData } = useGetApplicationSettingsQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
		},
		fetchPolicy: 'cache-first',
	});
	const setting = applicationSettingsData?.organization?.application?.setting;

	if (!setting) return null;

	const appSettings = getAppSettings(setting);
	const getSettingValueByKey = (key: ApplicationSettingKey) => appSettings.get(underscoreToDot(key));

	return { appSettings, getSettingValueByKey };
}
