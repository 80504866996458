import React, { Dispatch, useCallback, useEffect, useId, useRef, useState, KeyboardEvent, SetStateAction } from 'react';

import { LinkNode, $createLinkNode, $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import {
	$getSelection,
	$isLineBreakNode,
	$isRangeSelection,
	BLUR_COMMAND,
	COMMAND_PRIORITY_CRITICAL,
	COMMAND_PRIORITY_HIGH,
	COMMAND_PRIORITY_LOW,
	GridSelection,
	KEY_ESCAPE_COMMAND,
	LexicalEditor,
	LexicalNode,
	NodeSelection,
	RangeSelection,
	SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { createPortal } from 'react-dom';

import { getPrimaryColor } from '@pushpay/app-components/dist/app/utils/settings_utils';
import { Button } from '@pushpay/button';
import { SuccessIcon, RemoveIcon } from '@pushpay/iconography';
import { Checkbox, TextInput } from '@pushpay/inputs';
import { Link } from '@pushpay/link';
import { clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { InAppBrowserSwitch } from '@src/components/properties/shared';
import { ShellCapabilityKey } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyApp } from '@src/myContext';
import { useGetAppSettings } from '@src/shared/hooks';

import { HTTPS_PLACEHOLDER, sanitizeUrl, getSelectedNode, setFloatingElemPositionForLinkEditor } from '../utils';
import { useStyles } from './FloatingLinkEditorPluginStyles';

type FloatingLinkEditorProps = ComponentProps<
	{
		editor: LexicalEditor;
		isLink: boolean;
		setIsLink: Dispatch<boolean>;
		anchorElem: HTMLElement;
		isLinkEditMode: boolean;
		setIsLinkEditMode: Dispatch<SetStateAction<boolean>>;
	},
	undefined
>;

type UseFloatingLinkEditorToolbar = Omit<FloatingLinkEditorProps, 'isLink' | 'setIsLink'>;

type FloatingLinkEditorPluginProps = ComponentProps<
	Omit<FloatingLinkEditorProps, 'editor' | 'isLink' | 'setIsLink'>,
	undefined
>;

function FloatingLinkEditor({
	editor,
	isLink,
	setIsLink,
	anchorElem,
	isLinkEditMode,
	setIsLinkEditMode,
}: FloatingLinkEditorProps) {
	const { getAppShellCapability } = useMyApp();
	// Resi VOD and In-app Browser share the same shell requirement
	const isInAppBrowserSupported = getAppShellCapability(ShellCapabilityKey.ResiVodDraggable)?.isSupported ?? false;
	const id = useId();

	const settings = useGetAppSettings();
	const primaryColor = getPrimaryColor(settings?.appSettings);
	const classes = useStyles(undefined, { primaryColor });
	const editorRef = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [linkUrl, setLinkUrl] = useState('');
	const [editedLinkUrl, setEditedLinkUrl] = useState('');
	const [isOpenExternallyChecked, setIsOpenExternallyChecked] = useState(true);
	const [lastSelection, setLastSelection] = useState<RangeSelection | GridSelection | NodeSelection | null>(null);
	const { translate } = useTranslation('appDesign');

	const setLink = (node: LinkNode | LexicalNode) => {
		const url = node.getURL();
		const target = node.getTarget();

		setLinkUrl(url);
		setEditedLinkUrl(url);

		if (target) {
			setIsOpenExternallyChecked(target === '_blank');
		}
	};

	const updateLinkEditor = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const linkParent = $findMatchingParent(node, $isLinkNode);

			if (linkParent) {
				setLink(linkParent);
			} else if ($isLinkNode(node)) {
				setLink(node);
			} else {
				setLinkUrl('');
			}
			if (isLinkEditMode) {
				setEditedLinkUrl(linkUrl);
			}
		}
		const editorElem = editorRef.current;
		const nativeSelection = window.getSelection();
		const { activeElement } = document;

		if (editorElem === null) {
			return;
		}

		const rootElement = editor.getRootElement();

		if (
			selection !== null &&
			nativeSelection !== null &&
			rootElement !== null &&
			rootElement.contains(nativeSelection.anchorNode) &&
			editor.isEditable()
		) {
			const domRect: DOMRect | undefined = nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
			if (domRect) {
				domRect.y += 40;
				setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
			}
			setLastSelection(selection);
		} else if (!activeElement || !activeElement.className.startsWith('linkInput')) {
			if (rootElement !== null) {
				setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
			}
			setLastSelection(null);
			setIsLinkEditMode(false);
			setIsOpenExternallyChecked(true);
			setLinkUrl('');
		}
	}, [anchorElem, editor, setIsLinkEditMode, isLinkEditMode, linkUrl]);

	useEffect(() => {
		const scrollerElem = anchorElem.parentElement;

		const update = () => {
			editor.getEditorState().read(() => {
				updateLinkEditor();
			});
		};

		window.addEventListener('resize', update);

		if (scrollerElem) {
			scrollerElem.addEventListener('scroll', update);
		}

		return () => {
			window.removeEventListener('resize', update);

			if (scrollerElem) {
				scrollerElem.removeEventListener('scroll', update);
			}
		};
	}, [anchorElem.parentElement, editor, updateLinkEditor]);

	useEffect(
		() =>
			mergeRegister(
				editor.registerUpdateListener(({ editorState }) => {
					editorState.read(() => {
						updateLinkEditor();
					});
				}),

				editor.registerCommand(
					SELECTION_CHANGE_COMMAND,
					() => {
						updateLinkEditor();
						return true;
					},
					COMMAND_PRIORITY_LOW
				),
				editor.registerCommand(
					BLUR_COMMAND,
					() => {
						const { activeElement } = document;
						const rootElement = editor.getRootElement();

						if (!isLinkEditMode && activeElement !== rootElement) {
							setIsLink(false);
							return true;
						}

						return false;
					},
					COMMAND_PRIORITY_LOW
				),
				editor.registerCommand(
					KEY_ESCAPE_COMMAND,
					() => {
						if (isLink) {
							setIsLink(false);
							return true;
						}
						return false;
					},
					COMMAND_PRIORITY_HIGH
				)
			),
		[editor, updateLinkEditor, setIsLink, isLink, isLinkEditMode]
	);

	useEffect(() => {
		editor.getEditorState().read(() => {
			updateLinkEditor();
		});
	}, [editor, updateLinkEditor]);

	useEffect(() => {
		if (isLinkEditMode && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isLinkEditMode]);

	const handleOpenExternallyChange = useCallback(
		(value: boolean) => {
			setIsOpenExternallyChecked(value);
		},
		[setIsOpenExternallyChecked]
	);

	const handleLinkSubmission = () => {
		if (lastSelection !== null) {
			if (linkUrl !== '') {
				const target = isOpenExternallyChecked ? '_blank' : '_self';
				const url = sanitizeUrl(editedLinkUrl);

				editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url, target });
				editor.update(() => {
					const selection = $getSelection();
					if ($isRangeSelection(selection)) {
						const parent = getSelectedNode(selection).getParent();
						if ($isAutoLinkNode(parent)) {
							const linkNode = $createLinkNode(parent.getURL(), {
								rel: parent.__rel,
								target: parent.__target,
								title: parent.__title,
							});
							parent.replace(linkNode, true);
						}
					}
				});
			}
			setEditedLinkUrl(HTTPS_PLACEHOLDER);
			setIsLinkEditMode(false);
		}
	};

	const monitorInputInteraction = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleLinkSubmission();
		} else if (event.key === 'Escape') {
			event.preventDefault();
			setIsLinkEditMode(false);
		}
	};

	if (!isLink) {
		return <div ref={editorRef} className={classes.linkEditor} />;
	}

	const hasFocus = (element: HTMLElement) => document.activeElement === element;

	if (isLinkEditMode && inputRef.current && !hasFocus(inputRef.current)) {
		inputRef.current.focus();
	}

	const urlValue = editedLinkUrl === HTTPS_PLACEHOLDER ? '' : editedLinkUrl;

	return (
		<div ref={editorRef} className={classes.linkEditor}>
			{isLinkEditMode ? (
				<>
					<TextInput
						ref={inputRef}
						aria-label={translate('richTextEditor.url')}
						classes={{ field: clsx(classes.button, classes.linkInput) }}
						id="url"
						placeholder={HTTPS_PLACEHOLDER}
						value={urlValue}
						onChange={value => {
							setEditedLinkUrl(value);
						}}
						onKeyDown={event => {
							monitorInputInteraction(event);
						}}
					/>
					{isInAppBrowserSupported ? (
						<InAppBrowserSwitch
							checked={isOpenExternallyChecked}
							classes={{ wrapper: classes.switchWrapper }}
							id={id}
							onChange={handleOpenExternallyChange}
						/>
					) : (
						<Checkbox
							checked={isOpenExternallyChecked}
							classes={{ root: classes.checkBox }}
							id={id}
							onChange={handleOpenExternallyChange}
						>
							{translate('richTextEditor.openExternally')}
						</Checkbox>
					)}
					<div>
						<Button
							aria-label={translate('richTextEditor.cancel')}
							className={clsx(classes.button, classes.linkCancel)}
							type="button"
							onClick={() => {
								setIsLinkEditMode(false);
							}}
							onMouseDown={event => event.preventDefault()}
						>
							<RemoveIcon className={classes.editModeIconSize} />
						</Button>
						<Button
							aria-label={translate('richTextEditor.confirm')}
							className={clsx(classes.button, classes.linkConfirm)}
							type="button"
							onClick={() => {
								handleLinkSubmission();
							}}
							onMouseDown={event => event.preventDefault()}
						>
							<SuccessIcon className={classes.editModeIconSize} />
						</Button>
					</div>
				</>
			) : (
				<div className={classes.linkView}>
					<Link
						href={linkUrl}
						rel="noopener noreferrer"
						target="_blank"
						onMouseDown={event => event.preventDefault()}
					>
						{linkUrl}
					</Link>
					<Button
						aria-label={translate('richTextEditor.edit')}
						className={clsx(classes.button, classes.linkEdit)}
						type="button"
						onClick={() => {
							setEditedLinkUrl(linkUrl);
							setIsLinkEditMode(true);
						}}
						onMouseDown={event => event.preventDefault()}
					/>
					<Button
						aria-label={translate('richTextEditor.remove')}
						className={clsx(classes.button, classes.linkTrash)}
						type="button"
						onClick={() => {
							editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
						}}
						onMouseDown={event => event.preventDefault()}
					/>
				</div>
			)}
		</div>
	);
}

function useFloatingLinkEditorToolbar({
	editor,
	anchorElem,
	isLinkEditMode,
	setIsLinkEditMode,
}: UseFloatingLinkEditorToolbar): React.JSX.Element | null {
	const [activeEditor, setActiveEditor] = useState(editor);
	const [isLink, setIsLink] = useState(false);

	useEffect(() => {
		const updateToolbar = () => {
			const selection = $getSelection();
			if ($isRangeSelection(selection)) {
				const focusNode = getSelectedNode(selection);
				const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode);
				const focusAutoLinkNode = $findMatchingParent(focusNode, $isAutoLinkNode);
				if (!(focusLinkNode || focusAutoLinkNode)) {
					setIsLink(false);
					return;
				}
				const badNode = selection.getNodes().find(node => {
					const linkNode = $findMatchingParent(node, $isLinkNode);
					const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode);
					if (
						!linkNode?.is(focusLinkNode) &&
						!autoLinkNode?.is(focusAutoLinkNode) &&
						!linkNode &&
						!autoLinkNode &&
						!$isLineBreakNode(node)
					) {
						return node;
					}

					return undefined;
				});
				if (!badNode) {
					setIsLink(true);
				} else {
					setIsLink(false);
				}
			}
		};

		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload, newEditor) => {
					updateToolbar();
					setActiveEditor(newEditor);
					return false;
				},
				COMMAND_PRIORITY_CRITICAL
			)
		);
	}, [editor]);

	return createPortal(
		<FloatingLinkEditor
			anchorElem={anchorElem}
			editor={activeEditor}
			isLink={isLink}
			isLinkEditMode={isLinkEditMode}
			setIsLink={setIsLink}
			setIsLinkEditMode={setIsLinkEditMode}
		/>,
		anchorElem
	);
}

export function FloatingLinkEditorPlugin({
	anchorElem = document.body,
	isLinkEditMode,
	setIsLinkEditMode,
}: FloatingLinkEditorPluginProps) {
	const [editor] = useLexicalComposerContext();

	return useFloatingLinkEditorToolbar({ editor, anchorElem, isLinkEditMode, setIsLinkEditMode });
}
