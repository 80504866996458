import { FormState } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { SaveContainerSettingsSchema, SaveItemSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n/translation';

import { UrlField } from './UrlField';

const useStyle = createUseStyles((theme: Theme) => ({
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
	},
	root: {
		marginTop: '0px',
	},
}));

export type GivingLinkFieldProps = ComponentProps<
	{
		field:
			| FormState<SaveContainerSettingsSchema['input']['givingLink']>
			| FormState<SaveItemSettingsSchema['input']['properties'][number]['givePropertyInput']['givingLink']>;
		applicationGivingLink: string | null | undefined;
		campusGivingLink: string | null | undefined;
		isGiveProperty?: boolean;
	},
	typeof useStyle
>;

export function GivingLinkField({
	field,
	campusGivingLink,
	applicationGivingLink,
	isGiveProperty,
	classes: classesProp,
}: GivingLinkFieldProps) {
	const classes = useStyle(classesProp);
	const { translate } = useTranslation('appDesign');
	const getLabel = () => {
		if (field.value && field.value !== campusGivingLink && field.value !== applicationGivingLink) {
			return translate('settings.container.customGivingLink');
		}
		if (campusGivingLink && (!field.value || field.value === campusGivingLink)) {
			return translate('settings.container.campusGivingLink');
		}
		if (applicationGivingLink && (!field.value || field.value === applicationGivingLink)) {
			return translate('settings.container.applicationGivingLink');
		}
		return translate('settings.container.givingLink');
	};

	const getPlaceholder = () => {
		if (campusGivingLink) {
			return campusGivingLink;
		}
		if (applicationGivingLink) {
			return applicationGivingLink;
		}
		return translate('settings.container.givingLinkPlaceholder');
	};

	return (
		<UrlField
			classes={{
				label: classes.label,
				root: isGiveProperty ? classes.root : undefined,
			}}
			field={field}
			label={getLabel()}
			labelPosition="top"
			placeholder={getPlaceholder()}
			tooltip={translate('settings.container.givingLinkTooltip')}
			showLabel
		/>
	);
}
