import { FormState, useMutateField } from '@pushpay/forms';

import { SaveContainerSettingsSchema, PropertyType } from '@src/graphql/generated';

import { HomeCardDetailFieldType } from './type';

export function useDataMapField(field: FormState<SaveContainerSettingsSchema>['input']['cardDefinition']['dataMap']) {
	const { mutateField: mutateAudioUrlPositionField } = useMutateField(field.audioUrl.position);
	const { mutateField: mutateAudioUrlTypeField } = useMutateField(field.audioUrl.type);

	const { mutateField: mutateSubtitlePositionField } = useMutateField(field.subtitle.position);
	const { mutateField: mutateSubtitleTypeField } = useMutateField(field.subtitle.type);

	const { mutateField: mutatePublishedTimePositionField } = useMutateField(field.publishedTime.position);
	const { mutateField: mutatePublishedTypeField } = useMutateField(field.publishedTime.type);

	const { mutateField: mutateSummaryPositionField } = useMutateField(field.summary.position);
	const { mutateField: mutateSummaryTypeField } = useMutateField(field.summary.type);

	const { mutateField: mutateVideoUrlPositionField } = useMutateField(field.videoUrl.position);
	const { mutateField: mutateVideoUrlTypeField } = useMutateField(field.videoUrl.type);

	const mutateDataMapPosition = (cardField: HomeCardDetailFieldType, position: number | null) => {
		if (cardField === 'audioUrl') {
			mutateAudioUrlPositionField(position);
		}
		if (cardField === 'subtitle') {
			mutateSubtitlePositionField(position);
		}
		if (cardField === 'publishedTime') {
			mutatePublishedTimePositionField(position);
		}
		if (cardField === 'summary') {
			mutateSummaryPositionField(position);
		}
		if (cardField === 'videoUrl') {
			mutateVideoUrlPositionField(position);
		}
	};
	const mutateDataMapType = (cardField: HomeCardDetailFieldType, type: PropertyType | null) => {
		if (cardField === 'audioUrl') {
			mutateAudioUrlTypeField(type);
		}
		if (cardField === 'subtitle') {
			mutateSubtitleTypeField(type);
		}
		if (cardField === 'publishedTime') {
			mutatePublishedTypeField(type);
		}
		if (cardField === 'summary') {
			mutateSummaryTypeField(type);
		}
		if (cardField === 'videoUrl') {
			mutateVideoUrlTypeField(type);
		}
	};
	return {
		mutateDataMapPosition,
		mutateDataMapType,
	};
}
