import { useEffect, useId, useRef } from 'react';

import { Moment } from 'moment';

import { FormState, useMutateField } from '@pushpay/forms';
import { Switch, TimePickerMethods } from '@pushpay/inputs';
import { Columns, Column } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { DateInputField, TimeInputField } from '@src/components/formFields';
import { TimeframePropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ContentWrapper, LabelWrapper } from '../wrappers';

const useStyles = createUseStyles((theme: Theme) => ({
	noMargin: {
		margin: 0,
	},
	noPadding: {
		padding: 0,
	},
	startDateTimeColumn: {
		paddingLeft: 0,
		paddingBottom: theme.SPACING.SMALL,
	},
	allDayInputWrapper: {
		display: 'inline-flex',
	},
	switch: {
		paddingRight: theme.SPACING.XSMALL,
	},
}));

const combineDateTime = (date?: Moment | null, time?: Moment | null) => {
	if (date) {
		const datetime = date.clone();
		if (time) {
			datetime.hour(time.hour()).minute(time.minute()).second(time.second());
		} else {
			datetime.hour(0).minute(0).second(0);
		}

		return datetime;
	}

	return undefined;
};

type TimeframeInput = FormState<Required<TimeframePropertyInput>>;
export type TimeframeProps = ComponentProps<
	{
		propertyField: FormState<TimeframePropertyInput>;
	},
	undefined
>;

export const Timeframe = ({ propertyField, 'data-pp-at-target': targetId }: TimeframeProps) => {
	const {
		startTime: startTimeFieldInput,
		endTime: endTimeFieldInput,
		allDay: allDayFieldInput,
	} = propertyField as TimeframeInput;

	const { translate } = useTranslation('appDesign');
	const classes = useStyles(undefined);

	const { mutateField: mutateStartTime } = useMutateField(startTimeFieldInput);
	const { mutateField: mutateEndTime } = useMutateField(endTimeFieldInput);
	const { mutateField: mutateAllDayTime } = useMutateField(allDayFieldInput);

	const startTimeRef = useRef<TimePickerMethods>(null);
	const endTimeRef = useRef<TimePickerMethods>(null);

	const startTime = allDayFieldInput.value ? undefined : startTimeFieldInput.value || undefined;
	const endTime = allDayFieldInput.value ? undefined : endTimeFieldInput.value || undefined;
	const allDayEvent = allDayFieldInput.value ?? false;
	const allDayDisabled = !startTimeFieldInput.value && !endTimeFieldInput.value;
	const startTimeDisabled = allDayEvent || startTime === undefined;
	const endTimeDisabled = allDayEvent || endTime === undefined;

	const handleEndDateTimeChange = (startDate?: Moment | null, endDate?: Moment | null) => {
		if (startDate && endDate && endDate.isBefore(startDate)) {
			mutateEndTime(startDate.clone());
		}
	};

	const handleStartDateTimeChange = (startDate?: Moment | null, endDate?: Moment | null) => {
		if (startDate && endDate && startDate.isAfter(endDate)) {
			mutateEndTime(startDate.clone());
		}
	};

	const onStartDateChange = (date?: Moment) => {
		const newDate = combineDateTime(date, startTimeFieldInput.value);

		mutateStartTime(newDate || null);
		handleStartDateTimeChange(newDate, endTimeFieldInput.value);
	};

	const onStartTimeChange = (time?: Moment) => {
		const newTime = combineDateTime(startTimeFieldInput.value, time);

		mutateStartTime(newTime || null);
		handleStartDateTimeChange(newTime, endTimeFieldInput.value);
	};

	const onEndDateChange = (date?: Moment) => {
		const newDate = combineDateTime(date, endTimeFieldInput.value);

		mutateEndTime(newDate || null);
		handleEndDateTimeChange(startTimeFieldInput.value, newDate);
	};

	const onEndTimeChange = (time?: Moment) => {
		const newTime = combineDateTime(endTimeFieldInput.value, time);

		mutateEndTime(newTime || null);
		handleEndDateTimeChange(startTimeFieldInput.value, newTime);
	};

	const onAllDayChange = () => {
		if (!allDayFieldInput.value) {
			startTimeRef.current?.clear();
			endTimeRef.current?.clear();
		}

		mutateAllDayTime(allDayFieldInput.value ? !allDayFieldInput.value : true);
	};

	useEffect(() => {
		if (!startTimeFieldInput.value && !endTimeFieldInput.value && allDayFieldInput.value) {
			mutateAllDayTime(false);
		}
	}, [startTimeFieldInput, endTimeFieldInput, allDayFieldInput, mutateAllDayTime]);

	return (
		<ContentWrapper>
			<Columns classes={{ root: classes.noMargin }} space="SMALL" stackWhen="NEVER">
				<Column classes={{ inner: classes.startDateTimeColumn }}>
					<LabelWrapper label={translate('timeframe.startDate')}>
						<DateInputField
							aria-label={translate('timeframe.startDate')}
							data-pp-at-target={`${targetId}-timeframe-start-date`}
							id={useId()}
							value={startTimeFieldInput.value || undefined}
							disableClear
							onChange={onStartDateChange}
						/>
					</LabelWrapper>
				</Column>
				<Column>
					<LabelWrapper label={translate('timeframe.startTime')}>
						<TimeInputField
							ref={startTimeRef}
							aria-label={translate('timeframe.startTime')}
							data-pp-at-target={`${targetId}-timeframe-start-time`}
							id={useId()}
							readOnly={startTimeDisabled}
							value={startTime}
							disableClear
							onChange={onStartTimeChange}
						/>
					</LabelWrapper>
				</Column>
			</Columns>
			<Columns classes={{ root: classes.noMargin }} space="SMALL" stackWhen="NEVER">
				<Column classes={{ inner: classes.noPadding }}>
					<LabelWrapper label={translate('timeframe.endDate')}>
						<DateInputField
							aria-label={translate('timeframe.endDate')}
							data-pp-at-target={`${targetId}-timeframe-end-date`}
							id={useId()}
							value={endTimeFieldInput.value || undefined}
							disableClear
							onChange={onEndDateChange}
						/>
					</LabelWrapper>
				</Column>
				<Column>
					<LabelWrapper label={translate('timeframe.endTime')}>
						<TimeInputField
							ref={endTimeRef}
							aria-label={translate('timeframe.endTime')}
							data-pp-at-target={`${targetId}-timeframe-end-time`}
							id={useId()}
							readOnly={endTimeDisabled}
							value={endTime}
							disableClear
							onChange={onEndTimeChange}
						/>
					</LabelWrapper>
				</Column>
			</Columns>
			<LabelWrapper classes={{ wrapper: classes.allDayInputWrapper }}>
				<Switch
					checked={allDayEvent}
					classes={{ switchWrapper: classes.switch }}
					id={useId()}
					readOnly={allDayDisabled}
					onChange={onAllDayChange}
				>
					{translate('timeframe.allDayEvent')}
				</Switch>
			</LabelWrapper>
		</ContentWrapper>
	);
};
