import { ActionButton, Button } from '@pushpay/button';
import { CrossIcon, WarningIcon } from '@pushpay/iconography';
import Modal from '@pushpay/modal';
import { clsx } from '@pushpay/styles';

import useStyles from '../modalStyles';

export type ConfirmationDialogProps = {
	isConfirmModalOpen: boolean;
	setIsConfirmModalOpen: (isOpen: boolean) => void;
	title: string;
	bodyText: string;
	cancelButtonHandler?: () => void;
	closeButtonHandler?: () => void;
	cancelButtonLabel: string;
	confirmButton: React.ReactNode;
};

export const ConfirmationDialog = ({
	isConfirmModalOpen,
	setIsConfirmModalOpen,
	title,
	bodyText,
	cancelButtonHandler,
	closeButtonHandler,
	cancelButtonLabel,
	confirmButton,
}: ConfirmationDialogProps) => {
	const classes = useStyles(undefined);
	const defaultOnClose = () => setIsConfirmModalOpen(false);

	const onClose = () => {
		if (closeButtonHandler) {
			closeButtonHandler();
		}
		defaultOnClose();
	};
	const onCancel = cancelButtonHandler ?? defaultOnClose;

	return (
		<>
			<Modal isOpen={isConfirmModalOpen} onClickOutside={onClose}>
				<div className={classes.content}>
					<ActionButton
						className={classes.closeButton}
						displayStyle="secondary"
						icon={CrossIcon}
						onClick={onClose}
					/>
					<WarningIcon classes={{ root: classes.icon }} data-pp-at-target="confirm-icon" />
					<div className={classes.header}>{title}</div>
					<div className={clsx(classes.cardText, classes.text)}>{bodyText}</div>
					<div className={classes.buttons}>
						<Button displayStyle="secondary" type="button" onClick={onCancel}>
							{cancelButtonLabel}
						</Button>
						{confirmButton}
					</div>
				</div>
			</Modal>
		</>
	);
};
