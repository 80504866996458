import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { useAppPreviewContext } from '@src/context';

const useStyles = createUseStyles((theme: Theme) => ({
	root: ({ height }: { height: number }) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: theme.colors['common-white'],
		backgroundColor: theme.colors['grey-700'],
		border: 'none',
		font: theme.typography['heading-3'],
		fontWeight: 'normal',
		textAlign: 'center',
		whiteSpace: 'pre-wrap',
		height,
		padding: theme.SPACING.LARGE,
	}),
}));

export function PreviewPlaceholder({ placeholderText, onClick }: { placeholderText: string; onClick?: () => void }) {
	const { screenHeight } = useAppPreviewContext();
	const classes = useStyles(undefined, { height: screenHeight });

	const Tag = onClick ? 'button' : 'div';

	return (
		<Tag className={classes.root} type={onClick && 'button'} onClick={onClick}>
			{placeholderText}
		</Tag>
	);
}
