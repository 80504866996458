import { Announcements, ScreenReaderInstructions } from '@dnd-kit/core';

import { useTranslation } from '@src/i18n/translation';

export function useLocalizedAnnouncementsAndInstructions() {
	const { translate } = useTranslation('appDesign');

	const announcements: Announcements = {
		onDragStart({ active }) {
			return translate('dragAndDrop.accessibility.announcements.onDragStart', {
				activeId: active.data?.current?.name || active.id,
			});
		},
		onDragOver({ active, over }) {
			const activeId = active.data?.current?.name || active.id;
			if (over) {
				return translate('dragAndDrop.accessibility.announcements.onDragOverDroppable', {
					activeId,
					overId: over.data?.current?.name || over.id,
				});
			}

			return translate('dragAndDrop.accessibility.announcements.onDragOverNonDroppable', { activeId });
		},
		onDragEnd({ active, over }) {
			const activeId = active.data?.current?.name || active.id;
			if (over) {
				return translate('dragAndDrop.accessibility.announcements.onDragEndOnDroppable', {
					activeId,
					overId: over.data?.current?.name || over.id,
				});
			}

			return translate('dragAndDrop.accessibility.announcements.onDragEndOnNonDroppable', {
				activeId,
			});
		},
		onDragCancel({ active }) {
			return translate('dragAndDrop.accessibility.announcements.onDragCancel', {
				activeId: active.data?.current?.name || active.id,
			});
		},
	};

	const screenReaderInstructions: ScreenReaderInstructions = {
		draggable: translate('dragAndDrop.accessibility.screenReaderInstructions'),
	};

	return { announcements, screenReaderInstructions };
}
