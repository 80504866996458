import { memo } from 'react';

import { Button } from '@pushpay/button';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { Icon, ICON_NAME } from '@src/components/icon';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	iconButton: {
		height: '44px',
		width: '44px',
		'&:hover': {
			borderRadius: '4px',
			backgroundColor: theme.colors['grey-300'],
		},
		padding: 0,
	},
	icon: {
		color: theme.colors['text-default'],
		width: theme.SPACING.MEDIUM,
		height: theme.SPACING.MEDIUM,
	},
	tooltip: {
		zIndex: theme.ELEVATION.MODAL.Z_INDEX,
	},
	selected: {
		border: `2px solid ${theme.colors['productBrand-500']} `,
		borderRadius: '4px',
		backgroundColor: theme.colors['productBrand-100'],
		'&:hover': {
			backgroundColor: theme.colors['productBrand-100'],
		},
	},
	small: {
		'& svg': {
			width: theme.SPACING.MEDIUM,
			height: theme.SPACING.MEDIUM,
		},
	},
}));

type IconComponentProps = {
	selectedIcon: ICON_NAME | undefined;
	iconName: ICON_NAME;
	onSelect: (icon: ICON_NAME) => void;
};

export const IconComponent = memo(
	({
		iconName,
		selectedIcon,
		onSelect,
		classes: classesProp,
		className: classNameProp,
		'data-pp-at-target': targetId,
	}: ComponentProps<IconComponentProps, typeof useStyles>) => {
		const classes = useStyles(classesProp);
		const className = clsx(classes.root, classNameProp);

		return (
			<div className={className}>
				<Tooltip
					aria-hidden={false}
					classes={{ root: classes.tooltip }}
					content={iconName}
					data-pp-at-target={`${targetId}-tooltip`}
					placement="top"
				>
					<Button
						aria-label={iconName}
						classes={{
							root: clsx(classes.iconButton, selectedIcon === iconName && classes.selected),
							small: classes.small,
						}}
						data-pp-at-target={`${targetId}-icon-button`}
						displayStyle="text"
						type="button"
						onClick={() => onSelect(iconName)}
					>
						<Icon
							classes={{ root: classes.icon }}
							data-pp-at-target={`${targetId}-icon-${iconName}`}
							name={iconName}
						/>
					</Button>
				</Tooltip>
			</div>
		);
	}
);
