import { useTranslation } from '@src/i18n';

import { ServiceUnavailableIllustration } from '../illustrations';
import { BaseError } from './BaseError';

const targetId = 'no-app-found-error';

export const NoAppFoundError = () => {
	const { translate } = useTranslation();

	return (
		<BaseError
			data-pp-at-target={targetId}
			illustration={ServiceUnavailableIllustration}
			text={translate('errors.text.noApplicationError')}
			title={translate('errors.title.noApplicationError')}
			withGetHelpLink
		/>
	);
};
