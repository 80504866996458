import { FC, PropsWithChildren } from 'react';

import { AccessDeniedError, NoAppFoundError } from '@src/components/errors';
import { useLoadingDispatchContext } from '@src/context';
import { useOrganizationFeatureFlagOverrides } from '@src/featureFlags';
import { useMyOrganizationQuery, AccountType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useMyIdentity } from '@src/myContext';
import { useParams } from '@src/router';
import { getAppSettings } from '@src/utils';

import { TopNav } from '../appChrome/components/topNav';

const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => (
	<>
		<TopNav data-pp-at-target="top-nav" withMyOrgData={false} />
		{children}
	</>
);

export const MyOrganization: FC<PropsWithChildren> = ({ children }) => {
	const { translate } = useTranslation();
	const { organizationKey } = useParams<'organizationKey'>();
	const { data, loading } = useMyOrganizationQuery({
		variables: { organizationKey },
		fetchPolicy: 'cache-first',
	});
	const { useFinishLoading } = useLoadingDispatchContext();
	const { masUrl } = getAppSettings();
	const { accountType } = useMyIdentity();

	useFinishLoading(loading);
	useOrganizationFeatureFlagOverrides(data?.organization?.featureFlags);

	if (data?.organization) {
		const isAppStudioDesignEnabled = data.organization?.appStudio?.isAppStudioDesignEnabled;
		const hasAppStudioPermission = isAppStudioDesignEnabled || accountType === AccountType.PushpayAdmin;
		if (!hasAppStudioPermission) {
			window.location.href = masUrl;
		}

		if (data.organization.applications.length === 0) {
			return (
				<ErrorWrapper>
					<NoAppFoundError />
				</ErrorWrapper>
			);
		}

		return <>{children}</>;
	}

	if (loading) {
		return null;
	}

	return (
		<ErrorWrapper>
			<AccessDeniedError error={translate('errors.text.noOrgAccessError')} />
		</ErrorWrapper>
	);
};
