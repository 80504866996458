import { ComponentProps } from '@pushpay/types';

import { ContentType, useContentStateContext } from '@src/context';
import { useDraggablesDataContext } from '@src/context/draggableData/draggablesDataContext';
import { EntitlementKey } from '@src/graphql/generated';
import { useMyEntitlements } from '@src/myContext';

import { DraggablePaneComponent } from './DraggablePaneComponent';

export type DraggablePaneProps = ComponentProps<unknown, undefined>;

export const DraggablePane = ({ 'data-pp-at-target': targetId }: DraggablePaneProps) => {
	const { type, loading } = useContentStateContext();
	const { hasOrganizationEntitlement } = useMyEntitlements();
	const { draggables } = useDraggablesDataContext();

	return (
		<DraggablePaneComponent
			data-pp-at-target={targetId}
			draggables={draggables}
			hasCcb={hasOrganizationEntitlement(EntitlementKey.AppChms)}
			isDHSScreen={type === ContentType.DHS}
			loading={loading}
		/>
	);
};
