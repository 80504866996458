import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	card: {
		fontWeight: theme.typography.font.weight.semiBold,
		color: theme.colors['productBrand-500'],
		transition: 'background-color 0.3s ease',
		display: 'flex',
		alignItems: 'center',
		width: '768px',
		margin: 0,
		paddingLeft: '15px',

		'&:hover': {
			backgroundColor: theme.colors['productBrand-100'],
		},
		'&>span': {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
	},
	navigationButton: {
		marginLeft: 'auto',
		alignItems: 'center',
		padding: '16px',
	},
	iconContainer: {
		display: 'flex',
	},
	nameContainer: {
		maxWidth: 'calc(100% - 94px)',
		margin: theme.SPACING.SMALL,
		marginLeft: 0,
		flexShrink: 7,
	},
	disabled: {
		color: theme.colors['grey-500'],
		cursor: 'not-allowed',

		'& svg': {
			color: theme.colors['grey-500'],
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	active: {
		borderLeft: `5px solid ${theme.colors['productBrand-500']}`,
		paddingLeft: '10px',
		backgroundColor: theme.colors['productBrand-100'],
	},
	leftIcon: {
		height: theme.SPACING.MEDIUM,
		width: theme.SPACING.MEDIUM,
		marginRight: theme.SPACING.XSMALL,
		displaySize: 'inherit',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '16px',
	},
	rightIcon: {
		height: theme.SPACING.SMALL,
		width: theme.SPACING.SMALL,
		displaySize: 'inherit',
		strokeWidth: 30,
		marginLeft: 'auto',
		color: theme.colors['grey-900'],
	},
	tagsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexShrink: 3,
	},
	campusTag: {
		marginRight: theme.SPACING.XXSMALL,
	},
}));

export default useStyles;
