import { createUseStyles } from '@pushpay/styles';
import { ChMSTheme, Theme } from '@pushpay/theming';

import { getShadow } from '@src/shared/styles';

const useStyles = createUseStyles((theme: Theme) => ({
	card: {
		height: '52px',
		borderRadius: '6px',
		backgroundColor: theme.colors['background-surface'],
		margin: `10px 0px`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		...theme.OUTLINE.INTERACTIVE_ELEMENT_FOCUS_VISIBLE,
	},
	loading: {
		padding: `0 ${theme.SPACING.SMALL}`,
	},
	cardLink: {
		flexGrow: 1,
		overflow: 'hidden',
		cursor: 'inherit',
		display: 'flex',
		alignItems: 'center',
	},
	noOverlay: {
		'&:hover, &:focus': {
			...getShadow(),
			cursor: 'grab',
		},
	},
	overlay: {
		boxShadow: theme.ELEVATION.EIGHT,
		cursor: 'grabbing',
	},
	insertBefore: {
		position: 'relative',
		transform: 'translateY(2px)',

		'&:before': {
			content: '""',
			position: 'absolute',
			top: '-8px',
			left: 0,
			width: '100%',
			height: '4px',
			backgroundColor: theme.colors['blue-500'],
		},
	},
	dragging: {
		visibility: 'hidden',
	},
	cardContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '52px',
		overflow: 'hidden',
		flex: 1,
	},
	containerCard: {
		color: theme.colors['productBrand-700'],
	},
	itemCard: {
		color: theme.colors['blue-700'],
	},
	CCBModuleCard: {
		color: ChMSTheme.colors['productBrand-500'],
	},
	resiCard: {
		color: 'var(--color-resi)',
	},
	handle: {
		position: 'relative',
		color: theme.colors['grey-600'],
		marginLeft: theme.SPACING.XXSMALL,
		display: 'flex',
		boxShadow: 'none',
	},
	end: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		cursor: 'default',
	},
	iconLegacy: {
		margin: '13px 4px 13px 6px',
		height: theme.SPACING.MEDIUM,
		width: theme.SPACING.MEDIUM,
		displaySize: 'inherit',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	nameContainer: {
		margin: theme.SPACING.SMALL,
		marginLeft: 0,
		minWidth: 0,
		flexShrink: 7,
	},
	name: {
		font: theme.typography['text-4-semiBold'],
	},
	tagsContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.SPACING.XXSMALL,
		flexShrink: 3,
	},
	campusTag: {
		marginRight: theme.SPACING.XXSMALL,
	},
	tagIcon: {
		marginInlineEnd: theme.SPACING.XXSMALL,
	},
	iconTag: {
		width: theme.SPACING.MEDIUM,
		height: theme.SPACING.MEDIUM,
		color: theme.colors['grey-600'],
		margin: theme.SPACING.XSMALL,
	},
	buttonContainer: {
		margin: theme.SPACING.XSMALL,
		borderRadius: theme.SHAPE.CIRCULAR,
	},
	buttonIcon: {
		height: '36px',
		width: '36px',
	},
	tooltip: {
		width: '100%',
	},
	selectedCard: {
		backgroundColor: theme.colors['productBrand-100'],
	},
	onHover: {
		'&:hover': {
			backgroundColor: theme.colors['productBrand-100'],
		},
	},
	highlight: {
		animation: '$highlight 2s ease',
	},
	'@keyframes highlight': {
		'0%': {
			backgroundColor: theme.colors['productBrand-100'],
		},
		'100%': {
			backgroundColor: theme.colors['background-surface'],
		},
	},
	cardName: {
		justifyContent: 'flex-start',
	},
	iconContainer: {
		height: '24px',
		minWidth: '24px',
		marginRight: `${theme.SPACING.XSMALL}`,
		display: 'flex',
		alignItems: 'center',
	},
	dragDropIconContainer: {
		height: '24px',
		minWidth: '24px',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.SPACING.XXSMALL,
		margin: `0 ${theme.SPACING.XSMALL}`,
	},
	icon: {
		height: '20px',
		width: '20px',
	},
	dragDropIcon: {
		position: 'relative',
		color: theme.colors['grey-600'],
		boxShadow: 'none',
		height: '20px',
		width: '20px',
	},
	cursorPointer: {
		'&:hover, &:focus': {
			cursor: 'default',
		},
	},
	shimmer: {
		overflow: 'hidden',
		position: 'relative',
		pointerEvents: 'none',

		'&:after': {
			opacity: 0.3,
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			width: 0,
			backgroundImage: `linear-gradient(to left, ${theme.colors['grey-300']} 25%, ${theme.colors['grey-400']} 40%, ${theme.colors['grey-300']} 80%)`,
			animation: '$shimmer 2s infinite',
		},
	},
	'@keyframes shimmer': {
		'100%': {
			width: '200%',
		},
	},
	disabledCard: {
		backgroundColor: theme.colors['background-input-disabled'],
	},
	feedIconContainer: {
		height: theme.SPACING.SMALL,
		minWidth: theme.SPACING.SMALL,
		display: 'flex',
		alignItems: 'center',
	},
	feedIcon: {
		height: theme.SPACING.MEDIUM_ICON_SIZE,
		width: theme.SPACING.MEDIUM_ICON_SIZE,
	},
}));

export default useStyles;
