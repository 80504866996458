const HTTPS_URL_REG_EXP = new RegExp(
	`^(?:(?:(?:https):)?\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff]\\.)+(?:[a-z\\u00a1-\\uffff]{2,}\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$`,
	'i'
);
const HTTP_URL_REG_EXP = new RegExp(
	`^(?:(?:(?:https?):)?\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff]\\.)+(?:[a-z\\u00a1-\\uffff]{2,}\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$`,
	'i'
);

export function validateUrl(url?: string, httpsOnly = true): url is string {
	if (!url) {
		return false;
	}

	const regEx = httpsOnly ? HTTPS_URL_REG_EXP : HTTP_URL_REG_EXP;

	return regEx.test(url.trim());
}
