import { FC, PropsWithChildren, Suspense } from 'react';

import Lottie from 'lottie-react';

import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { LoadingContextProvider, useLoadingStateContext } from '@src/context';
import { useTranslation } from '@src/i18n';

import loadingIconAnimation from './loadingIcon.json';

const useStyles = createUseStyles((theme: Theme) => ({
	'@global': {
		'html, body, #app': {
			height: '100%',
		},
		body: {
			backgroundColor: theme.colors['background-page'],
			overflow: 'hidden',
			'--color-resi': '#FF585D', // Resi theme doesn't exist on @pushpay/theming
		},
	},
	main: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
	},
	lottie: {
		width: '150px',
	},
}));

const LoadingText = () => {
	const { translate } = useTranslation();

	return <span>{translate('loading')}</span>;
};

const LoadingContent: FC<PropsWithChildren> = ({ children }) => {
	const classes = useStyles(undefined);
	const { isLoading } = useLoadingStateContext();

	return (
		<>
			{isLoading ? (
				<div className={classes.main}>
					<Lottie animationData={loadingIconAnimation} className={classes.lottie} loop />
					<Suspense fallback={<span>&nbsp;</span>}>
						<LoadingText />
					</Suspense>
				</div>
			) : null}
			<Suspense>{children}</Suspense>
		</>
	);
};

export const LoadingScreen: FC<PropsWithChildren> = ({ children }) => (
	<LoadingContextProvider>
		<LoadingContent>{children}</LoadingContent>
	</LoadingContextProvider>
);
