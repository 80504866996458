import { createContext, FC, PropsWithChildren, useContext, useMemo, useState, useEffect } from 'react';

type ErrorContext = {
	isPageNotFoundError: boolean;
	useUpdatePageNotFoundError: (value: boolean) => void;
};

const ErrorContext = createContext<ErrorContext | null>(null);
ErrorContext.displayName = 'ErrorContext';

const ErrorContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isPageNotFoundError, setIsPageNotFoundError] = useState<boolean>(false);

	const ErrorContextValue: ErrorContext = useMemo(
		() => ({
			isPageNotFoundError,
			useUpdatePageNotFoundError: (value?: boolean) => {
				useEffect(() => {
					if (value) {
						setIsPageNotFoundError(true);
					}

					// reset after navigating to another route
					return () => {
						setIsPageNotFoundError(false);
					};
				}, [value]);
			},
		}),
		[isPageNotFoundError]
	);

	return <ErrorContext.Provider value={ErrorContextValue}>{children}</ErrorContext.Provider>;
};

function useErrorContext(): ErrorContext {
	const context = useContext(ErrorContext);
	if (!context) {
		throw new Error('useErrorContext must be used within an ErrorContextProvider');
	}
	return context;
}

export { ErrorContext, ErrorContextProvider, useErrorContext };
